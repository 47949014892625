export default (serviceContainer) => ({
  namespaced: true,
  state: {
    merchantAccountInfo: {},
  },
  mutations: {
    setMerchantAccountInfo(state, newList) {
      state.merchantAccountInfo = newList;
    },
  },
  getters: {
    getMerchantAccountInfo(state) {
      return state.merchantAccountInfo;
    },
  },
  actions: {
    async refreshMerchantAccountInfo({ commit }) {
      const api = serviceContainer.resolve('api');

      try {
        const response = await api.account.getAccountInfo();
        const data = response.data ?? {};

        const dataBlocks = [
          'businessStructure',
          'businessRepresentative',
          'businessDetails',
          'supportDetails',
          'accountForPayouts',
          'taxCalculation',
        ];
        const merchantInfo = Object.keys(data)
          .filter((blockName) => dataBlocks.includes(blockName))
          .reduce((blocks, blockName) => {
            blocks[blockName] = Object.keys(data[blockName]).reduce((blockInfo, field) => {
              let parts = field.split('_');
              let fieldName = parts.shift();
              parts = parts.map((part) => part.charAt(0).toUpperCase() + part.slice(1));
              fieldName += parts.join('');
              blockInfo[fieldName] = data[blockName][field];
              return blockInfo;
            }, {});
            // TODO: delete after allsoft api send identificationNumber
            if (blockName === 'businessRepresentative' && !blocks[blockName].identificationNumber) {
              blocks[blockName].identificationNumber = null;
            }

            return blocks;
          }, {});

        merchantInfo.documents = data.documents;

        commit('setMerchantAccountInfo', merchantInfo);
        return merchantInfo;
      } catch (error) {
        return error;
      }
    },
  },
});
