
import Vue, {
  computed, inject, onMounted, onUpdated, PropType, reactive, ref, watch,
} from 'vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSSelect from '@/module/design-system/components/UI/DSSelect.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import EIcon from '@/module/design-system/models/EIcon';
import EIconColorName from '@/module/design-system/components/models/EIconColorName';
import ESize from '@/module/design-system/components/models/ESize';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import EDSButtonVariant from '@/module/design-system/models/UI/DSButton/EDSButtonVariant';
import EPricingModelName from '@/module/product/model/Product/Fields/PricingModel/EPricingModelName';
import TPricingTableValue from '@/module/product/model/TPricingTableValue';
import IPricingTableState from '@/module/product/model/PricingTable/IPricingTableState';
import CMaskedInput from '@/module/common/components/UI/MaskedInput/CMaskedInput.vue';
import CInput from '@/module/common/components/UI/CInput.vue';

export default Vue.extend({
  name: 'PricingTable',
  components: {
    CInput,
    CMaskedInput,
    DSButton,
    DSIcon,
    DSTextElement,
    DSSelect,
  },
  props: {
    value: {
      type: [Array, Object, undefined] as PropType<TPricingTableValue | undefined>,
      default: () => undefined,
    },
    edit: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;
    const dsButtonHelper = serviceContainer.helperService.dsButtonHelper;
    const localisationRegistry = serviceContainer.localisationRegistry;
    const pricingTableHelper = serviceContainer.moduleProduct.pricingTableHelper;
    const utilsService = serviceContainer.utilsService;
    const numberFormatService = serviceContainer.numberFormatService;

    const delimiter = numberFormatService.getMoneyDelimiter();
    const placeholder = `0${delimiter}00`;

    const state: IPricingTableState = reactive({
      data: {} as TPricingTableValue,
      shadowWidth: 0,
      shadowHeight: 0,
    });

    const mask = {
      mask: 'NumberConstructor',
      min: 0.01,
      scale: 2,
      radix: delimiter,
    };

    const isPriceModelWithForks = computed(() => {
      const pricingType = state.data.pricingModelName;

      const priceModelWithForks = [
        EPricingModelName.VOLUME_PRICING,
        EPricingModelName.VOLUME_PRICING_AND_OPTIONS,
      ];

      return pricingType && priceModelWithForks.includes(pricingType) && props.edit;
    });

    const currencyList = computed(() => {
      const productFormPageHandler = serviceContainer.portalModeService.getProductFormPageHandler();
      const pricingType = state.data.pricingModelName;

      if (pricingType === EPricingModelName.VOLUME_PRICING) {
        return productFormPageHandler.getPriceCurrencyList()
          .map((currency) => ({ id: currency, title: currency }));
      }

      return productFormPageHandler.getSellingCurrencyList()
        .map((currency) => ({ id: currency, title: currency }));
    });

    const wrapperRef = ref();
    const scrollRef = ref();
    const tableRef = ref();
    const shadowRef = ref();

    const enableCurrencySelect = computed(() => pricingTableHelper.isVolumePrice(state.data));

    const shadowStyle = computed(() => ({
      width: `${state.shadowWidth}px`,
      height: `${state.shadowHeight}px`,
    }));

    const updateShadowSize = () => {
      const innerBorderTotalWidth = 2;

      if (wrapperRef.value && shadowRef.value) {
        wrapperRef.value.style.maxWidth = `${wrapperRef.value.parentElement.offsetWidth}px`;

        shadowRef.value.style.maxWidth = `${wrapperRef.value.parentElement.offsetWidth}px`;
        shadowRef.value.style.width = `${tableRef.value.offsetWidth - innerBorderTotalWidth}px`;
        shadowRef.value.style.height = `${tableRef.value.offsetHeight}px`;
      }
    };

    const tableTitles = computed(() => ({
      firstUnit: localisationRegistry.productsOld.form.table.firstUnit,
      lastUnit: localisationRegistry.productsOld.form.table.lastUnit,
      sku: localisationRegistry.productsOld.form.table.sku,
      forTheFirst: localisationRegistry.productsOld.form.table.forTheFirst,
    }));

    function handleBlur(): void {
      emit('blur', state.data);
    }

    const deleteForkButton = dsButtonHelper.getButtonWithIcon(
      {
        text: localisationRegistry.productsOld.form.table.forTheNext,
        variant: EDSButtonVariant.TRETIARY_GRAY,
      },
      (id) => {
        state.data = pricingTableHelper.deleteTier(state.data, id as unknown as number);

        emit('blur', state.data);
      },
      {
        icon: EIcon.TRASH,
        color: EIconColorName.DISABLED,
        size: ESize.S,
      },
      true,
    );

    const addForkButton = dsButtonHelper.getButtonWithIcon(
      {
        text: localisationRegistry.productsOld.form.table.addTier,
        variant: EDSButtonVariant.SECONDARY,
      },
      () => {
        state.data = pricingTableHelper.addTier(state.data);

        emit('blur', state.data);
      },
      {
        icon: EIcon.PLUS,
        color: EIconColorName.LINKED,
        size: ESize.S,
      },
      true,
    );

    onMounted(() => {
      state.data = utilsService.data.cloneImmutable(props.value) as TPricingTableValue;

      updateShadowSize();
    });

    watch(() => props.value, (newValue) => {
      state.data = utilsService.data.cloneImmutable(newValue) as TPricingTableValue;

      updateShadowSize();
    });

    onUpdated(() => {
      updateShadowSize();
    });

    return {
      state,
      shadowStyle,
      isPriceModelWithForks,
      wrapperRef,
      scrollRef,
      tableRef,
      shadowRef,
      tableTitles,
      enableCurrencySelect,
      currencyList,
      localisationRegistry,
      deleteForkButton,
      addForkButton,
      handleBlur,
      pricingTableHelper,
      mask,
      placeholder,
    };
  },
});
