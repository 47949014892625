
import Vue, {
  computed,
  ComputedRef,
  inject,
  onMounted,
  reactive,
} from 'vue';
import {
  Store,
} from 'vuex';
import {
  useRouter,
} from 'vue-router/composables';

import {
  EMerchantModeAlertStatus,
  EPage,
  getIsMerchantModeAlertVisible,
  setMerchantModeAlertStatus,
} from '@/module/common/utils/merchantModeAlert';

import PaymentLinkPage from '@/module/product/view/PaymentLink/PaymentLinkPage/PaymentLinkPage';

import EGridTitleKey from '@/module/product/view/PaymentLink/PaymentLinkPage/model/enum/EGridTitleKey';
import EMerchantMode from '@/module/common/models/EMerchantMode';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import ESize from '@/module/design-system/components/models/ESize';
import ESortDirection from '@/module/common/models/ESortDirection';

import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IState from '@/module/product/view/PaymentLink/PaymentLinkPage/model/interface/IState';
import IVueInstance from '@/module/common/models/IVueInstance';

import ButtonMore from '@/components/ButtonMore/ButtonMore.vue';
import CCopyField from '@/module/common/components/UI/CCopyField.vue';
import CInput from '@/module/common/components/UI/CInput.vue';
import COverviewPageHeaderRow from '@/module/common/components/PageParts/COverviewPageHeaderRow.vue';
import COverviewPageLayout from '@/module/common/components/PageParts/COverviewPageLayout.vue';
import CSelect from '@/module/common/components/UI/CSelect.vue';
import CTextWrapper from '@/module/common/components/Text/CTextWrapper.vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import InlineAlert from '@/components/InlineAlert/InlineAlert.vue';
import PreloaderComponent from '@/components/Preloader/PreloaderComponent.vue';
import CStatusWindowComponent from '@/module/common/components/UI/CStatusWindowComponent.vue';
import DSModal from '@/module/design-system/components/UI/DSModal.vue';
import IPaymentLinkBackendModel from '@/module/product/view/PaymentLink/model/interface/IPaymentLinkBackendModel';
import EPaymentLinkStatus from '@/module/product/view/PaymentLink/model/enum/EPaymentLinkStatus';

export default Vue.extend({
  name: 'PaymentLinkPage',
  components: {
    DSModal,
    CStatusWindowComponent,
    PreloaderComponent,
    CSelect,
    ButtonMore,
    CCopyField,
    InlineAlert,
    CInput,
    CTextWrapper,
    DSButton,
    DSTextElement,
    DSIcon,
    COverviewPageHeaderRow,
    COverviewPageLayout,
  },
  setup() {
    const vueInstance = inject<IVueInstance>('vueInstance') as IVueInstance;

    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;

    const localisationRegistry = serviceContainer.localisationRegistry;
    const localisation = localisationRegistry.paymentLinkList;
    const localisationCommon = localisationRegistry.common;

    const store = serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);

    const utilsService = serviceContainer.utilsService;

    const numberFormatService = serviceContainer.numberFormatService;

    const router = useRouter();

    const state: IState = reactive({
      isLoading: true,
      isUpdating: false,
      isError: false,
      showModal: false,
      paymentLinkList: [],
      responseStatus: 200,
      gridTitles: [
        {
          title: localisation.listHeader.paymentLink.toUpperCase(),
          key: EGridTitleKey.PAYMENT_LINK,
        },
        {
          title: localisation.listHeader.amount.toUpperCase(),
          key: EGridTitleKey.AMOUNT,
        },
        {
          title: localisation.listHeader.status.toUpperCase(),
          key: EGridTitleKey.STATUS,
        },
        {
          title: localisation.listHeader.lastEdit.toUpperCase(),
          key: EGridTitleKey.CREATED_AT,
        },
        {
          title: '',
          key: EGridTitleKey.CONTROL,
        },
      ],
      gridItems: [
        {
          key: EGridTitleKey.NAME,
        },
        {
          key: EGridTitleKey.CREATED_AT,
        },
        {
          key: EGridTitleKey.AMOUNT,
        },
        {
          key: EGridTitleKey.STATUS,
        },
        {
          key: EGridTitleKey.CONTROL,
        },
        {
          key: EGridTitleKey.PAYMENT_LINK,
        },
      ],
      mobileBreakpoints: [
        ESize.XXS,
        ESize.XS,
        ESize.S,
        ESize.M,
        ESize.L,
      ],
      filters: {
        searchFilter: '',
        orderBy: {
          title: localisation.filters.sortBy.variants.recentlyCreatedFirst,
          id: ESortDirection.DESC,
        },
      },
      orderByList: [
        {
          title: localisation.filters.sortBy.variants.recentlyCreatedFirst,
          id: ESortDirection.DESC,
        },
        {
          title: localisation.filters.sortBy.variants.recentlyCreatedLast,
          id: ESortDirection.ASC,
        },
      ],
      limit: 10,
      offset: 0,
      paymentLinkTotalCount: 0,
      searching: false,
      deleteModal: {
        title: localisation.deleteModal.title,
        linkToDelete: {} as IPaymentLinkBackendModel,
      },
    });

    const paymentLinkPage = new PaymentLinkPage(
      state,
      serviceContainer,
      vueInstance,
    );

    const currentBreakpoint = computed(() => store.getters['commonStatusesStore/getCurrentBreakpoint']);
    const isMobile = computed(() => state.mobileBreakpoints.includes(currentBreakpoint.value));

    const countText = computed(() => vueInstance.$tc(
      'common.item_counter',
      state.paymentLinkList.length,
    ));

    const merchantMode: EMerchantMode = store.getters['authStore/getMerchantMode'];
    const createButtonText = computed(() => localisation.buttons.create[merchantMode]);

    const merchantModeAlert = reactive({
      visible: true,
      variant: 'accent',
      title: localisation.testModeAlert.title,
      subtitle: localisation.testModeAlert.text,
    });

    const gridItems = computed(() => paymentLinkPage.getGridItems(isMobile.value));

    const handlerSearchFilterChange = async () => {
      const isSearchDiapasonReached = state.filters.searchFilter.length > 2 && state.filters.searchFilter.length <= 255;

      if (isSearchDiapasonReached || utilsService.data.isEmpty(state.filters.searchFilter)) {
        state.searching = true;
        await paymentLinkPage.refreshPaymentLinkList();
        state.searching = false;
      }
    };

    const searchInput = computed(() => ({
      label: isMobile.value ? '' : localisation.filters.search.label,
      placeholder: isMobile.value ? localisation.filters.search.label : localisation.filters.search.placeholder,
      icon: 'search',
      handler: {
        input: () => handlerSearchFilterChange(),
        clear: () => {
          state.filters.searchFilter = '';

          handlerSearchFilterChange();
        },
      },
    }));

    const sortBySelect = computed(() => ({
      label: isMobile.value ? '' : localisation.filters.sortBy.label,
      handler: () => handlerSearchFilterChange(),
    }));

    const headerText = computed(() => ({
      title: localisation.title,
      tooltip: localisation.tooltip ?? '',
    }));

    const getColumnClass = (item, isTitle = false) => [
      'payment-link-page__column',
      `payment-link-page__column_${item.key}`,
      isTitle ? 'payment-link-page__column_title' : null,
    ];

    const getStatusText = (link: IPaymentLinkBackendModel) => {
      const localisationMap = {
        [EPaymentLinkStatus.NOT_PAID]: localisation.paymentLinkStatus.notPaid,
        [EPaymentLinkStatus.PAID]: localisation.paymentLinkStatus.paid,
        [EPaymentLinkStatus.DELETED]: localisation.paymentLinkStatus.deleted,
      };

      return localisationMap[link.status];
    };

    const getProductsComputedInfo = (paymentLink) => {
      const createdAt = utilsService.date.formatSkipCurrentYearWithoutWeekDay(paymentLink.createdAt);
      const amount = numberFormatService.money(paymentLink.amount, paymentLink.currency);
      const status = getStatusText(paymentLink);

      return `${amount} • ${status} • ${createdAt}`;
    };

    const getShowControl = (link: IPaymentLinkBackendModel) => link.status === EPaymentLinkStatus.NOT_PAID;

    const showFilter = computed(
      () => state.paymentLinkList.length > 0 || state.filters.searchFilter.length > 0 || state.searching,
    );

    const showSearchClear = computed(() => state.filters.searchFilter.length > 0 && !state.searching);

    const statusWindow: ComputedRef<{
      title: string;
      subtitle: string;
      type: string;
      buttons?: {
        create: {
          text: string;
        };
      };
    }> = computed(() => {
      if (state.filters.searchFilter.length > 0) {
        return {
          title: localisation.statusWindow.notFound.title,
          subtitle: localisation.statusWindow.notFound.subtitle,
          type: 'empty_search',
        };
      }

      return {
        title: localisation.statusWindow.noLinks.title,
        subtitle: localisation.statusWindow.noLinks.subtitle,
        type: 'no_links',
        buttons: {
          create: {
            text: createButtonText.value,
          },
        },
      };
    });

    const handleScroll = (event) => {
      if (event.detail?.ref?.ps?.reach?.y === 'end' && state.paymentLinkTotalCount > state.paymentLinkList.length) {
        paymentLinkPage.updatePaymentLinkList();
      }
    };

    onMounted(async () => {
      await paymentLinkPage.refreshPaymentLinkList();

      merchantModeAlert.visible = getIsMerchantModeAlertVisible(EPage.PAYMENT_LINKS, merchantMode);

      state.isLoading = false;

      window.addEventListener('scroll', handleScroll);
    });

    const handleHowItWorksClick = () => {
      window.open(
        'https://docs.softline.com/ru-ru/payment',
        '_blank',
      );
    };

    const handleLinkCopy = () => {
      store.dispatch(
        'notificationsStore/pushNotification',
        {
          title: localisation.notification.copyLink,
          component: 'DSAlert',
          type: 'info',
        },
      );
    };

    const handleDelete = (paymentLink) => {
      state.showModal = true;
      state.deleteModal.linkToDelete = paymentLink;
    };

    const handleClickCloseMerchantModeAlert = () => {
      merchantModeAlert.visible = false;
      setMerchantModeAlertStatus(EPage.PAYMENT_LINKS, EMerchantModeAlertStatus.HIDDEN);
    };

    const handleClickContinue = async () => {
      const apiService = serviceContainer.apiService;

      state.isLoading = true;

      state.showModal = false;

      await apiService.paymentLink.delete(state.deleteModal.linkToDelete.id);

      await paymentLinkPage.refreshPaymentLinkList();

      state.isLoading = false;
    };

    const handleCloseModal = () => {
      state.showModal = false;
    };

    return {
      router,
      state,
      localisation,
      localisationCommon,
      utilsService,
      numberFormatService,
      gridItems,
      isMobile,
      headerText,
      merchantModeAlert,
      countText,
      createButtonText,
      searchInput,
      sortBySelect,
      statusWindow,
      showFilter,
      showSearchClear,
      getShowControl,
      getStatusText,
      getColumnClass,
      getProductsComputedInfo,
      handleHowItWorksClick,
      handleLinkCopy,
      handleDelete,
      handleClickCloseMerchantModeAlert,
      handleClickContinue,
      handleCloseModal,
    };
  },
});
