import IRESTApiMapper from '@/module/common/service/RESTApi/mappers/IRESTApiMapper';

import {
  IWidgetConfigFrontendModel,
  IWidgetConfigBackendModel,
  TModelWidgetConfig,
} from '@/module/dashboard/models/IWidgetConfig';

import RESTApiMapperEntityError from '@/module/common/service/RESTApi/mappers/RESTApiMapperEntityError';
import {
  getWidgetType,
} from '@/module/dashboard/view/DashboardOverviewPage/DashboardOverviewPage.func';
import IDashboardWidgetRequestBackendModel from '@/module/dashboard/models/IDashboardWidgetRequestBackendModel';
import IDashboardWidgetRequestFrontendModel from '@/module/dashboard/models/IDashboardWidgetRequestFrontendModel';
import cloneImmutable from '@/module/common/utils/data/cloneImmutable';

function mapWidgetConfigRequestToModel(widget: IWidgetConfigBackendModel): IWidgetConfigFrontendModel {
  return {
    id: widget.id,
    pageOrder: widget.pageOrder,
    showOnPage: widget.showOnPage,
    name: widget.name,
    type: getWidgetType(widget.name),
    isComparison: false,
    format: cloneImmutable(widget.format),
  };
}

function mapWidgetConfigModelToRequest(widget: TModelWidgetConfig): IWidgetConfigBackendModel {
  return {
    id: widget.id,
    pageOrder: widget.pageOrder,
    showOnPage: widget.showOnPage,
    name: widget.name,
    format: cloneImmutable(widget.format),
  };
}

function responseMapper(response) {
  const state = response?.data ?? {} as IDashboardWidgetRequestBackendModel;
  const dashboardState = state.dashboardState ?? [];
  const modelWidgets = dashboardState.map((widget) => mapWidgetConfigRequestToModel(widget));

  return Promise.resolve({
    ...response,
    data: {
      ...state,
      dashboardState: modelWidgets,
    },
  });
}

function entityMapper(state) {
  if (!state) {
    throw new RESTApiMapperEntityError('Entity is not a IDashboardWidgetRequestFrontendModel');
  }

  return {
    dashboardState: [mapWidgetConfigModelToRequest(state.config)],
  };
}

const widgetConfigMapper: IRESTApiMapper<IDashboardWidgetRequestBackendModel, IDashboardWidgetRequestFrontendModel> = {
  create: responseMapper,
  createEntity: entityMapper,
  get: responseMapper,
  list: responseMapper,
  update: responseMapper,
  updateEntity: entityMapper,
  replace: responseMapper,
  replaceEntity: entityMapper,
};

export default widgetConfigMapper;
