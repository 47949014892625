<template>
  <div
    class="checkout-link-preview"
  >
    <DSHeader
      :size="3"
      :text="title"
      class="checkout-link-preview__title"
    />
    <div
      class="checkout-link-preview__link"
    >
      <CCopyField
        :variant="copyFieldBorderColor"
        :show-icon="isLinkNotEmpty"
        :size="copyFieldSize"
        :text="copyFieldText"
        border-style="dashed"
        @copied="$emit('copied')"
      >
        <template
          #copy-field-text="slotProps"
        >
          <DSHeader
            v-if="link.link.length > 0 && !isMobileVersion"
            :text="slotProps.text"
            color="linked"
            size="2"
          />
          <DSTextElement
            v-else
            :color="checkoutLinkColor"
            variant="medium"
          >
            {{ slotProps.text }}
          </DSTextElement>
        </template>
      </CCopyField>

      <DSTextElement
        v-if="isLinkLengthError"
        color="error"
        class="c-input__comment"
        variant="small"
      >
        {{ linkLengthError }}
      </DSTextElement>
    </div>

    <div
      class="checkout-link-preview__iframe-wrapper"
    >
      <iframe
        :src="iframeURL"
        :title="title"
        class="checkout-link-preview__iframe"
        @load="handleIframeLoad"
      />

      <div
        v-if="isIframeLoading"
        class="checkout-link-preview__iframe-loader"
      >
        <PreloaderComponent
          height="125px"
          width="125px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CCopyField from '@/module/common/components/UI/CCopyField.vue';
import DSHeader from '@/module/design-system/components/Text/DSHeader.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import PreloaderComponent from '@/components/Preloader/PreloaderComponent.vue';

export default {
  name: 'CheckoutLinkPreview',
  components: {
    PreloaderComponent,
    CCopyField,
    DSHeader,
    DSTextElement,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    link: {
      type: Object,
      default: () => ({}),
    },
    emptyLinkText: {
      type: String,
      default: '',
    },
    basketUrl: {
      type: String,
      default: '',
    },
    linkLengthError: {
      type: String,
      default: '',
    },
    isMobileVersion: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iframeURL: '',
      isIframeLoading: true,
    };
  },
  computed: {
    isLinkNotEmpty() {
      return this.link.preview.length > 0;
    },
    copyFieldText() {
      return this.isLinkNotEmpty ? this.link.link : this.emptyLinkText;
    },
    isLinkLengthError() {
      return this.iframeURL.length >= 2000;
    },
    copyFieldBorderColor() {
      let variant = this.isLinkNotEmpty ? 'linked' : 'disabled';
      if (this.isLinkLengthError) {
        variant = 'error';
      }

      return variant;
    },
    checkoutLinkColor() {
      return this.link.link.length > 0 ? 'linked' : 'primary';
    },
    copyFieldSize() {
      return this.isMobileVersion ? 'small' : 'large';
    },
  },
  watch: {
    link() {
      this.updateIframeURL();
    },
  },
  mounted() {
    this.updateIframeURL();
  },
  methods: {
    updateIframeURL() {
      const iframeURL = this.link.preview.length > 0 ? this.link.preview : `${this.basketUrl}/?clear=Y`;
      this.isIframeLoading = iframeURL !== this.iframeURL;
      this.iframeURL = iframeURL;
    },
    handleIframeLoad() {
      this.isIframeLoading = false;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import "@/module/design-system/components/variables.scss";

.checkout-link-preview {
  position: sticky;
  padding: 32px 0 32px 32px;
  border-radius: 12px;
  background-color: #f6f8fa;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    content: '';
    width: 4000px;
    height: 100%;
    border-radius: 12px;
    background-color: #f6f8fa;
  }

  &__title {
    margin-bottom: 16px;
  }

  &__link {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;
  }

  &__iframe-wrapper {
    position: relative;
    width: 100%;
    height: 600px;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: 100%;
    }
  }

  &__iframe {
    width: 175%;
    height: 175%;
    border: none;
    border-radius: 12px;
    background: white;
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.1));

    zoom: 0.57;
    -moz-transform: scale(0.57);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.57);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.57);
    -webkit-transform-origin: 0 0;
  }

  &__iframe-loader {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: $surface-bg-color-regular;
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .checkout-link-preview {
    padding: 0 0 16px 0;

    &::before {
      position: fixed;
      top: 70px;
      left: 0;
      width: 100vw;
    }
  }
}
</style>
