import IState from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/IState';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';
import TTSApi from '@/module/common/tsApi/TTSApi';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import { Store } from 'vuex';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import VueRouter from 'vue-router';
import IScenario from '@/module/product/view/FulfillmentFormPage/model/contract/scenario/IScenario';
import ILocalServiceContainer from '@/module/product/view/FulfillmentFormPage/model/ILocalServiceContainer';
import IHandlerService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IHandlerService';
import ELocalServiceFulfillment from '@/module/product/view/FulfillmentFormPage/model/enums/ELocalServiceFulfillment';
import IMapperService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IMapperService';
import INotificationService from '@/module/common/service/NotificationService/INotificationService';
import ENotificationType from '@/module/common/service/NotificationService/model/ENotificationType';

class CreateGeneratorScenario implements IScenario {
  private readonly state: IState;

  private readonly serviceContainer: IServiceContainer;

  private readonly localServiceContainer: ILocalServiceContainer;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    localServiceContainer: ILocalServiceContainer,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.localServiceContainer = localServiceContainer;
  }

  run = (): void => {
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const formDataHandler = handlerService.getFormDataHandler();
    const formData = formDataHandler.getData(this.state.licenseSourceName);
    const licenseSourceHandler = handlerService.getLicenseSourceHandler();

    if (
      !licenseSourceHandler.isSalesCreateGenerator(formData)
      && !licenseSourceHandler.isTestCreateGenerator(formData)
    ) {
      return;
    }

    this.state.isLoading = true;

    const mapperService = this.localServiceContainer
      .resolve<IMapperService>(ELocalServiceFulfillment.MAPPER_SERVICE);
    const tsApi = this.serviceContainer.resolve<TTSApi>(EServiceContainerInstances.TS_API);
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const fromDataMapper = mapperService.getIFormDataToFulfillmentBackendModelMapper();
    const localisationRegistry: ILocalisationRegistry = store.getters.getLocalisationRegistry;

    const localisation = localisationRegistry.fulfillment.form.form.request;

    const entity = fromDataMapper.map(formData);

    if (this.isEditMode()) {
      const router = this.serviceContainer.resolve<VueRouter>(EServiceContainerInstances.ROUTER);

      tsApi.fulfillment.update(+router.currentRoute.params.id, entity)
        .then(() => this.successHandler(localisation.edit))
        .catch(this.errorHandler);
    } else {
      tsApi.fulfillment.create(entity)
        .then(() => this.successHandler(localisation.save))
        .catch(this.errorHandler);
    }
  };

  private isEditMode = (): boolean => {
    const router = this.serviceContainer.resolve<VueRouter>(EServiceContainerInstances.ROUTER);
    const isSameLicenseSourceName = this.state.initialLicenseSourceName === this.state.licenseSourceName;

    return !!router.currentRoute.params.id && isSameLicenseSourceName;
  };

  private successHandler = (message: string): void => {
    const router = this.serviceContainer
      .resolve<VueRouter>(EServiceContainerInstances.ROUTER);
    const store = this.serviceContainer
      .resolve<Store<unknown>>(EServiceContainerInstances.STORE);

    const notificationService = this.serviceContainer
      .resolve<INotificationService>(EServiceContainerInstances.NOTIFICATION_SERVICE);

    notificationService.push(
      ENotificationType.INFO_ALERT,
      {
        title: message,
      },
    );

    this.state.isLoading = false;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    store.dispatch('authStore/refreshInfo').catch(() => {});

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    router.push('/products').catch(() => {});
  };

  private errorHandler = (): void => {
    const notificationService = this.serviceContainer
      .resolve<INotificationService>(EServiceContainerInstances.NOTIFICATION_SERVICE);
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const localisationRegistry: ILocalisationRegistry = store.getters.getLocalisationRegistry;
    const popUpLocalisation = localisationRegistry.popUp;

    notificationService.push(
      ENotificationType.ERROR_POPUP,
      {
        text: popUpLocalisation.wentWrong,
        title: popUpLocalisation.error,
      },
    );

    this.state.isLoading = false;
  };
}

export default CreateGeneratorScenario;
