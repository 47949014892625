
import Vue, {
  computed,
  PropType,
} from 'vue';

import ENavigationPosition
  from '@/module/common/models/CFormPageHeaderRow/ENavigationPosition';
import TNavigationItem from '@/module/common/models/CFormPageHeaderRow/TNavigationItem';

import CPageTitle from '@/module/common/components/PageParts/CPageTitle.vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import {
  isButtonWithIcon,
} from '@/module/common/helpers/getButton';

export default Vue.extend({
  name: 'CFormPageHeaderRow',
  components: {
    DSIcon,
    DSButton,
    CPageTitle,
  },
  props: {
    pageTitle: {
      type: String,
      default: () => '',
    },
    navigationPosition: {
      type: String as PropType<ENavigationPosition>,
      default: ENavigationPosition.SIDE,
    },
    navigationLeft: {
      type: Array as PropType<TNavigationItem[]>,
      default: () => [],
    },
    navigationRight: {
      type: Array as PropType<TNavigationItem[]>,
      default: () => [],
    },
  },
  setup(props, { slots }) {
    const blockClass = 'header-row';

    const hasLeftSlot = computed(() => !!slots['button-left']);

    const hasRightSlot = computed(() => !!slots['button-right']);

    const hasMultipleButtonsInLeftColumn = computed(() => {
      const visibleButtons = props.navigationLeft.filter((item) => item.visible);

      return visibleButtons.length > 1;
    });

    const hasMultipleButtonsInRightColumn = computed(() => {
      const visibleButtons = props.navigationRight.filter((item) => item.visible);

      return visibleButtons.length > 1;
    });

    const mainClasses = computed(() => {
      const multipleButtons = hasMultipleButtonsInLeftColumn.value || hasMultipleButtonsInRightColumn.value;

      return [
        blockClass,
        `${blockClass}_variant_${props.navigationPosition}`,
        multipleButtons ? `${blockClass}_multiple-buttons` : null,
      ];
    });

    const navigationClasses = computed(() => {
      const multipleButtons = hasMultipleButtonsInLeftColumn.value || hasMultipleButtonsInRightColumn.value;

      return [
        `${blockClass}__navigation`,
        multipleButtons ? `${blockClass}__navigation_multiple-buttons` : null,
      ];
    });

    const leftColumnClasses = computed(() => {
      const multipleButtons = hasMultipleButtonsInLeftColumn.value;

      return [
        `${blockClass}__col`,
        `${blockClass}__col_left`,
        multipleButtons ? `${blockClass}__col_multiple-buttons` : null,
      ];
    });

    const rightColumnClasses = computed(() => {
      const multipleButtons = hasMultipleButtonsInRightColumn.value;

      return [
        `${blockClass}__col`,
        `${blockClass}__col_right`,
        multipleButtons ? `${blockClass}__col_multiple-buttons` : null,
      ];
    });

    return {
      hasLeftSlot,
      hasRightSlot,
      mainClasses,
      navigationClasses,
      leftColumnClasses,
      rightColumnClasses,
      isButtonWithIcon,
    };
  },
});
