export default function product(instance) {
  return {
    list(options) {
      const parts = [
        'sortByUpdateDate=desc',
      ];

      if (![null, undefined].includes(options.limit) && ![null, undefined].includes(options.offset)) {
        parts.push(`limit=${options.limit}`);
        parts.push(`offset=${options.offset}`);
      }

      if (options.searchString) {
        parts.push(`searchString=${options.searchString}`);
      }

      if (options.currency && options.currency.length > 0) {
        const currenciesParts = options.currency.map((currency) => `currency[]=${currency}`);
        parts.push(currenciesParts.join('&'));
      }

      if (options.ids && options.ids.length > 0) {
        const ids = options.ids.map((id) => `ids[]=${id}`);
        parts.push(ids.join('&'));
      }

      const query = parts.filter((part) => !!part).join('&');

      return instance.get(`/vendor/checkout-link/product?${query}`);
    },
  };
}
