enum EFileMimeTypes {
  JPG = 'image/jpeg',
  JPEG = 'image/jpeg',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PDF = 'application/pdf',
  PNG = 'image/png',
}

export default EFileMimeTypes;
