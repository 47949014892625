
import Vue, {
  computed,
  PropType,
  ref,
} from 'vue';
import generateUID from '@/module/common/utils/generate/generateUID';

export default Vue.extend({
  name: 'DSFileUploadArea',
  props: {
    value: {
      type: Object as PropType<FileList>,
      default: null,
    },
  },
  setup(props, { slots, emit }) {
    const element = ref<HTMLInputElement>();
    const uid = generateUID();
    const id = `ds-file-upload-area-area-${uid}`;

    const hasBeforeSlot = computed(() => !!slots.before);
    const hasAfterSlot = computed(() => !!slots.after);
    const hasDefaultSlot = computed(() => !!slots.default);

    function handleDrop(event: DragEvent) {
      event.preventDefault();
      const fileList = event.dataTransfer?.files ?? [];

      emit('input', Array.from(fileList));
    }

    function handleDragOver(event) {
      event.preventDefault();
    }

    function handleDragLeave(event) {
      event.preventDefault();
    }

    function handleInputChange(event) {
      const fileList = Array.from(event.target.files);
      event.target.value = null;

      emit('input', fileList);
    }

    return {
      hasAfterSlot,
      hasBeforeSlot,
      hasDefaultSlot,
      handleDrop,
      handleDragOver,
      handleDragLeave,
      handleInputChange,
      element,
      id,
    };
  },
});
