import IRESTApi from '@/module/common/service/RESTApi/contract/IRESTApi';
import EWidgetName from '@/module/dashboard/models/EWidgetName';
import TWidget, {
  TWidgetData,
} from '@/module/dashboard/models/IWidgetConfig';
import EWidgetType from '@/module/dashboard/models/EWidgetType';
import ICamelCaseLocalisationDashboardPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationDashboardPage';
import cloneImmutable from '@/module/common/utils/data/cloneImmutable';
import initChartWidget, {
  updateChartWidgetsData,
} from '@/module/dashboard/view/DashboardOverviewPage/DashboardOverviewPageWidgetChart.func';
import initPerformanceWidget, {
  updatePerformanceWidgetData,
} from '@/module/dashboard/view/DashboardOverviewPage/DashboardOverviewPageWidgetPerformance.func';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import {
  Store,
} from 'vuex';
import IDashboardWidgetRequestFrontendModel from '@/module/dashboard/models/IDashboardWidgetRequestFrontendModel';
import IDashboardFilter from '@/module/dashboard/models/IDashboardFilter';
import TTSApi from '@/module/common/tsApi/TTSApi';

function getWidgetType(name: EWidgetName): EWidgetType {
  const widgetTypesMap = {
    [EWidgetName.PERFORMANCE]: EWidgetType.TABLE,
    [EWidgetName.TRANSACTIONS]: EWidgetType.CHART,
    [EWidgetName.GROSS_REVENUE]: EWidgetType.CHART,
    [EWidgetName.REFUNDS]: EWidgetType.CHART,
    [EWidgetName.AVERAGE_TRANSACTION_AMOUNT]: EWidgetType.CHART,
  };

  return widgetTypesMap[name];
}

async function prepareWidgets(
  localisation: ICamelCaseLocalisationDashboardPage,
  serviceContainer: IServiceContainer,
) {
  const tsApi = serviceContainer.resolve<TTSApi>(EServiceContainerInstances.TS_API);
  const widgetConfigAPI = tsApi.widgetConfig as IRESTApi<IDashboardWidgetRequestFrontendModel>;

  const store = serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);

  const response = await widgetConfigAPI.get('');

  const updateDateString = response?.data?.reportsDataLastUpdate;
  const updateDate: Date = updateDateString ? new Date(updateDateString) : new Date();

  await store.dispatch('dashboardStore/updateDataUpdateDate', updateDate);

  const widgetConfigs = response?.data?.dashboardState ?? [];

  const widgets = widgetConfigs.map((widgetConfig): TWidget => {
    let newWidget: TWidget = {} as TWidget;

    widgetConfig.title = localisation.widgets[widgetConfig.name].title;
    widgetConfig.tooltip = localisation.widgets[widgetConfig.name].tooltip;

    if (widgetConfig.type === EWidgetType.CHART) {
      newWidget = initChartWidget(widgetConfig);
    }

    if (widgetConfig.name === EWidgetName.PERFORMANCE) {
      newWidget = initPerformanceWidget(localisation, widgetConfig);
    }

    return newWidget;
  });

  widgets.sort((widgetA, widgetB) => widgetA.config.pageOrder - widgetB.config.pageOrder);

  await store.dispatch('dashboardStore/updateWidgets', widgets);
}

function updateWidgets(
  widgets: TWidget[],
  data: TWidgetData,
  isComparison: boolean,
  widgetName: EWidgetName,
) {
  let widgetIndex = widgets.findIndex((widget) => widget.config?.name === widgetName);

  widgetIndex = widgetIndex !== -1 ? widgetIndex : widgets.length;

  const updatedWidget: TWidget = cloneImmutable(widgets[widgetIndex]);

  updatedWidget.config.isComparison = isComparison;
  updatedWidget.data = cloneImmutable(data);

  widgets[widgetIndex] = updatedWidget;

  return cloneImmutable(widgets);
}

async function saveWidgets(
  serviceContainer: IServiceContainer,
  initialWidgets: TWidget[],
  updatedWidgets: TWidget[],
) {
  const tsApi = serviceContainer.resolve<TTSApi>(EServiceContainerInstances.TS_API);
  const widgetConfigAPI = tsApi.widgetConfig as IRESTApi<TWidget>;

  const widgetsIds = initialWidgets.map((widget) => widget.config?.id);

  const widgetsToUpdate = widgetsIds.reduce((widgetsToUpdate: TWidget[], widgetId) => {
    const initialWidget = initialWidgets.find((widget) => widget.config?.id === widgetId);
    const updatedWidget = updatedWidgets.find((widget) => widget.config?.id === widgetId);

    if (updatedWidget && initialWidget?.config?.showOnPage !== updatedWidget?.config?.showOnPage) {
      widgetsToUpdate.push(updatedWidget);
    }

    return widgetsToUpdate;
  }, []);
  const promises = widgetsToUpdate.map((widget) => widgetConfigAPI.update(widget.config?.id, widget));

  return Promise.all(promises);
}

async function loadWidgetsConfig(
  localisation: ICamelCaseLocalisationDashboardPage,
  serviceContainer: IServiceContainer,
) {
  const store = serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);

  await store.dispatch('commonStatusesStore/updateLoading', true, { root: true });
  try {
    await prepareWidgets(localisation, serviceContainer);
  } catch (error) {
    await store.dispatch('commonStatusesStore/pushError', error, { root: true });
  } finally {
    await store.dispatch('commonStatusesStore/updateLoading', false, { root: true });
  }
}

async function loadWidgetsData(
  localisation: ICamelCaseLocalisationDashboardPage,
  serviceContainer: IServiceContainer,
  filter: IDashboardFilter,
) {
  const store = serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);

  await store.dispatch('commonStatusesStore/updateLoading', true, { root: true });

  let widgets = await store.getters['dashboardStore/getWidgets'];

  const widgetTypes = widgets.map((widget) => widget.config?.type);
  const widgetNames = widgets.map((widget) => widget.config?.name);

  if (widgetTypes.includes(EWidgetType.CHART)) {
    widgets = await updateChartWidgetsData(serviceContainer, localisation, widgets, filter);
  }

  if (widgetNames.includes(EWidgetName.PERFORMANCE)) {
    widgets = await updatePerformanceWidgetData(serviceContainer, localisation, widgets, filter);
  }

  await store.dispatch('dashboardStore/updateWidgets', widgets);

  await store.dispatch('commonStatusesStore/updateLoading', false, { root: true });
}

export {
  getWidgetType,
  prepareWidgets,
  updateWidgets,
  saveWidgets,
  loadWidgetsConfig,
  loadWidgetsData,
};
