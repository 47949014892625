<template>
  <div
    :class="inlineAlertClassList"
  >
    <div
      class="inline-alert__icon"
    >
      <DSIcon
        :color="iconColor"
      />
    </div>

    <div
      class="inline-alert__body"
    >
      <div
        class="inline-alert__title"
      >
        <DSTextElement
          variant="medium"
        >
          {{ title }}
        </DSTextElement>
      </div>
      <div
        class="inline-alert__subtitle"
      >
        <DSTextElement>
          <!-- eslint-disable vue/no-v-html -->
          <span
            v-html="subtitle"
          />
          <!-- eslint-enable vue/no-v-html -->
        </DSTextElement>
      </div>
    </div>

    <div
      v-if="showControls"
      class="inline-alert__controls"
    >
      <button
        v-if="!isAlertControlSlot"
        class="inline-alert__button"
        type="button"
        @click="button.handler"
      >
        {{ button.title }}
      </button>
      <slot
        name="alert-controls"
      />
    </div>
  </div>
</template>

<script>
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default {
  name: 'InlineAlert',
  components: {
    DSTextElement,
    DSIcon,
  },
  props: {
    title: {
      type: String,
      default: 'Start selling with us!',
    },
    subtitle: {
      type: String,
      default: 'Сreate a product and then the graphs will show the analytics for it',
    },
    showControls: {
      type: Boolean,
      default: true,
    },
    button: {
      type: Object,
      default: () => ({
        title: 'How to start?',
        // eslint-disable-next-line no-alert
        handler: () => alert('info button'),
      }),
    },
    variant: {
      type: String,
      default: 'info',
      validator: (variant) => [
        'info',
        'alert',
        'error',
        'accent',
      ].includes(variant),
    },
  },
  computed: {
    isAlertControlSlot() {
      return !!this.$scopedSlots['alert-controls'];
    },
    inlineAlertClassList() {
      return [
        'inline-alert',
        `inline-alert--variant-${this.variant}`,
      ];
    },
    iconColor() {
      const colorMap = {
        info: 'linked',
        error: 'error',
        alert: 'warning',
        accent: 'accent',
      };

      return colorMap[this.variant] ?? 'linked';
    },
  },
};
</script>

<style lang="scss">
.inline-alert {
  display: flex;
  width: 100%;
  padding: 16px;
  border: 1px solid;
  border-radius: 5px;

  &__body {
    width: 100%;
    margin: 0 16px;
  }

  &__controls {
    width: max-content;
  }

  &__subtitle {
    margin-top: 12px;
  }

  &__button {
    width: max-content;
    font-family: $text-ibm-plex-sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    border: 0;
    background-color: transparent;
  }

  // variant accent
  &--variant-accent {
    border-color: #e4f2fe;
    background-color: #e4eafe;
  }

  // variant info
  &--variant-info {
    border-color: #e4f2fe;
    background-color: #edf7fe;
  }

  &--variant-info &__icon svg {
    fill: #4397cb;
  }

  &--variant-info &__button {
    color: #4397cb;
  }

  // variant alert
  &--variant-alert {
    border-color: #fef0e4;
    background-color: #fef6ed;
  }

  &--variant-alert &__icon svg {
    fill: #f2994a;
  }

  &--variant-alert &__button {
    color: #f2994a;
  }

  // variant error
  &--variant-error {
    border-color: #fee4e4;
    background-color: #feeded;
  }

  &--variant-error &__icon svg {
    fill: #ff4444;
  }

  &--variant-error &__button {
    color: #ff4444;
  }
}
</style>
