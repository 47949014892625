import {
  clearMerchantModeAlert,
} from '@/module/common/utils/merchantModeAlert';

function getValueFromStorage(storageKey, defaultValue) {
  const jsonValue = localStorage.getItem(storageKey);
  let storageValue;
  try {
    storageValue = JSON.parse(jsonValue);
  } catch (e) {
    storageValue = jsonValue;
  }

  return storageValue !== null ? storageValue : defaultValue;
}

function putIntoStorage(storageKey, value) {
  let valueToStore = value;
  if (Array.isArray(valueToStore) || typeof valueToStore === 'object') {
    valueToStore = JSON.stringify(value);
  }

  localStorage.setItem(storageKey, valueToStore);
}

function getDefaultMerchantMode(allowedMerchantModes) {
  return allowedMerchantModes?.find((allowedMode) => allowedMode.name === 'sales') ? 'sales' : 'test';
}

export default (serviceContainer) => ({
  namespaced: true,
  state: {
    accessToken: null,
    adminAccessToken: null,
    loggingIn: false,
    loginError: null,
    user: null,
    merchantMode: getValueFromStorage('merchantMode', 'test'),
    allowedMerchantModes: (getValueFromStorage('allowedMerchantModes', [{ name: 'test', value: true }])),
    salesStatusActivationAvailable: getValueFromStorage('salesStatusActivationAvailable', true),
    esupportLink: null,
    logoutLink: null,
    salesArea: null,
    statusFullfilmentSupport: null,
    sellingCurrencies: [],
    priceCurrency: [],
  },
  mutations: {
    loginStart(state) {
      state.loggingIn = true;
    },
    loginStop(state, errorMessage) {
      state.loggingIn = false;
      state.loginError = errorMessage;
    },
    updateAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
    updateMerchantMode(state, merchantMode) {
      state.merchantMode = merchantMode;
      putIntoStorage('merchantMode', merchantMode);
    },
    updateAllowedMerchantModes(state, allowedMerchantModes) {
      state.allowedMerchantModes = allowedMerchantModes;
      putIntoStorage('allowedMerchantModes', allowedMerchantModes);
    },
    updateSalesStatusActivationAvailable(state, salesStatusActivationAvailable) {
      state.salesStatusActivationAvailable = salesStatusActivationAvailable;
      putIntoStorage('salesStatusActivationAvailable', salesStatusActivationAvailable);
    },
    updateEsupportLink(state, esupportLink) {
      state.esupportLink = esupportLink;
    },
    updateLogoutLink(state, logoutLink) {
      state.logoutLink = logoutLink;
    },
    logout(state) {
      state.accessToken = null;
    },
    setUserInfo(state, payload) {
      state.user = payload;
    },
    setSalesArea(state, payload) {
      state.salesArea = payload;
    },
    setSellingCurrencies(state, payload) {
      state.sellingCurrencies = payload;
    },
    setPriceCurrency(state, payload) {
      state.priceCurrency = payload;
    },
    setStatusFullfilmentSupport(state, payload) {
      state.statusFullfilmentSupport = payload;
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getMerchantMode(state) {
      return state.merchantMode;
    },
    getAllowedMerchantModes(state) {
      return state.allowedMerchantModes;
    },
    getIsMerchantActivated(state) {
      return state.allowedMerchantModes.findIndex((mode) => mode.name === 'sales') !== -1;
    },
    getSalesStatusActivationAvailable(state) {
      return state.salesStatusActivationAvailable;
    },
    getEsupportLink(state) {
      return state.esupportLink;
    },
    getLogoutLink(state) {
      return state.logoutLink;
    },
    getSalesArea(state) {
      return state.salesArea;
    },
    getSellingCurrencies(state) {
      return state.sellingCurrencies;
    },
    getLoginError(state) {
      return state.loginError;
    },
    getPriceCurrency(state) {
      return state.priceCurrency;
    },
    getStatusFullfilmentSupport(state) {
      return state.statusFullfilmentSupport;
    },
  },
  actions: {
    async doLogin({ commit }, payload) {
      commit('loginStart');
      const api = serviceContainer.resolve('api');
      const router = serviceContainer.resolve('router');

      try {
        const { data } = await api.auth.merchantLogin(payload);

        putIntoStorage('accessToken', data.accessToken);
        clearMerchantModeAlert();

        commit('updateAccessToken', data.accessToken);
        commit('updateMerchantMode', getDefaultMerchantMode(data.allowedVendorModes));
        commit('updateAllowedMerchantModes', data.allowedVendorModes);
        commit('updateSalesStatusActivationAvailable', data.salesStatusActivationAvailable);

        router.push('/').catch(() => null);
        setTimeout(() => {
          commit('loginStop', null);
        }, 1000);
      } catch (error) {
        commit('loginStop', error.response.data.error);
        commit('updateAccessToken', null);
      }
    },
    async getSSOLink() {
      try {
        const api = serviceContainer.resolve('api');

        const { data } = await api.auth.getMerchantSSOLink();
        return data.link;
      } catch (error) {
        return Promise.resolve({ error });
      }
    },
    async refreshInfo({ commit, dispatch }) {
      try {
        const api = serviceContainer.resolve('api');

        const { status, data } = await api.auth.merchantInfo();

        if (status === 200) {
          const {
            allowedVendorModes,
            salesStatusActiveAllowed,
            esupportLink,
            logoutLink,
            salesArea,
            country,
            sellingCurrencies,
            priceCurrencies,
            language,
            statusFullfilmentSupport,
          } = data;

          const newSalesArea = salesArea ?? getValueFromStorage('salesArea', 'global');

          dispatch('changeLocale', language ?? getValueFromStorage('locale', 'en'), { root: true });
          commit('setUserInfo', data);
          commit('updateAllowedMerchantModes', allowedVendorModes);
          commit('updateSalesStatusActivationAvailable', salesStatusActiveAllowed);
          commit('updateEsupportLink', esupportLink);
          commit('updateLogoutLink', logoutLink);
          commit('setSalesArea', newSalesArea);
          commit('setSellingCurrencies', sellingCurrencies);
          commit('setPriceCurrency', priceCurrencies);
          commit('setStatusFullfilmentSupport', statusFullfilmentSupport);
          commit('setCountry', country, { root: true });
          if (newSalesArea) {
            localStorage.setItem('salesArea', newSalesArea);
          }
        }
      } catch (e) {
        localStorage.removeItem('accessToken');
        commit('setUserInfo', null);
      }
    },
    changeSalesArea({ commit }, portalRegionalMode) {
      commit('setPortalRegionalMode', portalRegionalMode);
    },
    fetchAccessToken({ commit }) {
      commit('updateAccessToken', localStorage.getItem('accessToken'));
    },
    logout({ commit }) {
      const router = serviceContainer.resolve('router');

      commit('logout');
      router.push('/login').catch(() => null);
    },
  },
});
