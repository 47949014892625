<template>
  <div class="header-shimer">
    <ShimmerLoader
      class="header-shimer__shimer"
      :class="'header-shimer__shimer--size-' + size "
    />
    <ShimmerLoader class="header-shimer__shimer" />
  </div>
</template>

<script>
import ShimmerLoader from './ShimerLoader.vue';

export default {
  name: 'HeaderShimer',
  components: {
    ShimmerLoader,
  },
  props: {
    size: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss">
  .header-shimer {
    display: grid;
    grid-template-rows: auto 24px 80px;
    grid-template-columns: 2fr 1fr;

    &__shimer {
      height: 100%;
    }

    &__shimer:last-child {
      grid-row: -1 / -2;
      grid-column: 1 / -1;
    }

    &__shimer--size-1 {
      height: 58px;
    }

    &__shimer--size-2 {
      height: 32px;
    }
  }
</style>
