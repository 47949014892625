<template>
  <div class="error-component">
    <section class="error-component__section">
      <div class="error-component__title">
        <DSIcon
          icon="upload"
          size="xxl"
        />

        <HeaderText
          size="3"
          :text="$t(title)"
        />
        <p class="secondary">
          {{ $t(subtitle) }}
        </p>

        <ButtonComponent
          v-if="button"
          class="error-component__button"
          @click="button.handler"
        >
          {{ button.title }}
        </ButtonComponent>
        <ButtonComponent
          v-if="buttonAdditional"
          variant="secondary"
          class="error-component__button--additional"
          @click="handleClickToExternalLink(buttonAdditional.path)"
        >
          <template #icon-before>
            <i>
              <DSIcon
                icon="arrowInsideCircle"
                color="linked"
              />
            </i>
          </template>
          {{ $t(buttonAdditional.title) }}
        </ButtonComponent>
      </div>
    </section>
  </div>
</template>

<script>
import HeaderText from '@/components/HeaderText.vue';
import ButtonComponent from '@/components/Button/ButtonComponent.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';

export default {
  name: 'ErrorComponent',
  components: {
    HeaderText,
    DSIcon,
    ButtonComponent,
  },
  props: {
    title: {
      type: String,
      default: 'product.no_products_message',
    },
    subtitle: {
      type: String,
      default: 'product.try_change_request',
    },
    button: {
      type: Object,
      default: null,
    },
    buttonAdditional: {
      type: Object,
      default: null,
    },
  },
  methods: {
    handleClickToExternalLink(path) {
      window.open(path, '_blank');
    },
  },
};
</script>

<style lang="scss">
.error-component {

  &__section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 21px;
    background-color: $header-background;
    border-radius: 48px;
  }

  &__title {
    margin: 25px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;

    svg {
      margin-bottom: 25px;
    }

    h3, p {
      text-align: center;
    }
  }

  &__icon {
    padding-left: 10px;
    text-align: center;

    svg path {
      fill: #C7C7CD;
    }
  }

  &__button {
    margin-top: 32px;
    width: fit-content;
    justify-content: center;

    &--additional {
      display: flex;
      align-items: center;
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 370px) {
  .error-component__section {
    padding: 10px;
  }
}
</style>
