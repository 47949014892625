<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      d="M11.9999 14.1982L6.63988 9.73223C6.53952 9.64443 6.42262 9.57759 6.29605 9.53564C6.16948 9.49369 6.0358 9.47748 5.90287 9.48796C5.76994 9.49843 5.64045 9.53539 5.52201 9.59665C5.40357 9.65791 5.29859 9.74224 5.21322 9.84468C5.12786 9.94712 5.06385 10.0656 5.02495 10.1931C4.98605 10.3207 4.97305 10.4547 4.98671 10.5873C5.00038 10.72 5.04043 10.8486 5.10452 10.9655C5.1686 11.0824 5.25543 11.1853 5.35988 11.2682L11.3599 16.2682C11.5396 16.4179 11.766 16.4999 11.9999 16.4999C12.2337 16.4999 12.4602 16.4179 12.6399 16.2682L18.6399 11.2682C18.7443 11.1853 18.8312 11.0824 18.8952 10.9655C18.9593 10.8486 18.9994 10.72 19.0131 10.5873C19.0267 10.4547 19.0137 10.3207 18.9748 10.1931C18.9359 10.0656 18.8719 9.94712 18.7865 9.84468C18.7012 9.74224 18.5962 9.65791 18.4778 9.59665C18.3593 9.53539 18.2298 9.49843 18.0969 9.48796C17.964 9.47748 17.8303 9.49369 17.7037 9.53564C17.5771 9.57759 17.4602 9.64443 17.3599 9.73223L11.9999 14.1982Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSArrowDownIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>
