
import Vue, {
  computed,
  inject,
} from 'vue';
import {
  Store,
} from 'vuex';

import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';

import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IUtils from '@/plugins/utils/IUtils';

import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import CDropdownMenu from '@/module/common/components/UI/CDropdownMenu.vue';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';

export default Vue.extend({
  name: 'CHelpWidget',
  components: {
    DSIcon,
    CDropdownMenu,
  },
  setup() {
    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;
    const utils = serviceContainer.resolve<IUtils>(EServiceContainerInstances.UTILS);
    const utilsService = serviceContainer.utilsService;
    const store = serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const localisationRegistry: ILocalisationRegistry = store.getters.getLocalisationRegistry;
    const portalConfigService = serviceContainer.portalConfigService;

    const isMerchantActivated = store.getters['authStore/getIsMerchantActivated'];

    const menuItemListHover = computed(() => [
      [
        {
          type: 'text',
          title: localisationRegistry.helpWidget.helpAndResources.title,
        },
      ],
    ]);

    const menuItemListFull = computed(() => [
      [
        {
          type: 'link',
          title: localisationRegistry.helpWidget.gettingStarted.title,
          url: localisationRegistry.helpWidget.gettingStarted.url,
          target: '_blank',
        },
        {
          type: 'link',
          title: localisationRegistry.helpWidget.knowledgeBase.title,
          url: localisationRegistry.helpWidget.knowledgeBase.url,
          target: '_blank',
        },
        {
          type: 'link',
          title: localisationRegistry.helpWidget.apiReference.title,
          url: localisationRegistry.helpWidget.apiReference.url,
          target: '_blank',
        },
      ],
      [
        {
          type: 'link',
          title: localisationRegistry.helpWidget.privacyPolicy.title,
          url: localisationRegistry.helpWidget.privacyPolicy.url,
          target: '_blank',
        },
        {
          type: 'link',
          title: localisationRegistry.helpWidget.termsOfService.title,
          url: portalConfigService.get().documents.terms_of_service,
          download: localisationRegistry.helpWidget.termsOfService.title,
        },
      ].filter((linkConfig) => !!linkConfig),
      [
        {
          type: 'email',
          title: localisationRegistry.helpWidget.shareFeedback.title,
          email: localisationRegistry.helpWidget.shareFeedback.email,
          url: localisationRegistry.helpWidget.shareFeedback.url,
        },
        {
          type: 'email',
          title: localisationRegistry.helpWidget.contactSupport.title,
          email: utilsService.email.getSupportEmail(isMerchantActivated),
          url: utils.link.generateLinkHref(
            utilsService.email.getSupportEmail(isMerchantActivated),
            'email',
          ),
        },
      ],
    ]);

    return {
      utilsService,
      menuItemListHover,
      menuItemListFull,
    };
  },
});
