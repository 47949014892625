<template>
  <div
    :class="classes"
    @click="$emit('click', $event)"
    @mouseenter="handleMouseenter"
    @mouseleave="handleMouseleave"
  >
    <DSIcon
      :color="color"
      :icon="icon"
    />
  </div>
</template>

<script>
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';

export default {
  name: 'CHeaderButton',
  components: {
    DSIcon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    classes() {
      return [
        'c-header-button',
        this.isActive ? 'c-header-button_active' : null,
        this.isHovered ? 'c-header-button_hover' : null,
      ];
    },
    color() {
      return this.isActive || this.isHovered ? 'menu-active' : 'surface-icons';
    },
  },
  methods: {
    handleMouseenter() {
      this.isHovered = true;
    },
    handleMouseleave() {
      this.isHovered = false;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.c-header-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;

  &_active,
  &_hover {
    background-color: #393939;
  }
}
</style>
