<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="6" width="19" height="4" fill="white" />
    <rect x="3" y="10" width="19" height="4" fill="#0056D7" />
    <path d="M3 14H22V16C22 17.1046 21.1046 18 20 18H5C3.89543 18 3 17.1046 3 16V14Z" fill="#FF0606" />
    <rect x="3.5" y="6.5" width="18" height="11" rx="1.5" stroke="#E6E6E6" />
  </svg>
</template>

<script>
import {
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'DSFlagRu',
});
</script>
