enum EBreakPoint {
  XXL = 'xxl',
  XL = 'xl',
  LG = 'lg',
  MD = 'md',
  SM = 'sm',
  XS = 'xs',
  XXS = 'xxs',
}

export default EBreakPoint;
