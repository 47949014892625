import IArrayTool from '@/module/common/service/UtilsService/contract/IArrayTool';
import IUtilsService from '@/module/common/service/UtilsService/contract/IUtilsService';

class ArrayTool implements IArrayTool {
  private readonly utils: IUtilsService;

  constructor(utils: IUtilsService) {
    this.utils = utils;
  }

  isEmpty = (array: unknown[]): boolean => array.length === 0;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  diff = (arr1: unknown[], arr2: unknown[]): unknown[] => [];
}

export default ArrayTool;
