
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  watch,
} from 'vue';
import EColorScheme from '@/module/common/models/EColorScheme';
import DSLabel from '@/module/design-system/components/UI/DSLabel.vue';
import DSSwitcher from '@/module/design-system/components/UI/DSSwitcher.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

interface IState {
  localValue: boolean,
}

export default defineComponent({
  name: 'CSwitcherField',
  components: {
    DSTextElement,
    DSLabel,
    DSSwitcher,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    color: {
      type: String as PropType<EColorScheme>,
      default: () => EColorScheme.PRIMARY,
    },
    prevent: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit, slots }) {
    const state: IState = reactive<IState>({
      localValue: false,
    });

    const textColor = computed(() => (props.color === EColorScheme.PRIMARY
      ? EColorScheme.SECONDARY
      : EColorScheme.MENU));
    const hasSlot = computed(() => !!slots['switcher-label']);

    function handleChange(event) {
      emit('change', event);
    }

    onMounted(() => {
      state.localValue = props.value;
    });

    watch(props, (newValue) => {
      state.localValue = newValue.value;
    });

    return {
      state,
      textColor,
      hasSlot,
      handleChange,
    };
  },
});
