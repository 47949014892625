export default (serviceContainer) => ({
  namespaced: true,
  state: {
    notifications: new Map(),
  },
  getters: {
    getNotifications(state) {
      return state.notifications;
    },
  },
  mutations: {
    setNotifications(state, value) {
      state.notifications = value;
    },
  },
  actions: {
    pushNotification({ commit, getters, dispatch }, notification) {
      const utils = serviceContainer.resolve('utilsService');

      const notifications = new Map(getters.getNotifications.entries());
      notifications.set(notification, notification);

      const delay = utils.typeCheck.isUndefined(notification.delay) ? 3000 : notification.delay;

      if (delay !== null) {
        setTimeout(() => {
          dispatch('deleteNotification', notification);
        }, delay);
      } else {
        dispatch('deleteNotification', notification);
      }

      commit('setNotifications', notifications);
    },
    deleteNotification({ commit, getters }, notification) {
      const notifications = new Map(getters.getNotifications.entries());
      notifications.delete(notification);

      commit('setNotifications', notifications);
    },
  },
});
