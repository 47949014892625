import EFieldName from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/enum/EFieldName';
import EProtocols from '@/module/common/models/EProtocols';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import EValidatorType from '@/module/common/utils/validator/enum/EValidatorType';

import IConfig from '@/module/common/components/FormComponent/ValidatorHandler/contract/IConfig';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IValidator from '@/module/common/utils/validator/contract/IValidator';
import IVueInstance from '@/module/common/models/IVueInstance';

import TValidatorConfig from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/type/TValidatorConfig';

import Validator from '@/module/common/utils/validator/Validator';
import ValidatorException from '@/module/product/__tests__/FulfillmentFormPage/exception/ValidatorException';

class ValidationConfig implements IConfig<TValidatorConfig, EFieldName, undefined> {
  private serviceContainer: IServiceContainer;

  constructor(
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
  }

  getConfig(): TValidatorConfig {
    const vueInstance = this.serviceContainer.resolve<IVueInstance>(EServiceContainerInstances.VUE_INSTANCE);

    const localisationRegistry = this.serviceContainer.localisationRegistry;
    const localisation = localisationRegistry.validationMessages;

    const nameValidator = new Validator();
    nameValidator.addValidator(
      EValidatorType.NOT_EMPTY,
      localisation.required,
    );
    nameValidator.addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      255,
    );

    const amountValidator = new Validator();
    amountValidator.addValidator(
      EValidatorType.NOT_EMPTY,
      localisation.required,
    );
    amountValidator.addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      255,
    );

    const currencyValidator = new Validator();
    currencyValidator.addValidator(
      EValidatorType.NOT_EMPTY,
      localisation.required,
    );

    const paymentMethodValidator = new Validator();
    paymentMethodValidator.addValidator(
      EValidatorType.NOT_EMPTY,
      localisation.required,
    );

    const paymentIdValidator = new Validator();
    paymentIdValidator.addValidator(
      EValidatorType.NOT_EMPTY,
      localisation.required,
    );
    paymentIdValidator.addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      180,
    );

    const paymentDescriptionValidator = new Validator();
    paymentDescriptionValidator.addValidator(
      EValidatorType.NOT_EMPTY,
      localisation.required,
    );
    paymentDescriptionValidator.addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      255,
    );

    const returnSuccessUrlValidator = new Validator();
    returnSuccessUrlValidator.addValidator(
      EValidatorType.NOT_EMPTY,
      localisation.required,
    );
    returnSuccessUrlValidator.addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      255,
    );
    returnSuccessUrlValidator.addValidator(
      EValidatorType.URL,
      localisation.url,
      [EProtocols.HTTPS, EProtocols.HTTP],
    );

    const customerFirstNameValidator = new Validator();
    customerFirstNameValidator.addValidator(
      EValidatorType.NOT_EMPTY,
      localisation.required,
    );
    customerFirstNameValidator.addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 180 }),
      180,
    );

    const customerLastNameValidator = new Validator();
    customerLastNameValidator.addValidator(
      EValidatorType.NOT_EMPTY,
      localisation.required,
    );
    customerLastNameValidator.addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 180 }),
      180,
    );

    const customerEmailValidator = new Validator();
    customerEmailValidator.addValidator(
      EValidatorType.NOT_EMPTY,
      localisation.required,
    );
    customerEmailValidator.addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 80 }),
      80,
    );
    customerEmailValidator.addValidator(
      EValidatorType.EMAIL,
      localisation.invalidFormat,
    );

    return {
      [EFieldName.NAME]: nameValidator,
      [EFieldName.PAYMENT_DESCRIPTION]: paymentDescriptionValidator,
      [EFieldName.AMOUNT]: amountValidator,
      [EFieldName.CURRENCY]: currencyValidator,
      [EFieldName.PAYMENT_METHOD]: paymentMethodValidator,
      [EFieldName.RETURN_SUCCESS_URL]: returnSuccessUrlValidator,
      [EFieldName.PAYMENT_ID]: paymentIdValidator,
      [EFieldName.CUSTOMER_FIRST_NAME]: customerFirstNameValidator,
      [EFieldName.CUSTOMER_LAST_NAME]: customerLastNameValidator,
      [EFieldName.CUSTOMER_EMAIL]: customerEmailValidator,
    };
  }

  getValidator(fieldName: EFieldName): IValidator {
    const config = this.getConfig();

    if (!config[fieldName]) {
      throw new ValidatorException(`Validator for field '${fieldName}' not exists.`);
    }

    return config[fieldName];
  }

  hasValidator(fieldName: EFieldName): boolean {
    const config = this.getConfig();

    return !!config[fieldName];
  }
}

export default ValidationConfig;
