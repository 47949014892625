<template>
  <div
    ref="rootRef"
    v-click-outside="handleOutsideClick"
    :class="dropdownClass"
  >
    <LabelComponent
      v-if="label"
      :label-text="label"
      :tooltip="tooltip"
    />
    <div class="dropdown__body">
      <div
        :class="dropdownInputClass"
        @click="toggle"
      >
        <div class="dropdown__dummy-input">
          {{ countForTranslatePluralization
            ? isLocalized ? $tc(inputValue, countForTranslatePluralization) : inputValue
            : isLocalized ? $t(inputValue) : inputValue }}
        </div>
        <template v-if="clearable">
          <template v-if="isClearable">
            <div
              class="dropdown-input__close"
              @click.stop="handleClear"
            >
              <DSIcon
                icon="crossInsideCircle"
                size="s"
              />
            </div>
          </template>
        </template>
        <div :class="dropdownIconClass">
          <DSIcon
            icon="arrowDown"
          />
        </div>
      </div>
      <template v-if="hasOptions">
        <transition name="fade">
          <div
            v-show="isShowOptions"
            ref="optionsRef"
            :class="dropdownOptionsClass"
          >
            <template v-if="hasSearch">
              <div class="dropdown-options__search">
                <input
                  v-model="searchValue"
                  placeholder="Поиск"
                >
                <div class="dropdown-options__search-icon">
                  <DSIcon
                    icon="search"
                  />
                </div>
                <div
                  v-show="isClearableSearchInput"
                  class="dropdown-options__clear-icon"
                  @click="handleClearSearch"
                >
                  <DSIcon
                    icon="cross"
                    size="s"
                  />
                </div>
              </div>
            </template>
            <perfect-scrollbar ref="scrollRef">
              <template v-if="multiple">
                <ul :class="dropdownOptionsListClass">
                  <template v-if="hasPlaceholder">
                    <li class="dropdown-options-list__item dropdown-options-list-item">
                      <span class="dropdown-options-list-item__placeholder">
                        Ничего не найдено
                      </span>
                    </li>
                  </template>
                  <template v-else>
                    <template v-for="option in visibleOptions">
                      <li
                        :key="optionId(option)"
                        :class="dropdownOptionClass"
                      >
                        <c-checkbox
                          :model-value="modelValue"
                          :input-value="option"
                          :label="$t(optionValue(option))"
                          :binary="false"
                          @update:modelValue="handleCheckOption"
                        />
                      </li>
                    </template>
                  </template>
                </ul>
              </template>
              <template v-else>
                <ul :class="dropdownOptionsListClass">
                  <template v-if="hasPlaceholder">
                    <li class="dropdown-options-list__item dropdown-options-list-item">
                      <span class="dropdown-options-list-item__placeholder">
                        Ничего не найдено
                      </span>
                    </li>
                  </template>
                  <template v-else>
                    <template v-for="option in visibleOptions">
                      <li
                        :key="optionId(option)"
                        :class="dropdownOptionClass"
                      >
                        <a
                          href="#"
                          class="dropdown-options-list-item__link"
                          @click.prevent="handleOptionClick(option)"
                        >
                          <div
                            v-if="checkboxed"
                            class="dropdown-options-list-item__checkbox"
                            :class="modelValue === option ? 'dropdown-options-list-item__checkbox--checked' : 0"
                          />
                          {{ countForTranslatePluralization
                            ? isLocalized ? $tc(optionValue(option), countForTranslatePluralization) : option
                            : isLocalized ? $t(optionValue(option)) : option }}
                        </a>
                      </li>
                    </template>
                  </template>
                </ul>
              </template>
            </perfect-scrollbar>
          </div>
        </transition>
      </template>
    </div>
  </div>
</template>

<script>
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import LabelComponent from '@/components/LabelComponent.vue';
import CheckboxComponent from '../Checkbox/CheckboxComponent.vue';

const MAX_OPTIONS_HEIGHT = 600;
const MIN_OPTIONS_FOR_SEARCH = 10;
const MIN_STRING_LENGTH_FOR_CLEAR = 1;

export default {
  name: 'DropdownComponent',
  components: {
    DSIcon,
    LabelComponent,
    cCheckbox: CheckboxComponent,
  },
  props: {
    isLocalized: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: [String, Array],
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    modelValueCount: {
      type: String || Number,
      default: null,
    },
    optionLabel: {
      type: String,
      default: 'title',
    },
    label: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Не выбрано',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    blockDropTop: {
      type: Boolean,
      default: false,
    },
    isDropToTopBlocked: {
      type: Boolean,
      default: false,
    },
    checkboxed: {
      type: Boolean,
      default: false,
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isShowOptions: false,
      isDropdownTop: false,
      multipleSelected: [],
      visibleOptions: [],
      searchValue: '',
    };
  },
  computed: {
    countForTranslatePluralization() {
      if (this.$utils.data.hasValue(this.modelValueCount)) {
        return this.modelValueCount.length > 0 ? this.modelValueCount : 1;
      }

      return this.modelValueCount;
    },
    dropdownClass() {
      return [
        'dropdown',
        this.small ? 'dropdown__small' : 'dropdown__medium',
      ];
    },
    dropdownIconClass() {
      return [
        'dropdown-input__icon',
        { 'dropdown-input__icon_reversed': this.isShowOptions },
      ];
    },
    dropdownOptionsClass() {
      return [
        'dropdown__options',
        'dropdown-options',
        this.isDropdownTop && !this.isDropToTopBlocked
          ? 'dropdown__options_top'
          : 'dropdown__options_bottom',
      ];
    },
    hasOptions() {
      return !!this.options.length;
    },
    hasScroll() {
      return this.visibleOptions.length > MIN_OPTIONS_FOR_SEARCH;
    },
    hasPlaceholder() {
      return !this.visibleOptions.length && this.hasSearch;
    },
    isClearable() {
      if (!Array.isArray(this.modelValue) && this.modelValue) {
        return true;
      }
      return !!(Array.isArray(this.modelValue) && this.modelValue.length);
    },
    isClearableSearchInput() {
      return this.searchValue.length >= MIN_STRING_LENGTH_FOR_CLEAR;
    },
    inputValue() {
      if (Array.isArray(this.modelValue)) {
        return this.modelValue
          .map(
            (i) => (typeof i === 'string' ? this.$t(i) : i[this.optionLabel]),
          )
          .join(', ');
      }
      if (typeof this.modelValue === 'object' && this.modelValue !== null) {
        const obj = this.modelValue;
        return obj[this.optionLabel];
      }
      return this.modelValue;
    },
    dropdownInputClass() {
      return [
        { 'dropdown-input__opened': this.isShowOptions.value },
        { 'dropdown-input__clearable': this.clearable },
        'dropdown__input',
        'dropdown-input',
      ];
    },
    dropdownOptionsListClass() {
      return [
        'dropdown-options__list',
        'dropdown-options-list',
        this.hasScroll ? 'dropdown-options-list__scrolled' : '',
      ];
    },
    dropdownOptionClass() {
      return [
        'dropdown-options-list__item',
        'dropdown-options-list-item',
        { 'dropdown-options-list-item__multiple': this.multiple },
      ];
    },
  },
  watch: {
    searchValue() {
      this.handleUpdateSelected();
    },
    modelValue() {
      this.handleUpdateSelected();
    },
  },
  mounted() {
    this.isDropdownTop = this.calculateOffset() < MAX_OPTIONS_HEIGHT - 100;
    this.visibleOptions = [...this.options];
    this.multipleSelected = Array.isArray(this.modelValue) ? [...this.modelValue] : [];
    this.$emit('mounted');
  },
  methods: {
    optionId(option) {
      return typeof (option) === 'string' ? option : option.id;
    },
    handleClear() {
      if (this.multiple) {
        this.multipleSelected.value = [];
        this.$emit('update:modelValue', []);
      } else {
        this.$emit('update:modelValue', '');
      }
    },
    clearDropDown() {
      if (!this.multipleSelected.length) {
        this.visibleOptions = [...this.options];
        this.searchValue = '';
      }
    },
    scrollToTop() {
      if (this.scrollRef.value) {
        this.scrollRef.value.$el.scrollTop = 0;
      }
    },
    sortOptions() {
      if (
        this.isShowOptions.value
        && this.visibleOptions.value.length
        && this.multiple
        && Array.isArray(this.modelValue)
      ) {
        const modelValue = this.modelValue;
        return this.visibleOptions.value.sort((a, b) => {
          if (typeof a === 'string' && typeof b === 'string') {
            return Number(this.modelValue.includes(b)) - Number(this.modelValue.includes(a));
          }
          if (typeof a === 'object' && typeof b === 'object') {
            return Number(!!modelValue.filter(
              (i) => i[this.optionLabel] === b[this.optionLabel],
            ).length)
              - Number(!!modelValue.filter(
                (i) => i[this.optionLabel] === a[this.optionLabel],
              ).length);
          }
          return 1;
        });
      }
      return false;
    },
    toggle() {
      this.isShowOptions = !this.isShowOptions;
      this.searchValue = '';

      const wrapper = document.querySelector('.app-layout__content.ps');
      this.isDropdownTop = wrapper.clientHeight + wrapper.scrollTop - this.$refs.rootRef.offsetTop < 400;
      // this.nextTick(this.scrollToTop)
    },
    handleOptionClick(option) {
      this.$emit('update:modelValue', option);
      this.isShowOptions = false;
    },
    handleCheckOption(event) {
      this.$emit('update:modelValue', event);
    },
    handleOutsideClick() {
      this.isShowOptions = false;
      setTimeout(this.clearDropDown, 500);
    },
    calculateOffset() {
      const body = document.querySelector('body');
      const windowHeight = body ? body.clientHeight : 0;
      const elemOffset = this.$refs.rootRef ? this.$refs.rootRef.getBoundingClientRect().y : 0;
      return windowHeight - elemOffset;
    },
    handleUpdateSearch() {
      this.visibleOptions.value = this.options.filter((option) => {
        if (typeof (option) === 'string') {
          return option.toLowerCase().includes(this.searchValue.value.toLowerCase());
        }
        if (typeof (option[this.optionLabel]) === 'string') {
          const optionLabel = option[this.optionLabel];
          return optionLabel.toLowerCase().includes(this.searchValue.value.toLowerCase());
        }
        return true;
      });
    },
    handleClearSearch() {
      this.searchValue.value = '';
      setTimeout(this.sortOptions, 0);
    },
    handleUpdateSelected() {
      if (this.multiple && !this.modelValue.length && this.isShowOptions.value) {
        this.isShowOptions.value = false;
      }
      if (!this.modelValue && this.isShowOptions.value) {
        this.isShowOptions.value = false;
      }
    },
    optionValue(option) {
      return typeof (option) === 'string' ? option : option[this.optionLabel];
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
