import RESTApi from '@/module/common/service/RESTApi/RESTApi';
import RESTApiDecoratorMap from '@/module/common/service/RESTApi/RESTApiDecoratorMap';
import IApiKeysModel from '@/module/developers/models/IApiKeysModel';
import RegistryModel from '@/module/product/model/RegistryModel';
import IFileStorageFrontendModel from '@/module/merchant/models/IFileStorageFrontendModel';
import {
  AxiosInstance,
} from 'axios';
import widgetConfigMapper from '@/module/common/service/RESTApi/mappers/widgetConfigMapper';
import IDashboardWidgetRequestBackendModel from '@/module/dashboard/models/IDashboardWidgetRequestBackendModel';
import IDashboardWidgetRequestFrontendModel from '@/module/dashboard/models/IDashboardWidgetRequestFrontendModel';
import IChartDataResponse from '@/module/dashboard/models/ChartDataResponse/IChartDataResponse';
import TTSApi from '@/module/common/tsApi/TTSApi';
import EApiName from '@/module/common/tsApi/EApiName';
import IFulfillmentBackendModel
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/IFulfillmentBackendModel';
import IFulfillmentParametersResponseModel
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/IFulfillmentParametersResponseModel';
import IKontragentioIndividual from '@/module/merchant/models/Kontragentio/IKontragentioIndividual';
import IKontragentioCompany from '@/module/merchant/models/Kontragentio/IKontragentioCompany';

export default function initTsApi(instance: AxiosInstance): TTSApi {
  const widgetConfigInstance = new RESTApi<IDashboardWidgetRequestBackendModel>(instance, 'vendor/dashboard/state');

  return {
    [EApiName.API_KEYS]: new RESTApi<IApiKeysModel>(instance, 'vendor/api-keys'),
    [EApiName.COMPANY_INFO]: new RESTApi<IKontragentioIndividual | IKontragentioCompany>(
      instance,
      'vendor/company-info',
    ),
    [EApiName.FILES_STORAGE]: new RESTApi<IFileStorageFrontendModel>(instance, 'vendor/files-storage'),
    [EApiName.PRODUCT_REGISTRY_VALIDATOR]: new RESTApi<RegistryModel>(instance, 'vendor/russian-software/check'),
    [EApiName.WIDGET_CONFIG]: new RESTApiDecoratorMap<
      IDashboardWidgetRequestBackendModel,
      IDashboardWidgetRequestFrontendModel
    >(
      widgetConfigInstance,
      widgetConfigMapper,
    ),
    [EApiName.METRICS]: new RESTApi<IChartDataResponse>(instance, 'vendor/dashboard'),
    [EApiName.PERFORMANCE]: new RESTApi<IChartDataResponse>(instance, 'vendor/report/performance/table'),
    [EApiName.FULFILLMENT]: new RESTApi<IFulfillmentBackendModel>(instance, 'vendor/fulfillment'),
    [EApiName.FULFILLMENT_PARAMETERS]: new RESTApi<IFulfillmentParametersResponseModel>(
      instance,
      'vendor/fulfillment-parameters',
    ),
    [EApiName.FULFILLMENT_EMAIL]: new RESTApi<{ message: string }>(instance, 'vendor/fulfillment-mail'),
  };
}
