
import Vue, {
  onMounted,
  PropType,
  reactive,
  watch,
} from 'vue';

import EWidgetType from '@/module/dashboard/models/EWidgetType';
import TWidget from '@/module/dashboard/models/IWidgetConfig';

import cloneImmutable from '@/module/common/utils/data/cloneImmutable';

import DSHeader from '@/module/design-system/components/Text/DSHeader.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import ErrorComponent from '@/components/ErrorComponent/ErrorComponent.vue';
import DashboardWidgetChart from '@/module/dashboard/components/WidgetList/DashboardWidgetChart.vue';
import DashboardWidgetTable from '@/module/dashboard/components/WidgetList/DashboardWidgetTable.vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';

export default Vue.extend({
  name: 'DashboardWidgetListEdit',
  components: {
    DSButton,
    DSIcon,
    DashboardWidgetChart,
    DashboardWidgetTable,
    DSTextElement,
    DSHeader,
    ErrorComponent,
  },
  props: {
    showedWidgets: {
      type: Array as PropType<TWidget[]>,
      required: true,
    },
    hiddenWidgets: {
      type: Array as PropType<TWidget[]>,
      required: true,
    },
    dimension: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state: { widgetsToShow: TWidget[]; widgetsToHide: TWidget[]; } = reactive({
      widgetsToShow: [],
      widgetsToHide: [],
    });

    onMounted(() => {
      state.widgetsToShow = cloneImmutable(props.showedWidgets);
      state.widgetsToHide = cloneImmutable(props.hiddenWidgets);
    });

    watch(() => props.showedWidgets, (newValue: TWidget[]) => {
      state.widgetsToShow = cloneImmutable(newValue);
    });

    watch(() => props.hiddenWidgets, (newValue: TWidget[]) => {
      state.widgetsToHide = cloneImmutable(newValue);
    });

    function isChart(widget: TWidget): boolean {
      return widget.config?.type === EWidgetType.CHART;
    }

    function isTable(widget: TWidget): boolean {
      return widget.config?.type === EWidgetType.TABLE;
    }

    function updateWidget(widget, showOnPage) {
      const newWidget = cloneImmutable(widget);

      newWidget.config.showOnPage = showOnPage;

      let widgetsToShow;
      let widgetsToHide;

      if (showOnPage) {
        widgetsToShow = cloneImmutable(state.widgetsToShow);
        widgetsToHide = state.widgetsToHide.filter((widgetToHide) => widgetToHide.config?.id !== newWidget.config?.id);

        widgetsToShow.push(newWidget);
        widgetsToShow.sort((widgetA, widgetB) => widgetA.config.pageOrder - widgetB.config.pageOrder);
      } else {
        widgetsToShow = state.widgetsToShow.filter((widgetToHide) => widgetToHide.config?.id !== newWidget.config?.id);
        widgetsToHide = cloneImmutable(state.widgetsToHide);

        widgetsToHide.push(newWidget);
        widgetsToHide.sort((widgetA, widgetB) => widgetA.config.pageOrder - widgetB.config.pageOrder);
      }

      state.widgetsToHide = widgetsToHide.slice();
      state.widgetsToShow = widgetsToShow.slice();

      emit('update-widgets', [...widgetsToShow, ...widgetsToHide]);
    }

    return {
      state,
      isChart,
      isTable,
      updateWidget,
    };
  },
});
