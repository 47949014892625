<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      d="M11.9999 11.7861L6.63988 16.2521C6.53952 16.3399 6.42262 16.4068 6.29605 16.4487C6.16948 16.4907 6.0358 16.5069 5.90287 16.4964C5.76994 16.4859 5.64045 16.449 5.52201 16.3877C5.40357 16.3265 5.29859 16.2421 5.21322 16.1397C5.12786 16.0373 5.06385 15.9188 5.02495 15.7912C4.98605 15.6637 4.97305 15.5297 4.98671 15.397C5.00038 15.2644 5.04043 15.1358 5.10452 15.0189C5.1686 14.902 5.25543 14.799 5.35988 14.7161L11.3599 9.71615C11.5396 9.56648 11.766 9.48452 11.9999 9.48452C12.2337 9.48452 12.4602 9.56648 12.6399 9.71615L18.6399 14.7161C18.7443 14.799 18.8312 14.902 18.8952 15.0189C18.9593 15.1358 18.9994 15.2644 19.0131 15.397C19.0267 15.5297 19.0137 15.6637 18.9748 15.7912C18.9359 15.9188 18.8719 16.0373 18.7865 16.1397C18.7012 16.2421 18.5962 16.3265 18.4778 16.3877C18.3593 16.449 18.2298 16.4859 18.0969 16.4964C17.964 16.5069 17.8303 16.4907 17.7037 16.4487C17.5771 16.4068 17.4602 16.3399 17.3599 16.2521L11.9999 11.7861Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSArrowUpIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>
