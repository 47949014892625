<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.75 2.25H11.25V6.75H13.5255C13.5849 6.74995 13.6429 6.76752 13.6923 6.80048C13.7417 6.83345 13.7802 6.88032 13.8029 6.93516C13.8256 6.99001 13.8316 7.05037 13.82 7.10859C13.8084 7.16682 13.7798 7.22029 13.7377 7.26225L9.21225 11.7878C9.18439 11.8156 9.1513 11.8378 9.11488 11.8529C9.07846 11.868 9.03942 11.8757 9 11.8757C8.96058 11.8757 8.92154 11.868 8.88512 11.8529C8.8487 11.8378 8.81561 11.8156 8.78775 11.7878L4.26225 7.26225C4.22025 7.22029 4.19164 7.16682 4.18004 7.10859C4.16845 7.05037 4.17439 6.99001 4.19711 6.93516C4.21984 6.88032 4.25833 6.83345 4.30771 6.80048C4.35708 6.76752 4.41513 6.74995 4.4745 6.75H6.75V2.25ZM4.5 13.5H13.5C13.6989 13.5 13.8897 13.579 14.0303 13.7197C14.171 13.8603 14.25 14.0511 14.25 14.25C14.25 14.4489 14.171 14.6397 14.0303 14.7803C13.8897 14.921 13.6989 15 13.5 15H4.5C4.30109 15 4.11032 14.921 3.96967 14.7803C3.82902 14.6397 3.75 14.4489 3.75 14.25C3.75 14.0511 3.82902 13.8603 3.96967 13.7197C4.11032 13.579 4.30109 13.5 4.5 13.5Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>

</template>

<script>
export default {
  name: 'DSDownloadFilledIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>
