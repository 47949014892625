import TPricingModelOption from '@/module/product/model/Product/Fields/PricingModel/Option/TPricingModelOption';
import IOnePriceEachCurrencyPricingModelOption
  from '@/module/product/model/Product/Fields/PricingModel/Option/IOnePriceEachCurrencyPricingModelOption';
import IOnePricePricingModelOption
  from '@/module/product/model/Product/Fields/PricingModel/Option/IOnePricePricingModelOption';
import IVolumePriceAndOptionsPricingModelOption
  from '@/module/product/model/Product/Fields/PricingModel/Option/IVolumePriceAndOptionsPricingModelOption';
import IVolumePricePricingModelOption
  from '@/module/product/model/Product/Fields/PricingModel/Option/IVolumePricePricingModelOption';
import EPricingModelName from '@/module/product/model/Product/Fields/PricingModel/EPricingModelName';
import IProductTypeOptionCheckHelper
  from '@/module/product/helper/ProductTypeOptionCheckHelper/IProductTypeOptionCheckHelper';

class ProductTypeOptionCheckHelper implements IProductTypeOptionCheckHelper {
  isOnePriceEachCurrencyPricingModelOption(
    option: TPricingModelOption,
  ): option is IOnePriceEachCurrencyPricingModelOption {
    return option.id === EPricingModelName.ONE_PRICE_FOR_EACH_CURRENCY;
  }

  isOnePriceOption(
    option: TPricingModelOption,
  ): option is IOnePricePricingModelOption {
    return option.id === EPricingModelName.ONE_PRICE;
  }

  isVolumePriceAndOptionsPricingModelOption(
    option: TPricingModelOption,
  ): option is IVolumePriceAndOptionsPricingModelOption {
    return option.id === EPricingModelName.VOLUME_PRICING_AND_OPTIONS;
  }

  isVolumePricePricingModelOption(
    option: TPricingModelOption,
  ): option is IVolumePricePricingModelOption {
    return option.id === EPricingModelName.VOLUME_PRICING;
  }
}

export default ProductTypeOptionCheckHelper;
