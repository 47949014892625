import IProductImageUploadHandler
  from '@/module/product/view/ProductFormPage/handler/contract/IProductImageUploadHandler';
import IProductIconResponse from '@/module/product/model/Api/Product/IProductIconResponse';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EFileMimeType from '@/module/common/models/EFileMimeType';

class ProductImageUploadHandler implements IProductImageUploadHandler {
  private serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  handle = async (event: InputEvent): Promise<IProductIconResponse> => {
    const localisationRegistry = this.serviceContainer.localisationRegistry;
    const file = (event.target as HTMLInputElement).files?.[0];

    if (!file) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: 'Empty file.' });
    }

    const allowedMimeTypes = [
      EFileMimeType.IMAGE_GIF,
      EFileMimeType.IMAGE_JPG,
      EFileMimeType.IMAGE_JPEG,
      EFileMimeType.IMAGE_PJPEG,
      EFileMimeType.IMAGE_PNG,
      EFileMimeType.IMAGE_X_PNG,
    ];

    if (!allowedMimeTypes.includes(file.type as EFileMimeType)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: localisationRegistry.validationMessages.imgFormat });
    }

    if (file.size / 1000 >= 512) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: localisationRegistry.validationMessages.imgSize });
    }

    const data = this.prepareFile(file);

    const response = await this.serviceContainer.apiService.productIconApi.upload(data);

    return Promise.resolve(response.data);
  };

  isValidationError = (error: unknown): error is { message: string } => {
    const typeCheck = this.serviceContainer.utilsService.typeCheck;

    if (!typeCheck.isObject(error)) {
      return false;
    }

    return !!(error as { message: string }).message;
  };

  private prepareFile = (file: File): FormData => {
    const extension = file.name.split('.')[file.name.split('.').length - 1];

    const formData = new FormData();
    formData.append('icon', file, `icon.${extension}`);

    return formData;
  };
}

export default ProductImageUploadHandler;
