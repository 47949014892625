import ECurrency from '@/module/merchant/models/ECurrency';

enum ELicenseType {
  NEW = 'new',
  RENEW = 'renew',
}

enum EAvailableFor {
  ALL = 'all',
  PHYSICAL = 'physical',
  JURIDICAL = 'juridical',
}

interface PriceValue {
  currency: ECurrency;
  price: string;
}

interface OnePricePrice {
  sku: string;
  price: {
    common: PriceValue;
  };
}

interface VolumePricePrice {
  sku: string;
  from: number;
  to: number | null;
  price: {
    common: PriceValue;
  };
}

interface OnePriceEachCurrencyPrice {
  sku: string;
  price: {
    [key in ECurrency]: PriceValue;
  }
}

interface VolumePriceAndOptionsPrice {
  sku: string;
  from: number;
  to: number | null;
  price: {
    [key in ECurrency]: PriceValue;
  }
}

type TPrice = OnePricePrice | VolumePricePrice | OnePriceEachCurrencyPrice | VolumePriceAndOptionsPrice;

interface ProductModel {
  title: string;
  subtitle: string;
  description: string;
  licenseType: ELicenseType;
  availableFor: EAvailableFor;
  licenseTerm: string;
  price: TPrice[];
  trialSku?: string;
  trialTerm?: string;
  trialTitle?: string;
  trialSubtitle?: string;
  trialCheckbox: boolean;
  renewalTerm?: string;
  renewalTitle?: string;
  renewalSubtitle?: string;
  renewalPrice?: TPrice[];
  renewalCheckbox: boolean;
  softwareRegistry?: {
    status: boolean;
    date?: string;
    url?: string;
    registrationNumber?: string;
  }
}

export default ProductModel;

export {
  ELicenseType,
  EAvailableFor,
  OnePricePrice,
  VolumePricePrice,
  OnePriceEachCurrencyPrice,
  VolumePriceAndOptionsPrice,
  PriceValue,
  TPrice,
};
