
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from 'vue';

/* eslint-disable import/extensions */
import {
  IMaskDirective,
} from 'vue-imask/esm/index.js';
/* eslint-enable import/extensions */

import EDSFieldStatus from '@/module/design-system/models/UI/EDSFieldStatus';
import IMaskOptions from '@/module/common/models/UI/IMaskOptions';

import initState from '@/module/common/components/UI/MaskedInput/CMaskedInputField.func';
import generateUID from '@/module/common/utils/generate/generateUID';

export default defineComponent({
  name: 'CMaskedInputField',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    status: {
      type: String as PropType<EDSFieldStatus>,
      default: EDSFieldStatus.DEFAULT,
    },
    value: {
      type: [
        String,
        Number,
      ],
      default: '',
    },
    inputDelay: {
      type: Number,
      default: null,
    },
    maskOptions: {
      type: Object as PropType<IMaskOptions>,
      required: true,
    },
    transparentDefaultBorder: {
      type: Boolean,
      default: false,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { slots, emit }) {
    const state = initState();

    const element = ref(null);

    const uid = generateUID();

    const classes = computed(() => [
      'c-masked-input-field',
      `c-masked-input-field_${props.status}`,
      state.isFocused ? 'c-masked-input-field_focused' : null,
      props.borderless ? 'c-masked-input-field_borderless' : null,
      props.transparentDefaultBorder ? 'c-masked-input-field_transparent-default-border' : null,
    ]);

    const isBeforeSlot = computed(() => !!slots.before);
    const isAfterSlot = computed(() => !!slots.after);

    const mask = props.maskOptions.mask as string;
    let maskedFieldStartSymbols = '';

    if (props.maskOptions.eager) {
      maskedFieldStartSymbols = mask.match(/^({.+?}[^0a*]*|[^0a*]*)/)?.[0] ?? '';
    }

    const mappedMaskOptions = computed(() => {
      const mappedMaskOptions = props.maskOptions;

      if (props.maskOptions.mask === 'NumberConstructor') {
        mappedMaskOptions.mask = Number;
      }

      return mappedMaskOptions;
    });

    function getMaskedFieldStartSymbolsWithoutEscapes(maskedFieldStartSymbols: string) {
      const match = maskedFieldStartSymbols.match(/\\./g) ?? [];
      let maskedFieldStartSymbolsWithPlaceholders = maskedFieldStartSymbols;
      const map = match.reduce((mapState, item, index) => {
        const key = `###${index + 1}###`;
        maskedFieldStartSymbolsWithPlaceholders = maskedFieldStartSymbolsWithPlaceholders.replace(item, key);
        mapState[key] = item.replace('\\', '');

        return mapState;
      }, {});

      maskedFieldStartSymbolsWithPlaceholders = maskedFieldStartSymbolsWithPlaceholders
        .replaceAll('{', '')
        .replaceAll('}', '');

      Object.keys(map).forEach((mapKey) => {
        maskedFieldStartSymbolsWithPlaceholders = maskedFieldStartSymbolsWithPlaceholders
          .replaceAll(mapKey, map[mapKey]);
      });

      return maskedFieldStartSymbolsWithPlaceholders;
    }

    function handleFocus() {
      state.isFocused = true;
      const currentValue = (state.currentValue ?? '') as string;

      if (currentValue.length === 0) {
        state.currentValue = getMaskedFieldStartSymbolsWithoutEscapes(maskedFieldStartSymbols);
      }

      emit('focus');
    }
    function handleIconClick() {
      const elementNode: HTMLElement = element.value as unknown as HTMLElement;

      elementNode.focus();
      emit('icon-click');
    }
    function handleParentClick() {
      const elementNode: HTMLElement = element.value as unknown as HTMLElement;

      elementNode.focus();
    }

    function handleBlur() {
      state.isFocused = false;
      const currentValue = state.currentValue as string;

      const maskedFieldStartSymbolsWithoutEscapes = getMaskedFieldStartSymbolsWithoutEscapes(maskedFieldStartSymbols);
      if (currentValue === maskedFieldStartSymbolsWithoutEscapes) {
        state.currentValue = currentValue.replace(maskedFieldStartSymbolsWithoutEscapes, '');
      }

      emit('blur', state.currentValue);
    }
    function handleAccept(event) {
      if (props.inputDelay > 0) {
        clearTimeout(state.delay);

        state.delay = window.setTimeout(() => {
          emit('input', state.currentValue);
        }, props.inputDelay);
      } else {
        state.currentValue = event.detail.value;
        emit('input', state.currentValue);
      }
    }

    function handleKeydown(event) {
      emit('keydown', event);
    }

    function handleComplete() {
      emit('complete', state.currentValue);
    }

    onMounted(() => {
      state.currentValue = props.value;
    });

    watch(props, (newValue) => {
      state.currentValue = newValue.value;
    });

    return {
      element,
      classes,
      state,
      isBeforeSlot,
      isAfterSlot,
      uid,
      mappedMaskOptions,
      handleFocus,
      handleIconClick,
      handleParentClick,
      handleBlur,
      handleAccept,
      handleKeydown,
      handleComplete,
    };
  },
  directives: {
    imask: IMaskDirective,
  },
});
