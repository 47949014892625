<template>
  <DashboardWidgetArea
    :widget="widget"
  >
    <template
      #right-side
    >
      <slot
        name="right-side"
      />
    </template>
    <template
      #content
    >
      <LineChart
        class="dashboard-widget-chart__chart"
        :chart-data="chartData"
        :options="options"
      />
    </template>
  </DashboardWidgetArea>
</template>

<script>
import DashboardWidgetArea from '@/module/dashboard/components/WidgetList/DashboardWidgetArea.vue';
import LineChart from '@/components/LineChart/LineChart.vue';

export default {
  name: 'DashboardWidgetChart',
  components: {
    DashboardWidgetArea,
    LineChart,
  },
  props: {
    widget: {
      type: Object,
      required: true,
    },
    dimension: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  computed: {
    chartData() {
      const labels = this.widget.config?.isComparison
        ? this.widget.data?.compare?.labels
        : this.widget.data?.initial?.labels;
      return {
        labels: labels ?? [],
        datasets: [
          this.initDataSet(this.widget.data?.initial, this.widget?.config, true),
          this.initDataSet(this.widget.data?.compare, this.widget?.config),
        ].filter((item) => !!item),
      };
    },
    options() {
      return {
        legend: {
          display: false,
        },
        hover: {
          intersect: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0,
          },
          plugins: {
            title: {
              display: true,
              text: 'title',
            },
          },
        },
        tooltips: {
          mode: 'x',
          intersect: false,
          callbacks: {
            title: this.getWidgetTitle.bind(this),
            label: this.formatLabel.bind(this),
          },
          backgroundColor: '#1F2847',
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                callback: (value) => this.formatDate.call(this, value.date, value.isDateShown),
                autoSkip: true,
                maxTicksLimit: 4,
                maxRotation: 0,
                minRotation: 0,
                padding: 8,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
              },
              ticks: {
                maxTicksLimit: this.widget.config?.maxYAxisLines,
                callback: this.yAxisTicksFormatter,
                fontSize: 14,
                fontFamily: 'IBM Plex Sans',
                padding: 16,
                suggestedMax: this.initialRowMaxValue,
                suggestedMin: this.initialRowMinValue,
              },
              position: 'left',
              id: 'initial',
            },
          ],
        },
      };
    },
    initialRowMaxValue() {
      const items = this.widget.data?.initial?.items ?? [];

      if (items.every((el) => el === 0)) {
        return 1;
      }

      const maxValue = Math.max(...items);
      const modifier = maxValue < 0 ? -1 : 1;
      const value = modifier * Math.round(Math.floor((modifier * maxValue) / 100)) * 100;

      return value < 0 ? 0 : value;
    },
    initialRowMinValue() {
      const items = this.widget.data?.initial?.items ?? [];

      if (items.every((el) => el === 0)) {
        return 0;
      }

      const minValue = Math.min(...items);
      const modifier = minValue < 0 ? -1 : 1;
      const value = modifier * Math.round(Math.floor((modifier * minValue) / 100)) * 100;

      return value >= 0 ? 0 : value;
    },
  },
  methods: {
    initDataSet(data, config, isInitial = false) {
      if (!data) {
        return null;
      }

      const name = isInitial ? config.initial.name : config.compare.name;
      const color = isInitial ? config.initial.color : config.compare.color;
      const dataSet = {
        label: name,
        data: data.items,
        backgroundColor: color,
        borderColor: color,
        pointBorderColor: color,
        fill: false,
        pointRadius: data.items.length === 1 ? 1 : 0,
        pointHoverRadius: 5,
        borderWidth: 4,
        borderJoinStyle: 'round',
        padding: 0,
      };

      if (isInitial) {
        dataSet.yAxisID = 'initial';
      }

      return dataSet;
    },
    formatDate(date, isDateShown, isTooltip) {
      const daily = this.dimension === 'daily' ? 'DD ' : '';
      let dateString = '';
      if (isDateShown) {
        const format = `${daily}MMM${isTooltip ? ' YYYY' : ''}`;

        dateString = this.$utils.date.normalizeDate(date, 'DD.MM.YYYY').locale(this.locale).format(format);
      }

      return dateString;
    },
    formatLabel(tooltipItem) {
      const isComparisonDataset = tooltipItem.datasetIndex === 1;
      const date = isComparisonDataset && tooltipItem.xLabel.secondDate
        ? tooltipItem.xLabel.secondDate
        : tooltipItem.xLabel.date;
      const formattedDate = this.formatDate(date, true, true);

      const numberFormatService = this.$container.numberFormatService;

      const mapNumberFormatHandler = {
        percentage: (data) => numberFormatService.percentage(data.value),
        amount: (data) => numberFormatService.amount(data.value),
        currency: (data) => numberFormatService.money(data.value, data.currency),
      };

      const formattedValue = mapNumberFormatHandler[this.widget.config?.format?.type]({
        value: tooltipItem.yLabel,
        currency: this.widget.config?.format?.currencyName,
      });

      return `${formattedDate} : ${formattedValue}`;
    },
    yAxisTicksFormatter(value) {
      const numberFormatService = this.$container.numberFormatService;
      let newValue = value;

      if (this.widget.config?.format?.type === 'amount') {
        newValue = numberFormatService.amount(value);
      }

      if (this.widget.config?.format?.type === 'currency') {
        newValue = numberFormatService.money(value, this.widget.config.format.currencyName);
      }

      return newValue;
    },
    getWidgetTitle() {
      return this.widget.config?.title;
    },
  },
};
</script>

<style
  scoped
  lang="scss"
>
.dashboard-widget-chart {
  &__totals {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__chart {
    position: relative;
    height: 200px;
    width: 100%;
  }
}

</style>
