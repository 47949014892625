<template>
  <div
    class="mobileSidebar__wrapper"
    :style="sidebarWrapperStyle"
    @click="closeMobileMenu"
  >
    <div
      ref="mobileSidebar"
      :class="sidebarClass"
      class="mobileSidebar"
    >
      <div class="mobileSidebar__linkList">
        <div
          v-for="(link, index) in linkList"
          :key="index"
          class="mobileSidebar__item"
          @click="handleLinkClick(link)"
        >
          <a
            :class="getMainLinkClasses(link)"
          >
            <div
              class="mobileSidebar__mainLinkWrapper"
              @click="link.subgroup && subgroupToggle(index)"
            >
              <DSIcon
                :icon="link.icon"
                :color="sidebarIconColor(link)"
                :class="sidebarIconClasses(link)"
              />
              <a
                :class="getLinkClasses(link)"
              >{{ $t(link.title) }}
                <span
                  v-if="link.iconSoon"
                  class="mobileSidebar__link--soon"
                >SOON</span>
                <button
                  v-if="link.subgroup && link.subgroup.type !== 'notify'"
                  class="mobileSidebar__linkExpand"
                  type="button"
                >
                  <DSIcon
                    icon="arrowDown"
                    :class="getLinkIconClasses(link)"
                  />
                </button>
              </a>
            </div>
          </a>
          <div
            v-if="link.subgroup && link.subgroup.data"
            class="mobileSidebar__linkGroup"
            :style="getLinkGroupStyles(link)"
          >
            <div
              v-for="(sublink, subGroupIndex) in link.subgroup.data"
              :key="subGroupIndex"
              :class="getSubLinkWrapperClasses(link, sublink)"
              :style="getSubLinkWrapperStyles(link)"
            >
              <a
                :class="getSubLinkClasses(link, sublink)"
                @click="handleLinkClick(sublink)"
              >
                <component
                  :is="sublink.icon"
                  v-if="sublink.icon"
                  class="mobileSidebar__icon"
                  :class="getSubLinkIconClasses(link)"
                />
                {{ $t(sublink.title) }}
                <span
                  v-if="sublink.iconSoon"
                  class="mobileSidebar__link--soon"
                >SOON</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="mobileSidebar__account-info">
        <button
          v-if="!isMerchantActivated && salesStatusActivationAvailable"
          class="mobileSidebar__account-activate"
          type="button"
          @click="$router.push('/merchant/account/activate').catch(() => null)"
        >
          {{ $t('header.activate_account') }} →
        </button>
      </div>
      <div>
        <button
          :class="settingsButtonClasses"
          type="button"
        >
          <div class="mobileSidebar__settingsButtonWrapper">
            <div
              class="mobileSidebar__icon"
              :class="settingsButtonIconClasses"
            >
              <DSIcon
                icon="gear"
                color="primary-button"
                size="s"
              />
            </div>
            {{ isMenuMinimized ? "" : "Settings" }}
            <span
              v-if="!isMenuMinimized"
              class="mobileSidebar__link--soon mobileSidebar__link--soon_settings"
            >SOON</span>
          </div>
        </button>
      </div>
    </div>
    <div
      class="mobileSidebar__mobileCloseArea"
      :class="sidebarCloseArea"
    >
      <button
        class="mobileSidebar__closeMobileMenu"
        type="button"
        @click="closeMobileMenu"
      >
        <DSIcon
          icon="cross"
          color="menu-active"
          class="mobileSidebar__closeMobileMenuIcon"
        />
      </button>
    </div>
  </div>
</template>

<script>
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import { defineComponent } from 'vue';

import {
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

export default defineComponent({
  name: 'MobileSidebar',
  components: {
    DSIcon,
  },
  props: {
    isMenuMinimized: {
      type: Boolean,
      default: false,
    },
    menuMobileShowDisabler: {
      type: Function,
      default: null,
    },
    menuMinimizedHandler: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      sublinkHeight: 36,
    };
  },
  computed: {
    ...mapState({
      isMobileMenuShown: (state) => state.productLayoutStore.isMobileMenuShown,
      linkList: (state) => state.productLayoutStore.linkList,
    }),
    ...mapGetters({
      getMerchantMode: 'authStore/getMerchantMode',
      isMerchantActivated: 'authStore/getIsMerchantActivated',
      getSalesStatusActivationAvailable: 'authStore/getSalesStatusActivationAvailable',
      esupportLink: 'authStore/getEsupportLink',
    }),
    settingsButtonClasses() {
      return {
        mobileSidebar__settingsButton: true,
        mobileSidebar__settingsButtonFull: !this.isMenuMinimized,
      };
    },
    settingsButtonIconClasses() {
      return {
        mobileSidebar__icon: true,
        mobileSidebar__settingsIconFull: !this.isMenuMinimized,
      };
    },
    sidebarClass() {
      return {
        'mobileSidebar--full': this.isMobileMenuShown,
        'mobileSidebar--hide': !this.isMobileMenuShown,
      };
    },
    sidebarWrapperStyle() {
      return {
        visibility: this.isMobileMenuShown ? 'visible' : 'hidden',
        'background-color': this.isMobileMenuShown ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.0)',
      };
    },
    sidebarCloseArea() {
      return {
        'mobileSidebar__closeArea--show': this.isMobileMenuShown,
        'mobileSidebar__closeArea--hide': !this.isMobileMenuShown,
      };
    },
    salesStatusActivationAvailable() {
      return this.getSalesStatusActivationAvailable;
    },
  },
  created() {
    window.addEventListener('resize', this.resizeHandler);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    ...mapMutations({
      toggleMobileMenu: 'productLayoutStore/toggleMobileMenu',
      subgroupToggle: 'productLayoutStore/subgroupToggle',
    }),
    handleLinkClick(linkInfo) {
      if (!linkInfo.subgroup) {
        if (linkInfo.to === 'esupport') {
          window.open(this.esupportLink);
        } else if (linkInfo.to !== 'nowhere') {
          this.$router.push(linkInfo.to).catch(() => null);
        }
      }
    },
    resizeHandler() {
      if (window.innerWidth > 640) {
        this.toggleMobileMenu(false);
      }
    },
    closeMobileMenu(e) {
      if (!e.target.closest('.mobileSidebar')) {
        this.toggleMobileMenu(false);
      }
    },
    isActiveSidebarItem(link) {
      return this.isCurrentRoute(link)
        && (this.isMenuMinimized || (!this.isMenuMinimized && (!link.subgroup || !link.subgroup.show)));
    },
    sidebarIconColor(link) {
      return this.isActiveSidebarItem(link) ? 'menu-active' : 'menu';
    },
    sidebarIconClasses(link) {
      return [
        'mobileSidebar__icon',
        this.isActiveSidebarItem(link) ? 'mobileSidebar__icon--active' : null,
      ];
    },
    getMainLinkClasses(link) {
      const isRouteMatched = this.isCurrentRoute(link) || this.isSubGroupContainsCurrentRoute(link);
      return {
        mobileSidebar__mainLink: true,
        mobileSidebar__linkActive:
          isRouteMatched
          && (this.isMenuMinimized
            || (!this.isMenuMinimized && (!link.subgroup || !link.subgroup.show))
          ),
      };
    },
    getLinkClasses(link) {
      return {
        mobileSidebar__link: true,
        'mobileSidebar__linkActive--white-text': this.isCurrentRoute(link)
        && (this.isMenuMinimized
          || (!this.isMenuMinimized && (!link.subgroup || !link.subgroup.show))
        ),
      };
    },
    getLinkIconClasses(link) {
      return {
        'mobileSidebar__linkExpand--active': link.subgroup.show,
      };
    },
    getLinkGroupStyles(link) {
      return [
        this.isMenuMinimized || !link.subgroup.show
          ? { maxHeight: '0px' }
          : { maxHeight: `${link.subgroup.data.length * this.sublinkHeight + 44}px` },
      ];
    },
    getSubLinkWrapperClasses(link, subLink) {
      return {
        mobileSidebar__sublinkWrapper: true,
        mobileSidebar__linkActive:
          this.isCurrentRoute(subLink)
          && link.subgroup.show
          && !this.isMenuMinimized,
        mobileSidebar__sublinkWrapper_big: subLink.icon,
      };
    },
    getSubLinkWrapperStyles(link) {
      return [
        this.isMenuMinimized || !link.subgroup.show
          ? { pointerEvents: 'none' }
          : { pointerEvents: 'auto' },
      ];
    },
    getSubLinkClasses(link, subLink) {
      return {
        mobileSidebar__link: true,
        mobileSidebar__sublink: true,
        'mobileSidebar__link--full': !this.isMenuMinimized || link.subgroup.show,
        'mobileSidebar__link--mini': this.isMenuMinimized || !link.subgroup.show,
        'mobileSidebar__linkActive--white-text': this.isCurrentRoute(subLink),
        'mobileSidebar__sublink--with-icon': subLink.icon,
      };
    },
    getSubLinkIconClasses(link) {
      return {
        mobileSidebar__icon: true,
        'mobileSidebar__icon--active':
          this.isCurrentRoute(link)
          && (this.isMenuMinimized
            || (!this.isMenuMinimized && (!link.subgroup || !link.subgroup.show))),
      };
    },
    isCurrentRoute(link) {
      return link.to === this.$route.fullPath || `${link.to}/` === this.$route.fullPath;
    },
    isSubGroupContainsCurrentRoute(link) {
      return (link.subgroup?.data ?? []).reduce(
        (isCurrentRoute, subItem) => isCurrentRoute || this.isCurrentRoute(subItem),
        false,
      );
    },
  },
  mounted() {
    if (window.innerWidth <= 670) {
      this.linkList.forEach((rootItem, index) => {
        const isContainsCurrentRoute = this.isCurrentRoute(rootItem) || this.isSubGroupContainsCurrentRoute(rootItem);
        if (isContainsCurrentRoute) {
          this.subgroupToggle(index);
        }
      });
    }
  },
  beforeDestroy() {
    if (window.innerWidth <= 670) {
      this.linkList.forEach((rootItem, index) => {
        const isOpenedSubGroup = this.isCurrentRoute(rootItem) || (rootItem.subgroup?.show ?? false);
        if (isOpenedSubGroup) {
          this.subgroupToggle(index);
        }
      });
    }
  },
});
</script>

<style lang="scss" scoped>
@import "style";
</style>
