enum EOrganizationalLegalFormOfBusiness {
  'INDIVIDUAL_ENTREPRENEUR' = 'Individual entrepreneur',
  'SELF_EMPLOYED' = 'Self-Employed',
  'INDIVIDUAL' = 'Individual',
  'COMPANY' = 'Company',
  'OOO' = 'ООО',
  'ZAO' = 'ЗАО',
  'OAO' = 'ОАО',
  'IP' = 'ИП',
  'AO' = 'АО',
  'PBOYUL' = 'ПБОЮЛ',
  'IPBOYUL' = 'ИПБОЮЛ',
  'SPD' = 'СПД',
  'FLP' = 'ФЛП',
  'CHP' = 'ЧП',
  'TOO' = 'ТОО',
  'CHUP' = 'ЧУП',
  'ODO' = 'ОДО',
  'TOV' = 'ТОВ',
}

export default EOrganizationalLegalFormOfBusiness;
