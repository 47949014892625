
import Vue, {
  computed,
  onMounted,
  PropType,
  reactive,
  watch,
} from 'vue';

import IFile from '@/module/common/models/UI/FileUpload/IFile';

import cloneImmutable from '@/module/common/utils/data/cloneImmutable';

import PreloaderComponent from '@/components/Preloader/PreloaderComponent.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default Vue.extend({
  name: 'CFileList',
  components: {
    DSTextElement,
    DSIcon,
    PreloaderComponent,
  },
  props: {
    list: {
      type: Array as PropType<IFile[]>,
      default: () => [],
    },
    enableDelete: {
      type: Boolean,
      default: () => false,
    },
    enableLink: {
      type: Boolean,
      default: () => true,
    },
  },
  setup(props, { emit }) {
    const state: { list: IFile[] } = reactive({
      list: [],
    });

    const fileNameClasses = computed(() => [
      'c-file-list__file-name',
      props.enableLink ? 'c-file-list__file-name_link' : null,
    ]);

    function handleClickDelete(file: IFile) {
      emit('delete-file', file);
    }

    function handleClickLink(event, file: IFile) {
      emit('download-file', file);
    }

    function isDeleteIcon(file: IFile, enableDelete: boolean) {
      if (!enableDelete) {
        return false;
      }

      return file.status === 'error' || file.status === 'success';
    }

    onMounted(() => {
      state.list = props.list.map((file) => cloneImmutable(file));
    });

    watch(() => props.list, (newValue) => {
      state.list = newValue.map((file) => cloneImmutable(file));
    });

    return {
      handleClickDelete,
      handleClickLink,
      isDeleteIcon,
      fileNameClasses,
      state,
    };
  },
});
