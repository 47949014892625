<template>
  <COverviewPageLayout>
    <template
      #header
    >
      <COverviewPageHeaderRow
        :page-title="$t('pages.privacy_notice.title')"
      />
    </template>
    <template
      #content
    >
      <CTextBlock
        v-for="(block, index) in textBlocks"
        :key="`text-block-${index}`"
        :block="block"
        class="text-page__text-block"
      >
        <template
          #tableCell="slotData"
        >
          <div
            v-if="slotData.options && slotData.options.minColWidth && slotData.options.minColWidth.length > 0"
            :style="tableCellWidth(slotData)"
          >
            <!-- eslint-disable vue/no-v-html -->
            <span
              v-html="slotData.cell"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
        </template>
      </CTextBlock>

      <template
        v-for="(block, index) in annex"
      >
        <div
          v-if="hasAnnexContent(block)"
          :key="`annex-${index}`"
          class="annex"
        >
          <DSHeader
            v-if="getBlockTitle(block)"
            :size="3"
            :text="getBlockTitle(block)"
            class="annex__title"
          />

          <div
            v-if="getBlockGridContent(block)"
            :class="annexGridClasses(block)"
          >
            <div
              class="annex__grid-cell annex__grid-cell--header"
            >
              <DSTextElement>
                {{ getBlockGridTitle(block) }}
              </DSTextElement>
            </div>
            <div
              v-for="(item, subItemIndex) in getBlockGridContent(block)"
              :key="subItemIndex"
              class="annex__grid-cell"
            >
              <DSTextElement
                variant="small"
              >
                {{ item }}
              </DSTextElement>
            </div>
          </div>

          <DSTextElement
            v-if="getBlockComment(block)"
            variant="xsmall"
            class="annex__comment"
          >
            {{ getBlockComment(block) }}
          </DSTextElement>
        </div>
      </template>
    </template>
  </COverviewPageLayout>
</template>

<script>
import DSHeader from '@/module/design-system/components/Text/DSHeader.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import COverviewPageHeaderRow from '@/module/common/components/PageParts/COverviewPageHeaderRow.vue';
import COverviewPageLayout from '@/module/common/components/PageParts/COverviewPageLayout.vue';
import CTextBlock from '@/module/common/components/CTextBlock.vue';

export default {
  name: 'PrivacyNoticePage',
  components: {
    DSHeader,
    DSTextElement,
    COverviewPageHeaderRow,
    COverviewPageLayout,
    CTextBlock,
  },
  data() {
    return {
      textBlocks: [
        {
          name: 'who_are_we',
          title: this.$t('pages.privacy_notice.who_are_we.title'),
          items: this.mapData(this.$t('pages.privacy_notice.who_are_we.paragraphs'), 'paragraph'),
        },
        {
          name: 'privacy_notice_cover',
          title: this.$t('pages.privacy_notice.privacy_notice_cover.title'),
          items: this.mapData(this.$t('pages.privacy_notice.privacy_notice_cover.paragraphs'), 'paragraph'),
        },
        {
          name: 'data_collect',
          title: this.$t('pages.privacy_notice.data_collect.title'),
          items: [
            ...this.mapData(this.$t('pages.privacy_notice.data_collect.lists'), 'list'),
            ...this.mapData(this.$t('pages.privacy_notice.data_collect.tables'), 'table'),
          ],
        },
        {
          name: 'data_share',
          title: this.$t('pages.privacy_notice.data_share.title'),
          items: this.mapData(this.$t('pages.privacy_notice.data_share.paragraphs'), 'paragraph'),
        },
        {
          name: 'user_rights',
          title: this.$t('pages.privacy_notice.user_rights.title'),
          items: this.mapData(this.$t('pages.privacy_notice.user_rights.lists'), 'list'),
        },
        {
          name: 'safeguard',
          title: this.$t('pages.privacy_notice.safeguard.title'),
          items: this.mapData(this.$t('pages.privacy_notice.safeguard.lists'), 'list'),
        },
        {
          name: 'contacts',
          title: this.$t('pages.privacy_notice.contacts.title'),
          items: this.mapData(
            this.$t('pages.privacy_notice.contacts.tables'),
            'table',
            {
              minColWidth: [
                140,
                100,
                100,
              ],
            },
          ),
        },
        {
          name: 'privacy_notice_updated',
          title: this.$t('pages.privacy_notice.privacy_notice_updated.title'),
          items: this.mapData(this.$t('pages.privacy_notice.privacy_notice_updated.paragraphs'), 'paragraph'),
        },
      ],
      annex: [
        'annex_1a',
        'annex_1b',
      ],
    };
  },
  methods: {
    mapData(data, type, options = {}) {
      return data.map((item) => {
        if (type === 'table') {
          item.rows = [item.rows];
        }

        return {
          type,
          data: item,
          options,
        };
      });
    },
    getBlockTitle(block) {
      const translateToken = `pages.privacy_notice.${block}.title`;
      const title = this.$t(translateToken);

      return title !== translateToken ? title : '';
    },
    getBlockGridTitle(block) {
      const translateToken = `pages.privacy_notice.${block}.grid.title`;
      const gridTitle = this.$t(translateToken);

      return gridTitle !== translateToken ? gridTitle : '';
    },
    getBlockGridContent(block) {
      const gridItems = this.$t(`pages.privacy_notice.${block}.grid.items`);

      const isArray = Array.isArray(gridItems);
      const haveElements = gridItems.length > 0;
      const isSingleEmptyElementArray = gridItems.length === 1 && gridItems[0] === '';

      return isArray && haveElements && !isSingleEmptyElementArray ? gridItems : '';
    },
    getBlockComment(block) {
      const translateToken = `pages.privacy_notice.${block}.comment`;
      const comment = this.$t(translateToken);

      return comment !== translateToken ? comment : '';
    },
    hasAnnexContent(block) {
      return this.getBlockTitle(block)
        || this.getBlockGridTitle(block)
        || this.getBlockGridContent(block)
        || this.getBlockComment(block);
    },
    tableCellWidth(slotData) {
      const minColWidth = slotData.options?.minColWidth ?? [];

      return {
        minWidth: minColWidth[slotData.col] ? `${minColWidth[slotData.col]}px` : 0,
      };
    },
    annexGridClasses(block) {
      return [
        'annex__grid',
        `annex__grid_${block === 'annex_1a' ? '1a' : '1b'}`,
      ];
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import "@/module/design-system/components/variables.scss";

.annex {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__grid {
    display: grid;
    border-top: 1px solid $surface-stroke-color;
    border-left: 1px solid $surface-stroke-color;
    grid-template-columns: repeat(4, 1fr);
  }

  &__grid-cell {
    padding: 12px 15px;
    border-right: 1px solid $surface-stroke-color;
    border-bottom: 1px solid $surface-stroke-color;

    &--header {
      text-align: center;
      background-color: #f8fafc;
      grid-row-start: 1;
      grid-column-start: 1;
      grid-row-end: 2;
      grid-column-end: 5;
    }
  }

  &__comment {

  }
}

@media screen and (max-width: $breakpoint-lg) {
  .annex {
    &__grid {
      &_1a {
        grid-template-rows: repeat(13, min-content);
      }

      &_1b {
        grid-template-rows: repeat(10, min-content);
      }
    }

    &__grid-cell--header {
      grid-column-end: 4;
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .annex {
    &__title {
      margin-bottom: 12px;
    }

    &__grid {
      &_1a {
        grid-template-rows: repeat(18, min-content);
      }

      &_1b {
        grid-template-rows: repeat(16, min-content);
      }
    }

    &__grid-cell--header {
      grid-column-end: 3;
    }
  }
}
</style>
