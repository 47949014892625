
import {
  computed,
  defineComponent,
  inject,
  onMounted,
} from 'vue';
import initState, {
  closeApiKeyPreviewModal,
  closeConfirmRollModal,
  copyApiKeyHandler,
  createApiKeyHandler,
  getCreateButtonText,
  ILocalisationApiKeys,
  initApiKeyPreviewModal,
  initConfirmRollModal,
  initInfoBlock,
  initMerchantModeAlert,
  initStatusWindow,
  openConfirmRollModal,
  openHowItWorksLink,
  refreshApiKeys,
  rootLocalisationKey,
  updateApiKeyHandler,
} from '@/module/developers/views/ApiKeys.func';
import IApiKeysModel from '@/module/developers/models/IApiKeysModel';
import EDSButtonVariant from '@/module/design-system/models/UI/DSButton/EDSButtonVariant';
import EBreakPoint from '@/module/design-system/components/models/EBreakPoint';
import IVueInstance from '@/module/common/models/IVueInstance';
import getLocalisation from '@/module/common/helpers/getLocalisation';
import getButton from '@/module/common/helpers/getButton';

import ApiKeysList from '@/module/developers/components/ApiKeysList.vue';
import CCard from '@/module/common/components/CCard.vue';
import CCopyField from '@/module/common/components/UI/CCopyField.vue';
import CLinkTextBlock from '@/module/common/components/Text/CLinkTextBlock.vue';
import COverviewPageHeaderRow from '@/module/common/components/PageParts/COverviewPageHeaderRow.vue';
import COverviewPageLayout from '@/module/common/components/PageParts/COverviewPageLayout.vue';
import CStatusWindowComponent from '@/module/common/components/UI/CStatusWindowComponent.vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSModal from '@/module/design-system/components/UI/DSModal.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import PreloaderComponent from '@/components/Preloader/PreloaderComponent.vue';
import InlineAlert from '@/components/InlineAlert/InlineAlert.vue';
import {
  EMerchantModeAlertStatus,
  EPage,
  getIsMerchantModeAlertVisible,
  setMerchantModeAlertStatus,
} from '@/module/common/utils/merchantModeAlert';
import IRESTApi from '@/module/common/service/RESTApi/contract/IRESTApi';
import TTSApi from '@/module/common/tsApi/TTSApi';

export default defineComponent({
  name: 'ApiKeys',
  components: {
    ApiKeysList,
    CCard,
    CCopyField,
    CLinkTextBlock,
    COverviewPageHeaderRow,
    COverviewPageLayout,
    CStatusWindowComponent,
    DSButton,
    DSIcon,
    PreloaderComponent,
    DSModal,
    DSTextElement,
    InlineAlert,
  },
  setup() {
    const vueInstance = inject<IVueInstance>('vueInstance');
    const api: IRESTApi<IApiKeysModel> = inject<TTSApi>('tsApi')?.apiKeys as IRESTApi<IApiKeysModel>;

    const state = initState();

    const localisation = getLocalisation<ILocalisationApiKeys>(rootLocalisationKey);

    const merchantMode = vueInstance?.$store.getters['authStore/getMerchantMode'];

    const howItWorksButton = getButton(
      {
        text: localisation.buttons.how_it_works,
        variant: EDSButtonVariant.SECONDARY,
        showTextAfterBreakpoint: EBreakPoint.XL,
      },
      () => openHowItWorksLink(localisation),
    );

    const merchantModeAlert = initMerchantModeAlert(localisation);

    const infoBlock = initInfoBlock(localisation);

    const showEmptyApiKeys = computed(() => !state.loading && state.apiKeysListForPreview.length === 0);

    const statusWindowHowItWorksButton = getButton(
      {
        text: localisation.buttons.how_it_works,
        stretch: EBreakPoint.XXL,
        variant: EDSButtonVariant.TRETIARY,
      },
      () => openHowItWorksLink(localisation),
    );
    const createApiKeyButton = getButton(
      {
        text: getCreateButtonText(
          merchantMode,
          localisation,
        ),
        stretch: EBreakPoint.XXL,
      },
      () => createApiKeyHandler(state, api, vueInstance, localisation),
    );
    const statusWindow = initStatusWindow(
      {
        create: createApiKeyButton,
        howItWorks: statusWindowHowItWorksButton,
      },
      localisation,
    );

    const showApiKeysList = computed(() => state.apiKeysListForPreview.length > 0);

    const apiKeyPreviewModal = computed(() => {
      let localApiKeyPreviewModal;

      if (state.apiKeyLoadMode) {
        const doneButton = getButton(
          {
            text: localisation.login_password[state.apiKeyLoadMode].buttons.done,
          },
          () => closeApiKeyPreviewModal(state),
        );

        localApiKeyPreviewModal = initApiKeyPreviewModal(
          localisation,
          state,
          {
            cancelHandler: () => closeApiKeyPreviewModal(state),
            copyHandler: () => copyApiKeyHandler(localisation, vueInstance),
          },
          {
            done: doneButton,
          },
        );
      }

      return localApiKeyPreviewModal;
    });

    const handleRoll = (item: IApiKeysModel) => openConfirmRollModal(state, item);

    const cancelRollButton = getButton(
      {
        text: localisation.roll_password.modal.buttons.cancel,
        variant: EDSButtonVariant.SECONDARY_NEGATIVE,
        stretch: EBreakPoint.XXL,
      },
      () => closeConfirmRollModal(state),
    );
    const continueRollButton = getButton(
      {
        text: localisation.roll_password.modal.buttons.continue,
        stretch: EBreakPoint.XXL,
      },
      () => updateApiKeyHandler(state, api, vueInstance, localisation),
    );

    const confirmRollModal = computed(() => {
      let localConfirmRollModal;

      if (state.apiKeyForUpdate) {
        localConfirmRollModal = initConfirmRollModal(
          localisation,
          () => closeConfirmRollModal(state),
          {
            cancel: cancelRollButton,
            continue: continueRollButton,
          },
        );
      }

      return localConfirmRollModal;
    });

    function handleClickCloseMerchantModeAlert() {
      merchantModeAlert.visible = false;
      setMerchantModeAlertStatus(EPage.API_KEYS, EMerchantModeAlertStatus.HIDDEN);
    }

    onMounted(async () => {
      state.loading = true;
      await refreshApiKeys(state, api, vueInstance, localisation);

      merchantModeAlert.visible = getIsMerchantModeAlertVisible(
        EPage.API_KEYS,
        merchantMode,
      );

      state.loading = false;
    });

    return {
      localisation,
      state,
      merchantMode,
      howItWorksButton,
      infoBlock,
      merchantModeAlert,
      statusWindow,
      showEmptyApiKeys,
      showApiKeysList,
      apiKeyPreviewModal,
      confirmRollModal,
      handleRoll,
      handleClickCloseMerchantModeAlert,
    };
  },
});
