import {
  reactive,
} from 'vue';

import moment from 'moment';

import EIntervalName from '@/module/dashboard/models/DatePickers/enums/EIntervalName';
import EDateInterval from '@/module/dashboard/models/DatePickers/enums/EDateInterval';
import IDateInterval from '@/module/dashboard/models/DatePickers/interfaces/IDateInterval';
import IState from '@/module/dashboard/models/DatePickers/interfaces/IState';
import IDatePair from '@/module/dashboard/models/DatePickers/interfaces/IDatePair';

function initState():IState {
  return reactive({
    [EIntervalName.INITIAL]: {
      selectionMode: EDateInterval.LAST_30_DAYS,
      dateFrom: moment().toDate(),
      dateTo: moment().toDate(),
      options: [],
    },
    [EIntervalName.COMPARISON]: {
      selectionMode: EDateInterval.NO_COMPARISON,
      dateFrom: moment().toDate(),
      dateTo: moment().toDate(),
      options: [],
    },
    isMenuOpen: {
      [EIntervalName.INITIAL]: false,
      [EIntervalName.COMPARISON]: false,
    },
  });
}

function getPrevPeriodDates(initialDates: IDatePair):IDatePair {
  const initialDateFrom = moment(initialDates.dateFrom);
  const initialDateTo = moment(initialDates.dateTo);
  const diff = initialDateTo.diff(initialDateFrom, 'days') + 1;

  return {
    dateFrom: moment(initialDateFrom).subtract(diff, 'days').toDate(),
    dateTo: moment(initialDateTo).subtract(diff, 'days').toDate(),
  };
}

function getDatePairs(option: IDateInterval, dates: IDatePair, initialDates: IDatePair) {
  const prevDay = moment().subtract(1, 'days');
  const prevMonth = moment().subtract(1, 'month');
  const prevQuarter = moment().subtract(1, 'quarter');
  const prevYear = moment().subtract(1, 'year');

  const prevPeriod = getPrevPeriodDates(initialDates);

  const datePairsMap = {
    [EDateInterval.LAST_30_DAYS]: {
      dateFrom: moment(prevDay).subtract(29, 'days').toDate(),
      dateTo: moment(prevDay).toDate(),
    },
    [EDateInterval.LAST_7_DAYS]: {
      dateFrom: moment(prevDay).subtract(6, 'days').toDate(),
      dateTo: moment(prevDay).toDate(),
    },
    [EDateInterval.LAST_4_WEEKS]: {
      dateFrom: moment(prevDay).subtract(27, 'days').toDate(),
      dateTo: moment(prevDay).toDate(),
    },
    [EDateInterval.LAST_3_MONTH]: {
      dateFrom: moment(prevDay).subtract(3, 'month').toDate(),
      dateTo: moment(prevDay).toDate(),
    },
    [EDateInterval.LAST_YEAR]: {
      dateFrom: moment(prevDay).subtract(1, 'years').toDate(),
      dateTo: moment(prevDay).toDate(),
    },
    [EDateInterval.THIS_MONTH]: {
      dateFrom: moment().startOf('month').toDate(),
      dateTo: moment(prevDay).toDate(),
    },
    [EDateInterval.THIS_QUARTER]: {
      dateFrom: moment().startOf('quarter').toDate(),
      dateTo: moment(prevDay).toDate(),
    },
    [EDateInterval.THIS_YEAR]: {
      dateFrom: moment().startOf('year').toDate(),
      dateTo: moment(prevDay).toDate(),
    },
    [EDateInterval.CUSTOM]: {
      dateFrom: dates.dateFrom ?? moment().toDate(),
      dateTo: dates.dateTo ?? moment().toDate(),
    },
    [EDateInterval.PREV_PERIOD]: {
      dateFrom: prevPeriod.dateFrom,
      dateTo: prevPeriod.dateTo,
    },
    [EDateInterval.PREV_MONTH]: {
      dateFrom: prevMonth.startOf('month').toDate(),
      dateTo: prevMonth.endOf('month').toDate(),
    },
    [EDateInterval.PREV_QUARTER]: {
      dateFrom: prevQuarter.startOf('quarter').toDate(),
      dateTo: prevQuarter.endOf('quarter').toDate(),
    },
    [EDateInterval.PREV_YEAR]: {
      dateFrom: prevYear.startOf('year').toDate(),
      dateTo: prevYear.endOf('year').toDate(),
    },
    [EDateInterval.NO_COMPARISON]: {
      dateFrom: moment().toDate(),
      dateTo: moment().toDate(),
    },
  };

  return datePairsMap[option.value] ?? {
    dateFrom: dates.dateFrom,
    dateTo: dates.dateTo,
  };
}

export default initState;

export {
  getDatePairs,
  getPrevPeriodDates,
};
