import EDSFieldStatus from '@/module/design-system/models/UI/EDSFieldStatus';
import EColorScheme from '@/module/design-system/components/models/EColorScheme';

function mapFieldStatusToColorScheme(status: EDSFieldStatus): EColorScheme {
  const map = {
    [EDSFieldStatus.DEFAULT]: EColorScheme.DEFAULT,
    [EDSFieldStatus.ERROR]: EColorScheme.ERROR,
    [EDSFieldStatus.SUCCESS]: EColorScheme.SUCCESS,
  };

  return map[status];
}

export default mapFieldStatusToColorScheme;
