import {
  AxiosInstance,
  AxiosResponse,
} from 'axios';
import IFulfillmentTestRequestBackendModel
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/IFulfillmentTestRequestBackendModel';
import IFulfillmentTestRequestResponse from '@/module/product/model/Api/Fulfillment/IFulfillmentTestRequestResponse';
import IFulfillmentTestRequestApi from '@/module/product/api/contract/IFulfillmentTestRequestApi';

class FulfillmentTestRequestApi implements IFulfillmentTestRequestApi {
  private readonly client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  async post(
    options: IFulfillmentTestRequestBackendModel,
  ): Promise<AxiosResponse<IFulfillmentTestRequestResponse>> {
    return this.client.post('vendor/fulfillment-test-request', options);
  }
}

export default FulfillmentTestRequestApi;
