import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IArrayTool from '@/module/common/service/UtilsService/contract/IArrayTool';
import IUtilsService from '@/module/common/service/UtilsService/contract/IUtilsService';
import TypeCheckTool from '@/module/common/service/UtilsService/TypeCheckTool';
import ITypeCheckTool from '@/module/common/service/UtilsService/contract/ITypeCheckTool';
import ArrayTool from '@/module/common/service/UtilsService/ArrayTool';
import IComparatorTool from '@/module/common/service/UtilsService/contract/IComparatorTool';
import ComparatorTool from '@/module/common/service/UtilsService/ComparatorTool';
import DataTool from '@/module/common/service/UtilsService/DataTool';
import IDataTool from '@/module/common/service/UtilsService/contract/IDataTool';
import IStringTool from '@/module/common/service/UtilsService/contract/IStringTool';
import StringTool from '@/module/common/service/UtilsService/StringTool';
import ClipboardTool from '@/module/common/service/UtilsService/ClipboardTool';
import IClipboardTool from '@/module/common/service/UtilsService/contract/IClipboardTool';
import IJsonTool from '@/module/common/service/UtilsService/contract/IJsonTool';
import JsonTool from '@/module/common/service/UtilsService/JsonTool';
import IEmailTool from '@/module/common/service/UtilsService/contract/IEmailTool';
import EmailTool from '@/module/common/service/UtilsService/EmailTool';
import IDateTool from '@/module/common/service/UtilsService/contract/IDateTool';
import DateTool from '@/module/common/service/UtilsService/DateTool';
import IObjectTool from '@/module/common/service/UtilsService/contract/IObjectTool';
import ObjectTool from '@/module/common/service/UtilsService/ObjectTool';

class UtilsService implements IUtilsService {
  private serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  get array(): IArrayTool {
    return new ArrayTool(this);
  }

  get typeCheck(): ITypeCheckTool {
    return new TypeCheckTool();
  }

  get comparator(): IComparatorTool {
    return new ComparatorTool(this);
  }

  get data(): IDataTool {
    return new DataTool(this);
  }

  get date(): IDateTool {
    return new DateTool(this.serviceContainer);
  }

  get string(): IStringTool {
    return new StringTool();
  }

  get clipboard(): IClipboardTool {
    return new ClipboardTool();
  }

  get json(): IJsonTool {
    return new JsonTool(this);
  }

  get email(): IEmailTool {
    return new EmailTool(this.serviceContainer);
  }

  get object(): IObjectTool {
    return new ObjectTool(this);
  }
}

export default UtilsService;
