
import {
  defineComponent,
  inject,
} from 'vue';

import CCard from '@/module/common/components/CCard.vue';
import CLinkTextBlock from '@/module/common/components/Text/CLinkTextBlock.vue';
import COverviewPageHeaderRow from '@/module/common/components/PageParts/COverviewPageHeaderRow.vue';
import COverviewPageLayout from '@/module/common/components/PageParts/COverviewPageLayout.vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSLink from '@/module/design-system/components/Text/DSLink.vue';
import DSMarkedList from '@/module/design-system/components/Data/DSMarkedList.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import {
  Store,
} from 'vuex';
import IUtilsService from '@/module/common/service/UtilsService/contract/IUtilsService';

export default defineComponent({
  name: 'DataExportsPage',
  components: {
    CCard,
    CLinkTextBlock,
    COverviewPageHeaderRow,
    COverviewPageLayout,
    DSButton,
    DSLink,
    DSMarkedList,
    DSTextElement,
  },
  setup() {
    const vueInstance = inject<{ $t, $store, $utils }>('vueInstance');
    const utils = vueInstance?.$utils ?? null;

    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;
    const store = serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const localisationRegistry = store.getters.getLocalisationRegistry;
    const localisation = localisationRegistry.dataExports;

    const docsSiteUrl = serviceContainer.portalConfigService.get().url.docs;

    const utilsService = serviceContainer.resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);

    const isMerchantActivated = store.getters['authStore/getIsMerchantActivated'];
    const locale = store.getters.getLocale;

    const exposedParametersList = [
      localisation.infoBlocks.infoBlock.exposedParametersList.list.generalInformation,
      localisation.infoBlocks.infoBlock.exposedParametersList.list.productIds,
      localisation.infoBlocks.infoBlock.exposedParametersList.list.subscriptions,
      localisation.infoBlocks.infoBlock.exposedParametersList.list.successfulOrdersAndRefunds,
      localisation.infoBlocks.infoBlock.exposedParametersList.list.productsAndPrices,
      localisation.infoBlocks.infoBlock.exposedParametersList.list.financialDataAndPayouts,
      localisation.infoBlocks.infoBlock.exposedParametersList.list.promotions,
      localisation.infoBlocks.infoBlock.exposedParametersList.list.customersData,
      localisation.infoBlocks.infoBlock.exposedParametersList.list.additionalData,
    ];

    const infoBlockText = utilsService.email.replaceSupportEmail(
      localisation.infoBlocks.infoBlock.text,
      isMerchantActivated,
    );

    const linksList = {
      orderAPI: {
        link: {
          url: utils.link.localizeURL(
            `${docsSiteUrl}/en-en/checkout/orders-api`,
            locale,
          ),
          title: localisation.infoBlocks.apiInfo.links.orderApi.title,
          target: '_blank',
          isRouterLink: false,
        },
        text: {
          text: localisation.infoBlocks.apiInfo.links.orderApi.text,
        },
      },
      subscriptionsAPI: {
        link: {
          url: utils.link.localizeURL(
            `${docsSiteUrl}/en-en/checkout/subscriptions-api-overview`,
            locale,
          ),
          title: localisation.infoBlocks.apiInfo.links.subscriptionsApi.title,
          target: '_blank',
          isRouterLink: false,
        },
        text: {
          text: localisation.infoBlocks.apiInfo.links.subscriptionsApi.text,
        },
      },
    };

    const learnMoreLink = utils.link.localizeURL(
      `${docsSiteUrl}/en-en/checkout/integration`,
      locale,
    );

    function handleSetUpWithSupport() {
      window.location.href = utils.link.generateLinkHref(
        utilsService.email.getSupportEmail(isMerchantActivated),
        'email',
        {
          subject: localisation.infoBlocks.infoBlock.emailSubject,
        },
      );
    }

    return {
      isMerchantActivated,
      locale,
      localisation,
      exposedParametersList,
      infoBlockText,
      linksList,
      learnMoreLink,
      handleSetUpWithSupport,
    };
  },
});
