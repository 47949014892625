export default function getPathWithoutParams(route) {
  const paramsValues = Object.values(route.params);
  let { path } = route;
  if (paramsValues.length > 0) {
    const parts = path.split('/');
    path = parts.slice(0, parts.length - paramsValues.length).join('/');
  }

  return path;
}
