<template>
  <div
    class="dashboard-shimmer"
  >
    <HeaderShimer
      :style="{ width: '100%' }"
    />
    <ChartShimer
      :style="{ width: '100%' }"
    />
    <div
      class="dashboard-shimmer__table"
    >
      <ShimmerLoader
        v-for="item in Array(12)"
        :key="item"
        class="dashboard-shimmer__table-item"
      />
    </div>
  </div>
</template>

<script>
import ChartShimer from '@/components/ShimerLoader/ChartShimer.vue';
import ShimmerLoader from '@/components/ShimerLoader/ShimerLoader.vue';
import HeaderShimer from '@/components/ShimerLoader/HeaderShimer.vue';

export default {
  name: 'DashboardPageShimmer',
  components: {
    HeaderShimer,
    ShimmerLoader,
    ChartShimer,
  },
};
</script>

<style
  scoped
  lang="scss"
>
@import "@/module/common/components/variables.scss";

.dashboard-shimmer {
  display: flex;
  flex-direction: column;
  gap: 52px;
  width: 100%;

  &__table {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    gap: 24px;

    &-item {
      height: 75px;
      width: 22.4%;
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .dashboard-shimmer {
    &__table {
      gap: 20px;

      &-item {
        width: 29%;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .dashboard-shimmer {
    &__table {
      gap: 16px;

      &-item {
        width: 47%;
      }
    }
  }
}
</style>
