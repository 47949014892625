import IValidateHandler from '@/module/product/view/ProductFormPage/handler/contract/IValidateHandler';
import TFormData from '@/module/product/model/FormData/TFormData';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IValidator from '@/module/common/utils/validator/contract/IValidator';
import EFieldName from '@/module/product/view/ProductFormPage/model/EFieldName';
import Validator from '@/module/common/utils/validator/Validator';
import EValidatorType from '@/module/common/utils/validator/enum/EValidatorType';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import IVueInstance from '@/module/common/models/IVueInstance';
import IFieldStatusHandler
  from '@/module/common/components/FormComponent/ValidatorHandler/contract/IFieldStatusHandler';
import TValidationStatus from '@/module/product/view/ProductFormPage/model/TValidationStatus';
import ECalendarSymbol from '@/module/product/model/ECalendarSymbol';
import EFormFieldType from '@/module/design-system/components/models/FormComponent/EFormFieldType';
import checkCondition from '@/module/common/helpers/checkCondition';
import EPricingModelName from '@/module/product/model/Product/Fields/PricingModel/EPricingModelName';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import IFormFieldItem from '@/module/design-system/components/models/FormComponent/IFormFieldItem';
import EProtocols from '@/module/common/models/EProtocols';
// TODO: add lowercaseProtocol to utilsService
import { lowerCaseProtocol } from '@/module/product/view/CreateEditProductPage.func';
import IFieldStatus from '@/module/design-system/components/models/FormComponent/IFieldStatus';
import IVolumePricePricingTableItem from '@/module/product/model/FormData/Price/IVolumePricePricingTableItem';
import TPricingTableValue from '@/module/product/model/TPricingTableValue';
import IVolumePricingAndOptionsPricingTableItem
  from '@/module/product/model/FormData/Price/IVolumePricingAndOptionsPricingTableItem';
import IOnePriceForEachCurrencyPricingTable
  from '@/module/product/model/FormData/Price/IOnePriceForEachCurrencyPricingTableItem';
// TODO: add lowercaseProtocol to service
import onlyNumbersValidator from '@/module/common/utils/validator/validators/onlyNumbersValidator';

class ValidateHandler implements IValidateHandler {
  private serviceContainer: IServiceContainer;

  private readonly simpleFieldStatusHandler: IFieldStatusHandler;

  private readonly emptyFieldStatusHandler: IFieldStatusHandler;

  constructor(
    serviceContainer: IServiceContainer,
    simpleFieldStatusHandler: IFieldStatusHandler,
    emptyFieldStatusHandler: IFieldStatusHandler,
  ) {
    this.simpleFieldStatusHandler = simpleFieldStatusHandler;
    this.emptyFieldStatusHandler = emptyFieldStatusHandler;
    this.serviceContainer = serviceContainer;
  }

  private getStatuses = (): TValidationStatus => {
    const state = this.serviceContainer.moduleProduct.productFormPage.getState();
    const utilsService = this.serviceContainer.utilsService;

    return utilsService.data.cloneImmutable(state.statuses);
  };

  private updateStatuses = (statuses: TValidationStatus) => {
    const state = this.serviceContainer.moduleProduct.productFormPage.getState();

    state.statuses = statuses;
  };

  private updateFormConfig = () => {
    const formConfigHandler = this.serviceContainer.moduleProduct.productFormPage.handlerService.formConfigHandler;

    const formConfig = formConfigHandler.get();
    formConfigHandler.update(formConfig);
  };

  validateField = (fieldName: EFieldName, formData: TFormData) => {
    let validator = this.getFieldValidator(fieldName);
    if ([EFieldName.PRICE, EFieldName.RENEWAL_PRICE].includes(fieldName)) {
      validator = this.getPriceValidator(fieldName, this.emptyFieldStatusHandler);
    }

    if (!validator) {
      return;
    }

    const statuses = this.getStatuses();

    const value = formData[fieldName];

    validator.validate(value, formData);

    statuses[fieldName] = this.emptyFieldStatusHandler.getStatus(validator);

    this.updateStatuses(statuses);
  };

  validateForm(formData: TFormData) {
    const productFormPage = this.serviceContainer.moduleProduct.productFormPage;
    const formConfigHandler = productFormPage.handlerService.formConfigHandler;

    const formConfig = formConfigHandler.get();

    const skipValidationFieldTypeList = [
      EFormFieldType.SLOT,
      EFormFieldType.INFO_BLOCK,
    ];

    formConfig.forEach((block) => {
      block.items.forEach((field) => {
        let validator = this.getFieldValidator(field.fieldName);
        if ([
          EFieldName.PRICE,
          EFieldName.RENEWAL_PRICE,
        ].includes(field.fieldName)
        ) {
          validator = this.getPriceValidator(field.fieldName, this.simpleFieldStatusHandler);
        }

        if (
          !!validator
          && !skipValidationFieldTypeList.includes(field.type)
          && (
            !field.conditionsList
            || (
              checkCondition.resolveAndCondition(field.conditionsList, formData)
            )
          )
        ) {
          const value = formData[field.fieldName];

          this.checkFormField(field, value, formData, validator);
        }
      });
    });

    this.updateFormConfig();
  }

  private checkFormField = (
    field: IFormFieldItem<EFieldName, undefined>,
    value: unknown,
    formData: TFormData,
    validator: IValidator,
  ) => {
    const state = this.serviceContainer.moduleProduct.productFormPage.getState();

    validator.validate(value, formData);

    const statuses = this.getStatuses();
    const status = this.simpleFieldStatusHandler.getStatus(validator);
    statuses[field.fieldName] = status;

    state.isFormValid = status.code !== EValidationFieldStatusCode.ERROR && state.isFormValid;

    this.updateStatuses(statuses);
  };

  private getFieldValidator = (field: EFieldName): IValidator | null => {
    const vueInstance = this.serviceContainer.resolve<IVueInstance>(EServiceContainerInstances.VUE_INSTANCE);
    const localisationRegistry = this.serviceContainer.localisationRegistry;

    const fieldMap = {
      [EFieldName.TITLE]: new Validator(),
      [EFieldName.SUBTITLE]: new Validator(),
      [EFieldName.TRIAL_TITLE]: new Validator(),
      [EFieldName.TRIAL_SUBTITLE]: new Validator(),
      [EFieldName.RENEWAL_TITLE]: new Validator(),
      [EFieldName.RENEWAL_SUBTITLE]: new Validator(),
      [EFieldName.DESCRIPTION]: new Validator(),
      [EFieldName.LICENSE_TERM]: new Validator(),
      [EFieldName.TRIAL_TERM]: new Validator(),
      [EFieldName.RENEWAL_TERM]: new Validator(),
    };

    fieldMap[EFieldName.TITLE].addValidator(
      EValidatorType.NOT_EMPTY,
      localisationRegistry.validationMessages.required,
    );
    fieldMap[EFieldName.TITLE].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      255,
    );

    fieldMap[EFieldName.SUBTITLE].addValidator(
      EValidatorType.NOT_EMPTY,
      localisationRegistry.validationMessages.required,
    );
    fieldMap[EFieldName.SUBTITLE].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      255,
    );

    fieldMap[EFieldName.TRIAL_TITLE].addValidator(
      EValidatorType.NOT_EMPTY,
      localisationRegistry.validationMessages.required,
    );
    fieldMap[EFieldName.TRIAL_TITLE].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      255,
    );

    fieldMap[EFieldName.TRIAL_SUBTITLE].addValidator(
      EValidatorType.NOT_EMPTY,
      localisationRegistry.validationMessages.required,
    );
    fieldMap[EFieldName.TRIAL_SUBTITLE].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      255,
    );

    fieldMap[EFieldName.RENEWAL_TITLE].addValidator(
      EValidatorType.NOT_EMPTY,
      localisationRegistry.validationMessages.required,
    );
    fieldMap[EFieldName.RENEWAL_TITLE].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      255,
    );

    fieldMap[EFieldName.RENEWAL_SUBTITLE].addValidator(
      EValidatorType.NOT_EMPTY,
      localisationRegistry.validationMessages.required,
    );
    fieldMap[EFieldName.RENEWAL_SUBTITLE].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      255,
    );

    fieldMap[EFieldName.DESCRIPTION].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 300 }),
      300,
    );

    fieldMap[EFieldName.LICENSE_TERM].addValidator(
      EValidatorType.CUSTOM_NOT_EMPTY,
      localisationRegistry.validationMessages.required,
      (value) => !!value.inputValue,
    );
    fieldMap[EFieldName.LICENSE_TERM].addValidator(
      EValidatorType.CUSTOM,
      localisationRegistry.validationMessages.onlyNumbers,
      (value) => !Number.isNaN(+value.inputValue),
    );
    fieldMap[EFieldName.LICENSE_TERM].addValidator(
      EValidatorType.CUSTOM,
      vueInstance.$t('validation_messages.greater_or_equal', { number: 30 }),
      (value) => {
        if (value.selectValue.id !== ECalendarSymbol.D) {
          return true;
        }

        const localValue = Number.isNaN(+value.inputValue) ? 0 : +value.inputValue;

        return localValue >= 30;
      },
    );
    fieldMap[EFieldName.LICENSE_TERM].addValidator(
      EValidatorType.CUSTOM,
      vueInstance.$t('validation_messages.greater_than', { number: 0 }),
      (value) => {
        const localValue = Number.isNaN(+value.inputValue) ? 0 : +value.inputValue;

        return localValue > 0;
      },
    );

    fieldMap[EFieldName.TRIAL_TERM].addValidator(
      EValidatorType.CUSTOM_NOT_EMPTY,
      localisationRegistry.validationMessages.required,
      (value) => !!value.inputValue,
    );
    fieldMap[EFieldName.TRIAL_TERM].addValidator(
      EValidatorType.CUSTOM,
      localisationRegistry.validationMessages.onlyNumbers,
      (value) => !Number.isNaN(+value.inputValue),
    );
    fieldMap[EFieldName.TRIAL_TERM].addValidator(
      EValidatorType.CUSTOM,
      vueInstance.$t('validation_messages.greater_or_equal', { number: 30 }),
      (value) => {
        if (value.selectValue.id !== ECalendarSymbol.D) {
          return true;
        }

        const localValue = Number.isNaN(+value.inputValue) ? 0 : +value.inputValue;

        return localValue >= 30;
      },
    );
    fieldMap[EFieldName.TRIAL_TERM].addValidator(
      EValidatorType.CUSTOM,
      vueInstance.$t('validation_messages.greater_than', { number: 0 }),
      (value) => {
        const localValue = Number.isNaN(+value.inputValue) ? 0 : +value.inputValue;

        return localValue > 0;
      },
    );

    fieldMap[EFieldName.RENEWAL_TERM].addValidator(
      EValidatorType.CUSTOM_NOT_EMPTY,
      localisationRegistry.validationMessages.required,
      (value) => !!value.inputValue,
    );
    fieldMap[EFieldName.RENEWAL_TERM].addValidator(
      EValidatorType.CUSTOM,
      localisationRegistry.validationMessages.onlyNumbers,
      (value) => !Number.isNaN(+value.inputValue),
    );
    fieldMap[EFieldName.RENEWAL_TERM].addValidator(
      EValidatorType.CUSTOM,
      vueInstance.$t('validation_messages.greater_or_equal', { number: 30 }),
      (value) => {
        if (value.selectValue.id !== ECalendarSymbol.D) {
          return true;
        }

        const localValue = Number.isNaN(+value.inputValue) ? 0 : +value.inputValue;

        return localValue >= 30;
      },
    );
    fieldMap[EFieldName.RENEWAL_TERM].addValidator(
      EValidatorType.CUSTOM,
      vueInstance.$t('validation_messages.greater_than', { number: 0 }),
      (value) => {
        const localValue = Number.isNaN(+value.inputValue) ? 0 : +value.inputValue;

        return localValue > 0;
      },
    );

    return fieldMap[field] ?? null;
  };

  validateNumberField = (value: string, fieldStatusHandler: IFieldStatusHandler) => {
    const localisationRegistry = this.serviceContainer.localisationRegistry;
    const state = this.serviceContainer.moduleProduct.productFormPage.getState();
    const utilsService = this.serviceContainer.utilsService;

    const validator = new Validator();
    validator.addValidator(
      EValidatorType.NOT_EMPTY,
      localisationRegistry.validationMessages.required,
    );
    validator.addValidator(
      EValidatorType.ONLY_NUMBERS,
      localisationRegistry.validationMessages.onlyNumbers,
    );
    validator.validate(value);

    validator.validate(value as string);

    const softwareRegistryStatus = utilsService.data.cloneImmutable(state.softwareRegistryStatus);
    softwareRegistryStatus.number = fieldStatusHandler.getStatus(validator);

    state.softwareRegistryStatus = softwareRegistryStatus;
  };

  validateLinkField = (value: string, fieldStatusHandler: IFieldStatusHandler) => {
    const state = this.serviceContainer.moduleProduct.productFormPage.getState();
    const localisationRegistry = this.serviceContainer.localisationRegistry;
    const utilsService = this.serviceContainer.utilsService;

    const validator = new Validator();
    validator.addValidator(
      EValidatorType.NOT_EMPTY,
      localisationRegistry.validationMessages.required,
    );
    validator.addValidator(
      EValidatorType.URL,
      localisationRegistry.validationMessages.url,
      [EProtocols.HTTPS, EProtocols.HTTP],
    );
    validator.addValidator(
      EValidatorType.MAX_LENGTH,
      localisationRegistry.validationMessages.maxLength,
      255,
    );

    validator.validate(lowerCaseProtocol(value as string));

    const softwareRegistryStatus = utilsService.data.cloneImmutable(state.softwareRegistryStatus);
    softwareRegistryStatus.link = fieldStatusHandler.getStatus(validator);

    state.softwareRegistryStatus = softwareRegistryStatus;
  };

  isSoftwareRegistryValid = (softwareRegistryStatus: {
    link: IFieldStatus;
    number: IFieldStatus;
  }): boolean => {
    const isValidNumber = softwareRegistryStatus.number.code !== EValidationFieldStatusCode.ERROR;
    const isValidLink = softwareRegistryStatus.link.code !== EValidationFieldStatusCode.ERROR;

    return isValidNumber && isValidLink;
  };

  private getPriceValidator = (
    fieldName: EFieldName,
    fieldStatusHandler: IFieldStatusHandler,
  ): IValidator => {
    const localisationRegistry = this.serviceContainer.localisationRegistry;

    const validator = new Validator();
    validator.addValidator(
      EValidatorType.CUSTOM_NOT_EMPTY,
      localisationRegistry.validationMessages.required,
      (value, formData) => this.validatePrice(value, formData, fieldName, fieldStatusHandler),
    );

    return validator;
  };

  private validatePrice = (
    value: unknown,
    formData: TFormData,
    fieldName: EFieldName,
    fieldStatusHandler: IFieldStatusHandler,
  ): boolean => {
    const pricingModelName = formData.pricingModel.id;
    const pricingTableHelper = this.serviceContainer.moduleProduct.pricingTableHelper;

    if (pricingModelName === EPricingModelName.ONE_PRICE) {
      const onePriceValue = value as undefined | { inputValue: string };

      return !!onePriceValue?.inputValue && (onePriceValue.inputValue as string).length > 0;
    }

    const localValue = value as TPricingTableValue;

    if (pricingTableHelper.isVolumePrice(localValue)) {
      return this.validateVolumePriceValue(localValue, formData, fieldName, fieldStatusHandler);
    }

    if (pricingTableHelper.isVolumePriceAndOptions(localValue)) {
      return this.validateVolumePriceAndOptionsValue(localValue, formData, fieldName, fieldStatusHandler);
    }

    if (pricingTableHelper.isOnePriceForEachCurrency(localValue)) {
      return this.validateOnePriceEachCurrencyValue(localValue, formData, fieldName, fieldStatusHandler);
    }

    return true;
  };

  private validateVolumePriceValue = (
    value: IVolumePricePricingTableItem,
    formData: TFormData,
    fieldName: EFieldName,
    fieldStatusHandler: IFieldStatusHandler,
  ): boolean => {
    const utilsService = this.serviceContainer.utilsService;
    const formDataHandler = this.serviceContainer.moduleProduct.productFormPage.handlerService.formDataHandler;
    const localFormData = utilsService.data.cloneImmutable(formData);

    let isValid = true;
    value.value.columns = value.value.columns.map((column, index) => {
      const localColumn = utilsService.data.cloneImmutable(column);
      const errorStatuses = [
        EValidationFieldStatusCode.ERROR,
        EValidationFieldStatusCode.EMPTY,
      ];

      localColumn.from.status = this.validatePriceFromField(
        fieldStatusHandler,
        localColumn.from.value,
        localColumn.to.value,
        value.value.columns[index - 1]?.to.value,
      );

      isValid = isValid && !errorStatuses.includes(localColumn.from.status.code);

      localColumn.to.status = this.validatePriceToField(
        fieldStatusHandler,
        localColumn.to.value,
        localColumn.from.value,
        value.value.columns[index + 1]?.from.value,
      );

      isValid = isValid && !errorStatuses.includes(localColumn.to.status.code);

      let priceFieldStatus = this.validatePriceValueField(
        fieldStatusHandler,
        localColumn.price.value,
      );
      if (
        priceFieldStatus.code === EValidationFieldStatusCode.EMPTY
        && localColumn.price.status.code === EValidationFieldStatusCode.ERROR
      ) {
        priceFieldStatus = localColumn.price.status;
      }
      localColumn.price.status = priceFieldStatus;

      isValid = isValid && !errorStatuses.includes(localColumn.price.status.code);

      return localColumn;
    });

    localFormData[fieldName] = value;

    formDataHandler.update(localFormData);

    return isValid;
  };

  private validateVolumePriceAndOptionsValue = (
    value: IVolumePricingAndOptionsPricingTableItem,
    formData: TFormData,
    fieldName: EFieldName,
    fieldStatusHandler: IFieldStatusHandler,
  ): boolean => {
    const utilsService = this.serviceContainer.utilsService;
    const formDataHandler = this.serviceContainer.moduleProduct.productFormPage.handlerService.formDataHandler;
    const localFormData = utilsService.data.cloneImmutable(formData);

    const excludedFields = ['id', 'from', 'to', 'sku'];

    const isThereValueMap = {};

    value.value.forEach((column) => {
      Object.keys(column).forEach((key) => {
        if (!excludedFields.includes(key)) {
          isThereValueMap[key] = isThereValueMap[key] || !!column[key].value;
        }
      });
    });

    let isValid = true;
    value.value = value.value.map((column, index) => {
      const localColumn = utilsService.data.cloneImmutable(column);
      const errorStatuses = [
        EValidationFieldStatusCode.ERROR,
        EValidationFieldStatusCode.EMPTY,
      ];

      localColumn.from.status = this.validatePriceFromField(
        fieldStatusHandler,
        localColumn.from.value,
        localColumn.to.value,
        value.value[index - 1]?.to.value,
      );

      isValid = isValid && !errorStatuses.includes(localColumn.from.status.code);

      localColumn.to.status = this.validatePriceToField(
        fieldStatusHandler,
        localColumn.to.value,
        localColumn.from.value,
        value.value[index + 1]?.from.value,
      );

      isValid = isValid && !errorStatuses.includes(localColumn.to.status.code);

      let isSuccessPriceInColumn = false;

      Object.keys(localColumn).forEach((fieldName) => {
        if (!excludedFields.includes(fieldName)) {
          const localStatus = this.validatePriceValueField(fieldStatusHandler, localColumn[fieldName].value);

          if (
            !(localStatus.code === EValidationFieldStatusCode.EMPTY
            && localColumn[fieldName].status.code === EValidationFieldStatusCode.ERROR)
          ) {
            localColumn[fieldName].status = localStatus;
          }

          if (!errorStatuses.includes(localStatus.code)) {
            isSuccessPriceInColumn = true;
          }
        }
      });

      Object.keys(localColumn).forEach((fieldName) => {
        if (!excludedFields.includes(fieldName)) {
          const defaultStatus = { code: EValidationFieldStatusCode.DEFAULT };

          if (!isThereValueMap[fieldName]) {
            localColumn[fieldName].status = isSuccessPriceInColumn ? defaultStatus : localColumn[fieldName].status;
          } else {
            isValid = isValid && !errorStatuses.includes(localColumn[fieldName].status.code);
          }
        }
      });

      isValid = isValid && isSuccessPriceInColumn;

      return localColumn;
    });

    localFormData[fieldName] = value;

    formDataHandler.update(localFormData);

    return isValid;
  };

  private validateOnePriceEachCurrencyValue = (
    value: IOnePriceForEachCurrencyPricingTable,
    formData: TFormData,
    fieldName: EFieldName,
    fieldStatusHandler: IFieldStatusHandler,
  ): boolean => {
    const utilsService = this.serviceContainer.utilsService;
    const formDataHandler = this.serviceContainer.moduleProduct.productFormPage.handlerService.formDataHandler;
    const localFormData = utilsService.data.cloneImmutable(formData);

    const localValue = utilsService.data.cloneImmutable(value.value);

    let isValid = true;
    const errorStatuses = [
      EValidationFieldStatusCode.ERROR,
      EValidationFieldStatusCode.EMPTY,
    ];

    const skipFields = ['id', 'from', 'to', 'sku'];
    let status: IFieldStatus = {
      code: EValidationFieldStatusCode.DEFAULT,
    };
    let isSuccessPriceInColumn = false;

    Object.keys(localValue).forEach((fieldName) => {
      if (!skipFields.includes(fieldName)) {
        const localStatus = this.validatePriceValueField(fieldStatusHandler, localValue[fieldName].value);

        if (errorStatuses.includes(localStatus.code)) {
          status = localStatus;
        } else {
          isSuccessPriceInColumn = true;
        }
      }
    });

    Object.keys(localValue).forEach((fieldName) => {
      if (!skipFields.includes(fieldName)) {
        const defaultStatus = { code: EValidationFieldStatusCode.DEFAULT };

        if (
          status.code === EValidationFieldStatusCode.EMPTY
            && localValue[fieldName].status.code === EValidationFieldStatusCode.ERROR
        ) {
          status = localValue[fieldName].status;
        }

        localValue[fieldName].status = isSuccessPriceInColumn ? defaultStatus : status;
      }
    });

    isValid = isValid && isSuccessPriceInColumn;

    value.value = localValue;

    localFormData[fieldName] = value;

    formDataHandler.update(localFormData);

    return isValid;
  };

  private validatePriceFromField = (
    fieldStatusHandler: IFieldStatusHandler,
    value: string,
    toValue: string,
    prevColumnToValue?: string,
  ): IFieldStatus => {
    const localisationRegistry = this.serviceContainer.localisationRegistry;

    const validator = new Validator();
    validator.addValidator(
      EValidatorType.NOT_EMPTY,
      localisationRegistry.validationMessages.required,
    );
    validator.addValidator(
      EValidatorType.ONLY_NUMBERS,
      localisationRegistry.validationMessages.onlyNumbers,
    );
    validator.addValidator(
      EValidatorType.CUSTOM,
      localisationRegistry.validationMessages.forksGreaterThan,
      (value) => {
        if (!prevColumnToValue) {
          return true;
        }

        if (toValue === '∞') {
          return true;
        }

        return +toValue > +value && +value > +prevColumnToValue;
      },
    );

    validator.validate(value);

    return fieldStatusHandler.getStatus(validator);
  };

  private validatePriceToField = (
    fieldStatusHandler: IFieldStatusHandler,
    value: string,
    fromValue: string,
    nextColumnFromValue?: string,
  ): IFieldStatus => {
    const localisationRegistry = this.serviceContainer.localisationRegistry;

    const validator = new Validator();
    validator.addValidator(
      EValidatorType.NOT_EMPTY,
      localisationRegistry.validationMessages.required,
    );
    validator.addValidator(
      EValidatorType.CUSTOM,
      localisationRegistry.validationMessages.onlyNumbers,
      (value) => {
        if (value === '∞') {
          return true;
        }

        return onlyNumbersValidator(value);
      },
    );
    validator.addValidator(
      EValidatorType.CUSTOM,
      localisationRegistry.validationMessages.forksGreaterThan,
      (value) => {
        if (value === '∞') {
          return true;
        }

        if (!nextColumnFromValue && fromValue) {
          return +fromValue < +value;
        }

        return +fromValue < +value && +value < +(nextColumnFromValue ?? '');
      },
    );

    validator.validate(value);

    return fieldStatusHandler.getStatus(validator);
  };

  private validatePriceValueField = (
    fieldStatusHandler: IFieldStatusHandler,
    value: string,
  ): IFieldStatus => {
    const localisationRegistry = this.serviceContainer.localisationRegistry;

    const validator = new Validator();
    validator.addValidator(
      EValidatorType.NOT_EMPTY,
      localisationRegistry.validationMessages.required,
    );

    validator.validate(value);

    return fieldStatusHandler.getStatus(validator);
  };
}

export default ValidateHandler;
