<template>
  <div class="tags">
    <DSTooltip
      v-for="(tag, index) in list"
      :key="tag.name + index"
      :text="tag.tooltip"
    >
      <DSTag
        :color="tag.color"
        :background-color="tag.background"
        :text="tag.name"
      />
    </DSTooltip>
  </div>
</template>

<script>
import DSTag from '@/module/design-system/components/InfoBlock/DSTag.vue';
import DSTooltip from '@/module/design-system/components/InfoBlock/DSTooltip.vue';

export default {
  name: 'PromoTags',
  components: {
    DSTooltip,
    DSTag,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.tags {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 12px 0;
  padding: 0;
  gap: 12px;
}
</style>
