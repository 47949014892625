<template>
  <div
    class="c-select"
  >
    <CLabel
      v-if="label"
      :label-for="select"
      :tooltip="tooltip"
      :text="label"
      class="c-select__label"
    />
    <div class="c-select__row c-select__row_select">
      <DSSelect
        ref="select"
        :placeholder="placeholder"
        :status="status.code"
        :value="localValue"
        :id-field="idField"
        :title-field="titleField"
        class="c-select__field"
        :list="list"
        :offset="offset"
        :icon-only="iconOnly"
        :alternate-icon="alternateIcon"
        @input="$emit('input', $event)"
        @blur="$emit('blur', $event)"
        @scroll-y-reach-end="$emit('scroll-y-reach-end')"
      >
        <template
          #before-list
        >
          <slot
            name="before-list"
          />
        </template>
        <template
          #list-item="slotProps"
        >
          <slot
            name="list-item"
            :option="slotProps.option"
          />
          <CRadioButton
            v-if="!isListItemSlot"
            :checked="isSelectedItem(slotProps.option)"
            :name="radioButtonName"
            :title="readOptionTitle(slotProps.option)"
            :value="readOptionId(slotProps.option)"
          />
        </template>
        <template
          #after-list
        >
          <slot
            name="after-list"
          />
        </template>
      </DSSelect>
      <div
        v-if="isAfterSelectIconSlot"
        class="c-select__icon"
      >
        <slot
          name="after-select-icon"
        />
      </div>
    </div>
    <DSTextElement
      v-if="description"
      class="c-select__comment"
      color="secondary"
      variant="small"
    >
      <!-- eslint-disable vue/no-v-html -->
      <span
        v-html="description"
      />
      <!-- eslint-enable vue/no-v-html -->
    </DSTextElement>
    <DSTextElement
      v-if="status.text"
      :color="status.code"
      class="c-select__comment"
      variant="small"
    >
      {{ status.text }}
    </DSTextElement>
  </div>
</template>

<script>
import CLabel from '@/module/common/components/UI/CLabel.vue';
import CRadioButton from '@/module/common/components/UI/СRadioButton.vue';
import DSSelect from '@/module/design-system/components/UI/DSSelect.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default {
  name: 'CSelect',
  components: {
    CLabel,
    CRadioButton,
    DSSelect,
    DSTextElement,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      required: true,
    },
    titleField: {
      type: String,
      default: 'title',
    },
    idField: {
      type: String,
      default: 'id',
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    status: {
      type: Object,
      default: () => ({
        code: 'default',
      }),
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    alternateIcon: {
      type: String,
      default: 'arrowOppositelyDirected',
    },
    offset: {
      type: Number,
      default: null,
    },
    autoSelectSingleValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      select: null,
      localValue: null,
    };
  },
  watch: {
    value() {
      this.localValue = this.value;
    },
    list() {
      this.autoSelect();
    },
  },
  computed: {
    radioButtonName() {
      return `radio-button-${this._uid}`;
    },
    isListItemSlot() {
      return !!this.$scopedSlots['list-item'];
    },
    isAfterSelectIconSlot() {
      return !!this.$scopedSlots['after-select-icon'];
    },
  },
  mounted() {
    this.select = this.$refs.select;
    this.localValue = this.value;
    this.autoSelect();
  },
  methods: {
    autoSelect() {
      if (this.autoSelectSingleValue && !this.localValue?.[this.idField] && (this.list ?? []).length === 1) {
        this.localValue = this.list[0];
        this.$emit('blur', this.localValue);
      }
    },
    isSelectedItem(option) {
      return option[this.idField] === this.value[this.idField];
    },
    readOptionTitle(option) {
      return option[this.titleField] ?? '';
    },
    readOptionId(option) {
      return option[this.idField] ?? '';
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.c-select {
  &__label {
    margin-bottom: 8px;
  }

  &__row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: stretch;

    &_select {
      flex-wrap: nowrap;
      gap: 10px;
    }
  }

  &__field {
    flex: 1 0 auto;
  }

  &__icon {
    flex: 0 0 auto;
    cursor: pointer;
  }

  &__comment {
    display: block;
    margin-top: 8px;
  }
}
</style>
