import IFormConfigHandler from '@/module/product/view/FulfillmentFormPage/model/contract/handler/IFormConfigHandler';
import IFormDataHandler from '@/module/product/view/FulfillmentFormPage/model/contract/handler/IFormDataHandler';
import IFormHandler from '@/module/product/view/FulfillmentFormPage/model/contract/handler/IFormHandler';
import IInitialDataLoadHandler
  from '@/module/product/view/FulfillmentFormPage/model/contract/handler/IInitialDataLoadHandler';
import ILicenseSourceHandler
  from '@/module/product/view/FulfillmentFormPage/model/contract/handler/ILicenseSourceHandler';
import ILicenseVariantHandler
  from '@/module/product/view/FulfillmentFormPage/model/contract/handler/ILicenseVariantHandler';
import IHandlerService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IHandlerService';
import LicenseSourceHandler from '@/module/product/view/FulfillmentFormPage/handler/LicenseSourceHandler';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import LicenseVariantHandler from '@/module/product/view/FulfillmentFormPage/handler/LicenseVariantHandler';
import FormDataHandler from '@/module/product/view/FulfillmentFormPage/handler/FormDataHandler';
import FormConfigHandler from '@/module/product/view/FulfillmentFormPage/handler/FormConfigHandler';
import IState from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/IState';
import FormHandler from '@/module/product/view/FulfillmentFormPage/handler/FormHandler';
import ILocalServiceContainer from '@/module/product/view/FulfillmentFormPage/model/ILocalServiceContainer';
import InitialDataLoadHandler from '@/module/product/view/FulfillmentFormPage/handler/InitialDataLoadHandler';
import IHttpMethodHandler from '@/module/product/view/FulfillmentFormPage/model/contract/handler/IHttpMethodHandler';
import HttpMethodHandler from '@/module/product/view/FulfillmentFormPage/handler/HttpMethodHandler';
import IValidateHandler from '@/module/common/components/FormComponent/ValidatorHandler/contract/IValidateHandler';
import TValidatorConfig from '@/module/product/view/FulfillmentFormPage/model/types/TValidatorConfig';
import TFormData from '@/module/product/view/FulfillmentFormPage/model/types/TFormData';
import EFieldName from '@/module/product/view/FulfillmentFormPage/model/enums/EFieldName';
import ValidateHandler from '@/module/common/components/FormComponent/ValidatorHandler/ValidateHandler';
import ValidationConfig from '@/module/product/view/FulfillmentFormPage/config/ValidationConfig';
import EmptyFieldStatusHandler from '@/module/common/components/FormComponent/ValidatorHandler/EmptyFieldStatusHandler';
import SimpleFieldStatusHandler
  from '@/module/common/components/FormComponent/ValidatorHandler/SimpleFieldStatusHandler';
import IPageValidateHandler
  from '@/module/product/view/FulfillmentFormPage/model/contract/handler/validate/IValidateHandler';
import PageValidateHandler from '@/module/product/view/FulfillmentFormPage/handler/validate/ValidateHandler';
import IFieldStatusHandler
  from '@/module/common/components/FormComponent/ValidatorHandler/contract/IFieldStatusHandler';
import ParamsValidateHandler from '@/module/product/view/FulfillmentFormPage/handler/validate/ParamsValidateHandler';
import IParamsValidateHandler
  from '@/module/product/view/FulfillmentFormPage/model/contract/handler/validate/IParamsValidateHandler';
import IModalHandler from '@/module/product/view/FulfillmentFormPage/model/contract/handler/IModalHandler';
import ModalHandler from '@/module/product/view/FulfillmentFormPage/handler/ModalHandler';

class HandlerService implements IHandlerService {
  private readonly state: IState;

  private readonly serviceContainer: IServiceContainer;

  private readonly localServiceContainer: ILocalServiceContainer;

  private formConfigHandler?: IFormConfigHandler;

  private formDataHandler?: IFormDataHandler;

  private formHandler?: IFormHandler;

  private fieldValidatorHandler?: IValidateHandler<TValidatorConfig, TFormData, EFieldName>;

  private formValidatorHandler?: IValidateHandler<TValidatorConfig, TFormData, EFieldName>;

  private httpMethodHandler?: IHttpMethodHandler;

  private initialDataLoadHandler?: IInitialDataLoadHandler;

  private licenseSourceHandler?: ILicenseSourceHandler;

  private licenseVariantHandler?: ILicenseVariantHandler;

  private pageValidateHandler?: IPageValidateHandler;

  private emptyFieldStatusHandler?: IFieldStatusHandler;

  private simpleFieldStatusHandler?: IFieldStatusHandler;

  private paramsValidateHandler?: IParamsValidateHandler;

  private modalHandler?: IModalHandler;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    localServiceContainer: ILocalServiceContainer,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.localServiceContainer = localServiceContainer;
  }

  getFormConfigHandler = (): IFormConfigHandler => {
    if (!this.formConfigHandler) {
      this.formConfigHandler = new FormConfigHandler(
        this.state,
        this.serviceContainer,
        this.localServiceContainer,
      );
    }

    return this.formConfigHandler;
  };

  getFormDataHandler = (): IFormDataHandler => {
    if (!this.formDataHandler) {
      this.formDataHandler = new FormDataHandler(
        this.serviceContainer,
        this.localServiceContainer,
      );
    }

    return this.formDataHandler;
  };

  getFormHandler = (): IFormHandler => {
    if (!this.formHandler) {
      this.formHandler = new FormHandler(
        this.state,
        this.localServiceContainer,
      );
    }

    return this.formHandler;
  };

  getInitialDataLoadHandler = (): IInitialDataLoadHandler => {
    if (!this.initialDataLoadHandler) {
      this.initialDataLoadHandler = new InitialDataLoadHandler(
        this.state,
        this.serviceContainer,
        this.localServiceContainer,
      );
    }

    return this.initialDataLoadHandler;
  };

  getLicenseSourceHandler = (): ILicenseSourceHandler => {
    if (!this.licenseSourceHandler) {
      this.licenseSourceHandler = new LicenseSourceHandler(
        this.localServiceContainer,
      );
    }

    return this.licenseSourceHandler;
  };

  getLicenseVariantHandler = (): ILicenseVariantHandler => {
    if (!this.licenseVariantHandler) {
      this.licenseVariantHandler = new LicenseVariantHandler(
        this.state,
        this.serviceContainer,
      );
    }

    return this.licenseVariantHandler;
  };

  getHttpMethodHandler = (): IHttpMethodHandler => {
    if (!this.httpMethodHandler) {
      this.httpMethodHandler = new HttpMethodHandler(
        this.state,
        this.localServiceContainer,
      );
    }

    return this.httpMethodHandler;
  };

  getFieldValidatorHandler = (): IValidateHandler<TValidatorConfig, TFormData, EFieldName> => {
    if (!this.formValidatorHandler) {
      const config = new ValidationConfig(
        this.state,
        this.serviceContainer,
        this.localServiceContainer,
      );

      this.formValidatorHandler = new ValidateHandler(
        config,
        this.getEmptyFieldStatusHandler(),
      );
    }

    return this.formValidatorHandler;
  };

  getFormValidatorHandler = (): IValidateHandler<TValidatorConfig, TFormData, EFieldName> => {
    if (!this.fieldValidatorHandler) {
      const config = new ValidationConfig(
        this.state,
        this.serviceContainer,
        this.localServiceContainer,
      );

      this.fieldValidatorHandler = new ValidateHandler(
        config,
        this.getSimpleFieldStatusHandler(),
      );
    }

    return this.fieldValidatorHandler;
  };

  getPageValidatorHandler = (): IPageValidateHandler => {
    if (!this.pageValidateHandler) {
      this.pageValidateHandler = new PageValidateHandler(
        this.state,
        this.serviceContainer,
        this.localServiceContainer,
      );
    }

    return this.pageValidateHandler;
  };

  getEmptyFieldStatusHandler = (): IFieldStatusHandler => {
    if (!this.emptyFieldStatusHandler) {
      this.emptyFieldStatusHandler = new EmptyFieldStatusHandler();
    }

    return this.emptyFieldStatusHandler;
  };

  getSimpleFieldStatusHandler = (): IFieldStatusHandler => {
    if (!this.simpleFieldStatusHandler) {
      this.simpleFieldStatusHandler = new SimpleFieldStatusHandler();
    }

    return this.simpleFieldStatusHandler;
  };

  getParamsValidateHandler = (): IParamsValidateHandler => {
    if (!this.paramsValidateHandler) {
      this.paramsValidateHandler = new ParamsValidateHandler(this.serviceContainer);
    }

    return this.paramsValidateHandler;
  };

  getModalHandler = (): IModalHandler => {
    if (!this.modalHandler) {
      this.modalHandler = new ModalHandler(
        this.state,
        this.serviceContainer,
      );
    }

    return this.modalHandler;
  };
}

export default HandlerService;
