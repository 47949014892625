import IFile from '@/module/common/models/UI/FileUpload/IFile';

function downloadFile(href, file: IFile) {
  const url = URL.createObjectURL(href);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', file.fileName);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

export default downloadFile;
