import THandleFieldBlurParams from '@/module/product/view/ProductFormPage/model/THandleFieldBlurParams';
import IFieldBlurScenario from '@/module/product/view/ProductFormPage/scenario/contract/IFieldBlurScenario';
import EFieldName from '@/module/product/view/ProductFormPage/model/EFieldName';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import PricingModelFieldBlurScenario
  from '@/module/product/view/ProductFormPage/scenario/PricingModelFieldBlurScenario';
import IScenarioService
  from '@/module/product/view/ProductFormPage/service/contract/IScenarioService';
import DefaultFieldBlurScenario from '@/module/product/view/ProductFormPage/scenario/DefaultFieldBlurScenario';
import RenewalFieldBlurScenario from '@/module/product/view/ProductFormPage/scenario/RenewalFieldBlurScenario';
import TermFieldBlurScenario from '@/module/product/view/ProductFormPage/scenario/TermFieldBlurScenario';
import PriceBlurScenario from '@/module/product/view/ProductFormPage/scenario/PriceBlurScenario';
import TrialFieldBlurScenario from '@/module/product/view/ProductFormPage/scenario/TrialFieldBlurScenario';

class ScenarioService implements IScenarioService {
  private readonly serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  getBlurScenario = (field: THandleFieldBlurParams): IFieldBlurScenario => {
    const map = {
      [EFieldName.PRICING_MODEL]: new PricingModelFieldBlurScenario(this.serviceContainer),
      [EFieldName.RENEWAL]: new RenewalFieldBlurScenario(this.serviceContainer),
      [EFieldName.TRIAL]: new TrialFieldBlurScenario(this.serviceContainer),
      [EFieldName.LICENSE_TERM]: new TermFieldBlurScenario(this.serviceContainer),
      [EFieldName.RENEWAL_TERM]: new TermFieldBlurScenario(this.serviceContainer),
      [EFieldName.TRIAL_TERM]: new TermFieldBlurScenario(this.serviceContainer),
      [EFieldName.PRICE]: new PriceBlurScenario(this.serviceContainer),
      [EFieldName.RENEWAL_PRICE]: new PriceBlurScenario(this.serviceContainer),
    };

    return map[field.field.fieldName] ?? new DefaultFieldBlurScenario(this.serviceContainer);
  };

  getDefault = (): IFieldBlurScenario => new DefaultFieldBlurScenario(this.serviceContainer);
}

export default ScenarioService;
