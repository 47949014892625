<template>
  <div
    class="dashboard-filter"
  >
    <div
      class="dashboard-filter__block"
    >
      <label
        class="dashboard-filter__label"
      > {{ $t(datesLabel) }}</label>
      <div
        class="dashboard-filter__date"
      >
        <FilterDatePickers
          :initial="initial"
          :comparison="comparison"
          @submit="handleSubmit"
        />
      </div>
    </div>
    <UIDropdown
      class="dashboard-filter__block dashboard-filter__dimension"
      :model-value="dimension"
      :model-options="dimensionOptionsList"
      :with-radio-buttons="true"
      @update:modelValue="handleUpdateDimension"
    />

    <div
      class="dashboard-filter__block dashboard-filter__dimension-buttons"
    >
      <ButtonComponent
        v-for="option of dimensionOptionsList"
        :key="option.value"
        small
        :variant="dimension === option.value ? 'secondary' : 'tretiary-negative'"
        @click="handleUpdateDimension(option.value)"
      >
        {{ option.name }}
      </ButtonComponent>
    </div>
  </div>
</template>

<script>
import ButtonComponent from '@/components/Button/ButtonComponent.vue';
import FilterDatePickers from '@/components/FilterDatePicker/FilterDatePickers.vue';
import UIDropdown from '@/components/UI/UIDropdown.vue';

import moment from 'moment/moment';

export default {
  name: 'DashboardWidgetFilter',
  components: {
    FilterDatePickers,
    UIDropdown,
    ButtonComponent,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  data() {
    const prevDay = moment().subtract(1, 'days');

    return {
      datesLabel: 'pages.dashboard.filter.dates.label',
      today: moment(),
      yesterday: moment().subtract(1, 'days'),
      minDate: moment('1/1/2020', 'DD MM YYYY').toDate(),
      maxDate: moment().toDate(),
      initial: {
        selectionMode: 'L30D',
        dateFrom: moment(prevDay).subtract(29, 'days').toDate(),
        dateTo: moment(prevDay).toDate(),
        options: [
          {
            value: 'L30D',
            name: 'reports.overview.date_picker_options.last_30_days',
          },
          {
            value: 'L7D',
            name: 'reports.overview.date_picker_options.last_7_days',
          },
          {
            value: 'L4W',
            name: 'reports.overview.date_picker_options.last_4_weeks',
          },
          {
            value: 'L3M',
            name: 'reports.overview.date_picker_options.last_3_month',
          },
          {
            value: 'LY',
            name: 'reports.overview.date_picker_options.last_year',
          },
          {
            value: 'PM',
            name: 'reports.overview.date_picker_options.prev_month',
          },
          {
            value: 'TM',
            name: 'reports.overview.date_picker_options.this_month',
          },
          {
            value: 'TQ',
            name: 'reports.overview.date_picker_options.this_quarter',
          },
          {
            value: 'TY',
            name: 'reports.overview.date_picker_options.this_year',
          },
          {
            value: 'CM',
            name: 'reports.overview.date_picker_options.custom',
          },
        ],
      },
      comparison: {
        selectionMode: 'NC',
        dateFrom: moment().toDate(),
        dateTo: moment().toDate(),
        options: [
          {
            value: 'PP',
            name: 'reports.overview.date_picker_options.prev_period',
          },
          {
            value: 'PM',
            name: 'reports.overview.date_picker_options.prev_month',
          },
          {
            value: 'CM',
            name: 'reports.overview.date_picker_options.custom',
          },
          {
            value: 'PQ',
            name: 'reports.overview.date_picker_options.prev_quarter',
          },
          {
            value: 'PY',
            name: 'reports.overview.date_picker_options.prev_year',
          },
          {
            value: 'NC',
            name: 'reports.overview.date_picker_options.no_comparison',
          },
        ],
      },
      dimension: 'daily',
      dimensionOptionsList: [
        {
          value: 'daily',
          name: this.$t('pages.dashboard.filter.dimension.daily'),
        },
        {
          value: 'monthly',
          name: this.$t('pages.dashboard.filter.dimension.monthly'),
        },
      ],
    };
  },
  watch: {
    filter() {
      this.initFromFilter();
    },
  },
  mounted() {
    this.initFromFilter();
  },
  methods: {
    async handleSubmit(event) {
      this.initial.selectionMode = event.initial.selectionMode;
      this.initial.dateFrom = event.initial.dateFrom;
      this.initial.dateTo = event.initial.dateTo;

      this.comparison.selectionMode = event.comparison.selectionMode;
      this.comparison.dateFrom = event.comparison.dateFrom;
      this.comparison.dateTo = event.comparison.dateTo;

      this.emitUpdate();
    },
    handleUpdateDimension(dimension) {
      this.dimension = dimension;

      this.emitUpdate();
    },
    emitUpdate() {
      const selectionMode = this.initial.selectionMode;
      const date = {
        dateFrom: new Date(this.$utils.date.dayStart(this.initial.dateFrom)),
        dateTo: new Date(this.$utils.date.dayEnd(this.initial.dateTo)),
      };

      const comparisonSelectionMode = this.comparison.selectionMode;
      const comparisonDate = {
        dateFrom: new Date(this.$utils.date.dayStart(this.comparison.dateFrom)),
        dateTo: new Date(this.$utils.date.dayEnd(this.comparison.dateTo)),
      };

      const dimension = this.dimension;

      const filter = {
        date,
        comparisonDate,
        selectionMode,
        comparisonSelectionMode,
        dimension,
      };

      this.$emit('update-filter', filter);
    },
    initFromFilter() {
      const {
        date,
        comparisonDate,
        comparisonSelectionMode,
        selectionMode,
        dimension,
      } = this.filter;

      this.initial.selectionMode = selectionMode;
      this.initial.dateFrom = date.dateFrom;
      this.initial.dateTo = date.dateTo;

      this.comparison.selectionMode = comparisonSelectionMode;
      this.comparison.dateFrom = comparisonDate.dateFrom;
      this.comparison.dateTo = comparisonDate.dateTo;

      this.dimension = dimension;
    },
  },
};
</script>

<style
  lang="scss"
>
@import '@/module/design-system/components/variables.scss';

.dashboard-filter {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  row-gap: 12px;

  &__date {
    display: flex;
  }

  &__date &__date-picker {
    min-width: 250px;
    width: max-content;
  }

  &__date &__date-picker:first-child {
    button {
      border-right-width: 1px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    button.active {
      border-right-width: 2px;
      padding-right: 5px;
    }
  }

  &__date &__date-picker:last-child {
    button {
      border-left-width: 1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    button.active {
      border-left-width: 2px;
      padding-left: 5px;
    }
  }

  &__dimension {
    width: 170px;
  }

  &__dimension-buttons {
    margin-top: 12px;
    display: none;
    flex-direction: row;

    button {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0;
      border: 1px solid transparent;
    }

    button.button__tretiary-negative {
      border: 1px solid #E2E2E5;
      color: #595959;
    }

    button:last-child {
      margin-left: 12px;
    }
  }

  &__label {
    display: block;
    font-family: $text-font-ibm-plex-sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: #000000A6;
    margin-bottom: 12px;
  }

  &__block {
    height: auto;
    margin-right: 8px;
    white-space: nowrap;
  }
}

@media screen and (max-width: $breakpoint-md) {
  .dashboard-filter {
    align-items: flex-start;
    flex-direction: column;

    &__date-picker {
      width: auto;
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .dashboard-filter {
    &__block {
      width: 100%;
    }

    &__date {
      width: 100%;
    }

    &__date &__date-picker:first-child {
      width: 100%;

      .filter-date-picker__dropdown-button {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-right-width: 2px;
      }
    }

    &__date &__date-picker:last-child {
      display: none;
    }

    &__dimension {
      display: none;
    }

    &__dimension-buttons {
      display: flex;
    }
  }
}
</style>
