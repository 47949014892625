
import Vue, {
  computed, onMounted, reactive, ref,
} from 'vue';

import CFileList from '@/module/common/components/UI/CFileUpload/CFileList.vue';
import DSHeader from '@/module/design-system/components/Text/DSHeader.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSMarkedList from '@/module/design-system/components/Data/DSMarkedList.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import CTable from '@/module/common/components/Data/CTable.vue';
import DSTooltip from '@/module/design-system/components/InfoBlock/DSTooltip.vue';

export default Vue.extend({
  name: 'CInfoBlock',
  components: {
    DSTooltip,
    CTable,
    CFileList,
    DSHeader,
    DSIcon,
    DSMarkedList,
    DSTextElement,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: null,
    },
    syncWidth: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit, slots }) {
    const state = reactive({
      synced: false,
    });
    const refs = ref([]);
    const isHeaderRightSlot = computed(() => !!slots['header-right']);

    function handleDownloadFile(file) {
      emit('download-file', file);
    }

    function handleClickUploadIcon(file) {
      emit('download-file', file);
    }

    onMounted(() => {
      const size = (refs.value[0] as HTMLElement).getBoundingClientRect().width;

      emit('sync', Math.ceil(size));

      state.synced = true;
    });

    const styles = computed(() => {
      if (state.synced && props.syncWidth) {
        return {
          width: `${props.syncWidth}px`,
        };
      }

      return {};
    });

    return {
      refs,
      styles,
      isHeaderRightSlot,
      handleDownloadFile,
      handleClickUploadIcon,
    };
  },
});
