// eslint-disable-next-line max-len
import IFormDataMapper from '@/module/merchant/view/AccountActivatePage/DataMapper/IFormDataMapper';
import IFormFieldItem from '@/module/design-system/components/models/FormComponent/IFormFieldItem';
import IUtils from '@/plugins/utils/IUtils';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import camelCaseToSnake from '@/module/common/utils/string/camelCaseToSnake';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EFormFieldType from '@/module/design-system/components/models/FormComponent/EFormFieldType';
import mapObjectCamelCaseToSnakeKeysRecursive
  from '@/module/common/utils/object/mapObjectCamelCaseToSnakeKeysRecursive';
import IPageState from '@/module/merchant/view/AccountActivatePage/models/IPageState';
import EFieldName from '@/module/merchant/view/AccountActivatePage/models/EFieldName';
import EFieldBlock from '@/module/merchant/view/AccountActivatePage/models/EFieldBlock';
import TFormData
  from '@/module/merchant/view/AccountActivatePage/models/FormData/TFormData';
import TFromDataDraft
  from '@/module/merchant/view/AccountActivatePage/models/Draft/TFromDataDraft';
import ETypeOfBusiness from '@/module/merchant/models/BuisnessType/ETypeOfBusiness';

class FormDataToSaveMapper implements IFormDataMapper {
  private readonly state: IPageState;

  private readonly serviceContainer: IServiceContainer;

  constructor(
    state: IPageState,
    serviceContainer: IServiceContainer,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
  }

  // eslint-disable-next-line class-methods-use-this
  mapFieldValue(
    formFieldConfigItem: IFormFieldItem<EFieldName, EFieldBlock>,
    data: TFormData | TFromDataDraft,
  ) {
    const namesMap = {
      [EFieldName.BUSINESS_TYPE]: 'type_of_business',
    };
    const utils = this.serviceContainer.resolve<IUtils>(EServiceContainerInstances.UTILS);

    const { fieldBlock, fieldName, type } = formFieldConfigItem;

    const fieldBlockToSave = camelCaseToSnake(fieldBlock);
    const fieldNameToSave = namesMap[fieldName] ?? camelCaseToSnake(fieldName);

    let value = this.state.formData[fieldBlock]?.[fieldName];

    if (type === EFormFieldType.DATE) {
      value = utils.date.getDateStringFromDate(value, 'YYYY-MM-DD');
    } else if (
      fieldBlock === EFieldBlock.BUSINESS_STRUCTURE
      && fieldName === EFieldName.SELLING_AREA
    ) {
      value = value.id;
    } else if (
      fieldBlock === EFieldBlock.BUSINESS_STRUCTURE
      && fieldName === EFieldName.BUSINESS_TYPE
    ) {
      value = value.typeOfBusiness.value;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (value === ETypeOfBusiness.INDIVIDUAL) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!data.business_details) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data.business_details = {};
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data.business_details.individual = this.state.formData.businessStructure.businessType.individual?.value;
      }
    } else if (
      fieldBlock === EFieldBlock.ACCOUNT_FOR_PAYOUTS
      && fieldName === EFieldName.CURRENCY
    ) {
      value = value.value;
    } else if (
      fieldBlock === EFieldBlock.BUSINESS_DETAILS
      && fieldName === EFieldName.ORGANIZATIONAL_LEGAL_FORM_OF_BUSINESS
    ) {
      value = value.id;
    } else if (
      fieldBlock === EFieldBlock.TAX_CALCULATION
      && fieldName === EFieldName.TAX_CALCULATION
    ) {
      value = value.value;
    } else if (
      [
        EFieldBlock.BUSINESS_STRUCTURE,
        EFieldBlock.ACCOUNT_FOR_PAYOUTS,
      ].includes(fieldBlock)
      && fieldName === EFieldName.COUNTRY
    ) {
      value = value.code;
    } else if (
      [
        EFieldName.DOCUMENT_COMPANY,
        EFieldName.DOCUMENT_INDIVIDUAL_ENTREPRENEUR,
        EFieldName.DOCUMENT_PASSPORT,
        EFieldName.SNILS,
      ].includes(fieldName)
      && fieldBlock === EFieldBlock.BUSINESS_REPRESENTATIVE
    ) {
      // TODO: typing
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!data.documents) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data.documents = [];
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data.documents.push(...value.map((file) => mapObjectCamelCaseToSnakeKeysRecursive(file.fileModel)));

      return data;
    } else if (
      fieldName === EFieldName.PRODUCT_TAX_CATEGORY
      && fieldBlock === EFieldBlock.BUSINESS_DETAILS
    ) {
      value = value.reduce((list, item) => {
        list[item.id] = item.value;

        return list;
      }, {});
    } else if (
      fieldName === EFieldName.REPRESENTATIVE
      && fieldBlock === EFieldBlock.BUSINESS_REPRESENTATIVE
    ) {
      value = value.id;
    } else if (
      fieldName === EFieldName.TAXATION_SYSTEM
      && fieldBlock === EFieldBlock.BUSINESS_DETAILS
    ) {
      value = value.id;
    } else if (
      fieldName === EFieldName.PHONE_NUMBER
    ) {
      value = (value ?? '').replaceAll(' ', '');
    } else {
      value = value ?? '';
    }

    if (!data[fieldBlockToSave]) {
      data[fieldBlockToSave] = {};
    }

    data[fieldBlockToSave][fieldNameToSave] = value;

    return data;
  }
}

export default FormDataToSaveMapper;
