<template>
  <div
    v-click-outside="handleClickOutside"
    :class="classes"
  >
    <select
      :id="id"
      ref="element"
      class="ds-filtered-select__select"
      multiple="multiple"
    >
      <option
        v-for="option in offsetList"
        :key="getOptionId(option)"
        :value="getOptionId(option)"
      >
        {{ getOptionTitle(option) }}
      </option>
    </select>

    <div
      v-show="isDropdownOpened"
      class="ds-filtered-select__input"
    >
      <DSInput
        ref="input"
        :placeholder="placeholder"
        :input-delay="inputDelay"
        :status="status"
        :value="searchText"
        @focus="showDropdown"
        @input="handleInput"
      />
    </div>
    <div
      v-show="!isDropdownOpened"
      :class="fieldClasses"
      @click.stop="handleClick"
    >
      <DSTextElement
        :color="fieldColor"
        variant="medium"
      >
        {{ fieldText }}
      </DSTextElement>
      <DSIcon
        :icon="icon"
        class="ds-filtered-select__icon"
        @click.stop="handleClick"
      />
    </div>

    <div
      v-show="isDropdownOpened"
      ref="dropdown"
      :class="dropDownClasses"
    >
      <PerfectScrollbar
        ref="scrollbar"
        class="ds-filtered-select__option-list-wrapper"
        @ps-scroll-y="handleScrollY"
        @touchmove.stop
      >
        <slot
          name="before-list"
        />

        <ul
          v-if="offsetList.length > 0"
          class="ds-filtered-select__option-list"
        >
          <li
            v-for="option in offsetList"
            :key="getOptionId(option)"
            :class="getOptionClasses(option)"
            @click="handleOptionClick(option)"
          >
            <slot
              :option="option"
              name="list-item"
            />
            <div
              v-if="!isListItemSlot"
              class="ds-select__option"
            >
              <DSTextElement>
                {{ getOptionTitle(option) }}
              </DSTextElement>
            </div>
          </li>
        </ul>

        <slot
          name="after-list"
        />
      </PerfectScrollbar>
    </div>
  </div>
</template>

<script>
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSInput from '@/module/design-system/components/UI/DSInput.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default {
  name: 'DSFilteredSelect',
  components: {
    DSIcon,
    DSInput,
    DSTextElement,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: 'default',
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      required: true,
    },
    titleField: {
      type: String,
      default: 'title',
    },
    idField: {
      type: String,
      default: 'id',
    },
    inputDelay: {
      type: Number,
      required: false,
      default: 500,
    },
    offset: {
      type: Number,
      default: null,
    },
    symbolsForSearch: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      isDropdownOpened: false,
      isOnTop: false,
      selectedOption: {},
      offsetList: [],
      currentOffset: null,
      searchText: '',
    };
  },
  computed: {
    fieldText() {
      return this.selectedOption[this.titleField]
        ? this.selectedOption[this.titleField]
        : this.placeholder;
    },
    classes() {
      return [
        'ds-filtered-select',
      ];
    },
    fieldClasses() {
      return [
        'ds-filtered-select__field',
        `ds-filtered-select__field_${this.status}`,
        this.selectedOption[this.titleField] ? 'ds-filtered-select__field_filled' : 'ds-filtered-select__field_empty',
      ];
    },
    dropDownClasses() {
      return [
        'ds-filtered-select__dropdown',
        `ds-filtered-select__dropdown_position_${this.isOnTop ? 'top' : 'bottom'}`,
      ];
    },
    fieldColor() {
      return this.selectedOption[this.titleField] ? 'primary' : 'disabled';
    },
    id() {
      return `ds-filtered-select-id-${this._uid}`;
    },
    icon() {
      return this.isDropdownOpened ? 'arrowUp' : 'arrowDown';
    },
    isBeforeListItemSlot() {
      return !!this.$scopedSlots['before-list'];
    },
    isListItemSlot() {
      return !!this.$scopedSlots['list-item'];
    },
    isAfterListItemSlot() {
      return !!this.$scopedSlots['after-list'];
    },
  },
  watch: {
    value() {
      this.selectedOption = this.value;
    },
    list() {
      this.initOffsetList();
      if (this.isDropdownOpened === true) {
        this.$nextTick(this.isEnoughBottomSpace);
      }
    },
  },
  mounted() {
    this.currentOffset = this.offset;
    this.initOffsetList();

    this.selectedOption = this.value;
  },
  methods: {
    handleClick() {
      this.isDropdownOpened = !this.isDropdownOpened;
      if (this.isDropdownOpened) {
        this.focusInput();
      }
    },
    focusInput() {
      this.$nextTick(() => {
        if (this.$refs.input?.$refs?.element) {
          this.$refs.input.$refs.element.focus();
        }
      });
    },
    blurInput() {
      this.$nextTick(() => {
        if (this.$refs.input?.$refs?.element) {
          this.$refs.input.$refs.element.blur();
        }
      });
    },
    getOptionId(option) {
      return this.$utils.data.readProp(option, this.idField);
    },
    getOptionTitle(option) {
      return this.$utils.data.readProp(option, this.titleField);
    },
    getOptionClasses(option) {
      const selectedOptionId = this.getOptionId(this.selectedOption, this.idField);
      const currentOptionId = this.getOptionId(option, this.idField);

      const isCurrentOption = selectedOptionId === currentOptionId;

      return [
        'ds-filtered-select__option-item',
        `ds-filtered-select__option-item_${isCurrentOption ? 'active' : 'inactive'}`,
      ];
    },
    handleInput(event) {
      const isMinStringLength = event.length >= this.symbolsForSearch || event.length === 0;

      if (!isMinStringLength) {
        return;
      }

      this.searchText = event;
      this.$emit('search-input', event);
    },
    handleClickOutside() {
      this.isDropdownOpened = false;
    },
    showDropdown() {
      this.isDropdownOpened = true;
      this.$nextTick(this.isEnoughBottomSpace);
    },
    isEnoughBottomSpace() {
      this.isOnTop = false;

      this.$nextTick(() => {
        const componentRect = this.$refs.dropdown.getBoundingClientRect();

        this.isOnTop = document.body.offsetHeight < componentRect.bottom;
      });
    },
    handleOptionClick(option) {
      this.selectedOption = option;
      this.isDropdownOpened = false;
      this.blurInput();
      this.searchText = '';

      this.$emit('input', option);
      this.$emit('blur', option);
    },
    handleScrollY() {
      if (this.$refs.scrollbar.ps.reach.y === 'end') {
        this.initOffsetList();
        this.$emit('scroll-y-reach-end');
      }
    },
    initOffsetList() {
      if (this.currentOffset) {
        const increasedOffset = this.currentOffset + this.offset;
        this.currentOffset = increasedOffset <= this.list.length ? increasedOffset : this.list.length;

        this.offsetList = this.list.slice(0, this.currentOffset);
      } else {
        this.offsetList = [...this.list];
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import "@/module/design-system/components/variables.scss";

.ds-filtered-select {
  position: relative;

  &__input {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__icon {
    position: absolute;
    cursor: pointer;
    right: 19px;
    background-color: $surface-bg-color-regular;
  }

  &__select {
    position: absolute;
    z-index: -10;
    overflow: hidden;
    width: 0;
    height: 0;
    border: none;
    outline: none;

    &:hover + .ds-select__field {
      &_default {
        border-color: $field-border-color-primary-hover;
      }

      &_error {
        border-color: $field-border-color-error-hover;
      }

      &_success {
        border-color: $field-border-color-success-hover;
      }
    }
  }

  &__field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    height: 48px;
    padding: 0 14px;
    font-family: $text-font-ibm-plex-sans;
    font-size: $text-size-regular;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    border: 2px solid $field-border-color-primary;
    border-radius: 8px;
    outline: none;
    background-color: $surface-bg-color-regular;
    cursor: pointer;
    transition: all linear .2s;

    &_filled {
      color: $text-color-primary;
    }

    &_empty {
      color: $text-color-disabled;
    }

    &_default {
      &:hover {
        border-color: $field-border-color-primary-hover;
      }

      &:focus {
        border-color: $field-border-color-primary-focus;
      }
    }

    &_error {
      border-color: $field-border-color-error;

      &:hover {
        border-color: $field-border-color-error-hover;
      }

      &:focus {
        border-color: $field-border-color-error-focus;
      }
    }

    &_success {
      border-color: $field-border-color-success;

      &:hover {
        border-color: $field-border-color-success-hover;
      }

      &:focus {
        border-color: $field-border-color-success-focus;
      }
    }

    &_disabled {
      pointer-events: none;
      border-color: $field-border-color-disabled;
    }
  }

  &__dropdown {
    position: absolute;
    z-index: 6;
    width: 100%;
    min-width: 100%;
    padding: 20px 4px 20px 16px;
    border: 1px solid $surface-stroke-color;
    border-radius: 8px;
    background-color: $surface-bg-color-regular;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);

    &_position {
      &_top {
        bottom: 100%;
        margin-bottom: 8px;
      }

      &_bottom {
        top: 100%;
        margin-top: 8px;
      }
    }
  }

  &__option-list-wrapper {
    display: flex;
    flex-direction: column;
    max-height: 240px;
    gap: 8px;
    min-height: 18px;
  }

  &__option-list {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0 16px 0 0;
  }

  &__option-item {
    border-radius: 8px;
    cursor: pointer;
    gap: 4px;

    &:hover {
      background-color: $button-bg-color-secondary-hover;
    }
  }
}
</style>
