export default () => ({
  namespaced: true,
  state: {
    title: 'Title',
    description: 'Description',
    footerButtons: false,
    isOpened: false,
    withControls: false,
    isError: false,
    tryAgainHandler: () => null,
  },
  mutations: {
    changeTitle(state, title) {
      state.title = title;
    },
    changeDescription(state, description) {
      state.description = description;
    },
    togglePopUp(state) {
      if (state.isOpened) {
        state.isError = false;
      }

      state.isOpened = !state.isOpened;
    },
    fiveSecondsShowPopUp(state) {
      state.isOpened = true;
      setTimeout(() => {
        state.isOpened = false;
      }, 5000);
    },
    toggleWithControls(state) {
      state.withControls = !state.withControls;
    },
    changeTryAgainHandler(state, handler) {
      state.tryAgainHandler = handler;
    },
    toggleError(state) {
      state.isError = !state.isError;
    },
  },
});
