import IHTTPMethodService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IHTTPMethodService';
import EHTTPMethod from '@/module/common/models/EHTTPMethod';
import IHTTPMethod from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IHTTPMethod';

class HTTPMethodService implements IHTTPMethodService {
  get = (method: EHTTPMethod): IHTTPMethod => this.list().find((item) => item.id === method) as IHTTPMethod;

  list = (): IHTTPMethod[] => [
    {
      id: EHTTPMethod.GET,
      title: EHTTPMethod.GET.toUpperCase(),
    },
    {
      id: EHTTPMethod.POST,
      title: EHTTPMethod.POST.toUpperCase(),
    },
  ];
}

export default HTTPMethodService;
