import {
  AxiosInstance,
  AxiosResponse,
} from 'axios';
import IFulfillmentEmailApi from '@/module/product/api/contract/IFulfillmentEmailApi';
import IFulfillmentEmailRequest from '@/module/product/model/Api/Fulfillment/IFulfillmentEmailRequest';
import IFulfillmentEmailResponse from '@/module/product/model/Api/Fulfillment/IFulfillmentEmailResponse';

class FulfillmentEmailApi implements IFulfillmentEmailApi {
  private readonly client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  async create(options: IFulfillmentEmailRequest): Promise<AxiosResponse<IFulfillmentEmailResponse>> {
    return this.client.post('vendor/fulfillment-mail', options);
  }
}

export default FulfillmentEmailApi;
