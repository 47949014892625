
import Vue, {
  PropType,
  reactive,
} from 'vue';
import CInfoBlock from '@/module/common/components/CInfoBlock.vue';
import ICInfoBlock from '@/module/common/models/ICInfoBlock/ICInfoBlock';

export default Vue.extend({
  name: 'CInfoBlockList',
  components: {
    CInfoBlock,
  },
  props: {
    list: {
      type: Array as PropType<ICInfoBlock[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      sync: {
        maxWidth: 0,
      },
    });

    function sync(event) {
      state.sync.maxWidth = Math.max(state.sync.maxWidth, event);
    }

    function handleDownloadFile(event) {
      emit('download-file', event);
    }

    return {
      state,
      sync,
      handleDownloadFile,
    };
  },
});
