<template>
  <div
    class="c-header-profile-menu"
  >
    <CDropdownMenu
      :menu-item-list-full="menuItemList"
      @fullMenuToggle="handleMenuToggle"
      @linkClick="handleClickMenuItem"
    >
      <CHeaderButton
        :is-active="isMenuShow"
        position="bottom-left"
        class="c-header-profile-menu__button"
        icon="profile"
      />
    </CDropdownMenu>
  </div>
</template>

<script>
import CDropdownMenu from '@/module/common/components/UI/CDropdownMenu.vue';
import CHeaderButton from '@/module/common/components/UI/CHeaderButton.vue';
import {
  mapGetters,
} from 'vuex';

export default {
  name: 'CHeaderProfileMenu',
  components: {
    CDropdownMenu,
    CHeaderButton,
  },
  data() {
    return {
      isMenuShow: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'authStore/getUser',
      logoutLink: 'authStore/getLogoutLink',
    }),
    merchantAccountInfo() {
      const accountInfo = {
        type: 'link',
        title: this.user.email,
        titleVariant: 'small-medium',
        url: '/merchant/account/profile',
        isRouterLink: true,
      };

      if (this.user.fullName) {
        accountInfo.title = this.user.fullName;
        accountInfo.text = this.user.email;
      }

      return accountInfo;
    },
    menuItemList() {
      return [
        [
          this.merchantAccountInfo,
        ],
        [
          {
            name: 'logout',
            type: 'link',
            title: this.$t('common.logout'),
            icon: 'arrowReturn',
            url: `${this.logoutLink}?back=${window.location.origin}`,
          },
        ],
      ];
    },
  },
  methods: {
    handleMenuToggle(isShow) {
      this.isMenuShow = isShow;
    },
    async handleClickMenuItem($event, item) {
      if (item.name === 'logout') {
        $event.preventDefault();

        await this.$api.account.logout();
        localStorage.clear();
        window.location.href = item.url;
      }
    },
  },
};
</script>

<style scoped>

</style>
