import IParamsValidateHandler
  from '@/module/product/view/FulfillmentFormPage/model/contract/handler/validate/IParamsValidateHandler';
import IFieldStatusHandler
  from '@/module/common/components/FormComponent/ValidatorHandler/contract/IFieldStatusHandler';
import IVueInstance from '@/module/common/models/IVueInstance';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import { Store } from 'vuex';
import IFormDataTestCreateGenerator
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataTestCreateGenerator';
import IFormDataSalesCreateGenerator
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataSalesCreateGenerator';
import Validator from '@/module/common/utils/validator/Validator';
import EValidatorType from '@/module/common/utils/validator/enum/EValidatorType';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IUtilsService from '@/module/common/service/UtilsService/contract/IUtilsService';
import ICInputWithSelectListValue from '@/module/common/models/UI/CInputWithSelectList/ICInputWithSelectListValue';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';

class ParamsValidateHandler implements IParamsValidateHandler {
  private serviceContainer: IServiceContainer;

  constructor(
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
  }

  validate(
    formData: IFormDataTestCreateGenerator | IFormDataSalesCreateGenerator,
    statusHandler: IFieldStatusHandler,
    prevFormData?: IFormDataTestCreateGenerator | IFormDataSalesCreateGenerator,
  ): IFormDataTestCreateGenerator | IFormDataSalesCreateGenerator {
    const utilsService = this.serviceContainer.resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);

    const localFormData = utilsService.data.cloneImmutable(formData);

    localFormData.parameters = localFormData.parameters.map((parameterItem) => {
      let updatedItem = parameterItem;

      const prevItem = prevFormData?.parameters.find((parameter) => parameter.id === parameterItem.id);

      updatedItem = this.validateInput(updatedItem, statusHandler, prevItem);
      updatedItem = this.validateSelect(updatedItem, statusHandler, prevItem);

      return updatedItem;
    });

    return localFormData;
  }

  private validateInput = (
    parameterItem: ICInputWithSelectListValue,
    statusHandler: IFieldStatusHandler,
    prevValue?: ICInputWithSelectListValue,
  ): ICInputWithSelectListValue => {
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const vueInstance = this.serviceContainer.resolve<IVueInstance>(EServiceContainerInstances.VUE_INSTANCE);
    const utilsService = this.serviceContainer.resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);

    const localisationRegistry = store.getters.getLocalisationRegistry;
    const localisation = localisationRegistry.validationMessages;

    const localParameterItem = utilsService.data.cloneImmutable(parameterItem);

    const inputValidator = new Validator();
    inputValidator.addValidator(
      EValidatorType.NOT_EMPTY,
      localisation.required,
    );
    inputValidator.addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 64 }),
      64,
    );

    inputValidator.validate(localParameterItem.value.inputValue);

    const inputStatus = statusHandler.getStatus(inputValidator);

    if (
      !utilsService.typeCheck.isSet<ICInputWithSelectListValue>(prevValue)
    ) {
      localParameterItem.inputConfig.status = inputStatus;
    } else if (
      !(inputStatus.code === EValidationFieldStatusCode.EMPTY
        && localParameterItem.inputConfig.status?.code === EValidationFieldStatusCode.ERROR
        && localParameterItem.value.inputValue === prevValue.value.inputValue)
    ) {
      localParameterItem.inputConfig.status = inputStatus;
    }

    return localParameterItem;
  };

  private validateSelect = (
    parameterItem: ICInputWithSelectListValue,
    statusHandler: IFieldStatusHandler,
    prevValue?: ICInputWithSelectListValue,
  ): ICInputWithSelectListValue => {
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const utilsService = this.serviceContainer.resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);

    const localisationRegistry = store.getters.getLocalisationRegistry;
    const localisation = localisationRegistry.validationMessages;

    const localParameterItem = utilsService.data.cloneImmutable(parameterItem);

    const selectValidator = new Validator();
    selectValidator.addValidator(
      EValidatorType.NOT_EMPTY,
      localisation.required,
    );

    selectValidator.validate(localParameterItem.value.selectValue);

    const selectStatus = statusHandler.getStatus(selectValidator);

    if (
      !utilsService.typeCheck.isSet<ICInputWithSelectListValue>(prevValue)
    ) {
      localParameterItem.selectConfig.status = selectStatus;
    } else if (
      !(selectStatus.code === EValidationFieldStatusCode.EMPTY
        && localParameterItem.selectConfig.status?.code === EValidationFieldStatusCode.ERROR
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        && localParameterItem.value.selectValue.id === prevValue.value.selectValue.id)
    ) {
      localParameterItem.selectConfig.status = selectStatus;
    }

    return localParameterItem;
  };
}

export default ParamsValidateHandler;
