enum EValidatorType {
  REQUIRED = 'required',
  NOT_EMPTY = 'notEmpty',
  URL = 'url',
  ONLY_NUMBERS = 'onlyNumbers',
  MAX_LENGTH = 'maxLength',
  MIN_LENGTH = 'minLength',
  EMAIL = 'email',
  REGEX = 'regex',
  MATCH = 'match',
  CUSTOM = 'custom',
  CUSTOM_NOT_EMPTY = 'customEmpty',
}

export default EValidatorType;
