import {
  AxiosResponse,
  AxiosInstance,
} from 'axios';
import IProductApi from '@/module/product/api/contract/IProductApi';
import IProductResponseFrontendModel from '@/module/product/model/Api/Product/IProductResponseFrontendModel';
import IProductResponseBackendModel from '@/module/product/model/Api/Product/IProductResponseBackendModel';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IProductMapperService from '@/module/product/service/contract/IProductMapperService';
import TProductFrontend from '@/module/product/model/Product/TProduct';
import TProductBackend from '@/module/product/model/Api/Product/TProduct';

class ProductApi implements IProductApi {
  private readonly client: AxiosInstance;

  private readonly serviceContainer: IServiceContainer;

  private readonly mapper: IProductMapperService;

  constructor(
    client: AxiosInstance,
    serviceContainer: IServiceContainer,
    mapper: IProductMapperService,
  ) {
    this.client = client;
    this.serviceContainer = serviceContainer;
    this.mapper = mapper;
  }

  async get(id: number): Promise<AxiosResponse<IProductResponseFrontendModel>> {
    const response = await this.client.get(`/vendor/product/${id}`);

    const data: IProductResponseBackendModel = response.data;

    return {
      ...response,
      data: {
        product: this.mapper.mapProductFormBackendToFrontendModel(data.product),
        buyLink: data.buylink,
      },
    };
  }

  async create(product: TProductFrontend): Promise<AxiosResponse<{ productId: number }>> {
    const data: TProductBackend = this.mapper.mapProductFormFrontendToBackendModel(product);

    return this.client.post('/vendor/product', data);
  }

  async update(product: TProductFrontend): Promise<AxiosResponse<{ productId: number }>> {
    const data: TProductBackend = this.mapper.mapProductFormFrontendToBackendModel(product);

    return this.client.put(`/vendor/product/${data.id}`, data);
  }
}

export default ProductApi;
