import IConfig from '@/module/common/service/PortalConfigService/IConfig';
import IPortalConfigService from '@/module/common/service/PortalConfigService/IPortalConfigService';

class PortalConfigService implements IPortalConfigService {
  private readonly config: IConfig;

  constructor(config: IConfig) {
    this.config = config;
  }

  get = (): IConfig => this.config;
}

export default PortalConfigService;
