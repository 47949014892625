
import Vue, {
  PropType,
  computed,
} from 'vue';

import ETextVariants from '@/module/common/models/ETextVariants';
import EColorScheme from '@/module/common/models/EColorScheme';

import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default Vue.extend({
  name: 'DSLink',
  components: {
    DSTextElement,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    variant: {
      type: String as PropType<ETextVariants>,
      default: ETextVariants.REGULAR,
    },
    color: {
      type: String as PropType<EColorScheme>,
      default: EColorScheme.LINKED,
    },
    target: {
      type: String,
      default: '_self',
    },
    isRouterLink: {
      type: Boolean,
      default: true,
    },
    download: {
      type: String,
      default: null,
    },
  },
  setup(props, { slots }) {
    const isIconAfterSlot = computed(() => !!slots['icon-after']);
    const isIconBeforeSlot = computed(() => !!slots['icon-before']);

    const classes = computed(() => [
      'ds-link',
      isIconAfterSlot.value || isIconBeforeSlot.value ? 'ds-link_with-icon' : null,
    ]);

    return {
      isIconAfterSlot,
      isIconBeforeSlot,
      classes,
    };
  },
});
