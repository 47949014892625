import { reactive } from 'vue';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';
import ICountry from '@/module/merchant/models/ICountry';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import EDraftName from '@/api/EDraftName';
import EPageTab from '@/module/merchant/view/AccountActivatePage/models/EPageTab';
import IFormConfig from '@/module/merchant/view/AccountActivatePage/models/IFormConfig';
import TFieldStatuses
  from '@/module/merchant/view/AccountActivatePage/models/TFieldStatuses';
import TFilteredSelectRegistry from '@/module/merchant/view/AccountActivatePage/models/TFilteredSelectRegistry';
import IPageState from '@/module/merchant/view/AccountActivatePage/models/IPageState';
import ITab from '@/module/merchant/view/AccountActivatePage/models/ITab';
import ETabStatus
  from '@/module/merchant/view/AccountActivatePage/models/ETabStatus';
import TFromDataDraft
  from '@/module/merchant/view/AccountActivatePage/models/Draft/TFromDataDraft';
import TFormData
  from '@/module/merchant/view/AccountActivatePage/models/FormData/TFormData';

function getTabs(localisationRegistry: ILocalisationRegistry): ITab[] {
  const localisation = localisationRegistry.accountActivate;

  return [
    {
      id: EPageTab.BUSINESS_INFO,
      title: localisation.businessInfo.tab,
      status: ETabStatus.DEFAULT,
    },
    {
      id: EPageTab.SUPPORT_DETAILS,
      title: localisation.support.tab,
      status: ETabStatus.DEFAULT,
    },
    {
      id: EPageTab.ACCOUNT_FOR_PAYOUTS,
      title: localisation.bank.tab,
      status: ETabStatus.DEFAULT,
    },
    {
      id: EPageTab.TAX_CALCULATION,
      title: localisation.taxCalculation.tab,
      status: ETabStatus.DEFAULT,
    },
    {
      id: EPageTab.SUMMARY,
      title: localisation.summary.tab,
      status: ETabStatus.DEFAULT,
    },
  ];
}

async function getCountryList(
  serviceContainer: IServiceContainer,
  localisationRegistry: ILocalisationRegistry,
): Promise<ICountry[]> {
  const api = serviceContainer.resolve(EServiceContainerInstances.API);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const response = await api.common.getCountryList();

  const countryList = (response?.data ?? []) as ICountry[];

  return Promise.resolve(
    countryList
      .map((country) => ({ ...country, name: localisationRegistry.country[country.code] })) as ICountry[],
  );
}

async function getDraft(
  serviceContainer: IServiceContainer,
): Promise<TFromDataDraft> {
  const api = serviceContainer.resolve(EServiceContainerInstances.API);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const response = await api.common.refreshDraft(EDraftName.ACCOUNT_ACTIVATE);

  let draft = response?.data?.formData ?? {};
  draft = Array.isArray(draft) ? {} : draft;
  return draft as TFromDataDraft;
}

export default function initState(
  localisationRegistry: ILocalisationRegistry,
): IPageState {
  const tabs = getTabs(localisationRegistry);

  return reactive<IPageState>({
    isLoading: true,
    filteredSelectRegistry: {} as TFilteredSelectRegistry,
    tabs,
    activeTab: tabs[0],
    formData: {} as TFormData,
    formDraft: {} as TFromDataDraft,
    activeTabConfig: {} as IFormConfig,
    formFieldStatusList: {} as TFieldStatuses,
    tabListConfig: [] as IFormConfig[],
    isTermsAndConditionsChecked: false,
    isTermsOfUseChecked: false,
    isSaveInProgress: false,
    lists: {
      country: [] as ICountry[],
    },
  });
}

export {
  getTabs,
  getCountryList,
  getDraft,
};
