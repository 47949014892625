<template>
  <div class="promotion-pricing">
    <div class="product-card__row">
      <div class="promotion-pricing__table-wrapper">
        <perfect-scrollbar>
          <div class="promotion-pricing__table">
            <div class="promotion-pricing__column">
              <p
                v-if="variants.length > 1"
                class="promotion-pricing__cell promotion-pricing__title"
              >
                {{ $t('promotions.promotion_card.discount.table.units') }}
              </p>
              <p class="promotion-pricing__cell promotion-pricing__title">
                {{ $t('promotions.promotion_card.discount.table.sku') }}
              </p>
              <p
                v-for="price in variants[0].price"
                :key="price.currency"
                class="promotion-pricing__cell promotion-pricing__title"
              >
                {{ $t('promotions.promotion_card.discount.table.discount_price') }}
                <span class="promotion-pricing__title-currency">, {{ price.currency }}</span>
              </p>
            </div>

            <div
              v-for="(variant, index) of variants"
              :key="variant.sku + index"
              class="promotion-pricing__column"
            >
              <p
                v-if="variants.length > 1"
                class="promotion-pricing__cell promotion-pricing__title"
              >
                {{ variant.from ? variant.from : '1' }}-{{ variant.to ? variant.to : '∞' }}
              </p>
              <p class="promotion-pricing__cell">
                {{ variant.sku || '-' }}
              </p>
              <div
                v-for="price in parseVariantPrice(variant.price)"
                :key="price[0]"
                class="promotion-pricing__cell"
              >
                <p>{{ getDiscountPrice(price, price[1].currency) }}</p>
                <p
                  v-if="percent"
                  class="promotion-pricing__price_line-through"
                >
                  {{ priceFormat(price[1].price, price[1].currency) }}
                </p>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PromotionPricing',
  props: {
    variants: {
      type: Array,
      default: () => [],
    },
    percent: {
      type: Number,
      default: 10,
    },
    locale: {
      type: String,
      default: 'en-EN',
    },
  },
  methods: {
    parseVariantPrice(price) {
      return Object.entries(price);
    },
    priceFormat(price, currency) {
      const numberFormatService = this.$container.resolve('numberFormatService');

      return numberFormatService.money(price, currency);
    },
    getDiscountPrice(price, currency) {
      const discountPrice = (price[1].price / 100) * (100 - this.percent);
      return this.priceFormat(discountPrice, currency);
    },
  },
};
</script>

<style lang="scss">
.promotion-pricing {
  .ps {
    max-width: 713px;
  }

  &__table {
    border-radius: 12px;
    display: flex;
    align-items: center;
  }

  &__table-wrapper {
    width: fit-content;
    position: relative;
    overflow: hidden;
    border: 1px solid #E5E5E5;
    border-radius: 12px;
  }

  &__cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    min-width: 140px;
    height: 46px;
    padding: 12px;
    border: 1px solid #E5E5E5;
    border-top: 0;
    border-left: 0;
    white-space: nowrap;

    @media screen and (max-width: 370px) {
      min-width: 110px;
    }
  }

  &__price_line-through {
    color: #888888;
    font-size: 11px;
    line-height: 12px;
    text-decoration: line-through;
  }

  &__column &__cell:last-child {
    border-bottom: 0;
  }

  &__column:first-child &__cell{
    width: 224px;

    @media screen and (max-width: 370px) {
      width: 155px;
    }

    &:first-child {
      border-top-left-radius: 12px;
    }

    &:last-child {
      border-bottom-left-radius: 12px;
    }
  }

  &__column:last-child &__cell {
    border-right: 0;
  }

  &__column:last-child &__cell:first-child {
    border-top-right-radius: 12px;
  }

  &__column:last-child &__cell:last-child {
    border-bottom-right-radius: 12px;
  }

  &__title {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #F8FAFC;
  }

  &__title-currency {
    @media screen and (max-width: 370px) {
      display: none;
    }
  }
}

@media screen and (max-width: 1120px) {
  .promotion-pricing {
    &__table-wrapper {
      max-width: 518px;
    }
  }
}

@media screen and (max-width: 920px) {
  .promotion-pricing {
    &__table-wrapper {
      max-width: 448px;
    }
  }
}

@media screen and (max-width: 840px) {
  .promotion-pricing {
    &__table-wrapper {
      max-width: 345px;
    }
  }
}

@media screen and (max-width: 370px) {
  .promotion-pricing {
    &__table-wrapper {
      max-width: 300px;
    }
  }
}
</style>
