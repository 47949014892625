<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      d="M20.4004 1.2002H18.0004C17.6821 1.2002 17.3769 1.32662 17.1519 1.55167C16.9268 1.77671 16.8004 2.08194 16.8004 2.4002V22.7906H21.6004V2.4002C21.6004 2.08194 21.474 1.77671 21.2489 1.55167C21.0239 1.32662 20.7186 1.2002 20.4004 1.2002ZM13.2004 8.4002H10.8004C10.4821 8.4002 10.1769 8.52662 9.95186 8.75167C9.72682 8.97671 9.60039 9.28194 9.60039 9.6002V22.7906H14.4004V9.6002C14.4004 9.28194 14.274 8.97671 14.0489 8.75167C13.8239 8.52662 13.5187 8.4002 13.2004 8.4002ZM6.00039 15.6002H3.60039C3.28213 15.6002 2.97691 15.7266 2.75186 15.9517C2.52682 16.1767 2.40039 16.4819 2.40039 16.8002V22.7906H7.20039V16.8002C7.20039 16.4819 7.07396 16.1767 6.84892 15.9517C6.62387 15.7266 6.31865 15.6002 6.00039 15.6002Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSBarGraphIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>

<style scoped>

</style>
