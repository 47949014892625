import axios from 'axios';
import improvedTrim from '@/helpers/improvedTrim';

export default (serviceContainer) => ({
  namespaced: true,
  state: {
    cancellingRequestToken: axios.CancelToken.source(),
    filterOptions: {
      offset: 0,
      limit: 20,
      order: 'desc',
      search: '',
    },
    listView: 'list',
    isUpdating: false,
    isRequestRunning: true,
    isLoading: true,
    responseStatus: 200,
    totalCount: 0,
    searchCount: 0,
    products: [],
    isInitialRequest: true,
    scrollPosition: 0,
  },
  mutations: {
    changeFilterOption(state, { optionName, newValue }) {
      state.filterOptions[optionName] = newValue;
    },
    setProducts(state, newProducts) {
      state.products = newProducts;
    },
    changeRequestRunning(state, value) {
      state.isRequestRunning = value;
    },
    setStatus(state, status) {
      state.responseStatus = status;
    },
    setTotalCount(state, value) {
      state.totalCount = value;
    },
    setCancelToken(state) {
      state.cancellingRequestToken = axios.CancelToken.source();
    },
    changeFieldValue(state, { fieldName, value }) {
      state[fieldName] = value;
    },
    deleteProduct(state, productId) {
      state.totalCount -= 1;
      state.searchCount -= 1;
      state.offset -= 1;
      state.products = state.products.filter((product) => product.id !== productId);
    },
  },
  getters: {
    totalCount(state) {
      return state.totalCount;
    },
    selectedOrder(state) {
      return state.filterOptions.order === 'desc' ? 'product.filter_desc' : 'product.filter_asc';
    },
    searchQuery(state) {
      const options = state.filterOptions;
      const query = `?offset=${options.offset}&limit=${options.limit}&sortByUpdateDate=${options.order}`;
      if (improvedTrim(options.search).length) {
        return `${query}&searchString=${improvedTrim(options.search)}`;
      }
      return query;
    },
    getRequestRunning(state) {
      return state.isRequestRunning;
    },
    getProducts(state) {
      return state.products;
    },
  },
  actions: {
    async getProducts({ state, commit, getters }, isOverwrite) {
      if (isOverwrite) {
        commit('changeFilterOption', { optionName: 'offset', newValue: 0 });
        commit('changeFieldValue', { fieldName: 'isUpdating', value: true });
      }

      if (state.isRequestRunning) {
        state.cancellingRequestToken.cancel('userCancel');
      }

      commit('changeRequestRunning', true);
      commit('setCancelToken');

      const api = serviceContainer.resolve('api');

      try {
        const { status, data } = await api.products.getProducts(
          getters.searchQuery,
          state.cancellingRequestToken.token,
        );

        const totalCount = data.total_count || data.totalCount;

        if (state.isInitialRequest) {
          commit('setTotalCount', totalCount);
          commit('changeFieldValue', { fieldName: 'searchCount', value: totalCount });
        }

        if (isOverwrite) {
          commit('setProducts', data.products);
          commit('changeFieldValue', { fieldName: 'searchCount', value: totalCount });
        } else {
          const isEndOfList = state.searchCount <= 20 + state.products.length;

          if (data.products.length < 20 && !isEndOfList) {
            commit('changeFieldValue', {
              fieldName: 'searchCount',
              value: state.searchCount - 20 + data.products.length,
            });
          }

          if (isEndOfList) {
            const productsCount = state.products.length + data.products.length;
            const listDifference = state.searchCount - productsCount;
            commit('changeFieldValue', {
              fieldName: 'searchCount',
              value: state.searchCount - listDifference + data.products.length,
            });
          }

          const concatedProducts = state.products.concat(data.products);
          commit('setProducts', concatedProducts);
        }

        commit('setStatus', status);
        commit('changeFilterOption', { optionName: 'offset', newValue: state.filterOptions.offset + 20 });
        commit('changeRequestRunning', false);
        commit('changeFieldValue', { fieldName: 'isLoading', value: false });
        commit('changeFieldValue', { fieldName: 'isUpdating', value: false });
        commit('changeFieldValue', { fieldName: 'isInitialRequest', value: false });
        return { isError: false };
      } catch (error) {
        if (error.message === 'userCancel') {
          return { isError: false };
        }
        commit('setStatus', error.response.status);
        commit('changeRequestRunning', false);
        commit('changeFieldValue', { fieldName: 'isLoading', value: false });
        commit('changeFieldValue', { fieldName: 'isUpdating', value: false });
        commit('changeFieldValue', { fieldName: 'isInitialRequest', value: false });
        return { isError: true };
      }
    },
    getScrollPosition({ state }, scrollPosition) {
      state.scrollPosition = scrollPosition;
    },
  },
});
