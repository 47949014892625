import THandleFieldBlurParams from '@/module/product/view/ProductFormPage/model/THandleFieldBlurParams';
import BaseFieldBlurScenario from '@/module/product/view/ProductFormPage/scenario/BaseFieldBlurScenario';

class DefaultFieldBlurScenario extends BaseFieldBlurScenario {
  run = (item: THandleFieldBlurParams) => {
    const productFormPage = this.serviceContainer.moduleProduct.productFormPage;

    this.updateFromData(item);

    const formData = productFormPage.handlerService.formDataHandler.get();
    productFormPage.handlerService.validateHandler.validateField(item.field.fieldName, formData);

    this.updateFromConfig();
  };
}

export default DefaultFieldBlurScenario;
