<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      d="M7.5 10.5H10.5C10.6989 10.5 10.8897 10.421 11.0303 10.2803C11.171 10.1397 11.25 9.94891 11.25 9.75C11.25 9.55109 11.171 9.36032 11.0303 9.21967C10.8897 9.07902 10.6989 9 10.5 9H7.5C7.30109 9 7.11032 9.07902 6.96967 9.21967C6.82902 9.36032 6.75 9.55109 6.75 9.75C6.75 9.94891 6.82902 10.1397 6.96967 10.2803C7.11032 10.421 7.30109 10.5 7.5 10.5ZM14.25 2.25H3.75C3.15326 2.25 2.58097 2.48705 2.15901 2.90901C1.73705 3.33097 1.5 3.90326 1.5 4.5V6.75C1.5 6.94891 1.57902 7.13968 1.71967 7.28033C1.86032 7.42098 2.05109 7.5 2.25 7.5H3V13.5C3 14.0967 3.23705 14.669 3.65901 15.091C4.08097 15.5129 4.65326 15.75 5.25 15.75H12.75C13.3467 15.75 13.919 15.5129 14.341 15.091C14.7629 14.669 15 14.0967 15 13.5V7.5H15.75C15.9489 7.5 16.1397 7.42098 16.2803 7.28033C16.421 7.13968 16.5 6.94891 16.5 6.75V4.5C16.5 3.90326 16.2629 3.33097 15.841 2.90901C15.419 2.48705 14.8467 2.25 14.25 2.25ZM13.5 13.5C13.5 13.6989 13.421 13.8897 13.2803 14.0303C13.1397 14.171 12.9489 14.25 12.75 14.25H5.25C5.05109 14.25 4.86032 14.171 4.71967 14.0303C4.57902 13.8897 4.5 13.6989 4.5 13.5V7.5H13.5V13.5ZM15 6H3V4.5C3 4.30109 3.07902 4.11032 3.21967 3.96967C3.36032 3.82902 3.55109 3.75 3.75 3.75H14.25C14.4489 3.75 14.6397 3.82902 14.7803 3.96967C14.921 4.11032 15 4.30109 15 4.5V6Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSProductIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>

<style scoped>

</style>
