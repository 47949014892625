<template>
  <COverviewPageLayout
    :is-loading="isLoading"
  >
    <template
      #header
    >
      <COverviewPageHeaderRow
        :page-title="$t('account.profile.title')"
      >
        <template
          #mobile-menu
        >
          <ButtonMore
            v-if="isMerchantNeedApproval || isMerchantActivated"
            variant="gray-square"
            :buttons="[
              {
                name: 'account.profile.how_to_edit_info.button',
                close: true,
                handler: () => handleClickHowToEdit(),
              },
            ]"
          >
            <div
              class="ellipsis-icon__wrapper"
            >
              <DSIcon
                icon="ellipsis"
                color="primary"
              />
            </div>
          </ButtonMore>
        </template>
        <template
          #right-side
        >
          <DSButton
            v-if="isMerchantNeedApproval || isMerchantActivated"
            :text="$t('account.profile.how_to_edit_info.button')"
            class="how-to-edit-button"
            variant="tretiary"
            show-text-after-breakpoint="sm"
            @click="handleClickHowToEdit()"
          >
            <template
              #icon-before
            >
              <DSIcon
                icon="edit"
                color="linked"
              />
            </template>
          </DSButton>
        </template>
      </COverviewPageHeaderRow>
    </template>
    <template
      #content
    >
      <main
        class="account-page"
      >
        <InlineAlert
          v-if="!isMerchantActivated"
          :subtitle="alertText"
          :title="alertTitle"
          :variant="alertVariant"
          class="account-page__alert"
        >
          <template
            #alert-controls
          >
            <button
              v-if="!isMerchantNeedApproval"
              class="inline-alert__button"
              type="button"
              @click="$router.push('/merchant/account/activate').catch(() => null)"
            >
              <span
                class="account-page__alert-text_full"
              >
                {{ alertButtonText }}
              </span>
              <DSIcon
                icon="tailArrowRight"
                :color="alertButtonColor"
                class="account-page__alert-text_short"
              />
            </button>
          </template>
        </InlineAlert>

        <section
          class="merchant account-page__section"
        >
          <div
            class="merchant__photo"
          >
            <div class="merchant__photo_icon">
              <DSIcon
                icon="profile"
                color="linked"
                size="l"
              />
            </div>
          </div>
          <div
            :class="merchantInfoClasses"
          >
            <DSHeader
              :text="userEmail"
              :size="2"
              class="merchant__name"
            />
          </div>
        </section>

        <CInfoBlockList
          v-if="!isLoading && merchantAccountInfo.length > 0"
          :list="merchantAccountInfo"
          @download-file="handleDownloadFile"
        />

        <CStatusWindowComponent
          v-if="statusWindow.visible"
          :icon="statusWindow.icon"
          :size="statusWindow.size"
          :subtitle="statusWindow.subtitle"
          :title="statusWindow.title"
        >
          <template
            #buttons
          >
            <DSButton
              :text="statusWindow.button.text"
              @click="statusWindow.button.handler"
            >
              <template
                #icon-before
              >
                <DSIcon
                  color="primary-button"
                  icon="refresh"
                />
              </template>
            </DSButton>
          </template>
        </CStatusWindowComponent>
      </main>
      <DSModal
        v-show="isShowHowToEdit"
        :title="$t('account.profile.how_to_edit_info.modal.title')"
        @close-modal="handleCloseModal"
      >
        <template
          #modal-content
        >
          <DSTextElement>
            <!-- eslint-disable vue/no-v-html -->
            <span
              v-html="howToEditInfoModalContent"
            />
            <!-- eslint-enable vue/no-v-html -->
          </DSTextElement>
        </template>
        <template
          #modal-footer
        >
          <DSButton
            strech="xxl"
            :text="$t('account.profile.how_to_edit_info.modal.button')"
            @click="handleClickContactSupport"
          />
        </template>
      </DSModal>
    </template>
  </COverviewPageLayout>
</template>

<script>
import {
  inject,
} from 'vue';
import {
  mapActions,
  mapGetters,
} from 'vuex';

import getBlockData, {
  initCountryLocalisation,
} from '@/module/merchant/view/ProfilePage.func';
import downloadFile from '@/module/common/utils/file/downloadFile';

import ButtonMore from '@/components/ButtonMore/ButtonMore.vue';
import CInfoBlockList from '@/module/common/components/CInfoBlockList.vue';
import COverviewPageHeaderRow from '@/module/common/components/PageParts/COverviewPageHeaderRow.vue';
import COverviewPageLayout from '@/module/common/components/PageParts/COverviewPageLayout.vue';
import CStatusWindowComponent from '@/module/common/components/UI/CStatusWindowComponent.vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSHeader from '@/module/design-system/components/Text/DSHeader.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSModal from '@/module/design-system/components/UI/DSModal.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import InlineAlert from '@/components/InlineAlert/InlineAlert.vue';

export default {
  name: 'ProfilePage',
  components: {
    ButtonMore,
    CInfoBlockList,
    COverviewPageHeaderRow,
    COverviewPageLayout,
    CStatusWindowComponent,
    DSButton,
    DSHeader,
    DSIcon,
    DSModal,
    DSTextElement,
    InlineAlert,
  },
  data() {
    return {
      isLoading: true,
      statusWindow: {
        visible: false,
        icon: 'alert',
        title: this.$t('account.profile.statusWindow.error.title'),
        subtitle: this.$t('account.profile.statusWindow.error.text'),
        size: 'm',
        button: {
          text: this.$t('account.profile.statusWindow.error.button'),
          handler: () => {
            this.$router.go();
          },
        },
      },
      isShowHowToEdit: false,
      countryLocalisation: {},
    };
  },
  computed: {
    ...mapGetters({
      getMerchantMode: 'authStore/getMerchantMode',
      isMerchantActivated: 'authStore/getIsMerchantActivated',
      getAllowedMerchantModes: 'authStore/getAllowedMerchantModes',
      getSalesStatusActivationAvailable: 'authStore/getSalesStatusActivationAvailable',
      getUser: 'authStore/getUser',
      locale: 'getLocale',
      getIsEmptyDraft: 'accountActivateStore/getIsEmptyDraft',
      getMerchantAccountInfo: 'merchantAccountStore/getMerchantAccountInfo',
      countryList: 'accountActivateStore/getCountryList',
      salesArea: 'authStore/getSalesArea',
      getLocalisationRegistry: 'getLocalisationRegistry',
    }),
    isMerchantNeedApproval() {
      return !this.isMerchantActivated && !this.getSalesStatusActivationAvailable;
    },
    isMerchantNeedActivate() {
      return !this.isMerchantActivated && this.getSalesStatusActivationAvailable;
    },
    alertTitle() {
      let textToken = 'account.profile.activate_account.title';

      if (this.isMerchantNeedActivate && !this.getIsEmptyDraft) {
        textToken = 'account.profile.continue_activate_account.title';
      } else if (this.isMerchantNeedApproval) {
        textToken = 'account.profile.account_need_approval.title';
      }

      return this.$t(textToken);
    },
    alertText() {
      let textToken = 'account.profile.activate_account.text';

      if (this.isMerchantNeedActivate && !this.getIsEmptyDraft) {
        textToken = 'account.profile.continue_activate_account.text';
      } else if (this.isMerchantNeedApproval) {
        textToken = 'account.profile.account_need_approval.text';
      }

      return this.$t(textToken);
    },
    alertButtonText() {
      const textToken = this.getIsEmptyDraft
        ? 'account.profile.activate_account.button'
        : 'account.profile.continue_activate_account.button';

      return this.$t(textToken);
    },
    alertButtonColor() {
      return this.getIsEmptyDraft ? 'warning' : 'linked';
    },
    alertVariant() {
      return (this.isMerchantNeedActivate && !this.getIsEmptyDraft) || this.isMerchantNeedApproval ? 'info' : 'alert';
    },
    merchantInfoClasses() {
      return [
        'merchant__info',
        'merchant__info_centred',
      ];
    },
    userEmail() {
      return this.getUser?.email;
    },
    merchantAccountInfo() {
      return getBlockData(
        this.getLocalisationRegistry,
        this.countryLocalisation,
        this.locale,
        this.getMerchantAccountInfo,
        this.$container,
      );
    },
    howToEditInfoModalContent() {
      return this.utilsService.email.replaceSupportEmail(
        this.$t('account.profile.how_to_edit_info.modal.content'),
        this.isMerchantActivated,
      );
    },
  },
  watch: {
    getSalesStatusActivationAvailable() {
      if (!this.getSalesStatusActivationAvailable) {
        this.refreshMerchantInfo();
      }
    },
  },
  setup() {
    const serviceContainer = inject('serviceContainer');

    const utilsService = serviceContainer.resolve('utilsService');

    const tsApi = inject('tsApi');

    async function handleDownloadFile(file) {
      if (!file.fileModel?.s3ObjectKey) {
        return;
      }

      const response = await tsApi.filesStorage.get(file.fileModel.s3ObjectKey, { responseType: 'blob' });

      downloadFile(response.data, file);
    }

    return {
      utilsService,
      handleDownloadFile,
    };
  },
  async created() {
    try {
      if (this.getSalesStatusActivationAvailable) {
        await this.refreshDraft({ salesArea: this.salesArea });
      } else {
        const response = await this.refreshMerchantInfo();

        this.statusWindow.visible = !!response.response?.status;
      }
      await this.refreshCountryList();
    } finally {
      this.isLoading = false;
    }
  },
  mounted() {
    this.countryLocalisation = initCountryLocalisation(this);
  },
  methods: {
    ...mapActions({
      refreshDraft: 'accountActivateStore/refreshDraft',
      refreshCountryList: 'accountActivateStore/refreshCountryList',
      refreshMerchantInfo: 'merchantAccountStore/refreshMerchantAccountInfo',
    }),
    handleClickHowToEdit() {
      this.isShowHowToEdit = true;
    },
    handleCloseModal() {
      this.isShowHowToEdit = false;
    },
    handleClickContactSupport() {
      window.location.href = this.$utils.link.generateLinkHref(
        this.utilsService.email.getSupportEmail(this.isMerchantActivated),
        'email',
      );
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>

.ellipsis-icon__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.account-page {
  &__title {
    line-height: 58px;
  }

  &__alert {
    margin-top: 32px;
  }

  &__alert-text_short {
    display: none;
  }

  &__section {
    margin-bottom: 32px;
    padding: 32px 0;
    border-bottom: 1px solid $app-stroke;

    &:last-child {
      border-bottom: none;
    }
  }

  &__preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 840px) {
  .account-page {
    &__alert-text {
      &_full {
        display: none;
      }

      &_short {
        display: inline-block;
      }
    }
  }
}

.merchant {
  display: flex;
  column-gap: 30px;

  &__photo_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EBF7FF;
    height: 64px;
    width: 64px;
    border-radius: 20px;
  }

  &__info {
    width: calc(100% - 64px - 30px);
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    &_centred {
      justify-content: center;
    }
  }

  &__name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__type {
    margin-top: 12px;
  }
}

.info {

}

@media screen and (max-width: 670px) {
  .merchant {
    flex-wrap: wrap;

    &__photo {
      display: block;
      margin-bottom: 12px;
    }

    &__info {
      width: 100%;
    }
  }

  .how-to-edit-button {
    display: none;
  }
}
</style>
