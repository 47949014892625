<template>
  <div class="promotion-list-wrapper">
    <ModalComponent
      v-if="isModalOpen"
      @closeModal="handleCloseModal"
    >
      <div class="promotion-list-modal">
        <div class="promotion-list-modal__title">
          <HeaderText
            size="3"
            :text="$t('promotions.promotion_card.modal.delete.title')"
          />
          <ButtonComponent
            variant="tretiary-negative"
            icon-button
            small
            @click="handleCloseModal"
          >
            <DSIcon
              icon="cross"
              color="primary"
            />
          </ButtonComponent>
        </div>
        <div class="promotion-list-modal__description">
          <p class="promotion-list-modal__name">
            {{ promotionToDelete.promotionName }}
          </p>
          <p class="promotion-list-modal__date">
            {{ getFormattedDate(promotionToDelete.promotionStartDate) }}
            -
            {{ getFormattedDate(promotionToDelete.promotionEndDate) }}
            •
            {{ promotionToDelete.promotionStatistic.promotionUses }}
          </p>
        </div>
        <ButtonGroup
          class="promotion-list-modal__button-group"
          variant="spaced"
        >
          <ButtonComponent
            variant="primary"
            @click="handleCloseModal"
          >
            {{ $t('promotions.promotion_card.modal.delete.keep') }}
          </ButtonComponent>
          <ButtonComponent
            variant="secondary-negative"
            @click="handleDeleteClick(promotionToDelete.promotionId)"
          >
            {{ $t('promotions.promotion_card.modal.delete.delete') }}
          </ButtonComponent>
        </ButtonGroup>
      </div>
    </ModalComponent>

    <ul class="promotion-list">
      <li class="promotion-list__row promotion-list__row-title">
        <div class="promotion-list__row-wrapper">
          <span
            v-for="(title, index) in titleList"
            :key="index"
            class="promotion-list__title secondary"
          >{{ $t(title) }}</span>
        </div>
      </li>

      <li
        v-for="promotion of promotionsList"
        :key="promotion.promotionId"
        class="promotion-list__row"
        @click="handleRedirectToCard(promotion.promotionId)"
      >
        <div class="promotion-list__row-wrapper">
          <div
            class="promotion-list__text"
          >
            <DSTextElement
              :color="promotionNameColor(promotion)"
            >
              {{ getFormattedName(promotion.promotionName) }}
            </DSTextElement>
            <PromoTags
              v-if="getPromoTags(promotion).length"
              class="promotion-list__tags promotion-list__tags-max"
              :list="getPromoTags(promotion)"
            />
          </div>
          <span class="promotion-list__text promotion-list__text-min">
            <span class="promotion-list__text-wrapper">
              {{ getFormattedExpiredDate(promotion.promotionEndDate) }}
              <span class="promotion-list__min-info">
                • {{ promotion.promotionStatistic.promotionUses || '—' }}

              </span>
            </span>
          </span>
          <span
            class="promotion-list__text promotion-list__text-uses promotion-list__text-max"
          >
            <span class="promotion-list__text-wrapper">
              <span class="promotion-list__text-redemptions">{{
                promotion.promotionStatistic.promotionUses || '—'
              }}</span>
              <DSTooltip
                v-if="getIsPromotionResourceEnded(promotion)"
                :text="$t('promotions.promotion_card.uses.tooltip')"
              >
                <template
                  #tooltip-icon
                >
                  <div class="promotion-list__mark-icon">
                    <DSIcon
                      icon="exclamation"
                      color="warning"
                      size="s"
                    />
                  </div>
                </template>
              </DSTooltip>
            </span>
          </span>
          <span class="promotion-list__text promotion-list__text-max">
            {{ utilsService.date.formatSkipCurrentYearWithoutWeekDay(promotion.promotionUpdateDate) }}
          </span>

          <PromoTags
            v-if="getPromoTags(promotion).length"
            class="promotion-list__tags promotion-list__tags-min"
            :list="getPromoTags(promotion)"
          />

          <ButtonMore
            ref="buttonMoreRef"
            class="promotion-list__button-more"
            :buttons="getButtonMoreOptions(promotion.promotionId, promotion.promotionStatus)"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  inject,
} from 'vue';
import {
  mapActions,
  mapMutations,
} from 'vuex';

import ButtonMore from '@/components/ButtonMore/ButtonMore.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import PromoTags from '@/components/PromoTags/index.vue';
import ButtonGroup from '@/components/ButtonGroup/ButtonGroup.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import HeaderText from '@/components/HeaderText.vue';
import ButtonComponent from '@/components/Button/ButtonComponent.vue';
import DSTooltip from '@/module/design-system/components/InfoBlock/DSTooltip.vue';
import initPromotionTags from '@/constants/initPromotionTags';

export default {
  name: 'PromotionList',
  components: {
    DSIcon,
    DSTooltip,
    ButtonMore,
    DSTextElement,
    PromoTags,
    ModalComponent,
    ButtonGroup,
    HeaderText,
    ButtonComponent,
  },
  props: {
    promotionsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      titleList: [
        'promotions.overview.list_headers.name',
        'promotions.overview.list_headers.end_date',
        'promotions.overview.list_headers.uses',
        'promotions.overview.list_headers.last_edit',
      ],
      monthNames: [
        'months_short.jan',
        'months_short.feb',
        'months_short.mar',
        'months_short.apr',
        'months_short.may',
        'months_short.jun',
        'months_short.jul',
        'months_short.sep',
        'months_short.oct',
        'months_short.nov',
        'months_short.dec',
      ],
      weekDayNames: [
        'week_days_short.sun',
        'week_days_short.mon',
        'week_days_short.tue',
        'week_days_short.wed',
        'week_days_short.thu',
        'week_days_short.fri',
        'week_days_short.sat',
      ],
      isModalOpen: false,
      promotionToDelete: null,
      promotionsTags: {},
    };
  },
  setup() {
    const serviceContainer = inject('serviceContainer');
    const utilsService = serviceContainer.utilsService;

    return {
      utilsService,
    };
  },
  methods: {
    ...mapMutations({
      openPopup: 'popupStore/togglePopUp',
      changePopupTitle: 'popupStore/changeTitle',
      changePopupDescription: 'popupStore/changeDescription',
      togglePopupControls: 'popupStore/toggleWithControls',
      changePopupTryAgainHandler: 'popupStore/changeTryAgainHandler',
      togglePopupError: 'popupStore/toggleError',
    }),
    ...mapActions({
      deletePromotion: 'promotionsOverviewStore/deletePromotion',
      getPromotionsList: 'promotionsOverviewStore/getPromotionsList',
      changePromotionStatus: 'promotionsOverviewStore/changeStatus',
    }),
    promotionNameColor(promotion) {
      const inactiveStatuses = [
        'inactive',
        'expired',
      ];
      const currentStatus = promotion.promotionStatus.toLowerCase();

      return inactiveStatuses.includes(currentStatus) ? 'secondary' : 'primary';
    },
    getButtonMoreOptions(promotionId, promotionStatus) {
      return [
        {
          name: 'promotions.promotion_card.edit',
          handler: () => this.handlerRedirectToEdit(promotionId),
        },
        {
          name: promotionStatus.toLowerCase() === 'active'
            ? 'promotions.promotion_card.popover.disable'
            : 'promotions.promotion_card.popover.activate',
          handler: () => this.handleActivateDeactivatePromotion(promotionId),
        },
        {
          name: 'promotions.promotion_card.popover.delete',
          handler: () => this.handleDeletePromotion(promotionId),
        },
      ];
    },
    handlerRedirectToEdit(promotionId) {
      this.$router.push(`/promotions/edit/${promotionId}`).catch(() => null);
    },
    handleDeletePromotion(promotionId) {
      this.promotionToDelete = this.promotionsList.find((promotion) => promotion.promotionId === promotionId);
      this.isModalOpen = true;
    },
    async handleActivateDeactivatePromotion(promotionId) {
      const status = await this.changePromotionStatus(promotionId);
      await this.getPromotionsList();

      if (status === 204) {
        await this.getPromotionsList(true);
        this.popupController({
          title: 'promotions.popup.title.status',
          description: 'promotions.popup.description.status',
        });
      } else {
        this.popupController({
          title: 'promotions.popup.title.error',
          description: 'promotions.popup.description.error',
          withControls: true,
          isError: true,
          handler: () => this.handleActivateDeactivatePromotion(promotionId),
        });
      }
    },
    async handleDeleteClick(promotionId) {
      this.isModalOpen = false;
      const response = await this.deletePromotion(promotionId);

      if (response === 204) {
        await this.getPromotionsList(true);
        this.popupController({
          title: 'promotions.popup.title.deleted',
          description: 'promotions.popup.description.deleted',
        });
      } else {
        this.popupController({
          title: 'promotions.popup.title.error',
          description: 'promotions.popup.description.error',
          withControls: true,
          isError: true,
          handler: () => this.handleDeleteClick(promotionId),
        });
      }
    },
    popupController({
      title,
      description,
      withControls = false,
      handler,
      isError = false,
    }) {
      this.changePopupTitle(title);
      this.changePopupDescription(description);
      if (withControls) {
        this.togglePopupControls(true);
        this.changePopupTryAgainHandler(handler);
      }

      if (isError) this.togglePopupError(true);
      this.openPopup();
    },
    handleRedirectToCard(promotionId) {
      this.$router.push(`/promotions/${promotionId}`).catch(() => null);
    },
    getFormattedDate(dateString) {
      return this.$utils.date.formatSkipCurrentYearWithTime(dateString);
    },
    getFormattedExpiredDate(dateString) {
      const date = this.$utils.date.getPromotionExpiredDate(dateString);

      return date ? this.$utils.date.formatSkipCurrentYearWithTime(dateString, false) : '—';
    },
    getFormattedName(nameString) {
      return nameString[0].toUpperCase() + nameString.slice(1);
    },
    getPromoTags(promotion) {
      const tags = [];
      const promotionType = promotion.promotionType.toLowerCase();
      const promotionStatus = promotion.promotionStatus.toLowerCase();

      if (this.promotionsTags[promotionType]) {
        tags.push(this.promotionsTags[promotionType]);
      }
      if (this.promotionsTags[promotionStatus]) {
        tags.push(this.promotionsTags[promotionStatus]);
      }

      return tags;
    },
    getIsPromotionResourceEnded(promotion) {
      const isPromotionTypeCoupon = promotion.promotionType === 'coupon';

      if (!isPromotionTypeCoupon) return false;

      let promotionAmount = 0;
      let isPromotionOneTime = false;

      if (promotion.promotionStatistic.coupons) {
        promotionAmount = promotion.promotionStatistic.coupons.length;
        isPromotionOneTime = promotion.promotionStatistic.coupons[0].couponType.toLowerCase() === 'one-time';
      }

      const { promotionUses } = promotion.promotionStatistic;

      return (promotionUses >= promotionAmount) && isPromotionOneTime;
    },
    handleCloseModal() {
      this.isModalOpen = false;
      this.promotionToDelete = null;
    },
  },
  mounted() {
    this.promotionsTags = initPromotionTags(this);
  },
};
</script>

<style lang="scss">
.promotion-list-modal {
  width: 350px;
  min-height: 220px;
  display: flex;
  flex-direction: column;

  &__title {
    display: flex;
    justify-content: space-between;

    svg path {
      fill: black;
    }
  }

  &__description {
    height: 100%;
    margin: 20px 0;
  }

  &__name {
    font-weight: 500;
    margin-bottom: 8px;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__button-group {
    width: 100%;

    button {
      width: 100%;
      justify-content: center;
    }
  }
}

.promotion-list {
  margin: 0;
  padding: 0;
  margin-top: 32px;

  &__row {
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 48px;
    cursor: pointer;
    border-bottom: 1px solid #e5e5e5;
  }

  &__row:first-child {
    cursor: initial;
    margin-bottom: 32px;
    border-bottom: 0;
    padding-bottom: 0;
  }

  &__row-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 40% 25% 15% 15% 28px;
    justify-content: space-between;
  }

  &__title {
    font-family: $text-ibm-plex-sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    text-transform: uppercase;
    margin-left: 4px;

    &:first-child {
      margin-left: 0;
    }
  }

  &__text {
    font-family: $text-ibm-plex-sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    margin-left: 5px;

    &:first-child {
      margin-left: 0;
    }

    &-uses {
      display: flex;
      align-items: flex-start;
    }

    &-wrapper {
      display: flex;
      align-items: center;
    }

    &-redemptions {
      margin-right: 5px;
    }
  }

  &__mark-icon {
    width: 18px;
    height: 18px;
  }

  &__min-info {
    display: none;
  }

  &__tags.tags {
    margin-top: 12px;
    margin-bottom: 0;
  }

  &__tags-min.tags {
    margin: 0;
    display: none;
  }

  &__button-more {
    button {
      border: 0;
    }
  }
}

@media screen and (max-width: 840px) {
  .promotion-list {
    &__row-title {
      display: none;
    }

    &__row {
      margin-bottom: 20px;
      padding-bottom: 12px;
    }

    &__row-wrapper {
      display: flex;
      flex-direction: column;
      position: relative;
    }

    &__tags-max.tags {
      display: none;
    }

    &__tags-min.tags {
      display: flex;
    }

    &__text {
      display: flex;
      align-items: center;
      margin-left: 0;
      margin-bottom: 5px;
    }

    &__text-max {
      display: none;
    }

    &__button-more {
      position: absolute;
      top: 0;
      right: 0;
    }

    &__text-min {
      color: #888888;
    }

    &__min-info {
      display: inline-block;
    }
  }
}
</style>
