import { render, staticRenderFns } from "./CTextWrapper.vue?vue&type=template&id=60f202fe&scoped=true&"
import script from "./CTextWrapper.vue?vue&type=script&lang=ts&"
export * from "./CTextWrapper.vue?vue&type=script&lang=ts&"
import style0 from "./CTextWrapper.vue?vue&type=style&index=0&id=60f202fe&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60f202fe",
  null
  
)

export default component.exports