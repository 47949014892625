<template>
  <CFormPageLayout
    :show-error="isError"
    :error-status="errorStatus"
    :show-preloader="isLoading"
  >
    <CFormPageHeaderRow
      :page-title="pageTitle"
    >
      <template #button-left>
        <DSButton
          :text="cancelButton.config.text"
          :variant="cancelButton.config.variant"
          @click="cancelButton.handler"
        >
          <template
            #icon-before
          >
            <DSIcon
              :icon="cancelButton.icon.icon"
              :color="cancelButton.icon.color"
            />
          </template>
        </DSButton>
      </template>

      <template #button-right>
        <DSButton
          :text="saveButton.config.text"
          :disabled="saveButton.config.disabled"
          @click="saveButton.handler"
        />
      </template>
    </CFormPageHeaderRow>
    <CFormPageContent>
      <main
        class="create-edit-webhook"
      >
        <section
          class="create-edit-webhook__section"
        >
          <div
            class="create-edit-webhook__group create-edit-webhook__field"
          >
            <CInput
              class="create-edit-webhook__field"
              :label="endpointURL.label"
              :tooltip="endpointURL.tooltip"
              :placeholder="endpointURL.placeholder"
              :value="endpointURLValue"
              :status="endpointURLStatus"
              @blur="updateEndpointURLValue"
            />
            <a
              target="_blank"
              :href="helpLink"
              class="create-edit-webhook__help-link"
            >{{ $t('webhooks.form.how_endpoint_works.label') }}</a>
          </div>

          <UIDropdown
            class="create-edit-webhook__field"
            :label="eventsDropdown.label"
            :tooltip="eventsDropdown.tooltip"
            :with-radio-buttons="true"
            :model-options="eventsDropdown.list"
            :model-value="eventsValue"
            @update:modelValue="updateEventsValue"
          />

          <InputText
            class="create-edit-webhook__field"
            :label="secretKey.label"
            :tooltip="secretKey.tooltip"
            :placeholder="secretKey.placeholder"
            :value="secretKeyValue"
            :is-invalid="validation.hasError('secretKeyValue')"
            :error="validation.firstError('secretKeyValue')"
            @update:value="updateSecretKey"
          />
        </section>
      </main>
    </CFormPageContent>
  </CFormPageLayout>
</template>

<script>
import CFormPageContent from '@/module/common/components/PageParts/CFormPageContent.vue';
import CFormPageHeaderRow from '@/module/common/components/PageParts/CFormPageHeaderRow.vue';
import CFormPageLayout from '@/module/common/components/PageParts/CFormPageLayout.vue';
import CInput from '@/module/common/components/UI/CInput.vue';
import getButton, {
  getButtonWithIcon,
} from '@/module/common/helpers/getButton';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import InputText from '@/components/InputText/InputText.vue';
import UIDropdown from '@/components/UI/UIDropdown.vue';
import {
  inject,
} from 'vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import {
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';
import EventListenerRegister from '@/module/common/service/EventListenerRegister/EventListenerRegister';
import initLocalisation, {
  getButtonName,
  initCreatedPopUp,
  initUpdatedPopUp,
  initAlreadyExistsPopUp,
  initErrorPopUp,
} from '@/views/CreateEditWebhookPage.func';
import SimpleVueValidatior from 'simple-vue-validator';

const Validator = SimpleVueValidatior.Validator;

export default {
  name: 'CreateEditWebhookPage',
  components: {
    DSButton,
    CFormPageLayout,
    CFormPageHeaderRow,
    CFormPageContent,
    DSIcon,
    CInput,
    InputText,
    UIDropdown,
  },
  mixins: [SimpleVueValidatior.mixin],
  data() {
    return {
      popUpList: {
        created: null,
        updated: null,
        alreadyExist: null,
        error: null,
      },
      localisation: {},
      endpointURL: {
        label: this.$t('webhooks.form.endpoint_field.label'),
        tooltip: this.$t('webhooks.form.endpoint_field.tooltip'),
        placeholder: this.$t('webhooks.form.endpoint_field.placeholder'),
      },
      eventsDropdown: {
        label: this.$t('webhooks.form.events_field.label'),
        tooltip: this.$t('webhooks.form.events_field.tooltip'),
        list: [
          {
            value: 'order.payment.succeeded',
            name: this.$t('webhooks.events_list.order_payment_succeeded'),
          },
          {
            value: 'order.created',
            name: this.$t('webhooks.events_list.order_created'),
          },
          {
            value: 'order.payment.failed',
            name: this.$t('webhooks.events_list.order_payment_failed'),
          },
          {
            value: 'subscription.cancelled',
            name: this.$t('webhooks.events_list.subscription_cancelled'),
          },
          {
            value: 'subscription.restored',
            name: this.$t('webhooks.events_list.subscription_restored'),
          },
          {
            value: 'product.delivered',
            name: this.$t('webhooks.events_list.product_delivered'),
          },
          {
            value: 'product.returned',
            name: this.$t('webhooks.events_list.product_returned'),
          },
        ],
      },
      secretKey: {
        label: this.$t('webhooks.form.secret_key_field.label'),
        tooltip: this.$t('webhooks.form.secret_key_field.tooltip'),
        placeholder: this.$t('webhooks.form.secret_key_field.placeholder'),
      },
      errorMessage: {
        required: this.$t('webhooks.form.error_message.required'),
        URLmaxLength255: this.$t('webhooks.form.error_message.url_max_length', { number: 255 }),
        SecretKeyMaxLength255: this.$t('webhooks.form.error_message.secret_key_max_length', { number: 255 }),
        httpsOnly: this.$t('webhooks.form.error_message.https_only'),
        invalidFormat: this.$t('webhooks.form.error_message.invalid_format'),
      },
      isLoading: true,
      isError: false,
      errorStatus: null,
      isSavingInProgress: false,
    };
  },
  setup() {
    const serviceContainer = inject('serviceContainer');

    const docsSiteUrl = serviceContainer.portalConfigService.get().url.docs;

    const utils = serviceContainer.resolve('utils');

    const store = serviceContainer.resolve('store');

    const helpLink = utils.link.localizeURL(
      `${docsSiteUrl}/en-en/merchant-portal/webhooks-overview`,
      store.getters.getLocale,
    );

    return {
      helpLink,
    };
  },
  computed: {
    ...mapGetters({
      endpointURLValue: 'createEditWebhookStore/getEndpointURL',
      eventsValue: 'createEditWebhookStore/getEvents',
      secretKeyValue: 'createEditWebhookStore/getSecretKey',
      getLocalisationRegistry: 'getLocalisationRegistry',
    }),
    commonLocalisation() {
      return this.getLocalisationRegistry.common;
    },
    webhookId() {
      return this.$route.params.id;
    },
    isEditMode() {
      return !!this.$route.meta.isEdit;
    },
    pageTitle() {
      return this.isEditMode
        ? this.$t('webhooks.form.edit_title')
        : this.$t('webhooks.form.create_title');
    },
    endpointURLStatus() {
      return this.validation.hasError('endpointURLValue')
        ? {
          code: 'error',
          text: this.validation.firstError('endpointURLValue'),
        }
        : {
          code: 'default',
        };
    },
    cancelButton() {
      return getButtonWithIcon(
        {
          text: this.$t('common.cancel'),
          variant: 'tretiary',
        },
        () => this.$router.push('/webhooks/').catch(() => null),
        {
          icon: 'cross',
          color: 'linked',
        },
      );
    },
    saveButton() {
      return getButton(
        {
          text: this.$t('common.save'),
          variant: 'tretiary',
          disabled: this.isSavingInProgress,
        },
        this.handleSaveClick,
      );
    },
  },
  async mounted() {
    this.localisation = initLocalisation(this);

    this.popUpList.created = initCreatedPopUp(this.localisation);
    this.popUpList.updated = initUpdatedPopUp(this.localisation);
    this.popUpList.alreadyExists = initAlreadyExistsPopUp(this.localisation);
    this.popUpList.error = initErrorPopUp(this.localisation, this.commonLocalisation);

    const eventListenerRegister = new EventListenerRegister();
    const buttonName = getButtonName();

    this.validation.reset();
    if (this.isEditMode) {
      eventListenerRegister.register(buttonName, () => this.handleUpdateWebhook(this.$route.params.id));

      const status = await this.loadWebhookToEdit(this.$route.params.id);
      if (status !== 200) {
        this.isError = true;
        this.errorStatus = status;
      } else {
        this.isError = false;
      }
    } else {
      await this.clearStore();

      eventListenerRegister.register(buttonName, () => this.handleCreateWebhook());
    }

    this.isLoading = false;
  },
  methods: {
    ...mapMutations({
      updateEndpointURLValue: 'createEditWebhookStore/updateEndpointURL',
      updateEventsValue: 'createEditWebhookStore/updateEvents',
      updateSecretKey: 'createEditWebhookStore/updateSecretKey',
      setPopupTitle: 'popupStore/changeTitle',
      setPopupDescription: 'popupStore/changeDescription',
      openPopup: 'popupStore/togglePopUp',
      togglePopupError: 'popupStore/toggleError',
      togglePopupControls: 'popupStore/toggleWithControls',
      setPopupTryAgainHandler: 'popupStore/changeTryAgainHandler',
      changeFieldValue: 'webhooksStore/changeFieldValue',
    }),
    ...mapActions({
      createWebhook: 'createEditWebhookStore/createWebhook',
      updateWebhook: 'createEditWebhookStore/updateWebhook',
      loadWebhookToEdit: 'createEditWebhookStore/loadWebhook',
      clearStore: 'createEditWebhookStore/clearStore',
      loadUpdatedEndpoints: 'webhooksStore/getEndpoints',
      pushNotification: 'notificationsStore/pushNotification',
    }),
    async handleSaveClick() {
      const isValid = await this.$validate();
      if (isValid) {
        if (this.isEditMode) {
          await this.handleUpdateWebhook(this.webhookId);
        } else {
          await this.handleCreateWebhook(this.webhookId);
        }
        this.changeFieldValue({ fieldName: 'isLoading', value: true });
        await this.loadUpdatedEndpoints(true);
      }
    },
    async handleCreateWebhook() {
      this.isSavingInProgress = true;
      const status = await this.createWebhook();

      this.isSavingInProgress = false;

      switch (status) {
        case 200:
          this.pushNotification(this.popUpList.created);
          this.$router.push('/webhooks/').catch(() => null);
          break;
        case 'alreadyExists':
          this.pushNotification(this.popUpList.alreadyExists);
          break;
        default:
          this.pushNotification(this.popUpList.error);
          break;
      }
    },
    async handleUpdateWebhook(webhookId) {
      this.isSavingInProgress = true;
      const status = await this.updateWebhook(webhookId);

      this.isSavingInProgress = false;

      switch (status) {
        case 204:
          this.pushNotification(this.popUpList.updated);
          this.$router.push('/webhooks/').catch(() => null);
          break;
        case 'alreadyExists':
          this.pushNotification(this.popUpList.alreadyExists);
          break;
        default:
          this.pushNotification(this.popUpList.error);
          break;
      }
    },
  },
  validators: {
    secretKeyValue(value) {
      return Validator.value(value)
        .required(this.errorMessage.required)
        .maxLength(255, this.errorMessage.SecretKeyMaxLength255);
    },
    endpointURLValue(value) {
      const maxLength = 255;

      return Validator.value(value)
        .required(this.$t('validation_messages.required'))
        .maxLength(maxLength, this.$t('validation_messages.max_length', { number: maxLength }))
        .regex(/^https:\/\//, this.errorMessage.httpsOnly)
        .regex(
          // eslint-disable-next-line max-len
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
          this.$t('validation_messages.invalid_format'),
        );
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import "@/module/design-system/components/variables.scss";

.create-edit-webhook {
  position: relative;
  width: 100%;
  margin: 0 auto 45px;

  &__section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__group {
    position: relative;
  }

  &__help-link {
    position: absolute;
    top: 0;
    right: 0;
    font-family: $text-font-ibm-plex-sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: right;
    letter-spacing: 0;
    color: $text-color-linked;
  }
}
</style>
