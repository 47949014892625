import moment from 'moment';

export default function getMonthYearsInPeriod(start, end) {
  const startDate = moment(start);
  const endDate = moment(end);
  const iterationDate = startDate.clone().add(-1, 'month');

  const resultArr = [];
  while (+(iterationDate.add(1, 'month')) < +endDate.endOf('month')) resultArr.push(iterationDate.format('MM.YYYY'));
  return resultArr;
}
