<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      d="M93.5999 49.0562L80.1599 21.0242C79.2959 19.2962 77.5679 18.2402 75.7439 18.2402H21.2159C19.2959 18.2402 17.5679 19.2962 16.7999 21.0242L3.35988 49.0562C3.07188 49.7282 2.87988 50.4962 2.87988 51.1682V73.7282C2.87988 76.4162 5.08788 78.6242 7.77588 78.6242H89.0879C91.7759 78.6242 93.9839 76.4162 93.9839 73.7282V51.1682C94.0799 50.4962 93.8879 49.7282 93.5999 49.0562ZM61.0559 45.7922C59.2319 45.7922 57.5039 46.9442 56.9279 48.6722C55.7759 52.2242 52.4159 54.8162 48.3839 54.8162C44.3519 54.8162 41.0879 52.2242 39.8399 48.6722C39.2639 46.9442 37.5359 45.7922 35.7119 45.7922C29.0879 45.7922 15.7439 45.7922 15.7439 45.7922L24.1919 28.1282H72.4799L80.9279 45.7922C81.0239 45.6962 67.6799 45.6962 61.0559 45.7922Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSUploadIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>

<style scoped>

</style>
