<template>
  <div class="webhooks-table">
    <div
      v-if="endpoints.length"
      class="webhooks-table__head"
    >
      <DSTextElement
        color="secondary"
      >
        {{ $t('webhooks.webhooks_table.title').toUpperCase() }}
      </DSTextElement>
      <DSTextElement
        color="secondary"
        class="webhooks-table__title"
      >
        {{ $t('webhooks.webhooks_table.last_days').toUpperCase() }}
      </DSTextElement>
      <DSTextElement
        color="secondary"
        class="webhooks-table__title"
      >
        {{ $t('webhooks.webhooks_table.error_rate').toUpperCase() }}
      </DSTextElement>
    </div>
    <div
      v-for="(endpoint, idx) in endpoints"
      :key="endpoint.id"
      class="webhooks-table__row"
      @click="(event) => handleRedirectToEndpoint(event, endpoint.id, idx)"
    >
      <div
        class="webhooks-table__url"
      >
        <DSTextElement
          :color="!endpoint.status ? 'secondary' : 'primary'"
          variant="medium"
        >
          {{ endpoint.url }}
        </DSTextElement>
      </div>
      <div
        class="webhooks-table__sum"
      >
        <DSTextElement>
          {{ endpoint.sum }}
        </DSTextElement>
      </div>
      <div
        class="webhooks-table__error-rate"
      >
        <DSTextElement>
          {{ endpoint.errorRate }}%
        </DSTextElement>
      </div>
      <div
        class="webhooks-table__values-mobile"
      >
        <DSTextElement
          color="secondary"
        >
          {{ endpoint.sum }} • {{ endpoint.errorRate }}%
        </DSTextElement>
      </div>

      <ButtonMore
        ref="buttonMoreRef"
        class="webhooks-table__button-more"
        :buttons="[
          {
            name: 'product.product_card.delete',
            handler: (event) => handleDeleteEndpoint(event, endpoint.id),
          },
          {
            name: endpoint.status ? 'webhooks.deactivate' : 'webhooks.activate',
            handler: () => handleToggleActivation(endpoint),
          },
          {
            name: 'product.product_card.edit',
            handler: (event) => handleRedirectToEdit(event, endpoint.id),
          },
        ]"
      />

      <DSTagList
        :tag-list="getTagList(endpoint)"
        class="webhooks-table__tag-list"
      />
    </div>
  </div>
</template>

<script>
import ButtonMore from '@/components/ButtonMore/ButtonMore.vue';
import DSTagList from '@/module/design-system/components/InfoBlock/DSTagList.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import DateTimeMixin from '@/mixins/dateTimeMixin';

import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'WebhooksTable',
  components: {
    DSTagList,
    DSTextElement,
    ButtonMore,
  },
  mixins: [
    DateTimeMixin,
  ],
  props: {
    endpoints: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      popup: {
        title: {
          updated: 'webhooks.card.popup.title.updated',
          error: 'webhooks.card.popup.title.error',
          deleted: 'webhooks.card.popup.title.deleted',
        },
        description: {
          activated: 'webhooks.card.popup.description.activated',
          deactivated: 'webhooks.card.popup.description.deactivated',
          error: 'webhooks.card.popup.description.error',
          deleted: 'webhooks.card.popup.description.deleted',
        },
      },
    };
  },
  computed: {
    ...mapState({
      isPopupWdithControls: (state) => state.popupStore.withControls,
      isPopupOpened: (state) => state.popupStore.isOpened,
      id: (state) => state.deleteModalStore.id,
      isLoading: (state) => state.webhooksStore.isLoading,
    }),
    endpointToDelete() {
      return this.$store.state.webhooksStore.endpoints.find((endpoint) => endpoint.id === this.id);
    },
  },
  methods: {
    ...mapActions({
      handleDeleteEndpointClick: 'deleteModalStore/handleDeleteEndpointClick',
    }),
    ...mapMutations({
      togglePopUp: 'popupStore/togglePopUp',
      toggleControls: 'popupStore/toggleWithControls',
      changeTryAgainhandler: 'popupStore/changeTryAgainHandler',
      changeDeleteModalFieldValue: 'deleteModalStore/changeFieldValue',
      updateEndpointStatus: 'webhooksStore/updateEndpointStatus',
      changeWebhooksListFieldValue: 'webhooksStore/changeFieldValue',
      setPopupTitle: 'popupStore/changeTitle',
      setPopupDescription: 'popupStore/changeDescription',
      togglePopup: 'popupStore/togglePopUp',
      togglePopupError: 'popupStore/toggleError',
      togglePopupControls: 'popupStore/toggleWithControls',
      setTryAgainHandler: 'popupStore/changeTryAgainHandler',
    }),
    handleRedirectToEndpoint(event, endpointId, idx) {
      if (this.isPopupOpened) this.handleClosePopup();

      const { target } = event;
      const button = this.$refs.buttonMoreRef[idx].$el;
      if (button.contains(target)) return;
      this.$router.push(`/webhooks/${endpointId}`).catch(() => null);
    },
    handleClosePopup() {
      this.togglePopUp();

      if (this.isPopupWdithControls) {
        this.changeTryAgainhandler(null);
        this.toggleControls();
      }
    },
    handleDeleteEndpoint(_event, endpointId) {
      this.changeDeleteModalFieldValue({
        fieldName: 'id',
        newValue: endpointId,
      });
      this.changeDeleteModalFieldValue({
        fieldName: 'isOpened',
        newValue: true,
      });
      this.changeDeleteModalFieldValue({
        fieldName: 'activeModalData',
        newValue: {
          dataToDelete: this.endpointToDelete,
          title: this.$t('modal_window.delete_endpoint'),
          buttonKeep: this.$t('modal_window.no'),
          buttonDelete: this.$t('modal_window.yes'),
          handleDeleteClick: () => this.handleDeleteEndpointClick([
            endpointId,
            this.$t('popup_messages.deleted_endpoint'),
            this.endpointToDelete.url,
            this.$t('popup_messages.error'),
            this.$t('popup_messages.went_wrong'),
          ]),
        },
      });
      if (this.$refs.buttonMoreRef.length > 0) {
        this.$refs.buttonMoreRef.forEach((el) => {
          el.isMenuOpened = false;
        });
      }
    },
    popupController({
      title,
      description,
      isError,
      withControls,
      handler,
    }) {
      this.setPopupTitle(title);
      this.setPopupDescription(description);
      if (isError) {
        this.togglePopupError();
      }

      if (withControls) {
        this.togglePopupControls();
        this.setTryAgainHandler(handler);
      }

      this.togglePopup();
    },
    async handleToggleActivation({
      id,
      event,
      url,
      secret,
      status,
    }) {
      this.changeWebhooksListFieldValue({
        fieldName: 'isLoading',
        value: true,
      });
      this.$api.webhooks.updateEndpoint(id, {
        event,
        url,
        secretKey: secret,
        status: !status,
      })
        .then((res) => {
          if (res.status === 204) {
            this.updateEndpointStatus(id);
          }
        })
        .then(() => {
          this.changeWebhooksListFieldValue({
            fieldName: 'isLoading',
            value: false,
          });
        })
        .then(() => {
          this.popupController({
            title: this.popup.title.updated,
            description: this.popup.description[status ? 'deactivated' : 'activated'],
          });
        })
        .catch(() => {
          this.popupController({
            title: this.popup.title.error,
            description: this.popup.description.error,
            isError: true,
            withControls: true,
            handler: () => this.handleToggleActivation({
              id,
              event,
              url,
              secret,
              status,
            }),
          });
        });

      this.$refs.buttonMoreRef.forEach((el) => {
        el.isMenuOpened = false;
      });
    },
    handleRedirectToEdit(_event, endpointId) {
      this.$router.push(`/webhooks/edit/${endpointId}`).catch(() => null);
    },
    getTagList(endpoint) {
      const eventName = endpoint.event.replaceAll('.', '_');

      const colorsMap = {
        'order.created': '#27ae601a',
        'order.payment.succeeded': '#27ae601a',
        'order.payment.failed': '#fae3de',
        'product.delivered': '#27ae601a',
        'product.returned': '#f2994a1a',
        'subscription.cancelled': '#ebf7ff',
        'subscription.restored': '#ebf7ff',
        default: '#ebf7ff',
      };

      const tagList = [
        {
          text: this.$t(`webhooks.webhooks_table.tags.${eventName}.title`),
          backgroundColor: colorsMap[endpoint.event] ?? colorsMap.default,
        },
      ];

      if (!endpoint.status) {
        tagList.push({
          text: this.$t('webhooks.webhooks_table.tags.status.title'),
          backgroundColor: '#e5e5e5',
          color: '#000000',
          tooltip: {
            text: this.$t('webhooks.webhooks_table.tags.status.tooltip'),
          },
        });
      }

      return tagList;
    },
  },
};
</script>

<style lang="scss">
.webhooks-table {
  margin-top: 10px;
  width: 100%;

  &__row {
    cursor: pointer;
    padding: 45px 0 20px;
    display: grid;
    grid-row-gap: 8px;
    grid-template-columns: 1fr 140px 160px 48px;
    grid-template-rows: repeat(2, auto);
    border-bottom: 1px solid #E5E5E5;

    &:nth-child(2) {
      padding: 20px 0;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &__head {
    display: grid;
    grid-template-columns: 1fr 140px 160px 48px;
    grid-template-rows: auto;
    padding-bottom: 6px;

    p {
      font-size: 14px;
    }
  }

  &__title {
    text-align: right;
  }

  &__url {
    font-weight: 500;
    overflow-wrap: anywhere;

    &--disabled {
      color: #888888;
    }
  }

  &__sum {
    text-align: right;
  }

  &__error-rate {
    text-align: right;
  }

  &__button-more {
    margin-left: auto;
  }

  &__values-mobile {
    display: none;
  }

  &__button-more .button-more {
    border: 0;
    padding: 0;

    svg path {
      fill: black
    }
  }
}

@media screen and (max-width: 840px) {
  .webhooks-table {
    &__head {
      display: none;
    }

    &__row {
      grid-template-columns: 1fr 48px;
      grid-template-rows: repeat(3, auto);
      padding: 20px 0 10px;

      &:nth-child(2) {
        padding: 5px 0 10px;
      }
    }

    &__button-more {
      grid-column: 2;
      grid-row: 1;
    }

    &__sum {
      display: none;
    }

    &__error-rate {
      display: none;
    }

    &__values-mobile {
      display: block;
      color: #888888;
    }

    &__tag-list {
      grid-column: 1;
      grid-row: 3;
    }
  }
}
</style>
