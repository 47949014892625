<template>
  <c-input-text
    class="input-search"
    :value="value"
    :placeholder="$t(placeholder)"
    :clearable="true"
    :error="error"
    :maxlength="maxlength"
    :is-loading="isLoading"
    :label="label"
    @update:value="handleInputChange"
    @update:clear="handleClearSearch"
  >
    <template #left-icon>
      <span class="input-search__icon">
        <DSIcon
          icon="search"
          size="s"
        />
      </span>
    </template>
  </c-input-text>
</template>

<script>
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import InputText from '@/components/InputText/InputText.vue';

export default {
  name: 'SearchComponent',
  components: {
    DSIcon,
    'c-input-text': InputText,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'product.search_placeholder',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: '',
    },
    maxlength: {
      type: Number,
      default: null,
    },
  },
  methods: {
    handleInputChange(value) {
      return this.$emit('update:value', value);
    },
    handleClearSearch(value) {
      return this.$emit('update:clear', value);
    },
  },
};
</script>

<style lang="scss">
@import './styles.scss';
</style>
