<template>
  <DashboardWidgetArea
    :widget="widget"
  >
    <template
      #right-side
    >
      <slot
        name="right-side"
      />
    </template>
    <template
      #content
    >
      <div
        class="dashboard-widget-table__table"
      >
        <CTable
          :head="widget.config?.headers"
          :rows="widget.data?.initial?.items"
          :fill-first-col="true"
        >
          <template
            #head="{ cell }"
          >
            <div
              class="dashboard-widget-table__cell dashboard-widget-table__cell_header"
            >
              <DSTextElement
                variant="regular"
              >
                {{ getHeaderTitle(cell) }}
              </DSTextElement>
              <DSTooltip
                v-if="getHeaderTooltip(cell)"
                :text="getHeaderTooltip(cell)"
              >
                <template
                  #tooltip-icon
                >
                  <DSIcon
                    size="xs"
                  />
                </template>
              </DSTooltip>
            </div>
          </template>
          <template
            #cell="{ cell, col }"
          >
            <div
              :class="getCellClass(col)"
            >
              <DSTextElement
                :variant="getCellTextVariant(cell)"
              >
                {{ getCellValue(cell) }}
              </DSTextElement>
            </div>
          </template>
        </CTable>
      </div>
    </template>
  </DashboardWidgetArea>
</template>

<script>
import DashboardWidgetArea from '@/module/dashboard/components/WidgetList/DashboardWidgetArea.vue';
import CTable from '@/module/common/components/Data/CTable.vue';
import DSTooltip from '@/module/design-system/components/InfoBlock/DSTooltip.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';

export default {
  name: 'DashboardWidgetTable',
  components: {
    DSIcon,
    DSTextElement,
    DSTooltip,
    CTable,
    DashboardWidgetArea,
  },
  props: {
    widget: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    dimension: {
      type: String,
      required: true,
    },
  },
  methods: {
    getHeaderTitle(headerCell) {
      return headerCell?.title ?? '';
    },
    getHeaderTooltip(headerCell) {
      return headerCell?.tooltip ?? null;
    },
    getCellValue(cell) {
      const numberFormatService = this.$container.numberFormatService;
      let value = '';

      if (cell?.format === 'currency') {
        value = this.$t(`currency.title.${cell?.value}`);
      } else if (cell?.format === 'money') {
        value = numberFormatService.money(cell?.value ?? 0, cell?.currency);
      } else if (cell?.format === 'percentage') {
        value = numberFormatService.percentage(cell?.value ?? 0);
      } else if (cell?.format === 'quantity') {
        value = numberFormatService.amount(cell?.value ?? 0);
      } else {
        value = cell?.value ?? '';
      }

      return value;
    },
    getCellTextVariant(cell) {
      return cell?.textVariant ?? 'medium';
    },
    getCellClass(col) {
      const baseClass = 'dashboard-widget-table__cell';

      return [
        baseClass,
        col !== 0 ? `${baseClass}_align-right` : null,
        `${baseClass}_${col === 0 ? 'first-col' : 'data-col'}`,
      ];
    },
  },
};
</script>

<style
  scoped
  lang="scss"
>
.dashboard-widget-table {
  &__table {
     width: 100%;
  }

  &__cell {
    display: flex;
    width: 100%;
    white-space: nowrap;

    &_header {
      gap: 4px;
    }

    &_align-right {
      justify-content: flex-end;
    }
  }
}
</style>
