<template>
  <div
    class="c-card"
  >
    <div
      class="c-card__text"
    >
      <div
        class="c-card__title"
      >
        <DSHeader
          v-if="title"
          :size="4"
          :text="title"
        />
      </div>
      <div
        class="c-card__text"
      >
        <slot
          name="text-slot"
        />
        <DSParagraph
          v-if="!isTextSlot && text"
        >
          <!-- eslint-disable vue/no-v-html -->
          <span
            v-html="text"
          />
          <!-- eslint-enable vue/no-v-html -->
        </DSParagraph>
      </div>
    </div>
    <div
      v-if="showFooter"
      class="c-card__footer"
    >
      <div
        class="c-card__footer-left-side"
      >
        <slot
          name="footer-left"
        />
      </div>
      <div
        class="c-card__footer-right-side"
      >
        <slot
          name="footer-right"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DSHeader from '@/module/design-system/components/Text/DSHeader.vue';
import DSParagraph from '@/module/design-system/components/Text/DSParagraph.vue';

export default {
  name: 'CCard',
  components: {
    DSParagraph,
    DSHeader,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
  },
  computed: {
    isTextSlot() {
      return !!this.$scopedSlots['text-slot'];
    },
    showFooter() {
      return !!this.$scopedSlots['footer-left'] || !!this.$scopedSlots['footer-right'];
    },
  },
};
</script>

<style scoped lang="scss">
@import "variables";

.c-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 32px;
  background-color: #f8f8f8;
  border-radius: 16px;

  &__text {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &-left-side {
      display: flex;
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .c-card {
    &__footer {
      flex-direction: column;
      gap: 32px;

      &-left-side {
        width: 100%;
      }

      &-right-side {
        width: 100%;
      }
    }
  }
}
</style>
