<template>
  <div class="dashboard-widget-title">
    <DSTextElement
      color="secondary"
      variant="medium"
    >
      {{ title }}
    </DSTextElement>
    <DSTooltip
      v-if="tooltip"
      :text="tooltip"
    >
      <template
        #tooltip-icon
      >
        <DSIcon
          size="xs"
        />
      </template>
    </DSTooltip>
    <DSTextElement
      v-else
      color="secondary"
      variant="medium"
    >
      {{ title }}
    </DSTextElement>
  </div>
</template>

<script>
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import DSTooltip from '@/module/design-system/components/InfoBlock/DSTooltip.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';

export default {
  name: 'DashboardWidgetTitle',
  components: {
    DSIcon,
    DSTooltip,
    DSTextElement,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.dashboard-widget-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
</style>
