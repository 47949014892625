
import Vue, {
  computed,
  PropType,
} from 'vue';

import EWidth from '@/module/common/models/PageParts/CFormPageLayout/enum/EWidth';

import ErrorPage from '@/components/ErrorPage/ErrorPage.vue';
import PreloaderComponent from '@/components/Preloader/PreloaderComponent.vue';

export default Vue.extend({
  name: 'CFormPageLayout',
  components: {
    PreloaderComponent,
    ErrorPage,
  },
  props: {
    width: {
      type: String as PropType<EWidth>,
      default: EWidth.SM,
    },
    showError: {
      type: Boolean,
      default: false,
    },
    errorStatus: {
      type: [Number, String],
      default: null,
    },
    showPreloader: {
      type: Boolean,
      default: false,
    },

  },
  setup(props) {
    const classes = computed(() => [
      'c-form-page-layout',
      `c-form-page-layout_width_${props.width}`,
    ]);

    return {
      classes,
    };
  },
});
