import ELocale from '@/module/common/models/ELocale';

function loadLocaleMessages(translationApi) {
  const localisationRequests = [
    translationApi.list(ELocale.EN),
    translationApi.list(ELocale.RU),
  ];

  return Promise.all(localisationRequests)
    .then((responses) => ({
      en: responses[0].data,
      ru: responses[1].data,
    }));
}

export default loadLocaleMessages;
