export default function numberFormat(vm) {
  const { getters } = vm.$store;
  const locale = getters?.getLocale ?? 'en';

  return {
    money(value, currency) {
      const currencyKey = vm.$t(`currency.key.${currency}`);
      const result = new Intl.NumberFormat(
        locale,
        {
          style: 'currency',
          currency,
          currencyDisplay: 'narrowSymbol',
        },
      )
        .format(value);

      return vm.$utils.string.replace(result, currency, currencyKey);
    },
    amount(value) {
      return Number.isNaN(+value) ? value : new Intl.NumberFormat(this.locale).format(value);
    },
    percentage(value) {
      return `${parseFloat(value).toFixed(1)} %`;
    },
  };
}
