import TTSApi from '@/module/common/tsApi/TTSApi';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import { Store } from 'vuex';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';
import IScenario from '@/module/product/view/FulfillmentFormPage/model/contract/scenario/IScenario';
import IState from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/IState';
import ILocalServiceContainer from '@/module/product/view/FulfillmentFormPage/model/ILocalServiceContainer';
import IGeneratorMockService
  from '@/module/product/view/FulfillmentFormPage/model/contract/service/IGeneratorMockService';
import ELocalServiceFulfillment from '@/module/product/view/FulfillmentFormPage/model/enums/ELocalServiceFulfillment';
import INotificationService from '@/module/common/service/NotificationService/INotificationService';
import ENotificationType from '@/module/common/service/NotificationService/model/ENotificationType';
import VueRouter from 'vue-router';

class CreateTestGeneratorScenario implements IScenario {
  private readonly state: IState;

  private readonly serviceContainer: IServiceContainer;

  private readonly localServiceContainer: ILocalServiceContainer;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    localServiceContainer: ILocalServiceContainer,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.localServiceContainer = localServiceContainer;
  }

  run = (): void => {
    const notificationService = this.serviceContainer
      .resolve<INotificationService>(EServiceContainerInstances.NOTIFICATION_SERVICE);
    const router = this.serviceContainer
      .resolve<VueRouter>(EServiceContainerInstances.ROUTER);
    const generatorMockService = this.localServiceContainer
      .resolve<IGeneratorMockService>(ELocalServiceFulfillment.GENERATOR_MOCK_SERVICE);
    const tsApi = this.serviceContainer.resolve<TTSApi>(EServiceContainerInstances.TS_API);
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const localisationRegistry: ILocalisationRegistry = store.getters.getLocalisationRegistry;
    const popUpLocalisation = localisationRegistry.popUp;

    this.state.isLoading = true;

    tsApi.fulfillment.create(generatorMockService.getMock())
      .then(() => {
        notificationService.push(
          ENotificationType.INFO_ALERT,
          {
            title: localisationRegistry.fulfillment.form.form.request.save,
          },
        );

        this.state.isLoading = false;

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        store.dispatch('authStore/refreshInfo').catch(() => {});

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        router.push('/products').catch(() => {});
      })
      .catch(() => {
        notificationService.push(
          ENotificationType.ERROR_POPUP,
          {
            text: popUpLocalisation.wentWrong,
            title: popUpLocalisation.error,
          },
        );

        this.state.isLoading = false;
      });
  };
}

export default CreateTestGeneratorScenario;
