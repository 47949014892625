import IFile from '@/module/common/models/UI/FileUpload/IFile';
import EFileStatus from '@/module/common/models/UI/FileUpload/EFileStatus';
import EFileErrorType from '@/module/common/models/UI/FileUpload/EFileErrorType';
import ICFileUploadOptions from '@/module/common/models/UI/FileUpload/ICFileUploadOptions';
import castMegabytesToBytes from '@/module/common/utils/data/castMegabytesToBytes';
import EFileMimeTypes from '@/module/design-system/components/models/DSFileUpload/EFileMimeTypes';

function isValidMimeType(file: IFile, options: ICFileUploadOptions): boolean {
  if (!file.file) {
    return true;
  }
  if (!options.allowedMimeTypes || options.allowedMimeTypes.length === 0) {
    return true;
  }

  return options.allowedMimeTypes.includes(file.file.type as EFileMimeTypes);
}

function validateFiles(files: IFile[], options: ICFileUploadOptions) {
  return files.map((file) => {
    if (!file.file || file.status !== EFileStatus.DEFAULT) {
      return file;
    }

    const maxFileSize = options.maxFileSize ?? 5;
    const maxFileNameLength = options.maxFileNameLength ?? 255;

    if (file.file.size > castMegabytesToBytes(maxFileSize)) {
      file.status = EFileStatus.ERROR;
      file.errors.push({
        type: EFileErrorType.MAX_SIZE,
        message: options.errorMessages[EFileErrorType.MAX_SIZE],
      });
    }
    if (file.file.size === 0) {
      file.status = EFileStatus.ERROR;
      file.errors.push({
        type: EFileErrorType.MIN_SIZE,
        message: options.errorMessages[EFileErrorType.MIN_SIZE],
      });
    }
    if (file.fileName.length > maxFileNameLength) {
      file.status = EFileStatus.ERROR;
      file.errors.push({
        type: EFileErrorType.NAME,
        message: options.errorMessages[EFileErrorType.NAME],
      });
    }
    if (!isValidMimeType(file, options)) {
      file.status = EFileStatus.ERROR;
      file.errors.push({
        type: EFileErrorType.MIME_TYPE,
        message: options.errorMessages[EFileErrorType.MIME_TYPE],
      });
    }

    return file;
  });
}

export default validateFiles;
