import ENotificationType from '@/module/common/service/NotificationService/model/ENotificationType';
import INotification from '@/module/common/service/NotificationService/model/INotification';
import INotificationService from '@/module/common/service/NotificationService/INotificationService';
import { Store } from 'vuex';
import ENotificationComponent from '@/module/common/service/NotificationService/model/ENotificationComponent';
import ENotifyVariant from '@/module/design-system/components/models/UI/ENotifyVariant';
import INotificationConfig from '@/module/common/service/NotificationService/model/INotificationConfig';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';

class NotificationService implements INotificationService {
  private serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  push = async (notificationType: ENotificationType, notification: INotification) => {
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);

    const {
      text,
      title,
      delay,
      control,
    } = notification;

    const notificationConfig: INotificationConfig = {
      title,
      delay,
      control,
      component: this.getNotificationComponent(notificationType),
      type: this.getNotificationTypeName(notificationType),
    };

    if (text) {
      notificationConfig.text = text;
    }

    await store.dispatch(
      'notificationsStore/pushNotification',
      notificationConfig,
    );
  };

  private getNotificationComponent = (notificationType: ENotificationType): ENotificationComponent => {
    const map = {
      [ENotificationType.ERROR_ALERT]: ENotificationComponent.DS_ALERT,
      [ENotificationType.ERROR_POPUP]: ENotificationComponent.DS_POPUP,
      [ENotificationType.INFO_ALERT]: ENotificationComponent.DS_ALERT,
      [ENotificationType.INFO_POPUP]: ENotificationComponent.DS_POPUP,
    };

    return map[notificationType];
  };

  private getNotificationTypeName = (notificationType: ENotificationType): ENotifyVariant => {
    const map = {
      [ENotificationType.ERROR_ALERT]: ENotifyVariant.ERROR,
      [ENotificationType.ERROR_POPUP]: ENotifyVariant.ERROR,
      [ENotificationType.INFO_ALERT]: ENotifyVariant.INFO,
      [ENotificationType.INFO_POPUP]: ENotifyVariant.INFO,
    };

    return map[notificationType];
  };
}

export default NotificationService;
