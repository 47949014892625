import loadLocaleMessages from '@/i18n/loadLocaleMessages';
import initLocalisationEngine from '@/i18n/initLocalisationEngine';

async function initLocalisation(vue, serviceContainer) {
  const translationApi = serviceContainer.apiService.translation;

  const messages = await loadLocaleMessages(translationApi);

  return initLocalisationEngine(vue, messages);
}

export default initLocalisation;
