<template>
  <label :class="['checkbox', checked && 'checkbox_checked', disabled && 'checkbox_disabled']">
    <span v-if="hasLabelSlot">
      <slot name="label" />
    </span>
    <span v-else>{{ label }}</span>
    <input
      type="checkbox"
      class="checkbox__control"
      :checked="checked"
      :value="inputValue"
      :disabled="disabled"
      @change="handleChange($event)"
    >
    <span class="checkbox__checkmark" />
  </label>
</template>

<script>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'CheckboxComponent',
  props: {
    modelValue: {
      type: [Boolean, Object],
      default: null,
    },
    inputValue: {
      type: [Boolean, Object],
      default: null,
    },
    binary: Boolean,
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, slots }) {
    const equals = computed(
      () => !!props.modelValue.find(
        (val) => (typeof val === 'string'
          ? val === props.inputValue
          : val.id === props.inputValue.id),
      ),
    );
    const checked = computed(() => (props.binary ? props.modelValue : equals.value));
    const hasLabelSlot = computed(() => !!slots.label);

    const handleChange = () => {
      let newModelValue;

      if (props.binary) {
        newModelValue = !props.modelValue;
      } else if (checked.value) {
        newModelValue = props.modelValue.filter((val) => (typeof val === 'string'
          ? val !== props.inputValue
          : val.id !== props.inputValue.id));
      } else {
        newModelValue = props.modelValue ? [...props.modelValue, props.inputValue] : [props.inputValue];
      }

      emit('update:modelValue', newModelValue);
    };

    return {
      hasLabelSlot,
      handleChange,
      checked,
    };
  },
});
</script>

<style lang="scss">
@import "style";
</style>
