import IComparatorTool from '@/module/common/service/UtilsService/contract/IComparatorTool';
import IUtilsService from '@/module/common/service/UtilsService/contract/IUtilsService';

class ComparatorTool implements IComparatorTool {
  private utils: IUtilsService;

  constructor(utils: IUtilsService) {
    this.utils = utils;
  }

  match = (value1: unknown, value2: unknown): boolean => {
    if (this.utils.typeCheck.isSameType(value1, value2)) {
      return false;
    }

    if (this.utils.typeCheck.isNumber(value1)) {
      return value1 === value2;
    }

    if (this.utils.typeCheck.isArray(value1) && this.utils.typeCheck.isArray(value2)) {
      const compareFirstWithSecond = value1.reduce((result, val, index) => {
        const preResult = this.match(value1[index] ?? null, value2[index] ?? null);
        return result && preResult;
      }, true) as boolean;
      const compareSecondWithFirst = value2.reduce((result, val, index) => {
        const preResult = this.match(value2[index] ?? null, value1[index] ?? null);
        return result && preResult;
      }, true) as boolean;

      return compareFirstWithSecond && compareSecondWithFirst;
    }

    if (this.utils.typeCheck.isObject(value1) && this.utils.typeCheck.isObject(value2)) {
      const compareFirstWithSecond = Object.keys((value1)).reduce((result, key) => {
        const preResult = this.match((value1)[key] ?? null, (value2)[key] ?? null);
        return result && preResult;
      }, true);
      const compareSecondWithFirst = Object.keys((value2)).reduce((result, key) => {
        const preResult = this.match((value2)[key] ?? null, (value1)[key] ?? null);
        return result && preResult;
      }, true);

      return compareFirstWithSecond && compareSecondWithFirst;
    }

    return value1 === value2;
  };
}

export default ComparatorTool;
