export default (serviceContainer) => ({
  namespaced: true,
  state: {
    checkoutLinksList: [],
    checkoutLinksListTotal: 0,
    preloader: false,
  },
  mutations: {
    setCheckoutLinksList(state, payload) {
      state.checkoutLinksList = payload;
    },
    setCheckoutLinksListTotal(state, payload) {
      state.checkoutLinksListTotal = payload;
    },
    setPreloader(state, payload) {
      state.preloader = payload;
    },
  },
  getters: {
    getCheckoutLinksList(state) {
      return state.checkoutLinksList;
    },
    getCheckoutLinksListTotal(state) {
      return state.checkoutLinksListTotal;
    },
    getPreloader(state) {
      return state.preloader;
    },
  },
  actions: {
    async refreshCheckoutLinks({ commit }, filter) {
      const api = serviceContainer.resolve('api');

      const response = await api.product.checkoutLink.list(filter);

      commit('setCheckoutLinksList', response?.data?.items ?? []);
      commit('setCheckoutLinksListTotal', response?.data?.totalCount ?? 0);
    },
    async updateCheckoutLinks({ commit, getters }, filter) {
      const api = serviceContainer.resolve('api');

      commit('setPreloader', true);
      try {
        const response = await api.product.checkoutLink.list(filter);
        commit('setPreloader', false);

        const data = getters.getCheckoutLinksList.concat(response?.data?.items ?? []);

        commit('setCheckoutLinksList', data);
        commit('setCheckoutLinksListTotal', response?.data?.totalCount ?? 0);
      } catch (_e) {
        commit('setPreloader', false);
      }
    },
  },
});
