export default function promotions(instance) {
  return {
    list(options) {
      const parts = [
        `limit=${options.limit}`,
        `offset=${options.offset}`,
        'sortByUpdateDate=desc',
      ];

      if (options.promotionType) {
        parts.push(`promotionType=${options.promotionType}`);
      }

      if (options.promotionStatus) {
        parts.push(`promotionStatus=${options.promotionStatus}`);
      }

      if (options.searchString) {
        parts.push(`searchString=${options.searchString}`);
      }

      if (options.coupon) {
        parts.push(`coupon=${options.coupon}`);
      }

      const query = parts.filter((part) => !!part).join('&');

      return instance.get(`/vendor/promotion?${query}`);
    },
  };
}
