import parseJSON from '@/module/common/utils/json/parseJSON';

export default function getLocalStorageItem<T>(key, defaultValue: T = '' as unknown as T): T {
  const value = localStorage.getItem(key);

  if (value === null || value === 'null') {
    return defaultValue;
  }

  try {
    return parseJSON(value);
  } catch (e) {
    return value as unknown as T;
  }
}
