<template>
  <div
    :class="classes"
  >
    <div
      v-if="isDefaultSlot"
      ref="tooltip-parent"
      class="tooltip__parent"
      @mouseenter="handleParentMouseEnter"
      @mouseleave="handleParentMouseLeave"
    >
      <slot />
    </div>
    <div
      v-if="isIconSLot"
      ref="tooltip-icon-parent"
      class="tooltip__icon"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
    >
      <slot
        name="tooltip-icon"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DSTooltip',
  props: {
    text: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      tooltipTextElement: null,
      tooltipPointerElement: null,
      isMouseOverTooltip: false,
      isMouseOverParentSlot: false,
      horizontalTooltipTextPadding: 20,
      bodyHeight: 0,
      bodyWidth: 0,
    };
  },
  computed: {
    isDefaultSlot() {
      return !!this.$scopedSlots.default;
    },
    isIconSLot() {
      return !!this.$scopedSlots['tooltip-icon'];
    },
    parentSlotWrapper() {
      return this.isIconSLot ? this.$refs['tooltip-icon-parent'] : this.$refs['tooltip-parent'];
    },
    tooltipTextClasses() {
      return [
        'tooltip__text',
      ];
    },
    tooltipPointerClasses() {
      return [
        'tooltip__pointer',
        'tooltip__pointer_horizontal',
        this.isEnoughBottomSpace ? null : 'tooltip__pointer_horizontal_mirror',
      ];
    },
    tooltipPointerPositionClasses() {
      return [
        'tooltip__pointer-position',
        'tooltip__pointer-position_horizontal',
      ];
    },
    tooltipTextStyles() {
      const styles = {
        top: 0,
        left: 0,
      };

      if (!this.$el || !this.tooltipTextElement || !this.tooltipPointerElement) {
        return styles;
      }

      const parentSlotWrapperRect = this.parentSlotWrapper.getBoundingClientRect();
      const pointerRect = this.tooltipPointerElement.getBoundingClientRect();
      const textRect = this.tooltipTextElement.getBoundingClientRect();

      if (this.isEnoughBottomSpace) {
        styles.top = `${parentSlotWrapperRect.bottom + pointerRect.height}px`;
      } else {
        styles.top = `${parentSlotWrapperRect.top - pointerRect.height - textRect.height}px`;
      }

      styles.left = this.getLeftPosition(parentSlotWrapperRect, textRect);

      return styles;
    },
    tooltipPointerPositionStyles() {
      const styles = {
        top: 0,
        left: 0,
      };

      if (!this.$el || !this.tooltipPointerElement) {
        return styles;
      }

      const parentSlotWrapperRect = this.parentSlotWrapper.getBoundingClientRect();
      const pointerRect = this.tooltipPointerElement.getBoundingClientRect();

      if (this.isEnoughBottomSpace) {
        styles.top = `${parentSlotWrapperRect.bottom}px`;
      } else {
        styles.top = `${parentSlotWrapperRect.top - pointerRect.height}px`;
      }

      const parentLeft = parentSlotWrapperRect.left;
      const parentSlotWidthHalf = parentSlotWrapperRect.width / 2;
      const pointerWidthHalf = pointerRect.width / 2;
      const parentMarginHalf = 0;

      styles.left = `${parentLeft + parentSlotWidthHalf + parentMarginHalf - pointerWidthHalf}px`;

      return styles;
    },
    tooltipFullHeight() {
      const tooltipRect = this.tooltipTextElement.getBoundingClientRect();
      const tooltipPointerRect = this.tooltipPointerElement.getBoundingClientRect();

      return tooltipRect.height + tooltipPointerRect.height;
    },
    isEnoughBottomSpace() {
      if (!this.$el) {
        return false;
      }

      const componentRect = this.$el.getBoundingClientRect();

      return this.bodyHeight > componentRect.bottom + this.tooltipFullHeight;
    },
    isEnoughLeftSpace() {
      const slotWrapperRect = this.parentSlotWrapper.getBoundingClientRect();
      const tooltipTextElementRect = this.tooltipTextElement.getBoundingClientRect();

      return slotWrapperRect.left + slotWrapperRect.width / 2
        + this.horizontalTooltipTextPadding - tooltipTextElementRect.width / 2 > 0;
    },
    isEnoughRightSpace() {
      const slotWrapperRect = this.parentSlotWrapper.getBoundingClientRect();
      const tooltipTextElementRect = this.tooltipTextElement.getBoundingClientRect();

      return slotWrapperRect.left + slotWrapperRect.width / 2
        + tooltipTextElementRect.width / 2 + this.horizontalTooltipTextPadding < this.bodyWidth;
    },
    classes() {
      return [
        'tooltip',
        this.isIconSLot ? 'tooltip_spaced' : null,
      ];
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.bodyHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight,
    );
    this.bodyWidth = Math.max(
      document.body.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.clientWidth,
      document.documentElement.scrollWidth,
      document.documentElement.offsetWidth,
    );
  },
  beforeDestroy() {
    this.deleteTooltip();
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    createTooltip() {
      if (this.tooltipTextElement || document.body.contains(this.tooltipTextElement)) {
        return;
      }
      this.tooltipTextElement = document.createElement('div');

      this.tooltipTextElement.innerHTML = this.text;

      Array.from(this.tooltipTextElement.querySelectorAll('a'))
        .forEach((link) => {
          link.addEventListener('click', this.deleteTooltip);
        });

      document.body.appendChild(this.tooltipTextElement);

      this.tooltipTextClasses.forEach((tooltipTextClass) => {
        this.tooltipTextElement.classList.add(tooltipTextClass);
      });

      this.createTooltipPointer();

      this.tooltipTextElement.style.top = this.tooltipTextStyles.top;
      this.tooltipTextElement.style.left = this.tooltipTextStyles.left;

      this.tooltipTextElement.addEventListener('mouseenter', (event) => {
        const coordinatesForInRect = this.getCoordinatesForInRect(event);
        this.enableCursorsLock(coordinatesForInRect.x, coordinatesForInRect.y);
      });

      this.tooltipTextElement.addEventListener('mouseleave', (event) => {
        const coordinatesForOutRect = this.getCoordinatesForOutRect(event);
        this.dropCursorsLock(coordinatesForOutRect.x, coordinatesForOutRect.y);
        if (!this.isMouseOverTooltip && !this.isMouseOverParentSlot) {
          setTimeout(() => {
            if (!this.isMouseOverParentSlot && !this.isMouseOverTooltip) {
              this.deleteTooltip();
            }
          }, 100);
        }
      });
    },
    createTooltipPointer() {
      if (this.tooltipPointerElement || document.body.contains(this.tooltipPointerElement)) {
        return;
      }
      this.tooltipPointerElement = document.createElement('div');

      const pointerElement = document.createElement('div');

      document.body.appendChild(this.tooltipPointerElement);

      this.tooltipPointerElement.appendChild(pointerElement);

      this.tooltipPointerPositionClasses.forEach((tooltipTextClass) => {
        this.tooltipPointerElement.classList.add(tooltipTextClass);
      });

      this.tooltipPointerClasses.forEach((tooltipPointerClass) => {
        pointerElement.classList.add(tooltipPointerClass);
      });

      this.tooltipPointerElement.style.top = this.tooltipPointerPositionStyles.top;
      this.tooltipPointerElement.style.left = this.tooltipPointerPositionStyles.left;
    },
    deleteTooltip() {
      if (document.body.contains(this.tooltipTextElement)) {
        document.body.removeChild(this.tooltipTextElement);
        this.tooltipTextElement = null;
      }
      this.deleteTooltipPointer();
    },
    deleteTooltipPointer() {
      if (document.body.contains(this.tooltipPointerElement)) {
        document.body.removeChild(this.tooltipPointerElement);
        this.tooltipPointerElement = null;
      }
    },
    handleMouseEnter(event) {
      const coordinatesForInRect = this.getCoordinatesForInRect(event);
      this.enableCursorsLock(coordinatesForInRect.x, coordinatesForInRect.y);
      this.createTooltip();
    },
    handleMouseLeave(event) {
      const coordinatesForOutRect = this.getCoordinatesForOutRect(event);
      this.dropCursorsLock(coordinatesForOutRect.x, coordinatesForOutRect.y);
      if (!this.isMouseOverParentSlot && !this.isMouseOverTooltip) {
        setTimeout(
          () => (!this.isMouseOverParentSlot && !this.isMouseOverTooltip ? this.deleteTooltip() : null),
          100,
        );
      }
    },
    handleParentMouseEnter(event) {
      if (this.isIconSLot) {
        return;
      }

      this.handleMouseEnter(event);
    },
    handleParentMouseLeave(event) {
      if (this.isIconSLot) {
        return;
      }

      this.handleMouseLeave(event);
    },
    isCursorInRect(x, y, node) {
      if (!node) {
        return false;
      }

      const rect = node.getBoundingClientRect();

      const horizontalCheck = Math.floor(rect.left) <= x && x <= Math.ceil(rect.right);
      const verticalCheck = Math.floor(rect.top) <= y && y <= Math.ceil(rect.bottom);

      return horizontalCheck && verticalCheck;
    },
    handleScroll() {
      this.deleteTooltip();
    },
    enableCursorsLock(x, y) {
      if (this.isCursorInRect(x, y, this.parentSlotWrapper)) {
        this.isMouseOverParentSlot = true;
      }
      if (this.isCursorInRect(x, y, this.tooltipTextElement)) {
        this.isMouseOverTooltip = true;
      }
    },
    dropCursorsLock(x, y) {
      if (!this.isCursorInRect(x, y, this.parentSlotWrapper)) {
        this.isMouseOverParentSlot = false;
      }
      if (!this.isCursorInRect(x, y, this.tooltipTextElement)) {
        this.isMouseOverTooltip = false;
      }
    },
    getLeftPosition(parentSlotWrapperRect, textRect) {
      let left = '0px';
      if (this.isEnoughLeftSpace && this.isEnoughRightSpace) {
        const parentLeft = parentSlotWrapperRect.left;
        const parentSlotWidthHalf = parentSlotWrapperRect.width / 2;
        const parentMarginHalf = 0;
        const textWidthHalf = textRect.width / 2;

        left = `${parentLeft + parentSlotWidthHalf + parentMarginHalf - textWidthHalf}px`;
      } else {
        const rightBorder = parentSlotWrapperRect.width / 2
          + parentSlotWrapperRect.left + textRect.width / 2 + this.horizontalTooltipTextPadding;
        const leftBorder = parentSlotWrapperRect.width / 2
          + parentSlotWrapperRect.left - textRect.width / 2 - this.horizontalTooltipTextPadding;

        if (leftBorder < 0 && this.bodyWidth - textRect.width - this.horizontalTooltipTextPadding > 0) {
          left = `${this.horizontalTooltipTextPadding}px`;
        } else if (leftBorder < 0) {
          left = `${this.horizontalTooltipTextPadding}px`;
        } else if (this.bodyWidth - rightBorder < 0) {
          const offset = -(this.bodyWidth - rightBorder);
          const leftPosition = leftBorder - offset < 0 ? 0 : leftBorder - offset;

          left = `${leftPosition}px`;
        }
      }

      return left;
    },
    getCoordinatesForInRect(event) {
      return {
        x: event.x - event.offsetX,
        y: event.y - event.offsetY,
      };
    },
    getCoordinatesForOutRect(event) {
      return {
        x: event.x + event.offsetX,
        y: event.y + event.offsetY,
      };
    },
  },
};
</script>

<style lang="scss">
.tooltip {
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &_spaced {
    gap: 4px;
  }

  &__parent {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__pointer-position {
    position: absolute;

    &_horizontal {
      width: 14px;
      height: 7px;
    }
  }

  &__pointer {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &_horizontal_mirror {
      transform: scaleY(-1);
    }

    &::before {
      display: block;
      position: absolute;
      content: '';
      background-color: #1f2847;
      opacity: 0.9;
      height: 10px;
      width: 10px;
      border-radius: 2px;
      transform: translate(21%, 21%) rotate(45deg);
      z-index: 100;
    }
  }

  &__text {
    display: block;
    position: absolute;
    box-sizing: border-box;
    padding: 12px 10px;
    border-radius: 4px;
    width: 320px;
    color: white;
    background-color: #1f2847;
    opacity: 0.9;
    z-index: 100;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
  }
}
</style>
