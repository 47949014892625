import VueRouter from 'vue-router';

import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';

import IFormHandler from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/contract/IFormHandler';
import IHandleFieldBlurParams
  from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/interface/IHandleFieldBlurParams';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';

class FormHandler implements IFormHandler {
  private readonly serviceContainer: IServiceContainer;

  constructor(
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
  }

  handleClickSave = () => {
    const paymentLinkFormPage = this.serviceContainer.moduleProduct.paymentLinkFormPage;

    const pageValidatorHandler = paymentLinkFormPage.pageValidatorHandler;

    const state = paymentLinkFormPage.stateHandler.get();

    pageValidatorHandler.validateForm();

    if (state.isFormValid) {
      const createScenario = paymentLinkFormPage.createPaymentLinkScenario;

      createScenario.run();
    }
  };

  handleClickCancel = () => {
    const router = this.serviceContainer.resolve<VueRouter>(EServiceContainerInstances.ROUTER);

    router.push('/payment-links').catch(() => null);
  };

  handleBlur = (item: IHandleFieldBlurParams) => {
    const paymentLinkFormPage = this.serviceContainer.moduleProduct.paymentLinkFormPage;
    const formData = paymentLinkFormPage.formDataHandler.get();

    // @TODO подумать про типизацию
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    formData[item.field.fieldName] = item.value;

    paymentLinkFormPage.formDataHandler.update(formData);

    const pageValidatorHandler = paymentLinkFormPage.pageValidatorHandler;

    pageValidatorHandler.validateField(item);
  };
}

export default FormHandler;
