<template>
  <div class="c-radio">
    <DSRadioButton
      ref="radio"
      :checked="checked"
      :name="name"
      :value="value"
      class="c-radio__input"
      type="radio"
    />
    <label
      :for="radio"
      class="c-radio__label"
    >
      <DSTextElement>
        {{ title }}
      </DSTextElement>
    </label>
  </div>
</template>

<script>
import DSRadioButton from '@/module/design-system/components/UI/DSRadioButton.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default {
  name: 'CRadioButton',
  components: {
    DSRadioButton,
    DSTextElement,
  },
  props: {
    name: {
      type: [
        String,
        Number,
      ],
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [
        String,
        Number,
      ],
      default: () => null,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      radio: null,
    };
  },
  mounted() {
    this.radio = this.$refs.radio;
  },
};
</script>

<style
  lang="scss"
  scoped
>
.c-radio {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 13px 12px;
  cursor: pointer;
  gap: 8px;

  &__label {
    cursor: pointer;
  }
}
</style>
