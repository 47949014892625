import EAccountActivateFileType from '@/module/merchant/models/EAccountActivateFileType';
import IFormField from '@/module/design-system/components/models/FormComponent/IFormField';
import ICamelCaseLocalisationAccountActivatePage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationAccountActivatePage';
import IBusinessType from '@/module/merchant/models/BuisnessType/IBusinessType';
import EBusinessType from '@/module/merchant/models/BuisnessType/EBusinessType';
import ETypeOfBusiness from '@/module/merchant/models/BuisnessType/ETypeOfBusiness';
import TTypeOFBusiness from '@/module/merchant/view/AccountActivatePage/models/TTypeOFBusiness';
import TIndividual from '@/module/merchant/view/AccountActivatePage/models/TIndividual';
import EIndividualKey from '@/module/merchant/view/AccountActivatePage/models/EIndividualKey';
import EIndividual from '@/module/merchant/models/BuisnessType/EIndividual';
import TTaxationSystem from '@/module/merchant/view/AccountActivatePage/models/TTaxationSystem';
import ETaxationSystem from '@/module/merchant/view/AccountActivatePage/models/ETaxationSystem';
import TBusinessRepresentative from '@/module/merchant/view/AccountActivatePage/models/TBusinessRepresentative';
import TTaxCalculationList from '@/module/merchant/view/AccountActivatePage/models/TTaxCalculationList';
import ETaxCalculation from '@/module/merchant/view/AccountActivatePage/models/ETaxCalculation';
import EBusinessRepresentativeIndividualEntrepreneur
  from '@/module/merchant/view/AccountActivatePage/models/EBusinessRepresentativeIndividualEntrepreneur';
import EOrganizationalLegalFormOfBusiness
  from '@/module/merchant/view/AccountActivatePage/models/EOrganizationalLegalFormOfBusiness';
import EBusinessRepresentativeCompany from './AccountActivatePage/models/EBusinessRepresentativeCompany';
import EFieldName from './AccountActivatePage/models/EFieldName';
import EFieldBlock from './AccountActivatePage/models/EFieldBlock';

function readSummaryFieldTitle(
  localisation: ICamelCaseLocalisationAccountActivatePage,
  fieldBlock: string,
  fieldName: string,
  businessType: IBusinessType,
): string {
  const businessInfo = localisation.businessInfo;

  let addressField = businessType.typeOfBusiness.value;
  let jobTitle = businessInfo.businessRepresentative.jobTitle.title.company;

  if (businessType.id === EBusinessType.INDIVIDUAL_ENTREPRENEUR) {
    addressField = ETypeOfBusiness.COMPANY;
    jobTitle = businessInfo.businessRepresentative.jobTitle.title.individualEntrepreneur;
  }

  const summaryFieldTitles = {
    businessStructure: {
      country: businessInfo.businessStructure.country.title,
      addressLine1: businessInfo.businessStructure.addressLine1.title,
      addressLine2: businessInfo.businessStructure.addressLine2.title,
      townOrCity: businessInfo.businessStructure.townOrCity.title,
      postalCode: businessInfo.businessStructure.postalCode.title,
      typeOfBusiness: businessInfo.businessStructure.typeOfBusiness.softline.title,
      businessType: businessInfo.businessStructure.typeOfBusiness.softline.title,
      legalAddress: businessInfo.businessStructure.legalAddress[addressField].title,
      actualAddress: businessInfo.businessStructure.actualAddress[addressField].title,
    },
    businessRepresentative: {
      firstName: businessInfo.businessRepresentative.firstName.title,
      lastName: businessInfo.businessRepresentative.lastName.title,
      middleName: businessInfo.businessRepresentative.middleName.title,
      identificationNumber: businessInfo.businessRepresentative.identificationNumber.title,
      jobTitle,
      document: businessInfo.businessRepresentative.document.title,
      email: businessInfo.businessRepresentative.email.title,
      dateOfBirth: businessInfo.businessRepresentative.dateOfBirth.title,
      phoneNumber: businessInfo.businessRepresentative.phoneNumber.title,
      representative: businessInfo.businessRepresentative.representative.title,
      documentCompany: businessInfo.businessRepresentative.documentCompany.label,
      documentIndividualEntrepreneur: businessInfo.businessRepresentative.documentIndividualEntrepreneur.label,
      documentPassport: businessInfo.businessRepresentative.documentPassport.label,
      documentSnils: businessInfo.businessRepresentative.documentSnils.label,
    },
    businessDetails: {
      organizationalLegalFormOfBusiness: businessInfo.businessDetails.organizationalLegalFormOfBusiness.title,
      businessType: businessInfo.businessDetails.individual.title,
      individual: businessInfo.businessDetails.individual.title,
      legalBusinessName: businessInfo.businessDetails.legalBusinessName.title,
      taxIdentificationCode: businessInfo.businessDetails.taxIdentificationCode.title,
      doingBusinessAs: businessInfo.businessDetails.doingBusinessAs.title,
      businessWebsite: businessInfo.businessDetails.businessWebsite.title,
      productDescription: businessInfo.businessDetails.productDescription.title,
      kpp: businessInfo.businessDetails.kpp.title,
      ogrn: businessInfo.businessDetails.ogrn.title,
      passport: businessInfo.businessDetails.passport.title,
      passportDate: businessInfo.businessDetails.passportDate.title,
      passportCode: businessInfo.businessDetails.passportCode.title,
      snils: businessInfo.businessDetails.snils.title,
      documentOgrnip: businessInfo.businessDetails.documentOgrnip.title,
      productTaxCategory: businessInfo.productTaxCategory.title,
      taxationSystem: businessInfo.businessDetails.taxationSystem.title,
    },
    supportDetails: {
      phoneNumber: localisation.support.phone.title,
      email: localisation.support.email.title,
    },
    accountForPayouts: {
      currency: localisation.bank.currency.title,
      country: localisation.bank.country.title,
      bankName: localisation.bank.bankName.title,
      iban: localisation.bank.iban.title,
      ibanConfirm: localisation.bank.ibanConfirm.title,
      vatNumber: localisation.bank.vatNumber.title,
      swift: localisation.bank.swift.title,
      bik: localisation.bank.bik.title,
      rs: localisation.bank.rs.title,
      ks: localisation.bank.ks.title,
    },
    taxCalculation: {
      taxCalculation: localisation.taxCalculation.taxCalculation.title,
    },
  };

  return summaryFieldTitles[fieldBlock]?.[fieldName] ?? '';
}

function getTypeOfBusinessList(localisation: ICamelCaseLocalisationAccountActivatePage): TTypeOFBusiness {
  return {
    [ETypeOfBusiness.COMPANY]: {
      id: 1,
      title: localisation.businessInfo.businessStructure.typeOfBusiness.softline.buttons.company,
      value: ETypeOfBusiness.COMPANY,
    },
    [ETypeOfBusiness.INDIVIDUAL]: {
      id: 2,
      title: localisation.businessInfo.businessStructure.typeOfBusiness.softline.buttons.individual,
      value: ETypeOfBusiness.INDIVIDUAL,
    },
  };
}

function getIndividualList(localisation: ICamelCaseLocalisationAccountActivatePage): TIndividual {
  return {
    [EIndividualKey.INDIVIDUAL]: {
      id: 1,
      title: localisation.businessInfo.businessDetails.individual.buttons[EIndividualKey.INDIVIDUAL],
      value: EIndividual.INDIVIDUAL,
    },
    [EIndividualKey.SELF_EMPLOYED]: {
      id: 2,
      title: localisation.businessInfo.businessDetails.individual.buttons[EIndividualKey.SELF_EMPLOYED],
      value: EIndividual.SELF_EMPLOYED,
    },
    [EIndividualKey.INDIVIDUAL_ENTREPRENEUR]: {
      id: 3,
      title: localisation.businessInfo.businessDetails.individual.buttons[EIndividualKey.INDIVIDUAL_ENTREPRENEUR],
      value: EIndividual.INDIVIDUAL_ENTREPRENEUR,
    },
  };
}

function getTaxationSystemList(localisation: ICamelCaseLocalisationAccountActivatePage): TTaxationSystem {
  return {
    [ETaxationSystem.NDS_IS_INCLUDED]: {
      id: ETaxationSystem.NDS_IS_INCLUDED,
      title: localisation.businessInfo.businessDetails.taxationSystem.ndsIsIncluded,
    },
    [ETaxationSystem.NDS_IS_EXCLUDED]: {
      id: ETaxationSystem.NDS_IS_EXCLUDED,
      title: localisation.businessInfo.businessDetails.taxationSystem.ndsIsExcluded,
    },
    [ETaxationSystem.SKOLKOVO_RESIDENT]: {
      id: ETaxationSystem.SKOLKOVO_RESIDENT,
      title: localisation.businessInfo.businessDetails.taxationSystem.skolkovoResident,
    },
  };
}

function getBusinessRepresentativeList(
  businessType: IBusinessType,
  localisation: ICamelCaseLocalisationAccountActivatePage,
): TBusinessRepresentative {
  const representativeListMap = {
    [EBusinessType.COMPANY]: {
      [EBusinessRepresentativeCompany.HEADER]: {
        id: EBusinessRepresentativeCompany.HEADER,
        title: localisation.businessInfo.businessRepresentative.representative.header,
      },
      [EBusinessRepresentativeCompany.OTHER]: {
        id: EBusinessRepresentativeCompany.OTHER,
        title: localisation.businessInfo.businessRepresentative.representative.other,
      },
    },
    [EBusinessType.INDIVIDUAL_ENTREPRENEUR]: {
      [EBusinessRepresentativeIndividualEntrepreneur.INDIVIDUAL_ENTREPRENEUR]: {
        id: EBusinessRepresentativeIndividualEntrepreneur.INDIVIDUAL_ENTREPRENEUR,
        title: localisation.businessInfo.businessRepresentative.representative.individualEntrepreneur,
      },
      [EBusinessRepresentativeIndividualEntrepreneur.ATTORNEY_IN_FACT]: {
        id: EBusinessRepresentativeIndividualEntrepreneur.ATTORNEY_IN_FACT,
        title: localisation.businessInfo.businessRepresentative.representative.attorneyInFact,
      },
    },
  };

  const mapKey = businessType.id ?? EBusinessType.COMPANY;

  return representativeListMap[mapKey];
}

function getTaxCalculationList(localisation: ICamelCaseLocalisationAccountActivatePage) :TTaxCalculationList {
  return {
    [ETaxCalculation.INCLUSIVE]: {
      id: 1,
      title: localisation.taxCalculation.taxCalculation.buttons[ETaxCalculation.INCLUSIVE],
      value: ETaxCalculation.INCLUSIVE,
    },
    [ETaxCalculation.EXCLUSIVE]: {
      id: 2,
      title: localisation.taxCalculation.taxCalculation.buttons[ETaxCalculation.EXCLUSIVE],
      value: ETaxCalculation.EXCLUSIVE,
    },
  };
}

function getBusinessTypes(
  localisation: ICamelCaseLocalisationAccountActivatePage,
): IBusinessType[] {
  const typeOfBusinessList = getTypeOfBusinessList(localisation);
  const individualList = getIndividualList(localisation);

  const businessStructure = localisation.businessInfo.businessStructure;
  const businessDetails = localisation.businessInfo.businessDetails;

  return [
    {
      id: EBusinessType.COMPANY,
      title: businessStructure.typeOfBusiness.softline.buttons[ETypeOfBusiness.COMPANY],
      typeOfBusiness: typeOfBusinessList[ETypeOfBusiness.COMPANY],
    },
    {
      id: EBusinessType.INDIVIDUAL_ENTREPRENEUR,
      title: businessDetails.individual.buttons[EIndividualKey.INDIVIDUAL_ENTREPRENEUR],
      typeOfBusiness: typeOfBusinessList[ETypeOfBusiness.COMPANY],
      individual: individualList[EIndividualKey.INDIVIDUAL_ENTREPRENEUR],
    },
    {
      id: EBusinessType.INDIVIDUAL,
      title: businessStructure.typeOfBusiness.softline.buttons[EIndividualKey.INDIVIDUAL],
      typeOfBusiness: typeOfBusinessList[EIndividualKey.INDIVIDUAL],
      individual: individualList[EIndividualKey.INDIVIDUAL],
    },
    {
      id: EBusinessType.SELF_EMPLOYED,
      title: businessDetails.individual.buttons[EIndividualKey.SELF_EMPLOYED],
      typeOfBusiness: typeOfBusinessList[EIndividualKey.INDIVIDUAL],
      individual: individualList[EIndividualKey.SELF_EMPLOYED],
    },
  ];
}

function getBusinessType(
  localisation: ICamelCaseLocalisationAccountActivatePage,
  typeOfBusiness: ETypeOfBusiness,
  organizationalLegalFormOfBusiness?: EOrganizationalLegalFormOfBusiness,
): IBusinessType {
  const businessTypes = getBusinessTypes(localisation);

  const businessType = businessTypes.find((item) => {
    if (typeOfBusiness === ETypeOfBusiness.COMPANY
      && organizationalLegalFormOfBusiness === EOrganizationalLegalFormOfBusiness.IP
    ) {
      return item.title === organizationalLegalFormOfBusiness;
    }

    return true;
  });

  if (!businessType) {
    throw new Error('Not found business type.');
  }

  return businessType;
}

function getFileType(
  item: IFormField<EFieldName, EFieldBlock>,
): EAccountActivateFileType {
  const fileTypeMap = {
    [EFieldBlock.BUSINESS_REPRESENTATIVE]: {
      [EFieldName.DOCUMENT_PASSPORT]: EAccountActivateFileType.DOCUMENT_PASSPORT,
      [EFieldName.DOCUMENT_SNILS]: EAccountActivateFileType.DOCUMENT_SNILS,
      [EFieldName.DOCUMENT_INDIVIDUAL_ENTREPRENEUR]:
        EAccountActivateFileType.DOCUMENT_INDIVIDUAL_ENTREPRENEUR,
      [EFieldName.DOCUMENT_COMPANY]: EAccountActivateFileType.DOCUMENT_COMPANY,
    },
  };

  return fileTypeMap[item.fieldBlock]?.[item.fieldName] ?? EAccountActivateFileType.NONE;
}

export {
  getTypeOfBusinessList,
  getIndividualList,
  getTaxationSystemList,
  getBusinessRepresentativeList,
  getTaxCalculationList,
  getBusinessType,
  readSummaryFieldTitle,
  getFileType,
};
