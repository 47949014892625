<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      d="M7.0001 11.0002H14.5901L11.2901 7.71019C11.1018 7.52188 10.996 7.26649 10.996 7.00019C10.996 6.73388 11.1018 6.47849 11.2901 6.29019C11.4784 6.10188 11.7338 5.99609 12.0001 5.99609C12.2664 5.99609 12.5218 6.10188 12.7101 6.29019L17.7101 11.2902C17.8011 11.3853 17.8725 11.4974 17.9201 11.6202C18.0201 11.8636 18.0201 12.1367 17.9201 12.3802C17.8725 12.5029 17.8011 12.6151 17.7101 12.7102L12.7101 17.7102C12.6171 17.8039 12.5065 17.8783 12.3847 17.9291C12.2628 17.9798 12.1321 18.006 12.0001 18.006C11.8681 18.006 11.7374 17.9798 11.6155 17.9291C11.4937 17.8783 11.3831 17.8039 11.2901 17.7102C11.1964 17.6172 11.122 17.5066 11.0712 17.3848C11.0204 17.2629 10.9943 17.1322 10.9943 17.0002C10.9943 16.8682 11.0204 16.7375 11.0712 16.6156C11.122 16.4937 11.1964 16.3831 11.2901 16.2902L14.5901 13.0002H7.0001C6.73489 13.0002 6.48053 12.8948 6.293 12.7073C6.10546 12.5198 6.0001 12.2654 6.0001 12.0002C6.0001 11.735 6.10546 11.4806 6.293 11.2931C6.48053 11.1055 6.73489 11.0002 7.0001 11.0002Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSTailArrowRightIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>
