
import Vue from 'vue';

import TWidget from '@/module/dashboard/models/IWidgetConfig';
import EWidgetType from '@/module/dashboard/models/EWidgetType';

import DashboardWidgetFilter from '@/module/dashboard/components/WidgetList/DashboardWidgetFilter.vue';
import DashboardWidgetChart from '@/module/dashboard/components/WidgetList/DashboardWidgetChart.vue';
import DashboardWidgetTable from '@/module/dashboard/components/WidgetList/DashboardWidgetTable.vue';

export default Vue.extend({
  name: 'DashboardWidgetList',
  components: {
    DashboardWidgetTable,
    DashboardWidgetChart,
    DashboardWidgetFilter,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    widgets: {
      type: Array,
      default: () => [],
    },
    dimension: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  setup() {
    function isChart(widget: TWidget): boolean {
      return widget.config?.type === EWidgetType.CHART;
    }

    function isTable(widget: TWidget): boolean {
      return widget.config?.type === EWidgetType.TABLE;
    }

    return {
      isChart,
      isTable,
    };
  },
});
