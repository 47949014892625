<template>
  <COverviewPageLayout
    :is-loading="isLoading"
    :error-status="404"
    :show-error="isError"
  >
    <template
      #header
    >
      <header
        class="webhook-card__header"
      >
        <div
          class="webhook-card__header-side webhook-card__header-side--left"
        >
          <DSButton
            :text="$t(button.back)"
            variant="tretiary"
            @click="$router.back()"
          >
            <template
              #icon-before
            >
              <DSIcon
                icon="tailArrowLeft"
                color="linked"
              />
            </template>
          </DSButton>
        </div>
        <div class="webhook-card__header-side webhook-card__header-side--right">
          <DSButton
            :text="$t(button.edit)"
            variant="tretiary"
            @click="handleRedirectToEdit"
          >
            <template
              #icon-before
            >
              <DSIcon
                icon="edit"
                color="linked"
              />
            </template>
          </DSButton>
          <ButtonMore
            class="webhook-card__button-more"
            :buttons="buttonMoreOptions"
          />
        </div>
      </header>
    </template>

    <template
      #content
    >
      <section
        class="webhook-card__section"
      >
        <DSHeader
          class="webhook-card__title"
          size="2"
          :text="webhookData.url"
        />
      </section>

      <section
        class="webhook-card__section"
      >
        <DSHeader
          class="webhook-card__table-title"
          size="3"
          :text="$t(detailsText)"
        />

        <table
          class="webhook-card__table"
        >
          <tr>
            <th>{{ $t(detailsTable.url) }}</th>
            <td>
              <div class="cell-wrapper">
                {{ webhookData.url }}
                <DSTooltip
                  class="webhook-card__tooltip"
                  :text="$t('webhooks.form.endpoint_field.tooltip')"
                >
                  <DSIcon
                    icon="info"
                    color="disabled"
                    size="s"
                  />
                </DSTooltip>
              </div>
            </td>
          </tr>

          <tr>
            <th>{{ $t(detailsTable.event) }}</th>
            <td>
              <div class="cell-wrapper">
                {{ $t(webhookEventName) }}
                <DSTooltip
                  class="webhook-card__tooltip"
                  :text="$t('webhooks.form.events_field.tooltip')"
                >
                  <DSIcon
                    icon="info"
                    color="disabled"
                    size="s"
                  />
                </DSTooltip>
              </div>
            </td>
          </tr>

          <tr>
            <th>{{ $t(detailsTable.secret) }}</th>
            <td>
              <div class="cell-wrapper">
                <p
                  class="webhook-card__secret-wrapper"
                  @click="isSecreShown = !isSecreShown"
                >
                  {{ isSecreShown ? webhookData.secret : secretPlaceHolder }}
                  <span class="webhook-card__secret-icon">
                    <DSIcon
                      v-if="!isSecreShown"
                      icon="eye"
                      color="linked"
                      size="xs"
                    />
                    <DSIcon
                      v-else
                      icon="blindEye"
                      color="linked"
                      size="xs"
                    />
                  </span>
                </p>
                <DSTooltip
                  class="webhook-card__tooltip"
                  :text="$t('webhooks.form.secret_key_field.tooltip')"
                >
                  <DSIcon
                    icon="info"
                    color="disabled"
                    size="s"
                  />
                </DSTooltip>
              </div>
            </td>
          </tr>

          <tr>
            <th>{{ $t(detailsTable.status) }}</th>
            <td>{{ $t(webhookStatus) }}</td>
          </tr>

          <tr>
            <th>{{ $t(detailsTable.sum) }}</th>
            <td>
              <div class="cell-wrapper">
                {{ webhookData.sum }}
                <DSTooltip
                  class="webhook-card__tooltip"
                  :text="$t('webhooks.card.details_table.sum.tooltip')"
                >
                  <DSIcon
                    icon="info"
                    color="disabled"
                    size="s"
                  />
                </DSTooltip>
              </div>
            </td>
          </tr>

          <tr>
            <th>{{ $t(detailsTable.errorRate) }}</th>
            <td>
              <div class="cell-wrapper">
                {{ webhookErrorRate }}
                <DSTooltip
                  class="webhook-card__tooltip"
                  :text="$t('webhooks.card.details_table.error_rate.tooltip')"
                >
                  <DSIcon
                    icon="info"
                    color="disabled"
                    size="s"
                  />
                </DSTooltip>
              </div>
            </td>
          </tr>
        </table>
      </section>

      <DSButton
        :text="$t(button.aboutLogs)"
        class="webhook-card__button-about"
        variant="secondary"
        @click="handleClickToDoc"
      >
        <template
          #icon-before
        >
          <DSIcon
            icon="arrowInsideCircle"
            color="linked"
          />
        </template>
      </DSButton>
    </template>
  </COverviewPageLayout>
</template>

<script>
import {
  inject,
} from 'vue';

import {
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';

import ButtonMore from '@/components/ButtonMore/ButtonMore.vue';
import COverviewPageLayout from '@/module/common/components/PageParts/COverviewPageLayout.vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSHeader from '@/module/design-system/components/Text/DSHeader.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSTooltip from '@/module/design-system/components/InfoBlock/DSTooltip.vue';

export default {
  name: 'WebhookCardPage',
  components: {
    ButtonMore,
    COverviewPageLayout,
    DSButton,
    DSHeader,
    DSIcon,
    DSTooltip,
  },
  data() {
    return {
      webhookData: null,
      isLoading: true,
      isError: false,
      errorStatus: null,
      isSecreShown: false,
      button: {
        back: 'webhooks.card.back',
        edit: 'webhooks.card.edit',
        activate: 'webhooks.card.activate',
        deactivate: 'webhooks.card.deactivate',
        delete: 'webhooks.card.delete',
        aboutLogs: 'webhooks.card.about_logs',
      },
      detailsText: 'webhooks.card.details',
      detailsTable: {
        url: 'webhooks.card.details_table.url',
        event: 'webhooks.card.details_table.events',
        status: 'webhooks.card.details_table.status',
        secret: 'webhooks.card.details_table.secret',
        sum: 'webhooks.card.details_table.sum.title',
        errorRate: 'webhooks.card.details_table.error_rate.title',
        statusActive: 'webhooks.card.details_table.status_active',
        statusDisabled: 'webhooks.card.details_table.status_disabled',
      },
      secretPlaceHolder: '********',
      popup: {
        title: {
          updated: 'webhooks.card.popup.title.updated',
          error: 'webhooks.card.popup.title.error',
          deleted: 'webhooks.card.popup.title.deleted',
        },
        description: {
          activated: 'webhooks.card.popup.description.activated',
          deactivated: 'webhooks.card.popup.description.deactivated',
          error: 'webhooks.card.popup.description.error',
          deleted: 'webhooks.card.popup.description.deleted',
        },
      },
    };
  },
  setup() {
    const serviceContainer = inject('serviceContainer');

    const docsSiteUrl = serviceContainer.portalConfigService.get().url.docs;

    const utils = serviceContainer.resolve('utils');

    const store = serviceContainer.resolve('store');
    const locale = store.getters.getLocale;

    const helpLink = utils.link.localizeURL(
      `${docsSiteUrl}/en-en/merchant-portal/webhooks-statistics`,
      locale,
    );

    return {
      helpLink,
    };
  },
  computed: {
    ...mapGetters({
      locale: 'getLocale',
    }),
    webhookId() {
      return this.$route.params.id;
    },
    webhookEventName() {
      return `webhooks.events_list.${this.webhookData.event.replaceAll('.', '_')}`;
    },
    webhookStatus() {
      return this.webhookData.status ? this.detailsTable.statusActive : this.detailsTable.statusDisabled;
    },
    buttonMoreOptions() {
      return [
        {
          name: this.button.delete,
          handler: () => this.handleDeleteWebhookClick(this.webhookId),
        },
        {
          name: this.webhookData.status ? this.button.deactivate : this.button.activate,
          handler: () => this.handleActivateDeactivateClick(this.webhookId),
        },
      ];
    },
    webhookErrorRate() {
      return new Intl
        .NumberFormat(this.locale, {
          style: 'percent',
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
        .format(this.webhookData.errorRate / 100);
    },
  },
  mounted() {
    this.loadWebhook(this.webhookId);
  },
  methods: {
    ...mapMutations({
      setPopupTitle: 'popupStore/changeTitle',
      setPopupDescription: 'popupStore/changeDescription',
      togglePopup: 'popupStore/togglePopUp',
      togglePopupError: 'popupStore/toggleError',
      togglePopupControls: 'popupStore/toggleWithControls',
      setTryAgainHandler: 'popupStore/changeTryAgainHandler',
      changeDeleteModalFieldValue: 'deleteModalStore/changeFieldValue',
    }),
    ...mapActions({
      handleDeleteEndpointClick: 'deleteModalStore/handleDeleteEndpointClick',
      loadUpdatedEndpoints: 'webhooksStore/getEndpoints',
    }),

    handleClickToDoc() {
      window.open(this.helpLink, '_blank');
    },

    handleRedirectToEdit() {
      const pathToEdit = `/webhooks/edit/${this.webhookId}`;
      this.$router.push(pathToEdit).catch(() => null);
    },
    async loadWebhook(webhookId) {
      try {
        const response = await this.$api.webhooks.getEndpoint(webhookId);
        this.webhookData = response.data;
      } catch (error) {
        this.isError = true;
        this.errorStatus = error.response.status;
      } finally {
        this.isLoading = false;
      }
    },
    async handleActivateDeactivateClick(webhookId) {
      this.isLoading = true;
      this.isSecreShown = false;

      try {
        await this.$api.webhooks.updateEndpoint(webhookId, {
          event: this.webhookData.event,
          url: this.webhookData.url,
          secretKey: this.webhookData.secret,
          status: !this.webhookData.status,
        });

        this.webhookData.status = !this.webhookData.status;

        this.popupController({
          title: this.popup.title.updated,
          description: this.popup.description[this.webhookData.status ? 'activated' : 'deactivated'],
        });
        this.loadUpdatedEndpoints(true);
      } catch (error) {
        this.popupController({
          title: this.popup.title.error,
          description: this.popup.description.error,
          isError: true,
          withControls: true,
          handler: () => this.handleActivateDeactivateClick(webhookId),
        });
      } finally {
        this.isLoading = false;
      }
    },
    async handleDeleteWebhookClick(webhookId) {
      await this.deleteEndpoint(webhookId);
    },
    async deleteEndpoint(endpointId) {
      this.changeDeleteModalFieldValue({
        fieldName: 'id',
        newValue: endpointId,
      });
      this.changeDeleteModalFieldValue({
        fieldName: 'isOpened',
        newValue: true,
      });
      await this.changeDeleteModalFieldValue({
        fieldName: 'activeModalData',
        newValue: {
          dataToDelete: this.webhookData,
          title: this.$t('modal_window.delete_endpoint'),
          buttonKeep: this.$t('modal_window.no'),
          buttonDelete: this.$t('modal_window.yes'),
          handleDeleteClick: () => this.handleDeleteEndpointClick([
            endpointId,
            this.$t('popup_messages.deleted_endpoint'),
            this.webhookData.url,
            this.$t('popup_messages.error'),
            this.$t('popup_messages.went_wrong'),
          ]),
        },
      });
    },
    popupController({
      title,
      description,
      isError,
      withControls,
      handler,
    }) {
      this.setPopupTitle(title);
      this.setPopupDescription(description);
      if (isError) {
        this.togglePopupError();
      }

      if (withControls) {
        this.togglePopupControls();
        this.setTryAgainHandler(handler);
      }

      this.togglePopup();
    },
  },
};
</script>

<style
  lang="scss"
>
.cell-wrapper {
  display: flex;
  align-items: center;
  word-break: break-word;
}

.webhook-card {
  display: flex;
  flex-direction: column;

  &__preloader {
    align-self: center;
    margin: auto 0;
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__title {
    overflow-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__header-side {
    display: flex;
    align-items: center;

    &--left {
      justify-content: left;
    }

    &--right {
      justify-content: right;
    }
  }

  &__section {
    border-bottom: 1px solid #e5e5e5;
    padding: 0 0 32px;
  }

  &__table {
    font-family: $text-ibm-plex-sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    table-layout: fixed;
    width: 100%;

    th {
      text-align: left;
      vertical-align: text-top;
      font-weight: 400;
      color: #888888;
      width: 72px;
    }

    td {
      padding-left: 30px;
      vertical-align: text-top;
      max-width: 100%;

      p {
        display: inline-block;
        max-width: 100%;
        overflow-wrap: break-word;
      }
    }

    th, td {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    tr:first-child td {
      overflow-wrap: break-word;
    }
  }

  &__tooltip {
    margin-left: 5px;
  }

  &__table-title {
    margin-bottom: 10px;
    max-width: 100%;
  }

  &__button-more {
    margin-left: 12px;

    .button-more {
      border-color: transparent;
    }
  }

  &__button-about {
    width: max-content;
  }

  &__secret-wrapper {
    max-width: fit-content;
    cursor: pointer;
  }

  &__secret-icon {
    width: fit-content;
    display: inline-flex;
    height: 22px;
    align-items: center;
    margin-left: 8px;

    svg {
      fill: #4397CB;
    }
  }
}

@media screen and (max-width: 840px) {
  .webhook-card {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>
