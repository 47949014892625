import ucFirst from '@/module/common/utils/string/ucFirst';

export default function snakeToCamelCase(value: string): string {
  const parts = value.split('_');
  return parts
    .map((part, index) => {
      if (index !== 0) {
        return ucFirst(part);
      }

      return part;
    })
    .join('');
}
