enum EIconColorName {
  DISABLED = 'disabled',
  ERROR = 'error',
  LINKED = 'linked',
  MENU = 'menu',
  MENU_ACTIVE = 'menu-active',
  NAVIGATION = 'navigation',
  PRIMARY = 'primary',
  PRIMARY_BUTTON = 'primary-button',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  SURFACE_ICONS = 'surface-icons',
  WARNING = 'warning',
  ACCENT = 'accent',
}

export default EIconColorName;
