<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      d="M10.7146 10.786C10.7146 11.2975 10.5114 11.788 10.1497 12.1497C9.78802 12.5114 9.29748 12.7146 8.78599 12.7146C8.2745 12.7146 7.78396 12.5114 7.42229 12.1497C7.06061 11.788 6.85742 11.2975 6.85742 10.786C6.85742 10.2745 7.06061 9.78396 7.42229 9.42229C7.78396 9.06061 8.2745 8.85742 8.78599 8.85742C9.29748 8.85742 9.78802 9.06061 10.1497 9.42229C10.5114 9.78396 10.7146 10.2745 10.7146 10.786Z"
    />
    <path
      :fill="color"
      d="M6.99035 16.0097L3.5 19.5H21L20.5 17L16.8325 12.1099C16.4229 11.5639 15.5988 11.5795 15.2103 12.1407L11.9937 16.7869C11.7104 17.1961 11.1694 17.3347 10.7243 17.1121L8.14467 15.8223C7.75968 15.6298 7.29471 15.7053 6.99035 16.0097Z"
    />
    <path
      :fill="color"
      d="M20.0909 16.93V19.2818H3.80909V6.61818H20.0909V16.93L21.9 15.1209V4.80909C21.9 3.81409 21.0859 3 20.0909 3H3.80909C2.81409 3 2 3.81409 2 4.80909V19.2818C2 20.2768 2.81409 21.0909 3.80909 21.0909H20.0909C21.0859 21.0909 21.9 20.2768 21.9 19.2818V15.1209L20.0909 16.93Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSBrandingIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>

<style scoped>

</style>
