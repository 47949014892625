
import {
  defineComponent,
  PropType,
} from 'vue';

import IDSLinkConfig from '@/module/common/models/Text/IDSLinkConfig';
import IDSTextElementConfig from '@/module/common/models/Text/IDSTextElementConfig';

import DSLink from '@/module/design-system/components/Text/DSLink.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default defineComponent({
  name: 'CLinkTextBlock',
  components: {
    DSTextElement,
    DSLink,
  },
  props: {
    link: {
      type: Object as PropType<IDSLinkConfig>,
      required: true,
    },
    text: {
      type: Object as PropType<IDSTextElementConfig>,
      required: true,
    },
  },
});
