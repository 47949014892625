
import Vue, {
  computed,
  PropType,
} from 'vue';

import EColorScheme from '@/module/design-system/components/models/EColorScheme';
import EModalVariant from '@/module/design-system/models/UI/DSModal/EModalVariant';

import DSHeader from '@/module/design-system/components/Text/DSHeader.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default Vue.extend({
  name: 'DSModal',
  components: {
    DSHeader,
    DSIcon,
    DSTextElement,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    textColor: {
      type: String as PropType<EColorScheme>,
      default: EColorScheme.PRIMARY,
    },
    variant: {
      type: String as PropType<EModalVariant>,
      default: EModalVariant.TIGHT,
    },
  },
  setup(props, { emit, slots }) {
    const isContentSlot = computed(() => !!slots['modal-content']);

    const isFooterSlot = computed(() => !!slots['modal-footer']);

    const modalWindowClasses = computed(() => [
      'ds-modal__window',
      `ds-modal__window_variant_${props.variant}`,
    ]);

    function handleCloseModal() {
      emit('close-modal');
    }

    return {
      isContentSlot,
      isFooterSlot,
      modalWindowClasses,
      handleCloseModal,
    };
  },
});
