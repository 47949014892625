<template>
  <div
    class="c-copy-field"
    @click="copyClick"
  >
    <CLabel
      v-if="label"
      :text="label"
      :tooltip="tooltip"
      class="c-copy-field__label"
    />

    <div
      :class="classes"
    >
      <slot
        name="copy-field-text"
        :text="text"
      />
      <DSTextElement
        v-if="!hasSlot"
        variant="medium"
        :color="textColor"
      >
        {{ text }}
      </DSTextElement>

      <DSIcon
        v-if="showIcon"
        :size="iconSize"
        class="c-copy-field__icon"
        color="linked"
        icon="copy"
      />
    </div>
  </div>
</template>

<script>
import CLabel from '@/module/common/components/UI/CLabel.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default {
  name: 'CCopyField',
  components: {
    DSTextElement,
    CLabel,
    DSIcon,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'default',
      validator: (value) => [
        'large',
        'default',
        'small',
      ].includes(value),
    },
    textColor: {
      type: String,
      default: 'primary',
      validator: (value) => [
        'primary',
        'secondary',
        'disabled',
        'linked',
        'navigation',
        'primary-button',
        'menu',
        'error',
        'success',
      ].includes(value),
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (value) => [
        'primary',
        'error',
        'linked',
        'disabled',
      ].includes(value),
    },
    borderStyle: {
      type: String,
      default: 'solid',
      validator: (value) => [
        'none',
        'dotted',
        'dashed',
        'solid',
        'double',
        'groove',
        'ridge',
        'inset',
        'outset',
      ].includes(value),
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    iconSize() {
      return this.size === 'large' ? 'l' : 'm';
    },
    classes() {
      return [
        'c-copy-field__field',
        `c-copy-field__field_size_${this.size}`,
        `c-copy-field__field_color_${this.textColor}`,
        `c-copy-field__field_variant_${this.variant}`,
        `c-copy-field__field_border-style_${this.borderStyle}`,
        this.showIcon ? 'c-copy-field__field_with_icon' : null,
      ];
    },
    hasSlot() {
      return !!this.$scopedSlots['copy-field-text'];
    },
  },
  methods: {
    copyClick() {
      navigator.clipboard.writeText(this.text);
      this.$emit('copied');
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import "@/module/design-system/components/variables.scss";

.c-copy-field {
  &__label {
    margin-bottom: 8px;
  }

  &__field {
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    border-radius: 12px;

    &_size {
      &_large {
        min-height: 70px;
        padding: 16px;
        border-width: 3px;
      }

      &_default {
        padding: 16px;
        border-width: 2px;
      }

      &_small {
        padding: 7px 16px;
        border-width: 2px;
        border-radius: 8px;
      }
    }

    &_color {
      &_primary {
        color: $text-color-primary;
      }

      &_secondary {
        color: $text-color-secondary;
      }

      &_disabled {
        color: $text-color-disabled;
      }

      &_linked {
        color: $text-color-linked;
      }

      &_navigation {
        color: $text-color-navigation;
      }

      &_primary-button {
        color: $text-color-primary-button;
      }

      &_menu {
        color: $text-color-menu;
      }

      &_error {
        color: $text-color-error;
      }

      &_success {
        color: $text-color-success;
      }
    }

    &_variant {
      &_disabled {
        border-color: $copy-field-border-color-disabled;
        background-color: $copy-field-background-color-disabled;
      }

      &_error {
        border-color: $text-color-error;
        background-color: $copy-field-background-color-disabled;
      }

      &_primary {
        border-color: $copy-field-border-color-primary;
        background-color: $copy-field-background-color-primary;
      }

      &_linked {
        border-color: $copy-field-border-color-linked;
        background-color: $copy-field-background-color-linked;
      }
    }

    &_border-style {
      &_none {
        border-style: none;
      }

      &_dotted {
        border-style: dotted;
      }

      &_dashed {
        border-style: dashed;
      }

      &_solid {
        border-style: solid;
      }

      &_double {
        border-style: double;
      }

      &_groove {
        border-style: groove;
      }

      &_ridge {
        border-style: ridge;
      }

      &_inset {
        border-style: inset;
      }

      &_outset {
        border-style: outset;
      }
    }

    &_with_icon {
      white-space: nowrap;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        content: '';
        background: linear-gradient(270deg, #f5fbff 56.29%, rgba(245, 251, 255, 0) 111.7%);
      }

      &.c-copy-field__field_size_large {
        &::after {
          width: 94px;
          height: 64px;
          border-radius: 12px;
        }
      }

      &.c-copy-field__field_size_default {
        &::after {
          width: 94px;
          height: 64px;
          border-radius: 12px;
        }
      }

      &.c-copy-field__field_size_small {
        &::after {
          width: 70px;
          height: 35px;
          border-radius: 8px;
        }
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 13px;
    z-index: 1;
    cursor: pointer;
    transform: translateY(-50%);
  }
}
</style>
