import {
  reactive,
} from 'vue';
import {
  Store,
} from 'vuex';

import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import EPaymentMethod from '@/module/product/view/PaymentLink/model/enum/EPaymentMethod';
import EFieldName from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/enum/EFieldName';

import IStateHandler from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/contract/IStateHandler';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IState from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/interface/IState';
import ICurrency from '@/module/merchant/models/ICurrency';
import IPaymentMethod from '@/module/product/view/PaymentLink/model/interface/IPaymentMethod';

class StateHandler implements IStateHandler {
  private readonly serviceContainer: IServiceContainer;

  private state?: IState;

  constructor(
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
  }

  private getInitState = (): IState => {
    const localisationRegistry = this.serviceContainer.localisationRegistry;

    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);

    const currencyList: ICurrency[] = store.getters['authStore/getPriceCurrency']
      .map((currency, index) => {
        const title = `${currency.toUpperCase()} - ${localisationRegistry.currency.title[currency.toUpperCase()]}`;
        return {
          id: index,
          title,
          value: currency,
        };
      });

    const paymentMethodList = [
      {
        title: localisationRegistry.paymentLinkForm.form.paymentMethod.options.card,
        value: EPaymentMethod.CREDIT_CARD,
      },
      {
        title: localisationRegistry.paymentLinkForm.form.paymentMethod.options.sbp,
        value: EPaymentMethod.SBP,
      },
    ];

    return {
      isLoading: false,
      isFormValid: true,
      currencyList,
      paymentMethodList,
      formData: {
        [EFieldName.NAME]: '',
        [EFieldName.PAYMENT_DESCRIPTION]: '',
        [EFieldName.AMOUNT]: '',
        [EFieldName.CURRENCY]: currencyList[0],
        [EFieldName.PAYMENT_METHOD]: {} as IPaymentMethod,
        [EFieldName.RETURN_SUCCESS_URL]: '',
        [EFieldName.PAYMENT_ID]: '',
        [EFieldName.CUSTOMER_FIRST_NAME]: '',
        [EFieldName.CUSTOMER_LAST_NAME]: '',
        [EFieldName.CUSTOMER_EMAIL]: '',
        [EFieldName.RECURRING_INDICATOR]: false,
      },
      formConfig: [],
    };
  };

  get = (): IState => {
    if (!this.state) {
      this.state = reactive<IState>(this.getInitState());
    }

    return this.state;
  };

  clear = () => {
    this.state = this.getInitState();
  };
}

export default StateHandler;
