enum ERootLocalisationKey {
  ACCOUNT_ACTIVATE = 'pages.account_activate.form',
  ADMIN = 'pages.admin',
  ADMIN_LOGIN = 'pages.admin_login',
  API_KEYS = 'pages.developers.api_keys',
  BRANDING = 'pages.branding',
  CHECKOUT_LINK_FORM = 'pages.checkout_links.form',
  CHECKOUT_LINK_LIST = 'pages.checkout_links.list',
  COMMON = 'common',
  COOKIE_POLICY = 'pages.cookie_policy',
  COUNTRY = 'country',
  CURRENCY = 'currency',
  DASHBOARD = 'pages.dashboard',
  DATA_EXPORTS = 'pages.data_exports',
  EMAIL = 'email',
  FULFILLMENT_FORM = 'pages.fulfillment',
  HELP_WIDGET = 'help_widget',
  LOGIN = 'pages.login',
  MODAL_WINDOW = 'modal_window',
  PAYMENT_LINK_FORM = 'pages.payment_link_form',
  PAYMENT_LINK_LIST = 'pages.payment_link_list',
  PAYOUTS = 'pages.payouts',
  POPUP_MESSAGES= 'popup_messages',
  PRIVACY_NOTICE = 'pages.privacy_notice',
  PRODUCT_CARD = 'pages.products_card',
  PRODUCT_FORM = 'pages.products.products.form',
  PRODUCT_LIST = 'pages.products.products.list',
  PRODUCT_OLD = 'product',
  PROFILE = 'pages.profile',
  PROMOTION_CARD = 'pages.promotions_card',
  PROMOTION_FORM = 'pages.promotions_form',
  PROMOTION_LIST = 'pages.promotions.list',
  REPORTS = 'pages.reports',
  TOOLTIPS = 'tooltips',
  VALIDATION_MESSAGES = 'validation_messages',
  WEBHOOK_CARD = 'pages.webhooks_card',
  WEBHOOK_FORM = 'pages.webhooks_form',
  WEBHOOK_LIST = 'pages.developers.webhooks.list',
}

export default ERootLocalisationKey;
