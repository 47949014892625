export default () => ({
  namespaced: true,
  state: {
    isMenuMinimized: false,
    errors: [],
    loading: false,
    currentBreakpoint: 'xxl',
  },
  getters: {
    getIsMenuMinimized(state) {
      return state.isMenuMinimized;
    },
    getErrors(state) {
      return state.errors;
    },
    getLoading(state) {
      return state.loading;
    },
    getCurrentBreakpoint(state) {
      return state.currentBreakpoint;
    },
  },
  mutations: {
    setIsMenuMinimized(state, value) {
      state.isMenuMinimized = value;
    },
    setErrors(state, value) {
      state.errors = value;
    },
    setLoading(state, value) {
      state.loading = value;
    },
    setCurrentBreakpoint(state, value) {
      state.currentBreakpoint = value;
    },
  },
  actions: {
    updateMenuStatus({ commit }, isMinimized) {
      commit('setIsMenuMinimized', isMinimized);
    },
    pushError({ commit, getters }, error) {
      const errors = getters.getErrors;
      errors.push(error);

      commit('setErrors', errors);
    },
    clearError({ commit }) {
      commit('setErrors', []);
    },
    updateLoading({ commit }, loading) {
      commit('setLoading', loading);
    },
    updateCurrentBreakpoint({ commit }, breakpoint) {
      commit('setCurrentBreakpoint', breakpoint);
    },
  },
});
