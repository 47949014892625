import IFieldStatusHandler
  from '@/module/common/components/FormComponent/ValidatorHandler/contract/IFieldStatusHandler';
import IValidator from '@/module/common/utils/validator/contract/IValidator';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import IFieldStatus from '@/module/design-system/components/models/FormComponent/IFieldStatus';
import EValidatorType from '@/module/common/utils/validator/enum/EValidatorType';

class EmptyFieldStatusHandler implements IFieldStatusHandler {
  getStatus = (
    validator: IValidator,
  ): IFieldStatus => {
    const hasErrors = validator.hasErrors();

    const status = {
      code: EValidationFieldStatusCode.DEFAULT,
    } as IFieldStatus;

    if (hasErrors) {
      const isNotEmptyError = validator.hasErrorsByTypeList([
        EValidatorType.NOT_EMPTY,
        EValidatorType.CUSTOM_NOT_EMPTY,
      ]);
      status.code = isNotEmptyError ? EValidationFieldStatusCode.EMPTY : EValidationFieldStatusCode.ERROR;

      if (!isNotEmptyError) {
        status.text = validator.getMessages()[0];
      }
    }

    return status;
  };
}

export default EmptyFieldStatusHandler;
