import IFieldValidatorStatusHandler
  from '@/module/merchant/view/AccountActivatePage/Validator/IFieldValidatorStatusHandler';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import IFieldStatus from '@/module/design-system/components/models/FormComponent/IFieldStatus';
import EValidatorType from '@/module/common/utils/validator/enum/EValidatorType';
import IFormFieldItem from '@/module/design-system/components/models/FormComponent/IFormFieldItem';
import cloneImmutable from '@/module/common/utils/data/cloneImmutable';
import IValidator from '@/module/common/utils/validator/contract/IValidator';
import EFieldName from '@/module/merchant/view/AccountActivatePage/models/EFieldName';
import EFieldBlock from '@/module/merchant/view/AccountActivatePage/models/EFieldBlock';
import TFieldStatuses
  from '@/module/merchant/view/AccountActivatePage/models/TFieldStatuses';

class EmptyFieldValidatorStatusHandler implements IFieldValidatorStatusHandler {
  // eslint-disable-next-line class-methods-use-this
  changeStatus(
    fieldConfig: IFormFieldItem<EFieldName, EFieldBlock>,
    validator: IValidator,
    formFieldStatusList: TFieldStatuses,
  ): TFieldStatuses {
    const { fieldBlock, fieldName } = fieldConfig;
    const statusList = cloneImmutable(formFieldStatusList);
    const hasError = validator.hasErrors();
    const response = {
      code: EValidationFieldStatusCode.DEFAULT,
    } as IFieldStatus;

    if (hasError) {
      const isNotEmptyError = validator.hasErrorsByTypeList([
        EValidatorType.NOT_EMPTY,
        EValidatorType.CUSTOM_NOT_EMPTY,
      ]);
      response.code = isNotEmptyError ? EValidationFieldStatusCode.EMPTY : EValidationFieldStatusCode.ERROR;

      if (!isNotEmptyError) {
        response.text = validator.getMessages()[0];
      }
    }

    statusList[fieldBlock][fieldName] = response;

    return statusList;
  }
}

export default EmptyFieldValidatorStatusHandler;
