export default function style(vm) {
  return {
    getComputedStyle(node) {
      return window.getComputedStyle(node);
    },
    clearUnit(val) {
      const unitList = /(cm|mm|Q|in|pc|pt|px|em|ex|ch|rem|lh|rlh|vw|vh|vmin|vmax|vb|vi|svw|svh|lvw|lvh|dvw|dvh|%)/;

      return Number(vm.$utils.string.replace(val, unitList, ''));
    },
  };
}
