
import {
  defineComponent,
  inject,
  onMounted,
  PropType,
  watch,
} from 'vue';
import initState,
{
  getRollButtonText,
  updateState,
} from '@/module/developers/components/ApiKeysList.func';
import IApiKeysModel from '@/module/developers/models/IApiKeysModel';
import IVueInstance from '@/module/common/models/IVueInstance';
import getLocalisation from '@/module/common/helpers/getLocalisation';
import {
  ILocalisationApiKeys,
  rootLocalisationKey,
} from '@/module/developers/views/ApiKeys.func';
import getButton from '@/module/common/helpers/getButton';
import EDSButtonSize from '@/module/design-system/models/UI/DSButton/EDSButtonSize';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSHeader from '@/module/design-system/components/Text/DSHeader.vue';
import DSSubstrate from '@/module/design-system/components/DSSubstrate.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default defineComponent({
  name: 'ApiKeysList',
  components: {
    DSButton,
    DSTextElement,
    DSSubstrate,
    DSHeader,
  },
  props: {
    list: {
      type: Array as PropType<IApiKeysModel[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const vueInstance = inject<IVueInstance>('vueInstance');
    const localisation = getLocalisation<ILocalisationApiKeys>(rootLocalisationKey);
    const utils = vueInstance?.$utils ?? null;

    const state = initState();

    function handler<IApiKeysModel>(value: IApiKeysModel) {
      emit('click-roll', value);
    }
    const rollButton = getButton(
      {
        text: getRollButtonText(vueInstance?.$store.getters['authStore/getMerchantMode'], localisation),
        size: EDSButtonSize.SMALL,
      },
      handler,
    );

    watch(
      () => props.list,
      (newValue) => {
        if (newValue.length > 0) {
          updateState(utils, state, props.list[0], localisation);
        }
      },
    );

    onMounted(() => {
      if (props.list.length > 0) {
        updateState(utils, state, props.list[0], localisation);
      }
    });

    return {
      state,
      rollButton,
    };
  },
});
