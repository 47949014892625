import isObject from '@/module/common/utils/data/isObject';
import isArray from '@/module/common/utils/data/isArray';

export default function readProp(object: object, pathStr: string) {
  let result = object;

  if (pathStr.length > 0) {
    const parts = pathStr.split('.');

    parts.forEach((part) => {
      if (isObject(result) || isArray(result)) {
        result = result[part];
      }
    });
  }

  return result;
}
