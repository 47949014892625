import ITranslationApi from '@/i18n/api/contract/ITranslationApi';
import {
  AxiosInstance,
  AxiosResponse,
} from 'axios';
import ELocale from '@/module/common/models/ELocale';

class TranslationApi implements ITranslationApi {
  private readonly client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  async list(locale: ELocale): Promise<AxiosResponse<unknown>> {
    return this.client.get(`translator/${locale}`);
  }
}

export default TranslationApi;
