import IBasePageLocalisation from '@/module/common/utils/localisation/models/IBasePageLocalisation';
import getLocalisation from '@/module/common/helpers/getLocalisation';
import mapObjectSnakeKeysToCamelCaseRecursive
  from '@/module/common/utils/object/mapObjectSnakeKeysToCamelCaseRecursive';
import ESize from '@/module/design-system/components/models/ESize';
import ESortDirection from '@/module/common/models/ESortDirection';

const rootLocalisationKey = 'pages.checkout_links.list';

interface ILocalisationCheckoutLinkPage extends IBasePageLocalisation {
  links_count: string;
  how_it_works: string;
  buttons: {
    create_link: string;
  };
  filters: {
    search: {
      label: string;
      placeholder: string;
    };
    sort_by: {
      label: string;
      variants: {
        recently_edited_first: string;
        recently_edited_last: string;
      };
    };
  };
  list_header: {
    checkout_link: string;
    last_edit: string;
    products: string;
  };
  not_found: {
    title: string;
    subtitle: string;
  };
  status_window: {
    title: string;
    subtitle: string;
  };
  notification: {
    copy_link: string;
  };
}

interface ICamelCaseLocalisationCheckoutLinkPage {
  title: string;
  tooltip: string;
  links_count: string;
  howItWorks: string;
  buttons: {
    create: {
      test: string;
      sales: string;
    };
  };
  filters: {
    search: {
      label: string;
      placeholder: string;
    };
    sortBy: {
      label: string;
      variants: {
        recentlyEditedFirst: string;
        recentlyEditedLast: string;
      };
    };
  };
  listHeader: {
    checkoutLink: string;
    lastEdit: string;
    products: string;
  };
  notFound: {
    title: string;
    subtitle: string;
  };
  statusWindow: {
    title: string;
    subtitle: string;
  };
  notification: {
    copyLink: string;
  };
}

enum EGridTitleKey {
  CHECKOUT_LINK = 'checkout_link',
  LAST_EDIT = 'last_edit',
  PRODUCTS = 'products',
  CONTROL = 'control',
  NAME = 'name',
  COMPUTED_INFO = 'computed_info',
}

interface IGridTitle
{
  title?: string,
  key: EGridTitleKey,
}

enum EOrderKey
{
  RECENTLY_EDITED_FIRST = 'recently_edited_first',
  RECENTLY_EDITED_LAST = 'recently_edited_last',
}

interface IOrderBy
{
  title: string,
  id: EOrderKey,
  value: ESortDirection,
}

interface IStateCheckoutLinkPage {
  gridTitles: IGridTitle[],
  gridItemsDesktop: IGridTitle[],
  gridItemsMobile: IGridTitle[],
  mobileBreakpoints: ESize[],
  searchFilter: string,
  orderBy: IOrderBy,
  orderByList: IOrderBy[],
  searching: boolean,
  limit: number,
  offset: number,
  localisation: ICamelCaseLocalisationCheckoutLinkPage,
}

function initLocalisation(): ICamelCaseLocalisationCheckoutLinkPage {
  const localisation = getLocalisation<ILocalisationCheckoutLinkPage>(rootLocalisationKey);

  return mapObjectSnakeKeysToCamelCaseRecursive<
    ILocalisationCheckoutLinkPage,
    ICamelCaseLocalisationCheckoutLinkPage
    >(localisation) as ICamelCaseLocalisationCheckoutLinkPage;
}

export default function initState(localisation: ICamelCaseLocalisationCheckoutLinkPage): IStateCheckoutLinkPage {
  return {
    gridTitles: [
      {
        title: localisation.listHeader.checkoutLink.toUpperCase(),
        key: EGridTitleKey.CHECKOUT_LINK,
      },
      {
        title: localisation.listHeader.lastEdit.toUpperCase(),
        key: EGridTitleKey.LAST_EDIT,
      },
      {
        title: localisation.listHeader.products.toUpperCase(),
        key: EGridTitleKey.PRODUCTS,
      },
      {
        title: '',
        key: EGridTitleKey.CONTROL,
      },
    ],
    gridItemsDesktop: [
      {
        key: EGridTitleKey.NAME,
      },
      {
        key: EGridTitleKey.LAST_EDIT,
      },
      {
        key: EGridTitleKey.PRODUCTS,
      },
      {
        key: EGridTitleKey.CONTROL,
      },
      {
        key: EGridTitleKey.CHECKOUT_LINK,
      },
    ],
    gridItemsMobile: [
      {
        key: EGridTitleKey.NAME,
      },
      {
        key: EGridTitleKey.CONTROL,
      },
      {
        key: EGridTitleKey.COMPUTED_INFO,
      },
      {
        key: EGridTitleKey.CHECKOUT_LINK,
      },
    ],
    mobileBreakpoints: [
      ESize.XXS,
      ESize.XS,
      ESize.S,
      ESize.M,
      ESize.L,
    ],
    searchFilter: '',
    orderBy: {
      title: localisation.filters.sortBy.variants.recentlyEditedFirst,
      id: EOrderKey.RECENTLY_EDITED_FIRST,
      value: ESortDirection.DESC,
    },
    orderByList: [
      {
        title: localisation.filters.sortBy.variants.recentlyEditedFirst,
        id: EOrderKey.RECENTLY_EDITED_FIRST,
        value: ESortDirection.DESC,
      },
      {
        title: localisation.filters.sortBy.variants.recentlyEditedLast,
        id: EOrderKey.RECENTLY_EDITED_LAST,
        value: ESortDirection.ASC,
      },
    ],
    limit: 10,
    offset: 0,
    localisation,
    searching: false,
  };
}

export {
  initLocalisation,
};
