import THandleFieldBlurParams from '@/module/product/view/FulfillmentFormPage/model/types/THandleFieldBlurParams';
import IState from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/IState';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import ILocalServiceContainer from '@/module/product/view/FulfillmentFormPage/model/ILocalServiceContainer';
import IHandlerService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IHandlerService';
import ELocalServiceFulfillment from '@/module/product/view/FulfillmentFormPage/model/enums/ELocalServiceFulfillment';
import AbstractBlurFieldScenario
  from '@/module/product/view/FulfillmentFormPage/scenario/formFieldBlur/AbstractBlurFieldScenario';

class DefaultFormFieldBlurScenario extends AbstractBlurFieldScenario {
  private readonly serviceContainer: IServiceContainer;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    localServiceContainer: ILocalServiceContainer,
  ) {
    super(state, localServiceContainer);

    this.serviceContainer = serviceContainer;
  }

  run = (item: THandleFieldBlurParams): void => {
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const licenseSourceHandler = handlerService.getLicenseSourceHandler();
    const formDataHandler = handlerService.getFormDataHandler();

    const licenseSourceName = licenseSourceHandler.getLicenseSourceName(item.formData);

    formDataHandler.store(licenseSourceName, item.formData);
  };
}

export default DefaultFormFieldBlurScenario;
