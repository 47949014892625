import IFormDataMapper from '@/module/merchant/view/AccountActivatePage/DataMapper/IFormDataMapper';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import ICInfoBlock from '@/module/common/models/ICInfoBlock/ICInfoBlock';
import ICInfoBlockItem from '@/module/common/models/ICInfoBlock/ICInfoBlockItem';
import checkCondition from '@/module/common/helpers/checkCondition';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import TTSApi from '@/module/common/tsApi/TTSApi';
import IRESTApi from '@/module/common/service/RESTApi/contract/IRESTApi';
import EFormFieldType from '@/module/design-system/components/models/FormComponent/EFormFieldType';
import IValidatorHandler from '@/module/merchant/view/AccountActivatePage/Validator/IValidatorHandler';
import EPageTab from '@/module/merchant/view/AccountActivatePage/models/EPageTab';
import IFormDataHandler from '@/module/merchant/view/AccountActivatePage/Handler/contract/IFormDataHandler';
import IPageState from '@/module/merchant/view/AccountActivatePage/models/IPageState';
import IFileUploadHandler from '@/module/merchant/view/AccountActivatePage/Handler/contract/IFileUploadHandler';
import IFormDraftHandler from '@/module/merchant/view/AccountActivatePage/Handler/contract/IFormDraftHandler';
import IFormConfigHandler from '@/module/merchant/view/AccountActivatePage/Handler/contract/IFormConfigHandler';
import EBusinessRepresentativeCompany
  from '@/module/merchant/view/AccountActivatePage/models/EBusinessRepresentativeCompany';
import EFieldBlock from '@/module/merchant/view/AccountActivatePage/models/EFieldBlock';
import EFieldName from '@/module/merchant/view/AccountActivatePage/models/EFieldName';
import IFormConfig from '@/module/merchant/view/AccountActivatePage/models/IFormConfig';
import IBusinessRepresentativeVariant
  from '@/module/merchant/view/AccountActivatePage/models/IBusinessRepresentativeVariant';
import IKontragentioIndividual from '@/module/merchant/models/Kontragentio/IKontragentioIndividual';
import IKontragentioCompany from '@/module/merchant/models/Kontragentio/IKontragentioCompany';
import IKontragentioFields from '@/module/merchant/models/Kontragentio/IKontragentioFields';
import ISummaryDataBlock from '@/module/merchant/view/AccountActivatePage/models/ISummaryDataBlock';
import EBusinessType from '@/module/merchant/models/BuisnessType/EBusinessType';
import IKontragentioService from '@/module/merchant/view/AccountActivatePage/service/contract/IKontragentioService';
import TFormData from '@/module/merchant/view/AccountActivatePage/models/FormData/TFormData';
import THandleFieldBlurParams from '@/module/merchant/view/AccountActivatePage/models/THandleFieldBlurParams';
import ITab from '@/module/merchant/view/AccountActivatePage/models/ITab';
import IFilteredSelectHandler from '@/module/merchant/view/AccountActivatePage/Handler/contract/IFilteredSelectHandler';
import EBusinessRepresentativeIndividualEntrepreneur
  from '@/module/merchant/view/AccountActivatePage/models/EBusinessRepresentativeIndividualEntrepreneur';
import ETypeOfBusiness from '@/module/merchant/models/BuisnessType/ETypeOfBusiness';

class FormDataHandler implements IFormDataHandler {
  private readonly state: IPageState;

  private readonly localisationRegistry: ILocalisationRegistry;

  private readonly serviceContainer: IServiceContainer;

  private readonly emptyFieldsValidatorHandler: IValidatorHandler;

  private readonly fileUploadHandler: IFileUploadHandler;

  private readonly draftHandler: IFormDraftHandler;

  private readonly formConfigHandler: IFormConfigHandler;

  private readonly fromDraftMapper: IFormDataMapper;

  private readonly toSummaryMapper: IFormDataMapper;

  private readonly toSaveMapper: IFormDataMapper;

  private readonly kontragentioClearMapper: IFormDataMapper;

  private readonly kontragentioFillMapper: IFormDataMapper;

  private readonly filteredSelectHandler: IFilteredSelectHandler;

  private readonly kontragentioService: IKontragentioService;

  constructor(
    state: IPageState,
    localisationRegistry: ILocalisationRegistry,
    serviceContainer: IServiceContainer,
    emptyFieldsValidatorHandler: IValidatorHandler,
    fileUploadHandler: IFileUploadHandler,
    draftHandler: IFormDraftHandler,
    formConfigHandler: IFormConfigHandler,
    fromDraftMapper: IFormDataMapper,
    toSummaryMapper: IFormDataMapper,
    toSaveMapper: IFormDataMapper,
    kontragentioClearMapper: IFormDataMapper,
    kontragentioFillMapper: IFormDataMapper,
    filteredSelectHandler: IFilteredSelectHandler,
    kontragentioService: IKontragentioService,
  ) {
    this.state = state;
    this.localisationRegistry = localisationRegistry;
    this.serviceContainer = serviceContainer;
    this.emptyFieldsValidatorHandler = emptyFieldsValidatorHandler;
    this.fileUploadHandler = fileUploadHandler;
    this.draftHandler = draftHandler;
    this.formConfigHandler = formConfigHandler;
    this.fromDraftMapper = fromDraftMapper;
    this.toSummaryMapper = toSummaryMapper;
    this.toSaveMapper = toSaveMapper;
    this.kontragentioClearMapper = kontragentioClearMapper;
    this.kontragentioFillMapper = kontragentioFillMapper;
    this.filteredSelectHandler = filteredSelectHandler;
    this.kontragentioService = kontragentioService;
  }

  private getFullName = (
    formData: TFormData,
  ): string => {
    const lastName = formData.businessRepresentative.lastName ?? '';
    const firstName = formData.businessRepresentative.firstName ?? '';
    const middleName = formData.businessRepresentative.middleName ?? '';

    return `${lastName} ${firstName} ${middleName}`;
  };

  initFromData = (
    formData?: TFormData,
  ): TFormData => {
    const tabListConfig = this.formConfigHandler.getTabListConfig();

    const currentFormData = tabListConfig
      .reduce((data, formConfigItem) => formConfigItem.formConfig
        .reduce((formConfigData, config) => config.items
          .reduce((fieldData, field) => {
            const { fieldBlock, fieldName } = field;

            if (!fieldData[fieldBlock]) {
              fieldData[fieldBlock] = {};
            }

            fieldData[fieldBlock][fieldName] = formData?.[fieldBlock]?.[fieldName] ?? field.default;

            fieldData = this.fromDraftMapper
              .mapFieldValue(field, fieldData as TFormData);

            return fieldData;
          }, formConfigData), data), {}) as TFormData;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const representative = currentFormData?.businessRepresentative?.representative?.id;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const jobTitle = currentFormData?.businessRepresentative?.jobTitle;

    if (representative === EBusinessRepresentativeCompany.HEADER && !jobTitle) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      currentFormData.businessRepresentative.jobTitle = currentFormData.businessRepresentative.representative.title;
    }

    if (representative === EBusinessRepresentativeIndividualEntrepreneur.INDIVIDUAL_ENTREPRENEUR) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      currentFormData.businessRepresentative.jobTitle = this.getFullName(currentFormData);
    }

    return currentFormData;
  };

  handleFieldBlur = (item: THandleFieldBlurParams) => {
    if (item.field.type === EFormFieldType.FILTERED_SELECT) {
      this.filteredSelectHandler.dropFilter(item);
    }

    const representativeNameFieldNameList = [
      EFieldName.LAST_NAME,
      EFieldName.FIRST_NAME,
      EFieldName.MIDDLE_NAME,
    ];

    if (item.field.fieldBlock === EFieldBlock.BUSINESS_DETAILS
      && item.field.fieldName === EFieldName.TAX_IDENTIFICATION_CODE
    ) {
      this.kontragentioBlurScenario(item);
    } else if (item.field.fieldBlock === EFieldBlock.BUSINESS_REPRESENTATIVE
      && representativeNameFieldNameList.includes(item.field.fieldName)
    ) {
      this.representativeNameBlurScenario(item);
    } else if (item.field.fieldBlock === EFieldBlock.BUSINESS_REPRESENTATIVE
      && item.field.fieldName === EFieldName.REPRESENTATIVE
    ) {
      this.representativeBlurScenario(item);
    } else if (item.field.fieldBlock === EFieldBlock.BUSINESS_STRUCTURE
      && item.field.fieldName === EFieldName.BUSINESS_TYPE
    ) {
      this.businessTypeBlurScenario(item);
    } else if (item.field.type === EFormFieldType.FILE_UPLOAD) {
      this.fileUploadHandler.processUploadFiles(item);
    } else {
      this.defaultBlurScenario(item);
    }

    const tabListConfig = this.formConfigHandler.getTabListConfig();

    this.state.tabListConfig = tabListConfig;
    this.state.activeTabConfig = tabListConfig
      .find((tabConfig) => tabConfig.id === this.state.activeTab.id) as IFormConfig;
  };

  defaultBlurScenario = (item: THandleFieldBlurParams) => {
    const utilsService = this.serviceContainer.utilsService;

    this.state.formData = utilsService.data.cloneImmutable(item.formData);

    const localFormFieldStatusList = utilsService.data.cloneImmutable(this.state.formFieldStatusList);
    this.state.formFieldStatusList = this.emptyFieldsValidatorHandler
      .validateField(item.field, this.state.formData, localFormFieldStatusList);

    const tabListConfig = this.formConfigHandler.getTabListConfig();
    this.state.formDraft = this.draftHandler.updateDraft(tabListConfig, this.state.formDraft);
    this.draftHandler.saveDraft(this.state.formDraft);
  };

  businessTypeBlurScenario = (item: THandleFieldBlurParams) => {
    const utilsService = this.serviceContainer.utilsService;

    this.state.formData = utilsService.data.cloneImmutable(item.formData);

    const businessRepresentativeList = this.formConfigHandler.getBusinessRepresentativeList(
      this.localisationRegistry.accountActivate,
    );

    const representative = businessRepresentativeList[EBusinessRepresentativeCompany.HEADER]
      || businessRepresentativeList[EBusinessRepresentativeIndividualEntrepreneur.INDIVIDUAL_ENTREPRENEUR];
    // TODO: Разобраться с типами
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.state.formData.businessRepresentative.representative = representative;

    let jobTitle = representative.title;

    if (representative.id === EBusinessRepresentativeIndividualEntrepreneur.INDIVIDUAL_ENTREPRENEUR) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      jobTitle = this.getFullName(this.state.formData);
    }

    // TODO: Разобраться с типами
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.state.formData.businessRepresentative.jobTitle = jobTitle;

    const formOfBusinessList = this.formConfigHandler.getOrganizationalLegalFormOfBusinessList();

    // TODO: Разобраться с типами
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.state.formData.businessDetails.organizationalLegalFormOfBusiness = formOfBusinessList.length > 1
      ? {}
      : formOfBusinessList[0];

    const tabListConfig = this.formConfigHandler.getTabListConfig();
    this.state.formDraft = this.draftHandler.updateDraft(tabListConfig, this.state.formDraft);
    this.draftHandler.saveDraft(this.state.formDraft);
  };

  representativeNameBlurScenario = (item: THandleFieldBlurParams) => {
    const utilsService = this.serviceContainer.utilsService;

    const formData = utilsService.data.cloneImmutable(item.formData);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const representative = formData.businessRepresentative.representative.id;

    if (representative === EBusinessRepresentativeIndividualEntrepreneur.INDIVIDUAL_ENTREPRENEUR) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formData.businessRepresentative.jobTitle = this.getFullName(formData);
    }

    this.state.formData = utilsService.data.cloneImmutable(formData);

    const localFormFieldStatusList = utilsService.data.cloneImmutable(this.state.formFieldStatusList);
    this.state.formFieldStatusList = this.emptyFieldsValidatorHandler
      .validateField(item.field, this.state.formData, localFormFieldStatusList);

    const tabListConfig = this.formConfigHandler.getTabListConfig();
    this.state.formDraft = this.draftHandler.updateDraft(tabListConfig, this.state.formDraft);
    this.draftHandler.saveDraft(this.state.formDraft);
  };

  representativeBlurScenario = (item: THandleFieldBlurParams) => {
    const utilsService = this.serviceContainer.utilsService;

    const formData = utilsService.data.cloneImmutable(item.formData);

    const representative = (item.value as IBusinessRepresentativeVariant).id;
    const representativeWithCustomLogic = [
      EBusinessRepresentativeCompany.HEADER,
      EBusinessRepresentativeIndividualEntrepreneur.INDIVIDUAL_ENTREPRENEUR,
    ];

    if (representativeWithCustomLogic.includes(representative)) {
      if (representative === EBusinessRepresentativeCompany.HEADER) {
        // TODO: Разобраться с типами
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.businessRepresentative.jobTitle = item.value.title;
      }

      if (representative === EBusinessRepresentativeIndividualEntrepreneur.INDIVIDUAL_ENTREPRENEUR) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.businessRepresentative.jobTitle = this.getFullName(formData);
      }

      let field;
      const businessInfoTabConfig = this.formConfigHandler.getFormConfig(this.state.activeTab);
      businessInfoTabConfig.formConfig.forEach((block) => {
        const fieldItemSearch = block.items.find(
          (fieldItem) => fieldItem.fieldBlock === EFieldBlock.BUSINESS_REPRESENTATIVE
            && fieldItem.fieldName === EFieldName.JOB_TITLE,
        );

        if (fieldItemSearch) {
          field = fieldItemSearch;
        }
      });

      this.state.formData = utilsService.data.cloneImmutable(formData);

      if (field) {
        const localFormFieldStatusList = utilsService.data.cloneImmutable(this.state.formFieldStatusList);
        this.state.formFieldStatusList = this.emptyFieldsValidatorHandler
          .validateField(field, this.state.formData, localFormFieldStatusList);
      }
    } else {
      // TODO: Разобраться с типами
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formData.businessRepresentative.jobTitle = '';
      this.state.formData = utilsService.data.cloneImmutable(formData);
    }

    const tabListConfig = this.formConfigHandler.getTabListConfig();
    this.state.formDraft = this.draftHandler.updateDraft(tabListConfig, this.state.formDraft);
    this.draftHandler.saveDraft(this.state.formDraft);
  };

  kontragentioBlurScenario = (item: THandleFieldBlurParams) => {
    const utilsService = this.serviceContainer.utilsService;

    const localFormFieldStatusList = utilsService.data.cloneImmutable(this.state.formFieldStatusList);
    this.state.formFieldStatusList = this.emptyFieldsValidatorHandler
      .validateField(item.field, item.formData, localFormFieldStatusList);

    const fieldStatusCode = this.state.formFieldStatusList[item.field.fieldBlock][item.field.fieldName].code;
    if ([EValidationFieldStatusCode.ERROR, EValidationFieldStatusCode.EMPTY].includes(fieldStatusCode)) {
      this.defaultBlurScenario(item);
      return;
    }

    if (item.value === item.prevValue) {
      this.defaultBlurScenario(item);
      return;
    }
    this.state.formData = this.kontragentioClearMapper.mapFieldValue(item.field, item.formData);

    const tsApi = this.serviceContainer.resolve<TTSApi>(EServiceContainerInstances.TS_API);
    const kontragentioApi: IRESTApi<
      IKontragentioIndividual | IKontragentioCompany
    > = tsApi.companyInfo as IRESTApi<IKontragentioIndividual | IKontragentioCompany>;

    const businessType = this.state.formData.businessStructure.businessType.id;

    const filter: IKontragentioFields = {
      inn: item.value as string,
      type: businessType === EBusinessType.COMPANY ? ETypeOfBusiness.COMPANY : ETypeOfBusiness.INDIVIDUAL,
    };

    kontragentioApi.list<IKontragentioFields>(filter).then((response) => {
      // TODO: Разобраться с типами
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if ((response.data?.data?.search?.entities ?? []).length > 0) {
        this.state.formData = this.kontragentioFillMapper.mapFieldValue(
          item.field,
          // TODO: Разобраться с типами
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          response.data.data.search.entities[0],
        );

        const kontragentioFields = this.kontragentioService.getFieldsByBusinessType(businessType);

        let localFormFieldStatusList = utilsService.data.cloneImmutable(this.state.formFieldStatusList);
        this.state.activeTabConfig.formConfig.forEach((block) => {
          block.items
            .filter(
              (field) => !field.conditionsList || checkCondition.resolveAndCondition(
                field.conditionsList,
                this.state.formData,
              ),
            )
            .forEach((field) => {
              if (kontragentioFields[field.fieldBlock].includes(field.fieldName) && !!field.validator) {
                localFormFieldStatusList = this.emptyFieldsValidatorHandler
                  .validateField(field, this.state.formData, localFormFieldStatusList);
              }
            });
        });
        this.state.formFieldStatusList = localFormFieldStatusList;
      }

      // TODO: Разобраться с типами
      /* eslint-disable @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      if (response.data?.data?.businessDetails?.companyName) {
        // @ts-ignore
        this.state.formData.businessDetails.doingBusinessAs = response.data.data.businessDetails.companyName;
        // @ts-ignore
        this.state.formData.businessDetails.legalBusinessName = response.data.data.businessDetails.companyName;
      }

      // @ts-ignore
      if (response.data?.data?.businessDetails?.companyType) {
        const formOfBusinessList = this.formConfigHandler.getOrganizationalLegalFormOfBusinessList();

        // @ts-ignore
        this.state.formData.businessDetails.organizationalLegalFormOfBusiness = formOfBusinessList.find(
          // @ts-ignore
          (item) => item.id === response.data.data.businessDetails.companyType,
        );
      }
      /* eslint-enable @typescript-eslint/ban-ts-comment */

      const tabListConfig = this.formConfigHandler.getTabListConfig();

      this.state.formDraft = this.draftHandler.updateDraft(tabListConfig, this.state.formDraft);
      this.draftHandler.saveDraft(this.state.formDraft);
    })
      .catch(() => {
        const tabListConfig = this.formConfigHandler.getTabListConfig();

        this.state.formDraft = this.draftHandler.updateDraft(tabListConfig, this.state.formDraft);
        this.draftHandler.saveDraft(this.state.formDraft);
      });
  };

  getSummaryData = (
    formData: TFormData,
    tabListConfig: IFormConfig[],
  ): ISummaryDataBlock[] => tabListConfig
    .filter((tabConfigItem) => tabConfigItem.id !== EPageTab.SUMMARY)
    .reduce((summaryBlockList, formConfigItem) => {
      const data = [] as ICInfoBlockItem[];

      formConfigItem.formConfig.forEach((config) => {
        config.items
          .filter(
            (field) => {
              if (!field.conditionsList) {
                return true;
              }

              return checkCondition.resolveAndCondition(field.conditionsList, formData);
            },
          )
          .forEach((field) => {
            const item = this.toSummaryMapper.mapFieldValue(field, formData) as ICInfoBlockItem;
            data.push(item);
          });
      });

      const infoBlock: ICInfoBlock = {
        title: formConfigItem.title,
        data,
      };

      const summaryDataBlock: ISummaryDataBlock = {
        tabConfig: formConfigItem,
        ...infoBlock,
        tab: this.state.tabs
          .find((tabItem) => tabItem.id === formConfigItem.id) as ITab,
      };
      summaryBlockList.push(summaryDataBlock);

      return summaryBlockList;
    }, [] as ISummaryDataBlock[]);

  saveData = async () => {
    const api = this.serviceContainer.resolve(EServiceContainerInstances.API);

    const apiCallsMap = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [EBusinessType.COMPANY]: async (formData) => api.account.activateCompanyAccount({ formData }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [EBusinessType.INDIVIDUAL_ENTREPRENEUR]: async (formData) => api.account
        .activateIndividualEntrepreneurAccount({ formData }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [EBusinessType.INDIVIDUAL]: async (formData) => api.account.activateIndividualAccount({ formData }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [EBusinessType.SELF_EMPLOYED]: async (formData) => api.account.activateSelfEmployedAccount({ formData }),
    };

    let data = {} as TFormData;

    this.formConfigHandler.getTabListConfig()
      .filter((tabConfig) => tabConfig.id !== EPageTab.SUMMARY)
      .forEach((tabConfig) => {
        tabConfig.formConfig.forEach((block) => {
          block.items
            .filter(
              (field) => !field.conditionsList || checkCondition.resolveAndCondition(
                field.conditionsList,
                this.state.formData,
              ),
            )
            .forEach((field) => {
              data = this.toSaveMapper.mapFieldValue(field, data);
            });
        });
      });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    data.account_for_payouts.country = 'RU';

    const apiVariant = this.state.formData.businessStructure.businessType.id;

    return apiCallsMap[apiVariant](data);
  };
}

export default FormDataHandler;
