<template>
  <div
    :id="name"
    :class="inputStyle"
  >
    <LabelComponent
      v-if="label"
      :label-text="label"
      :tooltip="tooltip"
      :label-for="name"
    />
    <div
      :class="inputControlClass"
    >
      <input
        ref="input"
        :name="name"
        :value="value"
        :type="type"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :maxlength="maxlength"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
      >
      <div
        class="input-text__control-left-icon"
      >
        <slot
          name="left-icon"
        />
      </div>
      <div
        class="input-text__control-right-icon"
      >
        <slot
          name="right-icon"
        />
        <div
          v-if="!isLoading && clearable"
          v-show="value"
          class="input-text__control-right-icon-clear"
          @click="handleClearInput"
        >
          <DSIcon
            icon="cross"
            size="s"
          />
        </div>
        <div
          v-if="isLoading"
          class="input-text__control-right-icon-loading"
        >
          <PreloaderComponent
            :height="'20px'"
            :width="'20px'"
          />
        </div>
      </div>
      <div
        class="input-text__control-button"
      >
        <slot
          name="button"
        />
      </div>
    </div>
    <div
      v-if="description || error || errors.length > 0 || success"
      class="input-text__options"
    >
      <DSTextElement
        v-if="description"
        variant="small"
        class="input-text__options-description"
      >
        {{ description }}
      </DSTextElement>
      <DSTextElement
        v-if="error"
        variant="small"
        color="error"
        class="input-text__options-error"
      >
        {{ error }}
      </DSTextElement>
      <ul v-if="errors.length">
        <li
          :id="`${name}-error`"
          class="input-text__options-error"
        >
          {{ errors[0].$message }}
        </li>
      </ul>
      <DSTextElement
        v-if="success"
        variant="small"
        class="input-text__options-success"
      >
        {{ success }}
      </DSTextElement>
    </div>
  </div>
</template>

<script>
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import PreloaderComponent from '@/components/Preloader/PreloaderComponent.vue';
import LabelComponent from '@/components/LabelComponent.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default {
  name: 'InputText',
  components: {
    DSIcon,
    LabelComponent,
    DSTextElement,
    PreloaderComponent,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    success: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
    maxlength: {
      type: Number,
      default: null,
    },
  },
  computed: {
    hasLeftIconSlot() {
      return !!this.$slots['left-icon'];
    },
    hasRightIconSlot() {
      return !!this.$slots['right-icon'];
    },
    hasButtonSlot() {
      return !!this.$slots.button;
    },
    inputStyle() {
      return [
        'input-text',
        this.clearable && 'input-text__clearable',
        this.hasLeftIconSlot && 'input-text__left-icon',
        this.hasRightIconSlot && 'input-text__right-icon',
        this.hasButtonSlot && 'input-text__button',
      ];
    },
    inputControlClass() {
      return [
        'input-text__control',
        this.error && 'input-text__control_error',
        this.isInvalid && 'input-text__control_error',
        this.success && 'input-text__control_success',
        { 'input-text__control_error': !!this.errors.length },
      ];
    },
  },
  methods: {
    handleInput(event) {
      this.$emit('update:value', event.target.value);
    },
    handleBlur(event) {
      this.$emit('blur', event);
    },
    handleClearInput() {
      this.$emit('update:clear', '');
    },
    handleFocus() {
      this.$emit('focus');
    },
  },
};
</script>

<style lang="scss">
@import "style";
</style>
