<template>
  <div class="time-picker">
    <span class="time-picker__icon">
      <DSIcon
        icon="clock"
      />
    </span>
    <MaskedInput
      class="time-picker__input"
      :value="modelValue"
      :mask="'0`0`:0`0`'"
      :lazy="false"
      :overwrite="true"
      @change="handleModelValueChange"
    />
  </div>
</template>

<script>
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import { IMaskComponent as MaskedInput } from 'vue-imask';

export default {
  name: 'TimePicker',
  components: {
    DSIcon,
    MaskedInput,
  },
  props: {
    modelValue: {
      type: String,
      default: '00:00',
    },
  },
  computed: {
  },
  methods: {
    handleModelValueChange(event) {
      const [hours, minutes] = event.target.value.split(':');
      const result = [hours, minutes];
      result[0] = hours > 23 ? 23 : hours;
      result[1] = minutes > 59 ? 59 : minutes;
      this.$emit('update:modelValue', result.join(':'));
    },
  },
};
</script>

<style lang="scss">
.time-picker {
    position: relative;

    &__icon {
      display: flex;
      left: 16px;
      position: absolute;
      fill: #ABACAC;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }

    &__input {
      display: flex;
      align-items: center;
      font-family: $text-ibm-plex-sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0em;
      border: 2px solid #ECEEF2;
      border-radius: 8px;
      width: 199px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 48px;
      color: #000000;
      height: 48px;
      outline: none;

      &:focus {
        border-color: #4397CB;
      }
    }
}
</style>
