import ISaveHandler from '@/module/product/view/ProductFormPage/handler/contract/ISaveHandler';
import IFormDataToModelMapper from '@/module/product/view/ProductFormPage/mapper/contract/IFormDataToModelMapper';
import TFormData from '@/module/product/model/FormData/TFormData';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import VueRouter from 'vue-router';
import ENotificationType from '@/module/common/service/NotificationService/model/ENotificationType';
import EEventName from '@/module/common/service/NotificationService/model/EEventName';
import { Store } from 'vuex';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import checkCondition from '@/module/common/helpers/checkCondition';

class SaveHandler implements ISaveHandler {
  private formDataToModelMapper: IFormDataToModelMapper;

  private readonly serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer, formDataToModelMapper: IFormDataToModelMapper) {
    this.serviceContainer = serviceContainer;
    this.formDataToModelMapper = formDataToModelMapper;
  }

  save = async (formData: TFormData): Promise<void> => {
    const notificationService = this.serviceContainer.notificationService;
    const localisationRegistry = this.serviceContainer.localisationRegistry;
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const state = this.serviceContainer.moduleProduct.productFormPage.getState();
    const router = this.serviceContainer.resolve<VueRouter>(EServiceContainerInstances.ROUTER);
    const validateHandler = this.serviceContainer.moduleProduct.productFormPage.handlerService.validateHandler;

    validateHandler.validateForm(formData);

    if (!state.isFormValid) {
      this.goToFirstErrorField();

      return;
    }

    state.isLoading = true;
    try {
      const data = this.formDataToModelMapper.map(formData);

      if (state.isEditMode) {
        await this.serviceContainer.apiService.product.update(data);
      } else {
        await this.serviceContainer.apiService.product.create(data);
      }

      notificationService.push(
        ENotificationType.INFO_POPUP,
        {
          text: localisationRegistry.popUp.savedProduct,
          title: localisationRegistry.popUp.canChange,
        },
      );

      await store.commit(
        'productListStore/changeFieldValue',
        { fieldName: 'isInitialRequest', value: true },
      );
      await store.dispatch('productListStore/getProducts', true);

      await router.push('/products/').catch(() => null);

      this.serviceContainer.moduleProduct.productFormPage.clearState();
    } catch (e) {
      notificationService.push(
        ENotificationType.ERROR_POPUP,
        {
          text: localisationRegistry.popUp.error,
          title: localisationRegistry.popUp.wentWrong,
          delay: 10000,
          control: [
            {
              text: localisationRegistry.popUp.tryAgain,
              name: EEventName.PRODUCT_TRY_AGAIN,
            },
          ],
        },
      );
    } finally {
      state.isLoading = false;
    }
  };

  private goToFirstErrorField = () => {
    const productFormPage = this.serviceContainer.moduleProduct.productFormPage;
    const state = productFormPage.getState();
    const formConfigHandler = productFormPage.handlerService.formConfigHandler;
    const formDataHandler = productFormPage.handlerService.formDataHandler;
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);

    const formData = formDataHandler.get();

    let fieldName;
    formConfigHandler.get().forEach((block) => {
      block.items.forEach((field) => {
        if (
          !field.conditionsList
          || checkCondition.resolveAndCondition(field.conditionsList, formData)
        ) {
          const fieldStatus = state.statuses[field.fieldName] ?? { code: EValidationFieldStatusCode.DEFAULT };

          if (
            !fieldName
            && fieldStatus.code === EValidationFieldStatusCode.ERROR
          ) {
            fieldName = field.fieldName;
          }
        }
      });
    });

    if (fieldName) {
      setTimeout(() => {
        const element = document.getElementById(fieldName as string);

        store.getters.getScrollHostRef.$el.scrollTop = element?.offsetTop;
      }, 200);
    }
  };
}

export default SaveHandler;
