import IMerchantModeService from '@/module/common/models/service/MerchantMode/IMerchantModeService';
import EMerchantMode from '@/module/common/models/EMerchantMode';
import { Store } from 'vuex';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';

class MerchantModeService implements IMerchantModeService {
  private readonly serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  getMerchantMode(): EMerchantMode {
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);

    return store.getters['authStore/getMerchantMode'];
  }

  isSalesMode(): boolean {
    return this.getMerchantMode() === EMerchantMode.SALES;
  }

  isTestMode(): boolean {
    return this.getMerchantMode() === EMerchantMode.TEST;
  }
}

export default MerchantModeService;
