<template>
  <table
    class="ds-table"
  >
    <tr
      v-if="head"
      class="ds-table__row"
    >
      <th
        v-for="(header, index) in head"
        :key="index"
        class="ds-table__cell ds-table__cell--header"
      >
        <template
          v-if="!isHeadSlot"
        >
          <DSTextElement>
            {{ header }}
          </DSTextElement>
        </template>
        <slot
          name="head"
          :cell="header"
          :col="index"
        />
      </th>
    </tr>
    <tbody
      v-for="(tbodyRow, tbodyRowIndex) in rows"
      :key="`tbody${tbodyRowIndex}`"
      class="ds-table__tbody"
    >
      <tr
        v-for="(row, rowIndex) in tbodyRow"
        :key="`row${rowIndex}`"
        class="ds-table__row"
      >
        <td
          v-for="(cell, colIndex) in row"
          :key="colIndex"
          :class="getCellClass(colIndex, fillFirstCol)"
        >
          <template
            v-if="!isCellSlot"
          >
            <DSTextElement
              variant="small"
            >
              {{ cell }}
            </DSTextElement>
          </template>
          <slot
            name="cell"
            :cell="cell"
            :row="rowIndex"
            :col="colIndex"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default {
  name: 'DSTable',
  components: {
    DSTextElement,
  },
  props: {
    head: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    fillFirstCol: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isHeadSlot() {
      return !!this.$scopedSlots.head?.();
    },
    isCellSlot() {
      return !!this.$scopedSlots.cell?.();
    },
  },
  methods: {
    getCellClass(col, fillFirstCol) {
      return [
        'ds-table__cell',
        fillFirstCol && col === 0 ? 'ds-table__cell_filled' : null,
      ];
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import "../variables";

.ds-table {
  min-width: 100%;
  border-collapse: collapse;
  border: none;

  &__row {
    border-bottom: 1px solid $surface-stroke-color;

    &:last-child {
      border-bottom: none;
    }
  }

  &__tbody {
    border-bottom: 2px solid $surface-stroke-color;

    &:last-child {
      border-bottom: none;
    }
  }

  &__cell {
    padding: 12px 15px;
    border: 1px solid $surface-stroke-color;

    &_filled {
      background-color: #f8fafc;
    }

    &--header {
      text-align: left;
      background-color: #f8fafc;
    }
  }
}
</style>
