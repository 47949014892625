import INumberFormatService from '@/module/common/service/NumberFormatService/contract/INumberFormatService';
import { Store } from 'vuex';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';
import IUtilsService from '@/module/common/service/UtilsService/contract/IUtilsService';
import NumberFormatException from '@/module/common/service/NumberFormatService/exception/NumberFormatException';
import ELocale from '@/module/common/models/ELocale';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';

class NumberFormatService implements INumberFormatService {
  private serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  amount = (value: number | string): string => {
    const store = this.serviceContainer
      .resolve<Store<unknown>>(EServiceContainerInstances.STORE);

    let preprocessedValue = this.preprocessValue(value);
    preprocessedValue = Math.round(+preprocessedValue);
    const locale = store.getters.getLocale ?? ELocale.EN;

    const result = new Intl.NumberFormat(locale).format(+preprocessedValue);

    return this.postprocessResult(result);
  };

  money = (value: number | string, currency: string): string => {
    const localCurrency = this.mapCurrency(currency);

    const store = this.serviceContainer
      .resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const utilsService = this.serviceContainer
      .resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);

    const locale = store.getters.getLocale ?? ELocale.EN;
    const localisationRegistry: ILocalisationRegistry = store.getters.getLocalisationRegistry;
    const currencyKey = localisationRegistry.currency.key[currency];

    const preprocessedValue = this.preprocessValue(value);

    let result = new Intl.NumberFormat(
      locale,
      {
        style: 'currency',
        currency: localCurrency,
        currencyDisplay: 'narrowSymbol',
      },
    )
      .format(+preprocessedValue);

    result = this.postprocessResult(result);

    return utilsService.string.replace(result, localCurrency, currencyKey);
  };

  percentage = (value: number | string): string => {
    const store = this.serviceContainer
      .resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const utilsService = this.serviceContainer
      .resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);
    const locale = store.getters.getLocale ?? ELocale.EN;

    const preprocessedValue = this.preprocessValue(value);

    let result = new Intl.NumberFormat(
      locale,
      {
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      },
    ).format(+preprocessedValue / 100);

    result = utilsService.string.replaceAll(result, ' ', ' ');

    if (locale === ELocale.EN) {
      result = utilsService.string.replaceLast(result, '%', ' %');
    }

    return result;
  };

  percentageForPromo = (value: number | string): string => {
    const store = this.serviceContainer
      .resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const utilsService = this.serviceContainer
      .resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);
    const locale = store.getters.getLocale ?? ELocale.EN;

    const preprocessedValue = this.preprocessValue(value);

    let result = new Intl.NumberFormat(
      locale,
      {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 6,
      },
    ).format(+preprocessedValue / 100);

    result = utilsService.string.replaceAll(result, ' ', ' ');

    if (locale === ELocale.EN) {
      result = utilsService.string.replaceLast(result, '%', ' %');
    }

    return result;
  };

  getMoneyRegex = (): RegExp => {
    const store = this.serviceContainer
      .resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const locale = store.getters.getLocale ?? ELocale.EN;

    return locale === ELocale.EN ? /^\d+(\.\d{1,2})?$/ : /^\d+(,\d{1,2})?$/;
  };

  getInputPriceValue = (value: string | number): string => {
    const utilsService = this.serviceContainer
      .resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);

    const delimiter = this.getMoneyDelimiter();

    return utilsService.string.replaceLast(`${value}`, '.', delimiter);
  };

  getPercentDelimiter = (): string => this.getDelimiter();

  getMoneyDelimiter = (): string => this.getDelimiter();

  private getDelimiter = (): string => {
    const store = this.serviceContainer
      .resolve<Store<unknown>>(EServiceContainerInstances.STORE);

    const locale = store.getters.getLocale ?? ELocale.EN;

    return locale === ELocale.EN ? '.' : ',';
  };

  private preprocessValue = (value: string | number): number => {
    const utilsService = this.serviceContainer
      .resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);

    const isString = utilsService.typeCheck.isString(value);
    if (!isString && !utilsService.typeCheck.isNumber(value)) {
      throw new NumberFormatException(`Unprocessable value type '${typeof value}'`);
    }

    let preprocessedValue = value;
    if (isString) {
      preprocessedValue = utilsService.string.replaceLast(`${value}`, ',', '.');
    }

    if (Number.isNaN(+preprocessedValue)) {
      throw new NumberFormatException(`Unprocessable value ${value}`);
    }

    return +preprocessedValue;
  };

  private postprocessResult = (value: string): string => {
    const utilsService = this.serviceContainer
      .resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);

    return utilsService.string.replace(value, ' ', ' ');
  };

  private mapCurrency = (currency: string): string => {
    const map = {
      TNG: 'KZT',
    };

    return map[currency] ?? currency;
  };
}

export default NumberFormatService;
