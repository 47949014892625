import IFormFieldItem from '@/module/design-system/components/models/FormComponent/IFormFieldItem';
import IVueInstance from '@/module/common/models/IVueInstance';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import TValidatorsBlocks from '@/module/merchant/view/AccountActivatePage/Validator/TValidatorsBlocks';
import TValidatorsFields from '@/module/merchant/view/AccountActivatePage/Validator/TValidatorsFields';
import Validator from '@/module/common/utils/validator/Validator';
import EValidatorType from '@/module/common/utils/validator/enum/EValidatorType';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';
import cloneImmutable from '@/module/common/utils/data/cloneImmutable';
import IFieldStatus from '@/module/design-system/components/models/FormComponent/IFieldStatus';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import checkCondition from '@/module/common/helpers/checkCondition';
import IFieldValidatorStatusHandler
  from '@/module/merchant/view/AccountActivatePage/Validator/IFieldValidatorStatusHandler';
import ITabValidatorStatusHandler
  from '@/module/merchant/view/AccountActivatePage/Validator/ITabValidatorStatusHandler';
import EFieldName from '@/module/merchant/view/AccountActivatePage/models/EFieldName';
import EFieldBlock from '@/module/merchant/view/AccountActivatePage/models/EFieldBlock';
import EPageTab from '@/module/merchant/view/AccountActivatePage/models/EPageTab';
import ETabStatus from '@/module/merchant/view/AccountActivatePage/models/ETabStatus';
import IPageState from '@/module/merchant/view/AccountActivatePage/models/IPageState';
import IFormConfig from '@/module/merchant/view/AccountActivatePage/models/IFormConfig';
import EBusinessType from '@/module/merchant/models/BuisnessType/EBusinessType';
import TFormData from '@/module/merchant/view/AccountActivatePage/models/FormData/TFormData';
import IValidatorHandler from '@/module/merchant/view/AccountActivatePage/Validator/IValidatorHandler';
import TFieldStatuses from '@/module/merchant/view/AccountActivatePage/models/TFieldStatuses';

class ValidatorHandler implements IValidatorHandler {
  private readonly serviceContainer: IServiceContainer;

  private readonly localisationRegistry: ILocalisationRegistry;

  private readonly state: IPageState;

  private readonly fieldValidatorHandler: IFieldValidatorStatusHandler;

  private readonly tabValidatorHandler: ITabValidatorStatusHandler;

  constructor(
    serviceContainer: IServiceContainer,
    localisationRegistry: ILocalisationRegistry,
    state: IPageState,
    fieldValidatorHandler: IFieldValidatorStatusHandler,
    tabValidatorHandler: ITabValidatorStatusHandler,
  ) {
    this.localisationRegistry = localisationRegistry;
    this.serviceContainer = serviceContainer;
    this.state = state;
    this.fieldValidatorHandler = fieldValidatorHandler;
    this.tabValidatorHandler = tabValidatorHandler;
  }

  getValidators = (): TValidatorsBlocks => {
    const validationMessagesLocalisation = this.localisationRegistry.validationMessages;
    const vueInstance = this.serviceContainer.resolve<IVueInstance>(EServiceContainerInstances.VUE_INSTANCE);
    const utilsService = this.serviceContainer.utilsService;

    const businessStructure = EFieldBlock.BUSINESS_STRUCTURE;
    const businessRepresentative = EFieldBlock.BUSINESS_REPRESENTATIVE;
    const businessDetails = EFieldBlock.BUSINESS_DETAILS;
    const accountForPayouts = EFieldBlock.ACCOUNT_FOR_PAYOUTS;
    const supportDetails = EFieldBlock.SUPPORT_DETAILS;

    const validatorsRegistry = {} as TValidatorsBlocks;
    validatorsRegistry[businessStructure] = {} as TValidatorsFields;
    validatorsRegistry[businessRepresentative] = {} as TValidatorsFields;
    validatorsRegistry[businessDetails] = {} as TValidatorsFields;
    validatorsRegistry[accountForPayouts] = {} as TValidatorsFields;
    validatorsRegistry[supportDetails] = {} as TValidatorsFields;

    validatorsRegistry[businessStructure][EFieldName.COUNTRY] = new Validator();
    validatorsRegistry[businessStructure][EFieldName.COUNTRY].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.required,
    );

    validatorsRegistry[businessDetails][EFieldName.TAX_IDENTIFICATION_CODE] = new Validator();
    validatorsRegistry[businessDetails][EFieldName.TAX_IDENTIFICATION_CODE].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.required,
    );
    validatorsRegistry[businessDetails][EFieldName.TAX_IDENTIFICATION_CODE].addValidator(
      EValidatorType.CUSTOM,
      validationMessagesLocalisation.accountActivate.softline.inn.format.company,
      (value, formData) => {
        if (formData.businessStructure.businessType.id !== EBusinessType.COMPANY) {
          return true;
        }

        return value?.length === 10;
      },
    );
    validatorsRegistry[businessDetails][EFieldName.TAX_IDENTIFICATION_CODE].addValidator(
      EValidatorType.CUSTOM,
      validationMessagesLocalisation.accountActivate.softline.inn.format.individual,
      (value, formData) => {
        if (formData.businessStructure.businessType.id !== EBusinessType.INDIVIDUAL_ENTREPRENEUR) {
          return true;
        }

        return value?.length === 12;
      },
    );

    validatorsRegistry[businessDetails][EFieldName.KPP] = new Validator();
    validatorsRegistry[businessDetails][EFieldName.KPP].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.accountActivate.softline.kpp.required,
    );
    validatorsRegistry[businessDetails][EFieldName.KPP].addValidator(
      EValidatorType.REGEX,
      validationMessagesLocalisation.accountActivate.softline.kpp.format,
      '^\\d+$',
    );
    validatorsRegistry[businessDetails][EFieldName.KPP].addValidator(
      EValidatorType.MAX_LENGTH,
      validationMessagesLocalisation.accountActivate.softline.kpp.format,
      9,
    );
    validatorsRegistry[businessDetails][EFieldName.KPP].addValidator(
      EValidatorType.MIN_LENGTH,
      validationMessagesLocalisation.accountActivate.softline.kpp.format,
      9,
    );

    validatorsRegistry[businessDetails][EFieldName.OGRN] = new Validator();
    validatorsRegistry[businessDetails][EFieldName.OGRN].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.accountActivate.softline.ogrn.required,
    );
    validatorsRegistry[businessDetails][EFieldName.OGRN].addValidator(
      EValidatorType.REGEX,
      validationMessagesLocalisation.accountActivate.softline.ogrn.format,
      '^\\d+$',
    );
    validatorsRegistry[businessDetails][EFieldName.OGRN].addValidator(
      EValidatorType.MAX_LENGTH,
      validationMessagesLocalisation.accountActivate.softline.ogrn.format,
      13,
    );
    validatorsRegistry[businessDetails][EFieldName.OGRN].addValidator(
      EValidatorType.MIN_LENGTH,
      validationMessagesLocalisation.accountActivate.softline.ogrn.format,
      13,
    );

    validatorsRegistry[businessDetails][EFieldName.TAXATION_SYSTEM] = new Validator();
    validatorsRegistry[businessDetails][EFieldName.TAXATION_SYSTEM].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.selectOneOf,
    );

    validatorsRegistry[businessStructure][EFieldName.LEGAL_ADDRESS] = new Validator();
    validatorsRegistry[businessStructure][EFieldName.LEGAL_ADDRESS].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.required,
    );
    validatorsRegistry[businessStructure][EFieldName.LEGAL_ADDRESS].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      255,
    );

    validatorsRegistry[businessStructure][EFieldName.ACTUAL_ADDRESS] = new Validator();
    validatorsRegistry[businessStructure][EFieldName.ACTUAL_ADDRESS].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.required,
    );
    validatorsRegistry[businessStructure][EFieldName.ACTUAL_ADDRESS].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      255,
    );

    validatorsRegistry[businessRepresentative][EFieldName.FIRST_NAME] = new Validator();
    validatorsRegistry[businessRepresentative][EFieldName.FIRST_NAME].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.required,
    );
    validatorsRegistry[businessRepresentative][EFieldName.FIRST_NAME].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 128 }),
      128,
    );
    validatorsRegistry[businessRepresentative][EFieldName.FIRST_NAME].addValidator(
      EValidatorType.REGEX,
      validationMessagesLocalisation.onlyCyrillicSymbols,
      '^([^a-zA-Z]+)$',
    );

    validatorsRegistry[businessRepresentative][EFieldName.LAST_NAME] = new Validator();
    validatorsRegistry[businessRepresentative][EFieldName.LAST_NAME].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.required,
    );
    validatorsRegistry[businessRepresentative][EFieldName.LAST_NAME].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 128 }),
      128,
    );
    validatorsRegistry[businessRepresentative][EFieldName.LAST_NAME].addValidator(
      EValidatorType.REGEX,
      validationMessagesLocalisation.onlyCyrillicSymbols,
      '^([^a-zA-Z]+)$',
    );

    validatorsRegistry[businessRepresentative][EFieldName.MIDDLE_NAME] = new Validator();
    validatorsRegistry[businessRepresentative][EFieldName.MIDDLE_NAME].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.required,
    );
    validatorsRegistry[businessRepresentative][EFieldName.MIDDLE_NAME].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 128 }),
      128,
    );
    validatorsRegistry[businessRepresentative][EFieldName.MIDDLE_NAME].addValidator(
      EValidatorType.REGEX,
      validationMessagesLocalisation.onlyCyrillicSymbols,
      '^([^a-zA-Z]+)$',
    );

    validatorsRegistry[businessRepresentative][EFieldName.JOB_TITLE] = new Validator();
    validatorsRegistry[businessRepresentative][EFieldName.JOB_TITLE].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.required,
    );
    validatorsRegistry[businessRepresentative][EFieldName.JOB_TITLE].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      255,
    );
    validatorsRegistry[businessRepresentative][EFieldName.JOB_TITLE].addValidator(
      EValidatorType.REGEX,
      validationMessagesLocalisation.onlyCyrillicSymbols,
      '^([^a-zA-Z]+)$',
    );

    validatorsRegistry[businessRepresentative][EFieldName.DOCUMENT] = new Validator();
    validatorsRegistry[businessRepresentative][EFieldName.DOCUMENT].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.required,
    );
    validatorsRegistry[businessRepresentative][EFieldName.DOCUMENT].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      255,
    );
    validatorsRegistry[businessRepresentative][EFieldName.DOCUMENT].addValidator(
      EValidatorType.REGEX,
      validationMessagesLocalisation.onlyCyrillicSymbols,
      '^([^a-zA-Z]+)$',
    );

    validatorsRegistry[businessRepresentative][EFieldName.DOCUMENT_PASSPORT] = new Validator();
    validatorsRegistry[businessRepresentative][EFieldName.DOCUMENT_PASSPORT].addValidator(
      EValidatorType.NOT_EMPTY,
      this.localisationRegistry.accountActivate.fileUpload.validationMessages.exactlyOneFile,
    );

    validatorsRegistry[businessRepresentative][EFieldName.DOCUMENT_SNILS] = new Validator();
    validatorsRegistry[businessRepresentative][EFieldName.DOCUMENT_SNILS].addValidator(
      EValidatorType.NOT_EMPTY,
      this.localisationRegistry.accountActivate.fileUpload.validationMessages.exactlyOneFile,
    );

    // eslint-disable-next-line max-len,vue/max-len
    validatorsRegistry[businessRepresentative][EFieldName.DOCUMENT_INDIVIDUAL_ENTREPRENEUR] = new Validator();
    // eslint-disable-next-line max-len,vue/max-len
    validatorsRegistry[businessRepresentative][EFieldName.DOCUMENT_INDIVIDUAL_ENTREPRENEUR].addValidator(
      EValidatorType.NOT_EMPTY,
      this.localisationRegistry.accountActivate.fileUpload.validationMessages.exactlyOneFile,
    );

    validatorsRegistry[businessRepresentative][EFieldName.DOCUMENT_COMPANY] = new Validator();
    validatorsRegistry[businessRepresentative][EFieldName.DOCUMENT_COMPANY].addValidator(
      EValidatorType.NOT_EMPTY,
      this.localisationRegistry.accountActivate.fileUpload.validationMessages.exactlyOneFile,
    );

    validatorsRegistry[businessDetails][EFieldName.DOCUMENT_OGRNIP] = new Validator();
    validatorsRegistry[businessDetails][EFieldName.DOCUMENT_OGRNIP].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.required,
    );
    validatorsRegistry[businessDetails][EFieldName.DOCUMENT_OGRNIP].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 15 }),
      15,
    );
    validatorsRegistry[businessDetails][EFieldName.DOCUMENT_OGRNIP].addValidator(
      EValidatorType.MIN_LENGTH,
      vueInstance.$t('validation_messages.min_length', { number: 15 }),
      15,
    );

    validatorsRegistry[businessRepresentative][EFieldName.EMAIL] = new Validator();
    validatorsRegistry[businessRepresentative][EFieldName.EMAIL].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.required,
    );
    validatorsRegistry[businessRepresentative][EFieldName.EMAIL].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 180 }),
      180,
    );
    validatorsRegistry[businessRepresentative][EFieldName.EMAIL].addValidator(
      EValidatorType.EMAIL,
      validationMessagesLocalisation.invalidFormat,
    );

    validatorsRegistry[businessRepresentative][EFieldName.PHONE_NUMBER] = new Validator();
    validatorsRegistry[businessRepresentative][EFieldName.PHONE_NUMBER].addValidator(
      EValidatorType.CUSTOM,
      validationMessagesLocalisation.accountActivate.softline.phone.format,
      (value) => {
        if (!utilsService.typeCheck.isSet<string>(value)) {
          return true;
        }
        return value.replaceAll(' ', '').length <= 12;
      },
    );
    validatorsRegistry[businessRepresentative][EFieldName.PHONE_NUMBER].addValidator(
      EValidatorType.REGEX,
      validationMessagesLocalisation.accountActivate.softline.phone.format,
      '^\\+[\\d-\\s]+$',
    );

    validatorsRegistry[businessDetails][EFieldName.PASSPORT] = new Validator();
    validatorsRegistry[businessDetails][EFieldName.PASSPORT].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.accountActivate.softline.passport.required,
    );
    validatorsRegistry[businessDetails][EFieldName.PASSPORT].addValidator(
      EValidatorType.MAX_LENGTH,
      validationMessagesLocalisation.accountActivate.softline.passport.format,
      10,
    );
    validatorsRegistry[businessDetails][EFieldName.PASSPORT].addValidator(
      EValidatorType.MIN_LENGTH,
      validationMessagesLocalisation.accountActivate.softline.passport.format,
      10,
    );

    validatorsRegistry[businessDetails][EFieldName.PASSPORT_DATE] = new Validator();
    validatorsRegistry[businessDetails][EFieldName.PASSPORT_DATE].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.required,
    );
    validatorsRegistry[businessDetails][EFieldName.PASSPORT_DATE].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      255,
    );
    validatorsRegistry[businessDetails][EFieldName.PASSPORT_DATE].addValidator(
      EValidatorType.REGEX,
      validationMessagesLocalisation.onlyCyrillicSymbols,
      '^([^a-zA-Z]+)$',
    );

    validatorsRegistry[businessDetails][EFieldName.PASSPORT_CODE] = new Validator();
    validatorsRegistry[businessDetails][EFieldName.PASSPORT_CODE].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.accountActivate.softline.passportCode.required,
    );
    validatorsRegistry[businessDetails][EFieldName.PASSPORT_CODE].addValidator(
      EValidatorType.MAX_LENGTH,
      validationMessagesLocalisation.accountActivate.softline.passportCode.format,
      6,
    );
    validatorsRegistry[businessDetails][EFieldName.PASSPORT_CODE].addValidator(
      EValidatorType.MIN_LENGTH,
      validationMessagesLocalisation.accountActivate.softline.passportCode.format,
      6,
    );

    validatorsRegistry[businessDetails][EFieldName.SNILS] = new Validator();
    validatorsRegistry[businessDetails][EFieldName.SNILS].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.accountActivate.softline.snils.required,
    );
    validatorsRegistry[businessDetails][EFieldName.SNILS].addValidator(
      EValidatorType.MAX_LENGTH,
      validationMessagesLocalisation.accountActivate.softline.snils.format,
      11,
    );
    validatorsRegistry[businessDetails][EFieldName.SNILS].addValidator(
      EValidatorType.MIN_LENGTH,
      validationMessagesLocalisation.accountActivate.softline.snils.format,
      11,
    );

    validatorsRegistry[businessDetails][EFieldName.LEGAL_BUSINESS_NAME] = new Validator();
    validatorsRegistry[businessDetails][EFieldName.LEGAL_BUSINESS_NAME].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.required,
    );
    validatorsRegistry[businessDetails][EFieldName.LEGAL_BUSINESS_NAME].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 128 }),
      128,
    );
    validatorsRegistry[businessDetails][EFieldName.LEGAL_BUSINESS_NAME].addValidator(
      EValidatorType.REGEX,
      validationMessagesLocalisation.onlyCyrillicSymbols,
      '^([^a-zA-Z]+)$',
    );

    validatorsRegistry[businessDetails][EFieldName.DOING_BUSINESS_AS] = new Validator();
    validatorsRegistry[businessDetails][EFieldName.DOING_BUSINESS_AS].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.required,
    );
    validatorsRegistry[businessDetails][EFieldName.DOING_BUSINESS_AS].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 128 }),
      128,
    );

    validatorsRegistry[businessDetails][EFieldName.BUSINESS_WEBSITE] = new Validator();
    validatorsRegistry[businessDetails][EFieldName.BUSINESS_WEBSITE].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.required,
    );
    validatorsRegistry[businessDetails][EFieldName.BUSINESS_WEBSITE].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      255,
    );
    validatorsRegistry[businessDetails][EFieldName.BUSINESS_WEBSITE].addValidator(
      EValidatorType.REGEX,
      validationMessagesLocalisation.invalidFormat,
      '^.+\\.+.+$',
    );

    validatorsRegistry[businessDetails][EFieldName.PRODUCT_DESCRIPTION] = new Validator();
    validatorsRegistry[businessDetails][EFieldName.PRODUCT_DESCRIPTION].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      255,
    );

    validatorsRegistry[supportDetails][EFieldName.EMAIL] = new Validator();
    validatorsRegistry[supportDetails][EFieldName.EMAIL].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.required,
    );
    validatorsRegistry[supportDetails][EFieldName.EMAIL].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 180 }),
      180,
    );
    validatorsRegistry[supportDetails][EFieldName.EMAIL].addValidator(
      EValidatorType.EMAIL,
      validationMessagesLocalisation.invalidFormat,
    );

    validatorsRegistry[supportDetails][EFieldName.PHONE_NUMBER] = new Validator();
    validatorsRegistry[supportDetails][EFieldName.PHONE_NUMBER].addValidator(
      EValidatorType.CUSTOM,
      validationMessagesLocalisation.accountActivate.softline.phone.format,
      (value) => {
        if (!utilsService.typeCheck.isSet<string>(value)) {
          return true;
        }
        return value.replaceAll(' ', '').length <= 12;
      },
    );
    validatorsRegistry[supportDetails][EFieldName.PHONE_NUMBER].addValidator(
      EValidatorType.REGEX,
      validationMessagesLocalisation.accountActivate.softline.phone.format,
      '^\\+[\\d-\\s]+$',
    );

    validatorsRegistry[accountForPayouts][EFieldName.BIK] = new Validator();
    validatorsRegistry[accountForPayouts][EFieldName.BIK].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.accountActivate.softline.bik.required,
    );
    validatorsRegistry[accountForPayouts][EFieldName.BIK].addValidator(
      EValidatorType.MAX_LENGTH,
      validationMessagesLocalisation.accountActivate.softline.bik.format,
      9,
    );
    validatorsRegistry[accountForPayouts][EFieldName.BIK].addValidator(
      EValidatorType.MIN_LENGTH,
      validationMessagesLocalisation.accountActivate.softline.bik.format,
      9,
    );

    validatorsRegistry[accountForPayouts][EFieldName.BANK_NAME] = new Validator();
    validatorsRegistry[accountForPayouts][EFieldName.BANK_NAME].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.required,
    );
    validatorsRegistry[accountForPayouts][EFieldName.BANK_NAME].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      255,
    );
    validatorsRegistry[accountForPayouts][EFieldName.BANK_NAME].addValidator(
      EValidatorType.REGEX,
      validationMessagesLocalisation.onlyCyrillicSymbols,
      '^([^a-zA-Z]+)$',
    );

    validatorsRegistry[accountForPayouts][EFieldName.KS] = new Validator();
    validatorsRegistry[accountForPayouts][EFieldName.KS].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.required,
    );
    validatorsRegistry[accountForPayouts][EFieldName.KS].addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      255,
    );
    validatorsRegistry[accountForPayouts][EFieldName.KS].addValidator(
      EValidatorType.REGEX,
      validationMessagesLocalisation.onlyNumbers,
      '^\\d+$',
    );

    validatorsRegistry[accountForPayouts][EFieldName.RS] = new Validator();
    validatorsRegistry[accountForPayouts][EFieldName.RS].addValidator(
      EValidatorType.NOT_EMPTY,
      validationMessagesLocalisation.accountActivate.softline.rs.required,
    );
    validatorsRegistry[accountForPayouts][EFieldName.RS].addValidator(
      EValidatorType.MAX_LENGTH,
      validationMessagesLocalisation.accountActivate.softline.rs.format,
      20,
    );
    validatorsRegistry[accountForPayouts][EFieldName.RS].addValidator(
      EValidatorType.MIN_LENGTH,
      validationMessagesLocalisation.accountActivate.softline.rs.format,
      20,
    );

    validatorsRegistry[businessDetails][EFieldName.PRODUCT_TAX_CATEGORY] = new Validator();
    validatorsRegistry[businessDetails][EFieldName.PRODUCT_TAX_CATEGORY].addValidator(
      EValidatorType.CUSTOM_NOT_EMPTY,
      validationMessagesLocalisation.oneOrMoreVariants,
      (value) => (value ?? []).filter((item) => item.value).length > 0,
    );

    return validatorsRegistry;
  };

  validateField = (
    fieldConfig: IFormFieldItem<EFieldName, EFieldBlock>,
    formData: TFormData,
    formFieldStatusList: TFieldStatuses,
  ): TFieldStatuses => {
    const { fieldBlock, fieldName } = fieldConfig;

    let localFormFieldStatusList = cloneImmutable(formFieldStatusList);
    if (!localFormFieldStatusList[fieldBlock]) {
      localFormFieldStatusList[fieldBlock] = {} as { [key in EFieldName]: IFieldStatus };
    }

    if (!fieldConfig.validator) {
      localFormFieldStatusList[fieldBlock][fieldName] = {
        code: EValidationFieldStatusCode.DEFAULT,
      };
    } else if (
      fieldConfig.conditionsList
      && !checkCondition.resolveAndCondition(fieldConfig.conditionsList, formData)
    ) {
      localFormFieldStatusList[fieldBlock][fieldName] = {
        code: EValidationFieldStatusCode.DEFAULT,
      };
    } else {
      fieldConfig.validator.clear();

      fieldConfig.validator.validate(formData[fieldBlock][fieldName], formData);

      localFormFieldStatusList = this.fieldValidatorHandler.changeStatus(
        fieldConfig,
        fieldConfig.validator,
        localFormFieldStatusList,
      );
    }

    return localFormFieldStatusList;
  };

  validateTab = (formConfig: IFormConfig, formData: TFormData) => {
    if (formConfig.id === EPageTab.SUMMARY) {
      return;
    }

    const utilsService = this.serviceContainer.utilsService;

    let localFormFieldStatusList = utilsService.data.cloneImmutable(this.state.formFieldStatusList);
    formConfig.formConfig.forEach((block) => {
      block.items.forEach((field) => {
        if (!localFormFieldStatusList[field.fieldBlock]) {
          localFormFieldStatusList[field.fieldBlock] = {} as { [key in EFieldName]: IFieldStatus };
        }
        localFormFieldStatusList[field.fieldBlock][field.fieldName] = {
          code: EValidationFieldStatusCode.DEFAULT,
        };
      });
      block.items.filter(
        (field) => !field.conditionsList
            || (field.conditionsList && checkCondition.resolveAndCondition(field.conditionsList, formData)),
      ).forEach((field) => {
        localFormFieldStatusList = this.validateField(field, formData, localFormFieldStatusList);
      });
    });

    this.state.formFieldStatusList = localFormFieldStatusList;

    let status = ETabStatus.SUCCESS;
    formConfig.formConfig.forEach((block) => {
      block.items.forEach((field) => {
        status = this.tabValidatorHandler.changeStatus(
          field,
          this.state.formFieldStatusList,
          status,
        );
      });
    });

    this.state.tabs = this.state.tabs.map((tab) => {
      if (tab.id === formConfig.id) {
        tab.status = status;
      }

      return tab;
    });
  };
}

export default ValidatorHandler;
