import ITermService from '@/module/product/service/contract/ITermService';
import ITermValue from '@/module/product/view/ProductFormPage/model/ITermValue';
import ITermOption from '@/module/product/view/ProductFormPage/model/ITermOption';
import IVueInstance from '@/module/common/models/IVueInstance';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import ECalendarSymbol from '@/module/product/model/ECalendarSymbol';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';

class TermService implements ITermService {
  private readonly serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  get = (
    licenseTerm: ITermValue,
  ): string => `P${licenseTerm.inputValue}${licenseTerm.selectValue.id.toUpperCase()}`;

  list = (inputValue: number): ITermOption[] => {
    const vueInstance = this.serviceContainer.resolve<IVueInstance>(EServiceContainerInstances.VUE_INSTANCE);

    return [
      { id: ECalendarSymbol.D, title: vueInstance.$tc('product.day', inputValue) },
      { id: ECalendarSymbol.M, title: vueInstance.$tc('product.month', inputValue) },
      { id: ECalendarSymbol.Y, title: vueInstance.$tc('product.year', inputValue) },
    ];
  };

  parse = (licenseTerm: string | null): ITermValue => {
    if (!licenseTerm || licenseTerm === '0') {
      return {
        inputValue: 1,
        selectValue: this.list(1)
          .find((item) => item.id === ECalendarSymbol.M) as ITermOption,
      };
    }

    const matches = licenseTerm.match(/P(\d+)(\w{1})/);

    const inputValue = +(matches?.[1] ?? 1);
    return {
      inputValue,
      selectValue: this.list(1)
        .find((item) => item.id === matches?.[2].toLowerCase() ?? ECalendarSymbol.M) as ITermOption,
    };
  };
}

export default TermService;
