import AuthorizationApi from '@/api/Auth/AuthorizationApi';
import account from '@/module/merchant/api/account';
import product from '@/module/product/api';
import dashboard from '@/module/dashboard/api';
import promotions from '@/module/promotions/api';
import { AxiosInstance } from 'axios';
import productModule from './productModule';
import authModule from './authModule';
import reportsModule from './reportsModule';
import webhookModule from './webhookModule';
import promotionModule from './promotionModule';
import createEditPromotionModule from './createEditPromotionModule';
import commonModule from './commonModule';

export default function initApi(instance: AxiosInstance) {
  return {
    common: commonModule(instance),
    products: productModule(instance),
    auth: authModule(instance),
    reports: reportsModule(instance),
    webhooks: webhookModule(instance),
    dashboard: dashboard(instance),
    promotion: promotionModule(instance),
    promotions: promotions(instance),
    createEditPromotion: createEditPromotionModule(instance),
    account: account(instance),
    product: product(instance),
    authorizationApi: new AuthorizationApi(instance),
  };
}
