
import Vue, {
  computed,
  inject,
  onUnmounted,
} from 'vue';

import {
  Store,
} from 'vuex';

import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';

import DashboardPageShimmer from '@/components/ShimerLoader/DashboardPageShimmer.vue';
import ErrorPage from '@/components/ErrorPage/ErrorPage.vue';

export default Vue.extend({
  name: 'COverviewPageLayout',
  components: {
    ErrorPage,
    DashboardPageShimmer,
  },
  props: {
    showError: {
      type: Boolean,
      default: false,
    },
    errorStatus: {
      type: [Number, String],
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;
    const store = serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);

    const isMenuMinimized = computed(() => store.getters['commonStatusesStore/getIsMenuMinimized']);
    const errors = computed(() => store.getters['commonStatusesStore/getErrors']);
    const loadingFromStore = computed(() => store.getters['commonStatusesStore/getLoading']);
    const merchantMode = computed(() => store.getters['authStore/getMerchantMode']);

    // @TODO убрать loading из store
    const loading = computed(() => loadingFromStore.value || props.isLoading);

    const containerClasses = computed(() => [
      'c-overview-page-layout__container',
      `c-overview-page-layout__container_${merchantMode.value}`,
      `c-overview-page-layout__container_${isMenuMinimized.value ? 'wide' : 'narrow'}`,
    ]);

    const hasErrors = computed(() => props.showError || errors.value.length > 0);

    const lastErrorCode = computed(() => {
      let code = 200;
      if (hasErrors.value) {
        code = errors.value[0].code;
      }

      return code;
    });

    const errorCode = computed(() => props.errorStatus || lastErrorCode.value);

    onUnmounted(() => {
      store.dispatch('commonStatusesStore/clearError');
    });

    return {
      loading,
      containerClasses,
      hasErrors,
      lastErrorCode,
      errorCode,
    };
  },
});
