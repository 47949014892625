<template>
  <div :class="labelClass">
    <label
      class="label__text"
      :for="labelFor"
    >
      {{ labelText }}
    </label>
    <DSTooltip
      v-if="tooltip"
      class="label__tooltip"
      :text="tooltip"
    >
      <template
        #tooltip-icon
      >
        <div class="label__tooltip-icon">
          <DSIcon
            color="disabled"
            icon="info"
            size="s"
          />
        </div>
      </template>
    </DSTooltip>
  </div>
</template>

<script>
import DSTooltip from '@/module/design-system/components/InfoBlock/DSTooltip.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';

export default {
  name: 'LabelComponent',
  components: {
    DSIcon,
    DSTooltip,
  },
  props: {
    labelText: {
      type: String,
      default: '',
    },
    labelFor: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: 'light',
    },
    tooltip: {
      type: String,
      default: '',
    },
    noMarginBottom: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    labelClass() {
      return [
        'label',
        this.noMarginBottom && 'label_no-margin_bottom',
        `label_theme_${this.theme}`,
      ];
    },
  },
};
</script>

<style lang="scss">
.label {
  display: flex;
  align-items: center;
  margin-bottom: 9px;

  &_no-margin_bottom {
    margin-bottom: 0;
  }

  &_theme {
    &_light {
      .label__text {
        color: $secondary-text;
      }
    }

    &_dark {
      .label__text {
        color: #ffffff;
      }
    }
  }

  &__text {
    font-family: $text-ibm-plex-sans;
    font-size: 16px;
    line-height: 22px;
  }

  &__tooltip {
    margin-left: 7px;

    &-icon {
      width: 18px;
      height: 18px;
    }
  }
}
</style>
