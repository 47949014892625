<template>
  <div
    class="page-title"
  >
    <div
      class="page-title__header-wrapper"
    >
      <DSHeader
        class="page-title__header"
        :size="1"
        :text="pageTitle"
        :class="pageTitleClasses"
      >
        <span
          class="page-title__header-text"
        >{{ pageTitle }}</span>&#65279;<div
          v-if="tooltipText"
          class="page-title__tooltip"
        >
          <DSTooltip
            :text="tooltipText"
            class="page-title__tooltip-icon"
          >
            <template
              #tooltip-icon
            >
              <div
                class="page-title__tooltip-icon"
              >
                <DSIcon
                  icon="info"
                  size="l"
                />
              </div>
            </template>
          </DSTooltip>
        </div>
      </DSHeader>
    </div>
    <div
      v-if="isDefaultSlot"
      class="slot-content"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import DSHeader from '@/module/design-system/components/Text/DSHeader.vue';
import DSTooltip from '@/module/design-system/components/InfoBlock/DSTooltip.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';

export default {
  name: 'CPageTitle',
  components: {
    DSIcon,
    DSHeader,
    DSTooltip,
  },
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      default: null,
    },
  },
  computed: {
    isDefaultSlot() {
      return !!this.$scopedSlots.default;
    },
    pageTitleClasses() {
      return [
        'page-title__header',
        this.tooltipText ? 'page-title__header_flex' : '',
      ];
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import "@/module/design-system/components/variables.scss";

.page-title {
  display: flex;
  align-items: flex-end;
  gap: 10px;

  &__header-wrapper {
    display: block;
  }

  &__header {
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
  }

  &__header-text {
    vertical-align: middle;
    white-space: normal;
  }

  &__tooltip {
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 10px;
    margin-left: 10px;

    &-icon {
      line-height: 32px;
      width: 32px;
      height: 32px;
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .page-title {
    flex-direction: column;
    align-items: baseline;
    gap: 4px;
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .page-title__header_flex {
    flex: 0 0;
  }
}
</style>
