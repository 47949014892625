<template>
  <div
    ref="rootRef"
    v-click-outside="handleClickOutside"
    class="ui-dropdown"
    :class="withControls ? 'ui-dropdown--with-controls' : null"
  >
    <LabelComponent
      v-if="label"
      class="ui-dropdown__label"
      :label-text="label"
      :tooltip="tooltip"
    />

    <button
      class="ui-dropdown__drop-button"
      :class="[isDropdownOpened ? 'ui-dropdown__drop-button--active' : null]"
      type="button"
      @click="toggleDropdown"
    >
      <span
        v-if="colorMarker"
        class="ui-dropdown__drop-button-color-marker"
        :style="{ backgroundColor: colorMarker }"
      />
      <span class="ui-dropdown__drop-button-text">{{ selectedValue }}</span>
      <span class="ui-dropdown__icon">
        <DSIcon
          icon="arrowDown"
        />
      </span>
    </button>

    <div
      v-if="isDropdownOpened"
      class="ui-dropdown__option-list-wrapper"
      :class="[
        isDropToTop && !label
          ? 'ui-dropdown__option-list-wrapper--top'
          : null,
        isDropToTop && label
          ? 'ui-dropdown__option-list-wrapper--top-labeled'
          : null,
      ]"
    >
      <PerfectScrollbar
        class="ui-dropdown__scrollbar"
        :style="{ maxHeight: maxListHeight ? maxListHeight + 'px' : 'auto' }"
      >
        <ul class="ui-dropdown__option-list">
          <li
            class="ui-dropdown__option-item"
            @click="handleOptionClick('all', false)"
          >
            <UICheckbox
              v-if="multipleSelect"
              class="ui-dropdown__option-item-content"
              :checkbox-checked="isAllChecked"
              :checkbox-text="customAll"
            />
          </li>

          <li
            v-for="option of modelOptions"
            :key="option.value"
            class="ui-dropdown__option-item"
            @click="handleOptionClick(option.value, option.isDisabled)"
          >
            <UICheckbox
              v-if="multipleSelect"
              class="ui-dropdown__option-item-content"
              :checkbox-checked="selectedOption.includes(option.value)"
              :checkbox-text="localized ? $t(option.name) : option.name"
              :is-disabled="option.isDisabled"
            />
            <UIRadioButton
              v-else-if="withRadioButtons"
              class="ui-dropdown__option-item-content"
              :radio-button-text="localized ? $t(option.name) : option.name"
              :radio-button-checked="option.value === selectedOption"
              :is-disabled="option.isDisabled"
            />
            <span
              v-else
              class="ui-dropdown__option-item-content ui-dropdown__option-item-text"
              :class="option.isDisabled ? 'ui-dropdown__option-item-content--disabled' : null"
            >
              {{ localized ? $t(option.name) : option.name }}
            </span>
          </li>
        </ul>
      </PerfectScrollbar>

      <div
        v-if="withControls"
        class="ui-dropdown__controls"
      >
        <button
          class="ui-dropdown__control-button ui-dropdown__control-button--submit"
          type="button"
          @click="handleSubmitClick"
        >
          {{ submitButtonText }}
        </button>
        <div class="ui-dropdown__control-separator" />
        <button
          class="ui-dropdown__control-button ui-dropdown__control-button--cancel"
          type="button"
          @click="handleCancelClick"
        >
          {{ cancelButtonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import { mapGetters } from 'vuex';
import LabelComponent from '@/components/LabelComponent.vue';
import UICheckbox from './UICheckbox.vue';
import UIRadioButton from './UIRadioButton.vue';

export default {
  name: 'UIDropdown',
  components: {
    DSIcon,
    UIRadioButton,
    UICheckbox,
    LabelComponent,
  },
  props: {
    modelValue: {
      type: [String, Array, Number],
      default: 'samsung',
    },
    modelOptions: {
      type: Array,
      default: () => [
        {
          value: 'samsung',
          name: 'Samsung',
          isDisabled: true,
        },
        {
          value: 'apple',
          name: 'Apple',
        },
        {
          value: 'xiaomi',
          name: 'Xiaomi',
        },
      ],
    },
    maxListHeight: {
      type: Number,
      default: 240,
    },
    withControls: {
      type: Boolean,
      default: false,
    },
    multipleSelect: {
      type: Boolean,
      default: false,
    },
    withRadioButtons: {
      type: Boolean,
      default: false,
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Not selected',
    },
    customAll: {
      type: String,
      default: 'All',
    },
    submitButtonText: {
      type: String,
      default: 'Apply',
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
    colorMarker: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    localized: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDropdownOpened: false,
      selectedOption: [],
      isDropToTop: false,
    };
  },
  computed: {
    ...mapGetters({
      scrollableParentRef: 'getScrollHostRef',
    }),
    isAllChecked() {
      return this.selectedOption.length === this.modelOptions.length;
    },
    selectedValue() {
      if (this.isAllChecked && this.multipleSelect) {
        return this.customAll;
      }

      if (!this.isAllChecked && this.multipleSelect) {
        return (
          this.modelOptions
            .filter((option) => this.selectedOption.includes(option.value))
            .map((option) => {
              if (this.localized) {
                return this.$t(option.name);
              }
              return option.name;
            })
            .join(', ') || this.placeholder
        );
      }

      const selectedOption = this.modelOptions.find(
        (option) => option.value === this.selectedOption,
      );
      const selectedOptionName = selectedOption?.name || this.placeholder;

      return this.localized ? this.$t(selectedOptionName) : selectedOptionName;
    },
  },
  watch: {
    modelValue() {
      this.initSelectOption();
    },
  },
  mounted() {
    this.initSelectOption();
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpened = !this.isDropdownOpened;
      const {
        clientHeight,
        scrollTop,
      } = this.scrollableParentRef.$el;
      const positionToCompare = clientHeight + scrollTop - this.$refs.rootRef.offsetTop;
      const dropdownHeight = (
        this.maxListHeight
          ? this.maxListHeight
          : 50 * (this.modelOptions.length + 1)
      ) + (this.withControls ? 130 : 0) + 50 + (this.label ? 20 : 0);
      this.isDropToTop = positionToCompare <= dropdownHeight;
    },
    async handleSubmitClick() {
      await this.$emit('update:modelValue', this.selectedOption);
      this.isDropdownOpened = false;
      if (this.selectedOption.length === 0 && this.multipleSelect) {
        this.selectedOption = typeof this.modelValue === 'object'
          ? [...this.modelValue]
          : [this.modelValue];
      }
    },
    handleCancelClick() {
      this.selectedOption = this.multipleSelect
        ? [...this.modelValue]
        : this.modelValue;
    },
    updateSelectedOption(value) {
      if (!this.selectedOption.includes(value)) {
        this.selectedOption.push(value);
      } else {
        this.selectedOption = this.selectedOption.filter(
          (option) => option !== value,
        );
      }
    },
    handleAllClick() {
      if (this.isAllChecked) {
        this.selectedOption = [];
      } else {
        this.selectedOption = [
          ...this.modelOptions.map((option) => option.value),
        ];
      }
    },
    handleOptionClick(value, isDisabled) {
      if (isDisabled) return;

      if (value === 'all') {
        this.handleAllClick();
      }

      if (this.multipleSelect && value !== 'all') {
        this.updateSelectedOption(value);
      }

      if (!this.multipleSelect) {
        this.selectedOption = value;
      }

      if (!this.withControls) {
        this.handleSubmitClick();
      }
    },
    handleClickOutside() {
      if (this.isDropdownOpened) this.handleCancelClick();
      this.isDropdownOpened = false;
    },
    initSelectOption() {
      let selectedOption = this.modelValue;
      if (this.multipleSelect) {
        selectedOption = typeof this.modelValue === 'object' ? [...this.modelValue] : [this.modelValue];
      }

      this.selectedOption = selectedOption;
    },
  },
};
</script>

<style lang="scss">
.ui-dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;

  &__drop-button {
    border: 2px solid #eceef2;
    background: white;
    width: 100%;
    height: 48px;
    border-radius: 8px;
    outline: none;
    display: flex;
    align-items: center;
    padding: 13px 16px;
    font-weight: 500;
    font-size: 16px;
    // justify-content: space-between;
    font-family: $text-ibm-plex-sans;
  }

  &__drop-button:hover {
    border-color: #cad0dd;
  }

  &__drop-button:focus,
  &__drop-button--active {
    border-color: #4397cb;
  }

  &__drop-button-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: left;
  }

  &__drop-button-color-marker {
    display: block;
    width: 12px;
    min-width: 12px;
    height: 12px;
    border-radius: 2px;
    margin-right: 4px;
  }

  &__label {
    font-family: $text-ibm-plex-sans;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #000000A6;
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 22px;
    display: flex;
  }

  &__tooltip {
    margin-left: 4px;

    svg {
      fill: #ccc;
    }
  }

  &__option-list-wrapper {
    z-index: 10;
    width: 100%;
    min-width: 240px;
    position: absolute;
    top: calc(100% + 8px);
    box-shadow: 0px 4px 24px 0px #0000001a;
    border-radius: 8px;
    overflow: hidden;
    border: 2px solid #e5e5e5;
    background-color: white;
  }

  &__option-list-wrapper--top {
    top: auto;
    bottom: calc(100% + 8px);
  }

  &__option-list-wrapper--top-labeled {
    top: auto;
    bottom: calc(100% - 24px);
  }

  &__scrollbar {
    margin: 1px 0;
  }

  &__option-list {
    list-style-type: none;
    padding: 12px 8px;
    background-color: white;
    margin: 0;
  }

  &--with-controls &__option-list {
    border-bottom-width: 1px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__option-item-text {
    display: block;
    color: black;
    font-family: $text-ibm-plex-sans;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__option-item-content {
    padding: 15px 12px;
    cursor: pointer;
  }

  &__option-item-content--disabled {
    color: #C7C7CD;
  }

  &__option-item:hover {
    background-color: #f0f0f0;
    border-radius: 8px;
  }

  &__controls {
    background-color: white;
    border-top: 1px solid #e5e5e5;
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    overflow: hidden;
  }

  &__control-separator {
    background-color: #e5e5e5;
  }

  &__control-button {
    border: 0;
    background-color: transparent;
    height: 48px;
    outline: none;
    font-family: $text-ibm-plex-sans;
  }

  &__control-button:hover {
    background-color: #f5f5f5;
  }

  &__control-button:active {
    background-color: #dddddd;
  }

  &__control-button--submit {
    color: #2d83b8;
  }

  &__control-button--cancel {
    color: #ff4444;
  }

  &__icon {
    margin-left: 8px;
    height: 48px;
    display: inherit;
    align-items: center;
  }

  &__icon svg {
    fill: #abacac;
    transition: 0.2s;
  }

  &__drop-button--active &__icon svg {
    transform: rotate(-180deg);
  }
}
</style>
