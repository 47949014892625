import ILicenseTypeService from '@/module/product/service/contract/ILicenseTypeService';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import ELicenseType from '@/module/product/model/Product/Fields/LicenseType/ELicenseType';
import OptionException from '@/module/product/service/OptionException';
import ILicenseTypeOption from '@/module/product/model/Product/Fields/LicenseType/ILicenseTypeOption';

class LicenseTypeService implements ILicenseTypeService {
  private serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  get = (licenseType: ELicenseType): ILicenseTypeOption => {
    const result = this.list()
      .find((option) => option.id === licenseType);

    if (!result) {
      throw new OptionException('licenseType', licenseType);
    }

    return result;
  };

  list = (): ILicenseTypeOption[] => {
    const localisationRegistry = this.serviceContainer.localisationRegistry;

    return [
      {
        id: ELicenseType.NEW,
        title: localisationRegistry.productsOld.form.productType.initial,
      },
      {
        id: ELicenseType.RENEW,
        title: localisationRegistry.productsOld.form.productType.renewal,
      },
    ];
  };
}

export default LicenseTypeService;
