// TODO: Удалить решения с передачей query строкой.
//  Сбор строки запроса - обязанность сервиса апи.
export default function promotionModule(instance) {
  return {
    getPromotions(query, cancelToken) {
      return instance.get(`/vendor/promotion${query}`, { cancelToken });
    },
    getCard(id) {
      return instance.get(`/vendor/promotion/${id}`);
    },
    delete(id) {
      return instance.delete(`/vendor/promotion/${id}`);
    },
    setNewStatus(id, data) {
      return instance.patch(`/vendor/promotion/${id}`, data);
    },
  };
}
