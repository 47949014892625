import IFormDataToFulfillmentBackendModelMapper
  from '@/module/product/view/FulfillmentFormPage/model/contract/mapper/IFormDataToFulfillmentBackendModelMapper';
import IFulfillmentBackendModel
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/IFulfillmentBackendModel';
import IFormDataTestCreateGenerator
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataTestCreateGenerator';
import IFormDataSalesCreateGenerator
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataSalesCreateGenerator';
import EHTTPMethod from '@/module/common/models/EHTTPMethod';
import EFulfillmentAllsoftHTTPMethod
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/EFulfillmentAllsoftHTTPMethod';
import IFulfillmentParametersResponseModel
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/IFulfillmentParametersResponseModel';
import EGeneratorType from '@/module/product/view/FulfillmentFormPage/model/enums/EGeneratorType';
import { lowerCaseProtocol } from '@/module/product/view/CreateEditProductPage.func';
import IFulfillmentTestRequestBackendModel
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/IFulfillmentTestRequestBackendModel';

class FormDataToFulfillmentBackendModelMapper implements IFormDataToFulfillmentBackendModelMapper {
  map = (formData: IFormDataTestCreateGenerator | IFormDataSalesCreateGenerator): IFulfillmentBackendModel => {
    const methodsMap = {
      [EHTTPMethod.GET]: EFulfillmentAllsoftHTTPMethod.GET,
      [EHTTPMethod.POST]: EFulfillmentAllsoftHTTPMethod.POST_JSON,
    };

    const entity: IFulfillmentBackendModel = {
      type: EGeneratorType.WEB_SERVICE,
      webService: {
        method: methodsMap[formData.method.id],
        quantityKeysItemsMatches: formData.licenseAmount.id,
        secretKey: formData.secretKey,
        sendToCustomer: formData.sendLicense,
        url: lowerCaseProtocol(formData.url),
      },
    };

    if (formData.id) {
      entity.id = formData.id;
    }

    if (formData.parameters && formData.parameters.length > 0) {
      entity.webService.parameters = formData.parameters.map((paramItem) => ({
        name: paramItem.value.inputValue,
        value: (paramItem.value.selectValue as IFulfillmentParametersResponseModel).key,
      }));
    }

    if (entity.webService.sendToCustomer) {
      entity.customerNotification = formData.messageToUser;
    }

    return entity;
  };

  mapTestRequest = (
    formData: IFormDataTestCreateGenerator | IFormDataSalesCreateGenerator,
  ): IFulfillmentTestRequestBackendModel => {
    const methodsMap = {
      [EHTTPMethod.GET]: EFulfillmentAllsoftHTTPMethod.GET,
      [EHTTPMethod.POST]: EFulfillmentAllsoftHTTPMethod.POST_JSON,
    };

    const entity: IFulfillmentTestRequestBackendModel = {
      type: EGeneratorType.WEB_SERVICE,
      webService: {
        method: methodsMap[formData.method.id],
        quantityKeysItemsMatches: formData.licenseAmount.id,
        secretKey: formData.secretKey,
        url: lowerCaseProtocol(formData.url),
      },
    };

    if (formData.id) {
      entity.id = formData.id;
    }

    if (formData.parameters && formData.parameters.length > 0) {
      entity.webService.parameters = formData.parameters.map((paramItem) => ({
        name: paramItem.value.inputValue,
        value: (paramItem.value.selectValue as IFulfillmentParametersResponseModel).key,
      }));
    }

    return entity;
  };
}

export default FormDataToFulfillmentBackendModelMapper;
