<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 14 10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.00005 7.20002L2.50005 4.70002C2.40846 4.60656 2.29926 4.53219 2.17875 4.4812C2.05825 4.43021 1.92883 4.40362 1.79798 4.40296C1.66713 4.4023 1.53745 4.42758 1.41643 4.47735C1.29542 4.52712 1.18547 4.60039 1.09294 4.69291C1.00042 4.78544 0.927153 4.89539 0.877384 5.0164C0.827615 5.13742 0.80233 5.2671 0.802991 5.39795C0.803652 5.5288 0.830246 5.65822 0.881234 5.77872C0.932223 5.89923 1.00659 6.00843 1.10005 6.10002L4.29305 9.29302C4.48058 9.48049 4.73489 9.58581 5.00005 9.58581C5.26521 9.58581 5.51952 9.48049 5.70705 9.29302L13.3 1.70002C13.4826 1.51373 13.5843 1.26292 13.583 1.00209C13.5816 0.741261 13.4775 0.49149 13.293 0.307054C13.1086 0.122618 12.8588 0.0184198 12.598 0.0171025C12.3371 0.0157851 12.0863 0.117455 11.9 0.300019L5.00005 7.20002Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'CheckIcon',
  props: {
    width: {
      type: Number,
      default: 14,
    },
    height: {
      type: Number,
      default: 10,
    },
  },
};
</script>
