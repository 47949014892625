import { AxiosInstance, AxiosResponse } from 'axios';
import IRESTApiListFilter from '@/module/common/service/RESTApi/IRESTApiListFilter';
import IRESTApi from '@/module/common/service/RESTApi/contract/IRESTApi';

export default class RESTApi<T> implements IRESTApi<T> {
  readonly client: AxiosInstance;

  readonly route: string;

  constructor(client: AxiosInstance, route: string) {
    this.route = route;
    this.client = client;
  }

  async list<R>(filter?: IRESTApiListFilter | R): Promise<AxiosResponse<T[]>> {
    let url = `/${this.route}`;
    if (filter) {
      const query: string = Object.entries(filter)
        .map((pair) => {
          let stringPart: string = pair.join('=');
          if (Array.isArray(pair[1])) {
            stringPart = pair[1].map((arrayItem) => `${pair[0]}[]=${arrayItem}`).join('&');
          }

          return stringPart;
        })
        .join('&');

      url = `${url}?${query}`;
    }

    return this.client.get<T[]>(url);
  }

  async get(id: number | string, options = null): Promise<AxiosResponse<T>> {
    const parts = [
      `/${this.route}`,
      id,
    ].filter((part) => !!part);

    return this.client.get<T>(parts.join('/'), options ?? {});
  }

  async create(data?: T): Promise<AxiosResponse<T>> {
    return this.client.post<T>(`/${this.route}`, data ?? null);
  }

  async update(id: number, data: T): Promise<AxiosResponse<T>> {
    return this.client.patch<T>(`/${this.route}/${id}`, data);
  }

  async replace(id: number, data: T): Promise<AxiosResponse<T>> {
    return this.client.put<T>(`/${this.route}/${id}`, data);
  }

  async delete(id: number): Promise<AxiosResponse> {
    return this.client.delete(`/${this.route}/${id}`);
  }
}
