// eslint-disable-next-line max-len
import IFormDataMapper from '@/module/merchant/view/AccountActivatePage/DataMapper/IFormDataMapper';
import IFormFieldItem from '@/module/design-system/components/models/FormComponent/IFormFieldItem';
import EFormFieldType from '@/module/design-system/components/models/FormComponent/EFormFieldType';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import IUtils from '@/plugins/utils/IUtils';
import IPageState from '@/module/merchant/view/AccountActivatePage/models/IPageState';
import EFieldName from '@/module/merchant/view/AccountActivatePage/models/EFieldName';
import EFieldBlock from '@/module/merchant/view/AccountActivatePage/models/EFieldBlock';
import TFromDataDraft
  from '@/module/merchant/view/AccountActivatePage/models/Draft/TFromDataDraft';

class FormDataToDraftMapper implements IFormDataMapper {
  private readonly state: IPageState;

  private readonly serviceContainer: IServiceContainer;

  constructor(
    state: IPageState,
    serviceContainer: IServiceContainer,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
  }

  mapFieldValue(
    formFieldConfigItem: IFormFieldItem<EFieldName, EFieldBlock>,
    data: TFromDataDraft,
  ) {
    const utils = this.serviceContainer.resolve<IUtils>(EServiceContainerInstances.UTILS);

    const { fieldBlock, fieldName, type } = formFieldConfigItem;

    let value = this.state.formData[fieldBlock]?.[fieldName];

    if ([undefined, null].includes(value)) {
      return data;
    }

    if (![undefined, null].includes(value) && type === EFormFieldType.DATE) {
      value = utils.date.getDateStringFromDate(value);
    }

    data[fieldBlock][fieldName] = value ?? '';

    return data;
  }
}

export default FormDataToDraftMapper;
