export default (serviceContainer) => ({
  namespaced: true,
  state: {
    currencyList: null,
  },
  mutations: {
    setCurrencyList(state, newList) {
      state.currencyList = newList;
    },
  },
  getters: {
    getCurrencyList(state) {
      return state.currencyList;
    },
    getIsCurrencyListLoaded(state) {
      return !!state.currencyList;
    },
  },
  actions: {
    async loadCurrencyList({ commit }) {
      try {
        const api = serviceContainer.resolve('api');

        const response = await api.reports.getCurrency();
        const currencyList = response.data.map((currency) => {
          currency.code = `currencies.${currency.code}`;
          return currency;
        });
        commit('setCurrencyList', currencyList);
        // eslint-disable-next-line no-empty
      } catch {
      }
    },
  },
});
