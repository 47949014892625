<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="48"
      height="48"
      rx="24"
      :fill="color"
    />
    <!-- eslint-disable vue/max-len max-len -->
    <path
      d="M22.3484 27.48V24.36C23.415 24.32 24.2217 24.12 24.7684 23.76C25.3284 23.3867 25.6084 22.8133 25.6084 22.04V21.76C25.6084 21.2 25.4417 20.7667 25.1084 20.46C24.775 20.1533 24.3284 20 23.7684 20C23.1684 20 22.6817 20.18 22.3084 20.54C21.9484 20.8867 21.695 21.34 21.5484 21.9L19.4084 21.02C19.5284 20.6067 19.7017 20.2067 19.9284 19.82C20.155 19.4333 20.4484 19.0933 20.8084 18.8C21.1684 18.4933 21.6017 18.2533 22.1084 18.08C22.615 17.8933 23.2017 17.8 23.8684 17.8C24.5484 17.8 25.1617 17.9 25.7084 18.1C26.255 18.2867 26.7217 18.56 27.1084 18.92C27.495 19.2667 27.795 19.6867 28.0084 20.18C28.2217 20.6733 28.3284 21.22 28.3284 21.82C28.3284 22.4067 28.2284 22.9333 28.0284 23.4C27.8417 23.8533 27.5817 24.2533 27.2484 24.6C26.915 24.9333 26.535 25.2067 26.1084 25.42C25.6817 25.62 25.2417 25.76 24.7884 25.84V27.48H22.3484ZM23.5884 32.24C23.0284 32.24 22.6217 32.1067 22.3684 31.84C22.115 31.5733 21.9884 31.2333 21.9884 30.82V30.42C21.9884 30.0067 22.115 29.6667 22.3684 29.4C22.6217 29.1333 23.0284 29 23.5884 29C24.1617 29 24.575 29.1333 24.8284 29.4C25.0817 29.6667 25.2084 30.0067 25.2084 30.42V30.82C25.2084 31.2333 25.0817 31.5733 24.8284 31.84C24.575 32.1067 24.1617 32.24 23.5884 32.24Z"
      fill="white"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSQuestionIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>

<style scoped>

</style>
