export default function commonModule(instance) {
  return {
    setDraft(payload) {
      return instance.post('common/form-data/draft', payload);
    },
    refreshDraft(draftName) {
      return instance.get(`common/form-data/draft/${draftName}`);
    },
    getCountryList() {
      return instance.get('common/country').then((response) => {
        if (!response.data) {
          return Promise.resolve(response);
        }

        response.data = response.data.filter((country) => !!country.name);

        return Promise.resolve(response);
      });
    },
  };
}
