import {
  AxiosError,
} from 'axios';

import IPaymentLinkPage from '@/module/product/view/PaymentLink/PaymentLinkPage/model/contract/IPaymentLinkPage';
import IState from '@/module/product/view/PaymentLink/PaymentLinkPage/model/interface/IState';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IVueInstance from '@/module/common/models/IVueInstance';
import EGridTitleKey from '@/module/product/view/PaymentLink/PaymentLinkPage/model/enum/EGridTitleKey';
import IGridItem from '@/module/product/view/PaymentLink/PaymentLinkPage/model/interface/IGridItem';
import IListRequestOptions from '@/module/product/view/PaymentLink/PaymentLinkPage/model/interface/IListRequestOptions';

class PaymentLinkPage implements IPaymentLinkPage {
  private readonly state: IState;

  private readonly serviceContainer: IServiceContainer;

  private readonly vueInstance: IVueInstance;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    vueInstance: IVueInstance,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.vueInstance = vueInstance;
  }

  refreshPaymentLinkList = async () => {
    const apiService = this.serviceContainer.apiService;
    const apiPaymentLink = apiService.paymentLink;

    try {
      const options: IListRequestOptions = {
        limit: this.state.limit,
        offset: 0,
        sortByCreateDate: this.state.filters.orderBy.id,
      };

      if (this.state.filters.searchFilter.length > 0) {
        options.searchString = this.state.filters.searchFilter;
      }

      const response = await apiPaymentLink.list(options);

      this.state.offset = response.data.list.length;
      this.state.paymentLinkList = response.data.list ?? [];
      this.state.paymentLinkTotalCount = response.data.totalCount ?? 0;
    } catch (e) {
      const error = e as AxiosError;

      this.state.isError = true;
      this.state.responseStatus = error.response?.status ?? 200;
    }
  };

  updatePaymentLinkList = async () => {
    if (this.state.isUpdating) {
      return;
    }

    const apiService = this.serviceContainer.apiService;
    const apiPaymentLink = apiService.paymentLink;

    this.state.isUpdating = true;

    try {
      const options: IListRequestOptions = {
        limit: this.state.limit,
        offset: this.state.offset,
        sortByCreateDate: this.state.filters.orderBy.id,
      };

      if (this.state.filters.searchFilter.length > 0) {
        options.searchString = this.state.filters.searchFilter;
      }

      const response = await apiPaymentLink.list(options);
      const updatedList = this.state.paymentLinkList.concat(response.data.list ?? []);

      this.state.offset = updatedList.length;
      this.state.paymentLinkList = updatedList;
      this.state.paymentLinkTotalCount = response.data.totalCount ?? 0;
    } catch (e) {
      const error = e as AxiosError;

      this.state.isError = true;
      this.state.responseStatus = error.response?.status ?? 200;
    } finally {
      this.state.isUpdating = false;
    }
  };

  getGridItems = (isMobile): IGridItem[] => {
    const gridItemsDesktop = [
      {
        key: EGridTitleKey.NAME,
      },
      {
        key: EGridTitleKey.AMOUNT,
      },
      {
        key: EGridTitleKey.STATUS,
      },
      {
        key: EGridTitleKey.CREATED_AT,
      },
      {
        key: EGridTitleKey.CONTROL,
      },
      {
        key: EGridTitleKey.PAYMENT_LINK,
      },
    ];

    const gridItemsMobile = [
      {
        key: EGridTitleKey.NAME,
      },
      {
        key: EGridTitleKey.CONTROL,
      },
      {
        key: EGridTitleKey.COMPUTED_INFO,
      },
      {
        key: EGridTitleKey.PAYMENT_LINK,
      },
    ];

    return isMobile ? gridItemsMobile : gridItemsDesktop;
  };
}

export default PaymentLinkPage;
