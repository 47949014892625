import getButton, {
  getButtonWithIcon,
} from '@/module/common/helpers/getButton';

import EDSButtonVariant from '@/module/design-system/models/UI/DSButton/EDSButtonVariant';
import EIcon from '@/module/design-system/components/models/EIcon';
import EIconColorName from '@/module/design-system/components/models/EIconColorName';

import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IHeaderRowService from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/contract/IHeaderRowService';
import ICFormPageHeaderRow from '@/module/common/models/CFormPageHeaderRow/ICFormPageHeaderRow';

import TNavigationItem from '@/module/common/models/CFormPageHeaderRow/TNavigationItem';

class HeaderRowService implements IHeaderRowService {
  private serviceContainer: IServiceContainer;

  constructor(
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
  }

  private getNavigationLeft = (): TNavigationItem[] => {
    const formHandler = this.serviceContainer.moduleProduct.paymentLinkFormPage.formHandler;

    return [
      getButtonWithIcon(
        {
          text: this.serviceContainer.localisationRegistry.common.cancel,
          variant: EDSButtonVariant.TRETIARY,
        },
        () => formHandler.handleClickCancel(),
        {
          icon: EIcon.CROSS,
          color: EIconColorName.LINKED,
        },
      ),
    ];
  };

  private getNavigationRight = (): TNavigationItem[] => {
    const formHandler = this.serviceContainer.moduleProduct.paymentLinkFormPage.formHandler;

    return [
      getButton(
        {
          text: this.serviceContainer.localisationRegistry.common.save,
          variant: EDSButtonVariant.PRIMARY,
        },
        () => formHandler.handleClickSave(),
      ),
    ];
  };

  getHeaderRowData = (): ICFormPageHeaderRow => ({
    pageTitle: this.serviceContainer.localisationRegistry.paymentLinkForm.title,
    navigationLeft: this.getNavigationLeft(),
    navigationRight: this.getNavigationRight(),
  });
}

export default HeaderRowService;
