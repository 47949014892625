
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';
import ESize from '@/module/design-system/components/models/ESize';
import EColorScheme from '@/module/design-system/components/models/EColorScheme';

export default defineComponent({
  name: 'DSSubstrate',
  props: {
    stretch: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<ESize>,
      default: () => ESize.S,
    },
    color: {
      type: String as PropType<EColorScheme>,
      default: () => EColorScheme.DEFAULT,
    },
    borderColor: {
      type: String as PropType<EColorScheme>,
      default: () => EColorScheme.NONE,
    },
    borderRadius: {
      type: String as PropType<ESize>,
      default: () => ESize.L,
    },
  },
  setup(props) {
    const classes = computed(() => [
      'ds-substrate',
      `ds-substrate_size_${props.size}`,
      props.borderColor !== EColorScheme.NONE ? `ds-substrate_border-color_${props.borderColor}` : null,
      `ds-substrate_border-radius_${props.borderRadius}`,
      props.stretch ? 'ds-substrate_stretch' : 'ds-substrate_not-stretch',
      `ds-substrate_color_${props.color}`,
    ]);

    return {
      classes,
    };
  },
});
