
import {
  defineComponent,
  computed,
  PropType,
} from 'vue';
import EColorScheme from '@/module/design-system/components/models/EColorScheme';

export default defineComponent({
  name: 'DSHeader',
  props: {
    size: {
      type: [
        Number,
        String,
      ],
      default: 1,
    },
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String as PropType<EColorScheme>,
      default: EColorScheme.PRIMARY,
    },
  },
  setup(props, { slots }) {
    const tag = computed(() => `h${props.size}`);
    const classes = computed(() => ([
      'ds-header',
      `ds-header_h${props.size}`,
      `ds-header_color_${props.color}`,
    ]));

    const isDefaultSlot = computed(() => !!slots.default);

    return {
      tag,
      classes,
      isDefaultSlot,
    };
  },
});
