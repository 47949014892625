
import Vue, {
  computed,
  reactive,
} from 'vue';

import EFormFieldType from '@/module/design-system/components/models/FormComponent/EFormFieldType';
import {
  EConditionType,
} from '@/module/common/helpers/checkCondition';
import EDSButtonSize from '@/module/design-system/models/UI/DSButton/EDSButtonSize';
import EDSButtonVariant from '@/module/design-system/models/UI/DSButton/EDSButtonVariant';
import EIcon from '@/module/design-system/components/models/EIcon';
import EIconColorName from '@/module/design-system/components/models/EIconColorName';

import cloneImmutable from '@/module/common/utils/data/cloneImmutable';

import COverviewPageLayout from '@/module/common/components/PageParts/COverviewPageLayout.vue';
import COverviewPageHeaderRow from '@/module/common/components/PageParts/COverviewPageHeaderRow.vue';
import CFormComponent from '@/module/common/components/CFormComponent.vue';

export default Vue.extend({
  name: 'FormComponentDemoPage',
  components: {
    CFormComponent,
    COverviewPageHeaderRow,
    COverviewPageLayout,
  },
  setup() {
    const state = reactive({
      formData: {},
      filteredSelectSearch: '',
      expandableListSearch: '',
    });

    const selectList = [
      {
        id: 1,
        title: 'Option 1',
      },
      {
        id: 2,
        title: 'Option 2',
      },
      {
        id: 3,
        title: 'Option 3',
      },
      {
        id: 4,
        title: 'Option 4',
      },
      {
        id: 5,
        title: 'Option 5',
      },
      {
        id: 6,
        title: 'Option 6',
      },
    ];

    function filterSelectList() {
      if (state.filteredSelectSearch.length > 0) {
        return selectList.filter((item) => item.title.includes(state.filteredSelectSearch));
      }

      return selectList;
    }

    const buttonsGroup = [
      {
        id: 1,
        title: 'Button 1',
      },
      {
        id: 2,
        title: 'Button 2',
      },
      {
        id: 3,
        title: 'Button 3',
      },
    ];

    const checkboxGroup = [
      {
        id: 1,
        title: 'Checkbox 1',
        value: false,
      },
      {
        id: 2,
        title: 'Checkbox 2',
        value: false,
      },
      {
        id: 3,
        title: 'Checkbox 3',
        value: false,
      },
      {
        id: 4,
        title: 'Checkbox 4',
        value: true,
      },
      {
        id: 5,
        title: 'Checkbox 5',
        value: false,
      },
    ];

    const formConfig = computed(() => ([
      {
        header: 'Input',
        items: [
          {
            id: 1,
            type: EFormFieldType.INPUT,
            fieldName: EFormFieldType.INPUT,
            label: 'Label',
            placeholder: 'Placeholder',
            description: {
              default: 'Description',
            },
          },
        ],
      },
      {
        header: 'Masked Input',
        items: [
          {
            id: 2,
            type: EFormFieldType.MASKED_INPUT,
            fieldName: EFormFieldType.MASKED_INPUT,
            label: 'Label',
            placeholder: 'Placeholder',
            description: {
              default: 'Description',
            },
            maskOptions: {
              mask: '+7 (000) 00-00-000',
            },
            inputType: 'phone',
          },
        ],
      },
      {
        header: 'Textarea',
        items: [
          {
            id: 3,
            type: EFormFieldType.TEXT_AREA,
            fieldName: EFormFieldType.TEXT_AREA,
            label: 'Label',
            placeholder: 'Placeholder',
            description: {
              default: 'Description',
            },
          },
        ],
      },
      {
        header: 'Select',
        items: [
          {
            id: 4,
            type: EFormFieldType.SELECT,
            fieldName: EFormFieldType.SELECT,
            label: 'Label',
            placeholder: 'Placeholder',
            description: {
              default: 'Description',
            },
            select: {
              idField: 'id',
              titleField: 'title',
              list: selectList,
            },
          },
        ],
      },
      {
        header: 'Filtered Select',
        items: [
          {
            id: 5,
            type: EFormFieldType.FILTERED_SELECT,
            fieldName: EFormFieldType.FILTERED_SELECT,
            label: 'Label',
            placeholder: 'Placeholder',
            description: {
              default: 'Description',
            },
            select: {
              idField: 'id',
              titleField: 'title',
              list: filterSelectList(),
            },
          },
        ],
      },
      {
        header: 'Button Group',
        items: [
          {
            id: 6,
            type: EFormFieldType.BUTTON_GROUP,
            fieldName: `${EFormFieldType.BUTTON_GROUP}-1`,
            label: 'Label. Variant: spaced',
            description: {
              default: 'Description',
            },
            variant: 'spaced',
            buttonList: buttonsGroup,
            default: buttonsGroup.find((button) => button.id === 1),
          },
          {
            id: 7,
            type: EFormFieldType.BUTTON_GROUP,
            fieldName: `${EFormFieldType.BUTTON_GROUP}-2`,
            label: 'Label. Variant: stuck',
            variants: [
              {
                value: 'Description variant 1',
                conditionsList: [
                  {
                    type: EConditionType.EQUALS,
                    field: `${EFormFieldType.BUTTON_GROUP}-2.id`,
                    value: 1,
                  },
                ],
              },
              {
                value: 'Description variant 2',
                conditionsList: [
                  {
                    type: EConditionType.EQUALS,
                    field: `${EFormFieldType.BUTTON_GROUP}-2.id`,
                    value: 2,
                  },
                ],
              },
              {
                value: 'Description variant 3',
                conditionsList: [
                  {
                    type: EConditionType.EQUALS,
                    field: `${EFormFieldType.BUTTON_GROUP}-2.id`,
                    value: 3,
                  },
                ],
              },
            ],
            variant: 'stuck',
            buttonList: buttonsGroup,
            default: buttonsGroup.find((button) => button.id === 1),
          },
        ],
      },
      {
        header: 'Switcher',
        items: [
          {
            id: 8,
            type: EFormFieldType.SWITCHER,
            fieldName: `${EFormFieldType.SWITCHER}-1`,
            label: 'Label. Default',
            description: {
              default: 'Description',
            },
          },
          {
            id: 9,
            type: EFormFieldType.SWITCHER,
            fieldName: `${EFormFieldType.SWITCHER}-2`,
            label: 'Label. withHeader',
            description: {
              default: 'Description',
            },
            switcher: {
              withHeader: true,
            },
          },
        ],
      },
      {
        header: 'Checkbox',
        items: [
          {
            id: 10,
            type: EFormFieldType.CHECKBOX,
            fieldName: EFormFieldType.CHECKBOX,
            label: 'Label',
            description: {
              default: 'Description',
            },
            default: false,
          },
        ],
      },
      {
        header: 'Checkbox Group',
        items: [
          {
            id: 11,
            type: EFormFieldType.CHECKBOX_GROUP,
            fieldName: EFormFieldType.CHECKBOX_GROUP,
            label: 'Label',
            description: {
              default: 'Description',
            },
            checkboxGroup: {
              list: checkboxGroup,
            },
          },
        ],
      },
      {
        header: 'File Upload',
        items: [
          {
            id: 12,
            type: EFormFieldType.FILE_UPLOAD,
            fieldName: EFormFieldType.FILE_UPLOAD,
            label: 'Label',
            description: {
              default: 'Description',
            },
            fileUpload: {
              options: {
                button: {
                  variant: EDSButtonVariant.SECONDARY,
                  text: 'File upload button text',
                  size: EDSButtonSize.SMALL,
                },
                icon: {
                  icon: EIcon.ATTACH,
                  color: EIconColorName.LINKED,
                },
                maxFileSize: 15,
                maxFilesSize: 100,
                description: 'File Upload Description',
              },
            },
          },
        ],
      },
      {
        header: 'Date',
        items: [
          {
            id: 13,
            type: EFormFieldType.DATE,
            fieldName: EFormFieldType.DATE,
            label: 'Label',
            placeholder: 'Placeholder',
            description: {
              default: 'Description',
            },
          },
        ],
      },
      {
        header: 'Input List',
        items: [
          {
            id: 14,
            type: EFormFieldType.INPUT_LIST,
            fieldName: EFormFieldType.INPUT_LIST,
            label: 'Label',
            placeholder: 'Placeholder',
            description: {
              default: 'Description',
            },
            inputList: {
              maxItems: 6,
              addButtonText: 'Input list add button text',
              placeholder: 'Input list field placeholder',
              inputDelay: 500,
              statuses: [],
            },
            default: [
              {
                value: '',
              },
            ],
          },
        ],
      },
      {
        header: 'Expandable List',
        items: [
          {
            id: 15,
            type: EFormFieldType.EXPANDABLE_LIST,
            fieldName: EFormFieldType.EXPANDABLE_LIST,
            label: 'Label',
            placeholder: 'Placeholder',
            description: {
              default: 'Description',
            },
            emptyListMessage: 'Empty List Message',
            expandableList: {
              idField: 'id',
              titleField: 'title',
              list: selectList,
              selectedItemList: state.formData[EFormFieldType.EXPANDABLE_LIST],
              quantityInputDelay: 500,
              addButtonText: 'Expandable List Add Button',
            },
          },
        ],
      },
    ]));

    function handleFormFieldBlur(item) {
      const { field, value } = item;
      const formData = cloneImmutable(state.formData);

      formData[field.fieldName] = value;

      state.formData = cloneImmutable(formData);
    }

    function handleInputFilteredSelectSearch({ data }) {
      state.filteredSelectSearch = data;
    }

    function handleAddItemExpandableList() {
      console.log('handleAddItemExpandableList');
    }
    function handleRemoveItemExpandableList() {
      console.log('handleRemoveItemExpandableList');
    }
    function handleInputExpandableListSearch({ data }) {
      state.expandableListSearch = data;
    }

    return {
      state,
      formConfig,
      handleFormFieldBlur,
      handleAddItemExpandableList,
      handleRemoveItemExpandableList,
      handleInputExpandableListSearch,
      handleInputFilteredSelectSearch,
    };
  },
});
