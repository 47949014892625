<template>
  <div
    ref="rootRef"
    v-click-outside="handleOutsideClick"
    :class="dropdownClass"
  >
    <template v-if="label">
      <div class="dropdown__label">
        {{ label }}
      </div>
    </template>
    <div class="dropdown__body">
      <input
        :class="dropdownInputClass"
        :placeholder="placeholder"
        @click="toggle"
        @input="handleInput"
      >
      <div :class="dropdownIconClass">
        <DSIcon
          icon="arrowDown"
        />
      </div>
    </div>
    <transition name="fade">
      <div
        v-show="isShowOptions"
        ref="optionsRef"
        :class="dropdownOptionsClass"
      >
        <perfect-scrollbar
          ref="scrollRef"
          @touchmove.stop
        >
          <ul :class="dropdownOptionsListClass">
            <template
              v-for="option in options"
            >
              <li
                :key="optionId(option)"
                :class="dropdownOptionClass"
              >
                <a
                  href="#"
                  :class="itemLinkClasses(option)"
                  @click.prevent="handleOptionClick(option)"
                >
                  {{
                    modelValueCount
                      ? isLocalized
                        ? $tc(optionValue(option), modelValueCount)
                        : option.name
                      : isLocalized
                        ? $t(optionValue(option))
                        : option
                  }}
                  <span
                    v-if="option.disabled"
                    class="dropdown-options-list-item__added"
                  >
                    {{ disabledItemText }}
                  </span>
                </a>
              </li>
            </template>
            <IntersectionObserver
              class="dropdown__options--loading"
              @intersecting="handleLoadMore"
            >
              <PreloaderComponent
                v-if="totalCount > options.length"
                height="30px"
                width="30px"
              />
            </IntersectionObserver>
          </ul>
        </perfect-scrollbar>
      </div>
    </transition>
    <p
      v-if="errorMessage"
      class="search-dropdown__error"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import { mapGetters } from 'vuex';

import PreloaderComponent from '@/components/Preloader/PreloaderComponent.vue';
import IntersectionObserver from '@/components/IntersectionObserver.vue';

const MAX_OPTIONS_HEIGHT = 600;
const MIN_OPTIONS_FOR_SEARCH = 10;

export default {
  name: 'SearchDropdown',
  components: {
    DSIcon,
    PreloaderComponent,
    IntersectionObserver,
  },
  props: {
    selectHandler: {
      type: Function,
      default: null,
    },
    isLoading: {
      type: Boolean,
    },
    isLocalized: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: [String, Array],
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    modelValueCount: {
      type: String || Number,
      default: null,
    },
    optionLabel: {
      type: String,
      default: 'title',
    },
    label: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Не выбрано',
    },
    disabledItemText: {
      type: String,
      default: 'Добавлено',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    blockDropTop: {
      type: Boolean,
      default: false,
    },
    isDropToTopBlocked: {
      type: Boolean,
      default: false,
    },
    checkboxed: {
      type: Boolean,
      default: false,
    },
    addProducts: {
      type: Function,
      default: null,
    },
    totalCount: {
      type: Number,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isShowOptions: false,
      isDropdownTop: false,
      multipleSelected: [],
      visibleOptions: [],
      searchValue: '',
      offsetTop: 0,
    };
  },
  computed: {
    ...mapGetters({
      isRequestRunning: 'createEditPromotionStore/getIsRequestRunning',
    }),
    dropdownClass() {
      return ['dropdown', this.small ? 'dropdown__small' : 'dropdown__medium'];
    },
    dropdownIconClass() {
      return [
        'dropdown-input__icon',
        { 'dropdown-input__icon_reversed': this.isShowOptions },
      ];
    },
    dropdownOptionsClass() {
      let isBottom = true;
      if (document.body.offsetHeight / 2 > this.offsetTop) isBottom = false;
      return [
        'dropdown__options',
        'dropdown-options',
        isBottom
          ? 'dropdown__options_top'
          : 'dropdown__options_bottom',
      ];
    },
    hasOptions() {
      return !!this.options.length;
    },
    hasScroll() {
      return this.visibleOptions.length > MIN_OPTIONS_FOR_SEARCH;
    },
    inputValue() {
      if (Array.isArray(this.modelValue)) {
        return this.modelValue
          .map((i) => (typeof i === 'string' ? this.$t(i) : i[this.optionLabel]))
          .join(', ');
      }
      if (typeof this.modelValue === 'object' && this.modelValue !== null) {
        const obj = this.modelValue;
        return obj[this.optionLabel];
      }
      return this.modelValue;
    },
    dropdownInputClass() {
      return [
        { 'dropdown-input__opened': this.isShowOptions.value },
        'dropdown__input',
        'dropdown-input',
        this.errorMessage ? 'dropdown-input__error' : '',
      ];
    },
    dropdownOptionsListClass() {
      return [
        'dropdown-options__list',
        'dropdown-options-list',
        this.hasScroll ? 'dropdown-options-list__scrolled' : '',
      ];
    },
    dropdownOptionClass() {
      return [
        'dropdown-options-list__item',
        'dropdown-options-list-item',
        { 'dropdown-options-list-item__multiple': this.multiple },
      ];
    },
  },
  watch: {
    searchValue() {
      this.handleUpdateSelected();
    },
    modelValue() {
      this.handleUpdateSelected();
    },
  },
  mounted() {
    this.offsetTop = this.$el.offsetTop;
    this.isDropdownTop = this.calculateOffset() < MAX_OPTIONS_HEIGHT - 100;
    this.visibleOptions = [...this.options];
    this.multipleSelected = Array.isArray(this.modelValue)
      ? [...this.modelValue]
      : [];
    this.$emit('mounted');
  },
  methods: {
    optionId(option) {
      return typeof option === 'string' ? option : option.id;
    },
    itemLinkClasses(option) {
      return [
        'dropdown-options-list-item__link',
        option.disabled ? 'dropdown-options-list-item__link--disabled' : '',
      ];
    },
    handleClear() {
      if (this.multiple) {
        this.multipleSelected.value = [];
        this.$emit('update:modelValue', []);
      } else {
        this.$emit('update:modelValue', '');
      }
    },
    clearDropDown() {
      if (!this.multipleSelected.length) {
        this.visibleOptions = [...this.options];
        this.searchValue = '';
      }
    },
    scrollToTop() {
      if (this.scrollRef.value) {
        this.scrollRef.value.$el.scrollTop = 0;
      }
    },
    sortOptions() {
      if (
        this.isShowOptions.value
        && this.visibleOptions.value.length
        && this.multiple
        && Array.isArray(this.modelValue)
      ) {
        const modelValue = this.modelValue;
        return this.visibleOptions.value.sort((a, b) => {
          if (typeof a === 'string' && typeof b === 'string') {
            return (
              Number(this.modelValue.includes(b))
              - Number(this.modelValue.includes(a))
            );
          }
          if (typeof a === 'object' && typeof b === 'object') {
            return (
              Number(
                !!modelValue.filter(
                  (i) => i[this.optionLabel] === b[this.optionLabel],
                ).length,
              )
              - Number(
                !!modelValue.filter(
                  (i) => i[this.optionLabel] === a[this.optionLabel],
                ).length,
              )
            );
          }
          return 1;
        });
      }
      return false;
    },
    toggle() {
      this.isShowOptions = !this.isShowOptions;
      this.searchValue = '';

      const wrapper = document.querySelector('.app-layout__content.ps');
      this.isDropdownTop = wrapper.clientHeight
          + wrapper.scrollTop
          - this.$refs.rootRef.offsetTop
        < 400;
      // this.nextTick(this.scrollToTop)
    },
    handleOptionClick(option) {
      this.selectHandler(option);
      this.isShowOptions = false;
    },
    handleCheckOption(event) {
      this.$emit('update:modelValue', event);
    },
    handleOutsideClick() {
      this.isShowOptions = false;
      setTimeout(this.clearDropDown, 500);
    },
    calculateOffset() {
      const body = document.querySelector('body');
      const windowHeight = body ? body.clientHeight : 0;
      const elemOffset = this.$refs.rootRef
        ? this.$refs.rootRef.getBoundingClientRect().y
        : 0;
      return windowHeight - elemOffset;
    },
    handleUpdateSearch() {
      this.visibleOptions.value = this.options.filter((option) => {
        if (typeof option === 'string') {
          return option
            .toLowerCase()
            .includes(this.searchValue.value.toLowerCase());
        }
        if (typeof option[this.optionLabel] === 'string') {
          const optionLabel = option[this.optionLabel];
          return optionLabel
            .toLowerCase()
            .includes(this.searchValue.value.toLowerCase());
        }
        return true;
      });
    },
    handleClearSearch() {
      this.searchValue.value = '';
      setTimeout(this.sortOptions, 0);
    },
    handleUpdateSelected() {
      if (
        this.multiple
        && !this.modelValue.length
        && this.isShowOptions.value
      ) {
        this.isShowOptions.value = false;
      }
      if (!this.modelValue && this.isShowOptions.value) {
        this.isShowOptions.value = false;
      }
    },
    optionValue(option) {
      return typeof option === 'string' ? option : option[this.optionLabel];
    },
    async handleLoadMore() {
      if (this.isRequestRunning) return;
      await this.addProducts();
    },
    handleInput(event) {
      this.$emit('update:value', event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";

.search-dropdown__error {
  padding-top: 4px;
  color: #FF4444;
}
</style>
