import ITabValidatorStatusHandler
  from '@/module/merchant/view/AccountActivatePage/Validator/ITabValidatorStatusHandler';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import IFormFieldItem from '@/module/design-system/components/models/FormComponent/IFormFieldItem';
import EFieldName from '@/module/merchant/view/AccountActivatePage/models/EFieldName';
import EFieldBlock from '@/module/merchant/view/AccountActivatePage/models/EFieldBlock';
import TFieldStatuses
  from '@/module/merchant/view/AccountActivatePage/models/TFieldStatuses';
import ETabStatus
  from '@/module/merchant/view/AccountActivatePage/models/ETabStatus';

class EmptyTabValidatorStatusHandler implements ITabValidatorStatusHandler {
  // eslint-disable-next-line class-methods-use-this
  changeStatus(
    fieldConfig: IFormFieldItem<EFieldName, EFieldBlock>,
    formFieldStatusList: TFieldStatuses,
    actualStatus: ETabStatus,
  ): ETabStatus {
    if (actualStatus === ETabStatus.ERROR) {
      return actualStatus;
    }

    const fieldStatusCode = formFieldStatusList[fieldConfig.fieldBlock][fieldConfig.fieldName].code;

    if (fieldStatusCode === EValidationFieldStatusCode.ERROR) {
      return ETabStatus.ERROR;
    }
    if (fieldStatusCode === EValidationFieldStatusCode.EMPTY) {
      return ETabStatus.DEFAULT;
    }

    return actualStatus;
  }
}

export default EmptyTabValidatorStatusHandler;
