export default function performance(instance) {
  return {
    getPerformanceData(data) {
      const parts = [
        'dimension=currency',
        `date[dateFrom]=${data.date.dateFrom}`,
        `date[dateTo]=${data.date.dateTo}`,
        data.compare ? `compare[dateFrom]=${data.compare.dateFrom}` : null,
        data.compare ? `compare[dateTo]=${data.compare.dateTo}` : null,
      ];

      const query = parts.filter((part) => !!part).join('&');

      return instance.get(`/vendor/report/performance/table?${query}`);
    },
  };
}
