export default function linkUtility() {
  return {
    generateLinkHref(link, type = 'url', parameters = {}) {
      let hrefString;

      switch (type) {
        case 'email':
          hrefString = `mailto:${link}`;
          break;
        case 'tel':
          hrefString = `tel:${link}`;
          break;
        default:
          hrefString = link;
          break;
      }

      if (Object.keys(parameters).length > 0) {
        const parametersString = Object.entries(parameters)
          .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
          .join('&');

        hrefString += `?${parametersString}`;
      }

      return hrefString;
    },
    localizeURL(link, locale = 'en') {
      return link.replace('en-en', `${locale}-${locale}`);
    },
  };
}
