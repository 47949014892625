
import {
  computed,
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'CTextWrapper',
  props: {
    hiddenText: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const classes = computed(() => [
      'с-text-wrapper',
      props.hiddenText ? 'с-text-wrapper_hidden' : null,
    ]);

    return {
      classes,
    };
  },
});
