
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import Vue, { inject } from 'vue';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';

export default Vue.extend({
  name: 'ProductIconComponent',
  components: {
    DSIcon,
  },
  props: {
    url: {
      type: String,
      default: () => null,
    },
    title: {
      type: String,
      default: () => null,
    },
    error: {
      type: String,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;
    const localisationRegistry = serviceContainer.localisationRegistry;

    const placeholder = localisationRegistry.productsOld.form.photo;

    function handleFileUpload(event: InputEvent) {
      emit('change', event);
    }

    return {
      placeholder,
      handleFileUpload,
    };
  },
});
