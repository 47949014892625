import IHttpMethodHandler from '@/module/product/view/FulfillmentFormPage/model/contract/handler/IHttpMethodHandler';
import IHTTPMethod from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IHTTPMethod';
import EHTTPMethod from '@/module/common/models/EHTTPMethod';
import IState from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/IState';
import ILocalServiceContainer from '@/module/product/view/FulfillmentFormPage/model/ILocalServiceContainer';
import IHandlerService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IHandlerService';
import ELocalServiceFulfillment from '@/module/product/view/FulfillmentFormPage/model/enums/ELocalServiceFulfillment';
import IFormDataSalesCreateGenerator
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataSalesCreateGenerator';
import IFormDataTestCreateGenerator
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataTestCreateGenerator';

class HttpMethodHandler implements IHttpMethodHandler {
  private readonly state: IState;

  private readonly localServiceContainer: ILocalServiceContainer;

  constructor(
    state: IState,
    localServiceContainer: ILocalServiceContainer,
  ) {
    this.state = state;
    this.localServiceContainer = localServiceContainer;
  }

  getId = (option: IHTTPMethod): EHTTPMethod => option.id;

  getName = (option: IHTTPMethod): string => option.title;

  getTitle = (option: IHTTPMethod): string => option.title;

  isChecked = (option: IHTTPMethod): boolean => {
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const formDataHandler = handlerService.getFormDataHandler();

    const formData = formDataHandler
      .getData(this.state.licenseSourceName) as IFormDataSalesCreateGenerator | IFormDataTestCreateGenerator;

    return option.id === formData.method.id;
  };
}

export default HttpMethodHandler;
