<template>
  <div
    :style="tagStyles"
    class="tag"
  >
    <DSTextElement
      :style="textStyles"
      variant="xsmall-medium"
    >
      {{ text }}
    </DSTextElement>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
} from 'vue';

import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default defineComponent({
  name: 'DSTag',
  components: {
    DSTextElement,
  },
  props: {
    color: {
      type: String,
      default: '#000000',
    },
    backgroundColor: {
      type: String,
      default: '#ebf7ff',
    },
    text: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const tagStyles = computed(() => ({
      backgroundColor: props.backgroundColor,
    }));

    // @todo удалить textStyles после переработки цветовой схемы и перейти на задекларированные цвета из EColorScheme
    const textStyles = computed(() => ({
      color: props.color,
    }));

    return {
      tagStyles,
      textStyles,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
.tag {
  display: flex;
  align-items: center;
  height: 20px;
  padding: 4px 8px;
  text-transform: uppercase;
  border-radius: 20px;
}
</style>
