<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      d="M5 0V7H3C1.355 7 0 8.355 0 10V20H5V24H19V20H24V10C24 8.355 22.645 7 21 7H19V0H5ZM7 2H17V7H7V2ZM3 9H21C21.566 9 22 9.434 22 10V18H19V14H5V18H2V10C2 9.434 2.434 9 3 9ZM4 10C3.45 10 3 10.45 3 11C3 11.55 3.45 12 4 12C4.55 12 5 11.55 5 11C5 10.45 4.55 10 4 10ZM7 16H17V22H7V16Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSPrintIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>

<style scoped>

</style>
