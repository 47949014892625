
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

import ESize from '@/module/design-system/components/models/ESize';
import EIcon from '@/module/design-system/components/models/EIcon';
import EIconColorName from '@/module/design-system/components/models/EIconColorName';
import EIconColorCode from '@/module/design-system/components/models/EIconColorCode';

import DSAlertIcon from '@/module/design-system/components/Icons/DSAlertIcon.vue';
import DSAnalyticsIcon from '@/module/design-system/components/Icons/DSAnalyticsIcon.vue';
import DSArrowDownIcon from '@/module/design-system/components/Icons/DSArrowDownIcon.vue';
import DSArrowInsideCircleIcon from '@/module/design-system/components/Icons/DSArrowInsideCircleIcon.vue';
import DSArrowLeftIcon from '@/module/design-system/components/Icons/DSArrowLeftIcon.vue';
import DSArrowOppositelyDirectedIcon from '@/module/design-system/components/Icons/DSArrowOppositelyDirectedIcon.vue';
import DSArrowReturnIcon from '@/module/design-system/components/Icons/DSArrowReturnIcon.vue';
import DSArrowRightIcon from '@/module/design-system/components/Icons/DSArrowRightIcon.vue';
import DSArrowUpIcon from '@/module/design-system/components/Icons/DSArrowUpIcon.vue';
import DSAttachIcon from '@/module/design-system/components/Icons/DSAttachIcon.vue';
import DSBagIcon from '@/module/design-system/components/Icons/DSBagIcon.vue';
import DSBarGraphIcon from '@/module/design-system/components/Icons/DSBarGraphIcon.vue';
import DSBellIcon from '@/module/design-system/components/Icons/DSBellIcon.vue';
import DSBillIcon from '@/module/design-system/components/Icons/DSBillIcon.vue';
import DSBlindEyeIcon from '@/module/design-system/components/Icons/DSBlindEyeIcon.vue';
import DSBrandingIcon from '@/module/design-system/components/Icons/DSBrandingIcon.vue';
import DSBurgerMenuIcon from '@/module/design-system/components/Icons/DSBurgerMenuIcon.vue';
import DSCalendarIcon from '@/module/design-system/components/Icons/DSCalendarIcon.vue';
import DSCameraIcon from '@/module/design-system/components/Icons/DSCameraIcon.vue';
import DSCheckCircleIcon from '@/module/design-system/components/Icons/DSCheckCircleIcon.vue';
import DSCheckCircleFilledIcon from '@/module/design-system/components/Icons/DSCheckCircleFilledIcon.vue';
import DSCheckboxOnIcon from '@/module/design-system/components/Icons/DSCheckboxOnIcon.vue';
import DSClockIcon from '@/module/design-system/components/Icons/DSClockIcon.vue';
import DSCopyIcon from '@/module/design-system/components/Icons/DSCopyIcon.vue';
import DSCrossCircleIcon from '@/module/design-system/components/Icons/DSCrossCircleIcon.vue';
import DSCrossIcon from '@/module/design-system/components/Icons/DSCrossIcon.vue';
import DSCrossInsideCircleIcon from '@/module/design-system/components/Icons/DSCrossInsideCircleIcon.vue';
import DSDataExportsIcon from '@/module/design-system/components/Icons/DSDataExportsIcon.vue';
import DSDevelopersIcon from '@/module/design-system/components/Icons/DSDevelopersIcon.vue';
import DSDotsIcon from '@/module/design-system/components/Icons/DSDotsIcon.vue';
import DSDoubleArrowLeftIcon from '@/module/design-system/components/Icons/DSDoubleArrowLeftIcon.vue';
import DSDownloadIcon from '@/module/design-system/components/Icons/DSDownloadIcon.vue';
import DSDownloadFilledIcon from '@/module/design-system/components/Icons/DSDownloadFilledIcon.vue';
import DSEditIcon from '@/module/design-system/components/Icons/DSEditIcon.vue';
import DSEllipsisIcon from '@/module/design-system/components/Icons/DSEllipsisIcon.vue';
import DSExclamationIcon from '@/module/design-system/components/Icons/DSExclamationIcon.vue';
import DSExportIcon from '@/module/design-system/components/Icons/DSExportIcon.vue';
import DSEyeIcon from '@/module/design-system/components/Icons/DSEyeIcon.vue';
import DSFlagRu from '@/module/design-system/components/Icons/DSFlagRu.vue';
import DSGearIcon from '@/module/design-system/components/Icons/DSGearIcon.vue';
import DSHomeIcon from '@/module/design-system/components/Icons/DSHomeIcon.vue';
import DSInfoIcon from '@/module/design-system/components/Icons/DSInfoIcon.vue';
import DSLineGraphIcon from '@/module/design-system/components/Icons/DSLineGraphIcon.vue';
import DSPayoutsIcon from '@/module/design-system/components/Icons/DSPayoutsIcon.vue';
import DSPlusIcon from '@/module/design-system/components/Icons/DSPlusIcon.vue';
import DSPrintIcon from '@/module/design-system/components/Icons/DSPrintIcon.vue';
import DSProductIcon from '@/module/design-system/components/Icons/DSProductIcon.vue';
import DSProfileIcon from '@/module/design-system/components/Icons/DSProfileIcon.vue';
import DSPromotionsIcon from '@/module/design-system/components/Icons/DSPromotionsIcon.vue';
import DSQuestionIcon from '@/module/design-system/components/Icons/DSQuestionIcon.vue';
import DSRefreshIcon from '@/module/design-system/components/Icons/DSRefreshIcon.vue';
import DSSearchIcon from '@/module/design-system/components/Icons/DSSearchIcon.vue';
import DSSelectArrowIcon from '@/module/design-system/components/Icons/DSSelectArrowIcon.vue';
import DSServicesIcon from '@/module/design-system/components/Icons/DSServicesIcon.vue';
import DSSortIcon from '@/module/design-system/components/Icons/DSSortIcon.vue';
import DSSubtractIcon from '@/module/design-system/components/Icons/DSSubtractIcon.vue';
import DSTableIcon from '@/module/design-system/components/Icons/DSTableIcon.vue';
import DSTailArrowLeftIcon from '@/module/design-system/components/Icons/DSTailArrowLeftIcon.vue';
import DSTailArrowRightIcon from '@/module/design-system/components/Icons/DSTailArrowRightIcon.vue';
import DSTrashIcon from '@/module/design-system/components/Icons/DSTrashIcon.vue';
import DSUploadIcon from '@/module/design-system/components/Icons/DSUploadIcon.vue';
import DSWarnCircleIcon from '@/module/design-system/components/Icons/DSWarnCircleIcon.vue';

type TColorMap = {
  [key in EIconColorName]: EIconColorCode;
}

const colorMap: TColorMap = {
  [EIconColorName.DISABLED]: EIconColorCode.DISABLED,
  [EIconColorName.ERROR]: EIconColorCode.ERROR,
  [EIconColorName.LINKED]: EIconColorCode.LINKED,
  [EIconColorName.MENU]: EIconColorCode.MENU,
  [EIconColorName.MENU_ACTIVE]: EIconColorCode.MENU_ACTIVE,
  [EIconColorName.NAVIGATION]: EIconColorCode.NAVIGATION,
  [EIconColorName.PRIMARY]: EIconColorCode.PRIMARY,
  [EIconColorName.PRIMARY_BUTTON]: EIconColorCode.PRIMARY_BUTTON,
  [EIconColorName.SECONDARY]: EIconColorCode.SECONDARY,
  [EIconColorName.SUCCESS]: EIconColorCode.SUCCESS,
  [EIconColorName.SURFACE_ICONS]: EIconColorCode.SURFACE_ICONS,
  [EIconColorName.WARNING]: EIconColorCode.WARNING,
  [EIconColorName.ACCENT]: EIconColorCode.ACCENT,
};

export default defineComponent({
  name: 'DSIcon',
  components: {
    alert: DSAlertIcon,
    analytics: DSAnalyticsIcon,
    arrowDown: DSArrowDownIcon,
    arrowInsideCircle: DSArrowInsideCircleIcon,
    arrowLeft: DSArrowLeftIcon,
    arrowOppositelyDirected: DSArrowOppositelyDirectedIcon,
    arrowReturn: DSArrowReturnIcon,
    arrowRight: DSArrowRightIcon,
    arrowUp: DSArrowUpIcon,
    attach: DSAttachIcon,
    bag: DSBagIcon,
    barGraph: DSBarGraphIcon,
    bell: DSBellIcon,
    bill: DSBillIcon,
    blindEye: DSBlindEyeIcon,
    branding: DSBrandingIcon,
    burgerMenu: DSBurgerMenuIcon,
    calendar: DSCalendarIcon,
    camera: DSCameraIcon,
    checkCircle: DSCheckCircleIcon,
    checkCircleFilled: DSCheckCircleFilledIcon,
    checkboxOn: DSCheckboxOnIcon,
    clock: DSClockIcon,
    copy: DSCopyIcon,
    cross: DSCrossIcon,
    crossCircle: DSCrossCircleIcon,
    crossInsideCircle: DSCrossInsideCircleIcon,
    dataExports: DSDataExportsIcon,
    developers: DSDevelopersIcon,
    dots: DSDotsIcon,
    doubleArrowLeft: DSDoubleArrowLeftIcon,
    download: DSDownloadIcon,
    downloadFilled: DSDownloadFilledIcon,
    edit: DSEditIcon,
    ellipsis: DSEllipsisIcon,
    exclamation: DSExclamationIcon,
    export: DSExportIcon,
    eye: DSEyeIcon,
    flagRu: DSFlagRu,
    gear: DSGearIcon,
    home: DSHomeIcon,
    info: DSInfoIcon,
    lineGraph: DSLineGraphIcon,
    payouts: DSPayoutsIcon,
    plus: DSPlusIcon,
    print: DSPrintIcon,
    product: DSProductIcon,
    profile: DSProfileIcon,
    promotions: DSPromotionsIcon,
    question: DSQuestionIcon,
    refresh: DSRefreshIcon,
    search: DSSearchIcon,
    selectArrow: DSSelectArrowIcon,
    services: DSServicesIcon,
    sort: DSSortIcon,
    subtract: DSSubtractIcon,
    tableGrid: DSTableIcon,
    tailArrowLeft: DSTailArrowLeftIcon,
    tailArrowRight: DSTailArrowRightIcon,
    trash: DSTrashIcon,
    upload: DSUploadIcon,
    warnCircle: DSWarnCircleIcon,
  },
  props: {
    color: {
      type: String as PropType<EIconColorName>,
      default: EIconColorName.SURFACE_ICONS,
    },
    icon: {
      type: String as PropType<EIcon>,
      default: EIcon.EXCLAMATION,
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.M,
    },
  },
  setup(props) {
    const classes = computed(() => [
      'ds-icon',
      `ds-icon_size_${props.size}`,
    ]);
    const iconColor = computed(() => colorMap[props.color]);

    return {
      classes,
      iconColor,
    };
  },
});
