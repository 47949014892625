<template>
  <div
    class="ui-checkbox"
    :class="[
      checkboxChecked ? 'ui-checkbox--checked' : null,
      isDisabled ? 'ui-checkbox--disabled' : null,
    ]"
  >
    <span class="ui-checkbox__checkbox">
      <CheckIcon v-if="checkboxChecked" />
    </span>
    <span class="ui-checkbox__text">{{ checkboxText }}</span>
  </div>
</template>

<script>
import CheckIcon from '@/assets/icons/CheckIcon.vue';

export default {
  name: 'UICheckbox',
  components: { CheckIcon },
  props: {
    checkboxText: {
      type: String,
      default: 'Some text',
    },
    checkboxChecked: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.ui-checkbox {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: black;
  }

  &:hover &__checkbox {
    transition: 0.2s;
  }

  &__checkbox {
    min-width: 18px;
    width: 18px;
    height: 18px;
    border: 1px solid #ABACAC;
    background-color: #F5F5F5;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__checkbox svg {
    fill: white;
  }

  &--checked &__checkbox {
    border-color: #4397cb;
    background-color: #4397cb;
  }

  &__text {
    margin-left: 8px;
    transition: 0.2s;
    font-family: $text-ibm-plex-sans;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--checked &__text {
    color: black;
  }

  &--disabled &__text {
    color: #cccccc;
  }

  &--disabled &__checkbox {
    border-color: #CAD0DD;
    background-color: #F0F0F0;
  }
}

.ui-checkbox--disabled.ui-checkbox--checked .ui-checkbox__checkbox {
  background-color: #D9D9D9;
  border-color: #D9D9D9;
}
</style>
