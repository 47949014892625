var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"webhooks-table"},[(_vm.endpoints.length)?_c('div',{staticClass:"webhooks-table__head"},[_c('DSTextElement',{attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.$t('webhooks.webhooks_table.title').toUpperCase())+" ")]),_c('DSTextElement',{staticClass:"webhooks-table__title",attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.$t('webhooks.webhooks_table.last_days').toUpperCase())+" ")]),_c('DSTextElement',{staticClass:"webhooks-table__title",attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.$t('webhooks.webhooks_table.error_rate').toUpperCase())+" ")])],1):_vm._e(),_vm._l((_vm.endpoints),function(endpoint,idx){return _c('div',{key:endpoint.id,staticClass:"webhooks-table__row",on:{"click":(event) => _vm.handleRedirectToEndpoint(event, endpoint.id, idx)}},[_c('div',{staticClass:"webhooks-table__url"},[_c('DSTextElement',{attrs:{"color":!endpoint.status ? 'secondary' : 'primary',"variant":"medium"}},[_vm._v(" "+_vm._s(endpoint.url)+" ")])],1),_c('div',{staticClass:"webhooks-table__sum"},[_c('DSTextElement',[_vm._v(" "+_vm._s(endpoint.sum)+" ")])],1),_c('div',{staticClass:"webhooks-table__error-rate"},[_c('DSTextElement',[_vm._v(" "+_vm._s(endpoint.errorRate)+"% ")])],1),_c('div',{staticClass:"webhooks-table__values-mobile"},[_c('DSTextElement',{attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(endpoint.sum)+" • "+_vm._s(endpoint.errorRate)+"% ")])],1),_c('ButtonMore',{ref:"buttonMoreRef",refInFor:true,staticClass:"webhooks-table__button-more",attrs:{"buttons":[
        {
          name: 'product.product_card.delete',
          handler: (event) => _vm.handleDeleteEndpoint(event, endpoint.id),
        },
        {
          name: endpoint.status ? 'webhooks.deactivate' : 'webhooks.activate',
          handler: () => _vm.handleToggleActivation(endpoint),
        },
        {
          name: 'product.product_card.edit',
          handler: (event) => _vm.handleRedirectToEdit(event, endpoint.id),
        },
      ]}}),_c('DSTagList',{staticClass:"webhooks-table__tag-list",attrs:{"tag-list":_vm.getTagList(endpoint)}})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }