import THandleFieldBlurParams from '@/module/product/view/FulfillmentFormPage/model/types/THandleFieldBlurParams';
import IHandlerService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IHandlerService';
import ELocalServiceFulfillment from '@/module/product/view/FulfillmentFormPage/model/enums/ELocalServiceFulfillment';
import AbstractBlurFieldScenario
  from '@/module/product/view/FulfillmentFormPage/scenario/formFieldBlur/AbstractBlurFieldScenario';

class LicenseSourceFieldBlurScenario extends AbstractBlurFieldScenario {
  run = (item: THandleFieldBlurParams): void => {
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const licenseSourceHandler = handlerService.getLicenseSourceHandler();
    const formDataHandler = handlerService.getFormDataHandler();
    const formConfigHandler = handlerService.getFormConfigHandler();

    const licenseSourceName = licenseSourceHandler.getLicenseSourceName(item.formData);

    const formData = formDataHandler.getData(licenseSourceName);

    formData.licenseSource = item.formData.licenseSource;

    formDataHandler.store(licenseSourceName, formData);

    this.state.formConfig = formConfigHandler.getConfig(licenseSourceName);
    this.state.licenseSourceName = licenseSourceName;
  };
}

export default LicenseSourceFieldBlurScenario;
