enum EServiceContainerInstances {
  ROUTER = 'router',
  API = 'api',
  TS_API = 'tsApi',
  TRANSLATE = 'translate',
  STORE = 'store',
  I18N = 'i18n',
  UTILS = 'utils',
  UTILS_SERVICE = 'utilsService',
  VUE_INSTANCE = 'vueInstance',
  LOCALISATION_REGISTRY = 'localisationRegistry',
  PORTAL_MODE_SERVICE = 'portalModeService',
  MERCHANT_MODE_SERVICE = 'merchantModeService',
  NOTIFICATION_SERVICE = 'notificationService',
  MODULE_PRODUCT = 'moduleProduct',
  AUTHORIZE_SERVICE = 'authorizeService',
  LOCAL_STORAGE_SERVICE = 'localStorageService',
  NUMBER_FORMAT_SERVICE = 'numberFormatService',
  PORTAL_CONFIG = 'portalConfig',
  // @TODO переработать все инстансы, начинающиеся с TEMP
  TEMP_FULFILLMENT_TEST_REQUEST_API = 'tempFulfillmentTestRequestApi',
}

export default EServiceContainerInstances;
