import requiredValidator from '@/module/common/utils/validator/validators/requiredValidator';
import onlyNumbersValidator from '@/module/common/utils/validator/validators/onlyNumbersValidator';
import urlValidator from '@/module/common/utils/validator/validators/urlValidator';
import maxLengthValidator from '@/module/common/utils/validator/validators/maxLengthValidator';
import minLengthValidator from '@/module/common/utils/validator/validators/minLengthValidator';
import emailValidator from '@/module/common/utils/validator/validators/emailValidator';
import regexValidator from '@/module/common/utils/validator/validators/regexValidator';
import matchValidator from '@/module/common/utils/validator/validators/matchValidator';
import EProtocols from '@/module/common/models/EProtocols';
import customValidator from '@/module/common/utils/validator/validators/customValidator';
import notEmptyValidator from '@/module/common/utils/validator/validators/notEmptyValidator';
import EValidatorType from '@/module/common/utils/validator/enum/EValidatorType';
import IValidatorMessages from '@/module/common/utils/validator/model/IValidatorMessages';
import IValidatorConfig from '@/module/common/utils/validator/model/IValidatorConfig';
import IValidator from '@/module/common/utils/validator/contract/IValidator';

class Validator implements IValidator {
  private messages: IValidatorMessages[] = [];

  readonly validators: IValidatorConfig[] = [];

  addError = (type: EValidatorType, message: string) => {
    this.messages.push({
      type,
      message,
    });
  };

  hasErrors = (): boolean => Object.values(this.messages).length > 0;

  hasErrorsByTypeList = (errorTypes: EValidatorType[]): boolean => {
    const types = this.messages.map((message) => message.type);

    return errorTypes.reduce(
      (isIncludes, errorType) => types.includes(errorType) || isIncludes,
      false,
    ) as unknown as boolean;
  };

  getMessages = (): string[] => this.messages.map((message) => message.message);

  getMessageByType = (type: EValidatorType): string | null => {
    const message = this.messages.find((message) => message.type === type);
    return message?.message ?? null;
  };

  addValidator = (type: EValidatorType, message: string, params: unknown = null) => {
    this.validators.push({
      type,
      message,
      params,
    });
  };

  validate = (value: unknown, formData: unknown = null) => {
    if (this.validators.length === 0) {
      throw new Error('No validator set');
    }

    let result;

    this.validators.forEach((validator) => {
      if (validator.type === EValidatorType.REQUIRED) {
        result = requiredValidator(value);
      } else if (validator.type === EValidatorType.NOT_EMPTY) {
        result = notEmptyValidator(value);
      } else if (validator.type === EValidatorType.ONLY_NUMBERS) {
        result = onlyNumbersValidator(value);
      } else if (validator.type === EValidatorType.URL) {
        result = urlValidator(value as string, validator.params as unknown as EProtocols[]);
      } else if (validator.type === EValidatorType.MAX_LENGTH) {
        result = maxLengthValidator(value as string, validator.params as unknown as number);
      } else if (validator.type === EValidatorType.MIN_LENGTH) {
        result = minLengthValidator(value as string, validator.params as unknown as number);
      } else if (validator.type === EValidatorType.EMAIL) {
        result = emailValidator(value as string);
      } else if (validator.type === EValidatorType.REGEX) {
        result = regexValidator(value as string, validator.params as string);
      } else if (validator.type === EValidatorType.MATCH) {
        result = matchValidator(value, validator.params);
      } else if (validator.type === EValidatorType.CUSTOM) {
        result = customValidator(value, formData, validator.params as (value: unknown, formData?: unknown) => boolean);
      } else if (validator.type === EValidatorType.CUSTOM_NOT_EMPTY) {
        result = customValidator(value, formData, validator.params as (value: unknown, formData?: unknown) => boolean);
      } else {
        throw new Error(`Validator type '${validator.type}' not exists.`);
      }

      if (!result) {
        this.addError(validator.type, validator.message);
      }
    });
  };

  clear = () => {
    this.messages = [];
  };
}

export default Validator;
