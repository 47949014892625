import THandleFieldBlurParams from '@/module/product/view/ProductFormPage/model/THandleFieldBlurParams';
import BaseFieldBlurScenario from '@/module/product/view/ProductFormPage/scenario/BaseFieldBlurScenario';

class TrialFieldBlurScenario extends BaseFieldBlurScenario {
  run = (item: THandleFieldBlurParams) => {
    const productFormPage = this.serviceContainer.moduleProduct.productFormPage;
    const formDataHandler = productFormPage.handlerService.formDataHandler;
    const formData = formDataHandler.get();

    if (item.value && !formData.enableLicenseTerm) {
      formData.enableLicenseTerm = true;
    } else if (!item.value && formData.enableLicenseTerm && !formData.renewal) {
      formData.enableLicenseTerm = false;
    }

    formDataHandler.update(formData);

    const defaultScenario = productFormPage.scenarioService.getDefault();

    defaultScenario.run(item);
  };
}

export default TrialFieldBlurScenario;
