<template>
  <div
    v-if="localisation"
    class="checkout-link-form-page"
  >
    <DSModal
      v-if="showModal"
      :title="localisation.modal.title"
      :text="localisation.modal.text"
      text-color="secondary"
      @close-modal="handleCloseModal"
    >
      <template
        #modal-footer
      >
        <div
          class="modal__buttons"
        >
          <div
            class="modal__button"
          >
            <DSButton
              :text="localisation.modal.continue"
              stretch="xxl"
              @click="handleClickContinue"
            />
          </div>
          <div
            class="modal__button"
          >
            <DSButton
              :text="localisation.modal.cancel"
              stretch="xxl"
              variant="secondary-negative"
              @click="handleCloseModal"
            />
          </div>
        </div>
      </template>
    </DSModal>
    <CFormPageLayout
      width="lg"
      :showPreloader="isLoading"
    >
      <CFormPageHeaderRow
        :page-title="pageTitle"
        :navigation-left="headerButtonsLeft"
        :navigation-right="headerButtonsRight"
        navigation-position="top"
      />
      <CFormPageContent>
        <div
          class="checkout-link-form-page__content"
        >
          <div
            v-show="isShowForm"
            class="checkout-link-form-page__form"
          >
            <CFormComponent
              :form-config="formConfig"
              :form-data="formData"
              @field-blur="handleFormFieldBlur"
              @expandable-list-add-item="handleAddItem"
              @expandable-list-remove-item="handleRemoveItem"
              @list-scroll-y-reach-end="handleScrollToBottom"
              @expandable-list-search-field-input="handleExpandableListSearch"
              @filtered-select-search-field-input="handleFilteredSelectSearchInput"
            >
              <template
                #expandable-list-list-item="{ item, option }"
              >
                <CInput
                  :label="localisation.quantity"
                  :status="getItemQuantityStatus(item)"
                  :value="item.priceGroup.quantity"
                  :input-delay="option.expandableList.quantityInputDelay"
                  class="checkout-link-form-page__selected-product-quantity"
                  @blur="onQuantityBlur($event, item)"
                />
                <div
                  class="checkout-link-form-page__selected-product-auto-renew"
                >
                  <CCheckbox
                    v-if="haveAutoRenew(item)"
                    :title="localisation.renew.auto"
                    :value="item.priceGroup.autoRenew"
                    @input="handleProductAutoRenewChange($event, item)"
                  />
                  <DSTextElement
                    v-else
                    color="secondary"
                  >
                    {{ localisation.renew.manual }}
                  </DSTextElement>
                </div>
              </template>
              <template
                #expandable-list-select-before-list="{ item }"
              >
                <DSTextElement
                  v-if="productTotalCount > 0 && !preloaders[item.fieldName]"
                  color="secondary"
                  variant="small-medium"
                >
                  {{ $t('pages.checkout_links.form.found_products', { number: productTotalCount }) }}
                </DSTextElement>
                <DSTextElement
                  v-else-if="!preloaders[item.fieldName] && productTotalCount === 0 && !isProductSearch"
                  color="secondary"
                  variant="small-medium"
                >
                  {{ item.emptyListMessage }}
                </DSTextElement>
                <div
                  v-if="showProductInfoAlert"
                  class="product-alert"
                >
                  <InlineAlert
                    :title="localisation.productAlert.title"
                    :subtitle="localisation.productAlert.text"
                    variant="alert"
                    :show-controls="false"
                  />
                </div>
              </template>
              <template
                #expandable-list-select-item="{ option: product, item: productFormConfig }"
              >
                <div
                  :class="getSelectItemClasses(productFormConfig, product)"
                  @click="handleSelectItemClick($event, product)"
                >
                  <div
                    class="checkout-link-form-page__select-item-main"
                  >
                    <DSTextElement
                      :color="getSelectItemColor(product)"
                      class="checkout-link-form-page__select-item-title"
                      variant="medium"
                    >
                      {{ getProductName(product, productFormConfig) }}
                    </DSTextElement>
                  </div>
                  <div
                    class="checkout-link-form-page__select-item-additional"
                  >
                    <DSTextElement
                      :color="getSelectItemColor(product)"
                      variant="medium"
                    >
                      {{ getPriceString(product) }}
                    </DSTextElement>
                  </div>
                </div>
              </template>
              <template
                #expandable-list-select-after-list="{ item }"
              >
                <div
                  v-if="preloaders[item.fieldName]"
                  class="checkout-link-form-page__select-loading"
                >
                  <PreloaderComponent
                    height="30px"
                    width="30px"
                  />
                </div>
              </template>
              <template
                #form-select-after="{ item }"
              >
                <div
                  v-if="preloaders[item.fieldName]"
                  class="checkout-link-form-page__select-loading"
                >
                  <PreloaderComponent
                    height="30px"
                    width="30px"
                  />
                </div>
              </template>

              <template
                #filtered-select-after-list="{ item }"
              >
                <div
                  v-if="preloaders[item.fieldName]"
                  class="checkout-link-form-page__select-loading"
                >
                  <PreloaderComponent
                    height="30px"
                    width="30px"
                  />
                </div>
                <template
                  v-if="
                    item.fieldName === 'promotion'
                      && item.select.list.length === 0
                      && !preloaders.promotion
                      && !isPromotionsSearch"
                >
                  <DSTextElement
                    variant="small-medium"
                    color="secondary"
                  >
                    {{ item.emptyListMessage }}
                  </DSTextElement>
                </template>
              </template>
              <template
                #filtered-select-list-item="{ item, option }"
              >
                <template
                  v-if="item.fieldName === 'promotion'"
                >
                  <div
                    :class="getSelectItemClasses(item, option)"
                  >
                    <div
                      class="checkout-link-form-page__select-item-main"
                    >
                      <div
                        class="checkout-link-form-page__select-item-row"
                      >
                        <DSTextElement
                          class="checkout-link-form-page__select-item-title"
                          variant="medium"
                        >
                          {{ getPromotionName(option) }}
                        </DSTextElement>
                        <DSTextElement
                          class="checkout-link-form-page__select-item-title"
                          variant="medium"
                        >
                          {{ getPromotionDiscount(option) }}
                        </DSTextElement>
                      </div>
                      <div
                        class="checkout-link-form-page__select-item-row"
                      >
                        <DSTextElement
                          color="secondary"
                          variant="small"
                        >
                          {{ getPromotionStartDate(option) }}
                        </DSTextElement>
                        <DSTextElement
                          color="secondary"
                          variant="small"
                        >
                          {{ getPromotionEndDate(option) }}
                        </DSTextElement>
                      </div>
                    </div>
                    <div
                      class="checkout-link-form-page__select-item-additional"
                    >
                      <div>
                        <DSTag
                          :background-color="getPromotionCouponTypeBackgroundColor(option)"
                          :color="getPromotionCouponTypeTextColor(option)"
                          :text="getPromotionCouponTypeText(option)"
                        />
                      </div>
                    </div>
                  </div>
                </template>
                <template
                  v-else-if="item.fieldName === 'promotionCode'"
                >
                  <div
                    :class="getSelectItemClasses(item, option)"
                  >
                    <div
                      class="checkout-link-form-page__select-item-main"
                    >
                      <div
                        class="checkout-link-form-page__select-item-row"
                      >
                        <DSTextElement
                          class="checkout-link-form-page__select-item-title"
                          variant="medium"
                        >
                          {{ option.couponCode }}
                        </DSTextElement>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <template
                #input-list-item-before="{ index }"
              >
                <DSLabel
                  :text="`${localisation.referer.label} ${index + 1}`"
                  class="checkout-link-form-page__referer-label"
                  text-color="secondary"
                />
              </template>
              <template
                #input-list-item-after="{ status }"
              >
                <DSTextElement
                  v-if="status && status.text"
                  class="checkout-link-form-page__referer-comment"
                  color="error"
                  variant="small"
                >
                  {{ status.text }}
                </DSTextElement>
              </template>
            </CFormComponent>

            <DSButton
              :text="localisation.showPreview"
              class="checkout-link-form-page__show-preview"
              stretch="md"
              @click="showPreview"
            />
          </div>
          <div
            v-if="isShowPreview"
            ref="preview-wrapper"
            class="checkout-link-form-page__preview-wrapper"
          >
            <CheckoutLinkPreview
              :basket-url="basketUrl"
              :empty-link-text="localisation.emptyLink"
              :is-mobile-version="isMobileVersion"
              :link="previewLink"
              :link-length-error="localisation.linkValidationError"
              :style="previewStyle"
              :title="localisation.previewTitle"
              class="checkout-link-form-page__preview"
              @copied="handleCopy"
            />
          </div>
        </div>
      </CFormPageContent>
    </CFormPageLayout>
  </div>
</template>

<script>
import CCheckbox from '@/module/common/components/UI/CCheckbox.vue';
import CFormComponent from '@/module/common/components/CFormComponent.vue';
import CFormPageContent from '@/module/common/components/PageParts/CFormPageContent.vue';
import CFormPageHeaderRow from '@/module/common/components/PageParts/CFormPageHeaderRow.vue';
import CFormPageLayout from '@/module/common/components/PageParts/CFormPageLayout.vue';
import CInput from '@/module/common/components/UI/CInput.vue';
import CheckoutLinkPreview from '@/module/product/components/CheckoutLinkPreview.vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSLabel from '@/module/design-system/components/UI/DSLabel.vue';
import DSTag from '@/module/design-system/components/InfoBlock/DSTag.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import PreloaderComponent from '@/components/Preloader/PreloaderComponent.vue';
import SimpleVueValidator from 'simple-vue-validator';
import {
  inject,
} from 'vue';
import {
  mapActions,
  mapGetters,
} from 'vuex';
import DSModal from '@/module/design-system/components/UI/DSModal.vue';
import InlineAlert from '@/components/InlineAlert/InlineAlert.vue';
import mapProducts from '@/module/product/view/CheckoutLinkFormPage.func';
import getButton, {
  getButtonWithIcon,
} from '@/module/common/helpers/getButton';

const Validator = SimpleVueValidator.Validator;

export default {
  name: 'CheckoutLinkFormPage',
  components: {
    InlineAlert,
    DSModal,
    CCheckbox,
    CFormComponent,
    CFormPageContent,
    CFormPageHeaderRow,
    CFormPageLayout,
    CInput,
    CheckoutLinkPreview,
    DSButton,
    DSLabel,
    DSTag,
    DSTextElement,
    PreloaderComponent,
  },
  mixins: [
    SimpleVueValidator.mixin,
  ],
  data() {
    return {
      clearLinkKey: 'clear=Y',
      checkoutLinkId: null,
      showModal: false,
      searchMap: new Map(),
      formFieldsStatuses: [],
      refererStatuses: [],
      refererStatusesBuffer: [],
      couponList: [],
      itemQuantityStatuses: [],
      itemQuantityStatusesBuffer: [],
      initialProductTotal: null,
      previewPositionY: 0,
      previewPositionYInitial: 170,
      previewWidth: 392,
      isMobileVersion: false,
      isShowPreview: true,
      isShowForm: true,
      formData: {
        products: [],
        referer: [],
        emptyCustomerCart: true,
        name: '',
        promotion: {},
        orderCurrency: {
          id: null,
          title: this.$t('pages.checkout_links.form.order_currency.not_selected'),
        },
        promotionCode: {},
        customPrices: false,
        tracking: false,
      },
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      productTotalCount: 'checkoutLinkFormPageStore/getProductTotalCount',
      promotionsTotalCount: 'checkoutLinkFormPageStore/getPromotionsTotalCount',
      getProductList: 'checkoutLinkFormPageStore/getProductList',
      promotionList: 'checkoutLinkFormPageStore/getPromotionsList',
      selectedProductList: 'checkoutLinkFormPageStore/getSelectedProductList',
      preloaders: 'checkoutLinkFormPageStore/getPreloaders',
      user: 'authStore/getUser',
      isSaveError: 'checkoutLinkFormPageStore/getIsSaveError',
      isSavingInProgress: 'checkoutLinkFormPageStore/getIsSavingInProgress',
      layoutScrollRef: 'getScrollHostRef',
    }),
    currencyList() {
      const store = this.$container.resolve('store');

      return store.getters['authStore/getSellingCurrencies'];
    },
    formConfig() {
      return [
        {
          items: [
            {
              id: 1,
              type: 'input',
              fieldName: 'name',
              label: this.localisation.name.label,
              description: {
                default: this.localisation.name.description,
              },
              validate: true,
              status: this.getFieldStatus('name'),
            },
            {
              id: 2,
              type: 'checkbox',
              fieldName: 'emptyCustomerCart',
              default: false,
              label: this.localisation.emptyCart,
              validate: false,
              status: this.getFieldStatus('emptyCustomerCart'),
            },
          ],
        },
        {
          header: this.localisation.products,
          items: [
            {
              id: 3,
              type: 'expandableList',
              fieldName: 'products',
              placeholder: this.localisation.findProduct,
              validate: true,
              status: this.getFieldStatus('products'),
              emptyListMessage: this.localisation.dontHaveProducts,
              expandableList: {
                idField: 'product.id',
                titleField: 'product.name',
                list: this.productList,
                selectedItemList: this.formData.products,
                quantityInputDelay: 500,
                addButtonText: this.localisation.addProduct,
              },
            },
          ],
        },
        {
          type: 'delimiter',
        },
        {
          header: this.localisation.localization,
          items: [
            {
              id: 4,
              type: 'select',
              select: {
                idField: 'id',
                titleField: 'title',
                list: this.productCurrencyList,
              },
              placeholder: this.$t('common.not_selected'),
              fieldName: 'orderCurrency',
              label: this.localisation.orderCurrency.label,
              validate: false,
              status: this.getFieldStatus('orderCurrency'),
            },
          ],
        },
        {
          items: [
            {
              id: 5,
              type: 'switcher',
              fieldName: 'customPrices',
              label: this.localisation.customPrices,
              switcher: {
                withHeader: true,
                revert: undefined,
              },
            },
            {
              id: 6,
              type: 'filteredSelect',
              select: {
                idField: 'promotionId',
                titleField: 'promotionName',
                list: this.promotionList,
              },
              emptyListMessage: this.localisation.dontHavePromotions,
              conditionsList: [
                {
                  type: 'equals',
                  field: 'customPrices',
                  value: true,
                },
              ],
              placeholder: this.localisation.promotion.placeholder,
              fieldName: 'promotion',
              label: this.localisation.promotion.title,
              validate: false,
              status: this.getFieldStatus('promotion'),
            },
            {
              id: 7,
              type: 'filteredSelect',
              select: {
                idField: 'couponId',
                titleField: 'couponCode',
                list: this.couponList,
                offset: 10,
              },
              conditionsList: [
                {
                  type: 'equals',
                  field: 'customPrices',
                  value: true,
                },
                {
                  type: 'notEmpty',
                  field: 'promotion.promotionId',
                },
              ],
              fieldName: 'promotionCode',
              label: this.localisation.promotionCode.label,
              placeholder: this.localisation.promotionCode.placeholder,
              validate: true,
              status: this.getFieldStatus('promotionCode'),
            },
          ],
        },
        {
          type: 'delimiter',
        },
        {
          items: [
            {
              id: 8,
              type: 'switcher',
              fieldName: 'tracking',
              label: this.localisation.tracking,
              switcher: {
                withHeader: true,
                revert: undefined,
              },
            },
            {
              id: 9,
              type: 'inputList',
              inputList: {
                maxItems: 6,
                addButtonText: this.localisation.referer.addReferer,
                placeholder: this.localisation.referer.placeholder,
                inputDelay: 500,
                statuses: this.refererStatuses,
              },
              default: [
                {
                  value: '',
                },
              ],
              fieldName: 'referer',
              validate: true,
              conditionsList: [
                {
                  type: 'equals',
                  field: 'tracking',
                  value: true,
                },
              ],
            },
          ],
        },
      ];
    },
    productList() {
      return mapProducts(this.getProductList, this.selectedProductList, this.$utils);
    },
    basketUrl() {
      return `${this.user.basketURL}/basket`;
    },
    link() {
      if (this.formData.products.length === 0) {
        return '';
      }

      const urlParts = [];
      const productsIds = this.formData.products.map(({ product }) => product.id);
      const productsQuantity = this.formData.products.map(({ priceGroup }) => priceGroup.quantity ?? '1');
      const productsAR = this.formData.products.map(({ priceGroup }) => (priceGroup.autoRenew ? 1 : 0));

      urlParts.push(`pricegroupid=${productsIds.join(',')}`);
      urlParts.push(`quantity=${productsQuantity.join(',')}`);
      urlParts.push(`auto_renew=${productsAR.join(',')}`);

      if (this.formData.orderCurrency?.id) {
        urlParts.push(`currency=${this.formData.orderCurrency.id}`);
      }

      if (this.formData.customPrices && this.formData.promotionCode?.couponCode?.length > 0) {
        urlParts.push(`coupon_seria=${this.formData.promotionCode.couponCode}`);
        urlParts.push('auto');
      }

      if (this.formData.tracking && this.formData.referer.length > 0) {
        this.formData.referer.forEach((referer, index) => {
          if (referer.value.length > 0) {
            urlParts.push(`referer${index + 1}=${encodeURIComponent(referer.value)}`);
          }
        });
      }

      urlParts.push(this.clearLinkKey);

      const urlString = urlParts.length > 0 ? `?${urlParts.join('&')}` : '';

      return `${this.basketUrl}/add/${urlString}`;
    },
    linkToSave() {
      let link = this.link;

      if (!this.formData.emptyCustomerCart) {
        link = link.replace(`&${this.clearLinkKey}`, '');
      }

      return link;
    },
    previewLink() {
      return {
        link: this.linkToSave,
        preview: this.link,
      };
    },
    productCurrencyList() {
      let currencyList = this.currencyList.map((currencyKey) => ({
        id: currencyKey,
        title: this.$t(`currency.short_title.${currencyKey}`),
      }));

      if (this.selectedProductList.length > 0) {
        const filteredProductList = this.selectedProductList.filter(
          (selectedProduct) => !selectedProduct.product.variants[0].price.common,
        );

        currencyList = Array.from(filteredProductList.reduce((intersectedCurrencies, product) => {
          const productCurrencies = this.getProductCurrencies(product);
          if (intersectedCurrencies.length === 0) {
            return productCurrencies;
          }
          return this.$utils.array.intersect(intersectedCurrencies, productCurrencies);
        }, []));

        currencyList = currencyList.length > 0
          ? this.$utils.array.intersect(currencyList, this.currencyList)
          : this.currencyList;

        currencyList = currencyList.map((currencyKey) => ({
          id: currencyKey,
          title: this.$t(`currency.short_title.${currencyKey}`),
        }));
      }

      return [
        {
          id: null,
          title: this.$t('pages.checkout_links.form.order_currency.not_selected'),
        },
        ...currencyList,
      ];
    },
    pageTitle() {
      const title = this.$route.meta.editMode ? this.localisation?.editTitle : this.localisation?.title;

      return this.isMobileVersion && this.isShowPreview ? '' : title;
    },
    previewStyle() {
      return {
        top: `${this.previewPositionY}px`,
        width: `${this.previewWidth}px`,
      };
    },
    availableProductCurrency() {
      const currencies = [];
      if (this.formData.orderCurrency?.id) {
        currencies.push(this.formData.orderCurrency.id);
      } else if (this.formData.products.length > 0) {
        this.productCurrencyList.forEach((currencyItem) => {
          if (currencyItem.id) {
            currencies.push(currencyItem.id);
          }
        });
      }

      return currencies;
    },
    isProductSearch() {
      return this.searchMap.get('products');
    },
    isPromotionsSearch() {
      return this.searchMap.get('promotion');
    },
    showProductInfoAlert() {
      const isTotalDecrease = this.initialProductTotal > this.productTotalCount;
      return isTotalDecrease && this.selectedProductList.length > 0 && !this.preloaders.products;
    },
    headerButtonsLeft() {
      return [
        getButtonWithIcon(
          {
            text: this.$t('common.back'),
            variant: 'tretiary',
          },
          this.showForm,
          {
            icon: 'tailArrowLeft',
            color: 'linked',
          },
          this.isMobileVersion && this.isShowPreview,
        ),
        getButtonWithIcon(
          {
            text: this.$t('common.cancel'),
            variant: 'tretiary',
          },
          () => { this.showModal = true; },
          {
            icon: 'cross',
            color: 'linked',
          },
          !this.isMobileVersion || !this.isShowPreview,
        ),
      ];
    },
    headerButtonsRight() {
      return [
        getButton(
          {
            text: this.$t('common.save'),
          },
          this.handleSaveClick,
        ),
      ];
    },
  },
  watch: {
    selectedProductList() {
      this.updateFormData({
        products: this.selectedProductList,
      });
    },
  },
  async mounted() {
    await this.initForm();
    this.isLoading = false;

    window.addEventListener('resize', this.resizeHandler);
    window.dispatchEvent(new Event('resize'));

    window.addEventListener('scroll', this.updatePreviewPositionY);
    window.dispatchEvent(new Event('scroll'));
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
    window.removeEventListener('scroll', this.updatePreviewPositionY);
  },
  methods: {
    ...mapActions({
      refreshProductList: 'checkoutLinkFormPageStore/refreshProductList',
      refreshPromotionsList: 'checkoutLinkFormPageStore/refreshPromotionsList',
      updatePromotionsList: 'checkoutLinkFormPageStore/updatePromotionsList',
      updateProductList: 'checkoutLinkFormPageStore/updateProductList',
      updateSelectedProductList: 'checkoutLinkFormPageStore/updateSelectedProductList',
      saveCheckoutLink: 'checkoutLinkFormPageStore/saveCheckoutLink',
      pushNotification: 'notificationsStore/pushNotification',
    }),
    async initForm() {
      await Promise.all([
        this.getProducts(true),
        this.refreshPromotionsList({
          limit: 10,
          offset: 0,
          promotionType: 'coupon',
          promotionStatus: 'active',
        }),
      ]);

      if (this.$route.meta.editMode) {
        this.checkoutLinkId = +this.$route.params.id;
        await this.loadFormData(this.checkoutLinkId);
        this.getProducts();
      }
    },
    getProductName(product, productFormConfig) {
      return this.$utils.data.readProp(product, productFormConfig.expandableList.titleField);
    },
    updatePreviewPositionY(event) {
      const diff = this.previewPositionYInitial - (event?.detail?.next?.target?.scrollTop ?? 0);
      const minPositionY = 90;

      this.previewPositionY = Math.max(minPositionY, diff);
    },
    updatePreviewWidth() {
      if (this.isMobileVersion) {
        this.previewWidth = '100%';
      } else {
        this.previewWidth = this.$refs['preview-wrapper']?.offsetWidth ?? '0';
      }
    },
    updateShowPreview() {
      this.isShowForm = true;
      this.isShowPreview = !this.isMobileVersion;
    },
    resizeHandler() {
      const prevIMobileVersion = this.isMobileVersion;

      this.isMobileVersion = window.innerWidth <= 992;
      if (prevIMobileVersion !== this.isMobileVersion) {
        this.updateShowPreview();
      }
      this.$nextTick(this.updatePreviewWidth);
    },
    async getIsFormValid() {
      const fields = [
        'formData.name',
        'formData.products',
      ];

      if (this.formData.customPrices && this.formData.promotion?.promotionName === 'coupon') {
        fields.push('formData.promotionCode');
      }

      if (this.formData.tracking) {
        fields.push('formData.referer');
      }

      await this.validateFields(fields);

      const productQuantityValidationErrors = this.itemQuantityStatuses.filter((item) => item.status.code === 'error');
      return !this.hasValidationErrors() && productQuantityValidationErrors.length === 0;
    },
    hasValidationErrors() {
      return Object.values(this.formFieldsStatuses).findIndex((item) => item.code === 'error') !== -1;
    },
    async handleSaveClick() {
      const isFormValid = await this.getIsFormValid();

      if (!isFormValid || this.linkToSave.length >= 2000) {
        return;
      }

      await this.saveCheckoutLink({
        id: this.checkoutLinkId,
        formData: this.formData,
        link: this.linkToSave,
        isEdit: this.$route.meta.editMode ?? false,
      });

      if (!this.isSaveError) {
        this.clearFormData();
        this.$router.push('/checkout-links').catch(() => null);
        setTimeout(() => {
          this.updatePopup('success');
        }, 1000);
      } else {
        this.updatePopup('error');
      }
    },
    handleAddItem(event) {
      if (!this.$utils.array.includesObject(this.selectedProductList, event.data, 'product.id')) {
        this.formData.products.push(event.data);

        this.itemQuantityStatuses.push({
          id: event.data.product.id,
          status: {
            code: 'default',
          },
        });

        this.updateSelectedProductList(this.formData.products);

        this.searchMap.set('products', null);

        this.getProducts();
      }
    },
    handleScrollToBottom(event) {
      if (event.formConfig.fieldName === 'products' && !this.preloaders.products) {
        this.handleProductScrollToBottom();
      } else if (event.formConfig.fieldName === 'promotion' && !this.preloaders.promotion) {
        this.handlePromotionScrollToBottom();
      }
    },
    handleProductScrollToBottom() {
      if (this.productList.length < this.productTotalCount && !this.preloaders.products) {
        this.updateProductList({
          limit: 10,
          offset: this.productList.length,
          searchString: this.searchMap.get('products'),
          currency: this.availableProductCurrency,
        });
      }
    },
    handlePromotionScrollToBottom() {
      if (this.promotionList.length < this.promotionsTotalCount && !this.preloaders.promotions) {
        this.updatePromotionsList({
          limit: 10,
          offset: this.promotionList.length,
          promotionType: 'coupon',
          promotionStatus: 'active',
        });
      }
    },
    handleProductSearchFieldInput(data) {
      const isMinStringLength = data.length >= 3 || !data;

      if (isMinStringLength && data.length <= 255) {
        const searchString = data.length >= 3 && data.length <= 255 ? data : null;
        this.searchMap.set('products', searchString);
        this.getProducts();
      }
    },
    handleFilteredSelectSearchInput({ data, formConfig }) {
      if (formConfig.fieldName === 'promotion') {
        this.handlePromotionSearch(data);
      } else if (formConfig.fieldName === 'promotionCode') {
        this.handlePromotionCodeSearch(data);
      }
    },
    handleExpandableListSearch({ data, formConfig }) {
      if (formConfig.fieldName === 'products') {
        this.handleProductSearchFieldInput(data);
      }
    },
    handlePromotionSearch(data) {
      const isMinStringLength = data.length >= 3 || !data;

      if (isMinStringLength && data.length <= 255) {
        const searchString = data.length >= 3 && data.length <= 255 ? data : null;
        this.searchMap.set('promotion', searchString);

        this.refreshPromotionsList({
          limit: 10,
          offset: 0,
          promotionType: 'coupon',
          promotionStatus: 'active',
          searchString,
        });
      }
    },
    handlePromotionCodeSearch(data) {
      const coupons = this.getCouponsFromPromotion();
      this.searchMap.set('promotion', data);

      if (data.length >= 3) {
        const searchString = data.toLowerCase();
        this.couponList = coupons.filter((coupon) => coupon.couponCode.toLowerCase().includes(searchString));
      } else if (!data) {
        this.couponList = coupons;
      }
    },
    handleRemoveItem(event) {
      const filteredProducts = this.formData.products.filter((item) => item.product.id !== event.data.product.id);

      this.updateSelectedProductList(filteredProducts);
      const isEmptyProductList = filteredProducts.length === 0;

      const productCurrencies = this.productCurrencyList.map((productCurrency) => productCurrency.id);
      if (isEmptyProductList || !productCurrencies.includes(this.formData.orderCurrency.id)) {
        this.formData.orderCurrency = {
          id: null,
          title: this.$t('pages.checkout_links.form.order_currency.not_selected'),
        };
      }

      this.getProducts(isEmptyProductList);
    },
    haveAutoRenew(product) {
      return product.product.autoRenew;
    },
    isProductSelected(product) {
      return this.$utils.array.includesObject(this.selectedProductList, product.product, 'product.id');
    },
    handleSelectItemClick(event, product) {
      if (this.isProductSelected(product)) {
        event.stopPropagation();
      }
    },
    getItemQuantityStatus(product) {
      const quantityStatus = this.itemQuantityStatuses.find((item) => item.id === product.product.id)?.status;
      return quantityStatus ?? {
        id: product.product.id,
        status: {
          code: 'default',
        },
      };
    },
    getPriceString(product) {
      let priceText = `${product.priceCount} ${this.localisation.prices}`;
      if (this.isProductSelected(product)) {
        priceText = this.localisation.productAdded;
      } else if (product.price !== null) {
        const numberFormatService = this.$container.numberFormatService;

        priceText = numberFormatService.money(
          product.price.price,
          product.price.currency,
        );
      }

      return priceText;
    },
    isLastSelectItem(fieldConfig, option) {
      const selectFields = [
        'products',
        'promotion',
        'promotionCode',
      ];

      if (!selectFields.includes(fieldConfig.fieldName)) {
        return false;
      }

      const listsMap = {
        products: this.productList,
        promotion: this.promotionList,
        promotionCode: this.couponList ?? [],
      };
      const itemIdFieldMap = {
        products: 'id',
        promotion: 'promotionId',
        promotionCode: 'couponId',
      };
      const list = listsMap[fieldConfig.fieldName];
      const idField = itemIdFieldMap[fieldConfig.fieldName];
      const findIndex = list.findIndex((item) => item[idField] === option[idField]);

      return findIndex === list.length - 1;
    },
    getSelectItemClasses(fieldConfig, option) {
      const classes = [
        'checkout-link-form-page__select-item',
      ];

      if (!this.preloaders[fieldConfig.fieldName] && this.isLastSelectItem(fieldConfig, option)) {
        classes.push('checkout-link-form-page__select-item_last');
      }

      return classes;
    },
    getSelectItemColor(product) {
      return this.$utils.array
        .includesObject(this.selectedProductList, product.product, 'product.id') ? 'secondary' : 'primary';
    },
    getFieldStatus(fieldName) {
      return this.formFieldsStatuses[fieldName] ?? {
        code: 'default',
      };
    },
    getCouponsFromPromotion() {
      const coupons = this.formData.promotion?.promotionStatistic?.coupons ?? [];
      return coupons.filter((coupon) => {
        const isAvailableOneTime = coupon.couponType === 'one-time' && coupon.couponUses === 0;
        return coupon.couponType === 'reusable' || isAvailableOneTime;
      });
    },
    async handleFormFieldBlur(item) {
      const fieldsWithSearch = [
        'products',
        'promotion',
        'promotionCode',
      ];
      const { field, value } = item;

      this.updateFormData({ [field.fieldName]: value });

      if (field.validate) {
        const fieldName = `formData.${field.fieldName}`;

        await this.validateFields([fieldName]);
      }

      if (field.fieldName === 'referer') {
        this.refererStatuses = this.refererStatusesBuffer;
      }

      if (field.fieldName === 'promotion') {
        this.couponList = this.getCouponsFromPromotion();
        this.updateFormData({ promotionCode: {} });
      }

      if (field.fieldName === 'orderCurrency') {
        this.getProducts();
      }

      if (fieldsWithSearch.includes(field.fieldName)) {
        this.searchMap.set(field.fieldName, null);
      }
    },
    async validateFields(fields) {
      await this.$validate(fields);

      this.formFieldsStatuses = fields.reduce((formFieldStatuses, field) => {
        const message = this.validation.firstError(field);
        const fieldName = field.replace('formData.', '');

        formFieldStatuses[fieldName] = {
          code: 'default',
        };

        if (message) {
          formFieldStatuses[fieldName] = {
            code: 'error',
            text: message,
          };
        }

        return formFieldStatuses;
      }, { ...this.formFieldsStatuses });
    },
    getProductCurrencies(product) {
      const variants = product.product?.variants ?? [];

      return Array.from(
        variants.reduce((currencies, variant) => {
          if (variant.price.common) {
            currencies.add(variant.price.common.currency);
          } else {
            Object.values(variant.price).forEach((price) => currencies.add(price.currency));
          }

          return currencies;
        }, new Set()),
      );
    },
    async onQuantityBlur(value, product) {
      this.formData.products = this.formData.products.map((productItem) => {
        if (productItem.product.id === product.product.id) {
          productItem.priceGroup.quantity = value;
        }

        return productItem;
      });

      await this.validateFields(['formData.products']);

      this.itemQuantityStatuses = this.itemQuantityStatusesBuffer;
    },
    handleProductAutoRenewChange(value, product) {
      this.formData.products = this.formData.products.map((productItem) => {
        if (productItem.product.id === product.product.id) {
          productItem.priceGroup.autoRenew = value;
        }

        return productItem;
      });
    },
    getPromotionName(option) {
      return `${option.promotionName}`;
    },
    getPromotionDiscount(option) {
      const discountPercent = option.promotionDiscountModel?.totalDiscountPercent ?? null;
      let discountStr = `${discountPercent}%`;

      if (discountPercent === null) {
        const discountCount = option.promotionDiscountModel.products.length;

        discountStr = `${discountCount} ${this.localisation.promotion.discount}`;
      }

      return ` • ${discountStr}`;
    },
    getPromotionStartDate(option) {
      return this.$utils.date.formatSkipCurrentYearWithTime(option.promotionStartDate);
    },
    getPromotionEndDate(option) {
      let dataStr = '';

      if (option.promotionEndDate.indexOf('3000') === -1) {
        const endDate = this.$utils.date.formatSkipCurrentYearWithTime(option.promotionEndDate);
        dataStr = ` - ${endDate}`;
      }

      return dataStr;
    },
    getPromotionCouponType(option) {
      return option.promotionStatistic?.coupons?.[0]?.couponType;
    },
    getPromotionCouponTypeText(option) {
      return this.getPromotionCouponType(option) === 'one-time'
        ? this.localisation.promotion.type.disposable
        : this.localisation.promotion.type.reusable;
    },
    getPromotionCouponTypeTextColor(option) {
      return this.getPromotionCouponType(option) === 'one-time' ? '#6f43cb' : '#4397cb';
    },
    getPromotionCouponTypeBackgroundColor(option) {
      return this.getPromotionCouponType(option) === 'one-time' ? '#f1ebff' : '#ebf7ff';
    },
    updatePopup(popup) {
      switch (popup) {
        case 'error':
          this.pushNotification({
            title: this.localisation.popup.error.title,
            text: this.localisation.popup.error.text,
            type: 'error',
            component: 'DSPopup',
          });
          break;
        default:
          this.pushNotification({
            title: this.localisation.popup.info.title,
            text: this.localisation.popup.info.text,
            type: 'info',
            component: 'DSPopup',
          });
          break;
      }
    },
    showPreview() {
      this.isShowForm = false;
      this.isShowPreview = true;
      this.layoutScrollRef.$el.scrollTop = 0;
    },
    showForm() {
      this.isShowForm = true;
      this.isShowPreview = false;
      this.layoutScrollRef.$el.scrollTop = 0;
    },
    handleCopy() {
      this.pushNotification({
        title: this.localisation.notification.copy.title,
        component: 'DSAlert',
        type: 'info',
      });
    },
    async getProducts(initial = false) {
      const options = {
        limit: 10,
        offset: 0,
        searchString: this.searchMap.get('products'),
        currency: initial ? [] : this.availableProductCurrency,
      };

      await this.refreshProductList(options);

      if (initial) {
        this.initialProductTotal = this.productTotalCount;
      }
    },
    async loadFormData(id) {
      const checkoutLinkResponse = await this.$api.product.checkoutLink.get(id);
      const priceGroupsMap = checkoutLinkResponse.data.parameters.price_groups
        .reduce((mappedPriceGroups, priceGroup) => {
          mappedPriceGroups[priceGroup.price_group_id] = {
            priceGroupId: priceGroup.price_group_id,
            autoRenew: priceGroup.auto_renew,
            quantity: priceGroup.quantity,
          };

          return mappedPriceGroups;
        }, {});
      const priceGroupsIds = Object.keys(priceGroupsMap);
      const customPrices = checkoutLinkResponse.data.parameters.custom_prices;
      let promotion = {};
      let promotionCode = {};

      const productsResponse = await this.$api.product.product.list({ ids: priceGroupsIds });

      if (customPrices && checkoutLinkResponse.data.parameters.promotion_id) {
        const promotionsResponse = await this.$api.promotion.getCard(checkoutLinkResponse.data.parameters.promotion_id);
        promotion = promotionsResponse.data;
        if (checkoutLinkResponse.data.parameters.coupon_code) {
          const promoCodesList = promotion.promotionStatistic?.coupons ?? [];
          promotionCode = promoCodesList
            .find((promoCodeItem) => promoCodeItem.couponCode === checkoutLinkResponse.data.parameters.coupon_code);
          promotionCode = promotionCode ?? {};
        }
      }

      const refers = Object.values(checkoutLinkResponse.data.parameters.refers ?? {})
        .map((referer) => ({ value: referer }));

      const currencyKey = checkoutLinkResponse.data.parameters.currency;

      const productList = productsResponse.data.products
        .map((product) => ({ ...product, priceGroup: priceGroupsMap[product.id] }));
      const products = mapProducts(productList, this.selectedProductList, this.$utils);
      this.updateSelectedProductList(products);

      let orderCurrency = {
        id: null,
        title: this.$t('pages.checkout_links.form.order_currency.not_selected'),
      };
      if (currencyKey) {
        orderCurrency = {
          id: currencyKey,
          title: this.$t(`currency.short_title.${currencyKey}`),
        };
      }

      this.updateFormData({
        products,
        referer: refers,
        name: checkoutLinkResponse.data.name,
        orderCurrency,
        emptyCustomerCart: checkoutLinkResponse.data.parameters.empty_customer_cart,
        promotion,
        promotionCode,
        customPrices,
        tracking: checkoutLinkResponse.data.parameters.tracking,
      });
    },
    updateFormData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      };
    },
    clearFormData() {
      this.formData = {
        products: [],
        referer: [],
        emptyCustomerCart: true,
        name: '',
        promotion: {},
        orderCurrency: {
          id: null,
          title: this.$t('pages.checkout_links.form.order_currency.not_selected'),
        },
        promotionCode: {},
        customPrices: false,
        tracking: false,
      };

      this.updateSelectedProductList([]);
    },
    handleCloseModal() {
      this.showModal = false;
    },
    handleClickContinue() {
      this.clearFormData();
      this.$router.push('/checkout-links/').catch(() => null);
    },
  },
  validators: {
    // eslint-disable-next-line func-names
    'formData.name': function (value) {
      return Validator.value(value)
        .required(this.$t('validation_messages.required'))
        .maxLength(255, this.$t('validation_messages.max_length', { number: 255 }));
    },
    // eslint-disable-next-line func-names
    'formData.products': function (value) {
      return Validator.value(value)
        .custom(() => {
          const isError = value.length === 0;

          this.itemQuantityStatusesBuffer = value.reduce((quantityStatus, item) => {
            const {
              product,
              priceGroup,
              minQuantity,
              maxQuantity,
            } = item;
            const haveMaxQuantity = maxQuantity !== null;
            let haveError = false;
            let errorMessage;

            if (priceGroup.quantity !== '' && /\D/.test(priceGroup.quantity)) {
              haveError = true;
              errorMessage = this.$t('validation_messages.only_numbers');
            }

            if (!haveError && priceGroup.quantity !== '' && priceGroup.quantity < minQuantity) {
              errorMessage = this.$t('validation_messages.greater_or_equal', { number: minQuantity });
              haveError = true;
            }

            if (!haveError && priceGroup.quantity !== '' && haveMaxQuantity && priceGroup.quantity > maxQuantity) {
              errorMessage = this.$t('validation_messages.number_interval', {
                min: minQuantity,
                max: maxQuantity,
              });
              haveError = true;
            }

            const status = haveError
              ? {
                code: 'error',
                text: errorMessage,
              }
              : {
                code: 'default',
              };

            quantityStatus.push({
              id: product.id,
              status,
            });

            return quantityStatus;
          }, []);

          return isError ? this.$t('validation_messages.required') : null;
        });
    },
    // eslint-disable-next-line func-names
    'formData.promotionCode': function (value) {
      if (this.formData.customPrices && this.formData.promotion?.promotionName === 'coupon') {
        return Validator.value(value ?? '')
          .required(this.$t('validation_messages.required'));
      }

      return null;
    },
    // eslint-disable-next-line func-names
    'formData.referer': function (value) {
      if (this.formData.tracking) {
        return Validator.value(value)
          .custom(() => {
            let listHaveError = false;

            this.refererStatusesBuffer = value.reduce((refererStatus, item) => {
              const haveError = item.value.length > 255;
              listHaveError = listHaveError || haveError;

              const status = haveError
                ? {
                  code: 'error',
                  text: this.$t('validation_messages.max_length', { number: 255 }),
                }
                : {
                  code: 'default',
                };

              refererStatus.push(status);

              return refererStatus;
            }, []);

            return listHaveError;
          });
      }

      return null;
    },
  },
  setup() {
    const serviceContainer = inject('serviceContainer');
    const store = serviceContainer.resolve('store');
    const localisationRegistry = store.getters.getLocalisationRegistry;
    const localisation = localisationRegistry.checkoutLinksForm;

    return {
      localisation,
    };
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import "@/module/design-system/components/variables.scss";
@import "@/popup-position.scss";

.product-alert {
  padding-right: 16px;
}

.modal {
  &__buttons {
    display: flex;
    gap: 20px;
    width: 100%;
  }
  &__button {
    display: flex;
    width: calc(50% - 4px);
  }
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: $surface-bg-color-regular;
}

.checkout-link-form-page {
  overflow-x: hidden;
  padding-bottom: 32px;

  &__content {
    display: flex;
    column-gap: 32px;
  }

  &__form {
    width: 540px;
    flex: 0 0 auto;
  }

  &__referer-label {
    margin-bottom: 8px;
  }

  &__referer-comment {
    margin-top: 8px;
  }

  &__select-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px solid $surface-stroke-color;
    background-color: $surface-bg-color-regular;
    gap: 8px;

    &_last {
      border-bottom: none;
    }
  }

  &__selected-product-quantity {
    margin-bottom: 12px;
  }

  &__selected-product-auto-renew {
    margin-bottom: 35px;
  }

  &__select-item-main {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__select-item-row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3px;
  }

  &__select-item-title {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__select-item-additional {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 90px;
    text-align: right;
    white-space: nowrap;
  }

  &__select-loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__show-preview {
    display: none;
  }

  &__preview-wrapper {
    position: relative;
    width: calc(100% - 475px);
    min-width: 392px;
  }

  &__preview {
    position: fixed;
  }
}

@media screen and (max-width: $breakpoint-xl) {
  .checkout-link-form-page {
    &__form {
      width: 445px;
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .checkout-link-form-page {
    &__form {
      width: 100%;
    }

    &__show-preview {
      display: block;
    }

    &__preview-wrapper {
      position: static;
      width: 100%;
      min-width: 0;
    }

    &__preview {
      position: static;
      width: 100%;
    }
  }
}
</style>
