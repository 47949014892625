<template>
  <div
    class="c-checkbox"
    @mouseenter="handleMouseenter"
    @mouseleave="handleMouseleave"
  >
    <div
      class="c-checkbox__field-row"
    >
      <DSCheckbox
        ref="checkbox"
        :value="value"
        class="c-checkbox__input"
        @input="$emit('input', $event)"
      />
      <CLabel
        :label-for="checkbox"
        :text-color="labelColor"
        :tooltip="tooltip"
        class="c-checkbox__label"
      >
        <slot
          name="label-text"
        />
        <!-- eslint-disable vue/no-v-html -->
        <span
          v-if="!isLabelText && title"
          v-html="title"
        />
        <!-- eslint-enable vue/no-v-html -->
      </CLabel>
    </div>
    <DSTextElement
      v-if="description"
      class="c-input__comment"
      color="secondary"
      variant="small"
    >
      <!-- eslint-disable vue/no-v-html -->
      <span
        v-html="description"
      />
      <!-- eslint-enable vue/no-v-html -->
    </DSTextElement>
    <DSTextElement
      v-if="status.text"
      :color="status.code"
      class="c-input__comment"
      variant="small"
    >
      {{ status.text }}
    </DSTextElement>
  </div>
</template>

<script>
import CLabel from '@/module/common/components/UI/CLabel.vue';
import DSCheckbox from '@/module/design-system/components/UI/DSCheckbox.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default {
  name: 'CCheckbox',
  components: {
    CLabel,
    DSCheckbox,
    DSTextElement,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    status: {
      type: Object,
      default: () => ({
        code: 'default',
      }),
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkbox: null,
      isHovered: false,
    };
  },
  computed: {
    checked() {
      return this.value;
    },
    isLabelText() {
      return !!this.$scopedSlots['label-text'];
    },
    labelColor() {
      return this.isHovered || this.checked ? 'primary' : 'secondary';
    },
  },
  mounted() {
    this.checkbox = this.$refs.checkbox;
  },
  methods: {
    handleMouseenter() {
      this.isHovered = true;
    },
    handleMouseleave() {
      this.isHovered = false;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import "@/module/design-system/components/variables.scss";

.c-checkbox {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__field-row {
    display: flex;
    cursor: pointer;
    gap: 8px;
  }

  &__input {
    flex: 0 0 18px;
    width: 18px;
    height: 18px;
    margin: 2px 0 0;
    border: 1px solid $checkbox-border-color-primary;
    border-radius: 4px;
    appearance: none;

    &:checked {
      border: 0;
    }
  }

  &:hover {
    .c-checkbox__input {
      border: 1px solid $checkbox-border-color-hover;
    }
  }

  &__label::v-deep {
    label {
      cursor: pointer;
    }
  }
}
</style>
