import THandleFieldBlurParams from '@/module/product/view/ProductFormPage/model/THandleFieldBlurParams';
import BaseFieldBlurScenario from '@/module/product/view/ProductFormPage/scenario/BaseFieldBlurScenario';
import EPricingModelName from '@/module/product/model/Product/Fields/PricingModel/EPricingModelName';
import TPricingTableValue from '@/module/product/model/TPricingTableValue';

class RenewalFieldBlurScenario extends BaseFieldBlurScenario {
  run = (item: THandleFieldBlurParams) => {
    const productFormPage = this.serviceContainer.moduleProduct.productFormPage;
    const formDataHandler = productFormPage.handlerService.formDataHandler;
    const pricingTableHelper = this.serviceContainer.moduleProduct.pricingTableHelper;
    const utilsService = this.serviceContainer.utilsService;
    const portalModeService = this.serviceContainer.portalModeService;

    const currencyList = portalModeService.getProductFormPageHandler().getPriceCurrencyList()
      .map((currency) => {
        const title = currency.toUpperCase();
        return { id: currency, title };
      });

    const formData = formDataHandler.get();
    const pricingModelName = formData.pricingModel.id;

    formData.renewal = item.value as boolean;

    if (item.value && !formData.enableLicenseTerm) {
      formData.enableLicenseTerm = true;
    } else if (!item.value && formData.enableLicenseTerm && !formData.trial) {
      formData.enableLicenseTerm = false;
    }

    if (!formData.renewal) {
      formDataHandler.update(formData);

      return;
    }

    if (pricingModelName === EPricingModelName.ONE_PRICE) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!formData.renewalPrice?.inputValue) {
        formData.renewalPrice = {
          inputValue: '',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          selectValue: currencyList[0],
        };
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formData.sku = formData.sku ?? '';
    } else if (pricingModelName === EPricingModelName.VOLUME_PRICING) {
      if (
        !formData.renewalPrice
          || !pricingTableHelper.isVolumePrice(formData.renewalPrice as TPricingTableValue)
      ) {
        formData.renewalPrice = utilsService.data.cloneImmutable(formData.price);
      }
    } else if (pricingModelName === EPricingModelName.VOLUME_PRICING_AND_OPTIONS) {
      if (
        !formData.renewalPrice
          || !pricingTableHelper.isVolumePriceAndOptions(formData.renewalPrice as TPricingTableValue)
      ) {
        formData.renewalPrice = utilsService.data.cloneImmutable(formData.price);
      }
    } else if (pricingModelName === EPricingModelName.ONE_PRICE_FOR_EACH_CURRENCY) {
      if (
        !formData.renewalPrice
          || !pricingTableHelper.isOnePriceForEachCurrency(formData.renewalPrice as TPricingTableValue)
      ) {
        formData.renewalPrice = utilsService.data.cloneImmutable(formData.price);
      }
    }

    formDataHandler.update(formData);

    this.updateFromConfig();
  };
}

export default RenewalFieldBlurScenario;
