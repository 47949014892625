import IConfig from '@/module/common/components/FormComponent/ValidatorHandler/contract/IConfig';
import EFieldName from '@/module/product/view/FulfillmentFormPage/model/enums/EFieldName';
import IValidator from '@/module/common/utils/validator/contract/IValidator';
import TValidatorConfig from '@/module/product/view/FulfillmentFormPage/model/types/TValidatorConfig';
import Validator from '@/module/common/utils/validator/Validator';
import EValidatorType from '@/module/common/utils/validator/enum/EValidatorType';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import IVueInstance from '@/module/common/models/IVueInstance';
import EScenario from '@/module/product/view/FulfillmentFormPage/model/enums/EScenario';
import { Store } from 'vuex';
import EProtocols from '@/module/common/models/EProtocols';
import ILocalServiceContainer from '@/module/product/view/FulfillmentFormPage/model/ILocalServiceContainer';
import ELocalServiceFulfillment from '@/module/product/view/FulfillmentFormPage/model/enums/ELocalServiceFulfillment';
import IState from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/IState';
import IScenarioService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IScenarioService';
import ValidatorException from '@/module/product/__tests__/FulfillmentFormPage/exception/ValidatorException';

class ValidationConfig implements IConfig<TValidatorConfig, EFieldName, undefined> {
  private state: IState;

  private serviceContainer: IServiceContainer;

  private localServiceContainer: ILocalServiceContainer;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    localServiceContainer: ILocalServiceContainer,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.localServiceContainer = localServiceContainer;
  }

  getConfig(): TValidatorConfig {
    const vueInstance = this.serviceContainer.resolve<IVueInstance>(EServiceContainerInstances.VUE_INSTANCE);
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const localisationRegistry = store.getters.getLocalisationRegistry;
    const localisation = localisationRegistry.validationMessages;

    const messageToManager = new Validator();
    messageToManager.addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 30000 }),
      30000,
    );

    const messageToUser = new Validator();
    messageToUser.addValidator(
      EValidatorType.NOT_EMPTY,
      localisation.required,
    );
    messageToUser.addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 30000 }),
      30000,
    );

    const url = new Validator();
    url.addValidator(
      EValidatorType.NOT_EMPTY,
      localisation.required,
    );
    url.addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      255,
    );
    url.addValidator(
      EValidatorType.URL,
      localisation.url,
      [EProtocols.HTTPS],
    );

    const secretKey = new Validator();
    secretKey.addValidator(
      EValidatorType.NOT_EMPTY,
      localisation.required,
    );
    secretKey.addValidator(
      EValidatorType.MIN_LENGTH,
      vueInstance.$t('validation_messages.min_length', { number: 3 }),
      3,
    );
    secretKey.addValidator(
      EValidatorType.MAX_LENGTH,
      vueInstance.$t('validation_messages.max_length', { number: 255 }),
      255,
    );

    const map: { [key in EScenario]: TValidatorConfig } = {
      [EScenario.SEND_EMAIL_TO_MANAGER]: {
        messageToManager,
      },
      [EScenario.CREATE_GENERATOR]: {
        secretKey,
        url,
        messageToUser,
      },
      [EScenario.MOCK_GENERATOR]: {} as TValidatorConfig,
      [EScenario.DEFAULT]: {} as TValidatorConfig,
      [EScenario.TEST_GENERATOR]: {} as TValidatorConfig,
    };

    const scenarioService = this.localServiceContainer
      .resolve<IScenarioService>(ELocalServiceFulfillment.SCENARIO_SERVICE);
    const scenarioName = scenarioService.getSaveScenarioName(this.state.licenseSourceName);

    return map[scenarioName];
  }

  getValidator(fieldName: EFieldName): IValidator {
    const config = this.getConfig();

    if (!config[fieldName]) {
      throw new ValidatorException(`Validator for field '${fieldName}' not exists.`);
    }

    return config[fieldName];
  }

  hasValidator(fieldName: EFieldName): boolean {
    const config = this.getConfig();

    return !!config[fieldName];
  }
}

export default ValidationConfig;
