
import Vue, {
  computed,
  onMounted,
  PropType,
  reactive,
  watch,
} from 'vue';

import EDSFieldStatus from '@/module/design-system/models/UI/EDSFieldStatus';

import generateUID from '@/module/common/utils/generate/generateUID';

export default Vue.extend({
  name: 'DSTextarea',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    status: {
      type: String as PropType<EDSFieldStatus>,
      default: EDSFieldStatus.DEFAULT,
    },
    value: {
      type: String,
      required: true,
    },
    rows: {
      type: Number,
      default: 5,
    },
  },
  setup(props, { emit }) {
    const state = reactive<{ currentValue: null | string; }>({
      currentValue: null,
    });

    const classes = computed(() => [
      'ds-textarea',
      `ds-textarea_${props.status}`,
    ]);

    const uid = generateUID();

    onMounted(() => {
      state.currentValue = props.value;
    });

    watch(() => props.value, (newValue) => {
      state.currentValue = newValue;
    });

    function handleBlur() {
      emit('blur', state.currentValue);
    }
    function handleInput() {
      emit('input', state.currentValue);
    }
    function handleKeydown(event) {
      emit('keydown', event);
    }

    return {
      state,
      classes,
      uid,
      handleBlur,
      handleInput,
      handleKeydown,
    };
  },
});
