export default function array(vm) {
  return {
    includesObject(list, object, listIdField = 'id', objectIdField = 'id') {
      return !!this.find(list, object, listIdField, objectIdField);
    },
    find(list, object, listIdField = 'id', objectIdField = 'id') {
      return list
        .find((item) => vm.$utils.data.readProp(item, listIdField) === vm.$utils.data.readProp(object, objectIdField));
    },
    intersect(arrayA, arrayB) {
      const intersection = arrayA.filter((arrayItem) => arrayB.includes(arrayItem));
      return Array.from(new Set(intersection));
    },
  };
}
