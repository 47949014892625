<template>
  <portal to="modal">
    <div
      ref="modal"
      class="modal"
      tabindex="0"
      @click="closeModal"
      @keydown.esc="closeModal"
      @keydown.enter="onSubmit"
    >
      <div
        class="content"
        :class="classes"
        @click.stop
      >
        <slot />
      </div>
    </div>
  </portal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ModalComponent',
  computed: {
    ...mapGetters({
      isMenuMinimized: 'commonStatusesStore/getIsMenuMinimized',
    }),
    classes() {
      return { content_wide: this.isMenuMinimized };
    },
  },
  mounted() {
    document.body.style.overflow = 'hidden';
    this.$nextTick(() => {
      this.$refs.modal.focus();
    });
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    onSubmit() {
      this.$emit('submitModal');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($color: black, $alpha: 0.2);
  z-index: 1000;

  .content {
    max-width: 414px;
    background-color: white;
    padding: 32px;
    margin-left: 255px;
    box-shadow: 0 4px 8px rgba(23, 28, 32, 0.2);
    border-radius: 20px;

    &_wide {
      margin-left: 64px;
    }

    @media screen and (max-width: 670px) {
      margin: 10px;
    }
  }
}
</style>
