
import Vue from 'vue';
import PreloaderComponent from '@/components/Preloader/PreloaderComponent.vue';

export default Vue.extend({
  name: 'CFormPageContent',
  components: {
    PreloaderComponent,
  },
  props: {
    showPreloader: {
      type: Boolean,
      default: false,
    },
  },
});
