import IFormFieldBlurScenario
  from '@/module/product/view/FulfillmentFormPage/model/contract/scenario/IFormFieldBlurScenario';
import IState from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/IState';
import THandleFieldBlurParams from '@/module/product/view/FulfillmentFormPage/model/types/THandleFieldBlurParams';
import ILocalServiceContainer from '@/module/product/view/FulfillmentFormPage/model/ILocalServiceContainer';

abstract class AbstractBlurFieldScenario implements IFormFieldBlurScenario {
  protected readonly state: IState;

  protected readonly localServiceContainer: ILocalServiceContainer;

  constructor(
    state: IState,
    localServiceContainer: ILocalServiceContainer,
  ) {
    this.state = state;
    this.localServiceContainer = localServiceContainer;
  }

  abstract run(item: THandleFieldBlurParams);
}

export default AbstractBlurFieldScenario;
