enum EFieldName {
  LICENSE_SOURCE = 'licenseSource',
  MESSAGE_TO_MANAGER = 'messageToManager',
  URL = 'url',
  METHOD = 'method',
  SECRET_KEY = 'secretKey',
  LICENSE_AMOUNT = 'licenseAmount',
  SEND_LICENSE = 'sendLicense',
  MESSAGE_TO_USER = 'messageToUser',
  PARAMETERS = 'parameters',
  SEND_LICENSE_ALERT = 'sendLicenseAlert',
}

export default EFieldName;
