import ILocalStorageService from '@/module/common/service/LocalStorage/ILocalStorageService';
import ELocalStorageKeys from '@/module/common/service/LocalStorage/ELocalStorageKeys';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IUtilsService from '@/module/common/service/UtilsService/contract/IUtilsService';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';

class LocalStorageService implements ILocalStorageService {
  private readonly serviceContainer: IServiceContainer;

  constructor(
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
  }

  clear = () => {
    localStorage.clear();
  };

  remove = (key: ELocalStorageKeys) => {
    localStorage.removeItem(key);
  };

  // TODO: решить вопрос с number значениями
  read = <Value>(key: ELocalStorageKeys): Value | undefined => {
    const utilsService = this.serviceContainer.resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);

    const storageValue = localStorage.getItem(key);
    if (storageValue === 'null') {
      return null as unknown as Value;
    }

    if (storageValue === 'undefined') {
      return undefined;
    }

    if (utilsService.typeCheck.isNull(storageValue)) {
      return undefined as unknown as Value;
    }

    if (storageValue === 'true') {
      return true as unknown as Value;
    }

    if (storageValue === 'false') {
      return false as unknown as Value;
    }

    try {
      return utilsService.json.from<Value>(storageValue);
    } catch (e) {
      return storageValue as unknown as Value;
    }
  };

  write = <Value>(key: ELocalStorageKeys, value: Value) => {
    const utilsService = this.serviceContainer.resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);

    if (utilsService.typeCheck.isString(value)) {
      localStorage.setItem(key, value);
    } else if (utilsService.typeCheck.isNumber(value)) {
      localStorage.setItem(key, `${value}`);
    } else if (utilsService.typeCheck.isNull(value)) {
      localStorage.setItem(key, 'null');
    } else if (utilsService.typeCheck.isObject(value)) {
      localStorage.setItem(key, utilsService.json.to(value));
    } else if (utilsService.typeCheck.isArray(value)) {
      localStorage.setItem(key, utilsService.json.to(value));
    } else if (utilsService.typeCheck.isSet(value)) {
      localStorage.setItem(key, `${value}`);
    } else {
      localStorage.setItem(key, 'undefined');
    }
  };
}

export default LocalStorageService;
