// TODO: Удалить решения с передачей query строкой.
//  Сбор строки запроса - обязанность сервиса апи.
export default function productModule(instance) {
  return {
    getProducts(query = '', token = false) {
      return instance.get(`vendor/product${query}`, { cancelToken: token });
    },
    getProduct(id) {
      return instance.get(`/vendor/product/${id}`);
    },
    deleteProduct(id) {
      return instance.delete(`/vendor/product/${id}`);
    },
    createProduct(payload) {
      return instance.post('/vendor/product', payload);
    },
    updateProduct(id, payload) {
      return instance.put(`/vendor/product/${id}`, payload);
    },
    loadIcon(payload) {
      return instance.post('vendor/product/icon', payload);
    },
  };
}
