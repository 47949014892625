<template>
  <svg
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 96 96"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      d="M48 56C47.0111 56 46.0444 56.2932 45.2222 56.8427C44.3999 57.3921 43.7591 58.173 43.3806 59.0866C43.0022 60.0002 42.9032 61.0055 43.0961 61.9755C43.289 62.9454 43.7652 63.8363 44.4645 64.5355C45.1637 65.2348 46.0547 65.711 47.0246 65.9039C47.9945 66.0968 48.9998 65.9978 49.9134 65.6194C50.8271 65.241 51.608 64.6001 52.1574 63.7779C52.7068 62.9556 53 61.9889 53 61C53 59.6739 52.4732 58.4021 51.5356 57.4645C50.5979 56.5268 49.3261 56 48 56ZM48 50C49.0609 50 50.0783 49.5786 50.8284 48.8284C51.5786 48.0783 52 47.0609 52 46V34C52 32.9391 51.5786 31.9217 50.8284 31.1716C50.0783 30.4214 49.0609 30 48 30C46.9392 30 45.9217 30.4214 45.1716 31.1716C44.4214 31.9217 44 32.9391 44 34V46C44 47.0609 44.4214 48.0783 45.1716 48.8284C45.9217 49.5786 46.9392 50 48 50ZM48 8C40.0888 8 32.3552 10.346 25.7772 14.7412C19.1992 19.1365 14.0723 25.3836 11.0448 32.6927C8.01733 40.0017 7.2252 48.0444 8.76861 55.8036C10.312 63.5629 14.1216 70.6902 19.7157 76.2843C25.3098 81.8784 32.4372 85.688 40.1964 87.2314C47.9556 88.7748 55.9983 87.9827 63.3074 84.9552C70.6164 81.9277 76.8635 76.8008 81.2588 70.2228C85.6541 63.6448 88 55.9113 88 48C87.9884 37.3949 83.7704 27.2275 76.2714 19.7286C68.7725 12.2297 58.6051 8.01165 48 8ZM48 80C41.671 80 35.4841 78.1232 30.2218 74.607C24.9594 71.0908 20.8579 66.0931 18.4359 60.2459C16.0139 54.3986 15.3802 47.9645 16.6149 41.7571C17.8496 35.5497 20.8973 29.8479 25.3726 25.3726C29.8479 20.8973 35.5497 17.8496 41.7571 16.6149C47.9645 15.3801 54.3987 16.0139 60.2459 18.4359C66.0931 20.8579 71.0908 24.9594 74.607 30.2218C78.1232 35.4841 80 41.671 80 48C79.9894 56.4837 76.6146 64.6169 70.6157 70.6157C64.6169 76.6146 56.4837 79.9894 48 80Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSAlertIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>
