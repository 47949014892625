enum ELocalServiceFulfillment {
  GENERATOR_MOCK_SERVICE = 'generatorMockService',
  HANDLER_SERVICE = 'handlerService',
  LICENSE_AMOUNT_SERVICE = 'licenseAmountService',
  MAPPER_SERVICE = 'mapperService',
  SCENARIO_SERVICE = 'scenarioService',
  HTTP_METHOD_SERVICE = 'httpMethodService',
  HEADER_CONTROL_SERVICE = 'headerControlService',
}

export default ELocalServiceFulfillment;
