
import CFileList from '@/module/common/components/UI/CFileUpload/CFileList.vue';
import DSFileUploadArea from '@/module/design-system/components/UI/DSFileUploadArea.vue';
import {
  computed,
  onMounted,
  PropType,
  reactive, ref, watch,
} from 'vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import ICFileUploadOptions from '@/module/common/models/UI/FileUpload/ICFileUploadOptions';
import EFileExtensions from '@/module/design-system/components/models/DSFileUpload/EFileExtensions';
import EFileMimeTypes from '@/module/design-system/components/models/DSFileUpload/EFileMimeTypes';
import EFileStatus from '@/module/common/models/UI/FileUpload/EFileStatus';
import IFile from '@/module/common/models/UI/FileUpload/IFile';
import cloneImmutable from '@/module/common/utils/data/cloneImmutable';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import changeFileName from '@/module/common/utils/file/changeFileName';
import getFileName from '@/module/common/utils/file/getFileName';

interface IStateCFileUploadArea {
  files: IFile[];
}

export default {
  name: 'CFileUploadArea',
  props: {
    options: {
      type: Object as PropType<ICFileUploadOptions>,
      required: true,
    },
    files: {
      type: Array as PropType<IFile[]>,
      default: () => [],
    },
    label: {
      type: String,
      default: () => '',
    },
    description: {
      type: String,
      default: () => null,
    },
    status: {
      type: Object as PropType<{ code: string; text: string; }>,
      default: () => ({}),
    },
  },
  components: {
    CFileList,
    DSButton,
    DSFileUploadArea,
    DSIcon,
    DSTextElement,
  },
  setup(props, { emit }) {
    const state: IStateCFileUploadArea = reactive<IStateCFileUploadArea>({
      files: [],
    });
    const dsFileUploadAreaRef = ref();

    const fileExtensionsMap = {
      [EFileMimeTypes.JPG]: [
        EFileExtensions.JPG,
        EFileExtensions.JPEG,
      ],
      [EFileMimeTypes.PDF]: [
        EFileExtensions.PDF,
      ],
      [EFileMimeTypes.JPEG]: [
        EFileExtensions.JPG,
        EFileExtensions.JPEG,
      ],
      [EFileMimeTypes.DOC]: [
        EFileExtensions.DOC,
      ],
      [EFileMimeTypes.DOCX]: [
        EFileExtensions.DOCX,
      ],
      [EFileMimeTypes.PNG]: [
        EFileExtensions.PNG,
      ],
    };

    const fileExtensionsText = computed(() => {
      if (props.options.extensionsString) {
        return props.options.extensionsString;
      }

      const mimeTypes = (props.options.allowedMimeTypes ?? [])
        .reduce((mimeTypesSet, mimeType) => {
          fileExtensionsMap[mimeType].forEach((mimeType) => mimeTypesSet.add(mimeType));

          return mimeTypesSet;
        }, new Set());

      return Array
        .from(mimeTypes)
        .map((mimeType) => `.${mimeType}`)
        .join(', ');
    });

    function mapFiles(fileList: FileList): IFile[] {
      const fileNames = state.files.map((file) => file.fileName);
      const list: File[] = Array.from(fileList);

      return list.map((file) => {
        if (fileNames.includes(file.name)) {
          file = changeFileName(file, getFileName(file.name, fileNames));
        }

        return {
          file,
          fileName: file.name,
          status: EFileStatus.DEFAULT,
          errors: [],
        };
      });
    }

    function handleInput(event: FileList) {
      const files = mapFiles(event);

      state.files = [...state.files, ...files];

      emit('input', state.files);
    }

    function handleDeleteFile(file: IFile) {
      emit('delete-file', file);
    }

    function handleDownloadFile(file: IFile) {
      emit('download-file', file);
    }

    function handleButtonCLick() {
      dsFileUploadAreaRef.value?.$refs?.element?.click();
    }

    onMounted(() => {
      state.files = props.files.map((file) => cloneImmutable(file));
    });

    watch(() => props.files, (newValue) => {
      state.files = newValue.map((file) => cloneImmutable(file));
    });

    return {
      handleInput,
      handleDeleteFile,
      handleDownloadFile,
      handleButtonCLick,
      fileExtensionsText,
      state,
      dsFileUploadAreaRef,
    };
  },
};
