enum EFileExtensions {
  JPEG = 'jpeg',
  JPG = 'jpg',
  DOC = 'doc',
  DOCX = 'docx',
  PDF = 'pdf',
  PNG = 'png',
}

export default EFileExtensions;
