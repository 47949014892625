import EmptyFieldStatusHandler
  from '@/module/common/components/FormComponent/ValidatorHandler/EmptyFieldStatusHandler';
import SimpleFieldStatusHandler
  from '@/module/common/components/FormComponent/ValidatorHandler/SimpleFieldStatusHandler';
import ValidateHandler from '@/module/common/components/FormComponent/ValidatorHandler/ValidateHandler';
import ValidationConfig from '@/module/product/view/PaymentLink/PaymentLinkFormPage/config/ValidationConfig';

import EFieldName from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/enum/EFieldName';

import IFieldStatusHandler
  from '@/module/common/components/FormComponent/ValidatorHandler/contract/IFieldStatusHandler';
import IFormData from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/interface/IFormData';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IValidateHandler from '@/module/common/components/FormComponent/ValidatorHandler/contract/IValidateHandler';
import IValidateHandlerService
  from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/contract/IValidateHandlerService';
import TValidatorConfig from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/type/TValidatorConfig';

class ValidateHandlerService implements IValidateHandlerService {
  private readonly serviceContainer: IServiceContainer;

  private _fieldValidatorHandler?: IValidateHandler<TValidatorConfig, IFormData, EFieldName>;

  private _formValidatorHandler?: IValidateHandler<TValidatorConfig, IFormData, EFieldName>;

  private _emptyFieldStatusHandler?: IFieldStatusHandler;

  private _simpleFieldStatusHandler?: IFieldStatusHandler;

  constructor(
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
  }

  get emptyFieldStatusHandler(): IFieldStatusHandler {
    if (!this._emptyFieldStatusHandler) {
      this._emptyFieldStatusHandler = new EmptyFieldStatusHandler();
    }

    return this._emptyFieldStatusHandler;
  }

  get simpleFieldStatusHandler(): IFieldStatusHandler {
    if (!this._simpleFieldStatusHandler) {
      this._simpleFieldStatusHandler = new SimpleFieldStatusHandler();
    }

    return this._simpleFieldStatusHandler;
  }

  get fieldValidatorHandler(): IValidateHandler<TValidatorConfig, IFormData, EFieldName> {
    if (!this._formValidatorHandler) {
      const config = new ValidationConfig(
        this.serviceContainer,
      );

      this._formValidatorHandler = new ValidateHandler(
        config,
        this.emptyFieldStatusHandler,
      );
    }

    return this._formValidatorHandler;
  }

  get formValidatorHandler(): IValidateHandler<TValidatorConfig, IFormData, EFieldName> {
    if (!this._fieldValidatorHandler) {
      const config = new ValidationConfig(
        this.serviceContainer,
      );

      this._fieldValidatorHandler = new ValidateHandler(
        config,
        this.simpleFieldStatusHandler,
      );
    }

    return this._fieldValidatorHandler;
  }
}

export default ValidateHandlerService;
