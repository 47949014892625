<template>
  <div
    v-click-outside="handleClickOutside"
    class="filter-date-picker"
  >
    <button
      class="filter-date-picker__dropdown-button"
      :class="[
        state.isMenuOpened
          ? 'filter-date-picker__dropdown-button--active active'
          : null,
        disabled
          ? 'filter-date-picker__dropdown-button--disabled'
          : null,
      ]"
      type="button"
      @click="handleToggleDropdown"
    >
      <span class="filter-date-picker__icon filter-date-picker__dropdown-icon-left">
        <DSIcon
          icon="calendar"
        />
      </span>
      <span class="filter-date-picker__dropdown-text">
        {{ selectedOption.name }}
      </span>
      <span class="filter-date-picker__icon filter-date-picker__dropdown-icon-right">
        <DSIcon
          icon="arrowDown"
        />
      </span>
    </button>

    <div
      v-if="state.isMenuOpened"
      class="filter-date-picker__dropdown"
    >
      <div class="filter-date-picker__options">
        <div class="filter-date-picker__option-lists">
          <ul
            v-for="(optionList, index) in optionLists"
            :key="`optionList-${index}`"
            class="filter-date-picker__option-list"
          >
            <li
              v-for="optionButton in optionList"
              :key="optionButton.value"
              class="filter-date-picker__option-item"
              @click="handleClickOption(optionButton)"
            >
              <div
                :class="getOptionButtonClasses(optionButton)"
              />
              {{ $t(optionButton.name) }}
            </li>
          </ul>
        </div>
        <div
          v-if="state.selectionMode !== 'NC'"
          class="filter-date-picker__input-group"
        >
          <!-- знак  `  в маске означает удержание позиции символа -->
          <MaskedInput
            class="filter-date-picker__input"
            :value="inputValueFrom"
            :mask="'0`0`/0`0`/0`0`0`0`'"
            :lazy="false"
            :overwrite="true"
            @focus="handleFocusInput"
            @change="event => handleInputDate(event, 'start')"
          />

          <div class="filter-date-picker__icon filter-date-picker__arrow-icon">
            <DSIcon
              icon="tailArrowLeft"
            />
          </div>

          <MaskedInput
            class="filter-date-picker__input"
            :value="inputValueTo"
            :mask="'0`0`/0`0`/0`0`0`0`'"
            :lazy="false"
            :overwrite="true"
            @focus="handleFocusInput"
            @change="event => handleInputDate(event, 'end')"
          />
        </div>
      </div>

      <vDatePicker
        v-if="state.selectionMode === 'CM'"
        ref="datePickerRef"
        v-model="state.datePair"
        class="filter-date-picker__calendar filter-date-picker__options"
        trim-weeks
        :attributes="attributes"
        :is-range="true"
        :popover="popoverOptions"
        :min-date="minDate"
        :max-date="maxDate"
        :locale="localeSetting"
      />

      <div class="filter-date-picker__controls filter-date-picker__options">
        <button
          class="filter-date-picker__button filter-date-picker__submit-button"
          type="button"
          @click="handleSubmitClick"
        >
          {{ $t('reports.overview.date_picker_controls.apply') }}
        </button>
        <button
          class="filter-date-picker__button filter-date-picker__cancel-button"
          type="button"
          @click="handleCancelClick"
        >
          {{ $t('reports.overview.date_picker_controls.cancel') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import { IMaskComponent as MaskedInput } from 'vue-imask';
import { DatePicker as vDatePicker } from 'v-calendar';
import { mapState } from 'vuex';

import cloneImmutable from '@/module/common/utils/data/cloneImmutable';

export default {
  name: 'FilterDatePicker',
  components: {
    DSIcon,
    vDatePicker,
    MaskedInput,
  },
  props: {
    dateFrom: {
      type: Date,
      default: null,
    },
    dateTo: {
      type: Date,
      default: null,
    },
    actualSelectionMode: {
      type: String,
      default: 'L30D',
    },
    minDate: {
      type: Date,
      default: new Date('1/1/2020'),
    },
    maxDate: {
      type: Date,
      default: new Date(),
    },
    localeOptions: {
      type: Array,
      default: () => [
        { id: 'en', firstDayOfWeek: 2, masks: { weekdays: 'WW' } },
        { id: 'ru', masks: { weekdays: 'WW' } },
      ],
    },
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      state: {
        datePair: {
          start: this.dateFrom || new Date(),
          end: this.dateTo || new Date(),
        },
        selectionMode: this.actualSelectionMode,
        isMenuOpened: false,
      },
      dateStringOptions: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      },
      monthNamesList: [
        'months_short.jan',
        'months_short.feb',
        'months_short.mar',
        'months_short.apr',
        'months_short.may',
        'months_short.jun',
        'months_short.jul',
        'months_short.aug',
        'months_short.sep',
        'months_short.oct',
        'months_short.nov',
        'months_short.dec',
      ],
    };
  },
  computed: {
    ...mapState({
      locale: (state) => state.locale,
    }),
    localeSetting() {
      return this.localeOptions.find((el) => el.id === this.locale);
    },
    attributes() {
      return [
        {
          bar: true,
          dates: this.state.datePair.end
            ? this.state.datePair.end
            : this.state.datePair,
        },
      ];
    },
    popoverOptions() {
      return {
        isInteractive: true,
        keepVisibleOnInput: true,
        placement: 'bottom',
        positionFixed: true,
      };
    },
    optionLists() {
      const length = this.options.length;
      const half = Math.round(length / 2);

      const leftColumn = cloneImmutable(this.options);
      const rightColumn = leftColumn.splice(half, length);

      return [
        leftColumn,
        rightColumn,
      ];
    },
    selectedOption() {
      const options = Object.values(this.options);

      const currentOption = options.find((option) => option.value === this.actualSelectionMode);
      if (currentOption.value === 'CM') {
        const dateStart = new Date(this.state.datePair.start);
        const dateEnd = new Date(this.state.datePair.end);
        const currentYear = new Date().getFullYear();
        let dateStartString = this.$utils.date.formatWithYear(this.state.datePair.start);
        let dateEndString = this.$utils.date.formatWithYear(this.state.datePair.end);

        if (dateStart.getFullYear() === currentYear && dateEnd.getFullYear() === currentYear) {
          dateStartString = this.$utils.date.formatWithoutYear(this.state.datePair.start);
          dateEndString = this.$utils.date.formatWithoutYear(this.state.datePair.end);
        }

        return {
          value: 'CM',
          name: `${dateStartString}–${dateEndString}`,
        };
      }

      return {
        value: currentOption.value,
        name: this.$t(currentOption.name),
      };
    },
    inputValueFrom() {
      const date = this.state.datePair.start;
      const dateString = date.toLocaleDateString('ru', this.dateStringOptions);

      return dateString.replaceAll('.', '/');
    },
    inputValueTo() {
      const date = this.state.datePair.end;
      const dateString = date.toLocaleDateString('ru', this.dateStringOptions);

      return dateString.replaceAll('.', '/');
    },
  },
  watch: {
    dateFrom() {
      this.state.datePair.start = this.dateFrom;
      if (this.state.selectionMode === 'CM') {
        this.$refs.datePickerRef.focusDate(new Date(this.dateFrom));
      }
    },
    dateTo() {
      this.state.datePair.end = this.dateTo;
      if (this.state.selectionMode === 'CM') {
        this.$refs.datePickerRef.focusDate(new Date(this.dateTo));
      }
    },
    actualSelectionMode() {
      this.state.selectionMode = this.actualSelectionMode;
    },
  },
  methods: {
    getOptionButtonClasses(optionButton) {
      return [
        'filter-date-picker__checkbox',
        this.state.selectionMode === optionButton.value ? 'filter-date-picker__checkbox--checked' : null,
      ];
    },
    handleFocusInput() {
      if (this.state.selectionMode.value !== 'CM') {
        this.handleClickOption(this.options.find((option) => option.value === 'CM'));
      }
    },
    handleInputDate(event, type) {
      const [DD, MM, YYYY] = event.target.value.split('/');
      const date = new Date(`${MM}/${DD}/${YYYY}`);

      if (new Date(this.minDate).getTime() > date.getTime()) {
        this.state.datePair = { ...this.state.datePair, [type]: new Date(this.minDate) };
        return;
      }

      if (new Date(this.maxDate).getTime() < date.getTime()) {
        this.state.datePair = { ...this.state.datePair, [type]: new Date(this.maxDate) };
        return;
      }

      if (Number.isNaN(date.getTime())) {
        this.state.datePair = { ...this.state.datePair };
        setTimeout(() => {
          event.target.value = type === 'start'
            ? this.dateFrom.toLocaleDateString('ru', this.dateStringOptions).replaceAll('.', '/')
            : this.dateTo.toLocaleDateString('ru', this.dateStringOptions).replaceAll('.', '/');
        }, 0);
        return;
      }

      this.state.datePair = { ...this.state.datePair, [type]: date };
      this.$refs.datePickerRef.focusDate(date);
    },
    handleClickOption(option) {
      this.$emit('change-option', option);
    },
    handleSubmitClick() {
      this.$emit('submit', {
        datePair: this.state.datePair,
        selectionMode: this.state.selectionMode,
      });

      this.state.isMenuOpened = false;
    },
    handleCancelClick() {
      this.state.isMenuOpened = false;
      this.$emit('hide-dropdown', { needToRewriteState: true });
    },
    async handleToggleDropdown() {
      const isMenuOpened = this.state.isMenuOpened;
      this.state.isMenuOpened = !isMenuOpened;

      if (isMenuOpened) {
        this.$emit('hide-dropdown', { needToRewriteState: false });
      } else {
        this.$emit('show-dropdown');
      }
    },
    async handleClickOutside() {
      if (this.state.isMenuOpened) {
        this.state.isMenuOpened = false;
        this.$emit('hide-dropdown', false);
      }
    },
  },
};
</script>

<style lang="scss">
  .filter-date-picker {
    position: relative;
    display: flex;
    width: 210px;

    &__dropdown-button {
      border: 2px solid #ECEEF2;
      width: 100%;
      height: 48px;
      background-color: white;
      border-radius: 8px;
      display: flex;
      align-items: center;
    }

    &__dropdown-button--disabled {
      background-color: #F8F8FA;
      pointer-events: none;
      cursor: none;
    }

    &__dropdown-text {
      font-family: $text-ibm-plex-sans;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      width: 100%;
      text-align: left;
    }

    &__dropdown {
      position: absolute;
      top: calc(100% + 10px);
      left: 0;
      z-index: 5;
      display: flex;
      flex-direction: column;
      width: max-content;
      min-width: 339.5px;
      max-width: calc(100vw - 32px);
      border-radius: 8px;
      background-color: white;
      box-shadow: 0 4px 24px 0 #0000001a;
    }

    &__options {
      border: 2px solid #ECEEF2;
      border-top-width: 0.5px;
      border-bottom-width: 0.5px;

      &:first-child {
        border-top-width: 2px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }

    &__input-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 12px;
      gap: 8px;
    }

    &__input {
      display: flex;
      flex: 1 0 130px;
      width: 130px;
      padding: 5px 16px;
      font-family: $text-ibm-plex-sans;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      line-height: 22px;
      letter-spacing: 0;
      color: #000000a6;
      border: 2px solid #eceef2;
      border-radius: 8px;
    }

    &__invalid {
      border-color: #EB0019;
    }

    &__icon {
      display: flex;
      margin: 10px;

      svg {
        fill: #B4B4B3;
      }
    }

    &__dropdown-button--active {
      border-color: #4397CB;
    }

    &__dropdown-icon-right {
      transition: 0.2s;
    }

    &__dropdown-button--active &__dropdown-icon-right {
      transform: rotate(180deg);
    }

    &__arrow-icon {
      margin: 0;
      transform: scaleX(-1);
    }

    &__option-lists {
      display: flex;
      flex-wrap: wrap;
      margin: 12px 8px;
    }

    &__option-list {
      margin: 0;
      padding: 0;
    }

    &__option-item {
      padding: 8px 12px;
      display: flex;
      align-items: center;
      font-family: $text-ibm-plex-sans;
      color: #282828;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      cursor: pointer;
    }

    &__checkbox {
      margin-right: 8px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #C7C7CD;
      transition: 0.2s;
    }

    &__checkbox--checked {
      border: 5px solid #4397CB
    }

    &__option-item:hover &__checkbox {
      border-color: #2D55A5;
    }

    &__controls {
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-bottom-width: 2px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      transition: 0.2s;
    }

    &__button {
      margin: 0;
      border: none;
      background-color: transparent;
      height: 50px;
      font-family: $text-ibm-plex-sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;

      &:hover {
        background-color: #F5F5F5;
      }

      &:active {
        background-color: #DDDDDD;
      }
    }

    &__submit-button {
      border-right: 0.5px solid #ECEEF2;
      color: #2D83B8;
    }

    &__cancel-button {
      border-left: 0.5px solid #ECEEF2;
      color: #FF4444;
    }

    &__calendar {

      &.vc-container, &.vc-pane {
        border-radius: 0;
        border: none;
        margin: 0 auto;
      }

      .vc-pane-container {
        --blue-200: #EFEFF1;
        --blue-600: #388FC4;
        --blue-700: #2D83B8;
        --blue-900: #282828;
        --font-bold: 500;
        --rounded-full: 8px;
      }

      .vc-pane-layout {
        // width: ;
        padding: 16px 37px;
      }

      .vc-arrows-container.title-center {
        padding: 24px 55px;
      }

      .vc-header {
        padding-bottom: 10px;
        height: 40px;
        align-items: baseline !important;
      }

      .vc-arrow svg {
        height: 20px;
        width: 20px;
        fill: #92929D;
      }

      .vc-title {
        font-family: $text-ibm-plex-sans;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 22px !important;
        letter-spacing: 0;
        color: #2D83B8 !important;
        text-transform: capitalize !important;
      }

      .vc-weekday {
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 16px !important;
        letter-spacing: 0;
        color: #92929D !important;
        text-transform: uppercase !important;
      }

      .vc-day-content {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0;
        text-align: center;
      }

      .vc-day.is-not-in-month *  {
        pointer-events: initial !important;
        opacity: 0.3 !important;
        color: #EB0019;
      }

      .vc-day.is-not-in-month > .vc-highlights *,
      .vc-day.is-not-in-month > .vc-highlights {
        opacity: 1 !important;
      }

      .vc-day.is-not-in-month > .vc-highlights ~ .vc-day-content {
        opacity: 0.5 !important;
      }

      .vc-day.is-not-in-month > .vc-highlights ~ .vc-day-box-center-bottom * {
        opacity: 1 !important;
      }

      .vc-day.is-not-in-month > .vc-highlights > .vc-highlight {
        opacity: 0.5 !important;
      }

      .vc-highlights.vc-day-layer .vc-highlight,
      .vc-day-content,
      .vc-day {
        height: 36px !important;
        width: 36px !important;
      }

      .vc-day-content,
      .vc-day {
        position: relative;
        font-family: $text-ibm-plex-sans;
        margin: 2px 0;

      }

      .vc-bars {
        position: absolute;
        width: 15px !important;
        height: 3px;
        bottom: 7px;
        left: 50%;
        transform: translate(-50%);
        border-radius: 4px;
        overflow: hidden;
        z-index: 2;
      }

      .vc-highlights ~ * .vc-bars {
        --blue-600: white;
      }

      .vc-day.weekday-position-1 .vc-highlights {
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
      }

      .vc-day.weekday-position-7 .vc-highlights {
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
      }

      .vc-day.weekday-position-6,
      .vc-day.weekday-position-7 {
        color: #FF4444;
        --blue-900: #FF4444;
      }

      .vc-highlight {
        border-width: 0 !important;
      }

      .vc-day-layer.vc-day-box-center-center {
        --white: var(--blue-600);
      }

      .vc-highlights .vc-highlight.vc-highlight-base-start,
      .vc-day.is-not-in-month > .vc-highlights .vc-highlight.vc-highlight-base-start {
        opacity: 1 !important;
        --blue-200: var(--blue-600);
        width: 36px !important;
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
      }

      .vc-highlights .vc-highlight.vc-highlight-base-end,
      .vc-day.is-not-in-month > .vc-highlights .vc-highlight.vc-highlight-base-end  {
        opacity: 1 !important;
        --blue-200: var(--blue-600);
        width: 36px !important;
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
      }

      .vc-day.is-not-in-month > .vc-highlights.vc-day-layer.vc-day-box-center-bottom,
      .vc-day.is-not-in-month > .vc-highlights.vc-day-layer.vc-day-box-center-bottom * {
        opacity: 1 !important;
      }

      .vc.highlights ~ .vc-day.is-not-in-month .vc-day-layer.vc-day-box-center-bottom,
      .vc.highlights ~ .vc-day.is-not-in-month .vc-day-layer.vc-day-box-center-bottom * {
        --blue-600: white;
        opacity: 1 !important;
      }

      .vc-day-content:hover, .vc-day-content:focus {
        background-color: inherit !important;
      }
    }
  }
</style>
