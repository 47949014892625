<template>
  <COverviewPageLayout>
    <template
      #header
    >
      <COverviewPageHeaderRow
        :page-title="localisation.title"
        :tooltip-text="localisation.tooltip"
      />
    </template>
    <template
      #content
    >
      <div class="info-block">
        <DSHeader
          :size="3"
          :text="localisation.infoBlocks.brandColors.title"
        />
        <CCard
          :title="localisation.infoBlocks.brandColors.subtitle"
        >
          <template
            #text-slot
          >
            <div class="info-block__list">
              <div class="info-block__list-header">
                <DSTextElement>
                  <!-- eslint-disable vue/no-v-html -->
                  <span
                    v-html="availableOptionsTitle"
                  />
                  <!-- eslint-enable vue/no-v-html -->
                </DSTextElement>
              </div>
              <DSMarkedList
                :list="availableOptionsList"
              />
            </div>
          </template>
          <template
            #footer-left
          >
            <div class="info-block__buttons">
              <DSButton
                :text="localisation.infoBlocks.brandColors.buttons.setUpWithSupport"
                stretch="sm"
                @click="handleBrandColorsSetUp"
              />
              <DSButton
                :text="localisation.infoBlocks.brandColors.buttons.howItWorks"
                variant="tretiary"
                stretch="sm"
                @click="handleBrandColorsHowItWorks"
              />
            </div>
          </template>
          <template
            #footer-right
          >
            <div
              class="info-block__right"
            >
              <DSTextElement
                variant="small"
                color="secondary"
              >
                {{ localisation.infoBlocks.brandColors.repliesMayTake }}
              </DSTextElement>
            </div>
          </template>
        </CCard>
      </div>
      <div class="info-block">
        <DSHeader
          :size="3"
          :text="localisation.infoBlocks.customDomain.title"
        />
        <CCard
          :title="localisation.infoBlocks.customDomain.subtitle"
          :text="customDomainText"
        >
          <template
            #footer-left
          >
            <div class="info-block__buttons">
              <DSButton
                :text="localisation.infoBlocks.customDomain.buttons.setUpWithSupport"
                stretch="sm"
                @click="handleCustomDomainSetUpWithSupport"
              />
              <DSButton
                :text="localisation.infoBlocks.customDomain.buttons.howItWorks"
                variant="tretiary"
                stretch="sm"
                @click="handleCustomDomainHowItWorks"
              />
            </div>
          </template>
          <template
            #footer-right
          >
            <div
              class="info-block__right"
            >
              <DSTextElement
                variant="small"
                color="secondary"
              >
                {{ localisation.infoBlocks.customDomain.repliesMayTake }}
              </DSTextElement>
            </div>
          </template>
        </CCard>
      </div>
    </template>
  </COverviewPageLayout>
</template>

<script>
import {
  inject,
} from 'vue';

import CCard from '@/module/common/components/CCard.vue';
import COverviewPageHeaderRow from '@/module/common/components/PageParts/COverviewPageHeaderRow.vue';
import COverviewPageLayout from '@/module/common/components/PageParts/COverviewPageLayout.vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSHeader from '@/module/design-system/components/Text/DSHeader.vue';
import DSMarkedList from '@/module/design-system/components/Data/DSMarkedList.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import {
  mapGetters,
} from 'vuex';

export default {
  name: 'BrandingPage',
  components: {
    CCard,
    COverviewPageHeaderRow,
    COverviewPageLayout,
    DSButton,
    DSHeader,
    DSMarkedList,
    DSTextElement,
  },
  setup() {
    const serviceContainer = inject('serviceContainer');
    const store = serviceContainer.resolve('store');
    const locale = store.getters.getLocale;

    const utilsService = serviceContainer.resolve('utilsService');

    const utils = serviceContainer.resolve('utils');

    const localisationRegistry = store.getters.getLocalisationRegistry;
    const localisation = localisationRegistry.branding;

    const docsSiteUrl = serviceContainer.portalConfigService.get().url.docs;

    const brandColorsHowItWorks = utils.link.localizeURL(
      `${docsSiteUrl}/en-en/checkout/checkout-page-settings`,
      locale,
    );

    const customDomainHowItWorks = utils.link.localizeURL(
      `${docsSiteUrl}/en-en/merchant-portal/get-started#next-steps`,
      locale,
    );

    return {
      utilsService,
      localisation,
      brandColorsHowItWorks,
      customDomainHowItWorks,
    };
  },
  computed: {
    ...mapGetters({
      isMerchantActivated: 'authStore/getIsMerchantActivated',
    }),
    availableOptionsTitle() {
      return this.utilsService.email.replaceSupportEmail(
        this.localisation.infoBlocks.brandColors.availableOptions.title,
        this.isMerchantActivated,
      );
    },
    availableOptionsList() {
      return [
        this.localisation.infoBlocks.brandColors.availableOptions.list.brandColor,
        this.localisation.infoBlocks.brandColors.availableOptions.list.buttonColor,
        this.localisation.infoBlocks.brandColors.availableOptions.list.logoOrIcon,
      ];
    },
    customDomainText() {
      return this.utilsService.email.replaceSupportEmail(
        this.localisation.infoBlocks.customDomain.text,
        this.isMerchantActivated,
      );
    },
  },
  methods: {
    handleBrandColorsSetUp() {
      window.location.href = this.$utils.link.generateLinkHref(
        this.utilsService.email.getSupportEmail(this.isMerchantActivated),
        'email',
        {
          subject: 'Branding colours update request',
        },
      );
    },
    handleBrandColorsHowItWorks() {
      window.open(this.brandColorsHowItWorks, '_blank');
    },
    handleCustomDomainSetUpWithSupport() {
      window.location.href = this.$utils.link.generateLinkHref(
        this.utilsService.email.getSupportEmail(this.isMerchantActivated),
        'email',
        {
          subject: 'Custom domain request',
        },
      );
    },
    handleCustomDomainHowItWorks() {
      window.open(this.customDomainHowItWorks, '_blank');
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/module/common/components/variables.scss";

.info-block {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__right {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__buttons {
    display: flex;
    gap: 16px;
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .info-block {
    &__buttons {
      width: 100%;
      justify-content: flex-start;
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .info-block {
    gap: 12px;

    &__right {
      justify-content: center;
    }

    &__buttons {
      flex-direction: column-reverse;
      align-items: center;
      width: 100%;
    }
  }
}
</style>
