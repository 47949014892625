export default function checkoutLink(instance) {
  return {
    list(options) {
      const sortByUpdateDate = options.sortByUpdateDate ?? 'desc';
      const parts = [
        `limit=${options.limit}`,
        `offset=${options.offset}`,
        `sortByUpdateDate=${sortByUpdateDate}`,
      ];

      if (options.searchString) {
        parts.push(`searchString=${encodeURIComponent(options.searchString)}`);
      }

      const query = parts.filter((part) => !!part).join('&');

      return instance.get(`vendor/checkout-link?${query}`);
    },
    create(payload) {
      return instance.post('vendor/checkout-link', payload);
    },
    update(payload) {
      return instance.put(`vendor/checkout-link/${payload.id}`, payload);
    },
    get(id) {
      return instance.get(`vendor/checkout-link/${id}`);
    },
  };
}
