import snakeToCamelCase from '@/module/common/utils/string/snakeToCamelCase';

export default function mapObjectSnakeKeysToCamelCaseRecursive<T, R>(object: T | object): R | object {
  if (typeof object === 'object') {
    if (Array.isArray(object)) {
      return object.map((arrayElement) => (typeof arrayElement === 'object'
        ? mapObjectSnakeKeysToCamelCaseRecursive(arrayElement)
        : arrayElement));
    }

    if ((object as object).constructor.name !== 'Object') {
      throw new Error('Instances of classes can`t be mapped.');
    }
  }

  return Object.entries(object).reduce((changed, pair) => {
    const [key, value] = pair;
    const changedKey = snakeToCamelCase(key);

    changed[changedKey] = typeof value === 'object' ? mapObjectSnakeKeysToCamelCaseRecursive(value) : value;

    return changed;
  }, {} as R | object);
}
