<template>
  <CFormPageLayout
    :show-error="!isProductExists"
    :error-status="404"
    :show-preloader="isLoading"
  >
    <CFormPageHeaderRow
      :page-title="isEditMode
        ? $t('promotions.form.edit_promotion')
        : $t('promotions.form.new_promotion')"
      :navigation-left="headerButtonsLeft"
      :navigation-right="headerButtonsRight"
    />

    <CFormPageContent>
      <div class="create-edit-promotion">
        <section class="create-edit-promotion__section">
          <HeaderText size="3" :text="$t('promotions.form.details')" />

          <!-- связано со стором -->
          <SliderSwitch
            :text-after="promotionStatusValue
              ? $t('promotions.form.status_active')
              : $t('promotions.form.status_disabled')"
            :checked="promotionStatusValue"
            @update:checked="updatePromotionStatus(!promotionStatusValue)"
          />

          <!-- связано со стором -->
          <DSTooltip
            :text="$t('promotions.form.type.tooltip')"
          >
            <ButtonGroup class="create-edit-promotion__button-group">
              <ButtonComponent
                v-for="button in promotionTypeButtons"
                :key="button.value"
                :class="promotionTypeValue === button.value ? 'active' : 'inactive'"
                :variant="promotionTypeValue === button.value ? 'tretiary' : 'tretiary-negative'"
                @click="updatePromotionType(button.value)"
              >
                {{ $t(button.text) }}
              </ButtonComponent>
            </ButtonGroup>
          </DSTooltip>

          <!-- связано со стором -->
          <InputText
            :label="$t('promotions.form.name')"
            :placeholder="$t('promotions.form.name_placeholder')"
            :tooltip="$t('tooltips.promotions.form.name')"
            :value="promotionNameValue"
            :is-invalid="validation.hasError('promotionNameValue')"
            :error="validation.firstError('promotionNameValue')"
            @update:value="updatePromotionName"
          />

          <div
            class="create-edit-promotion__group create-edit-promotion__group--date-time"
          >
            <p class="create-edit-promotion__label">
              {{ $t('promotions.form.start_date') }}
            </p>
            <div class="create-edit-promotion__date-time-wrapper">
              <div class="create-edit-promotion__date-time">
                <div
                  class="create-edit-promotion__date-time"
                  :class="{
                    'create-edit-promotion__date-time_error': validation.firstError('promotionDateTimeFromValue.time'),
                  }"
                >
                  <DatePicker
                    class="create-edit-promotion__date"
                    :value="promotionDateTimeFromValue.date"
                    :min-date="minDate"
                    @update:value="(value) => handleUpdateDate('dateFrom', value)"
                  />

                  <TimePicker
                    class="create-edit-promotion__time"
                    :model-value="promotionDateTimeFromValue.time"
                    @update:modelValue="(value) => handleUpdateTime('timeFrom', value)"
                  />
                </div>

                <p class="create-edit-promotion__timezone">
                  {{ timeZone }}
                </p>
              </div>
              <p
                v-if="validation.firstError('promotionDateTimeFromValue.time')"
                class="input-text__options-error"
              >
                {{ validation.firstError('promotionDateTimeFromValue.time') }}
              </p>
            </div>
          </div>

          <div
            class="create-edit-promotion__group  create-edit-promotion__group--date-time"
          >
            <SliderSwitch
              class="create-edit-promotion__label"
              :text-after="$t('promotions.form.add_date.title')"
              :tooltip-after="$t('promotions.form.add_date.tooltip')"
              :checked="isExpirationDate"
              @update:checked="updateIsExpirationDate(!isExpirationDate)"
            />

            <div
              v-if="isExpirationDate"
              class="create-edit-promotion__date-time-wrapper"
            >
              <div
                class="create-edit-promotion__date-time"
              >
                <div
                  class="create-edit-promotion__date-time"
                  :class="{
                    'create-edit-promotion__date-time_error': validation.firstError('promotionDateTimeToValue.time'),
                  }"
                >
                  <DatePicker
                    class="create-edit-promotion__date"
                    :value="promotionDateTimeToValue.date"
                    :min-date="promotionDateTimeFromValue.date"
                    @update:value="(value) => handleUpdateDate('dateTo', value)"
                  />

                  <TimePicker
                    class="create-edit-promotion__time"
                    :model-value="promotionDateTimeToValue.time"
                    @update:modelValue="(value) => handleUpdateTime('timeTo', value)"
                  />
                </div>

                <p class="create-edit-promotion__timezone">
                  {{ timeZone }}
                </p>
              </div>
              <p
                v-if="validation.firstError('promotionDateTimeToValue.time')"
                class="input-text__options-error"
              >
                {{ validation.firstError('promotionDateTimeToValue.time') }}
              </p>
            </div>
          </div>

          <template v-if="promotionTypeValue === 'coupon'">
            <div class="create-edit-promotion__group">
              <p class="create-edit-promotion__label">
                {{ $t('promotions.form.coupon_settings.title') }}

                <DSTooltip
                  class="create-edit-promotion__tooltip"
                  :text="$t('promotions.form.coupon_settings.tooltip')"
                >
                  <DSIcon
                    icon="info"
                    color="disabled"
                    size="s"
                  />
                </DSTooltip>
              </p>

              <ButtonGroup
                class="create-edit-promotion__button-group"
                :variant="'spaced'"
              >
                <ButtonComponent
                  v-for="button in couponTypeButtons"
                  :key="button.value"
                  :class="couponTypeValue === button.value ? 'active' : 'inactive'"
                  :variant="couponTypeValue === button.value ? 'tretiary' : 'tretiary-negative'"
                  small
                  @click="updateCouponType(button.value)"
                >
                  {{ button.text }}
                </ButtonComponent>
              </ButtonGroup>
            </div>

            <div class="create-edit-promotion__group">
              <CTextarea
                class="create-edit-promotion__textarea"
                :label="$t('promotions.form.coupon_codes')"
                :max-rows-count="10"
                :min-rows-count="1"
                :max-chars-in-row="30"
                :status="couponCodeStatus"
                :tooltip="$t('tooltips.promotions.form.coupon_codes')"
                :value="couponCodeValue"
                @blur="handleCouponsBlur"
                @keydown.space.prevent
              />

              <div class="create-edit-promotion__generate">
                <ButtonComponent
                  class="create-edit-promotion__button"
                  :variant="'secondary'"
                  @click="handleGenerateClick(generateCodesSelectedValue)"
                >
                  {{ $t('promotions.form.generate') }}
                </ButtonComponent>
                <CouponDropdown
                  :selected-option="generateCodesSelectedOption"
                  :model-value="generateCodesSelectedValue"
                  @update:selectedOption="value => generateCodesSelectedOption = value"
                  @update:modelValue="value => generateCodesSelectedValue = value"
                />
              </div>
            </div>
          </template>
        </section>

        <section
          class="create-edit-promotion__section"
        >
          <HeaderText
            size="3"
            :text="$t('promotions.form.discount')"
          />

          <UIDropdown
            v-if="promotionTypeValue === 'discount'"
            :label="$t('promotions.form.discount_model.title')"
            :tooltip="$t('promotions.form.discount_model.tooltip')"
            :model-options="discountModelOptionValues"
            :model-value="promotionDiscountModel"
            with-radio-buttons
            @update:modelValue="(newModel) => handleUpdateDiscountModel(newModel)"
          />

          <div
            v-else-if="promotionTypeValue === 'coupon'"
            class="create-edit-promotion__group"
          >
            <UIDropdown
              :label="$t('promotions.form.discount_model.title')"
              :tooltip="$t('promotions.form.discount_model.tooltip')"
              :model-options="couponModelOptionValues"
              :model-value="promotionDiscountModel"
              with-radio-buttons
              @update:modelValue="(newModel) => handleUpdateDiscountModel(newModel)"
            />
            <!-- eslint-disable vue/no-v-html -->
            <p
              class="create-edit-promotion__hint"
              v-html="couponModelHint"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>

          <template
            v-if="promotionDiscountModel === 'common'"
          >
            <CMaskedInput
              :mask-options="maskOptions"
              :label="$t('promotions.form.amount_of_discount')"
              :value="commonAmountOfDiscount"
              :status="commonAmountOfDiscountStatus"
              @input="updateCommonAmountOfDiscount"
            />

            <ul
              v-if="discountModelData.productId.length"
              class="create-edit-promotion__product-list"
            >
              <PromotionDiscountListItem
                v-for="(productId, index) in discountModelData.productId"
                :key="index"
                :product="getProductById(productId)"
                :amount-of-discount="discountModelData.discountPercent"
                :product-index="productId"
                :current-index="index"
                :submitted="submitted"
                @remove="handleRemoveItem"
              />
            </ul>

            <SearchDropdown
              v-if="isAddingProduct || !discountModelData.productId.length"
              :is-localized="false"
              :is-loading="isDropdownProductsLoading"
              :is-drop-to-top-blocked="true"
              :select-handler="(product) => {
                isProductExist = true
                isAddingProduct = false
                selectProduct(product)
                resetSearchDropdown()
              }"
              :add-products="addProducts"
              :options="dropdownProducts"
              :total-count="dropdownProductsTotalCount"
              :model-value-count="productData.licenseTermAmount"
              :placeholder="$t('promotions.form.find_product')"
              :disabled-item-text="$t('promotions.form.added_product')"
              :error-message="!isProductExist ? 'Choose at least one product' : null"
              @update:modelValue="(value) => {
                pricingModel = pricingModelOptions.find(
                  (option) => option.name === value,
                ).value
              }"
              @update:value="handleInputChange"
            />

            <ButtonComponent
              class="create-edit-promotion__button"
              variant="tretiary"
              :disabled="!discountModelData.productId.length || isAddingProduct"
              @click="isAddingProduct = true"
            >
              <template
                #icon-before
              >
                <DSIcon
                  icon="plus"
                  :color="addButtonIconColor"
                />
              </template>
              {{ $t('promotions.form.add_product') }}
            </ButtonComponent>
          </template>

          <template v-else-if="promotionDiscountModel === 'custom'">
            <ul
              v-if="discountModelData.products.length"
              class="create-edit-promotion__product-list"
            >
              <h1>{{ validation.firstError('discountModelData') }}<!-- @todo разобраться со вложенностью --></h1>
              <PromotionDiscountListItem
                v-for="(product, index) in discountModelData.products"
                :key="index"
                :product="getProductById(product.productId)"
                :variant="'custom'"
                :product-index="product.productId"
                :current-index="index"
                :amount-of-discount="product.discountPercent"
                :submitted="submitted"
                @remove="handleRemoveItem"
                @update:amountOfDiscount="updateCustomAmountOfDiscount"
                @update:isListProductsValid="bool => isListProductsValid = bool"
              />
            </ul>

            <SearchDropdown
              v-if="isAddingProduct || !discountModelData.products.length"
              :is-localized="false"
              :is-loading="isDropdownProductsLoading"
              :is-drop-to-top-blocked="true"
              :select-handler="(product) => {
                isProductExist = true
                isAddingProduct = false
                selectProduct(product)
                resetSearchDropdown()
              }"
              :add-products="addProducts"
              :options="dropdownProducts"
              :total-count="dropdownProductsTotalCount"
              :model-value-count="productData.licenseTermAmount"
              :placeholder="$t('promotions.form.find_product')"
              :disabled-item-text="$t('promotions.form.added_product')"
              :error-message="!isProductExist ? 'Choose at least one product' : null"
              @update:value="handleInputChange"
            />

            <ButtonComponent
              class="create-edit-promotion__button"
              variant="tretiary"
              :disabled="!discountModelData.products.length || isAddingProduct"
              @click="isAddingProduct = true"
            >
              <template
                #icon-before
              >
                <DSIcon
                  icon="plus"
                  :color="addButtonIconColor"
                />
              </template>
              {{ $t('promotions.form.add_product') }}
            </ButtonComponent>
          </template>

          <template v-else-if="promotionDiscountModel === 'all-catalog'">
            <CMaskedInput
              :mask-options="maskOptions"
              :label="$t('promotions.form.amount_of_discount')"
              :value="discountModelData.discountPercent"
              :status="allCatalogDiscountStatus"
              @input="updateAllAmountOfDiscount"
            />
          </template>
        </section>
      </div>
    </CFormPageContent>
  </CFormPageLayout>
</template>

<script>
import ButtonComponent from '@/components/Button/ButtonComponent.vue';
import HeaderText from '@/components/HeaderText.vue';
import ButtonGroup from '@/components/ButtonGroup/ButtonGroup.vue';
import InputText from '@/components/InputText/InputText.vue';
import CMaskedInput from '@/module/common/components/UI/MaskedInput/CMaskedInput.vue';
import SliderSwitch from '@/components/SliderSwitch.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import TimePicker from '@/components/TimePicker/TimePicker.vue';
import SearchDropdown from '@/components/SearchDropdown/SearchDropdown.vue';
import UIDropdown from '@/components/UI/UIDropdown.vue';
import CouponDropdown from '@/components/CouponDropdown/CouponDropdown.vue';
import CTextarea from '@/module/common/components/UI/CTextarea.vue';
import CFormPageLayout from '@/module/common/components/PageParts/CFormPageLayout.vue';
import CFormPageHeaderRow from '@/module/common/components/PageParts/CFormPageHeaderRow.vue';
import CFormPageContent from '@/module/common/components/PageParts/CFormPageContent.vue';
import EventListenerRegister from '@/module/common/service/EventListenerRegister/EventListenerRegister';
import initLocalisation, {
  getButtonName,
  initCreatedPopUp,
  initUpdatedPopUp,
  initDuplicatesPopUp,
  initErrorPopUp,
} from '@/views/CreateEditPromotionPage.func';
import SimpleVueValidator from 'simple-vue-validator';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import DSTooltip from '@/module/design-system/components/InfoBlock/DSTooltip.vue';
import PromotionDiscountListItem from '@/components/PromotionDiscountListItem/PromotionDiscountListItem.vue';
import debounce from '@/services/debounce';
import improvedTrim from '@/helpers/improvedTrim';
import moment from 'moment';
import initProductPricingModelsList from '@/module/product/helper/initProductPricingModelsList';
import getButton, {
  getButtonWithIcon,
} from '@/module/common/helpers/getButton';

const { Validator } = SimpleVueValidator;

export default {
  name: 'CreateEditPromotionPage',
  components: {
    CTextarea,
    CFormPageContent,
    CFormPageHeaderRow,
    CFormPageLayout,
    ButtonComponent,
    ButtonGroup,
    CouponDropdown,
    DSIcon,
    DatePicker,
    DSTooltip,
    HeaderText,
    InputText,
    PromotionDiscountListItem,
    SearchDropdown,
    SliderSwitch,
    TimePicker,
    UIDropdown,
    CMaskedInput,
  },
  mixins: [
    SimpleVueValidator.mixin,
  ],
  data() {
    return {
      popUpList: {
        created: null,
        updated: null,
        duplicated: null,
        error: null,
      },
      localisation: {},
      isProductExist: true,
      isAddingProduct: false,
      isSavingInProgress: false,
      isLoading: true,
      isProductExists: true,
      pricingModel: 'One price',
      pricingModelOptions: [],
      descriptionMaxLength: 300,
      isValidating: false,
      isFormValid: false,
      promotionTypeButtons: [
        {
          text: 'promotions.form.type.discount',
          value: 'discount',
        },
        {
          text: 'promotions.form.type.coupon',
          value: 'coupon',
        },
      ],
      couponTypeButtons: [
        {
          text: this.$t('promotions.form.reusable'),
          value: 'reusable',
        },
        {
          text: this.$t('promotions.form.disposable'),
          value: 'one-time',
        },
      ],
      imageUploadError: '',
      isFirstPricingValid: true,
      isSecondPricingValid: true,
      discountModelOptionValues: [
        {
          value: 'all-catalog',
          name: this.$t('promotions.form.discount_model.all_catalog.title'),
        },
        {
          value: 'common',
          name: this.$t('promotions.form.discount_model.common.title'),
        },
        {
          value: 'custom',
          name: this.$t('promotions.form.discount_model.custom.title'),
        },
      ],
      couponModelOptionValues: [
        {
          value: 'all-catalog',
          name: this.$t('promotions.form.discount_model.all_catalog.title'),
          hint: this.$t('promotions.form.discount_model.all_catalog.hint'),
        },
        {
          value: 'common',
          name: this.$t('promotions.form.discount_model.common.title'),
          hint: this.$t('promotions.form.discount_model.common.hint'),
        },
        {
          value: 'custom',
          name: this.$t('promotions.form.discount_model.custom.title'),
          hint: this.$t('promotions.form.discount_model.custom.hint'),
        },
      ],
      generateCodesSelectedOption: '10',
      generateCodesSelectedValue: 10,
      minDate: new Date(),
      dropdownSearchString: '',
      submitted: 0,
      isListProductsValid: true,
      couponCodeStatus: { code: 'default' },
    };
  },
  computed: {
    ...mapGetters({
      promotionTypeValue: 'createEditPromotionStore/getPromotionType',
      promotionStatusValue: 'createEditPromotionStore/getPromotionStatus',
      promotionNameValue: 'createEditPromotionStore/getPromotionName',
      promotionDateTimeFromValue: 'createEditPromotionStore/getPromotionDateTimeFrom',
      promotionDateTimeToValue: 'createEditPromotionStore/getPromotionDateTimeTo',
      getIsExpirationDate: 'createEditPromotionStore/getIsExpirationDate',
      promotionDiscountModel: 'createEditPromotionStore/getDiscountModel',
      productData: 'createEditProductStore/getProductData',
      commonAmountOfDiscount: 'createEditPromotionStore/getCommonAmountOfDiscount',
      discountModelData: 'createEditPromotionStore/getDiscountModelObject',
      couponTypeValue: 'createEditPromotionStore/getCouponType',
      couponCodeValue: 'createEditPromotionStore/getCouponCodes',
      selectedProducts: 'createEditPromotionStore/getSelectedProducts',
      duplicates: 'createEditPromotionStore/getDublicates',
      scrollHostRef: 'getScrollHostRef',
      getLocalisationRegistry: 'getLocalisationRegistry',
    }),
    ...mapState({
      isDropdownProductsLoading: (state) => state.createEditPromotionStore.isDropdownProductsLoading,
      dropdownProducts: (state) => state.createEditPromotionStore.dropdownProducts,
      dropdownProductsTotalCount: (state) => state.createEditPromotionStore.dropdownProductsTotalCount,
    }),
    commonLocalisation() {
      return this.getLocalisationRegistry.common;
    },
    isEditMode() {
      return !!this.$route.meta.isEdit;
    },
    promotionId() {
      return this.$route.params.id;
    },
    couponModelHint() {
      const couponModelOption = this.couponModelOptionValues
        .find((couponModelOptionItem) => couponModelOptionItem.value === this.promotionDiscountModel);

      return couponModelOption.hint;
    },
    timeZone() {
      return this.$utils.date.getTimeZone(moment());
    },
    isExpirationDate() {
      return this.getIsExpirationDate && !!this.$utils.date.getPromotionExpiredDate(this.promotionDateTimeToValue.date);
    },
    addButtonIconColor() {
      return this.isAddingProduct ? 'disabled' : 'linked';
    },
    maskOptions() {
      const numberFormatService = this.$container.resolve('numberFormatService');

      return {
        mask: 'num%',
        eager: true,
        blocks: {
          num: {
            mask: Number,
            scale: 6,
            radix: numberFormatService.getPercentDelimiter(),
            mapToRadix: [numberFormatService.getPercentDelimiter()],
            max: 99,
            min: 1,
          },
        },
      };
    },
    allCatalogDiscountStatus() {
      const status = this.validation.hasError('discountModelData.discountPercent') ? 'error' : 'default';
      const statusMap = {
        error: {
          code: 'error',
          text: this.validation.firstError('discountModelData.discountPercent'),
        },
        default: {
          code: 'default',
        },
      };

      return statusMap[status];
    },
    commonAmountOfDiscountStatus() {
      const status = this.validation.hasError('commonAmountOfDiscount') ? 'error' : 'default';
      const statusMap = {
        error: {
          code: 'error',
          text: this.validation.firstError('commonAmountOfDiscount'),
        },
        default: {
          code: 'default',
        },
      };

      return statusMap[status];
    },
    headerButtonsLeft() {
      return [
        getButtonWithIcon(
          {
            text: this.$t('promotions.form.cancel'),
            variant: 'tretiary',
          },
          () => {
            this.$router.push('/promotions').catch(() => null);
          },
          {
            icon: 'cross',
            color: 'linked',
          },
        ),
      ];
    },
    headerButtonsRight() {
      return [
        getButton(
          {
            text: this.$t('promotions.form.save'),
          },
          this.handleSaveClick,
        ),
      ];
    },
  },
  created() {
    this.pricingModelOptions = initProductPricingModelsList(this);
  },
  async mounted() {
    this.localisation = initLocalisation(this);

    this.popUpList.created = initCreatedPopUp(this.localisation);
    this.popUpList.updated = initUpdatedPopUp(this.localisation);
    this.popUpList.duplicated = initDuplicatesPopUp(this.localisation);
    this.popUpList.error = initErrorPopUp(this.localisation, this.commonLocalisation);

    const eventListenerRegister = new EventListenerRegister();
    const buttonName = getButtonName();

    this.getProductsDropdown({
      offset: 0,
      limit: 10,
      sortByUpdateDate: 'desc',
    });

    if (this.isEditMode) {
      eventListenerRegister.register(buttonName, () => this.updatePromotion(this.$route.params.id));
      await this.loadPromotionToEdit(this.promotionId);
    } else {
      eventListenerRegister.register(buttonName, () => this.savePromotion());
    }

    this.isLoading = false;
  },
  destroyed() {
    this.resetState();
  },
  methods: {
    ...mapMutations({
      changeFieldValue: 'createEditProductStore/changeFieldValue',
      changeSku: 'createEditProductStore/changeSku',
      changeProductListFieldValue: 'productListStore/changeFieldValue',
      updatePromotionType: 'createEditPromotionStore/updatePromotionType',
      updatePromotionStatus: 'createEditPromotionStore/updatePromotionStatus',
      updatePromotionName: 'createEditPromotionStore/updatePromotionName',
      updatePromotionDate: 'createEditPromotionStore/updateDate',
      updatePromotionTime: 'createEditPromotionStore/updateTime',
      updateIsExpirationDate: 'createEditPromotionStore/updateIsExpirationDate',
      updateDiscountModel: 'createEditPromotionStore/updateDiscountModel',
      updateCommonAmountOfDiscount: 'createEditPromotionStore/updateCommonAmountOfDiscount',
      updateCustomAmountOfDiscount: 'createEditPromotionStore/updateCustomAmountOfDiscount',
      updateAllAmountOfDiscount: 'createEditPromotionStore/updateAllAmountOfDiscount',
      updateCouponType: 'createEditPromotionStore/updateCouponType',
      updateCouponCodes: 'createEditPromotionStore/updateCouponCodes',
      resetState: 'createEditPromotionStore/resetState',
      setIsInitialRequest: 'promotionsOverviewStore/setIsInitialRequest',
    }),
    ...mapActions({
      updateProduct: 'createEditProductStore/updateProduct',
      loadIcon: 'createEditProductStore/loadIcon',
      getProductsDropdown: 'createEditPromotionStore/getProductsDropdown',
      selectProduct: 'createEditPromotionStore/handleSelectProduct',
      createPromotion: 'createEditPromotionStore/createPromotion',
      generateCouponCodes: 'createEditPromotionStore/generateCouponCodes',
      addProductsToDropdown: 'createEditPromotionStore/addProductsToDropdown',
      loadPromotionToEdit: 'createEditPromotionStore/loadPromotionToEdit',
      updateEditedPromotion: 'createEditPromotionStore/updateEditedPromotion',
      pushNotification: 'notificationsStore/pushNotification',
    }),
    getProductById(productId) {
      return this.dropdownProducts.find((product) => product.id === productId);
    },
    async handleGenerateClick(amount) {
      await this.generateCouponCodes(amount);
    },
    async handleSaveClick() {
      if (!this.isProductExist) {
        return;
      }

      if (this.promotionDiscountModel === 'custom' && !this.discountModelData.products.length) {
        this.isProductExist = false;
        return;
      }

      if (this.promotionDiscountModel === 'common' && !this.discountModelData.productId.length) {
        this.isProductExist = false;
        return;
      }

      this.isFormValid = false;
      this.isValidating = true;
      this.submitted += 1;
      this.isFormValid = await this.$validate();

      if (this.validation.hasError('couponCodeValue')) {
        this.couponCodeStatus = {
          code: 'error',
          text: this.validation.firstError('couponCodeValue'),
        };
      } else {
        this.couponCodeStatus = {
          code: 'default',
        };
      }

      if (this.isFormValid && this.isListProductsValid) {
        this.setIsInitialRequest(true);
        if (this.isEditMode) {
          this.updatePromotion(this.promotionId);
        } else {
          this.savePromotion();
        }
      }
    },
    handleUpdateDate(type, newDate) {
      this.updatePromotionDate({
        type,
        newDate,
      });
    },
    handleUpdateTime(type, newTime) {
      const notUpdated = this.updatePromotionTime({
        type,
        newTime,
      });
      if (notUpdated) {
        this.updatePromotionType({
          type,
          newTime: notUpdated,
        });
      }
    },
    async searchProductsInDropdown(value) {
      this.dropdownSearchString = value;
      await this.getProductsDropdown({
        offset: 0,
        limit: 10,
        sortByUpdateDate: 'desc',
        searchString: this.dropdownSearchString,
      });
    },

    debouncedGetProductList(value) {
      return (debounce(async () => {
        await this.searchProductsInDropdown(value);
      }, 1500))();
    },

    handleInputChange(value) {
      if (improvedTrim(value).length > 2 && improvedTrim(value).length < 256) {
        this.debouncedGetProductList(improvedTrim(value));
      }
      if (!improvedTrim(value).length) {
        this.searchProductsInDropdown('');
      }
    },
    async addProducts() {
      if (!this.dropdownProducts.length || (this.dropdownProductsTotalCount < this.dropdownProducts.length)) {
        return;
      }
      await this.addProductsToDropdown({
        offset: this.dropdownProducts.length,
        limit: 10,
        sortByUpdateDate: 'desc',
        searchString: this.dropdownSearchString,
      });
    },
    async savePromotion() {
      this.isSavingInProgress = true;
      const isSaved = await this.createPromotion();
      this.isSavingInProgress = false;

      if (isSaved) {
        this.pushNotification(this.popUpList.created);

        this.$router.push('/promotions').catch(() => null);
        return;
      }

      if (this.duplicates.length > 0) {
        this.pushNotification(this.popUpList.duplicated);
      } else {
        this.pushNotification(this.popUpList.error);
      }
    },
    async updatePromotion(promotionId) {
      this.isSavingInProgress = true;
      const isUpdated = await this.updateEditedPromotion(promotionId);
      this.isSavingInProgress = false;

      if (isUpdated) {
        this.pushNotification(this.popUpList.updated);

        this.$router.push('/promotions').catch(() => null);
        return;
      }

      if (this.duplicates.length > 0) {
        this.pushNotification(this.popUpList.duplicated);
      } else {
        this.pushNotification(this.popUpList.error);
      }
    },
    async resetSearchDropdown() {
      this.dropdownSearchString = '';
      this.getProductsDropdown({
        offset: 0,
        limit: 10,
        sortByUpdateDate: 'desc',
      });
    },
    handleUpdateDiscountModel(newModel) {
      this.isProductExist = true;
      this.isAddingProduct = false;
      this.updateDiscountModel(newModel);
      this.resetSearchDropdown();

      return newModel;
    },
    handleCouponsBlur(value) {
      this.updateCouponCodes(value);
    },
    handleRemoveItem() {
      this.resetSearchDropdown();
    },
  },
  validators: {
    promotionNameValue(value) {
      if (this.submitted) {
        return Validator.value(value)
          .required(this.$t('validation_messages.required'))
          .maxLength(255, this.$t('validation_messages.max_length', { number: 255 }));
      }

      return null;
    },
    // eslint-disable-next-line func-names
    'promotionDateTimeFromValue.time': function (start) {
      const newStr = start.split(/[:_]/)
        .join('');
      return Validator.value(newStr)
        .minLength(4, this.$t('validation_messages.invalid_format'));
    },
    // eslint-disable-next-line max-len,func-names
    'promotionDateTimeToValue.time, promotionDateTimeFromValue.time, promotionDateTimeFromValue.date, promotionDateTimeToValue.date': function (end, start, fromDate, toDate) {
      const numStart = +start.split(':')
        .join('');
      const numEnd = +end.split(':')
        .join('');
      if (+toDate > +fromDate) {
        return Validator.value(numEnd)
          .required(this.$t('validation_messages.required'));
      }
      return Validator.value(numEnd)
        .integer(this.$t('validation_messages.invalid_format'))
        .greaterThan(numStart, this.$t('validation_messages.invalid_time'));
    },
    // eslint-disable-next-line consistent-return
    couponCodeValue(value) {
      if (this.submitted && this.promotionTypeValue === 'coupon') {
        const deduplicated = [...new Set(value.split('\n'))].join('\n');

        return Validator.value(value)
          .required(this.$t('validation_messages.required'))
          .maxLength(30000, this.$t('validation_messages.max_length', { number: 30000 }))
          .match(deduplicated, this.$t('validation_messages.duplicate_promo_codes'))
          .regex(/^[A-Za-zА-Яа-я0-9_.\n\r-]*$/g, this.$t('validation_messages.invalid_format'));
      }
    },
    // eslint-disable-next-line consistent-return
    commonAmountOfDiscount(value) {
      if (this.submitted && this.promotionDiscountModel === 'common') {
        const utilsService = this.$container.resolve('utilsService');
        value = utilsService.string.replaceLast(value, ',', '.');
        value = utilsService.string.replaceLast(value, '%', '');

        const validateNum = value ? Number(`${value}`) : '';
        const minPercent = 1;
        const maxPercent = 100;

        return Validator.value(validateNum)
          .required(this.$t('validation_messages.required'))
          .greaterThanOrEqualTo(minPercent, this.$t('validation_messages.discount_percent_interval'))
          .lessThan(maxPercent, this.$t('validation_messages.discount_percent_interval'));
      }
    },
    // eslint-disable-next-line consistent-return,func-names
    'discountModelData.discountPercent': function (value) {
      if (this.submitted && this.promotionDiscountModel === 'all-catalog') {
        const utilsService = this.$container.resolve('utilsService');
        value = utilsService.string.replaceLast(value, ',', '.');
        value = utilsService.string.replaceLast(value, '%', '');

        const validateNum = value ? Number(`${value}`) : '';
        const minPercent = 1;
        const maxPercent = 100;

        return Validator.value(validateNum)
          .required(this.$t('validation_messages.required'))
          .greaterThanOrEqualTo(minPercent, this.$t('validation_messages.discount_percent_interval'))
          .lessThan(maxPercent, this.$t('validation_messages.discount_percent_interval'));
      }
    },
  },
};
</script>

<style
  lang="scss"
>
.create-edit-promotion {
  padding-bottom: 45px;

  &__date-time {
    display: flex;
    flex-direction: row;

    &_error {
      position: relative;

      &::after {
        position: absolute;
        display: inline-block;
        content: "";
        width: 100%;
        height: 100%;
        pointer-events: none;
        border: 2px solid $app-indication-red-color;
        border-radius: 8px;
      }
    }
  }

  &__date button {
    border-right-width: 1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__date button.active {
    padding-right: 5px;
    border-right-width: 2px;
  }

  &__time input {
    border-left-width: 1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &__time input:focus {
    padding-left: 47px;
    border-left-width: 2px;
  }

  &__timezone {
    width: 70px;
    line-height: 48px;
    text-align: center;
    color: #c6c6c6;
  }

  &__label {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.65);
  }

  &__button {
    max-width: fit-content;
    margin: 0;
  }

  &__group {
    display: grid;
    row-gap: 12px;

    &--date-time {
      row-gap: 9px;
    }
  }

  &__hint {
    padding: 16px;
    border-radius: 5px;
    background-color: #edf7fe;
  }

  &__textarea {
    margin-bottom: 0;
  }

  &__product-list {
    margin: 0;
    padding: 0;
    padding-inline-start: 0;

    li {
      display: flex;
      flex-direction: column;
      margin: 8px 0 20px;
    }
  }

  &__delete-icon {
    cursor: pointer;
    fill: #abacac;

    &:hover {
      fill: #ff4444;
    }
  }

  &__product-name {
    font-family: $text-ibm-plex-sans;
    color: #4397cb;
  }

  &__tooltip {
    margin-left: 8px;
    fill: #cccccc;
  }

  &__generate {
    display: flex;

    .button {
      min-width: 1px !important;
      padding: 13px 12px;
      border-radius: 8px 0 0 8px;
    }
  }

  &__upload-image-error {
    padding-top: 12px;
    color: #ff4444;
  }

  &__popup {
    position: fixed;
    top: 0;
    right: 0;
  }

  &__title-group {
    display: flex;
    align-items: center;
  }

  &__section {
    display: grid;
    max-width: 540px;
    padding-top: 35px;
    row-gap: 20px;

    &--top-lined {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid #e5e5e5;
    }

    &:first-child {
      padding-top: 0;
    }
  }

  &__hint {
    padding: 16px;
    border-radius: 5px;
    background-color: #edf7fe;
  }

  &__button-group {
    .button__tretiary.active {
      border: 2px solid #ebf7ff;
      background-color: #ebf7ff;
    }

    .button__tretiary.button__small.active {
      border: 1px solid #ebf7ff;
      background-color: #ebf7ff;
    }
  }

  &__error {
    margin-top: 8px;
    color: #ff4444;
    grid-row: 2 / 3;
    grid-column: 1 / -1;
  }

  input::placeholder, textarea::placeholder {
    color: #cccccc;
  }

  &__scroll-top-button {
    position: fixed;
    right: 30px;
    bottom: 30px;
  }

  &__image-button {
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100px;
    height: 100px;
    margin-top: 20px;
    font-family: $text-ibm-plex-sans;
    font-size: 16px;
    color: #4397cb;
    border-radius: 39%;
    background-color: #ebf7ff;
    cursor: pointer;

    svg, svg path {
      fill: #4397cb
    }

    input {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__preloader {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: white;
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 0;

    &__date-time {
      position: relative;
      flex-direction: column;

      .date-picker {
        width: 100%;
        margin-bottom: 10px;

        &__dropdown-button {
          border-radius: 8px;
        }
      }

      .time-picker__input {
        width: 100%;
        border-radius: 8px;
      }
    }

    &__timezone {
      position: absolute;
      right: 5px;
      bottom: 0;
    }

    &__product-list {
      li {
        margin: 0;
      }
    }

    &__section {
      padding-top: 24px;

      .input-text, .textarea {
        margin-top: 0;
      }
    }

    &__date-time {
      flex-direction: column;
    }

    &__available {
      margin-top: 20px;
    }

    &__hint {
      margin-top: 10px;
    }

    &__available {
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 370px) {
    .dropdown input,
    .dropdown .dropdown__dummy-input {
      min-width: 145px;
    }

    .dropdown-options {
      min-width: 140px;
    }
  }

  .promotion-product {
    margin: 0;

    &__title {
      display: none;
    }

    .coupon_small {
      display: none;
    }
  }
}
</style>
