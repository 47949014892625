import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import ILoadHandler from '@/module/product/view/ProductFormPage/handler/contract/ILoadHandler';
import IModelToFormDataMapper from '@/module/product/view/ProductFormPage/mapper/contract/IModelToFormDataMapper';

class LoadHandler implements ILoadHandler {
  private modelToFormDataMapper: IModelToFormDataMapper;

  private readonly serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer, modelToFormDataMapper: IModelToFormDataMapper) {
    this.serviceContainer = serviceContainer;
    this.modelToFormDataMapper = modelToFormDataMapper;
  }

  load = async (id: number): Promise<void> => {
    const apiService = this.serviceContainer.apiService;
    const productFormPage = this.serviceContainer.moduleProduct.productFormPage;
    const formDataHandler = productFormPage.handlerService.formDataHandler;
    const state = productFormPage.getState();

    try {
      const response = await apiService.product.get(id);
      const product = response.data.product;

      const formData = this.modelToFormDataMapper.map(product);

      formDataHandler.update(formData);

      if (formData.softwareRegistry?.status) {
        productFormPage.handlerService.softwareRegistryHandler.disableSwitcher();
      }
    } catch (e) {
      state.isProductLoadError = true;
    }
  };
}

export default LoadHandler;
