enum EFieldName {
  AVAILABLE_FOR = 'availableFor',
  DESCRIPTION = 'description',
  FULFILLMENT_ID = 'fulfillmentId',
  IMAGE_URL = 'imageUrl',
  LICENSE_TERM = 'licenseTerm',
  LICENSE_TERM_FIELD_VALUE = 'licenseTermFieldValue',
  ENABLE_LICENSE_TERM = 'enableLicenseTerm',
  LICENSE_TYPE = 'licenseType',
  PRICE = 'price',
  SKU = 'sku',
  PRICING_MODEL = 'pricingModel',
  RENEWAL = 'renewal',
  RENEWAL_PRICE = 'renewalPrice',
  RENEWAL_SUBTITLE = 'renewalSubtitle',
  RENEWAL_TERM = 'renewalTerm',
  RENEWAL_TITLE = 'renewalTitle',
  RENEWAL_SKU = 'renewalSku',
  SOFTWARE_REGISTRY = 'softwareRegistry',
  SUBTITLE = 'subtitle',
  TITLE = 'title',
  TRIAL = 'trial',
  TRIAL_SKU = 'trialSku',
  TRIAL_SUBTITLE = 'trialSubtitle',
  TRIAL_TERM = 'trialTerm',
  TRIAL_TITLE = 'trialTitle',
}

export default EFieldName;
