<template>
  <div
    class="c-expandable-list-item"
  >
    <div
      class="c-expandable-list-item__name"
    >
      <DSLink
        :is-router-link="false"
        :title="title"
        :url="url"
        class="c-expandable-list-item__link"
        target="_blank"
      />

      <CIconButton
        class="c-expandable-list-item__delete"
        @click="$emit('remove-item', item)"
      >
        <DSIcon
          icon="trash"
        />
      </CIconButton>
    </div>
    <slot
      name="list-item"
    />
  </div>
</template>

<script>
import CIconButton from '@/module/common/components/UI/CIconButton.vue';
import DSLink from '@/module/design-system/components/Text/DSLink.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';

export default {
  name: 'CExpandableListItem',
  components: {
    DSIcon,
    CIconButton,
    DSLink,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    titleField: {
      type: String,
      default: 'title',
    },
    urlField: {
      type: String,
      default: 'url',
    },
  },
  computed: {
    title() {
      return this.$utils.data.readProp(this.item, this.titleField);
    },
    url() {
      return this.$utils.data.readProp(this.item, this.urlField);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.c-expandable-list-item {
  &__name {
    margin-bottom: 12px;
    white-space: nowrap;
  }

  &__link {
    vertical-align: middle;
    white-space: normal;
    word-break: break-word;
  }

  &__delete {
    vertical-align: middle;
  }
}
</style>
