import IAvailableForService from '@/module/product/service/contract/IAvailableForService';
import EAvailableFor from '@/module/product/model/Product/Fields/AvailableFor/EAvailableFor';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IAvailableForOption from '@/module/product/model/Product/Fields/AvailableFor/IAvailableForOption';
import OptionException from '@/module/product/service/OptionException';

class AvailableForService implements IAvailableForService {
  private serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  get = (availableFor: EAvailableFor): IAvailableForOption => {
    const result = this.list()
      .find((option) => option.id === availableFor);

    if (!result) {
      throw new OptionException('availableFor', availableFor);
    }

    return result;
  };

  list = (): IAvailableForOption[] => {
    const localisationRegistry = this.serviceContainer.localisationRegistry;

    return [
      {
        id: EAvailableFor.ALL,
        title: localisationRegistry.productsOld.form.availableFor.all,
      },
      {
        id: EAvailableFor.PHYSICAL,
        title: localisationRegistry.productsOld.form.availableFor.individuals,
      },
      {
        id: EAvailableFor.JURIDICAL,
        title: localisationRegistry.productsOld.form.availableFor.businesses,
      },
    ];
  };
}

export default AvailableForService;
