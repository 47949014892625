<template>
  <div class="chart-shimer">
    <ShimmerLoader
      v-for="(bar, index) in bars"
      :key="index + '-' + bar"
      class="chart-shimer__shimer"
      :class="'chart-shimer__shimer--' + bar"
    />
  </div>
</template>

<script>
import ShimmerLoader from './ShimerLoader.vue';

export default {
  name: 'ChartShimer',
  components: {
    ShimmerLoader,
  },
  data() {
    return {
      bars: ['xl', 's', 'm', 'm', 'xl', 'l', 'xl', 's', 'xl', 's', 'xl', 's', 'm', 'l'],
    };
  },
};
</script>

<style lang="scss">
  .chart-shimer {
    display: flex;
    height: 270px;
    align-items: baseline;

    &__shimer {
      margin-right: 25px;
      width: 100%;
    }

    &__shimer:last-child {
      margin-right: 0;
    }

    &__shimer--s {
      height: calc(128 / 270 * 100%);
    }

    &__shimer--m {
      height: calc(181 / 270 * 100%);
    }

    &__shimer--l {
      height: calc(222 / 270 * 100%);
    }

    &__shimer--xl {
      height: 100%;
    }
  }

  @media screen and (max-width: 1120px) {
    .chart-shimer {
      &__shimer {
        margin-right: 15px;
      }
    }
  }

  @media screen and (max-width: 840px) {
    .chart-shimer {
      &__shimer {
        margin-right: 5px;
      }
    }
  }
</style>
