import IDSButton from '@/module/design-system/models/UI/DSButton/IDSButton';
import IDSIcon from '@/module/design-system/components/models/IDSIcon';
import EDSButtonSize from '@/module/design-system/models/UI/DSButton/EDSButtonSize';
import EDSButtonVariant from '@/module/design-system/models/UI/DSButton/EDSButtonVariant';
import EFileMimeTypes from '@/module/design-system/components/models/DSFileUpload/EFileMimeTypes';
import ICFileUploadOptions from '@/module/common/models/UI/FileUpload/ICFileUploadOptions';
import TFileErrorMessages from '@/module/common/models/UI/FileUpload/TFileErrorMessages';

export default function getOptions(
  errorMessages: TFileErrorMessages,
  button?: IDSButton,
  icon?: IDSIcon,
  description?: string,
  extensionsString?: string,
  allowedMimeTypes?: EFileMimeTypes[],
  maxFileSize?: number,
  maxFilesSize?: number,
  maxFileNameLength?: number,
): ICFileUploadOptions {
  const buttonOption: IDSButton = button ?? {
    variant: EDSButtonVariant.SECONDARY,
    text: 'Text',
    size: EDSButtonSize.SMALL,
  };

  const options = {
    button: buttonOption,
    maxFileSize: maxFileSize ?? 15,
    maxFilesSize: maxFilesSize ?? 100,
    errorMessages,
  } as ICFileUploadOptions;

  if (icon) {
    options.icon = icon;
  }

  if (description) {
    options.description = description;
  }

  if (allowedMimeTypes) {
    options.allowedMimeTypes = allowedMimeTypes;
  }

  if (maxFileNameLength) {
    options.maxFileNameLength = maxFileNameLength;
  }

  if (extensionsString) {
    options.extensionsString = extensionsString;
  }

  return options;
}
