import IVueInstance from '@/module/common/models/IVueInstance';
import {
  inject,
} from 'vue';

function getLocalisation<T>(key: string): T {
  const vueInstance = inject<IVueInstance>('vueInstance');

  return vueInstance?.$t(key) as T;
}

export default getLocalisation;
