
import Vue, {
  computed,
  PropType,
} from 'vue';

import ETextVariants from '@/module/common/models/ETextVariants';
import EColorScheme from '@/module/common/models/EColorScheme';

export default Vue.extend({
  name: 'DSParagraph',
  props: {
    variant: {
      type: String as PropType<ETextVariants>,
      default: ETextVariants.REGULAR,
    },
    color: {
      type: String as PropType<EColorScheme>,
      default: EColorScheme.PRIMARY,
    },
  },
  setup(props) {
    const classes = computed(() => [
      'paragraph',
      `paragraph_variant_${props.variant}`,
      `paragraph_color_${props.color}`,
    ]);

    return {
      classes,
    };
  },
});
