<template>
  <svg
    fill="none"
    height="100%"
    width="100%"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      clip-rule="evenodd"
      d="M12 4.6875C9.81533 4.6875 7.98869 6.29655 7.78156 8.40347L7.67684 9.46875H16.3233L16.2185 8.40347C16.0114 6.29655 14.1848 4.6875 12 4.6875ZM6.04749 8.24348L5.92703 9.46875C5.56061 9.46875 5.17408 9.34992 4.78733 9.23102C4.39174 9.1094 3.99592 8.98771 3.62116 8.99305C3.57906 8.99365 3.53847 8.99597 3.5 9C3.02177 9.05009 2.95347 9.3774 3.00517 9.84069L3.94235 18.2388C4.11764 19.8095 5.48649 21 7.11737 21H16.8827C18.5136 21 19.8825 19.8095 20.0577 18.2388L20.9949 9.84069C21.0466 9.3774 20.9783 9.05009 20.5 9C20.4616 8.99597 20.421 8.99365 20.3789 8.99305C20.0042 8.9877 19.6083 9.1094 19.2127 9.23102C18.826 9.34992 18.4395 9.46875 18.0731 9.46875L17.9526 8.24348C17.6603 5.27047 15.0828 3 12 3C8.91727 3 6.33976 5.27047 6.04749 8.24348ZM10.75 14C10.75 13.5858 10.4143 13.25 10 13.25C9.58583 13.25 9.25005 13.5858 9.25005 14V16C9.25005 16.4142 9.58583 16.75 10 16.75C10.4143 16.75 10.75 16.4142 10.75 16V14ZM14 13.25C14.4143 13.25 14.75 13.5858 14.75 14V16C14.75 16.4142 14.4143 16.75 14 16.75C13.5858 16.75 13.25 16.4142 13.25 16V14C13.25 13.5858 13.5858 13.25 14 13.25Z"
      fill-rule="evenodd"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSBagIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>
