<template>
  <div class="overlay">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'OverlayComponent',
};
</script>

<style>
.overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: hsla(0, 0%, 2%, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
