<template>
  <COverviewPageLayout>
    <template
      #header
    >
      <COverviewPageHeaderRow
        v-if="state"
        :page-title="state.localisation.title"
        :tooltip-text="state.localisation.tooltip"
      >
        <template
          #default
        >
          <div class="checkout-link-page__title-items-count">
            <DSTextElement
              color="secondary"
              variant="regular"
            >
              {{ countText }}
            </DSTextElement>
          </div>
        </template>
        <template
          #right-side
        >
          <DSButton
            :text="$t('common.how_it_works')"
            show-text-after-breakpoint="xl"
            variant="secondary"
            @click="handleHowItWorksClick()"
          >
            <template
              #icon-before
            >
              <DSIcon
                color="linked"
                icon="arrowInsideCircle"
              />
            </template>
          </DSButton>
          <DSButton
            v-if="addCheckoutLinkButton"
            :text="addCheckoutLinkButton.text"
            show-text-after-breakpoint="xl"
            @click="$router.push('/checkout-links/new').catch(() => null)"
          >
            <template
              #icon-before
            >
              <DSIcon
                color="primary-button"
                icon="plus"
              />
            </template>
          </DSButton>
        </template>
      </COverviewPageHeaderRow>
    </template>
    <template
      #content
    >
      <InlineAlert
        v-if="merchantModeAlert.visible"
        class="checkout-link-page__test-alert"
        :variant="merchantModeAlert.variant"
        :title="merchantModeAlert.title"
        :subtitle="merchantModeAlert.subtitle"
      >
        <template
          #alert-controls
        >
          <button
            class="inline-alert__button"
            type="button"
            @click="handleClickCloseMerchantModeAlert()"
          >
            <DSIcon
              icon="cross"
            />
          </button>
        </template>
      </InlineAlert>
      <CStatusWindowComponent
        v-if="statusWindow && statusWindow.type === 'no_links'"
        :subtitle="statusWindow.subtitle"
        :title="statusWindow.title"
      >
        <template
          #buttons
        >
          <DSButton
            :text="createButtonText"
            @click="$router.push('/checkout-links/new').catch(() => null)"
          >
            <template
              #icon-before
            >
              <DSIcon
                color="primary-button"
                icon="plus"
              />
            </template>
          </DSButton>
        </template>
      </CStatusWindowComponent>
      <main
        v-else-if="state"
        class="checkout-link-page__main"
      >
        <div
          class="checkout-link-page__filters"
        >
          <div
            class="checkout-link-page__search"
          >
            <CInput
              v-model="state.searchFilter"
              :input-delay="500"
              :label="searchInputLabel"
              :placeholder="searchInputPlaceholder"
              :icon="'search'"
              @input="handlerSearchFilterChange"
            >
              <template
                #before-input
              >
                <DSIcon
                  icon="search"
                  size="s"
                />
              </template>
              <template
                #after-input
              >
                <div
                  v-if="state.searching"
                  class="checkout-link-page__search-preloader"
                >
                  <PreloaderComponent
                    :height="'20px'"
                    :width="'20px'"
                  />
                </div>
                <DSIcon
                  v-if="state.searchFilter.length > 0 && !state.searching"
                  class="checkout-link-page__search-clear"
                  icon="cross"
                  size="s"
                  @click="handleClickSearchClear"
                />
              </template>
            </CInput>
          </div>
          <div
            class="checkout-link-page__order_by"
          >
            <CSelect
              v-model="state.orderBy"
              :label="sortByLabel"
              :list="state.orderByList"
              :icon-only="isMobile"
              @input="handlerOrderByFilterChange"
            />
          </div>
        </div>
        <div
          class="checkout-link-page__table"
        >
          <div
            v-if="checkoutLinksList.length > 0"
            class="checkout-link-page__table-title"
          >
            <div
              class="checkout-link-page__grid checkout-link-page__grid_title"
            >
              <div
                v-for="titleItem in state.gridTitles"
                :key="`header_${titleItem.key}`"
                :class="getColumnClass(titleItem, true)"
              >
                <DSTextElement
                  color="secondary"
                  variant="small"
                >
                  {{ titleItem.title }}
                </DSTextElement>
              </div>
            </div>
          </div>
          <div
            v-if="checkoutLinksList.length > 0 && state"
            class="checkout-link-page__table-body"
          >
            <div
              v-for="(checkoutLinksListItem, index) in checkoutLinksList"
              :key="`item_${index}`"
              class="checkout-link-page__grid checkout-link-page__item"
            >
              <div
                v-for="gridItem in gridItems"
                :key="`item_${index}_${gridItem.key}`"
                :class="getColumnClass(gridItem)"
              >
                <template
                  v-if="gridItem.key === 'name'"
                >
                  <div class="checkout-link-page__item_name">
                    <CTextWrapper>
                      <DSTextElement
                        variant="medium"
                      >
                        {{ checkoutLinksListItem.name }}
                      </DSTextElement>
                    </CTextWrapper>
                  </div>
                </template>
                <template
                  v-else-if="gridItem.key === 'last_edit'"
                >
                  <DSTextElement>
                    {{ utilsService.date.formatSkipCurrentYearWithoutWeekDay(checkoutLinksListItem.lastUpdate) }}
                  </DSTextElement>
                </template>
                <template
                  v-else-if="gridItem.key === 'products'"
                >
                  <DSTextElement>
                    {{ getProductsCount(checkoutLinksListItem) }}
                  </DSTextElement>
                </template>
                <template
                  v-else-if="gridItem.key === 'computed_info'"
                >
                  <DSTextElement
                    color="secondary"
                  >
                    {{ getProductsComputedInfo(checkoutLinksListItem) }}
                  </DSTextElement>
                </template>
                <template
                  v-else-if="gridItem.key === 'checkout_link'"
                >
                  <div class="checkout-link-page__item_checkout-link">
                    <CCopyField
                      :text="checkoutLinksListItem.url"
                      size="small"
                      @copied="handleLinkCopy()"
                    />
                  </div>
                </template>
                <template
                  v-else-if="gridItem.key === 'control'"
                >
                  <ButtonMore
                    ref="buttonMoreRef"
                    :buttons="[
                      {
                        name: 'common.edit',
                        handler: () => handleRedirectToEdit(checkoutLinksListItem),
                      },
                    ]"
                    class="checkout-link-page__item_button-more"
                    variant="no-border"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
        <CStatusWindowComponent
          v-if="statusWindow && statusWindow.type === 'empty_search'"
          :subtitle="statusWindow.subtitle"
          :title="statusWindow.title"
        />
        <div
          v-if="preloader"
          class="checkout-link-page__preloader"
        >
          <PreloaderComponent
            height="30px"
            width="30px"
          />
        </div>
      </main>
    </template>
  </COverviewPageLayout>
</template>

<script>
import {
  inject,
} from 'vue';
import {
  mapActions,
  mapGetters,
} from 'vuex';

import ButtonMore from '@/components/ButtonMore/ButtonMore.vue';
import CCopyField from '@/module/common/components/UI/CCopyField.vue';
import CInput from '@/module/common/components/UI/CInput.vue';
import COverviewPageHeaderRow from '@/module/common/components/PageParts/COverviewPageHeaderRow.vue';
import COverviewPageLayout from '@/module/common/components/PageParts/COverviewPageLayout.vue';
import CSelect from '@/module/common/components/UI/CSelect.vue';
import CTextWrapper from '@/module/common/components/Text/CTextWrapper.vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import CStatusWindowComponent from '@/module/common/components/UI/CStatusWindowComponent.vue';
import PreloaderComponent from '@/components/Preloader/PreloaderComponent.vue';
import InlineAlert from '@/components/InlineAlert/InlineAlert.vue';

import initState,
{
  initLocalisation,
} from '@/module/product/view/CheckoutLinkPage.func';
import {
  getIsMerchantModeAlertVisible,
  setMerchantModeAlertStatus,
} from '@/module/common/utils/merchantModeAlert';

export default {
  name: 'CheckoutLinkPage',
  components: {
    CTextWrapper,
    ButtonMore,
    CSelect,
    CCopyField,
    CStatusWindowComponent,
    DSTextElement,
    CInput,
    COverviewPageHeaderRow,
    COverviewPageLayout,
    DSButton,
    DSIcon,
    PreloaderComponent,
    InlineAlert,
  },
  data() {
    return {
      state: null,
      merchantModeAlert: {
        visible: true,
        variant: 'accent',
        title: this.$t('pages.checkout_links.list.test_mode_alert.title'),
        subtitle: this.$t('pages.checkout_links.list.test_mode_alert.text'),
      },
    };
  },
  setup() {
    const serviceContainer = inject('serviceContainer');
    const utilsService = serviceContainer.utilsService;

    return {
      utilsService,
    };
  },
  computed: {
    ...mapGetters({
      checkoutLinksList: 'checkoutLinkPageStore/getCheckoutLinksList',
      checkoutLinksListTotal: 'checkoutLinkPageStore/getCheckoutLinksListTotal',
      preloader: 'checkoutLinkPageStore/getPreloader',
      currentBreakpoint: 'commonStatusesStore/getCurrentBreakpoint',
      listLoading: 'commonStatusesStore/getLoading',
      merchantMode: 'authStore/getMerchantMode',
    }),
    isMobile() {
      return this.state?.mobileBreakpoints.includes(this.currentBreakpoint);
    },
    searchInputLabel() {
      return this.isMobile ? '' : this.state?.localisation.filters.search.label;
    },
    searchInputPlaceholder() {
      return this.isMobile
        ? this.state?.localisation.filters.search.label
        : this.state?.localisation.filters.search.placeholder;
    },
    sortByLabel() {
      return this.isMobile ? '' : this.state?.localisation.filters.sortBy.label;
    },
    countText() {
      return this.$tc('pages.checkout_links.list.links_count', this.checkoutLinksList.length);
    },
    gridItems() {
      return this.isMobile ? this.state?.gridItemsMobile : this.state?.gridItemsDesktop;
    },
    statusWindow() {
      if (!this.state || this.preloader || this.checkoutLinksList.length > 0 || this.state.searching) {
        return null;
      }

      const statusWindow = {};
      if (this.$utils.data.isEmpty(this.state.searchFilter)) {
        statusWindow.subtitle = this.state.localisation.statusWindow.subtitle;
        statusWindow.title = this.state.localisation.statusWindow.title;
        statusWindow.type = 'no_links';
        statusWindow.buttons = {
          createLink: {
            text: this.state.localisation.buttons.createLink,
          },
        };
      } else {
        statusWindow.subtitle = this.state.localisation.notFound.subtitle;
        statusWindow.type = 'empty_search';
        statusWindow.title = this.state.localisation.notFound.title;
      }

      return statusWindow;
    },
    addCheckoutLinkButton() {
      if (!this.state) {
        return null;
      }

      if (this.checkoutLinksList.length === 0 && this.$utils.data.isEmpty(this.state.searchFilter)) {
        return null;
      }

      return {
        text: this.createButtonText,
      };
    },
    createButtonText() {
      return this.state.localisation.buttons.create[this.merchantMode];
    },
  },
  async mounted() {
    const localisation = initLocalisation();
    this.state = initState(localisation);

    this.updateLoading(true);
    await this.refreshCheckoutLinksList();
    this.updateLoading(false);

    window.addEventListener('scroll', this.scrollListener);

    this.merchantModeAlert.visible = getIsMerchantModeAlertVisible(
      'checkoutLinks',
      this.merchantMode,
    );
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollListener);
  },
  methods: {
    ...mapActions({
      pushNotification: 'notificationsStore/pushNotification',
      refreshCheckoutLinks: 'checkoutLinkPageStore/refreshCheckoutLinks',
      updateCheckoutLinks: 'checkoutLinkPageStore/updateCheckoutLinks',
      updateLoading: 'commonStatusesStore/updateLoading',
    }),
    scrollListener(event) {
      if (event.detail?.ref?.ps?.reach?.y === 'end' && this.checkoutLinksListTotal > this.checkoutLinksList.length) {
        this.updateCheckoutLinksList();
      }
    },
    getColumnClass(item, isTitle = false) {
      return [
        'checkout-link-page__column',
        isTitle ? 'checkout-link-page__column_title' : null,
        `checkout-link-page__column_${item.key}`,
      ];
    },
    getProductsCount(checkoutLinksListItem) {
      return checkoutLinksListItem.parameters.price_groups.length;
    },
    getProductsComputedInfo(checkoutLinksListItem) {
      const lastEdit = this.$utils.date.formatSkipCurrentYearWithoutWeekDay(checkoutLinksListItem.lastUpdate);
      const count = checkoutLinksListItem.parameters.price_groups.length;

      return `${lastEdit} • ${count}`;
    },
    async handlerSearchFilterChange() {
      const isSearchDiapasonReached = this.state?.searchFilter.length > 2 && this.state?.searchFilter.length <= 255;
      if (isSearchDiapasonReached || this.$utils.data.isEmpty(this.state?.searchFilter)) {
        this.state.searching = true;
        await this.refreshCheckoutLinksList();
        this.state.searching = false;
      }
    },
    handleClickSearchClear() {
      this.state.searchFilter = '';

      this.handlerSearchFilterChange();
    },
    handlerOrderByFilterChange() {
      this.refreshCheckoutLinksList();
    },
    async refreshCheckoutLinksList() {
      await this.refreshCheckoutLinks({
        limit: this.state?.limit,
        offset: 0,
        searchString: this.state?.searchFilter && this.state?.searchFilter !== '' ? this.state?.searchFilter : null,
        sortByUpdateDate: this.state?.orderBy.value ?? 'desc',
      });
    },
    async updateCheckoutLinksList() {
      await this.updateCheckoutLinks({
        limit: this.state?.limit,
        offset: this.checkoutLinksList.length,
        searchString: this.state?.searchFilter && this.state?.searchFilter !== '' ? this.state?.searchFilter : null,
        sortByUpdateDate: this.orderBy.value ?? 'desc',
      });
    },
    handleLinkCopy() {
      this.pushNotification({
        title: this.state?.localisation.notification.copyLink,
        component: 'DSAlert',
        type: 'info',
      });
    },
    handleHowItWorksClick() {
      window.open(this.state?.localisation.howItWorks);
    },
    handleRedirectToEdit(checkoutLinksListItem) {
      this.$router.push(`/checkout-links/${checkoutLinksListItem.id}`).catch(() => null);
    },
    handleClickCloseMerchantModeAlert() {
      this.merchantModeAlert.visible = false;
      setMerchantModeAlertStatus('checkoutLinks', 'hidden');
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import "@/module/design-system/components/variables.scss";

.checkout-link-page {
  &__preloader {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__title-items-count {
    white-space: nowrap;
    margin-bottom: 7px;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &__table {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &-body {
      display: flex;
      flex-direction: column;
      gap: 28px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: auto 125px 100px 28px;
    column-gap: 50px;
  }

  &__item {
    padding: 20px 0 24px;
    border-bottom: 1px solid $surface-stroke-color;
  }

  &__header {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    gap: 53px;
  }

  &__column {
    &_name {
      margin-bottom: 8px;
      padding-left: 0;
    }

    &_last_edit,
    &_products,
    &_control {
      grid-row: span 2;
    }

    &_products {
      text-align: right;
    }

    &_checkout_link {
      overflow: hidden;
      padding-left: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &_title {
      grid-row: 1;
    }
  }

  &__filters {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
  }

  &__search {
    width: 315px;

    &-preloader {
      display: flex;
      align-items: center;
    }

    &-clear {
      cursor: pointer;
    }
  }

  &__order_by {
    flex: 0 0 206px;
    width: 206px;
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .checkout-link-page {
    &__order_by {
      flex: 0 0 48px;
      width: 48px;
    }

    &__main {
      gap: 22px;
    }

    &__table {
      &-title {
        display: none;
      }

      &-body {
        gap: 20px;
      }
    }

    &__grid {
      position: relative;
      grid-template-columns: max-content max-content minmax(0%, 1fr) 24px;
    }

    &__item {
      padding: 0 0 16px;
    }

    &__column {
      &_computed_info {
        margin-bottom: 12px;
        padding-left: 0;
      }

      &_control {
        margin-bottom: 0;
        padding-bottom: 0;
        padding-left: 0;
        border-bottom: none;
        grid-row: span 1;
      }

      &_name {
        margin-bottom: 6px;
        grid-column: span 3;
      }

      &_checkout_link {
        grid-column: span 4;
      }
    }
  }
}
</style>
