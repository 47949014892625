import IFormBlurHandler from '@/module/product/view/ProductFormPage/handler/contract/IFormBlurHandler';
import THandleFieldBlurParams from '@/module/product/view/ProductFormPage/model/THandleFieldBlurParams';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';

class FormBlurHandler implements IFormBlurHandler {
  private serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  handle = (item: THandleFieldBlurParams): void => {
    const productFormPage = this.serviceContainer.moduleProduct.productFormPage;
    const formConfigHandler = this.serviceContainer.moduleProduct.productFormPage.handlerService.formConfigHandler;
    const scenario = productFormPage.scenarioService.getBlurScenario(item);

    scenario.run(item);

    productFormPage.getState().isFormValid = true;

    const formConfig = formConfigHandler.get();
    formConfigHandler.update(formConfig);
  };
}

export default FormBlurHandler;
