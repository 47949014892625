import IFormConfigHandler
  from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/contract/IFormConfigHandler';
import IFormDataHandler
  from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/contract/IFormDataHandler';
import IFormHandler from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/contract/IFormHandler';
import IHeaderRowService from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/contract/IHeaderRowService';
import IPageValidatorHandler
  from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/contract/IPageValidatorHandler';
import IPaymentLinkFormPage
  from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/contract/IPaymentLinkFormPage';
import IScenario from '@/module/common/models/interface/IScenario';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IStateHandler from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/contract/IStateHandler';
import IValidateHandlerService
  from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/contract/IValidateHandlerService';

import CreatePaymentLinkScenario
  from '@/module/product/view/PaymentLink/PaymentLinkFormPage/scenario/CreatePaymentLinkScenario';
import FormConfigHandler from '@/module/product/view/PaymentLink/PaymentLinkFormPage/handler/FormConfigHandler';
import FormDataHandler from '@/module/product/view/PaymentLink/PaymentLinkFormPage/handler/FormDataHandler';
import FormHandler from '@/module/product/view/PaymentLink/PaymentLinkFormPage/handler/FormHandler';
import HeaderRowService from '@/module/product/view/PaymentLink/PaymentLinkFormPage/service/HeaderRowService';
import PageValidatorHandler from '@/module/product/view/PaymentLink/PaymentLinkFormPage/handler/PageValidatorHandler';
import StateHandler from '@/module/product/view/PaymentLink/PaymentLinkFormPage/handler/StateHandler';
import ValidateHandlerService
  from '@/module/product/view/PaymentLink/PaymentLinkFormPage/service/ValidateHandlerService';

class PaymentLinkFormPage implements IPaymentLinkFormPage {
  private readonly serviceContainer: IServiceContainer;

  private _stateHandler?: IStateHandler;

  private _formHandler?: IFormHandler;

  private _formDataHandler?: IFormDataHandler;

  private _formConfigService?: IFormConfigHandler;

  private _headerRowService?: IHeaderRowService;

  private _pageValidatorHandler?: IPageValidatorHandler;

  private _validateHandlerService?: IValidateHandlerService;

  private _createPaymentLinkScenario?: IScenario;

  constructor(
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
  }

  get stateHandler(): IStateHandler {
    if (!this._stateHandler) {
      this._stateHandler = new StateHandler(this.serviceContainer);
    }

    return this._stateHandler;
  }

  get formHandler(): IFormHandler {
    if (!this._formHandler) {
      this._formHandler = new FormHandler(this.serviceContainer);
    }

    return this._formHandler;
  }

  get formDataHandler(): IFormDataHandler {
    if (!this._formDataHandler) {
      this._formDataHandler = new FormDataHandler(this.serviceContainer);
    }

    return this._formDataHandler;
  }

  get formConfigHandler(): IFormConfigHandler {
    if (!this._formConfigService) {
      this._formConfigService = new FormConfigHandler(this.serviceContainer);
    }

    return this._formConfigService;
  }

  get headerRowService(): IHeaderRowService {
    if (!this._headerRowService) {
      this._headerRowService = new HeaderRowService(this.serviceContainer);
    }

    return this._headerRowService;
  }

  get validateHandlerService(): IValidateHandlerService {
    if (!this._validateHandlerService) {
      this._validateHandlerService = new ValidateHandlerService(this.serviceContainer);
    }

    return this._validateHandlerService;
  }

  get pageValidatorHandler(): IPageValidatorHandler {
    if (!this._pageValidatorHandler) {
      this._pageValidatorHandler = new PageValidatorHandler(this.serviceContainer);
    }

    return this._pageValidatorHandler;
  }

  get createPaymentLinkScenario(): IScenario {
    if (!this._createPaymentLinkScenario) {
      this._createPaymentLinkScenario = new CreatePaymentLinkScenario(this.serviceContainer);
    }

    return this._createPaymentLinkScenario;
  }
}

export default PaymentLinkFormPage;
