type THandlers<T extends string> = { [key in T]: EventListener };

class EventListenerRegister<T extends string> {
  private handlers: THandlers<T> = {} as THandlers<T>;

  register(name: T, handler: EventListener) {
    this.handlers[name] = handler;
    window.addEventListener('ClickPopupButton', (e) => {
      if (this.handlers[name]) {
        this.handlers[name](e);
      }
    });
  }
}

export default EventListenerRegister;
