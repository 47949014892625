enum EWidgetName {
  PERFORMANCE = 'performance',
  TRANSACTIONS = 'transactions',
  GROSS_REVENUE = 'grossRevenue',
  NET_REVENUE = 'netRevenue',
  REFUNDS = 'refunds',
  AVERAGE_TRANSACTION_AMOUNT = 'averageTransactionAmount',
  TOP_PRODUCTS = 'topProducts',
}

export default EWidgetName;
