enum EColorScheme {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DISABLED = 'disabled',
  LINKED = 'linked',
  NAVIGATION = 'navigation',
  PRIMARY_BUTTON = 'primary-button',
  MENU = 'menu',
  ERROR = 'error',
  SUCCESS = 'success',
  DEFAULT = 'default',
}

export default EColorScheme;
