function matchValidator(value1: unknown, value2: unknown): boolean {
  if (typeof value1 !== typeof value2) {
    return false;
  }

  if (['number', 'string'].includes(typeof value1)) {
    return value1 === value2;
  }

  if (value1 === null || value2 === null || value1 === undefined || value2 === undefined) {
    return value1 === value2;
  }

  if (Array.isArray(value1) && Array.isArray(value2)) {
    const compareFirstWithSecond = value1.reduce((result, val, index) => {
      const preResult = matchValidator(value1[index] ?? null, value2[index] ?? null);
      return result && preResult;
    }, true);
    const compareSecondWithFirst = value2.reduce((result, val, index) => {
      const preResult = matchValidator(value2[index] ?? null, value1[index] ?? null);
      return result && preResult;
    }, true);
    return compareFirstWithSecond && compareSecondWithFirst;
  }

  const compareFirstWithSecond = Object.keys((value1 as object)).reduce((result, key) => {
    const preResult = matchValidator((value1 as object)[key] ?? null, (value2 as object)[key] ?? null);
    return result && preResult;
  }, true);
  const compareSecondWithFirst = Object.keys((value2 as object)).reduce((result, key) => {
    const preResult = matchValidator((value2 as object)[key] ?? null, (value1 as object)[key] ?? null);
    return result && preResult;
  }, true);

  return compareFirstWithSecond && compareSecondWithFirst;
}

export default matchValidator;
