import mapAllowedMerchantModes from '@/module/common/utils/mapAllowedMerchantModes';

export default function authModule(instance) {
  return {
    merchantLogin(payload) {
      return instance.post('/vendor/auth', { code: payload, redirectUri: `${window.location.origin}/ssoAuth` })
        .then((response) => {
          response.data.allowedVendorModes = mapAllowedMerchantModes(response.data.allowedVendorModes);

          return Promise.resolve(response);
        });
    },
    getMerchantSSOLink() {
      return instance.get('/sso/link');
    },
    merchantInfo() {
      return instance.get('/vendor/info').then((response) => {
        const values = {
          sales: false,
          test: true,
        };

        response.data.allowedVendorModes = response.data.allowedVendorModes
          .map((mode) => ({ name: mode, value: values[mode] }));

        return Promise.resolve(response);
      });
    },
    refreshToken() {
      return instance.post('/token/refresh');
    },
  };
}
