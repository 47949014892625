import EIcon from '@/module/design-system/components/models/EIcon';

function getSidebarConfig() {
  return [
    {
      title: 'sidebar.dashboard',
      icon: EIcon.HOME,
      to: '/',
    },
    {
      title: 'sidebar.product.product',
      icon: EIcon.SERVICES,
      to: '/products',
      subgroup: {
        data: [
          {
            title: 'sidebar.product.overview',
            to: '/products',
          },
          {
            title: 'pages.payment_link_list.title',
            to: '/payment-links',
          },
          {
            title: 'pages.checkout_links.list.title',
            to: '/checkout-links',
          },
        ],
        show: false,
      },
    },
    {
      title: 'sidebar.orders',
      icon: EIcon.BILL,
      to: 'esupport',
    },
    {
      title: 'sidebar.reports.reports',
      icon: EIcon.ANALYTICS,
      to: 'nowhere',
      subgroup: {
        type: 'notify',
        notify: [
          {
            icon: EIcon.CLOCK,
            text: 'sidebar.will_be_available',
          },
        ],
        show: false,
      },
      soon: true,
      iconSoon: true,
    },
    {
      title: 'sidebar.promotions',
      icon: EIcon.PROMOTIONS,
      to: '/promotions',
    },
    {
      title: 'sidebar.branding',
      icon: EIcon.BRANDING,
      to: '/branding',
    },
    {
      title: 'sidebar.data_exports',
      icon: EIcon.DATA_EXPORTS,
      to: '/data-exports',
    },
    {
      title: 'sidebar.developers.developers',
      icon: EIcon.DEVELOPERS,
      to: 'nowhere',
      subgroup: {
        data: [
          {
            title: 'sidebar.developers.api_keys',
            to: '/api-keys',
          },
          {
            title: 'sidebar.developers.webhooks',
            to: '/webhooks',
          },
        ],
        show: false,
      },
    },
  ];
}

export default getSidebarConfig;
