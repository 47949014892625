import { render, staticRenderFns } from "./DSDataExportsIcon.vue?vue&type=template&id=15e6e762&scoped=true&"
import script from "./DSDataExportsIcon.vue?vue&type=script&lang=js&"
export * from "./DSDataExportsIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15e6e762",
  null
  
)

export default component.exports