export default () => ({
  namespaced: true,
  state: {
    scrollDirection: null,
    isScrollLeftDisabled: true,
    isScrollRightDisabled: false,
  },
  mutations: {
    setScrollDirection(state, newDirection) {
      state.scrollDirection = newDirection;
    },
    setScrollDisabled(state, { direction, isDisabled }) {
      switch (direction) {
        case 'right':
          state.isScrollRightDisabled = isDisabled;
          break;
        case 'left':
          state.isScrollLeftDisabled = isDisabled;
          break;
        default:
          break;
      }
    },
  },
  getters: {
    getScrollDirection(state) {
      return state.scrollDirection;
    },
    getScrollDisabled(state) {
      return {
        left: state.isScrollLeftDisabled,
        right: state.isScrollRightDisabled,
      };
    },
  },
});
