enum EPerformanceTableColumn {
  PERIOD = 'period',
  CURRENCY_NAME = 'currency_name',
  PERFORMANCE_SUM_END_USER_PRICE = 'performance_sum_end_user_price',
  PERFORMANCE_SUM_NET_PRICE = 'performance_sum_net_price',
  ORDERS = 'orders',
  PERFORMANCE_AVERAGE_ORDERS = 'performance_average_orders',
  EMPTY = 'empty',
}

export default EPerformanceTableColumn;
