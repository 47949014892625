import IObjectTool from '@/module/common/service/UtilsService/contract/IObjectTool';
import IUtilsService from '@/module/common/service/UtilsService/contract/IUtilsService';

class ObjectTool implements IObjectTool {
  private readonly utils: IUtilsService;

  constructor(utils: IUtilsService) {
    this.utils = utils;
  }

  isEmpty = (object: object): boolean => Object.keys(object).length === 0;
}

export default ObjectTool;
