
import Vue, { inject } from 'vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';

export default Vue.extend({
  name: 'CCookieModal',
  components: {
    DSButton,
    DSTextElement,
  },
  setup(_, { emit }) {
    const serviceContainer = inject('serviceContainer') as IServiceContainer;
    const localisationRegistry = serviceContainer.localisationRegistry;
    const text = localisationRegistry.common.cookieModalText;

    const button = {
      handler: () => emit('ok-click'),
      text: localisationRegistry.common.ok,
    };

    return {
      button,
      text,
    };
  },
});
