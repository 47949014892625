<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      :fill="color"
      width="100%"
      height="100%"
      rx="4"
    />
    <!-- eslint-disable vue/max-len max-len -->
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.00005 11.2L4.50005 8.70002C4.40846 8.60656 4.29926 8.53219 4.17875 8.4812C4.05825 8.43021 3.92883 8.40362 3.79798 8.40296C3.66713 8.4023 3.53745 8.42758 3.41643 8.47735C3.29542 8.52712 3.18547 8.60039 3.09294 8.69291C3.00042 8.78544 2.92715 8.89539 2.87738 9.0164C2.82762 9.13742 2.80233 9.2671 2.80299 9.39795C2.80365 9.5288 2.83025 9.65822 2.88123 9.77872C2.93222 9.89923 3.00659 10.0084 3.10005 10.1L6.29305 13.293C6.48058 13.4805 6.73489 13.5858 7.00005 13.5858C7.26521 13.5858 7.51952 13.4805 7.70705 13.293L15.3 5.70002C15.4826 5.51373 15.5843 5.26292 15.583 5.00209C15.5816 4.74126 15.4775 4.49149 15.293 4.30705C15.1086 4.12262 14.8588 4.01842 14.598 4.0171C14.3371 4.01579 14.0863 4.11746 13.9 4.30002L7.00005 11.2Z"
      fill="white"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSCheckboxOnIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>
