import VueRouter from 'vue-router';
import {
  Store,
} from 'vuex';

import EBreakPoint from '@/module/design-system/components/models/EBreakPoint';
import EDSButtonVariant from '@/module/design-system/models/UI/DSButton/EDSButtonVariant';
import EFieldName from '@/module/product/view/FulfillmentFormPage/model/enums/EFieldName';
import EIcon from '@/module/design-system/components/models/EIcon';
import EIconColorName from '@/module/design-system/components/models/EIconColorName';
import ELicenseSourceName from '@/module/product/view/FulfillmentFormPage/model/enums/ELicenseSourceName';
import ELocalServiceFulfillment from '@/module/product/view/FulfillmentFormPage/model/enums/ELocalServiceFulfillment';
import EScenario from '@/module/product/view/FulfillmentFormPage/model/enums/EScenario';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';

import IFormDataSalesCreateGenerator
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataSalesCreateGenerator';
import IFormDataTestCreateGenerator
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataTestCreateGenerator';
import IHandlerService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IHandlerService';
import IHeaderControlsService
  from '@/module/product/view/FulfillmentFormPage/model/contract/service/IHeaderControlsService';
import ILocalServiceContainer from '@/module/product/view/FulfillmentFormPage/model/ILocalServiceContainer';
import IScenarioService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IScenarioService';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IState from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/IState';

import TFormData from '@/module/product/view/FulfillmentFormPage/model/types/TFormData';
import TNavigationItem from '@/module/common/models/CFormPageHeaderRow/TNavigationItem';

import getButton, {
  getButtonWithIcon,
} from '@/module/common/helpers/getButton';

class HeaderControlsService implements IHeaderControlsService {
  private state: IState;

  private serviceContainer: IServiceContainer;

  private localServiceContainer: ILocalServiceContainer;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    localServiceContainer: ILocalServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
    this.localServiceContainer = localServiceContainer;
    this.state = state;
  }

  getLeftColumnItems = (): TNavigationItem[] => {
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const router = this.serviceContainer.resolve<VueRouter>(EServiceContainerInstances.ROUTER);

    return [
      getButtonWithIcon(
        {
          text: store.getters.getLocalisationRegistry.common.cancel,
          variant: EDSButtonVariant.TRETIARY,
        },
        () => {
          router.push('/products').catch(() => null);
        },
        {
          icon: EIcon.CROSS,
          color: EIconColorName.LINKED,
        },
      ),
    ];
  };

  getRightColumnItems = (formData: TFormData): TNavigationItem[] => {
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);

    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const scenarioSelectService = this.localServiceContainer
      .resolve<IScenarioService>(ELocalServiceFulfillment.SCENARIO_SERVICE);

    const formHandler = handlerService.getFormHandler();

    const isScenarioSelected = this.state.licenseSourceName !== ELicenseSourceName.DEFAULT;
    const isSaveEnabled = isScenarioSelected && !this.state.isLoading;

    const buttons = [
      getButton(
        {
          text: store.getters.getLocalisationRegistry.common.save,
          stretch: EBreakPoint.MD,
          disabled: !isSaveEnabled,
        },
        formHandler.handleSaveClick,
      ),
    ];

    if (this.isSalesCreateGenerator(formData) || this.isTestCreateGenerator(formData)) {
      const formConfigHandler = handlerService.getFormConfigHandler();
      const formConfig = formConfigHandler.getConfig(this.state.licenseSourceName);

      const urlField = formConfig[0].items.find((item) => item.fieldName === EFieldName.URL);
      const isUrlFieldInvalid = urlField?.status?.code === EValidationFieldStatusCode.ERROR;

      const secretKeyField = formConfig[0].items.find((item) => item.fieldName === EFieldName.SECRET_KEY);
      const isSecretKeyFieldInvalid = secretKeyField?.status?.code === EValidationFieldStatusCode.ERROR;
      const isTestButtonDisabled = !formData.url || !formData.secretKey || this.state.isTestRequestProcessing
        || isUrlFieldInvalid || isSecretKeyFieldInvalid;

      buttons.unshift(
        getButton(
          {
            text: store.getters.getLocalisationRegistry.fulfillment.form.header.buttons.test,
            variant: EDSButtonVariant.SECONDARY,
            stretch: EBreakPoint.MD,
            disabled: isTestButtonDisabled,
          },
          formHandler.handleTestClick,
          scenarioSelectService.getSaveScenarioName(this.state.licenseSourceName) === EScenario.CREATE_GENERATOR,
        ),
      );
    }

    return buttons;
  };

  private isSalesCreateGenerator = (formData: TFormData): formData is IFormDataSalesCreateGenerator => {
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const licenseSourceHandler = handlerService.getLicenseSourceHandler();
    const licenseSourceName = licenseSourceHandler.getLicenseSourceName(formData);

    return licenseSourceName === ELicenseSourceName.SALES_CREATE_GENERATOR;
  };

  private isTestCreateGenerator = (formData: TFormData): formData is IFormDataTestCreateGenerator => {
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const licenseSourceHandler = handlerService.getLicenseSourceHandler();
    const licenseSourceName = licenseSourceHandler.getLicenseSourceName(formData);

    return licenseSourceName === ELicenseSourceName.TEST_CREATE_GENERATOR;
  };
}

export default HeaderControlsService;
