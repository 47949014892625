import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import ServiceContainerException from '@/module/common/service/ServiceContainer/ServiceContainerException';
import IAuthorizeService from '@/module/common/service/AuthorizeService/IAuthorizeService';
import ILocalStorageService from '@/module/common/service/LocalStorage/ILocalStorageService';
import LocalisationRegistry from '@/module/common/utils/localisation/LocalisationRegistry';
import IMerchantModeService from '@/module/common/models/service/MerchantMode/IMerchantModeService';
import IProductModule from '@/module/product/service/contract/IProductModule';
import INotificationService from '@/module/common/service/NotificationService/INotificationService';
import INumberFormatService from '@/module/common/service/NumberFormatService/contract/INumberFormatService';
import IPortalModeService from '@/module/common/service/PortalMode/IPortalModeService';
import IUtilsService from '@/module/common/service/UtilsService/contract/IUtilsService';
import IServiceContainerState from '@/module/common/service/ServiceContainer/IServiceContainerState';
import AuthorizeService from '@/module/common/service/AuthorizeService/AuthorizeService';
import LocalStorageService from '@/module/common/service/LocalStorage/LocalStorageService';
import MerchantModeService from '@/module/common/service/MerchantMode/MerchantModeService';
import ProductModule from '@/module/product/service/ProductModule';
import NotificationService from '@/module/common/service/NotificationService/NotificationService';
import NumberFormatService from '@/module/common/service/NumberFormatService/NumberFormatService';
import PortalModeService from '@/module/common/service/PortalMode/PortalModeService';
import UtilsService from '@/module/common/service/UtilsService/UtilsService';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';
import IPortalConfigService from '@/module/common/service/PortalConfigService/IPortalConfigService';
import PortalConfigService from '@/module/common/service/PortalConfigService/PortalConfigService';
import portalConfig from '@/config/config';
import IApiService from '@/module/common/service/ApiService/contract/IApiService';
import ApiService from '@/module/common/service/ApiService/ApiService';
import IAxiosClientService from '@/module/common/service/AxiosClientService/IAxiosClientService';
import AxiosClientService from '@/module/common/service/AxiosClientService/AxiosClientService';
import IHelperService from '@/module/common/service/HelperService/IHelperService';
import HelperService from '@/module/common/service/HelperService/HelperService';
import ICookieService from '@/module/common/service/CookieService/ICookieService';
import CookieService from '@/module/common/service/CookieService/CookieService';
import IConfig from '@/module/common/service/PortalConfigService/IConfig';

class ServiceContainer implements IServiceContainer {
  readonly serviceMap: Record<EServiceContainerInstances, unknown> = {} as Record<EServiceContainerInstances, unknown>;

  private state: IServiceContainerState = {};

  register<Type>(serviceName: EServiceContainerInstances, instance: Type): void {
    this.serviceMap[serviceName] = instance;
  }

  resolve<Type>(serviceName: EServiceContainerInstances): Type {
    if (!this.serviceMap[serviceName]) {
      throw new ServiceContainerException(`Service '${serviceName}' not registered.`);
    }

    return this.serviceMap[serviceName] as Type;
  }

  get authorizeService(): IAuthorizeService {
    if (!this.state.authorizeService) {
      this.state.authorizeService = new AuthorizeService(this);
    }

    return this.state.authorizeService;
  }

  get localStorageService(): ILocalStorageService {
    if (!this.state.localStorageService) {
      this.state.localStorageService = new LocalStorageService(this);
    }

    return this.state.localStorageService;
  }

  get cookieService(): ICookieService {
    if (!this.state.cookieService) {
      this.state.cookieService = new CookieService(this);
    }

    return this.state.cookieService;
  }

  get localisationRegistry(): ILocalisationRegistry {
    if (!this.state.localisationRegistry) {
      this.state.localisationRegistry = new LocalisationRegistry(this);
    }

    return this.state.localisationRegistry;
  }

  get merchantModeService(): IMerchantModeService {
    if (!this.state.merchantModeService) {
      this.state.merchantModeService = new MerchantModeService(this);
    }

    return this.state.merchantModeService;
  }

  get moduleProduct(): IProductModule {
    if (!this.state.moduleProduct) {
      this.state.moduleProduct = new ProductModule(this);
    }

    return this.state.moduleProduct;
  }

  get notificationService(): INotificationService {
    if (!this.state.notificationService) {
      this.state.notificationService = new NotificationService(this);
    }

    return this.state.notificationService;
  }

  get numberFormatService(): INumberFormatService {
    if (!this.state.numberFormatService) {
      this.state.numberFormatService = new NumberFormatService(this);
    }

    return this.state.numberFormatService;
  }

  get portalModeService(): IPortalModeService {
    if (!this.state.portalModeService) {
      this.state.portalModeService = new PortalModeService(this);
    }

    return this.state.portalModeService;
  }

  get utilsService(): IUtilsService {
    if (!this.state.utilsService) {
      this.state.utilsService = new UtilsService(this);
    }

    return this.state.utilsService;
  }

  get portalConfigService(): IPortalConfigService {
    if (!this.state.portalConfigService) {
      this.state.portalConfigService = new PortalConfigService(portalConfig as unknown as IConfig);
    }

    return this.state.portalConfigService;
  }

  get axiosClientService(): IAxiosClientService {
    if (!this.state.axiosClientService) {
      this.state.axiosClientService = new AxiosClientService();
      this.state.axiosClientService.addResponseInterceptors(
        this.localStorageService,
      );
      this.state.axiosClientService.addRequestInterceptors(
        this.authorizeService,
      );
    }

    return this.state.axiosClientService;
  }

  get apiService(): IApiService {
    if (!this.state.apiService) {
      this.state.apiService = new ApiService(this.axiosClientService.instance, this);
    }

    return this.state.apiService;
  }

  get helperService(): IHelperService {
    if (!this.state.helperService) {
      this.state.helperService = new HelperService();
    }

    return this.state.helperService;
  }
}

export default ServiceContainer;
