<template>
  <div
    class="c-icon-button"
    @click="$emit('click')"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CIconButton',
};
</script>

<style
  lang="scss"
  scoped
>
.c-icon-button {
  display: inline-block;
  cursor: pointer;
}
</style>
