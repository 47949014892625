const defaultStore = () => ({
  card: {},
});

export default (serviceContainer) => ({
  namespaced: true,
  state: defaultStore(),
  mutations: {
    setCard(state, data) {
      state.card = data;
    },
  },
  actions: {
    async loadPromotionCard({ commit }, id) {
      let data = {};

      const api = serviceContainer.resolve('api');

      try {
        const response = await api.promotion.getCard(id);
        commit('setCard', response.data);
        data = response.data;
      } catch (error) {
        data = { error };
      }

      return Promise.resolve(data);
    },
    async changeStatus({ state, dispatch, commit }, newStatus) {
      let response;

      const api = serviceContainer.resolve('api');

      const id = state.card.promotionId;
      const { promotionType } = state.card;
      commit('promotionsOverviewStore/setIsInitialRequest', true, { root: true });
      commit('promotionsOverviewStore/setIsRequestRunning', true, { root: true });
      commit('promotionsOverviewStore/setIsOverwriting', null, { root: true });
      try {
        switch (newStatus) {
          case 'delete':
            response = await api.promotion.delete(state.card.promotionId);
            break;
          case 'disable':
            response = await api.promotion.setNewStatus(id, { promotionType, status: false });
            if (response.status < 400) {
              commit('setCard', {});
              dispatch('loadPromotionCard', id);
            }
            break;
          case 'activate':
            response = await api.promotion.setNewStatus(id, { promotionType, status: true });
            if (response.status < 400) {
              commit('setCard', {});
              dispatch('loadPromotionCard', id);
            }
            break;
          default:
            break;
        }
      } catch (e) {
        response = e.response;
      }

      return response;
    },
  },
  getters: {
    getPromotionCard(state) {
      return state.card;
    },
    getCouponsType(state) {
      return state.card.promotionStatistic.coupons[0].couponType;
    },
    getAllCoupons(state) {
      return state.card.promotionStatistic.coupons;
    },
    getAllCouponsNumber(state) {
      return state.card.promotionStatistic.coupons.length || 0;
    },
    getActiveCouponsNumber(state, { getCouponsType, getAllCouponsNumber }) {
      return getCouponsType === 'one-time'
        ? getAllCouponsNumber - state.card.promotionStatistic.promotionUses
        : getAllCouponsNumber;
    },
  },
});
