<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      d="M20 3H4C2.897 3 2 3.897 2 5V19C2 20.103 2.897 21 4 21H20C21.103 21 22 20.103 22 19V5C22 3.897 21.103 3 20 3ZM4 19V7H20L20.002 19H4Z"
    />
    <path
      :fill="color"
      d="M9.29294 9.29297L5.58594 13L9.29294 16.707L10.7069 15.293L8.41394 13L10.7069 10.707L9.29294 9.29297ZM14.7069 9.29297L13.2929 10.707L15.5859 13L13.2929 15.293L14.7069 16.707L18.4139 13L14.7069 9.29297Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSDevelopersIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>

<style scoped>

</style>
