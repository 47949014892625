
import Vue,
{
  computed,
  PropType,
} from 'vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import EColorScheme from '@/module/design-system/components/models/EColorScheme';
import EIconColorName from '@/module/design-system/components/models/EIconColorName';

export default Vue.extend({
  name: 'DSInlineAlert',
  components: {
    DSTextElement,
    DSIcon,
  },
  props: {
    variant: {
      type: String as PropType<EColorScheme>,
      default: () => EColorScheme.SUCCESS,
    },
    text: {
      type: String,
      default: () => null,
    },
    title: {
      type: String,
      default: () => null,
    },
    disableIcon: {
      type: Boolean,
      default: () => false,
    },
    allowHtml: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { slots }) {
    const iconColorsMap = {
      [EColorScheme.SUCCESS]: EIconColorName.SUCCESS,
      [EColorScheme.ERROR]: EIconColorName.ERROR,
      [EColorScheme.PRIMARY]: EIconColorName.PRIMARY,
      [EColorScheme.WARNING]: EIconColorName.WARNING,
    };

    const isIconSlot = computed(() => slots.icon);
    const isControlsSLot = computed(() => slots.controls);
    const rootClasses = computed(() => [
      'ds-inline-alert',
      `ds-inline-alert_color_${props.variant}`,
    ]);
    const iconColor = computed(() => iconColorsMap[props.variant]);
    const contentCLasses = computed(() => [
      'ds-inline-alert__content',
      'ds-inline-alert__content_grow',
    ]);

    return {
      isIconSlot,
      isControlsSLot,
      rootClasses,
      iconColor,
      contentCLasses,
    };
  },
});
