enum ECurrency {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  CAD = 'CAD',
  AUD = 'AUD',
  NZD = 'NZD',
  RUB = 'RUB',
}

export default ECurrency;
