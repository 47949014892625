// eslint-disable-next-line max-len
import IFormDataMapper from '@/module/merchant/view/AccountActivatePage/DataMapper/IFormDataMapper';
import IFormFieldItem from '@/module/design-system/components/models/FormComponent/IFormFieldItem';
import cloneImmutable from '@/module/common/utils/data/cloneImmutable';
import EFieldName from '@/module/merchant/view/AccountActivatePage/models/EFieldName';
import EFieldBlock from '@/module/merchant/view/AccountActivatePage/models/EFieldBlock';
import IPageState from '@/module/merchant/view/AccountActivatePage/models/IPageState';
import TFormData
  from '@/module/merchant/view/AccountActivatePage/models/FormData/TFormData';
// eslint-disable-next-line max-len
import TFormDataIndividualEntrepreneur from '@/module/merchant/view/AccountActivatePage/models/FormData/TFormDataIndividualEntrepreneur';
import TFromDataDraft
  from '@/module/merchant/view/AccountActivatePage/models/Draft/TFromDataDraft';
import IKontragentioIndividualEntry from '@/module/merchant/models/Kontragentio/IKontragentioIndividualEntry';
import IKontragentioCompanyEntry from '@/module/merchant/models/Kontragentio/IKontragentioCompanyEntry';
import EBusinessType from '@/module/merchant/models/BuisnessType/EBusinessType';
import TFormDataCompany
  from '@/module/merchant/view/AccountActivatePage/models/FormData/TFormDataCompany';

class FormDataKontragentioFillMapper implements IFormDataMapper {
  private readonly state: IPageState;

  constructor(
    state: IPageState,
  ) {
    this.state = state;
  }

  // eslint-disable-next-line class-methods-use-this
  mapFieldValue(
    formFieldConfigItem: IFormFieldItem<EFieldName, EFieldBlock>,
    data: TFormData
      | TFromDataDraft
      | IKontragentioIndividualEntry
      | IKontragentioCompanyEntry,
  ) {
    let formData: TFormData;

    if (this.state.formData.businessStructure.businessType.id === EBusinessType.INDIVIDUAL_ENTREPRENEUR) {
      formData = cloneImmutable(this.state.formData) as TFormDataIndividualEntrepreneur;
      const entry: IKontragentioIndividualEntry = data as IKontragentioIndividualEntry;

      formData.businessRepresentative.firstName = entry.firstName;
      formData.businessRepresentative.lastName = entry.lastName;
      formData.businessRepresentative.middleName = entry.middleName;

      formData.businessRepresentative.jobTitle = `${entry.lastName} ${entry.firstName} ${entry.middleName}`;

      if (entry.address?.full) {
        formData.businessStructure.actualAddress = entry.address.full;
        formData.businessStructure.legalAddress = entry.address.full;
      }

      if (entry.personalData?.birthDate) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.businessRepresentative.dateOfBirth = new Date(entry.personalData.birthDate);
      }

      if (entry.personalData?.snils) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.businessDetails.snils = entry.personalData.snils;
      }

      if (entry.ogrnip) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.businessDetails.documentOgrnip = entry.ogrnip;
      }

      if (entry.status?.reorganizationParticipants?.[0]?.company?.fullName) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.businessDetails.legalBusinessName = entry.status.reorganizationParticipants[0].company.fullName;
      }
    } else {
      const entry: IKontragentioCompanyEntry = data as IKontragentioCompanyEntry;
      formData = cloneImmutable(this.state.formData) as TFormDataCompany;

      if (entry.address?.full) {
        formData.businessStructure.actualAddress = entry.address.full;
        formData.businessStructure.legalAddress = entry.address.full;
      }

      if (entry.management?.[0]) {
        const companyManagement = entry.management[0];
        if (companyManagement.position) {
          formData.businessRepresentative.jobTitle = companyManagement.position;
        }
        if (companyManagement.head?.firstName) {
          formData.businessRepresentative.firstName = companyManagement.head.firstName;
        }
        if (companyManagement.head?.lastName) {
          formData.businessRepresentative.lastName = companyManagement.head.lastName;
        }
        if (companyManagement.head?.middleName) {
          formData.businessRepresentative.middleName = companyManagement.head.middleName;
        }
      }

      if (entry.fullName) {
        formData.businessDetails.legalBusinessName = entry.fullName;
      }

      if (entry.shortName) {
        formData.businessDetails.doingBusinessAs = entry.shortName;
      }

      if (entry.ogrn) {
        formData.businessDetails.ogrn = entry.ogrn;
      }

      if (entry.kpp) {
        formData.businessDetails.kpp = entry.kpp;
      }

      if (entry.verification?.data?.contacts?.website) {
        formData.businessDetails.businessWebsite = entry.verification.data.contacts.website;
      }

      if (entry.verification?.data?.description) {
        formData.businessDetails.productDescription = entry.verification.data.description;
      }

      if (entry.verification?.data?.bankAccounts?.[0]) {
        const bankAccount = entry.verification?.data?.bankAccounts?.[0];
        if (bankAccount.bankName) {
          formData.accountForPayouts.bankName = bankAccount.bankName;
        }
        if (bankAccount.bic) {
          formData.accountForPayouts.bik = bankAccount.bic;
        }
        if (bankAccount.correspondentAccount) {
          formData.accountForPayouts.ks = bankAccount.correspondentAccount;
        }
      }
    }

    return formData;
  }
}

export default FormDataKontragentioFillMapper;
