
import Vue, {
  computed,
  PropType,
} from 'vue';

import ETextVariants from '@/module/common/models/ETextVariants';
import EColorScheme from '@/module/common/models/EColorScheme';
import EDSColorScheme from '@/module/design-system/components/models/EColorScheme';

export default Vue.extend({
  name: 'DSTextElement',
  props: {
    variant: {
      type: String as PropType<ETextVariants>,
      default: ETextVariants.REGULAR,
    },
    color: {
      type: String as PropType<EColorScheme | EDSColorScheme>, // @TODO избавиться от одной из EColorScheme
      default: EColorScheme.PRIMARY,
    },
  },
  setup(props) {
    const classes = computed(() => [
      'ds-text-block',
      `ds-text-block_variant_${props.variant}`,
      `ds-text-block_color_${props.color}`,
    ]);

    return {
      classes,
    };
  },
});
