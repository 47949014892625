function extractFileExtension(fileName: string): string {
  if (fileName.indexOf('.') === -1) {
    return '';
  }

  const parts: string[] = fileName.split('.');

  if (parts.length === 2 && fileName.indexOf('.') === 0) {
    return '';
  }

  return parts.pop() as string;
}

export default extractFileExtension;
