import IModalHandler from '@/module/product/view/FulfillmentFormPage/model/contract/handler/IModalHandler';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import {
  Store,
} from 'vuex';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import IState from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/IState';
import ITestRequestModal from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/ITestRequestModal';
import EModalVariant from '@/module/design-system/models/UI/DSModal/EModalVariant';
import getButton from '@/module/common/helpers/getButton';
import EBreakPoint from '@/module/design-system/components/models/EBreakPoint';
import ITestRequestResponse from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/ITestRequestResponse';
import EColorScheme from '@/module/common/models/EColorScheme';
import ENotifyVariant from '@/module/design-system/components/models/UI/ENotifyVariant';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';

class ModalHandler implements IModalHandler {
  private readonly state: IState;

  private readonly serviceContainer: IServiceContainer;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
  }

  handleCopy = async () => {
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const localisationRegistry: ILocalisationRegistry = store.getters.getLocalisationRegistry;
    const fulfillmentFormLocalisation = localisationRegistry.fulfillment.form;

    await store.dispatch(
      'notificationsStore/pushNotification',
      {
        title: fulfillmentFormLocalisation.notification.copy.title ?? '',
        type: ENotifyVariant.INFO,
        component: 'DSAlert',
      },
    );
  };

  closeTestRequest = () => {
    this.state.isTestRequestVisible = false;
  };

  getTestRequestModalConfig = (data?: ITestRequestResponse): ITestRequestModal => {
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);

    const isSuccess = data?.statusCode === 200;

    return {
      title: store.getters.getLocalisationRegistry.fulfillment.form.modal.testRequest.title,
      variant: EModalVariant.WIDE,
      submittingUrl: {
        text: store.getters.getLocalisationRegistry.fulfillment.form.modal.testRequest.submittingUrl,
        value: data?.url ?? '',
      },
      method: {
        text: store.getters.getLocalisationRegistry.fulfillment.form.modal.testRequest.method,
        value: data?.method ?? '',
      },
      parameters: {
        text: store.getters.getLocalisationRegistry.fulfillment.form.modal.testRequest.parameters,
        value: data?.requestParameters ?? '',
      },
      statusCode: {
        text: store.getters.getLocalisationRegistry.fulfillment.form.modal.testRequest.statusCode,
        value: data?.statusCode ? `${data.statusCode}` : '',
        color: isSuccess ? EColorScheme.SUCCESS : EColorScheme.ERROR,
        tag: {
          text: store.getters.getLocalisationRegistry.common.responseStatus[isSuccess ? 'success' : 'error'],
          color: isSuccess ? '#27ae60' : '#ff4444',
          backgroundColor: isSuccess ? '#27ae601a' : '#ff44441a',
        },
      },
      response: {
        text: store.getters.getLocalisationRegistry.fulfillment.form.modal.testRequest.response,
        value: data?.content ?? '',
      },
      button: getButton(
        {
          text: store.getters.getLocalisationRegistry.fulfillment.form.modal.testRequest.button,
          stretch: EBreakPoint.XXL,
        },
        this.closeTestRequest,
      ),
    };
  };
}

export default ModalHandler;
