<template>
  <ul
    :class="listClasses"
  >
    <li
      v-for="(item, index) in list"
      :key="index"
      :class="itemClasses"
    >
      <template
        v-if="!isSlots"
      >
        <DSTextElement>
          {{ item }}
        </DSTextElement>
      </template>
      <slot
        :item="item"
        name="list-item"
      />
    </li>
  </ul>
</template>

<script>
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default {
  name: 'DSMarkedList',
  components: {
    DSTextElement,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    listStyle: {
      type: String,
      default: () => 'disc',
    },
  },
  computed: {
    isSlots() {
      return !!this.$scopedSlots['list-item'];
    },
    itemClasses() {
      return [
        'ds-list__item',
        `ds-list__item_${this.listStyle}`,
      ];
    },
    listClasses() {
      return [
        'ds-list',
        this.listStyle === 'none' ? 'ds-list_none' : 'ds-list_marked',
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.ds-list {
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: 10px;

  &_marked {
    padding: 0 0 0 15px;
  }

  &_none {
    padding: 0;
  }

  &__item {
    margin-bottom: 0;

    &_disc {
      list-style-type: disc;
    }

    &_none {
      list-style-type: none;
    }
  }
}
</style>
