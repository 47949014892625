
import {
  defineComponent,
  computed,
  inject,
} from 'vue';

import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IUtils from '@/plugins/utils/IUtils';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import EWidgetName from '@/module/dashboard/models/EWidgetName';
import EWidgetDataFormat from '@/module/dashboard/models/EWidgetDataFormat';

import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import CPercentage from '@/module/common/components/Data/CPercentage.vue';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';
import IUtilsService from '@/module/common/service/UtilsService/contract/IUtilsService';

export default defineComponent({
  name: 'DashboardWidgetMetrics',
  components: {
    DSTextElement,
    CPercentage,
  },
  props: {
    widget: {
      type: Object,
      required: true,
    },
    delimiters: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;
    const utilsService = serviceContainer.resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);
    const utils = serviceContainer.resolve<IUtils>(EServiceContainerInstances.UTILS);
    const numberFormatService = serviceContainer.numberFormatService;
    const localisationRegistry = serviceContainer.resolve<ILocalisationRegistry>(
      EServiceContainerInstances.LOCALISATION_REGISTRY,
    );
    const localisationCurrency = localisationRegistry.currency;

    const listClasses = computed(() => [
      'dashboard-widget-metrics__list',
      `dashboard-widget-metrics__list_${props.delimiters ? 'delimiters' : 'default'}`,
    ]);

    function formatTitle(value): number | string {
      if (utilsService.typeCheck.isNumber(value)) {
        let title = numberFormatService.amount(value);

        if (props.widget.config.format.type === EWidgetDataFormat.PERCENTAGE) {
          title = numberFormatService.percentage(value);
        } else if (props.widget.config.format.type === EWidgetDataFormat.CURRENCY) {
          title = numberFormatService.money(
            value,
            localisationCurrency.shortTitle[props.widget.config.format.currencyName],
          );
        }

        return title;
      }

      return value;
    }

    const metrics = computed(() => props.widget.data?.metrics.filter((metric) => {
      let showMetric = true;

      if (Object.prototype.hasOwnProperty.call(metric, 'percent')) {
        showMetric = metric.percent !== undefined;
      }

      return showMetric;
    }));

    const isRefunds = computed(() => props.widget.config.name === EWidgetName.REFUNDS);

    return {
      listClasses,
      formatTitle,
      isRefunds,
      metrics,
      utils,
    };
  },
});
