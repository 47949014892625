import { render, staticRenderFns } from "./CCheckboxGroup.vue?vue&type=template&id=f608c152&scoped=true&"
import script from "./CCheckboxGroup.vue?vue&type=script&lang=ts&"
export * from "./CCheckboxGroup.vue?vue&type=script&lang=ts&"
import style0 from "./CCheckboxGroup.vue?vue&type=style&index=0&id=f608c152&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f608c152",
  null
  
)

export default component.exports