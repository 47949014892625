function notEmptyValidator(value: unknown): boolean {
  if (Array.isArray(value)) {
    return value.length > 0;
  }

  if (value === null || value === undefined) {
    return false;
  }

  if (typeof value === 'string') {
    return !!value.trim();
  }

  if (typeof value === 'object' && value.constructor?.name === 'Object') {
    return Object.values(value as object).length > 0;
  }

  return !!value;
}

export default notEmptyValidator;
