<template>
  <div class="c-text-block">
    <DSHeader
      v-if="block.title"
      :size="2"
      :text="block.title"
      class="c-text-block__header"
    />

    <template
      v-for="(item, index) in block.items"
    >
      <template
        v-if="item.type === 'paragraph'"
      >
        <DSParagraph
          :key="`${block.name}-${index}`"
          class="c-text-block__paragraph"
        >
          <!-- eslint-disable vue/no-v-html -->
          <span
            v-html="item.data"
          />
          <!-- eslint-enable vue/no-v-html -->
        </DSParagraph>
      </template>
      <template
        v-else-if="item.type === 'list'"
      >
        <CMarkedList
          :key="`${block.name}-${index}`"
          :list="item.data.items"
          :text-after="item.data.textAfter"
          :text-before="item.data.textBefore"
          :title="item.data.title"
          class="c-text-block__list"
        />
      </template>
      <template
        v-else-if="item.type === 'table'"
      >
        <CTable
          :key="`${block.name}-${index}`"
          :head="item.data.headers"
          :rows="item.data.rows"
          :text-after="item.data.textAfter"
          :text-before="item.data.textBefore"
          :title="item.data.title"
        >
          <template
            #cell="slotData"
          >
            <slot
              name="tableCell"
              :cell="slotData.cell"
              :row="slotData.row"
              :col="slotData.col"
              :options="item.options"
            />
          </template>
        </CTable>
      </template>
    </template>
  </div>
</template>

<script>
import CMarkedList from '@/module/common/components/Data/CMarkedList.vue';
import CTable from '@/module/common/components/Data/CTable.vue';
import DSHeader from '@/module/design-system/components/Text/DSHeader.vue';
import DSParagraph from '@/module/design-system/components/Text/DSParagraph.vue';

export default {
  name: 'CTextBlock',
  components: {
    CMarkedList,
    CTable,
    DSHeader,
    DSParagraph,
  },
  props: {
    block: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.c-text-block {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__paragraph {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
