import IButtonConfig from '@/module/common/models/helpers/getButton/IButtonConfig';
import IButtonWithIconConfig from '@/module/common/models/helpers/getButton/IButtonWithIconConfig';
import IDSButton from '@/module/design-system/models/UI/DSButton/IDSButton';
import IDSIcon from '@/module/design-system/components/models/IDSIcon';
import TButtonHandler from '@/module/common/models/helpers/getButton/TButtonHandler';

function getButton(
  config: IDSButton,
  handler: TButtonHandler,
  visible = true,
): IButtonConfig {
  return {
    config,
    handler,
    visible,
  };
}

function getButtonWithIcon(
  config: IDSButton,
  handler: TButtonHandler,
  icon: IDSIcon,
  visible = true,
): IButtonWithIconConfig {
  return {
    ...getButton(config, handler),
    icon,
    visible,
  };
}

function isButtonWithIcon(buttonConfig: IButtonConfig | IButtonWithIconConfig): buttonConfig is IButtonWithIconConfig {
  return 'icon' in buttonConfig;
}

export default getButton;

export {
  getButtonWithIcon,
  isButtonWithIcon,
};
