<template>
  <div class="promotion-product">
    <p class="promotion-product__title">
      <router-link
        class="promotion-product__name"
        :to="{ name: 'ProductCard', params: { id: product.id } }"
      >
        {{ product.name }}
      </router-link>
      <span v-if="product.discountPercent || !totalPercent"> - {{ product.discountPercent }}%</span>
    </p>
    <CouponComponent
      :text="product.buyLink"
      :active="true"
      type="small"
    />
    <PromotionPricing
      :variants="product.variants"
      :percent="+product.discountPercent || totalPercent"
      :locale="locale"
    />
  </div>
</template>

<script>
import CouponComponent from '@/components/Coupon/index.vue';
import PromotionPricing from '@/components/Pricing/PromotionPricing.vue';

export default {
  name: 'PromotionProduct',
  components: { CouponComponent, PromotionPricing },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    locale: {
      type: String,
      default: 'en-EN',
    },
    totalPercent: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.promotion-product {
  margin-top: 28px;

  &__title {
    font-weight: 500;
    margin-bottom: 8px;
  }

  &__name {
    color: #4397CB;
    text-decoration: none;
  }
}
</style>
