import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import ICamelCaseLocalisationDashboardPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationDashboardPage';
import TWidget, {
  IChartData,
  IChartLabel,
  IChartWidgetData,
  IWidgetChart,
  IWidgetConfigFrontendModel,
  IWidgetMetric,
} from '@/module/dashboard/models/IWidgetConfig';
import IDashboardFilter from '@/module/dashboard/models/IDashboardFilter';
import EWidgetName from '@/module/dashboard/models/EWidgetName';
import {
  IWidgetChartDataRequestQuery,
} from '@/module/dashboard/models/IWidgetDataRequestQuery';
import moment from 'moment';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import cloneImmutable from '@/module/common/utils/data/cloneImmutable';
import IChartDataResponse from '@/module/dashboard/models/ChartDataResponse/IChartDataResponse';
import IRESTApi from '@/module/common/service/RESTApi/contract/IRESTApi';
import {
  updateWidgets,
} from '@/module/dashboard/view/DashboardOverviewPage/DashboardOverviewPage.func';
import TTSApi from '@/module/common/tsApi/TTSApi';

function initChartWidget(
  widgetConfig: IWidgetConfigFrontendModel,
): IWidgetChart {
  const configMap = {
    [EWidgetName.TRANSACTIONS]: {
      yAxisFormat: 'amount',
      maxYAxisLines: 5,
    },
    [EWidgetName.GROSS_REVENUE]: {
      yAxisFormat: 'amount',
      maxYAxisLines: 5,
    },
    [EWidgetName.REFUNDS]: {
      yAxisFormat: 'amount',
      maxYAxisLines: 5,
    },
    [EWidgetName.AVERAGE_TRANSACTION_AMOUNT]: {
      yAxisFormat: 'amount',
      maxYAxisLines: 5,
    },
  };

  return {
    config: {
      ...widgetConfig,
      yAxisFormat: configMap[widgetConfig.name].yAxisFormat,
      maxYAxisLines: configMap[widgetConfig.name].maxYAxisLines,
      initial: {
        color: '#8E84F7',
        name: widgetConfig.title ?? '',
      },
      compare: {
        color: '#8E84F766',
        name: widgetConfig.title ?? '',
      },
    },
    data: {
      initial: {
        items: [],
        labels: [],
      },
      compare: {
        items: [],
        labels: [],
      },
      metrics: [],
    },
  };
}

function getCompareLabels(initialMetrics, comparisonMetrics): IChartLabel[] {
  if (initialMetrics.length < comparisonMetrics.length) {
    return comparisonMetrics.map((item, i) => {
      const newItem: IChartLabel = {
        date: item.date,
        isDateShown: true,
      };

      if (i > initialMetrics.length - 1 || !initialMetrics.rows?.[i]) {
        newItem.isDateShown = false;
      } else {
        newItem.secondDate = item.date;
        newItem.date = initialMetrics.rows[i].date;
      }
      return newItem;
    });
  }
  return initialMetrics.map((item, i) => {
    const newItem: IChartLabel = {
      date: item.date,
      isDateShown: true,
    };

    if (i < comparisonMetrics.length) {
      newItem.secondDate = comparisonMetrics[i].date;
    }
    return newItem;
  });
}

function getPercentage(initial, comparison) {
  if (initial === 0 && comparison === 0) return 0;
  if (comparison === 0) return 100;

  return Math.round((initial / comparison - 1) * 100 * 100) / 100;
}

function extractDataByMetric(initialData, compareData, isComparison, metric) {
  const data: IChartWidgetData<IChartData> = {
    initial: {} as IChartData,
    metrics: [],
  };

  const currentMetric = {} as IWidgetMetric;
  if (initialData.rows) {
    data.initial = {
      items: initialData.rows.map((row) => row[metric] ?? 0),
      labels: initialData.rows.map((row) => ({
        date: row.date,
        isDateShown: true,
      })),
    };
    currentMetric.title = initialData.totals?.[0]?.[metric]?.value ?? 0;
  }

  if (compareData?.rows && isComparison) {
    data.compare = {
      items: compareData.rows.map((row) => row[metric] ?? 0),
      labels: getCompareLabels(initialData.rows, compareData.rows),
    };

    const compareTotals = compareData?.totals?.[0]?.[metric]?.value ?? 0;
    currentMetric.percent = getPercentage(currentMetric.title, compareTotals);
  }

  data.metrics.push(currentMetric);

  return data;
}

function mapData(
  widgets: TWidget[],
  initialData: IChartDataResponse,
  compareData?: IChartDataResponse,
): TWidget[] {
  const metricsMap = {
    [EWidgetName.TRANSACTIONS]: 'orders',
    [EWidgetName.GROSS_REVENUE]: 'revenue',
    [EWidgetName.REFUNDS]: 'refunds',
    [EWidgetName.AVERAGE_TRANSACTION_AMOUNT]: 'average_orders',
  };

  widgets = widgets.reduce((mappedWidgets, widget) => {
    const metric = metricsMap[widget.config?.name];
    const isComparison = !!compareData;

    if (metric) {
      const data = extractDataByMetric(initialData, compareData ?? null, isComparison, metric);

      mappedWidgets = updateWidgets(
        mappedWidgets,
        data,
        isComparison,
        widget.config?.name,
      );
    }

    return mappedWidgets;
  }, widgets);

  return cloneImmutable(widgets);
}

async function updateChartWidgetsData(
  serviceContainer: IServiceContainer,
  localisation: ICamelCaseLocalisationDashboardPage,
  widgets: TWidget[],
  filter: IDashboardFilter,
): Promise<TWidget[]> {
  const api = serviceContainer.resolve<TTSApi>(EServiceContainerInstances.TS_API);
  const metricsAPI = api.metrics as IRESTApi<IChartDataResponse>;

  const initialQuery: IWidgetChartDataRequestQuery = {
    'date[dateFrom]': moment(filter.date.dateFrom).format('YYYY-MM-DD 00:00:00'),
    'date[dateTo]': moment(filter.date.dateTo).format('YYYY-MM-DD 23:59:59'),
    dimension: filter.dimension,
  };
  const initialResponse = await metricsAPI.get('', { params: initialQuery });
  const initialData = initialResponse.data;

  if (filter.comparisonSelectionMode !== 'NC') {
    const compareQuery: IWidgetChartDataRequestQuery = {
      'date[dateFrom]': moment(filter.comparisonDate.dateFrom).format('YYYY-MM-DD 00:00:00'),
      'date[dateTo]': moment(filter.comparisonDate.dateTo).format('YYYY-MM-DD 23:59:59'),
      dimension: filter.dimension,
    };
    const compareResponse = await metricsAPI.get('', { params: compareQuery });
    const compareData = compareResponse.data;

    return mapData(widgets, initialData, compareData);
  }

  return mapData(widgets, initialData);
}

export default initChartWidget;

export {
  updateChartWidgetsData,
};
