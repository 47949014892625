import { Store } from 'vuex';
import EFieldName from '@/module/product/view/FulfillmentFormPage/model/enums/EFieldName';
import EFormFieldType from '@/module/design-system/components/models/FormComponent/EFormFieldType';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IFormConfigHandler from '@/module/product/view/FulfillmentFormPage/model/contract/handler/IFormConfigHandler';
import IFormConfigBlock from '@/module/design-system/components/models/FormComponent/IFormConfigBlock';
import EHTTPMethod from '@/module/common/models/EHTTPMethod';
import { EConditionType } from '@/module/common/helpers/checkCondition';
import ESize from '@/module/design-system/components/models/ESize';
import EDSButtonVariant from '@/module/design-system/models/UI/DSButton/EDSButtonVariant';
import EIcon from '@/module/design-system/models/EIcon';
import EIconColorName from '@/module/design-system/components/models/EIconColorName';
import IState from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/IState';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import ICInputWithSelectListValueConfig
  from '@/module/common/models/UI/CInputWithSelectList/ICInputWithSelectListValueConfig';
import ELicenseSourceName from '@/module/product/view/FulfillmentFormPage/model/enums/ELicenseSourceName';
import EScenario from '@/module/product/view/FulfillmentFormPage/model/enums/EScenario';
import IHandlerService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IHandlerService';
import ILocalServiceContainer from '@/module/product/view/FulfillmentFormPage/model/ILocalServiceContainer';
import ELocalServiceFulfillment from '@/module/product/view/FulfillmentFormPage/model/enums/ELocalServiceFulfillment';
import ILicenseAmountService
  from '@/module/product/view/FulfillmentFormPage/model/contract/service/ILicenseAmountService';
import IScenarioService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IScenarioService';
import EColorScheme from '@/module/design-system/components/models/EColorScheme';
import IHTTPMethodService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IHTTPMethodService';

class FormConfigHandler implements IFormConfigHandler {
  private readonly state: IState;

  private readonly serviceContainer: IServiceContainer;

  private readonly localServiceContainer: ILocalServiceContainer;

  private readonly formConfigMap: {
    [key in EScenario]: IFormConfigBlock<EFieldName, undefined>[];
  } = {
      [EScenario.CREATE_GENERATOR]: [],
      [EScenario.SEND_EMAIL_TO_MANAGER]: [],
      [EScenario.MOCK_GENERATOR]: [],
      [EScenario.DEFAULT]: [],
      [EScenario.TEST_GENERATOR]: [],
    };

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    localServiceContainer: ILocalServiceContainer,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.localServiceContainer = localServiceContainer;
  }

  getConfig = (licenseSourceName: ELicenseSourceName): IFormConfigBlock<EFieldName, undefined>[] => {
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const licenseAmountService = this.localServiceContainer
      .resolve<ILicenseAmountService>(ELocalServiceFulfillment.LICENSE_AMOUNT_SERVICE);
    const scenarioSelectService = this.localServiceContainer
      .resolve<IScenarioService>(ELocalServiceFulfillment.SCENARIO_SERVICE);
    const httpMethodService = this.localServiceContainer
      .resolve<IHTTPMethodService>(ELocalServiceFulfillment.HTTP_METHOD_SERVICE);
    const licenseVariantHandler = handlerService.getLicenseVariantHandler();
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const localisationRegistry = store.getters.getLocalisationRegistry;
    const formLocalisation = localisationRegistry.fulfillment.form;

    const licenseSourceList = licenseVariantHandler.getLicenseSourceList();

    const paramsConfig = this.getParamsConfig();

    const configMap = {
      [EScenario.CREATE_GENERATOR]: [
        {
          id: 1,
          header: formLocalisation.form.title,
          items: [
            {
              id: 1,
              type: EFormFieldType.SELECT,
              fieldName: EFieldName.LICENSE_SOURCE,
              fieldBlock: undefined,
              label: formLocalisation.form.licenseSource.title,
              placeholder: formLocalisation.form.licenseSource.placeholder,
              tooltip: formLocalisation.form.licenseSource.tooltip,
              select: {
                idField: 'id',
                titleField: 'title',
                list: licenseSourceList,
              },
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            {
              id: 2,
              type: EFormFieldType.INPUT,
              fieldName: EFieldName.URL,
              fieldBlock: undefined,
              label: formLocalisation.form.url.title,
              placeholder: formLocalisation.form.url.placeholder,
              tooltip: formLocalisation.form.url.tooltip,
              conditionsList: [
                {
                  type: EConditionType.NOT_EMPTY,
                  field: EFieldName.LICENSE_SOURCE,
                },
              ],
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            {
              id: 3,
              type: EFormFieldType.SELECT,
              fieldName: EFieldName.METHOD,
              fieldBlock: undefined,
              label: formLocalisation.form.method.title,
              default: httpMethodService.get(EHTTPMethod.GET),
              select: {
                idField: 'id',
                titleField: 'title',
                list: httpMethodService.list(),
              },
              conditionsList: [
                {
                  type: EConditionType.NOT_EMPTY,
                  field: EFieldName.LICENSE_SOURCE,
                },
              ],
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            {
              id: 4,
              type: EFormFieldType.INPUT,
              fieldName: EFieldName.SECRET_KEY,
              fieldBlock: undefined,
              label: formLocalisation.form.secretKey.title,
              placeholder: formLocalisation.form.secretKey.placeholder,
              tooltip: formLocalisation.form.secretKey.tooltip,
              conditionsList: [
                {
                  type: EConditionType.NOT_EMPTY,
                  field: EFieldName.LICENSE_SOURCE,
                },
              ],
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            {
              id: 5,
              type: EFormFieldType.BUTTON_GROUP,
              fieldName: EFieldName.LICENSE_AMOUNT,
              fieldBlock: undefined,
              variant: 'spaced',
              label: formLocalisation.form.licenseAmount.title,
              tooltip: formLocalisation.form.licenseAmount.tooltip,
              buttonList: licenseAmountService.list(),
              default: licenseAmountService.get(true),
              conditionsList: [
                {
                  type: EConditionType.NOT_EMPTY,
                  field: EFieldName.LICENSE_SOURCE,
                },
              ],
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            {
              id: 6,
              type: EFormFieldType.SWITCHER,
              fieldName: EFieldName.SEND_LICENSE,
              fieldBlock: undefined,
              label: formLocalisation.form.sendLicense.title,
              switcher: {
                revert: undefined,
              },
              conditionsList: [
                {
                  type: EConditionType.NOT_EMPTY,
                  field: EFieldName.LICENSE_SOURCE,
                },
              ],
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            {
              id: 7,
              type: EFormFieldType.INFO_BLOCK,
              fieldName: EFieldName.SEND_LICENSE_ALERT,
              fieldBlock: undefined,
              config: {
                text: formLocalisation.form.sendLicense.description,
                allowHtml: true,
                disableIcon: true,
                variant: EColorScheme.PRIMARY,
              },
              conditionsList: [
                {
                  type: EConditionType.NOT_EMPTY,
                  field: EFieldName.LICENSE_SOURCE,
                },
                {
                  type: EConditionType.EQUALS,
                  field: EFieldName.SEND_LICENSE,
                  value: false,
                },
              ],
            },
            {
              id: 8,
              type: EFormFieldType.TEXT_AREA,
              fieldName: EFieldName.MESSAGE_TO_USER,
              fieldBlock: undefined,
              label: formLocalisation.form.messageToUser.title,
              placeholder: formLocalisation.form.messageToUser.placeholder,
              tooltip: formLocalisation.form.messageToUser.tooltip,
              config: {
                maxRowsCount: 25,
              },
              description: {
                default: formLocalisation.form.messageToUser.description,
              },
              conditionsList: [
                {
                  type: EConditionType.NOT_EMPTY,
                  field: EFieldName.LICENSE_SOURCE,
                },
                {
                  type: EConditionType.EQUALS,
                  field: EFieldName.SEND_LICENSE,
                  value: true,
                },
              ],
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
          ],
        },
        {
          id: 2,
          conditionsList: [
            {
              type: EConditionType.NOT_EMPTY,
              field: EFieldName.LICENSE_SOURCE,
            },
          ],
          header: formLocalisation.form.parameters.title,
          items: [
            {
              id: 9,
              type: EFormFieldType.INPUT_WITH_SELECT_LIST,
              fieldName: EFieldName.PARAMETERS,
              fieldBlock: undefined,
              config: {
                ...paramsConfig,
                buttonConfig: {
                  config: {
                    variant: EDSButtonVariant.TRETIARY,
                    text: formLocalisation.form.parameters.addButton,
                  },
                  icon: {
                    icon: EIcon.PLUS,
                    color: EIconColorName.LINKED,
                    size: ESize.M,
                  },
                },
                itemLabel: formLocalisation.form.parameters.label,
              },
              conditionsList: [
                {
                  type: EConditionType.NOT_EMPTY,
                  field: EFieldName.LICENSE_SOURCE,
                },
              ],
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
          ],
        },
      ],
      [EScenario.SEND_EMAIL_TO_MANAGER]: [
        {
          header: formLocalisation.form.title,
          items: [
            {
              id: 1,
              type: EFormFieldType.SELECT,
              fieldName: EFieldName.LICENSE_SOURCE,
              fieldBlock: undefined,
              label: formLocalisation.form.licenseSource.title,
              placeholder: formLocalisation.form.licenseSource.placeholder,
              tooltip: formLocalisation.form.licenseSource.tooltip,
              select: {
                idField: 'id',
                titleField: 'title',
                list: licenseSourceList,
              },
              conditionsList: [
                {
                  type: EConditionType.NOT_EMPTY,
                  field: EFieldName.LICENSE_SOURCE,
                },
              ],
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            {
              id: 2,
              type: EFormFieldType.TEXT_AREA,
              fieldName: EFieldName.MESSAGE_TO_MANAGER,
              fieldBlock: undefined,
              label: formLocalisation.form.messageToManager.title,
              placeholder: formLocalisation.form.messageToManager.placeholder,
              tooltip: formLocalisation.form.messageToManager.tooltip,
              config: {
                maxRowsCount: 25,
              },
              description: {
                default: formLocalisation.form.messageToManager.description,
              },
              conditionsList: [
                {
                  type: EConditionType.NOT_EMPTY,
                  field: EFieldName.LICENSE_SOURCE,
                },
              ],
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
          ],
        },
      ],
      [EScenario.MOCK_GENERATOR]: [
        {
          id: 5,
          header: formLocalisation.form.title,
          items: [
            {
              id: 1,
              type: EFormFieldType.SELECT,
              fieldName: EFieldName.LICENSE_SOURCE,
              fieldBlock: undefined,
              label: formLocalisation.form.licenseSource.title,
              placeholder: formLocalisation.form.licenseSource.placeholder,
              tooltip: formLocalisation.form.licenseSource.tooltip,
              select: {
                idField: 'id',
                titleField: 'title',
                list: licenseSourceList,
              },
              conditionsList: [
                {
                  type: EConditionType.NOT_EMPTY,
                  field: EFieldName.LICENSE_SOURCE,
                },
              ],
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
          ],
        },
      ],
      [EScenario.DEFAULT]: [
        {
          header: formLocalisation.form.title,
          items: [
            {
              id: 1,
              type: EFormFieldType.SELECT,
              fieldName: EFieldName.LICENSE_SOURCE,
              fieldBlock: undefined,
              label: formLocalisation.form.licenseSource.title,
              placeholder: formLocalisation.form.licenseSource.placeholder,
              tooltip: formLocalisation.form.licenseSource.tooltip,
              select: {
                idField: 'id',
                titleField: 'title',
                list: licenseSourceList,
              },
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
          ],
        },
      ],
    };

    const scenario = scenarioSelectService.getSaveScenarioName(licenseSourceName);

    return (this.formConfigMap[scenario] ?? []).length > 0 ? this.formConfigMap[scenario] : configMap[scenario];
  };

  store = (
    licenseSourceName: ELicenseSourceName,
    formConfig: IFormConfigBlock<EFieldName, undefined>[],
  ): void => {
    const scenarioSelectService = this.localServiceContainer
      .resolve<IScenarioService>(ELocalServiceFulfillment.SCENARIO_SERVICE);
    const scenario = scenarioSelectService.getSaveScenarioName(licenseSourceName);

    this.formConfigMap[scenario] = formConfig;
  };

  getParamsConfig = (): ICInputWithSelectListValueConfig => {
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const localisationRegistry = store.getters.getLocalisationRegistry;
    const formLocalisation = localisationRegistry.fulfillment.form;

    return {
      iconConfig: {
        icon: EIcon.TRASH,
        color: EIconColorName.DISABLED,
        size: ESize.M,
      },
      selectConfig: {
        status: {
          code: EValidationFieldStatusCode.DEFAULT,
        },
        list: this.state.lists.params,
        idField: 'key',
        titleField: 'value',
        placeholder: formLocalisation.form.parameters.selectPlaceholder,
      },
      inputConfig: {
        placeholder: formLocalisation.form.parameters.placeholder,
        inputDelay: 300,
        status: {
          code: EValidationFieldStatusCode.DEFAULT,
        },
      },
    };
  };
}

export default FormConfigHandler;
