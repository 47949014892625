import IException from '@/module/common/service/Exception/IException';
import EExceptionLevel from '@/module/common/service/Exception/EExceptionLevel';

class WarningException extends Error implements IException {
  constructor(message?: string) {
    super(message ?? 'Warning!');
  }

  getType(): EExceptionLevel {
    return EExceptionLevel.WARNING;
  }
}

export default WarningException;
