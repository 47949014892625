import {
  IWidgetPerformanceDataRequestQuery,
} from '@/module/dashboard/models/IWidgetDataRequestQuery';
import moment from 'moment';
import TWidget, {
  IPerformanceTotals,
  ITableData,
  ITableWidgetData,
  IWidgetConfigFrontendModel,
  IWidgetMetric,
  IWidgetTable,
} from '@/module/dashboard/models/IWidgetConfig';
import {
  updateWidgets,
} from '@/module/dashboard/view/DashboardOverviewPage/DashboardOverviewPage.func';
import EWidgetName from '@/module/dashboard/models/EWidgetName';
import ETextVariants from '@/module/common/models/ETextVariants';
import ICamelCaseLocalisationDashboardPage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationDashboardPage';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IDashboardFilter from '@/module/dashboard/models/IDashboardFilter';
import TTSApi from '@/module/common/tsApi/TTSApi';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import IRESTApi from '@/module/common/service/RESTApi/contract/IRESTApi';
import TPerformanceDataResponse from '@/module/dashboard/models/PerformanceDataResponse/TPerformanceDataResponse';
import IUtils from '@/plugins/utils/IUtils';
import EPerformanceTableColumn from '@/module/dashboard/models/EPerformanceTableColumn';
import EWidgetDataFormat from '@/module/dashboard/models/EWidgetDataFormat';
import EWidgetPerformanceTableRowType from '@/module/dashboard/models/EWidgetPerformanceTableRowType';

const totalRowMarker = '% change';

function changePeriod(period, serviceContainer) {
  const parts = period.split(' - ');

  const utils = serviceContainer.resolve('utils');

  return parts
    .map((date) => {
      const dateValue = utils.date.normalizeDate(date, 'DD.MM.YYYY');

      return utils.date.formatSkipCurrentYearWithoutWeekDay(dateValue);
    })
    .join(' - ');
}

function buildComparisonFirstRow(rowData, row) {
  const emptyColumnsCount = 4;

  rowData.push({
    name: EPerformanceTableColumn.CURRENCY_NAME,
    value: row.row.currency_name ?? null,
    format: EWidgetDataFormat.CURRENCY,
    textVariant: ETextVariants.MEDIUM,
  });

  for (let i = 1; i <= emptyColumnsCount; i += 1) {
    rowData.push({
      name: EPerformanceTableColumn.EMPTY,
      value: null,
      format: null,
      textVariant: ETextVariants.MEDIUM,
    });
  }
}

function buildComparisonLastRow(rowData, row) {
  rowData.push({
    name: EPerformanceTableColumn.PERIOD,
    value: row.row.period ?? null,
    format: EWidgetDataFormat.PERIOD,
    textVariant: ETextVariants.MEDIUM,
  });
  rowData.push({
    name: EPerformanceTableColumn.PERFORMANCE_SUM_END_USER_PRICE,
    value: row.row.performance_sum_end_user_price ?? null,
    format: EWidgetDataFormat.PERCENTAGE,
    textVariant: ETextVariants.SMALL_MEDIUM,
  });
  rowData.push({
    name: EPerformanceTableColumn.PERFORMANCE_SUM_NET_PRICE,
    value: row.row.performance_sum_net_price ?? null,
    format: EWidgetDataFormat.PERCENTAGE,
    textVariant: ETextVariants.SMALL_MEDIUM,
  });
  rowData.push({
    name: EPerformanceTableColumn.ORDERS,
    value: row.row.orders ?? null,
    format: EWidgetDataFormat.PERCENTAGE,
    textVariant: ETextVariants.SMALL_MEDIUM,
  });
  rowData.push({
    name: EPerformanceTableColumn.PERFORMANCE_AVERAGE_ORDERS,
    value: row.row.performance_average_orders ?? null,
    format: EWidgetDataFormat.PERCENTAGE,
    textVariant: ETextVariants.SMALL_MEDIUM,
  });
}

function buildDataRow(rowData, row, isComparison, serviceContainer) {
  if (isComparison) {
    rowData.push({
      name: EPerformanceTableColumn.PERIOD,
      value: row.row.period ? changePeriod(row.row.period, serviceContainer) : null,
      format: EWidgetDataFormat.PERIOD,
      textVariant: ETextVariants.REGULAR,
    });
  } else {
    rowData.push({
      name: EPerformanceTableColumn.CURRENCY_NAME,
      value: row.row.currency_name ?? null,
      format: EWidgetDataFormat.CURRENCY,
      textVariant: ETextVariants.MEDIUM,
    });
  }

  rowData.push({
    name: EPerformanceTableColumn.PERFORMANCE_SUM_END_USER_PRICE,
    value: row.row.performance_sum_end_user_price ?? null,
    format: EWidgetDataFormat.MONEY,
    currency: row.row.currency_name,
    textVariant: ETextVariants.SMALL,
  });
  rowData.push({
    name: EPerformanceTableColumn.PERFORMANCE_SUM_NET_PRICE,
    value: row.row.performance_sum_net_price ?? null,
    format: EWidgetDataFormat.MONEY,
    currency: row.row.currency_name,
    textVariant: ETextVariants.SMALL,
  });
  rowData.push({
    name: EPerformanceTableColumn.ORDERS,
    value: row.row.orders ?? null,
    format: EWidgetDataFormat.QUANTITY,
    textVariant: ETextVariants.SMALL,
  });
  rowData.push({
    name: EPerformanceTableColumn.PERFORMANCE_AVERAGE_ORDERS,
    value: row.row.performance_average_orders ?? null,
    format: EWidgetDataFormat.MONEY,
    currency: row.row.currency_name,
    textVariant: ETextVariants.SMALL,
  });
}

function mapComparisonData(data) {
  let lastKey = 0;
  return data
    .reduce((blocks, row) => {
      if (!blocks[lastKey]) {
        blocks[lastKey] = [];
      }

      if (blocks[lastKey].length === 0) {
        blocks[lastKey].push({
          rowType: EWidgetPerformanceTableRowType.FIRST,
          row,
        });
      }

      if (row.period.toLowerCase() === totalRowMarker) {
        blocks[lastKey].push({
          rowType: EWidgetPerformanceTableRowType.LAST,
          row,
        });

        lastKey += 1;
        blocks[lastKey] = [];
      } else {
        blocks[lastKey].push({
          rowType: EWidgetPerformanceTableRowType.ROW,
          row,
        });
      }

      return blocks;
    }, [])
    .filter((block) => block.length > 0);
}

function mapNoComparisonData(data) {
  return data.reduce((blocks, row) => {
    if (!blocks[0]) {
      blocks[0] = [];
    }

    blocks[0].push({
      rowType: EWidgetPerformanceTableRowType.ROW,
      row,
    });

    return blocks;
  }, []);
}

function mapPerformanceBlocks(blocks, isComparison, serviceContainer) {
  let items = blocks.reduce((mapped, subBlock) => {
    const mappedSubBlock = subBlock.map((row) => {
      const rowData = [];

      if (isComparison && row.rowType !== EWidgetPerformanceTableRowType.ROW) {
        if (row.rowType === EWidgetPerformanceTableRowType.FIRST) {
          buildComparisonFirstRow(rowData, row);
        } else if (row.rowType === EWidgetPerformanceTableRowType.LAST) {
          buildComparisonLastRow(rowData, row);
        }
      } else {
        buildDataRow(rowData, row, isComparison, serviceContainer);
      }

      return rowData;
    });

    mapped.push(mappedSubBlock);
    return mapped;
  }, []);

  if (!isComparison && items[0]) {
    items[0] = items[0].sort((item1, item2) => {
      const item1Revenue = item1.find(
        (metric) => metric.name === EPerformanceTableColumn.PERFORMANCE_SUM_END_USER_PRICE,
      );
      const item2Revenue = item2.find(
        (metric) => metric.name === EPerformanceTableColumn.PERFORMANCE_SUM_END_USER_PRICE,
      );

      return item2Revenue.value - item1Revenue.value;
    });
  } else if (isComparison && items.length > 1) {
    items = items.sort((item1, item2) => {
      const item1Revenue = item1[1].find(
        (metric) => metric.name === EPerformanceTableColumn.PERFORMANCE_SUM_END_USER_PRICE,
      );
      const item2Revenue = item2[1].find(
        (metric) => metric.name === EPerformanceTableColumn.PERFORMANCE_SUM_END_USER_PRICE,
      );

      return item2Revenue.value - item1Revenue.value;
    });
  }

  return items;
}

function splitPerformanceDataToBlocks(data, isComparison) {
  return isComparison ? mapComparisonData(data) : mapNoComparisonData(data);
}

async function updatePerformanceWidgetData(
  serviceContainer: IServiceContainer,
  localisation: ICamelCaseLocalisationDashboardPage,
  widgets: TWidget[],
  filter: IDashboardFilter,
): Promise<TWidget[]> {
  const query: IWidgetPerformanceDataRequestQuery = {
    dimension: 'currency',
    'date[dateFrom]': moment(filter.date.dateFrom).format('YYYY-MM-DD 00:00:00'),
    'date[dateTo]': moment(filter.date.dateTo).format('YYYY-MM-DD 23:59:59'),
  };

  const isComparison = filter.comparisonSelectionMode !== 'NC';
  if (isComparison) {
    query['compare[dateFrom]'] = moment(filter.comparisonDate.dateFrom).format('YYYY-MM-DD 00:00:00');
    query['compare[dateTo]'] = moment(filter.comparisonDate.dateTo).format('YYYY-MM-DD 23:59:59');
  }

  const utils = serviceContainer.resolve<IUtils>(EServiceContainerInstances.UTILS);

  const api = serviceContainer.resolve<TTSApi>(EServiceContainerInstances.TS_API);
  const performanceAPI = api.performance as IRESTApi<TPerformanceDataResponse>;

  const response = await performanceAPI.get('', { params: query });

  const rows = response.data?.rows ?? [];
  const responseTotals = response.data?.totals ?? [];
  const totals = responseTotals.find(
    (item) => item.period.toLowerCase() === totalRowMarker,
  ) ?? {} as IPerformanceTotals;

  const metrics: IWidgetMetric[] = [] as IWidgetMetric[];

  if (!utils.data.isEmpty(totals)) {
    metrics.push(
      {
        percent: totals.performance_sum_end_user_price,
        title: localisation.widgets.performance.metrics.endUserPrice,
      },
      {
        percent: totals.performance_sum_net_price,
        title: localisation.widgets.performance.metrics.netPrice,
      },
      {
        percent: totals.orders,
        title: localisation.widgets.performance.metrics.orders,
      },
      {
        percent: totals.performance_average_orders,
        title: localisation.widgets.performance.metrics.averageOrderValue,
      },
    );
  }

  const blocks = splitPerformanceDataToBlocks(rows, isComparison);
  const items = mapPerformanceBlocks(blocks, isComparison, serviceContainer);

  const data: ITableWidgetData<ITableData> = {
    initial: {
      items,
      totals,
    },
    metrics,
  };

  return updateWidgets(
    widgets,
    data,
    isComparison,
    EWidgetName.PERFORMANCE,
  );
}

function initPerformanceWidget(
  localisation: ICamelCaseLocalisationDashboardPage,
  widgetConfig: IWidgetConfigFrontendModel,
): IWidgetTable {
  return {
    config: {
      ...widgetConfig,
      headers: [
        {
          name: EPerformanceTableColumn.CURRENCY_NAME,
          format: EWidgetDataFormat.CURRENCY,
          title: localisation.widgets.performance.table.currencyName.title,
        },
        {
          name: EPerformanceTableColumn.PERFORMANCE_SUM_END_USER_PRICE,
          format: EWidgetDataFormat.MONEY,
          title: localisation.widgets.performance.table.endUserPrice.title,
          tooltip: localisation.widgets.performance.table.endUserPrice.tooltip,
        },
        {
          name: EPerformanceTableColumn.PERFORMANCE_SUM_NET_PRICE,
          format: EWidgetDataFormat.MONEY,
          title: localisation.widgets.performance.table.netPrice.title,
          tooltip: localisation.widgets.performance.table.netPrice.tooltip,
        },
        {
          name: EPerformanceTableColumn.ORDERS,
          format: EWidgetDataFormat.QUANTITY,
          title: localisation.widgets.performance.table.orders.title,
          tooltip: localisation.widgets.performance.table.orders.tooltip,
        },
        {
          name: EPerformanceTableColumn.PERFORMANCE_AVERAGE_ORDERS,
          format: EWidgetDataFormat.MONEY,
          title: localisation.widgets.performance.table.averageOrderValue.title,
          tooltip: localisation.widgets.performance.table.averageOrderValue.tooltip,
        },
      ],
    },
    data: {
      initial: {
        items: [],
        totals: {
          currency_name: null,
          orders: 0,
          performance_average_orders: 0,
          performance_sum_end_user_price: 0,
          performance_sum_net_price: 0,
          period: '',
        },
      },
      metrics: [],
    },
  };
}

export default initPerformanceWidget;

export {
  updatePerformanceWidgetData,
};
