<template>
  <div
    v-click-outside="handleKeepClick"
    class="delete-modal"
  >
    <div class="delete-modal__header">
      <header-text
        size="3"
        :text="activeModalData.title"
      />

      <ButtonComponent
        variant="tretiary-negative"
        :icon-button="true"
        :small="true"
        @click="handleKeepClick"
      >
        <DSIcon
          icon="cross"
          color="primary"
        />
      </ButtonComponent>
    </div>
    <div
      v-if="activeModalData.dataToDelete.title"
      class="delete-modal__info"
    >
      <p>{{ activeModalData.dataToDelete.title }}</p>
      <p
        class="secondary"
        :title="'ID: ' + activeModalData.dataToDelete.id + ' •  SKU:' + activeModalData.dataToDelete.sku"
      >
        {{
          $t('modal_window.id')
            .toUpperCase()
        }}: {{ activeModalData.dataToDelete.id }} • {{
          $t('modal_window.sku')
            .toUpperCase()
        }}: {{ activeModalData.dataToDelete.sku || '-' }}
      </p>
    </div>
    <div
      v-if="activeModalData.dataToDelete.url"
      class="delete-modal__info"
    >
      <p>{{ activeModalData.dataToDelete.url }}</p>
    </div>
    <button-group
      class="delete-modal__button-group"
      variant="spaced"
    >
      <ButtonComponent @click="handleKeepClick">
        {{ activeModalData.buttonKeep }}
      </ButtonComponent>
      <ButtonComponent
        variant="secondary-negative"
        @click="activeModalData.handleDeleteClick"
      >
        {{
          activeModalData.buttonDelete
        }}
      </ButtonComponent>
    </button-group>
  </div>
</template>

<script>
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import { mapState, mapActions } from 'vuex';
import HeaderText from './HeaderText.vue';
import ButtonComponent from './Button/ButtonComponent.vue';
import ButtonGroup from './ButtonGroup/ButtonGroup.vue';

export default {
  name: 'DeleteModal',
  components: {
    DSIcon,
    HeaderText,
    ButtonComponent,
    ButtonGroup,
  },
  computed: {
    ...mapState({
      activeModalData: (state) => state.deleteModalStore.activeModalData,
    }),
  },
  methods: {
    ...mapActions({
      handleKeepClick: 'deleteModalStore/handleKeepClick',
    }),
  },
};
</script>

<style lang="scss">
.delete-modal {
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 390px;
  background-color: white;
  padding: 32px;
  border-radius: 20px;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;

    button svg path {
      fill: black;
    }
  }

  &__info {
    margin-bottom: 20px;

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      overflow-wrap: anywhere;
    }

    p:first-child {
      padding-bottom: 8px;
    }

    p.secondary {
      font-weight: 400;
      max-height: 56px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &__button-group {
    column-gap: 20px;

    button {
      width: 100%;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 670px) {
  .delete-modal {
    grid-template-columns: 270px;
    padding: 15px;

    button {
      min-width: 70px;
    }
  }
}
</style>
