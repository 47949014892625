import ICamelCaseLocalisationAccountActivatePage
  from '@/module/common/utils/localisation/initialisator/model/page/ICamelCaseLocalisationAccountActivatePage';
import EFormFieldType from '@/module/design-system/components/models/FormComponent/EFormFieldType';
import { EConditionType } from '@/module/common/helpers/checkCondition';
import buildFieldName from '@/module/common/components/FormComponent/buildFieldName';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';
import IFieldStatus from '@/module/design-system/components/models/FormComponent/IFieldStatus';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import ICamelCaseLocalisationCommon
  from '@/module/common/utils/localisation/initialisator/model/ICamelCaseLocalisationCommon';
import ICurrency from '@/module/merchant/models/ICurrency';
import ECurrency from '@/module/merchant/models/ECurrency';
import EAccountActivateFileType from '@/module/merchant/models/EAccountActivateFileType';
import TFileErrorMessages from '@/module/common/models/UI/FileUpload/TFileErrorMessages';
import EFileErrorType from '@/module/common/models/UI/FileUpload/EFileErrorType';
import ICFileUploadOptions from '@/module/common/models/UI/FileUpload/ICFileUploadOptions';
import getOptions from '@/module/common/components/UI/CFileUpload/getOptions';
import EDSButtonVariant from '@/module/design-system/models/UI/DSButton/EDSButtonVariant';
import EIcon from '@/module/design-system/components/models/EIcon';
import EIconColorName from '@/module/design-system/components/models/EIconColorName';
import EFileMimeTypes from '@/module/design-system/components/models/DSFileUpload/EFileMimeTypes';
import IAccountActivatePageValidateHandler
  from '@/module/merchant/view/AccountActivatePage/Validator/IValidatorHandler';
import EPageTab from '@/module/merchant/view/AccountActivatePage/models/EPageTab';
import IUtilsService from '@/module/common/service/UtilsService/contract/IUtilsService';
import EBusinessRepresentativeCompany
  from '@/module/merchant/view/AccountActivatePage/models/EBusinessRepresentativeCompany';
import ETaxCalculation from '@/module/merchant/view/AccountActivatePage/models/ETaxCalculation';
import EIndividualKey from '@/module/merchant/view/AccountActivatePage/models/EIndividualKey';
import TTypeOFBusiness from '@/module/merchant/view/AccountActivatePage/models/TTypeOFBusiness';
import TTaxCalculationList from '@/module/merchant/view/AccountActivatePage/models/TTaxCalculationList';
import TTaxationSystem from '@/module/merchant/view/AccountActivatePage/models/TTaxationSystem';
import ETaxationSystem from '@/module/merchant/view/AccountActivatePage/models/ETaxationSystem';
import TBusinessRepresentative from '@/module/merchant/view/AccountActivatePage/models/TBusinessRepresentative';
import IFileUploadOptions
  from '@/module/merchant/view/AccountActivatePage/models/IFileUploadOptions';
import TIndividual from '@/module/merchant/view/AccountActivatePage/models/TIndividual';
import IProductTaxCategory from '@/module/merchant/view/AccountActivatePage/models/IProductTaxCategory';
import EProductTaxCategory from '@/module/merchant/view/AccountActivatePage/models/EProductTaxCategory';
import IFormConfigHandler
  from '@/module/merchant/view/AccountActivatePage/Handler/contract/IFormConfigHandler';
import IPageState from '@/module/merchant/view/AccountActivatePage/models/IPageState';
import ETypeOfBusiness from '@/module/merchant/models/BuisnessType/ETypeOfBusiness';
import EBusinessType from '@/module/merchant/models/BuisnessType/EBusinessType';
import ITab from '@/module/merchant/view/AccountActivatePage/models/ITab';
import TFieldStatuses
  from '@/module/merchant/view/AccountActivatePage/models/TFieldStatuses';
import IBusinessType from '@/module/merchant/models/BuisnessType/IBusinessType';
import EIndividual from '@/module/merchant/models/BuisnessType/EIndividual';
import IFormConfig from '@/module/merchant/view/AccountActivatePage/models/IFormConfig';
import EFieldBlock from '@/module/merchant/view/AccountActivatePage/models/EFieldBlock';
import EFieldName from '@/module/merchant/view/AccountActivatePage/models/EFieldName';
import EBusinessRepresentativeIndividualEntrepreneur
  from '@/module/merchant/view/AccountActivatePage/models/EBusinessRepresentativeIndividualEntrepreneur';
import EOrganizationalLegalFormOfBusiness
  from '@/module/merchant/view/AccountActivatePage/models/EOrganizationalLegalFormOfBusiness';
import ICSelectOption from '@/module/common/models/CSelect/ICSelectOption';

class FormConfigHandler implements IFormConfigHandler {
  private readonly state: IPageState;

  private readonly localisationRegistry: ILocalisationRegistry;

  private readonly validateHandler: IAccountActivatePageValidateHandler;

  private readonly serviceContainer: IServiceContainer;

  constructor(
    state: IPageState,
    localisationRegistry: ILocalisationRegistry,
    validateHandler: IAccountActivatePageValidateHandler,
    serviceContainer: IServiceContainer,
  ) {
    this.state = state;
    this.localisationRegistry = localisationRegistry;
    this.validateHandler = validateHandler;
    this.serviceContainer = serviceContainer;
  }

  getTabListConfig = (): IFormConfig[] => {
    const businessInfo = this.localisationRegistry.accountActivate.businessInfo;
    const maxDateOfBirthDate = this.getMaxDateOfBirth();
    const businessTypes = this.getBusinessTypes(this.localisationRegistry.accountActivate);
    const currencyButtonList = this.getCurrencies(this.localisationRegistry.accountActivate);
    const taxCalculationList = this.getTaxCalculationList(this.localisationRegistry.accountActivate);

    const formConfig: IFormConfig[] = [
      {
        id: EPageTab.BUSINESS_INFO,
        title: businessInfo.tab,
        formConfig: [
          {
            header: businessInfo.businessStructure.title,
            subHeader: businessInfo.businessStructure.subtitle,
            items: [
              {
                id: 1,
                type: EFormFieldType.FILTERED_SELECT,
                select: {
                  inputDelay: 0,
                  symbolsForSearch: 1,
                  idField: 'code',
                  titleField: 'name',
                  autoSelectSingleValue: true,
                  list: this.filterCountry(
                    EFieldBlock.BUSINESS_STRUCTURE,
                    EFieldName.COUNTRY,
                  ),
                },
                default: {},
                fieldName: EFieldName.COUNTRY,
                fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
                label: businessInfo.businessStructure.country.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessStructure.country.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_STRUCTURE,
                  EFieldName.COUNTRY,
                ),
              },
              {
                id: 3,
                type: EFormFieldType.BUTTON_GROUP,
                fieldName: EFieldName.BUSINESS_TYPE,
                fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
                label: businessInfo.businessStructure.typeOfBusiness.softline.title,
                summary: {
                  show: true,
                },
                description: {
                  default: businessInfo.businessStructure.typeOfBusiness.softline.description,
                },
                variant: 'spaced',
                buttonList: businessTypes.filter((businessType) => [
                  EBusinessType.COMPANY,
                  EBusinessType.INDIVIDUAL_ENTREPRENEUR,
                ].includes(businessType.id)),
                default: businessTypes.find(
                  (businessType) => businessType.typeOfBusiness.value === ETypeOfBusiness.COMPANY,
                ),
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_STRUCTURE,
                  EFieldName.BUSINESS_TYPE,
                ),
              },
              {
                id: 4,
                type: EFormFieldType.MASKED_INPUT,
                maskOptions: {
                  mask: '000000000000',
                },
                fieldName: EFieldName.TAX_IDENTIFICATION_CODE,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                label: businessInfo.businessDetails.taxIdentificationCode.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessDetails.taxIdentificationCode.placeholder.individual,
                description: {
                  default: businessInfo.businessDetails.taxIdentificationCode.description,
                },
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.TAX_IDENTIFICATION_CODE,
                ),
                conditionsList: [
                  {
                    type: EConditionType.NOT_EQUALS,
                    field: buildFieldName<EFieldName, EFieldBlock>(
                      EFieldName.BUSINESS_TYPE,
                      EFieldBlock.BUSINESS_STRUCTURE,
                      'id',
                    ),
                    value: EBusinessType.COMPANY,
                  },
                ],
              },
              {
                id: 5,
                type: EFormFieldType.MASKED_INPUT,
                maskOptions: {
                  mask: '0000000000',
                },
                fieldName: EFieldName.TAX_IDENTIFICATION_CODE,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                label: businessInfo.businessDetails.taxIdentificationCode.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessDetails.taxIdentificationCode.placeholder.company,
                description: {
                  default: businessInfo.businessDetails.taxIdentificationCode.description,
                },
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.TAX_IDENTIFICATION_CODE,
                ),
                conditionsList: [
                  {
                    type: EConditionType.EQUALS,
                    field: buildFieldName<EFieldName, EFieldBlock>(
                      EFieldName.BUSINESS_TYPE,
                      EFieldBlock.BUSINESS_STRUCTURE,
                      'id',
                    ),
                    value: EBusinessType.COMPANY,
                  },
                ],
              },
              {
                id: 6,
                type: EFormFieldType.MASKED_INPUT,
                maskOptions: {
                  mask: '000000000',
                },
                fieldName: EFieldName.KPP,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                label: businessInfo.businessDetails.kpp.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessDetails.kpp.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.KPP,
                ),
                conditionsList: [
                  {
                    type: EConditionType.EQUALS,
                    field: buildFieldName<EFieldName, EFieldBlock>(
                      EFieldName.BUSINESS_TYPE,
                      EFieldBlock.BUSINESS_STRUCTURE,
                      'id',
                    ),
                    value: EBusinessType.COMPANY,
                  },
                ],
              },
              {
                id: 7,
                type: EFormFieldType.MASKED_INPUT,
                maskOptions: {
                  mask: '0000000000000',
                },
                fieldName: EFieldName.OGRN,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                label: businessInfo.businessDetails.ogrn.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessDetails.ogrn.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.OGRN,
                ),
                conditionsList: [
                  {
                    type: EConditionType.EQUALS,
                    field: buildFieldName<EFieldName, EFieldBlock>(
                      EFieldName.BUSINESS_TYPE,
                      EFieldBlock.BUSINESS_STRUCTURE,
                      'id',
                    ),
                    value: EBusinessType.COMPANY,
                  },
                ],
              },
              {
                id: 8,
                type: EFormFieldType.SELECT,
                fieldName: EFieldName.TAXATION_SYSTEM,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                placeholder: this.localisationRegistry
                  .accountActivate.businessInfo.businessDetails.taxationSystem.notSelected,
                select: {
                  idField: 'id',
                  titleField: 'title',
                  list: Object.values(this.getTaxationSystemList(this.localisationRegistry.accountActivate)),
                },
                default: {},
                label: businessInfo.businessDetails.taxationSystem.title,
                summary: {
                  show: true,
                },
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.TAXATION_SYSTEM,
                ),
                conditionsList: [
                  {
                    type: EConditionType.OR,
                    conditionsList: [
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.COMPANY,
                      },
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.INDIVIDUAL_ENTREPRENEUR,
                      },
                    ],
                  },
                ],
              },
              {
                id: 9,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.LEGAL_ADDRESS,
                fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
                label: businessInfo.businessStructure.legalAddress.company.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessStructure.legalAddress.company.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_STRUCTURE,
                  EFieldName.LEGAL_ADDRESS,
                ),
                conditionsList: [
                  {
                    type: EConditionType.EQUALS,
                    field: buildFieldName<EFieldName, EFieldBlock>(
                      EFieldName.BUSINESS_TYPE,
                      EFieldBlock.BUSINESS_STRUCTURE,
                      'id',
                    ),
                    value: EBusinessType.COMPANY,
                  },
                ],
              },
              {
                id: 10,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.LEGAL_ADDRESS,
                fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
                label: businessInfo.businessStructure.legalAddress.individual.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessStructure.legalAddress.individual.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_STRUCTURE,
                  EFieldName.LEGAL_ADDRESS,
                ),
                conditionsList: [
                  {
                    type: EConditionType.OR,
                    conditionsList: [
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.INDIVIDUAL,
                      },
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.SELF_EMPLOYED,
                      },
                    ],
                  },
                ],
              },
              {
                id: 11,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.LEGAL_ADDRESS,
                fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
                label: businessInfo.businessStructure.legalAddress.individualEntrepreneur.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessStructure.legalAddress.individualEntrepreneur.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_STRUCTURE,
                  EFieldName.LEGAL_ADDRESS,
                ),
                conditionsList: [
                  {
                    type: EConditionType.EQUALS,
                    field: buildFieldName<EFieldName, EFieldBlock>(
                      EFieldName.BUSINESS_TYPE,
                      EFieldBlock.BUSINESS_STRUCTURE,
                      'id',
                    ),
                    value: EBusinessType.INDIVIDUAL_ENTREPRENEUR,
                  },
                ],
              },
              {
                id: 12,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.ACTUAL_ADDRESS,
                fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
                label: businessInfo.businessStructure.actualAddress.company.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessStructure.actualAddress.company.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_STRUCTURE,
                  EFieldName.ACTUAL_ADDRESS,
                ),
                conditionsList: [
                  {
                    type: EConditionType.EQUALS,
                    field: buildFieldName<EFieldName, EFieldBlock>(
                      EFieldName.BUSINESS_TYPE,
                      EFieldBlock.BUSINESS_STRUCTURE,
                      'id',
                    ),
                    value: EBusinessType.COMPANY,
                  },
                ],
              },
              {
                id: 13,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.ACTUAL_ADDRESS,
                fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
                label: businessInfo.businessStructure.actualAddress.individual.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessStructure.actualAddress.individual.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_STRUCTURE,
                  EFieldName.ACTUAL_ADDRESS,
                ),
                conditionsList: [
                  {
                    type: EConditionType.OR,
                    conditionsList: [
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.INDIVIDUAL,
                      },
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.SELF_EMPLOYED,
                      },
                    ],
                  },
                ],
              },
              {
                id: 14,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.ACTUAL_ADDRESS,
                fieldBlock: EFieldBlock.BUSINESS_STRUCTURE,
                label: businessInfo.businessStructure.actualAddress.individualEntrepreneur.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessStructure.actualAddress.individualEntrepreneur.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_STRUCTURE,
                  EFieldName.ACTUAL_ADDRESS,
                ),
                conditionsList: [
                  {
                    type: EConditionType.EQUALS,
                    field: buildFieldName<EFieldName, EFieldBlock>(
                      EFieldName.BUSINESS_TYPE,
                      EFieldBlock.BUSINESS_STRUCTURE,
                      'id',
                    ),
                    value: EBusinessType.INDIVIDUAL_ENTREPRENEUR,
                  },
                ],
              },
            ],
          },
          {
            header: businessInfo.businessRepresentative.title,
            subHeader: businessInfo.businessRepresentative.subtitle,
            items: [
              {
                id: 1,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.LAST_NAME,
                fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
                label: businessInfo.businessRepresentative.lastName.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessRepresentative.lastName.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_REPRESENTATIVE,
                  EFieldName.LAST_NAME,
                ),
              },
              {
                id: 2,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.FIRST_NAME,
                fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
                label: businessInfo.businessRepresentative.firstName.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessRepresentative.firstName.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_REPRESENTATIVE,
                  EFieldName.FIRST_NAME,
                ),
              },
              {
                id: 3,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.MIDDLE_NAME,
                fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
                label: businessInfo.businessRepresentative.middleName.title,
                placeholder: businessInfo.businessRepresentative.middleName.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_REPRESENTATIVE,
                  EFieldName.MIDDLE_NAME,
                ),
              },
              {
                id: 4,
                type: EFormFieldType.BUTTON_GROUP,
                fieldName: EFieldName.REPRESENTATIVE,
                fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
                label: businessInfo.businessRepresentative.representative.title,
                summary: {
                  show: true,
                },
                description: {
                  variants: [
                    {
                      value: '',
                      conditionsList: [
                        {
                          type: EConditionType.EQUALS,
                          field: buildFieldName<EFieldName, EFieldBlock>(
                            EFieldName.REPRESENTATIVE,
                            EFieldBlock.BUSINESS_REPRESENTATIVE,
                            'id',
                          ),
                          value: EBusinessRepresentativeCompany.OTHER,
                        },
                      ],
                    },
                    {
                      value: businessInfo.businessRepresentative.representative.description,
                      conditionsList: [
                        {
                          type: EConditionType.EQUALS,
                          field: buildFieldName<EFieldName, EFieldBlock>(
                            EFieldName.REPRESENTATIVE,
                            EFieldBlock.BUSINESS_REPRESENTATIVE,
                            'id',
                          ),
                          value: EBusinessRepresentativeCompany.HEADER,
                        },
                      ],
                    },
                  ],
                },
                variant: 'spaced',
                buttonList: Object
                  .values(this.getBusinessRepresentativeList(this.localisationRegistry.accountActivate)),
                default: this.getBusinessRepresentativeList(
                  this.localisationRegistry.accountActivate,
                )[EBusinessRepresentativeCompany.HEADER],
                conditionsList: [
                  {
                    type: EConditionType.OR,
                    conditionsList: [
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.COMPANY,
                      },
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.INDIVIDUAL_ENTREPRENEUR,
                      },
                    ],
                  },
                ],
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_REPRESENTATIVE,
                  EFieldName.REPRESENTATIVE,
                ),
              },
              {
                id: 5,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.JOB_TITLE,
                fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
                label: this.getJobTitleLabel(businessInfo),
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessRepresentative.jobTitle.placeholder,
                description: {
                  default: businessInfo.businessRepresentative.jobTitle.description,
                },
                disabled: this.getJobTitleDisabled(),
                conditionsList: [
                  {
                    type: EConditionType.OR,
                    conditionsList: [
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.COMPANY,
                      },
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.INDIVIDUAL_ENTREPRENEUR,
                      },
                    ],
                  },
                ],
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_REPRESENTATIVE,
                  EFieldName.JOB_TITLE,
                ),
              },
              {
                id: 6,
                type: EFormFieldType.FILE_UPLOAD,
                label: businessInfo.businessRepresentative.documentIndividualEntrepreneur.label,
                summary: {
                  show: true,
                },
                description: {
                  default: businessInfo.businessRepresentative.documentIndividualEntrepreneur.description,
                },
                fieldName: EFieldName.DOCUMENT_INDIVIDUAL_ENTREPRENEUR,
                fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
                fileUpload: {
                  options: this.getDocumentIndividualEntrepreneurOptions(),
                },
                conditionsList: [
                  {
                    type: EConditionType.AND,
                    conditionsList: [
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.INDIVIDUAL_ENTREPRENEUR,
                      },
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.REPRESENTATIVE,
                          EFieldBlock.BUSINESS_REPRESENTATIVE,
                          'id',
                        ),
                        value: EBusinessRepresentativeIndividualEntrepreneur.ATTORNEY_IN_FACT,
                      },
                    ],
                  },
                ],
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_REPRESENTATIVE,
                  EFieldName.DOCUMENT_INDIVIDUAL_ENTREPRENEUR,
                ),
              },
              {
                id: 7,
                type: EFormFieldType.FILE_UPLOAD,
                label: businessInfo.businessRepresentative.documentCompany.label,
                summary: {
                  show: true,
                },
                description: {
                  default: businessInfo.businessRepresentative.documentCompany.description,
                },
                fieldName: EFieldName.DOCUMENT_COMPANY,
                fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
                fileUpload: {
                  options: this.getDocumentCompanyOptions(),
                },
                conditionsList: [
                  {
                    type: EConditionType.AND,
                    conditionsList: [
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.COMPANY,
                      },
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.REPRESENTATIVE,
                          EFieldBlock.BUSINESS_REPRESENTATIVE,
                          'id',
                        ),
                        value: EBusinessRepresentativeCompany.OTHER,
                      },
                    ],
                  },
                ],
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_REPRESENTATIVE,
                  EFieldName.DOCUMENT_COMPANY,
                ),
              },
              {
                id: 8,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.DOCUMENT,
                fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
                label: businessInfo.businessRepresentative.document.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessRepresentative.document.placeholder,
                conditionsList: [
                  {
                    type: EConditionType.OR,
                    conditionsList: [
                      {
                        type: EConditionType.AND,
                        conditionsList: [
                          {
                            type: EConditionType.EQUALS,
                            field: buildFieldName<EFieldName, EFieldBlock>(
                              EFieldName.BUSINESS_TYPE,
                              EFieldBlock.BUSINESS_STRUCTURE,
                              'id',
                            ),
                            value: EBusinessType.COMPANY,
                          },
                          {
                            type: EConditionType.EQUALS,
                            field: buildFieldName<EFieldName, EFieldBlock>(
                              EFieldName.REPRESENTATIVE,
                              EFieldBlock.BUSINESS_REPRESENTATIVE,
                              'id',
                            ),
                            value: EBusinessRepresentativeCompany.OTHER,
                          },
                        ],
                      },
                      {
                        type: EConditionType.AND,
                        conditionsList: [
                          {
                            type: EConditionType.EQUALS,
                            field: buildFieldName<EFieldName, EFieldBlock>(
                              EFieldName.BUSINESS_TYPE,
                              EFieldBlock.BUSINESS_STRUCTURE,
                              'id',
                            ),
                            value: EBusinessType.INDIVIDUAL_ENTREPRENEUR,
                          },
                          {
                            type: EConditionType.EQUALS,
                            field: buildFieldName<EFieldName, EFieldBlock>(
                              EFieldName.REPRESENTATIVE,
                              EFieldBlock.BUSINESS_REPRESENTATIVE,
                              'id',
                            ),
                            value: EBusinessRepresentativeIndividualEntrepreneur.ATTORNEY_IN_FACT,
                          },
                        ],
                      },
                    ],
                  },
                ],
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_REPRESENTATIVE,
                  EFieldName.DOCUMENT,
                ),
              },
              {
                id: 9,
                type: EFormFieldType.INPUT,
                inputType: 'email',
                fieldName: EFieldName.EMAIL,
                fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
                label: businessInfo.businessRepresentative.email.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessRepresentative.email.placeholder.softline,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_REPRESENTATIVE,
                  EFieldName.EMAIL,
                ),
              },
              {
                id: 11,
                type: EFormFieldType.MASKED_INPUT,
                maskOptions: {
                  mask: '+7 000 000 00 00',
                  eager: true,
                },
                inputType: 'phone',
                fieldName: EFieldName.PHONE_NUMBER,
                fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
                label: businessInfo.businessRepresentative.phoneNumber.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessRepresentative.phoneNumber.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_REPRESENTATIVE,
                  EFieldName.PHONE_NUMBER,
                ),
              },
              {
                id: 12,
                type: EFormFieldType.DATE,
                fieldName: EFieldName.DATE_OF_BIRTH,
                fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
                maxDate: maxDateOfBirthDate,
                default: maxDateOfBirthDate,
                label: businessInfo.businessRepresentative.dateOfBirth.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessRepresentative.dateOfBirth.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_REPRESENTATIVE,
                  EFieldName.DATE_OF_BIRTH,
                ),
                conditionsList: [
                  {
                    type: EConditionType.OR,
                    conditionsList: [
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.INDIVIDUAL,
                      },
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.SELF_EMPLOYED,
                      },
                    ],
                  },
                ],
              },
              {
                id: 13,
                type: EFormFieldType.MASKED_INPUT,
                maskOptions: {
                  mask: '0000 000000',
                  eager: true,
                },
                fieldName: EFieldName.PASSPORT,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                label: businessInfo.businessDetails.passport.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessDetails.passport.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.PASSPORT,
                ),
                conditionsList: [
                  {
                    type: EConditionType.OR,
                    conditionsList: [
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.INDIVIDUAL,
                      },
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.SELF_EMPLOYED,
                      },
                    ],
                  },
                ],
              },
              {
                id: 14,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.PASSPORT_DATE,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                label: businessInfo.businessDetails.passportDate.title,
                summary: {
                  show: true,
                },
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.PASSPORT_DATE,
                ),
                conditionsList: [
                  {
                    type: EConditionType.OR,
                    conditionsList: [
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.INDIVIDUAL,
                      },
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.SELF_EMPLOYED,
                      },
                    ],
                  },
                ],
              },
              {
                id: 15,
                type: EFormFieldType.MASKED_INPUT,
                maskOptions: {
                  mask: '000-000',
                  eager: true,
                },
                fieldName: EFieldName.PASSPORT_CODE,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                label: businessInfo.businessDetails.passportCode.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessDetails.passportCode.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.PASSPORT_CODE,
                ),
                conditionsList: [
                  {
                    type: EConditionType.OR,
                    conditionsList: [
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.INDIVIDUAL,
                      },
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.SELF_EMPLOYED,
                      },
                    ],
                  },
                ],
              },
              {
                id: 16,
                type: EFormFieldType.FILE_UPLOAD,
                label: businessInfo.businessRepresentative.documentPassport.label,
                summary: {
                  show: true,
                },
                description: {
                  default: businessInfo.businessRepresentative.documentPassport.description,
                },
                fieldName: EFieldName.DOCUMENT_PASSPORT,
                fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
                fileUpload: {
                  options: this.getDocumentPassportOptions(),
                },
                conditionsList: [
                  {
                    type: EConditionType.OR,
                    conditionsList: [
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.INDIVIDUAL,
                      },
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.SELF_EMPLOYED,
                      },
                    ],
                  },
                ],
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_REPRESENTATIVE,
                  EFieldName.DOCUMENT_PASSPORT,
                ),
              },
              {
                id: 17,
                type: EFormFieldType.MASKED_INPUT,
                maskOptions: {
                  mask: '000-000-000 00',
                  eager: true,
                },
                fieldName: EFieldName.SNILS,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                label: businessInfo.businessDetails.snils.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessDetails.snils.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.SNILS,
                ),
                conditionsList: [
                  {
                    type: EConditionType.OR,
                    conditionsList: [
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.INDIVIDUAL,
                      },
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.SELF_EMPLOYED,
                      },
                    ],
                  },
                ],
              },
              {
                id: 18,
                type: EFormFieldType.FILE_UPLOAD,
                label: businessInfo.businessRepresentative.documentSnils.label,
                summary: {
                  show: true,
                },
                description: {
                  default: businessInfo.businessRepresentative.documentSnils.description,
                },
                fieldName: EFieldName.DOCUMENT_SNILS,
                fieldBlock: EFieldBlock.BUSINESS_REPRESENTATIVE,
                fileUpload: {
                  options: this.getDocumentSnilsOptions(),
                },
                conditionsList: [
                  {
                    type: EConditionType.OR,
                    conditionsList: [
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.INDIVIDUAL,
                      },
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.SELF_EMPLOYED,
                      },
                    ],
                  },
                ],
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_REPRESENTATIVE,
                  EFieldName.DOCUMENT_SNILS,
                ),
              },
              {
                id: 19,
                type: EFormFieldType.MASKED_INPUT,
                maskOptions: {
                  mask: '000000000000000',
                },
                fieldName: EFieldName.DOCUMENT_OGRNIP,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                label: businessInfo.businessDetails.documentOgrnip.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessDetails.documentOgrnip.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.DOCUMENT_OGRNIP,
                ),
                conditionsList: [
                  {
                    type: EConditionType.EQUALS,
                    field: buildFieldName<EFieldName, EFieldBlock>(
                      EFieldName.BUSINESS_TYPE,
                      EFieldBlock.BUSINESS_STRUCTURE,
                      'id',
                    ),
                    value: EBusinessType.INDIVIDUAL_ENTREPRENEUR,
                  },
                ],
              },
            ],
          },
          {
            header: businessInfo.businessDetails.title,
            subHeader: businessInfo.businessDetails.subtitle,
            items: [
              {
                id: 1,
                type: EFormFieldType.SELECT,
                fieldName: EFieldName.ORGANIZATIONAL_LEGAL_FORM_OF_BUSINESS,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                label: businessInfo.businessDetails.organizationalLegalFormOfBusiness.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessDetails.organizationalLegalFormOfBusiness.placeholder,
                select: {
                  idField: 'id',
                  titleField: 'title',
                  list: this.getOrganizationalLegalFormOfBusinessList(),
                },
                conditionsList: [
                  {
                    type: EConditionType.OR,
                    conditionsList: [
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.COMPANY,
                      },
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.INDIVIDUAL_ENTREPRENEUR,
                      },
                    ],
                  },
                ],
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.ORGANIZATIONAL_LEGAL_FORM_OF_BUSINESS,
                ),
              },
              {
                id: 2,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.LEGAL_BUSINESS_NAME,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                label: businessInfo.businessDetails.legalBusinessName.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessDetails.legalBusinessName.placeholder,
                description: {
                  default: businessInfo.businessDetails.legalBusinessName.description,
                },
                conditionsList: [
                  {
                    type: EConditionType.OR,
                    conditionsList: [
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.COMPANY,
                      },
                      {
                        type: EConditionType.EQUALS,
                        field: buildFieldName<EFieldName, EFieldBlock>(
                          EFieldName.BUSINESS_TYPE,
                          EFieldBlock.BUSINESS_STRUCTURE,
                          'id',
                        ),
                        value: EBusinessType.INDIVIDUAL_ENTREPRENEUR,
                      },
                    ],
                  },
                ],
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.LEGAL_BUSINESS_NAME,
                ),
              },
              {
                id: 3,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.DOING_BUSINESS_AS,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                label: businessInfo.businessDetails.doingBusinessAs.title,
                summary: {
                  show: true,
                },
                description: {
                  default: businessInfo.businessDetails.doingBusinessAs.description,
                },
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.DOING_BUSINESS_AS,
                ),
              },
              {
                id: 4,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.BUSINESS_WEBSITE,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                label: businessInfo.businessDetails.businessWebsite.title,
                summary: {
                  show: true,
                },
                placeholder: businessInfo.businessDetails.businessWebsite.placeholder,
                description: {
                  default: businessInfo.businessDetails.businessWebsite.description,
                },
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.BUSINESS_WEBSITE,
                ),
              },
              {
                id: 5,
                type: EFormFieldType.CHECKBOX_GROUP,
                fieldName: EFieldName.PRODUCT_TAX_CATEGORY,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                label: businessInfo.productTaxCategory.title,
                summary: {
                  show: true,
                },
                checkboxGroup: {
                  list: this.getProductTaxCategoryList(),
                },
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.PRODUCT_TAX_CATEGORY,
                ),
              },
              {
                id: 6,
                type: EFormFieldType.TEXT_AREA,
                fieldName: EFieldName.PRODUCT_DESCRIPTION,
                fieldBlock: EFieldBlock.BUSINESS_DETAILS,
                label: this.initOptionalFieldText(
                  businessInfo.businessDetails.productDescription.title,
                  this.localisationRegistry.common,
                ),
                summary: {
                  show: true,
                  label: businessInfo.businessDetails.productDescription.title,
                },
                description: {
                  default: businessInfo.businessDetails.productDescription.description,
                },
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.BUSINESS_DETAILS,
                  EFieldName.PRODUCT_DESCRIPTION,
                ),
              },
            ],
          },
        ],
      },
      {
        id: EPageTab.SUPPORT_DETAILS,
        title: this.localisationRegistry.accountActivate.support.tab,
        formConfig: [
          {
            header: this.localisationRegistry.accountActivate.support.title,
            subHeader: this.localisationRegistry.accountActivate.support.subtitle,
            items: [
              {
                id: 1,
                type: EFormFieldType.MASKED_INPUT,
                maskOptions: {
                  mask: '+7 000 000 00 00',
                  eager: true,
                },
                inputType: 'phone',
                fieldName: EFieldName.PHONE_NUMBER,
                fieldBlock: EFieldBlock.SUPPORT_DETAILS,
                label: this.initOptionalFieldText(
                  this.localisationRegistry.accountActivate.support.phone.title,
                  this.localisationRegistry.common,
                ),
                summary: {
                  show: true,
                  label: this.localisationRegistry.accountActivate.support.phone.title,
                },
                placeholder: this.localisationRegistry.accountActivate.support.phone.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.SUPPORT_DETAILS,
                  EFieldName.PHONE_NUMBER,
                ),
              },
              {
                id: 2,
                type: EFormFieldType.INPUT,
                inputType: 'email',
                fieldName: EFieldName.EMAIL,
                fieldBlock: EFieldBlock.SUPPORT_DETAILS,
                label: this.localisationRegistry.accountActivate.support.email.title,
                summary: {
                  show: true,
                },
                placeholder: this.localisationRegistry.accountActivate.support.email.placeholder.softline,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.SUPPORT_DETAILS,
                  EFieldName.EMAIL,
                ),
              },
            ],
          },
        ],
      },
      {
        id: EPageTab.ACCOUNT_FOR_PAYOUTS,
        title: this.localisationRegistry.accountActivate.bank.tab,
        formConfig: [
          {
            header: this.localisationRegistry.accountActivate.bank.title,
            subHeader: this.localisationRegistry.accountActivate.bank.subtitle,
            items: [
              {
                id: 1,
                type: EFormFieldType.BUTTON_GROUP,
                fieldName: EFieldName.CURRENCY,
                fieldBlock: EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                label: this.localisationRegistry.accountActivate.bank.currency.title,
                summary: {
                  show: true,
                },
                variant: 'spaced',
                description: {
                  default: this.localisationRegistry.accountActivate.bank.currency.description.softline,
                },
                buttonList: currencyButtonList,
                default: currencyButtonList[0],
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                  EFieldName.CURRENCY,
                ),
              },
              {
                id: 2,
                type: EFormFieldType.MASKED_INPUT,
                maskOptions: {
                  mask: '000000000',
                },
                fieldName: EFieldName.BIK,
                fieldBlock: EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                label: this.localisationRegistry.accountActivate.bank.bik.title,
                summary: {
                  show: true,
                },
                placeholder: this.localisationRegistry.accountActivate.bank.bik.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                  EFieldName.BIK,
                ),
              },
              {
                id: 3,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.BANK_NAME,
                fieldBlock: EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                label: this.localisationRegistry.accountActivate.bank.bankName.title,
                summary: {
                  show: true,
                },
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                  EFieldName.BANK_NAME,
                ),
              },
              {
                id: 4,
                type: EFormFieldType.INPUT,
                fieldName: EFieldName.KS,
                fieldBlock: EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                label: this.localisationRegistry.accountActivate.bank.ks.title,
                summary: {
                  show: true,
                },
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                  EFieldName.KS,
                ),
              },
              {
                id: 5,
                type: EFormFieldType.MASKED_INPUT,
                maskOptions: {
                  mask: '00000000000000000000',
                },
                fieldName: EFieldName.RS,
                fieldBlock: EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                label: this.localisationRegistry.accountActivate.bank.rs.title,
                summary: {
                  show: true,
                },
                placeholder: this.localisationRegistry.accountActivate.bank.rs.placeholder,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.ACCOUNT_FOR_PAYOUTS,
                  EFieldName.RS,
                ),
              },
            ],
          },
        ],
      },
      {
        id: EPageTab.TAX_CALCULATION,
        title: this.localisationRegistry.accountActivate.taxCalculation.tab,
        formConfig: [
          {
            header: this.localisationRegistry.accountActivate.taxCalculation.title,
            subHeader: this.localisationRegistry.accountActivate.taxCalculation.subtitle,
            items: [
              {
                id: 1,
                type: EFormFieldType.BUTTON_GROUP,
                fieldName: EFieldName.TAX_CALCULATION,
                fieldBlock: EFieldBlock.TAX_CALCULATION,
                label: this.localisationRegistry.accountActivate.taxCalculation.taxCalculation.title,
                summary: {
                  show: true,
                },
                description: {
                  variants: [
                    {
                      value: this.localisationRegistry
                        .accountActivate.taxCalculation.taxCalculation.description.softline.inclusive,
                      conditionsList: [
                        {
                          type: EConditionType.EQUALS,
                          field: buildFieldName<EFieldName, EFieldBlock>(
                            EFieldName.TAX_CALCULATION,
                            EFieldBlock.TAX_CALCULATION,
                            'value',
                          ),
                          value: ETaxCalculation.INCLUSIVE,
                        },
                      ],
                    },
                    {
                      value: this.localisationRegistry
                        .accountActivate.taxCalculation.taxCalculation.description.softline.exclusive,
                      conditionsList: [
                        {
                          type: EConditionType.EQUALS,
                          field: buildFieldName<EFieldName, EFieldBlock>(
                            EFieldName.TAX_CALCULATION,
                            EFieldBlock.TAX_CALCULATION,
                            'value',
                          ),
                          value: ETaxCalculation.EXCLUSIVE,
                        },
                      ],
                    },
                  ],
                },
                buttonList: Object.values(taxCalculationList),
                default: taxCalculationList.inclusive,
                status: this.getFieldStatus(
                  this.state.formFieldStatusList,
                  EFieldBlock.TAX_CALCULATION,
                  EFieldName.TAX_CALCULATION,
                ),
              },
            ],
          },
        ],
      },
      {
        id: EPageTab.SUMMARY,
        title: this.localisationRegistry.accountActivate.summary.tab,
        formConfig: [],
      },
    ];

    const validators = this.validateHandler.getValidators();

    formConfig.forEach((tab) => {
      tab.formConfig.forEach((formConfig) => {
        formConfig.items.forEach((item) => {
          if (validators[item.fieldBlock]?.[item.fieldName]) {
            item.validator = validators[item.fieldBlock][item.fieldName];
          }
        });
      });
    });

    return formConfig;
  };

  getFormConfig = (tab: ITab): IFormConfig => this.getTabListConfig()
    .find((tabConfig) => tab.id === tabConfig.id) as IFormConfig;

  // eslint-disable-next-line class-methods-use-this
  getFieldStatus = (
    formFieldsStatuses: TFieldStatuses,
    fieldBlock: EFieldBlock,
    fieldName: EFieldName,
  ): IFieldStatus => {
    if (!formFieldsStatuses[fieldBlock]) {
      return {
        code: EValidationFieldStatusCode.DEFAULT,
      };
    }

    return formFieldsStatuses[fieldBlock][fieldName] ?? {
      code: EValidationFieldStatusCode.DEFAULT,
    };
  };

  getBusinessStructureCountryDescription = (
    localisation: ICamelCaseLocalisationAccountActivatePage,
  ): string => {
    const utilsService = this.serviceContainer.resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);

    return utilsService.email.replaceSupportEmail(
      localisation.businessInfo.businessStructure.country.description,
      false,
    );
  };

  // eslint-disable-next-line class-methods-use-this
  getMaxDateOfBirth = (): Date => {
    const maxDateOfBirthDate = new Date();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    maxDateOfBirthDate.setYear(maxDateOfBirthDate.getFullYear() - 18);
    return maxDateOfBirthDate;
  };

  // eslint-disable-next-line class-methods-use-this
  initOptionalFieldText = (
    field: string,
    commonLocalisation: ICamelCaseLocalisationCommon,
  ) => `${field} (${commonLocalisation.optional})`;

  // eslint-disable-next-line class-methods-use-this
  getBusinessTypes = (
    localisation: ICamelCaseLocalisationAccountActivatePage,
  ): IBusinessType[] => {
    const typeOfBusinessList = this.getTypeOfBusinessList(localisation);
    const individualList = this.getIndividualList(localisation);

    const businessStructure = localisation.businessInfo.businessStructure;
    const businessDetails = localisation.businessInfo.businessDetails;

    return [
      {
        id: EBusinessType.COMPANY,
        title: businessStructure.typeOfBusiness.softline.buttons.company,
        typeOfBusiness: typeOfBusinessList[ETypeOfBusiness.COMPANY],
      },
      {
        id: EBusinessType.INDIVIDUAL_ENTREPRENEUR,
        title: businessDetails.individual.buttons.individualEntrepreneur,
        typeOfBusiness: typeOfBusinessList[ETypeOfBusiness.COMPANY],
        individual: individualList[EIndividualKey.INDIVIDUAL_ENTREPRENEUR],
      },
      {
        id: EBusinessType.INDIVIDUAL,
        title: businessStructure.typeOfBusiness.softline.buttons.individual,
        typeOfBusiness: typeOfBusinessList[EIndividualKey.INDIVIDUAL],
        individual: individualList[EIndividualKey.INDIVIDUAL],
      },
      {
        id: EBusinessType.SELF_EMPLOYED,
        title: businessDetails.individual.buttons.selfEmployed,
        typeOfBusiness: typeOfBusinessList[EIndividualKey.INDIVIDUAL],
        individual: individualList[EIndividualKey.SELF_EMPLOYED],
      },
    ].filter((businessType) => [
      EBusinessType.COMPANY,
      EBusinessType.INDIVIDUAL_ENTREPRENEUR,
    ].includes(businessType.id));
  };

  // eslint-disable-next-line class-methods-use-this
  getTypeOfBusinessList = (
    localisation: ICamelCaseLocalisationAccountActivatePage,
  ): TTypeOFBusiness => ({
    [ETypeOfBusiness.COMPANY]: {
      id: 1,
      title: localisation.businessInfo.businessStructure.typeOfBusiness.softline.buttons.company,
      value: ETypeOfBusiness.COMPANY,
    },
    [ETypeOfBusiness.INDIVIDUAL]: {
      id: 2,
      title: localisation.businessInfo.businessStructure.typeOfBusiness.softline.buttons.individual,
      value: ETypeOfBusiness.INDIVIDUAL,
    },
  });

  // eslint-disable-next-line class-methods-use-this
  getIndividualList = (localisation: ICamelCaseLocalisationAccountActivatePage): TIndividual => ({
    [EIndividualKey.INDIVIDUAL]: {
      id: 1,
      title: localisation.businessInfo.businessDetails.individual.buttons[EIndividualKey.INDIVIDUAL],
      value: EIndividual.INDIVIDUAL,
    },
    [EIndividualKey.SELF_EMPLOYED]: {
      id: 2,
      title: localisation.businessInfo.businessDetails.individual.buttons[EIndividualKey.SELF_EMPLOYED],
      value: EIndividual.SELF_EMPLOYED,
    },
    [EIndividualKey.INDIVIDUAL_ENTREPRENEUR]: {
      id: 3,
      title: localisation.businessInfo.businessDetails.individual.buttons[EIndividualKey.INDIVIDUAL_ENTREPRENEUR],
      value: EIndividual.INDIVIDUAL_ENTREPRENEUR,
    },
  });

  // eslint-disable-next-line class-methods-use-this
  getCurrencies = (
    localisation: ICamelCaseLocalisationAccountActivatePage,
  ): ICurrency[] => [
    {
      id: 1,
      title: localisation.bank.currency.buttons.rub,
      value: ECurrency.RUB,
    },
  ];

  // eslint-disable-next-line class-methods-use-this
  getTaxCalculationList = (localisation: ICamelCaseLocalisationAccountActivatePage): TTaxCalculationList => ({
    [ETaxCalculation.INCLUSIVE]: {
      id: 1,
      title: localisation.taxCalculation.taxCalculation.buttons[ETaxCalculation.INCLUSIVE],
      value: ETaxCalculation.INCLUSIVE,
    },
    [ETaxCalculation.EXCLUSIVE]: {
      id: 2,
      title: localisation.taxCalculation.taxCalculation.buttons[ETaxCalculation.EXCLUSIVE],
      value: ETaxCalculation.EXCLUSIVE,
    },
  });

  // eslint-disable-next-line class-methods-use-this
  getTaxationSystemList = (localisation: ICamelCaseLocalisationAccountActivatePage): TTaxationSystem => ({
    [ETaxationSystem.NDS_IS_INCLUDED]: {
      id: ETaxationSystem.NDS_IS_INCLUDED,
      title: localisation.businessInfo.businessDetails.taxationSystem.ndsIsIncluded,
    },
    [ETaxationSystem.NDS_IS_EXCLUDED]: {
      id: ETaxationSystem.NDS_IS_EXCLUDED,
      title: localisation.businessInfo.businessDetails.taxationSystem.ndsIsExcluded,
    },
    [ETaxationSystem.SKOLKOVO_RESIDENT]: {
      id: ETaxationSystem.SKOLKOVO_RESIDENT,
      title: localisation.businessInfo.businessDetails.taxationSystem.skolkovoResident,
    },
  });

  // eslint-disable-next-line class-methods-use-this
  getBusinessRepresentativeList = (
    localisation: ICamelCaseLocalisationAccountActivatePage,
  ): TBusinessRepresentative => {
    const representativeListMap = {
      [EBusinessType.COMPANY]: {
        [EBusinessRepresentativeCompany.HEADER]: {
          id: EBusinessRepresentativeCompany.HEADER,
          title: localisation.businessInfo.businessRepresentative.representative.header,
        },
        [EBusinessRepresentativeCompany.OTHER]: {
          id: EBusinessRepresentativeCompany.OTHER,
          title: localisation.businessInfo.businessRepresentative.representative.other,
        },
      },
      [EBusinessType.INDIVIDUAL_ENTREPRENEUR]: {
        [EBusinessRepresentativeIndividualEntrepreneur.INDIVIDUAL_ENTREPRENEUR]: {
          id: EBusinessRepresentativeIndividualEntrepreneur.INDIVIDUAL_ENTREPRENEUR,
          title: localisation.businessInfo.businessRepresentative.representative.individualEntrepreneur,
        },
        [EBusinessRepresentativeIndividualEntrepreneur.ATTORNEY_IN_FACT]: {
          id: EBusinessRepresentativeIndividualEntrepreneur.ATTORNEY_IN_FACT,
          title: localisation.businessInfo.businessRepresentative.representative.attorneyInFact,
        },
      },
    };

    const mapKey = this.state.formData?.businessStructure?.businessType.id ?? 'company';

    return representativeListMap[mapKey];
  };

  getJobTitleLabel = (businessInfo) => {
    let label = businessInfo.businessRepresentative.jobTitle.title.company;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const businessType = this.state.formData?.businessStructure?.businessType;

    if (businessType && businessType.id === EBusinessType.INDIVIDUAL_ENTREPRENEUR) {
      label = businessInfo.businessRepresentative.jobTitle.title.individualEntrepreneur;
    }

    return label;
  };

  getJobTitleDisabled = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!this.state.formData.businessRepresentative?.representative) {
      return false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const representativeId = this.state.formData.businessRepresentative.representative.id;

    return representativeId === EBusinessRepresentativeIndividualEntrepreneur.INDIVIDUAL_ENTREPRENEUR;
  };

  getDocumentCompanyOptions = () => {
    const accountActivate = this.localisationRegistry.accountActivate;

    // TODO: extract
    const fileErrorMessages: TFileErrorMessages = {
      [EFileErrorType.MAX_SIZE]: accountActivate.fileUpload.validationMessages.invalidFileSize,
      [EFileErrorType.MIN_SIZE]: accountActivate.fileUpload.validationMessages.noZeroFileSize,
      [EFileErrorType.MIME_TYPE]: accountActivate.fileUpload.validationMessages.invalidFileMimeType,
      [EFileErrorType.LOAD]: accountActivate.fileUpload.validationMessages.loadError,
      [EFileErrorType.NAME]: accountActivate.fileUpload.validationMessages.invalidFileName,
    };

    const documentCompanyOptions: IFileUploadOptions = this.initDocumentUploadOptions(
      fileErrorMessages,
      accountActivate,
      accountActivate.businessInfo.businessRepresentative.documentCompany.fileExtensions,
    ) as IFileUploadOptions;
    documentCompanyOptions.fileModel = {
      fileType: EAccountActivateFileType.DOCUMENT_COMPANY,
    };

    return documentCompanyOptions;
  };

  getDocumentPassportOptions = () => {
    const accountActivate = this.localisationRegistry.accountActivate;

    // TODO: extract
    const fileErrorMessages: TFileErrorMessages = {
      [EFileErrorType.MAX_SIZE]: accountActivate.fileUpload.validationMessages.invalidFileSize,
      [EFileErrorType.MIN_SIZE]: accountActivate.fileUpload.validationMessages.noZeroFileSize,
      [EFileErrorType.MIME_TYPE]: accountActivate.fileUpload.validationMessages.invalidFileMimeType,
      [EFileErrorType.LOAD]: accountActivate.fileUpload.validationMessages.loadError,
      [EFileErrorType.NAME]: accountActivate.fileUpload.validationMessages.invalidFileName,
    };

    const documentPassportOptions: IFileUploadOptions = this.initDocumentUploadOptions(
      fileErrorMessages,
      accountActivate,
      accountActivate.businessInfo.businessRepresentative.documentPassport.fileExtensions,
    ) as IFileUploadOptions;
    documentPassportOptions.fileModel = {
      fileType: EAccountActivateFileType.DOCUMENT_PASSPORT,
    };

    return documentPassportOptions;
  };

  getDocumentSnilsOptions = () => {
    const accountActivate = this.localisationRegistry.accountActivate;

    // TODO: extract
    const fileErrorMessages: TFileErrorMessages = {
      [EFileErrorType.MAX_SIZE]: accountActivate.fileUpload.validationMessages.invalidFileSize,
      [EFileErrorType.MIN_SIZE]: accountActivate.fileUpload.validationMessages.noZeroFileSize,
      [EFileErrorType.MIME_TYPE]: accountActivate.fileUpload.validationMessages.invalidFileMimeType,
      [EFileErrorType.LOAD]: accountActivate.fileUpload.validationMessages.loadError,
      [EFileErrorType.NAME]: accountActivate.fileUpload.validationMessages.invalidFileName,
    };

    const documentSnilsOptions: IFileUploadOptions = this.initDocumentUploadOptions(
      fileErrorMessages,
      accountActivate,
      accountActivate.businessInfo.businessRepresentative.documentSnils.fileExtensions,
    ) as IFileUploadOptions;
    documentSnilsOptions.fileModel = {
      fileType: EAccountActivateFileType.DOCUMENT_SNILS,
    };

    return documentSnilsOptions;
  };

  getDocumentIndividualEntrepreneurOptions = () => {
    const accountActivate = this.localisationRegistry.accountActivate;

    // TODO: extract
    const fileErrorMessages: TFileErrorMessages = {
      [EFileErrorType.MAX_SIZE]: accountActivate.fileUpload.validationMessages.invalidFileSize,
      [EFileErrorType.MIN_SIZE]: accountActivate.fileUpload.validationMessages.noZeroFileSize,
      [EFileErrorType.MIME_TYPE]: accountActivate.fileUpload.validationMessages.invalidFileMimeType,
      [EFileErrorType.LOAD]: accountActivate.fileUpload.validationMessages.loadError,
      [EFileErrorType.NAME]: accountActivate.fileUpload.validationMessages.invalidFileName,
    };

    const documentIndividualEntrepreneurOptions: IFileUploadOptions = this.initDocumentUploadOptions(
      fileErrorMessages,
      accountActivate,
      accountActivate
        .businessInfo.businessRepresentative.documentIndividualEntrepreneur.fileExtensions,
    ) as IFileUploadOptions;
    documentIndividualEntrepreneurOptions.fileModel = {
      fileType: EAccountActivateFileType.DOCUMENT_INDIVIDUAL_ENTREPRENEUR,
    };

    return documentIndividualEntrepreneurOptions;
  };

  // eslint-disable-next-line class-methods-use-this
  initDocumentUploadOptions = (
    fileErrorMessages: TFileErrorMessages,
    localisation: ICamelCaseLocalisationAccountActivatePage,
    extensionsString?: string,
  ): ICFileUploadOptions => getOptions(
    fileErrorMessages,
    {
      text: localisation.fileUpload.button,
      variant: EDSButtonVariant.SECONDARY,
    },
    {
      icon: EIcon.ATTACH,
      color: EIconColorName.LINKED,
    },
    localisation.fileUpload.description,
    extensionsString,
    [
      EFileMimeTypes.PDF,
      EFileMimeTypes.PNG,
      EFileMimeTypes.DOC,
      EFileMimeTypes.DOCX,
      EFileMimeTypes.PNG,
      EFileMimeTypes.JPG,
      EFileMimeTypes.JPEG,
    ],
    5,
    undefined,
    255,
  );

  // eslint-disable-next-line class-methods-use-this
  getProductTaxCategoryList = (): IProductTaxCategory[] => [
    {
      id: EProductTaxCategory.SOFTWARE,
      value: false,
      title: this.localisationRegistry.accountActivate.businessInfo.productTaxCategory.software.title,
    },
    {
      id: EProductTaxCategory.SERVICES,
      value: false,
      title: this.localisationRegistry.accountActivate.businessInfo.productTaxCategory.services.title,
    },
    {
      id: EProductTaxCategory.SAAS,
      value: false,
      title: this.localisationRegistry.accountActivate.businessInfo.productTaxCategory.saas.title,
    },
    {
      id: EProductTaxCategory.EBOOKS,
      value: false,
      title: this.localisationRegistry.accountActivate.businessInfo.productTaxCategory.ebooks.title,
    },
  ];

  getOrganizationalLegalFormOfBusinessList = (): ICSelectOption[] => {
    const localisation = this.localisationRegistry.accountActivate.businessInfo.businessDetails;

    const listMap = {
      [EBusinessType.COMPANY]: [
        {
          id: EOrganizationalLegalFormOfBusiness.OOO,
          title: localisation.organizationalLegalFormOfBusiness.options.ooo,
        },
        {
          id: EOrganizationalLegalFormOfBusiness.ZAO,
          title: localisation.organizationalLegalFormOfBusiness.options.zao,
        },
        {
          id: EOrganizationalLegalFormOfBusiness.AO,
          title: localisation.organizationalLegalFormOfBusiness.options.ao,
        },
      ],
      [EBusinessType.INDIVIDUAL_ENTREPRENEUR]: [
        {
          id: EOrganizationalLegalFormOfBusiness.IP,
          title: localisation.organizationalLegalFormOfBusiness.options.ip,
        },
      ],
    };

    const businessType = this.state.formData?.businessStructure?.businessType;

    const mapKey = businessType?.id ?? EBusinessType.COMPANY;

    return listMap[mapKey];
  };

  filterCountry = (
    fieldBlock: EFieldBlock,
    fieldName: EFieldName,
  ) => this.state.lists.country.filter((country) => {
    if (!this.state.filteredSelectRegistry[fieldBlock]?.[fieldName]) {
      return true;
    }

    return country.name
      .toLowerCase()
      .includes(this.state.filteredSelectRegistry[fieldBlock][fieldName].toLowerCase());
  });
}

export default FormConfigHandler;
