import IFieldValidatorStatusHandler
  from '@/module/merchant/view/AccountActivatePage/Validator/IFieldValidatorStatusHandler';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import IFieldStatus from '@/module/design-system/components/models/FormComponent/IFieldStatus';
import IValidator from '@/module/common/utils/validator/contract/IValidator';
import IFormFieldItem from '@/module/design-system/components/models/FormComponent/IFormFieldItem';
import cloneImmutable from '@/module/common/utils/data/cloneImmutable';
import EFieldName from '@/module/merchant/view/AccountActivatePage/models/EFieldName';
import EFieldBlock from '@/module/merchant/view/AccountActivatePage/models/EFieldBlock';
import TFieldStatuses
  from '@/module/merchant/view/AccountActivatePage/models/TFieldStatuses';

class SimpleFieldValidatorStatusHandler implements IFieldValidatorStatusHandler {
  // eslint-disable-next-line class-methods-use-this
  changeStatus(
    fieldConfig: IFormFieldItem<EFieldName, EFieldBlock>,
    validator: IValidator,
    formFieldStatusList: TFieldStatuses,
  ): TFieldStatuses {
    const { fieldBlock, fieldName } = fieldConfig;
    const statusList = cloneImmutable(formFieldStatusList);

    const response = {
      code: validator.hasErrors() ? EValidationFieldStatusCode.ERROR : EValidationFieldStatusCode.DEFAULT,
    } as IFieldStatus;

    if (validator.hasErrors()) {
      response.text = validator.getMessages()[0];
    }

    if (!statusList[fieldBlock]) {
      statusList[fieldBlock] = {} as { [key in EFieldName]: IFieldStatus};
    }

    statusList[fieldBlock][fieldName] = response;

    return statusList;
  }
}

export default SimpleFieldValidatorStatusHandler;
