const beforeMount = {
  bind(el, binding, vNode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vNode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('mousedown', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('mousedown', el.clickOutsideEvent);
  },
};
export default beforeMount;
