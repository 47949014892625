import moment from 'moment';

const defaultOptions = {
  deleteCurrentYear: false,
  isWeekDay: true,
  time: false,
  timeZone: false,
};

function getOptions(options) {
  return { ...defaultOptions, ...options };
}

export default function date(vm) {
  return {
    formatSkipCurrentYearWithTime(dateValue, timeZone = true) {
      return this.format(dateValue, {
        deleteCurrentYear: true,
        time: true,
        timeZone,
      });
    },
    formatSkipCurrentYearWithoutWeekDay(dateValue) {
      return this.format(dateValue, {
        deleteCurrentYear: true,
        isWeekDay: false,
      });
    },
    formatSkipCurrentYear(dateValue) {
      return this.format(dateValue, { deleteCurrentYear: true });
    },
    formatWithoutSkipCurrentYear(dateValue) {
      return this.format(dateValue);
    },
    format(value, options = null) {
      const dateValue = moment(value);
      const {
        deleteCurrentYear,
        isWeekDay,
        time,
        timeZone,
      } = getOptions(options);

      let mappedValue;
      if (deleteCurrentYear && this.getCurrentDate().year() === dateValue.year()) {
        mappedValue = this.formatWithoutYear(dateValue);
      } else {
        mappedValue = this.formatWithYear(dateValue);
      }

      const parts = [
        isWeekDay ? this.getWeekDay(dateValue) : null,
        mappedValue,
      ];

      const timeString = time ? ` ${this.getTime(dateValue)}` : ' ';

      const timeZoneValue = timeZone ? ` ${this.getTimeZone(dateValue)}` : '';

      return (parts.filter((part) => !!part).join(', ') + timeString + timeZoneValue).trim();
    },
    formatWithYear(dateValue) {
      return this.getFormat(dateValue, 'MMM DD, YYYY');
    },
    formatWithoutYear(dateValue) {
      return this.getFormat(dateValue, 'MMM DD');
    },
    getFormat(dateValue, format) {
      const { getters } = vm.$store;
      const locale = getters?.getLocale ?? 'en';

      const formattedDate = locale ? moment(dateValue).locale(locale).format(format) : moment(dateValue).format(format);

      return this.postFormat(formattedDate);
    },
    getTime(dateValue) {
      return moment(dateValue).format('HH:mm');
    },
    getTimeZone(dateValue) {
      let utcOffset = moment(dateValue).utcOffset() / 60;
      if (utcOffset > 0) {
        utcOffset = `+${utcOffset}`;
      }

      return `GMT ${utcOffset}`;
    },
    postFormat(dateValue) {
      const { getters } = vm.$store;
      const locale = getters?.getLocale ?? 'en';

      let newDate = dateValue;

      if (locale === 'ru') {
        newDate = vm.$utils.string.ucFirst(newDate);
      }

      return newDate;
    },
    getWeekDay(dateValue) {
      const { getters } = vm.$store;
      const locale = getters?.getLocale ?? 'en';

      const dayOfWeekFormat = locale === 'ru' ? 'dd' : 'ddd';
      const dayOfWeek = this.getFormat(dateValue, dayOfWeekFormat);

      return locale === 'ru' ? dayOfWeek.toUpperCase() : dayOfWeek;
    },
    getCurrentDate() {
      return moment();
    },
    isExpired(dateValue) {
      const current = moment().utc();
      const dateToCheck = moment.utc(dateValue);

      return dateToCheck.diff(current) < 0;
    },
    getPromotionExpiredDate(dateValue) {
      const dateToCheck = moment(dateValue);
      const crutchDate = moment('3000-01-01T00:00:00+03:00');

      return crutchDate.isSame(dateToCheck) ? null : dateToCheck;
    },
    mergeDateTime(dateValue, time) {
      const newDate = moment(dateValue);
      const newTime = moment(time);

      return moment(`${newDate.format('DD/MM/YYYY')} ${newTime.format('HH:mm')}`, 'DD/MM/YYYY HH:mm');
    },
    formatToSend(dateValue) {
      return moment(dateValue).format();
    },
    dayStart(dateValue) {
      return moment(dateValue).format('YYYY-MM-DD 00:00:00');
    },
    dayEnd(dateValue) {
      return moment(dateValue).format('YYYY-MM-DD 23:59:59');
    },
    now() {
      return moment();
    },
    normalizeDate(dateValue, format) {
      let delimiter;
      if (format.replaceAll('.', '###').length > format.length) {
        delimiter = '.';
      } else if (format.replaceAll('/', '###').length > format.length) {
        delimiter = '/';
      } else {
        delimiter = '-';
      }
      const formatParts = format.split(delimiter);
      const dateParts = dateValue.split(delimiter);

      const dateObject = formatParts.reduce((collected, part, index) => {
        collected[part] = dateParts[index];
        return collected;
      }, {});

      return moment(`${dateObject.YYYY}-${dateObject.MM}-${dateObject.DD}`);
    },
    getLastMonthStart() {
      return moment()
        .subtract(1, 'months')
        .startOf('month');
    },
    getLastMonthEnd() {
      return moment()
        .subtract(1, 'months')
        .endOf('month');
    },
    getLastWeekStart() {
      return moment()
        .subtract(1, 'week')
        .startOf('week')
        .add(1, 'day');
    },
    getLastWeekEnd() {
      return moment()
        .subtract(1, 'week')
        .endOf('week')
        .add(1, 'day');
    },

    getDateStringFromDate(date, format = 'YYYY-MM-DD HH:mm:ss') {
      return moment(date).format(format);
    },

    getDateObject(date) {
      return new Date(date);
    },

    getDateForSummary(value) {
      const { getters } = vm.$store;
      const locale = getters?.getLocale ?? 'en';

      let mappedValue = moment(value).format('YYYY-MM-DD');
      if (locale === 'ru') {
        mappedValue = moment(mappedValue).locale('ru').format('MMM DD, YYYY');
        mappedValue = mappedValue.charAt(0).toUpperCase() + mappedValue.slice(1);
      }

      return mappedValue;
    },
  };
}
