<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      d="M12.0011 22.5C10.3091 22.5 9.00106 21.625 9.00106 20H15.0011C15.0011 21.625 13.6941 22.5 12.0011 22.5ZM17.9951 14.71C17.9951 16.424 20.1431 16.853 20.1431 17.71C20.1431 18.567 19.7141 18.995 18.0001 18.995H6.00006C4.28606 18.995 3.85706 18.567 3.85706 17.71C3.85706 16.853 6.00006 16.424 6.00006 14.71V11.71C6.00006 7.424 7.71406 3.857 11.1431 3.857C11.1431 3.214 11.5711 3 12.0001 3C12.4291 3 12.8571 3.214 12.8571 3.857C16.2861 3.857 17.9951 7.424 17.9951 11.71V14.71Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSBellIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>

<style scoped>

</style>
