
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  watch,
} from 'vue';
import EColorScheme from '@/module/common/models/EColorScheme';

interface IState {
  localValue: boolean,
}

export default defineComponent({
  name: 'DSSwitcher',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String as PropType<EColorScheme>,
      default: () => EColorScheme.SECONDARY,
    },
    prevent: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const state: IState = reactive<IState>({
      localValue: false,
    });

    const classes = computed(() => [
      'ds-switcher',
      props.disabled ? 'ds-switcher_disabled' : null,
      `ds-switcher_color_${props.color}`,
    ]);

    function handleChange() {
      if (props.disabled) {
        return;
      }

      const localValue = !state.localValue;

      emit('input', localValue);
      emit('change', localValue);

      if (!props.prevent) {
        state.localValue = localValue;
      }
    }

    onMounted(() => {
      state.localValue = props.value;
    });

    watch(props, (newValue) => {
      state.localValue = newValue.value;
    });

    return {
      state,
      classes,
      handleChange,
    };
  },
});
