import ISoftwareRegistryHandler from '@/module/product/view/ProductFormPage/handler/contract/ISoftwareRegistryHandler';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import EColorScheme from '@/module/design-system/components/models/EColorScheme';
import EIcon from '@/module/design-system/components/models/EIcon';
import ESoftwareRegistryAlertStatus from '@/module/product/view/ProductFormPage/model/ESoftwareRegistryAlertStatus';
import IFieldStatusHandler
  from '@/module/common/components/FormComponent/ValidatorHandler/contract/IFieldStatusHandler';

class SoftwareRegistryHandler implements ISoftwareRegistryHandler {
  private serviceContainer: IServiceContainer;

  private readonly simpleFieldStatusHandler: IFieldStatusHandler;

  private readonly emptyFieldStatusHandler: IFieldStatusHandler;

  constructor(
    serviceContainer: IServiceContainer,
    simpleFieldStatusHandler: IFieldStatusHandler,
    emptyFieldStatusHandler: IFieldStatusHandler,
  ) {
    this.serviceContainer = serviceContainer;
    this.simpleFieldStatusHandler = simpleFieldStatusHandler;
    this.emptyFieldStatusHandler = emptyFieldStatusHandler;
  }

  addSoftwareRegistry = async () => {
    const state = this.serviceContainer.moduleProduct.productFormPage.getState();
    const formDataHandler = this.serviceContainer.moduleProduct.productFormPage.handlerService.formDataHandler;
    const validateHandler = this.serviceContainer.moduleProduct.productFormPage.handlerService.validateHandler;
    const api = this.serviceContainer.apiService.softwareRegistry;

    state.softwareRegistryLoading = true;
    const formData = formDataHandler.get();

    validateHandler.validateNumberField(state.tmpSoftwareRegistry.number, this.simpleFieldStatusHandler);
    validateHandler.validateLinkField(state.tmpSoftwareRegistry.link, this.simpleFieldStatusHandler);

    if (!validateHandler.isSoftwareRegistryValid(state.softwareRegistryStatus)) {
      state.softwareRegistryLoading = false;

      return;
    }

    const registrationNumber = state.tmpSoftwareRegistry.number;
    api.get('', { params: { registrationNumber } })
      .then((response) => {
        formData.softwareRegistry = {
          url: state.tmpSoftwareRegistry.link,
          registrationNumber,
          date: response.data.inclusionDate,
          status: true,
        };

        state.softwareRegistryModal = null;

        this.clearSoftwareRegistry();

        this.showSwitcherAlert(ESoftwareRegistryAlertStatus.SUCCESS);
      }).catch((response) => {
        let status = ESoftwareRegistryAlertStatus.SERVER_ERROR;
        if (response.response?.status === 422) {
          status = ESoftwareRegistryAlertStatus.ERROR;
        }

        if (!state.softwareRegistryModal) {
          this.showSwitcherAlert(status);
        } else {
          this.showModalAlert(status);
        }

        formData.softwareRegistry = {
          status: false,
        };
      }).finally(() => {
        state.softwareRegistryLoading = false;

        formDataHandler.update(formData);
      });
  };

  blurField = (fieldName: string, value: string) => {
    const handlers = {
      link: this.blurLinkField,
      number: this.blurNumberField,
    };

    if (handlers[fieldName]) {
      handlers[fieldName](value);
    }
  };

  closeModal = () => {
    const state = this.serviceContainer.moduleProduct.productFormPage.getState();

    state.softwareRegistryModal = null;
  };

  delete = () => {
    const productFormPage = this.serviceContainer.moduleProduct.productFormPage;
    const state = productFormPage.getState();
    const formDataHandler = productFormPage.handlerService.formDataHandler;

    const formData = formDataHandler.get();
    formData.softwareRegistry = {
      status: false,
    };

    state.softwareRegistrySwitcherAlert = null;
    state.softwareRegistryModalAlert = null;

    formDataHandler.update(formData);

    this.closeModal();
  };

  enableSoftwareRegistry = (value: boolean) => {
    const state = this.serviceContainer.moduleProduct.productFormPage.getState();
    const localisationRegistry = this.serviceContainer.localisationRegistry;
    const modals = localisationRegistry.productsForm.modals;

    state.blockRegistryDataSwitcher = false;

    if (value) {
      this.clearSoftwareRegistry();

      state.softwareRegistryModal = {
        variant: 'softwareRegistry',
        title: modals.softwareRegistry.softwareRegistry.title,
        text: '',
        fields: {
          link: {
            label: modals.softwareRegistry.softwareRegistry.inputs.link.label,
            placeholder: modals.softwareRegistry.softwareRegistry.inputs.link.placeholder,
          },
          number: {
            label: modals.softwareRegistry.softwareRegistry.inputs.number.label,
            placeholder: modals.softwareRegistry.softwareRegistry.inputs.number.placeholder,
            maskOptions: {
              mask: '000000000',
            },
          },
        },
        button: {
          stretch: 'xxl',
          text: modals.softwareRegistry.softwareRegistry.buttons.add,
        },
      };
    } else {
      state.softwareRegistryModal = {
        variant: 'confirmSwitchOff',
        title: modals.softwareRegistry.confirmSwitchOff.title,
        text: modals.softwareRegistry.confirmSwitchOff.text,
        buttons: {
          keep: {
            text: modals.softwareRegistry.confirmSwitchOff.buttons.keep,
            stretch: 'xxl',
            variant: 'secondary',
          },
          delete: {
            text: modals.softwareRegistry.confirmSwitchOff.buttons.delete,
            stretch: 'xxl',
          },
        },
      };
    }
  };

  keep = () => {
    this.closeModal();
  };

  disableSwitcher = () => {
    const state = this.serviceContainer.moduleProduct.productFormPage.getState();
    state.blockRegistryDataSwitcher = true;
  };

  private clearSoftwareRegistry = () => {
    const state = this.serviceContainer.moduleProduct.productFormPage.getState();

    state.tmpSoftwareRegistry = {
      link: '',
      number: '',
    };

    state.softwareRegistryStatus = {
      link: {
        code: EValidationFieldStatusCode.DEFAULT,
      },
      number: {
        code: EValidationFieldStatusCode.DEFAULT,
      },
    };
  };

  private blurLinkField = (value: string) => {
    const state = this.serviceContainer.moduleProduct.productFormPage.getState();
    const validateHandler = this.serviceContainer.moduleProduct.productFormPage.handlerService.validateHandler;

    validateHandler.validateLinkField(value, this.emptyFieldStatusHandler);

    state.tmpSoftwareRegistry.link = value;
  };

  private blurNumberField = (value: string) => {
    const state = this.serviceContainer.moduleProduct.productFormPage.getState();
    const validateHandler = this.serviceContainer.moduleProduct.productFormPage.handlerService.validateHandler;

    validateHandler.validateNumberField(value, this.emptyFieldStatusHandler);

    state.tmpSoftwareRegistry.number = value;
  };

  private showSwitcherAlert = (
    status: ESoftwareRegistryAlertStatus,
  ) => {
    const alert = this.getModalConfig(status);
    const state = this.serviceContainer.moduleProduct.productFormPage.getState();

    if (alert) {
      state.softwareRegistrySwitcherAlert = alert;
    }
  };

  private showModalAlert = (
    status: ESoftwareRegistryAlertStatus,
  ) => {
    const alert = this.getModalConfig(status);
    const state = this.serviceContainer.moduleProduct.productFormPage.getState();

    if (alert) {
      state.softwareRegistryModalAlert = alert;
    }
  };

  private getModalConfig = (status: ESoftwareRegistryAlertStatus) => {
    const localisationRegistry = this.serviceContainer.localisationRegistry;

    let alertData;
    switch (status) {
      case ESoftwareRegistryAlertStatus.SUCCESS:
        alertData = {
          text: localisationRegistry.productsForm.softwareRegistry.alert.success.text,
          variant: EColorScheme.SUCCESS,
          icon: {
            color: EColorScheme.SUCCESS,
            icon: EIcon.CHECK_CIRCLE_FILLED,
          },
        };
        break;
      case ESoftwareRegistryAlertStatus.ERROR:
        alertData = {
          title: localisationRegistry.productsForm.softwareRegistry.alert.error.title,
          text: localisationRegistry.productsForm.softwareRegistry.alert.error.text,
          variant: EColorScheme.ERROR,
          icon: {
            color: EColorScheme.ERROR,
          },
        };
        break;
      case ESoftwareRegistryAlertStatus.SERVER_ERROR:
        alertData = {
          title: localisationRegistry.productsForm.softwareRegistry.alert.serverError.title,
          text: localisationRegistry.productsForm.softwareRegistry.alert.serverError.text,
          variant: EColorScheme.ERROR,
          icon: {
            color: EColorScheme.ERROR,
          },
        };
        break;
      default:
        break;
    }

    return alertData;
  };
}

export default SoftwareRegistryHandler;
