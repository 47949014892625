import cloneImmutable from '@/module/common/utils/data/cloneImmutable';
import improvedTrim from './improvedTrim';

function mapPrices(renewalPrice) {
  return renewalPrice.map((variant) => {
    const pricesArray = Object.entries(variant.price);
    const newVariant = { ...variant };

    const filteredPricesArray = pricesArray.filter((element) => element[1].price);
    const mappedPricesArray = filteredPricesArray.map((priceItem) => {
      priceItem[1].price = priceItem[1].price.replace(',', '.');
      return priceItem;
    });
    newVariant.price = Object.fromEntries(mappedPricesArray);
    newVariant.from = Number(variant.from);
    newVariant.to = Number(variant.to);
    newVariant.sku = improvedTrim(newVariant.sku);

    if (!variant.to) delete newVariant.to;
    if (!variant.from) delete newVariant.from;
    if (!variant.sku) delete newVariant.sku;

    return newVariant;
  });
}

export default class CreateProductDTO {
  constructor(formData, pricingModelCode) {
    this.formData = cloneImmutable(formData);
    this.pricingModelCode = pricingModelCode;
  }

  getMain() {
    const {
      imageUrl,
      licenseType,
      title,
      subtitle,
      description,
      availableFor,
    } = this.formData;

    const image = imageUrl;

    const main = {
      imageUrl: image,
      licenseType,
      title: improvedTrim(title),
      subtitle: improvedTrim(subtitle),
      description: improvedTrim(description),
      availableFor,
    };

    if (!image) delete main.imageUrl;
    if (!description) delete main.description;

    return main;
  }

  getLicenseTerm() {
    const {
      licenseTermCheckbox,
      licenseTermAmount,
      licenseTermType,
    } = this.formData;

    if (!licenseTermCheckbox) return { licenseTerm: '0' };

    return {
      licenseTerm: `P${licenseTermAmount}${licenseTermType[0]}`,
    };
  }

  getTrial() {
    const {
      trialCheckbox,
      trialSku,
      trialTermAmount,
      trialTermType,
      trialTitle,
      trialSubtitle,
    } = this.formData;

    if (!trialCheckbox) {
      return { trialCheckbox };
    }

    const trialData = {
      trialCheckbox,
      trialTitle: improvedTrim(trialTitle),
      trialSubtitle: improvedTrim(trialSubtitle),
      trialSku: improvedTrim(trialSku),
      trialTerm: `P${trialTermAmount}${trialTermType[0]}`,
    };

    if (!improvedTrim(trialSku)) delete trialData.trialSku;

    return trialData;
  }

  getRenewal() {
    const {
      renewalCheckbox,
      renewalTitle,
      renewalSubtitle,
      renewalTermAmount,
      renewalTermType,
    } = this.formData;

    if (!renewalCheckbox) {
      return { renewalCheckbox };
    }

    const renewalPrice = this.getRenewalPrice();

    return {
      renewalCheckbox,
      renewalTitle: improvedTrim(renewalTitle),
      renewalSubtitle: improvedTrim(renewalSubtitle),
      renewalTerm: `P${renewalTermAmount}${renewalTermType[0]}`,
      renewalPrice,
    };
  }

  getPrice() {
    return mapPrices(this.formData.price[this.pricingModelCode]);
  }

  getRenewalPrice() {
    return mapPrices(this.formData.renewalPrice[this.pricingModelCode]);
  }

  getNewProduct() {
    const main = this.getMain();
    const price = this.getPrice();
    const licenseTerm = this.getLicenseTerm();
    const trial = this.getTrial();
    const renewal = this.getRenewal();

    return {
      ...main,
      ...licenseTerm,
      price,
      ...trial,
      ...renewal,
    };
  }
}
