import moment from 'moment';

export default () => ({
  namespaced: true,
  state: {
    date: {
      dateFrom: moment().subtract(30, 'days').toDate(),
      dateTo: moment().subtract(1, 'days').toDate(),
    },
    comparisonDate: {
      dateFrom: new Date(),
      dateTo: new Date(),
    },
    selectionMode: 'L30D',
    comparisonSelectionMode: 'NC',
    dimension: 'daily',
    widgets: [],
    dataUpdateDate: new Date(),
    isCompareAllowed: true,
  },
  getters: {
    getDate(state) {
      return state.date;
    },
    getComparisonDate(state) {
      return state.comparisonDate;
    },
    getSelectionMode(state) {
      return state.selectionMode;
    },
    getComparisonSelectionMode(state) {
      return state.comparisonSelectionMode;
    },
    getDimension(state) {
      return state.dimension;
    },
    getWidgets(state) {
      return state.widgets;
    },
    getIsCompareAllowed(state) {
      return state.isCompareAllowed;
    },
    getDataUpdateDate(state) {
      return state.dataUpdateDate;
    },
  },
  mutations: {
    setDate(state, payload) {
      state.date = payload;
    },
    setComparisonDate(state, payload) {
      state.comparisonDate = payload;
    },
    setSelectionMode(state, payload) {
      state.selectionMode = payload;
    },
    setComparisonSelectionMode(state, payload) {
      state.comparisonSelectionMode = payload;
    },
    setDimension(state, payload) {
      state.dimension = payload;
    },
    setWidgets(state, payload) {
      state.widgets = payload;
    },
    setIsCompareAllowed(state, payload) {
      state.isCompareAllowed = payload;
    },
    setDataUpdateDate(state, payload) {
      state.dataUpdateDate = payload;
    },
  },
  actions: {
    async updateFilter({ commit }, filter) {
      const {
        date,
        comparisonDate,
        selectionMode,
        comparisonSelectionMode,
        dimension,
      } = filter;

      commit('setDate', date);
      commit('setComparisonDate', comparisonDate);
      commit('setSelectionMode', selectionMode);
      commit('setComparisonSelectionMode', comparisonSelectionMode);
      commit('setDimension', dimension);
    },
    updateIsCompareAllowed({ commit }, isCompareAllowed) {
      commit('setIsCompareAllowed', isCompareAllowed);
    },
    updateDataUpdateDate({ commit }, date) {
      commit('setDataUpdateDate', date);
    },
    updateWidgets({ commit }, widgets) {
      commit('setWidgets', widgets);
    },
  },
});
