function updatePreloadersState(getters, commit, fieldName, value) {
  const preloaders = getters.getPreloaders;
  preloaders[fieldName] = value;

  commit('setPreloaders', { ...preloaders });
}

export default (serviceContainer) => ({
  namespaced: true,
  state: {
    productTotalCount: 0,
    promotionsTotalCount: 0,
    isSavingInProgress: false,
    isSaveError: false,
    preloaders: {
      products: true,
      promotion: true,
      promotionCode: false,
    },
    productList: [],
    promotionsList: [],
    selectedProductList: [],
  },
  mutations: {
    setProductTotalCount(state, payload) {
      state.productTotalCount = payload;
    },
    setPromotionsTotalCount(state, payload) {
      state.promotionsTotalCount = payload;
    },
    setProductList(state, payload) {
      state.productList = payload;
    },
    setPromotionsList(state, payload) {
      state.promotionsList = payload;
    },
    setSelectedProductList(state, payload) {
      state.selectedProductList = payload;
    },
    setPreloaders(state, payload) {
      state.preloaders = payload;
    },
    setIsSavingInProgress(state, payload) {
      state.isSavingInProgress = payload;
    },
    setIsSaveError(state, payload) {
      state.isSaveError = payload;
    },
  },
  getters: {
    getProductTotalCount(state) {
      return state.productTotalCount;
    },
    getPromotionsTotalCount(state) {
      return state.promotionsTotalCount;
    },
    getPromotionsList(state) {
      return state.promotionsList;
    },
    getProductList(state) {
      return state.productList;
    },
    getSelectedProductList(state) {
      return state.selectedProductList;
    },
    getPreloaders(state) {
      return state.preloaders;
    },
    getIsSavingInProgress(state) {
      return state.isSavingInProgress;
    },
    getIsSaveError(state) {
      return state.isSaveError;
    },
  },
  actions: {
    async refreshProductList({ commit, getters }, options) {
      updatePreloadersState(getters, commit, 'products', true);

      const api = serviceContainer.resolve('api');

      const response = await api.product.product.list(options);

      commit('setProductTotalCount', response?.data?.totalCount ?? null);
      commit('setProductList', response?.data?.products ?? []);

      updatePreloadersState(getters, commit, 'products', false);
    },
    async refreshPromotionsList({ commit, getters }, options) {
      updatePreloadersState(getters, commit, 'promotion', true);

      const api = serviceContainer.resolve('api');

      options.coupon = 'available';
      const response = await api.promotions.promotions.list(options);

      commit('setPromotionsTotalCount', response?.data?.totalCount ?? 0);
      commit('setPromotionsList', response?.data?.promotions ?? []);

      updatePreloadersState(getters, commit, 'promotion', false);
    },
    async updateProductList({ commit, getters }, options) {
      updatePreloadersState(getters, commit, 'products', true);

      const api = serviceContainer.resolve('api');

      const response = await api.product.product.list(options);
      const localProductList = getters.getProductList.concat(response?.data?.products ?? []);

      commit('setProductTotalCount', response?.data?.totalCount ?? null);
      commit('setProductList', localProductList);

      updatePreloadersState(getters, commit, 'products', false);
    },
    async updatePromotionsList({ commit, getters }, options) {
      updatePreloadersState(getters, commit, 'promotion', true);

      const api = serviceContainer.resolve('api');

      const response = await api.promotions.promotions.list(options);
      const localProductList = getters.getPromotionsList.concat(response?.data?.promotions ?? []);

      commit('setPromotionsTotalCount', response?.data?.totalCount ?? null);
      commit('setPromotionsList', localProductList);

      updatePreloadersState(getters, commit, 'promotion', false);
    },
    updateSelectedProductList({ commit }, data) {
      commit('setSelectedProductList', data);
    },
    async saveCheckoutLink({ commit }, data) {
      commit('setIsSavingInProgress', true);

      const {
        formData,
        link,
        isEdit,
        id,
      } = data;

      const isCustomPrices = formData.customPrices && formData.promotionCode?.couponCode?.length > 0;

      const parameters = {
        price_groups: formData.products.map(({ priceGroup, autoRenewAvailable }) => {
          const priceGroupInfo = {
            price_group_id: priceGroup.priceGroupId,
            quantity: parseInt(priceGroup.quantity, 10),
          };

          if (priceGroup.autoRenew !== undefined && autoRenewAvailable) {
            priceGroupInfo.auto_renew = priceGroup.autoRenew;
          }

          return priceGroupInfo;
        }),
        empty_customer_cart: formData.emptyCustomerCart,
        custom_prices: isCustomPrices,
        tracking: formData.tracking,
      };

      if (formData.orderCurrency.id) {
        parameters.currency = formData.orderCurrency.id;
      }

      if (isCustomPrices) {
        parameters.promotion_id = formData.promotion.promotionId;
        parameters.coupon_code = formData.promotionCode.couponCode;
      }

      if (formData.tracking && formData.referer.length > 0) {
        parameters.refers = formData.referer.reduce((refererList, item, index) => {
          refererList[`referer${index + 1}`] = item.value;

          return refererList;
        }, {});
      }

      const payload = {
        id,
        parameters,
        url: link,
        name: formData.name,
      };

      const api = serviceContainer.resolve('api');

      try {
        commit('setIsSaveError', false);
        if (isEdit) {
          await api.product.checkoutLink.update(payload);
        } else {
          await api.product.checkoutLink.create(payload);
        }
      } catch (e) {
        commit('setIsSaveError', true);
      }

      commit('setIsSavingInProgress', false);
    },
  },
});
