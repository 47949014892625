<template>
  <div
    :class="classes"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CFloatingElement',
  props: {
    positionX: {
      type: String,
      default: 'right',
      validator: (value) => [
        'left',
        'right',
      ].includes(value),
    },
  },
  computed: {
    classes() {
      return [
        'c-floating-element',
        `c-floating-element_${this.positionX}`,
      ];
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import '../variables';

.c-floating-element {
  position: fixed;
  bottom: 32px;
  z-index: 9;

  &_left {
    margin-left: 32px;
  }

  &_right {
    right: 32px;
  }
}

@media screen and (max-width: $breakpoint-md) {
  .c-floating-element {
    bottom: 16px;

    &_left {
      margin-left: 16px;
    }

    &_right {
      right: 16px;
    }
  }
}
</style>
