<template>
  <div
    class="c-button-group"
  >
    <CLabel
      v-if="label"
      :text="label"
      :tooltip="tooltip"
      class="c-button-group__label"
    />
    <DSTooltip
      v-if="isOnButtonsTooltip"
      :text="tooltip"
    >
      <DSButtonGroup
        v-model="activeButton"
        :button-list="buttonList"
        :variant="variant"
        class="c-button-group__input"
        @click="handleClick"
      />
    </DSTooltip>
    <DSButtonGroup
      v-else
      v-model="activeButton"
      :button-list="buttonList"
      :variant="variant"
      class="c-button-group__input"
      @click="handleClick"
    />
    <DSTextElement
      v-if="description"
      class="c-button-group__comment"
      color="secondary"
      variant="small"
    >
      <!-- eslint-disable vue/no-v-html -->
      <span
        v-html="description"
      />
      <!-- eslint-enable vue/no-v-html -->
    </DSTextElement>
  </div>
</template>

<script>
import CLabel from '@/module/common/components/UI/CLabel.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import DSButtonGroup from '@/module/design-system/components/UI/DSButtonGroup.vue';
import DSTooltip from '@/module/design-system/components/InfoBlock/DSTooltip.vue';

export default {
  name: 'CButtonGroup',
  components: {
    DSTooltip,
    CLabel,
    DSButtonGroup,
    DSTextElement,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    buttonList: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    variant: {
      type: String,
      default: 'stuck',
    },
    tooltip: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  computed: {
    isOnButtonsTooltip() {
      return this.tooltip && this.tooltip.length > 0 && (!this.label || this.label.length === 0);
    },
  },
  data() {
    return {
      activeButton: {},
    };
  },
  watch: {
    value() {
      this.activeButton = this.value;
    },
  },
  mounted() {
    this.activeButton = this.value;
  },
  methods: {
    handleClick(value) {
      this.$emit('input', { ...value });
      this.$emit('blur', { ...value });
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.c-button-group {
  &__label {
    margin-bottom: 8px;
  }

  &__comment {
    display: block;
    margin-top: 8px;
  }
}
</style>
