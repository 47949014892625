<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      clip-rule="evenodd"
      d="M6 8.5C4.9 8.5 4 7.6 4 6.5C4 5.4 4.9 4.5 6 4.5C7.1 4.5 8 5.4 8 6.5C8 7.6 7.1 8.5 6 8.5Z"
      fill-rule="evenodd"
    />
    <path
      :fill="color"
      clip-rule="evenodd"
      d="M12 8.5C10.9 8.5 10 7.6 10 6.5C10 5.4 10.9 4.5 12 4.5C13.1 4.5 14 5.4 14 6.5C14 7.6 13.1 8.5 12 8.5Z"
      fill-rule="evenodd"
    />
    <path
      :fill="color"
      clip-rule="evenodd"
      d="M18 8.5C16.9 8.5 16 7.6 16 6.5C16 5.4 16.9 4.5 18 4.5C19.1 4.5 20 5.4 20 6.5C20 7.6 19.1 8.5 18 8.5Z"
      fill-rule="evenodd"
    />
    <path
      :fill="color"
      clip-rule="evenodd"
      d="M6 14.5C4.9 14.5 4 13.6 4 12.5C4 11.4 4.9 10.5 6 10.5C7.1 10.5 8 11.4 8 12.5C8 13.6 7.1 14.5 6 14.5Z"
      fill-rule="evenodd"
    />
    <path
      :fill="color"
      clip-rule="evenodd"
      d="M12 14.5C10.9 14.5 10 13.6 10 12.5C10 11.4 10.9 10.5 12 10.5C13.1 10.5 14 11.4 14 12.5C14 13.6 13.1 14.5 12 14.5Z"
      fill-rule="evenodd"
    />
    <path
      :fill="color"
      clip-rule="evenodd"
      d="M18 14.5C16.9 14.5 16 13.6 16 12.5C16 11.4 16.9 10.5 18 10.5C19.1 10.5 20 11.4 20 12.5C20 13.6 19.1 14.5 18 14.5Z"
      fill-rule="evenodd"
    />
    <path
      :fill="color"
      clip-rule="evenodd"
      d="M6 20.5C4.9 20.5 4 19.6 4 18.5C4 17.4 4.9 16.5 6 16.5C7.1 16.5 8 17.4 8 18.5C8 19.6 7.1 20.5 6 20.5Z"
      fill-rule="evenodd"
    />
    <path
      :fill="color"
      clip-rule="evenodd"
      d="M12 20.5C10.9 20.5 10 19.6 10 18.5C10 17.4 10.9 16.5 12 16.5C13.1 16.5 14 17.4 14 18.5C14 19.6 13.1 20.5 12 20.5Z"
      fill-rule="evenodd"
    />
    <path
      :fill="color"
      clip-rule="evenodd"
      d="M18 20.5C16.9 20.5 16 19.6 16 18.5C16 17.4 16.9 16.5 18 16.5C19.1 16.5 20 17.4 20 18.5C20 19.6 19.1 20.5 18 20.5Z"
      fill-rule="evenodd"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSDotsIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>

<style scoped>

</style>
