<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.2727 11.0127L9.46091 15.8245C9.37705 15.9114 9.27673 15.9806 9.16582 16.0283C9.05491 16.0759 8.93562 16.101 8.81491 16.102C8.6942 16.1031 8.57449 16.0801 8.46277 16.0344C8.35105 15.9887 8.24954 15.9212 8.16419 15.8358C8.07883 15.7505 8.01133 15.649 7.96562 15.5372C7.91991 15.4255 7.89691 15.3058 7.89795 15.1851C7.899 15.0644 7.92408 14.9451 7.97173 14.8342C8.01937 14.7233 8.08863 14.623 8.17546 14.5391L12.9873 9.72727H9.72727C9.48617 9.72727 9.25494 9.63149 9.08445 9.46101C8.91396 9.29052 8.81818 9.05929 8.81818 8.81818C8.81818 8.57708 8.91396 8.34585 9.08445 8.17536C9.25494 8.00487 9.48617 7.90909 9.72727 7.90909H15.1818C15.4229 7.90909 15.6542 8.00487 15.8246 8.17536C15.9951 8.34585 16.0909 8.57708 16.0909 8.81818V14.2727C16.0909 14.5138 15.9951 14.7451 15.8246 14.9156C15.6542 15.086 15.4229 15.1818 15.1818 15.1818C14.9407 15.1818 14.7095 15.086 14.539 14.9156C14.3685 14.7451 14.2727 14.5138 14.2727 14.2727V11.0127ZM12 22C6.47727 22 2 17.5227 2 12C2 6.47727 6.47727 2 12 2C17.5227 2 22 6.47727 22 12C22 17.5227 17.5227 22 12 22ZM12 20.3636C14.2182 20.3636 16.3455 19.4825 17.914 17.914C19.4825 16.3455 20.3636 14.2182 20.3636 12C20.3636 9.78183 19.4825 7.6545 17.914 6.08602C16.3455 4.51753 14.2182 3.63636 12 3.63636C9.78183 3.63636 7.6545 4.51753 6.08602 6.08602C4.51753 7.6545 3.63636 9.78183 3.63636 12C3.63636 14.2182 4.51753 16.3455 6.08602 17.914C7.6545 19.4825 9.78183 20.3636 12 20.3636Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSDataExportsIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>

<style scoped>

</style>
