import { render, staticRenderFns } from "./ScrollTopButton.vue?vue&type=template&id=42d6f22b&"
import script from "./ScrollTopButton.vue?vue&type=script&lang=js&"
export * from "./ScrollTopButton.vue?vue&type=script&lang=js&"
import style0 from "./ScrollTopButton.vue?vue&type=style&index=0&id=42d6f22b&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports