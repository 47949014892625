
import Vue, {
  computed,
  PropType,
} from 'vue';

import EDSButtonVariant from '@/module/design-system/models/UI/DSButton/EDSButtonVariant';

import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import EBreakPoint from '@/module/design-system/components/models/EBreakPoint';
import EDSButtonSize from '@/module/design-system/models/UI/DSButton/EDSButtonSize';

// @TODO перевести все сборки конфигов для DSButton на getButton
export default Vue.extend({
  name: 'DSButton',
  components: {
    DSTextElement,
  },
  props: {
    variant: {
      type: String as PropType<EDSButtonVariant | string>,
      default: EDSButtonVariant.PRIMARY,
    },
    size: {
      type: String as PropType<EDSButtonSize | string>,
      default: EDSButtonSize.DEFAULT,
    },
    value: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showTextAfterBreakpoint: {
      type: String as PropType<EBreakPoint | string>,
      default: null,
    },
    stretch: {
      type: String as PropType<EBreakPoint | string>,
      default: null,
    },
  },
  setup(props) {
    const classes = computed(() => [
      'ds-button',
      `ds-button_variant_${props.variant}`,
      props.disabled ? `ds-button_variant_${props.variant}_disabled` : '',
      `ds-button_size_${props.size}`,
      `ds-button_hide-text-before_${props.showTextAfterBreakpoint}`,
      props.stretch ? `ds-button_stretch_${props.stretch}` : null,
    ]);

    const textColor = computed(() => {
      const textColorMap = {
        primary: 'primary-button',
        commerce: 'primary-button',
        secondary: props.disabled ? 'disabled' : 'linked',
        tretiary: props.disabled ? 'disabled' : 'linked',
        'tretiary-gray': props.disabled ? 'disabled' : 'primary',
        'secondary-negative': props.disabled ? 'disabled' : 'error',
        'tretiary-negative': props.disabled ? 'disabled' : 'error',
      };

      return textColorMap[props.variant] ?? 'primary';
    });

    const textClasses = computed(() => [
      'ds-button__text',
    ]);

    return {
      classes,
      textColor,
      textClasses,
    };
  },
});
