
import Vue, { computed, inject } from 'vue';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import VueRouter from 'vue-router';

export default Vue.extend({
  name: 'BaseComponentOverviewPage',
  setup() {
    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;
    const router = serviceContainer.resolve<VueRouter>(EServiceContainerInstances.ROUTER);
    const componentDemoRouteTree = router.options.routes?.find((route) => route.name === 'component-demo');

    const menu = computed(() => {
      if (componentDemoRouteTree?.children) {
        return componentDemoRouteTree.children
          .filter((rootRoute) => !['', '*'].includes(rootRoute.path))
          .map((rootRoute) => ({
            title: rootRoute.meta?.title ?? ' - ',
            name: rootRoute.name,
            path: `${componentDemoRouteTree.path}/${rootRoute.path}`,
            children: rootRoute?.children?.filter((subRoute) => !['*'].includes(subRoute.path))
              .map((subRoute) => ({
                title: subRoute.meta?.title ?? ' - ',
                name: subRoute.name,
                path: `${componentDemoRouteTree.path}/${rootRoute.path}/${subRoute.path}`,
                children: subRoute?.children?.filter((concreteRoute) => !['*'].includes(concreteRoute.path))
                  .map((concreteRoute) => ({
                    title: concreteRoute.meta?.title ?? ' - ',
                    path: `${componentDemoRouteTree.path}/${rootRoute.path}/${subRoute.path}/${concreteRoute.path}`,
                    name: concreteRoute.name,
                  })) ?? [],
              })) ?? [],
          }));
      }

      return [];
    });

    return {
      menu,
    };
  },
});
