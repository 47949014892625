import IFormDataHandler from '@/module/product/view/ProductFormPage/handler/contract/IFormDataHandler';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import TFormData from '@/module/product/model/FormData/TFormData';
import EPricingModelName from '@/module/product/model/Product/Fields/PricingModel/EPricingModelName';

class FormDataHandler implements IFormDataHandler {
  private serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  get = (): TFormData => {
    const utilsService = this.serviceContainer.utilsService;
    const productFromPage = this.serviceContainer.moduleProduct.productFormPage;

    return utilsService.data.cloneImmutable(productFromPage.getState().formData);
  };

  update = (formData: TFormData): TFormData => {
    const utilsService = this.serviceContainer.utilsService;
    const productFromPage = this.serviceContainer.moduleProduct.productFormPage;
    const state = productFromPage.getState();
    const formDataMap = utilsService.data.cloneImmutable(state.formDataMap);

    state.formData = utilsService.data.cloneImmutable(formData);

    [
      EPricingModelName.ONE_PRICE,
      EPricingModelName.VOLUME_PRICING,
      EPricingModelName.VOLUME_PRICING_AND_OPTIONS,
      EPricingModelName.ONE_PRICE_FOR_EACH_CURRENCY,
    ].forEach((pricingModel) => {
      if (pricingModel === formData.pricingModel.id) {
        formDataMap[pricingModel] = formData;
      } else if (formDataMap[pricingModel]) {
        formDataMap[pricingModel] = {
          ...formData,
          price: formDataMap[pricingModel].price,
          renewalPrice: formDataMap[pricingModel].renewalPrice,
        } as TFormData;
      }
    });

    state.formDataMap = formDataMap;

    return state.formData;
  };

  getByPricingModel = (pricingModel: EPricingModelName): TFormData | null => {
    const utilsService = this.serviceContainer.utilsService;
    const productFromPage = this.serviceContainer.moduleProduct.productFormPage;
    const formDataMap = utilsService.data.cloneImmutable(productFromPage.getState().formDataMap);

    return formDataMap[pricingModel] ?? null;
  };
}

export default FormDataHandler;
