import ucFirst from '@/module/common/utils/string/ucFirst';
import snakeToCamelCase from '@/module/common/utils/string/snakeToCamelCase';

export default function string() {
  return {
    ucFirst,
    snakeToCamelCase,
    replace(value, search, replace) {
      return value.replace(search, replace);
    },
    replaceAll(value, search, replace) {
      return value.replaceAll(search, replace);
    },
  };
}
