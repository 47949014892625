import {
  AxiosError,
} from 'axios';
import {
  Store,
} from 'vuex';

import ELicenseSourceName from '@/module/product/view/FulfillmentFormPage/model/enums/ELicenseSourceName';
import ELocalServiceFulfillment from '@/module/product/view/FulfillmentFormPage/model/enums/ELocalServiceFulfillment';
import ENotifyVariant from '@/module/design-system/components/models/UI/ENotifyVariant';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';

import IFormDataSalesCreateGenerator
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataSalesCreateGenerator';
import IFormDataTestCreateGenerator
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataTestCreateGenerator';
import IFulfillmentTestRequestApi from '@/module/common/service/API/contract/IFulfillmentTestRequestApi';
import IHandlerService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IHandlerService';
import ILocalServiceContainer from '@/module/product/view/FulfillmentFormPage/model/ILocalServiceContainer';
import IMapperService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IMapperService';
import IScenario from '@/module/product/view/FulfillmentFormPage/model/contract/scenario/IScenario';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IState from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/IState';
import ITestRequestResponse from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/ITestRequestResponse';

import TFormData from '@/module/product/view/FulfillmentFormPage/model/types/TFormData';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';

class TestGeneratorScenario implements IScenario {
  private readonly state: IState;

  private readonly serviceContainer: IServiceContainer;

  private readonly localServiceContainer: ILocalServiceContainer;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    localServiceContainer: ILocalServiceContainer,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.localServiceContainer = localServiceContainer;
  }

  run = async () => {
    const api = this.serviceContainer
      .resolve<IFulfillmentTestRequestApi>(EServiceContainerInstances.TEMP_FULFILLMENT_TEST_REQUEST_API);

    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const formDataHandler = handlerService.getFormDataHandler();
    const formData = formDataHandler.getData(this.state.licenseSourceName);
    const modalHandler = handlerService.getModalHandler();

    if (!this.isSalesCreateGenerator(formData) && !this.isTestCreateGenerator(formData)) {
      return;
    }

    const mapperService = this.localServiceContainer
      .resolve<IMapperService>(ELocalServiceFulfillment.MAPPER_SERVICE);
    const fromDataMapper = mapperService.getIFormDataToFulfillmentBackendModelMapper();

    const entity = fromDataMapper.mapTestRequest(formData);

    try {
      this.state.isTestRequestProcessing = true;
      const response = await api.fulfillmentTestRequest(entity);
      const data: ITestRequestResponse = response?.data as ITestRequestResponse ?? {} as ITestRequestResponse;

      this.state.modals.testRequest = modalHandler.getTestRequestModalConfig(data);
      this.state.isTestRequestVisible = true;
    } catch (e) {
      const error = e as AxiosError;

      if (error?.response?.status === 422) {
        const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
        const localisationRegistry: ILocalisationRegistry = store.getters.getLocalisationRegistry;
        const fulfillmentFormLocalisation = localisationRegistry.fulfillment.form;

        await store.dispatch(
          'notificationsStore/pushNotification',
          {
            title: fulfillmentFormLocalisation.notification.tooLongUrl.title ?? '',
            text: fulfillmentFormLocalisation.notification.tooLongUrl.text ?? '',
            type: ENotifyVariant.ERROR,
            component: 'DSPopup',
          },
        );
      }
    } finally {
      this.state.isTestRequestProcessing = false;
    }
  };

  private isSalesCreateGenerator = (formData: TFormData): formData is IFormDataSalesCreateGenerator => {
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const licenseSourceHandler = handlerService.getLicenseSourceHandler();
    const licenseSourceName = licenseSourceHandler.getLicenseSourceName(formData);

    return licenseSourceName === ELicenseSourceName.SALES_CREATE_GENERATOR;
  };

  private isTestCreateGenerator = (formData: TFormData): formData is IFormDataTestCreateGenerator => {
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const licenseSourceHandler = handlerService.getLicenseSourceHandler();
    const licenseSourceName = licenseSourceHandler.getLicenseSourceName(formData);

    return licenseSourceName === ELicenseSourceName.TEST_CREATE_GENERATOR;
  };
}

export default TestGeneratorScenario;
