function initPromotionTags(vue) {
  return {
    coupon: {
      get name() {
        return vue.$t('promotions.promotion_card.tags.coupon.title');
      },
      get tooltip() {
        return vue.$t('promotions.promotion_card.tags.coupon.tooltip');
      },
      color: '#4397CB',
      background: '#EBF7FF',
    },
    inactive: {
      get name() {
        return vue.$t('promotions.promotion_card.tags.inactive.title');
      },
      get tooltip() {
        return vue.$t('promotions.promotion_card.tags.inactive.tooltip');
      },
      color: '#000000',
      background: '#E5E5E5',
    },
    discount: {
      get name() {
        return vue.$t('promotions.promotion_card.tags.discount.title');
      },
      get tooltip() {
        return vue.$t('promotions.promotion_card.tags.discount.tooltip');
      },
      color: '#6F43CB',
      background: '#F1EBFF',
    },
    expired: {
      get name() {
        return vue.$t('promotions.promotion_card.tags.expired.title');
      },
      get tooltip() {
        return vue.$t('promotions.promotion_card.tags.expired.tooltip');
      },
      color: '#000000',
      background: '#FAE3DE',
    },
  };
}

export default initPromotionTags;
