import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import EDSFieldStatus from '@/module/design-system/models/UI/EDSFieldStatus';

function formFieldStatusMapper(status: EValidationFieldStatusCode): EDSFieldStatus {
  const map = {
    [EValidationFieldStatusCode.DEFAULT]: EDSFieldStatus.DEFAULT,
    [EValidationFieldStatusCode.ERROR]: EDSFieldStatus.ERROR,
    [EValidationFieldStatusCode.SUCCESS]: EDSFieldStatus.SUCCESS,
    [EValidationFieldStatusCode.EMPTY]: EDSFieldStatus.DEFAULT,
  };

  return map[status];
}

export default formFieldStatusMapper;
