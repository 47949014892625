<template>
  <section class="field-list">
    <div
      v-if="title || subtitle"
      class="field-list__header"
    >
      <DSHeader
        v-if="title"
        class="field-list__title"
        :size="3"
        :text="title"
      />
      <DSTextElement
        v-if="subtitle"
        class="field-list__subtitle"
        variant="small"
        color="secondary"
      >
        {{ subtitle }}
      </DSTextElement>
    </div>

    <slot />
  </section>
</template>

<script>
import DSHeader from '@/module/design-system/components/Text/DSHeader.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default {
  name: 'CFieldList',
  components: {
    DSHeader,
    DSTextElement,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.field-list {
  display: grid;
  row-gap: 32px;

  &__subtitle {
    display: block;
    margin-top: 4px;
  }
}
</style>
