import EBusinessType from '@/module/merchant/models/BuisnessType/EBusinessType';
import EFieldBlock from '@/module/merchant/view/AccountActivatePage/models/EFieldBlock';
import EFieldName from '@/module/merchant/view/AccountActivatePage/models/EFieldName';
import IKontragentioService from '@/module/merchant/view/AccountActivatePage/service/contract/IKontragentioService';

class KontragentioService implements IKontragentioService {
  getFieldsByBusinessType(businessType: EBusinessType) {
    const kontragentioFieldsMap = {
      [EBusinessType.COMPANY]: {
        [EFieldBlock.BUSINESS_STRUCTURE]: [
          EFieldName.ACTUAL_ADDRESS,
          EFieldName.LEGAL_ADDRESS,
        ],
        [EFieldBlock.BUSINESS_REPRESENTATIVE]: [
          EFieldName.JOB_TITLE,
          EFieldName.FIRST_NAME,
          EFieldName.LAST_NAME,
          EFieldName.MIDDLE_NAME,
        ],
        [EFieldBlock.BUSINESS_DETAILS]: [
          EFieldName.LEGAL_BUSINESS_NAME,
          EFieldName.DOING_BUSINESS_AS,
          EFieldName.OGRN,
          EFieldName.KPP,
          EFieldName.BUSINESS_WEBSITE,
          EFieldName.PRODUCT_DESCRIPTION,
        ],
        [EFieldBlock.ACCOUNT_FOR_PAYOUTS]: [
          EFieldName.BANK_NAME,
          EFieldName.BIK,
          EFieldName.KS,
        ],
      },
      [EBusinessType.INDIVIDUAL_ENTREPRENEUR]: {
        [EFieldBlock.BUSINESS_STRUCTURE]: [
          EFieldName.ACTUAL_ADDRESS,
          EFieldName.LEGAL_ADDRESS,
        ],
        [EFieldBlock.BUSINESS_REPRESENTATIVE]: [
          EFieldName.DATE_OF_BIRTH,
          EFieldName.FIRST_NAME,
          EFieldName.LAST_NAME,
          EFieldName.MIDDLE_NAME,
        ],
        [EFieldBlock.BUSINESS_DETAILS]: [
          EFieldName.SNILS,
          EFieldName.DOCUMENT_OGRNIP,
          EFieldName.LEGAL_BUSINESS_NAME,
        ],
      },
    };

    return kontragentioFieldsMap[businessType] ?? {};
  }
}

export default KontragentioService;
