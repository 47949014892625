export default (serviceContainer) => ({
  namespaced: true,
  state: {
    endpointURL: '',
    events: 'order.payment.succeeded',
    secretKey: null,
    endpointStatus: true,
  },
  mutations: {
    updateEndpointURL(state, newEndpointURL) {
      state.endpointURL = newEndpointURL;
    },
    updateEvents(state, newEvents) {
      state.events = newEvents;
    },
    updateSecretKey(state, newSecretKey) {
      state.secretKey = newSecretKey;
    },
    updateEndpointStatus(state, newEndpointStatus) {
      state.endpointStatus = newEndpointStatus;
    },
  },
  getters: {
    getEndpointURL(state) {
      return state.endpointURL;
    },
    getEvents(state) {
      return state.events;
    },
    getSecretKey(state) {
      return state.secretKey;
    },
    getPayload(state) {
      return {
        event: state.events,
        url: state.endpointURL,
        secretKey: state.secretKey,
        status: state.endpointStatus,
      };
    },
  },
  actions: {
    async createWebhook({ getters }) {
      try {
        const api = serviceContainer.resolve('api');

        const response = await api.webhooks.createEndpoint(getters.getPayload);

        return response.status;
      } catch (error) {
        if (error.response.data.title === 'Endpoint for event already exists.') {
          return 'alreadyExists';
        }

        return error.response.status;
      }
    },
    async updateWebhook({ getters }, webhookId) {
      try {
        const api = serviceContainer.resolve('api');

        const response = await api.webhooks.updateEndpoint(webhookId, getters.getPayload);

        return response.status;
      } catch (error) {
        if (error.response.data.title === 'Endpoint for event already exists.') {
          return 'alreadyExists';
        }
        return error.response.status;
      }
    },
    async loadWebhook({ commit }, webhookId) {
      try {
        const api = serviceContainer.resolve('api');

        const response = await api.webhooks.getEndpoint(webhookId);
        const {
          url, event, secret, status,
        } = response.data;
        commit('updateEndpointURL', url);
        commit('updateEvents', event);
        commit('updateSecretKey', secret);
        commit('updateEndpointStatus', status);

        return response.status;
      } catch (error) {
        return error.response.status;
      }
    },
    clearStore({ commit }) {
      commit('updateEndpointURL', '');
      commit('updateEvents', 'order.payment.succeeded');
      commit('updateSecretKey', null);
      commit('updateEndpointStatus', true);
    },
  },
});
