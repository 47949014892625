import IGeneratorMockService
  from '@/module/product/view/FulfillmentFormPage/model/contract/service/IGeneratorMockService';
import IFulfillmentBackendModel
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/IFulfillmentBackendModel';
import EGeneratorType from '@/module/product/view/FulfillmentFormPage/model/enums/EGeneratorType';
import EFulfillmentAllsoftHTTPMethod
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/EFulfillmentAllsoftHTTPMethod';

class GeneratorMockService implements IGeneratorMockService {
  getMock = (): IFulfillmentBackendModel => ({
    customerNotification: 'Licensed information: {KEY}',
    type: EGeneratorType.WEB_SERVICE,
    webService: {
      method: EFulfillmentAllsoftHTTPMethod.GET,
      quantityKeysItemsMatches: true,
      secretKey: 'QAZ123',
      sendToCustomer: true,
      url: 'https://keygens.ecommerce.softline.com.demoslweb.com/fake/index.php',
    },
  });
}

export default GeneratorMockService;
