<template>
  <div
    class="c-filtered-select"
  >
    <CLabel
      v-if="label"
      :label-for="select"
      :tooltip="tooltip"
      :text="label"
      class="c-filtered-select__label"
    />
    <DSFilteredSelect
      ref="select"
      class="c-filtered-select__input"
      :placeholder="placeholder"
      :status="status.code"
      :value="localValue"
      :id-field="idField"
      :title-field="titleField"
      :list="list"
      :offset="offset"
      :symbols-for-search="symbolsForSearch"
      :input-delay="inputDelay"
      @input="$emit('input', $event)"
      @blur="$emit('blur', $event)"
      @search-input="$emit('filtered-select-search-input', $event)"
      @scroll-y-reach-end="$emit('scroll-y-reach-end')"
    >
      <template
        #list-item="slotProps"
      >
        <slot
          name="list-item"
          :option="slotProps.option"
        />
        <CRadioButton
          v-if="!isListItemSlot"
          :checked="isSelectedItem(slotProps.option)"
          :name="radioButtonName"
          :title="slotProps.option[titleField]"
          :value="slotProps.option[idField]"
        />
      </template>
      <template
        #before-list
      >
        <slot
          name="before-list"
        />
      </template>
      <template
        #after-list
      >
        <slot
          name="after-list"
        />
      </template>
    </DSFilteredSelect>
    <DSTextElement
      v-if="description"
      class="c-filtered-select__comment"
      color="secondary"
      variant="small"
    >
      <!-- eslint-disable vue/no-v-html -->
      <span
        v-html="description"
      />
      <!-- eslint-enable vue/no-v-html -->
    </DSTextElement>
    <DSTextElement
      v-if="status.text"
      :color="status.code"
      class="c-filtered-select__comment"
      variant="small"
    >
      {{ status.text }}
    </DSTextElement>
  </div>
</template>

<script>
import CLabel from '@/module/common/components/UI/CLabel.vue';
import CRadioButton from '@/module/common/components/UI/СRadioButton.vue';
import DSFilteredSelect from '@/module/design-system/components/UI/DSFiltredSelect.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default {
  name: 'CFilteredSelect',
  components: {
    DSFilteredSelect,
    CLabel,
    CRadioButton,
    DSTextElement,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      required: true,
    },
    titleField: {
      type: String,
      default: 'title',
    },
    idField: {
      type: String,
      default: 'id',
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    status: {
      type: Object,
      default: () => ({
        code: 'default',
      }),
    },
    inputDelay: {
      type: Number,
      default: 500,
    },
    offset: {
      type: Number,
      default: null,
    },
    symbolsForSearch: {
      type: Number,
      default: 3,
    },
    autoSelectSingleValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      select: null,
      localValue: null,
    };
  },
  computed: {
    isListItemSlot() {
      return !!this.$scopedSlots['list-item'];
    },
    radioButtonName() {
      return `radio-button-${this._uid}`;
    },
  },
  watch: {
    value() {
      this.localValue = this.value;
    },
    list() {
      this.autoSelect();
    },
  },
  mounted() {
    this.select = this.$refs.select;
    this.localValue = this.value;
    this.autoSelect();
  },
  methods: {
    autoSelect() {
      if (this.autoSelectSingleValue && !this.localValue?.[this.idField] && (this.list ?? []).length === 1) {
        this.localValue = this.list[0];
        this.$emit('blur', this.localValue);
      }
    },
    isSelectedItem(option) {
      return option[this.idField] === this.value[this.idField];
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.c-filtered-select {
  &__label {
    margin-bottom: 8px;
  }

  &__comment {
    display: block;
    margin-top: 8px;
  }
}
</style>
