
import {
  defineComponent,
  PropType,
  ref,
  computed,
  reactive,
} from 'vue';

import EColorScheme from '@/module/design-system/components/models/EColorScheme';
import IFieldStatus from '@/module/design-system/components/models/FormComponent/IFieldStatus';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import EInputType from '@/module/common/models/UI/EInputType';

import CLabel from '@/module/common/components/UI/CLabel.vue';
import DSInput from '@/module/design-system/components/UI/DSInput.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import formFieldStatusCodeMapper from '@/module/common/service/FormComponent/mappers/formFieldStatusMapper';
import EDSFieldStatus from '@/module/design-system/models/UI/EDSFieldStatus';
import mapFieldStatusToColorScheme from '@/module/common/service/FormComponent/mappers/mapFieldStatusToColorScheme';

export default defineComponent({
  name: 'CInput',
  components: {
    CLabel,
    DSInput,
    DSTextElement,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [
        String,
        Number,
      ],
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    status: {
      type: Object as PropType<IFieldStatus>,
      default: () => ({
        code: EValidationFieldStatusCode.DEFAULT,
      }),
    },
    type: {
      type: String as PropType<EInputType>,
      default: EInputType.TEXT,
    },
    inputDelay: {
      type: Number,
      default: null,
    },
    transparentDefaultBorder: {
      type: Boolean,
      default: false,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    errorInPopup: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { slots, emit }) {
    const state = reactive<{ isEditing: boolean; isFocused: boolean; }>({
      isEditing: false,
      isFocused: false,
    });

    const input = ref(null);

    const isBeforeSlot = computed(() => !!slots['before-input']);
    const isAfterSlot = computed(() => !!slots['after-input']);
    const mappedStatusCode = computed(() => formFieldStatusCodeMapper(props.status.code));
    const statusCode = computed(() => (state.isEditing ? EDSFieldStatus.DEFAULT : mappedStatusCode.value));
    const statusTextColor = computed(() => {
      if (state.isEditing) {
        return EColorScheme.SECONDARY;
      }
      return mapFieldStatusToColorScheme(mappedStatusCode.value);
    });

    const classes = computed(() => ([
      'c-input',
      state.isFocused ? 'c-input_focused' : null,
    ]));

    const statusTextClasses = computed(() => [
      'c-input__comment',
      props.errorInPopup ? 'c-input__comment_popup' : null,
    ]);

    function handleBlur(event) {
      state.isEditing = false;
      state.isFocused = false;

      emit('blur', event);
    }

    function handleFocus(event) {
      state.isFocused = true;

      emit('focus', event);
    }

    function handleInput(event) {
      emit('input', event);
    }

    function handleKeydown(event) {
      state.isEditing = true;

      emit('keydown', event);
    }

    return {
      classes,
      input,
      isBeforeSlot,
      isAfterSlot,
      statusCode,
      statusTextColor,
      statusTextClasses,
      handleBlur,
      handleFocus,
      handleInput,
      handleKeydown,
    };
  },
});
