<template>
  <div
    class="c-label"
  >
    <DSLabel
      :for="labelFor"
      :text="text"
      :text-color="textColor"
      class="c-label__text"
    >
      <slot />
    </DSLabel>

    <DSTooltip
      v-if="tooltip"
      :text="tooltip"
      class="c-label__tooltip"
    >
      <template
        #tooltip-icon
      >
        <div
          class="c-label__tooltip-icon"
        >
          <DSIcon
            color="disabled"
            icon="info"
            size="s"
          />
        </div>
      </template>
    </DSTooltip>
  </div>
</template>

<script>
import DSLabel from '@/module/design-system/components/UI/DSLabel.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSTooltip from '@/module/design-system/components/InfoBlock/DSTooltip.vue';

export default {
  name: 'CLabel',
  components: {
    DSIcon,
    DSTooltip,
    DSLabel,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    labelFor: {
      type: Object,
      default: () => null,
    },
    textColor: {
      type: String,
      default: 'secondary',
      validator: (value) => [
        'primary',
        'secondary',
        'disabled',
        'linked',
        'navigation',
        'primary-button',
        'menu',
        'error',
        'success',
      ].includes(value),
    },
  },
  computed: {
    isDefaultSlot() {
      return !!this.$scopedSlots.default;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.c-label {
  display: flex;
  align-items: center;
  gap: 7px;

  &__tooltip-icon {
    width: 18px;
    height: 18px;
  }
}
</style>
