import array from '@/plugins/utils/array';
import data from '@/plugins/utils/data';
import date from '@/plugins/utils/date';
import numberFormat from '@/plugins/utils/numberFormat';
import string from '@/plugins/utils/string';
import style from '@/plugins/utils/style';
import link from '@/plugins/utils/link';

export default {
  install(Vue, vm) {
    Vue.prototype.$utils = {
      array: array(vm),
      data: data(vm),
      date: date(vm),
      numberFormat: numberFormat(vm),
      string: string(vm),
      style: style(vm),
      link: link(vm),
    };
  },
};
