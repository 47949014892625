<template>
  <div class="shimmer-loader" />
</template>

<script>
export default {
  name: 'ShimmerLoader',
};
</script>

<style lang="scss">
  .shimmer-loader {
    width: 100%;
    height: 100%;
    display: flex;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #F8F8FA;
    border-radius: 8px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(rgb(182, 182, 182), 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
