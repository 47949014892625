
import Vue, {
  computed,
  PropType,
} from 'vue';

import IDSButton from '@/module/design-system/models/UI/DSButton/IDSButton';
import EDSButtonSize from '@/module/design-system/models/UI/DSButton/EDSButtonSize';
import ENotifyVariant from '@/module/design-system/components/models/UI/ENotifyVariant';

import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default Vue.extend({
  name: 'DSPopUp',
  components: {
    DSButton,
    DSIcon,
    DSTextElement,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
    control: {
      type: Array as PropType<IDSButton[]>,
      default: () => [],
    },
    variant: {
      type: String as PropType<ENotifyVariant>,
      default: ENotifyVariant.INFO,
    },
    customPosition: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const popupClasses = computed(() => [
      'ds-popup',
      props.customPosition ? null : 'ds-popup_position-default',
    ]);
    const popupHeaderClasses = computed(() => [
      'ds-popup__header',
      `ds-popup__header_${props.variant}`,
    ]);

    function handleClosePopup() {
      emit('closePopup');
    }

    function handleCustomButtonClick(button: IDSButton) {
      const event = new CustomEvent('ClickPopupButton', {
        detail: {
          button,
        },
      });

      window.dispatchEvent(event);
    }

    function getButtonSize(button: IDSButton) {
      return button.size ?? EDSButtonSize.SMALL;
    }

    return {
      popupClasses,
      popupHeaderClasses,
      handleClosePopup,
      handleCustomButtonClick,
      getButtonSize,
    };
  },
});
