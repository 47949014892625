enum ELicenseSourceName {
  TEST_MOCK_GENERATOR = 'testMockGenerator',
  TEST_LICENSE_POOL = 'testLicensePool',
  TEST_CREATE_GENERATOR = 'testCreateGenerator',
  SALES_CREATE_GENERATOR = 'salesCreateGenerator',
  SALES_LICENSE_POOL = 'salesLicensePool',
  SALES_OTHER = 'salesOther',
  DEFAULT = 'default',
  CURRENT_FULFILLMENT = 'currentFulfillment',
}

export default ELicenseSourceName;
