
import {
  defineComponent,
} from 'vue';

import DSTag from '@/module/design-system/components/InfoBlock/DSTag.vue';
import DSTooltip from '@/module/design-system/components/InfoBlock/DSTooltip.vue';

export default defineComponent({
  name: 'DSTagList',
  components: {
    DSTooltip,
    DSTag,
  },
  props: {
    tagList: {
      type: Array,
      required: true,
    },
  },
});
