import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import ICookieService from '@/module/common/service/CookieService/ICookieService';
import ECookieKeys from '@/module/common/service/CookieService/ECookieKeys';

class CookieService implements ICookieService {
  private readonly serviceContainer: IServiceContainer;

  constructor(
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
  }

  clear = () => {
    localStorage.clear();
  };

  remove = (key: ECookieKeys) => {
    localStorage.removeItem(key);
  };

  read = <Value>(key: ECookieKeys): Value | undefined => {
    const utilsService = this.serviceContainer.utilsService;

    const storageValue = this.extractValue(key);
    if (storageValue === 'null') {
      return null as unknown as Value;
    }

    if (storageValue === 'undefined') {
      return undefined;
    }

    if (utilsService.typeCheck.isNull(storageValue)) {
      return undefined as unknown as Value;
    }

    if (utilsService.typeCheck.isUndefined(storageValue)) {
      return undefined as unknown as Value;
    }

    if (storageValue === 'true') {
      return true as unknown as Value;
    }

    if (storageValue === 'false') {
      return false as unknown as Value;
    }

    try {
      return utilsService.json.from<Value>(storageValue);
    } catch (e) {
      return storageValue as unknown as Value;
    }
  };

  write = <Value>(key: ECookieKeys, value: Value) => {
    const utilsService = this.serviceContainer.utilsService;

    if (utilsService.typeCheck.isString(value)) {
      document.cookie = this.prepareCookie(key, value);
    } else if (utilsService.typeCheck.isNumber(value)) {
      document.cookie = this.prepareCookie(key, `${value}`);
    } else if (utilsService.typeCheck.isNull(value)) {
      document.cookie = this.prepareCookie(key, 'null');
    } else if (utilsService.typeCheck.isObject(value)) {
      document.cookie = this.prepareCookie(key, utilsService.json.to(value));
    } else if (utilsService.typeCheck.isArray(value)) {
      document.cookie = this.prepareCookie(key, utilsService.json.to(value));
    } else if (utilsService.typeCheck.isSet(value)) {
      document.cookie = this.prepareCookie(key, `${value}`);
    } else {
      document.cookie = this.prepareCookie(key, 'undefined');
    }
  };

  private prepareCookie(key: ECookieKeys, value: string) {
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  }

  private extractValue(key: ECookieKeys) {
    const cookieRegistry = document.cookie.split(';')
      .map((value) => value.trim())
      .reduce((result, pair) => {
        const parts = pair.split('=').map((item) => decodeURIComponent(item));

        result[parts[0]] = parts[1];

        return result;
      }, {});

    return cookieRegistry[key];
  }
}

export default CookieService;
