import extractFileName from '@/module/common/utils/file/extractFileName';
import extractFileExtension from '@/module/common/utils/file/extractFileExtension';

function getFileName(fileName: string, fileNamesList: string[]): string {
  if (!fileNamesList.includes(fileName)) {
    return fileName;
  }

  const name = extractFileName(fileName);
  const ext = extractFileExtension(fileName);

  const pattern = `^${name}\\s*(\\(\\d+\\))*.${ext}$`;
  const equalFileNames = fileNamesList.filter(
    (fileNameItem) => (new RegExp(pattern)).test(fileNameItem) && fileNameItem !== fileName,
  );

  if (equalFileNames.length === 1 && equalFileNames[0] === fileName) {
    return `${name} (1).${ext}`;
  }

  const maxNumber = equalFileNames.reduce((maxValue, fileNameItem) => {
    const number = fileNameItem
      .replace(`).${ext}`, '')
      .split('(')
      .pop();

    maxValue = maxValue > Number(number) ? maxNumber : Number(number);

    return maxValue;
  }, 0);

  return `${name} (${maxNumber + 1}).${ext}`;
}

export default getFileName;
