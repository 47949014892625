<template>
  <label
    :for="forId"
    class="ds-label"
  >
    <DSTextElement
      :color="textColor"
      class="ds-label__text"
    >
      <template
        v-if="isDefaultSlot"
      >
        <slot />
      </template>
      <template
        v-else
      >
        {{ text }}
      </template>
    </DSTextElement>
  </label>
</template>

<script>
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default {
  name: 'DSLabel',
  components: {
    DSTextElement,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    for: {
      type: Object,
      default: () => null,
    },
    textColor: {
      type: String,
      default: 'primary',
      validator: (value) => [
        'primary',
        'secondary',
        'disabled',
        'linked',
        'navigation',
        'primary-button',
        'menu',
        'error',
        'success',
      ].includes(value),
    },
  },
  computed: {
    forId() {
      return this.for?.$refs.element.id ?? null;
    },
    isDefaultSlot() {
      return !!this.$scopedSlots.default;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.ds-label {
  &__text {
    display: block;
  }
}
</style>
