enum EFieldBlock {
  BUSINESS_STRUCTURE = 'businessStructure',
  BUSINESS_DETAILS = 'businessDetails',
  BUSINESS_REPRESENTATIVE = 'businessRepresentative',
  SUPPORT_DETAILS = 'supportDetails',
  ACCOUNT_FOR_PAYOUTS = 'accountForPayouts',
  TAX_CALCULATION = 'taxCalculation',
}

export default EFieldBlock;
