
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

import moment from 'moment/moment';

import EDateInterval from '@/module/dashboard/models/DatePickers/enums/EDateInterval';
import EIntervalName from '@/module/dashboard/models/DatePickers/enums/EIntervalName';
import IFilterDatePicker from '@/module/dashboard/models/DatePickers/interfaces/IFilterDatePicker';
import IFilterDatePickersDataToSubmit
  from '@/module/dashboard/models/DatePickers/interfaces/IFilterDatePickersDataToSubmit';

import initState, {
  getDatePairs,
  getPrevPeriodDates,
} from '@/components/FilterDatePicker/FilterDatePickers.func';
import cloneImmutable from '@/module/common/utils/data/cloneImmutable';

import FilterDatePicker from '@/components/FilterDatePicker/FilterDatePicker.vue';

export default defineComponent({
  name: 'FilterDatePickers',
  components: {
    FilterDatePicker,
  },
  props: {
    initial: {
      type: Object as PropType<IFilterDatePicker>,
      required: true,
    },
    comparison: {
      type: Object as PropType<IFilterDatePicker>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = initState();

    const minDate = moment('1/1/2020', 'DD MM YYYY').toDate();
    const maxDate = moment().toDate();

    state.initial = cloneImmutable(props.initial);
    state.comparison = cloneImmutable(props.comparison);

    const localInitial = computed(() => (
      state.isMenuOpen.initial
        ? cloneImmutable(state.initial)
        : cloneImmutable(props.initial)
    ));

    const localComparison = computed(() => (
      state.isMenuOpen.comparison
        ? cloneImmutable(state.comparison)
        : cloneImmutable(props.comparison)
    ));

    function handleChangeOption(option, fieldName: EIntervalName) {
      const dateFrom = state[fieldName].dateFrom;
      const dateTo = state[fieldName].dateTo;
      const datePair = getDatePairs(
        option,
        {
          dateFrom,
          dateTo,
        },
        {
          dateFrom: props.initial.dateFrom,
          dateTo: props.initial.dateTo,
        },
      );

      state[fieldName].selectionMode = option.value;
      state[fieldName].dateFrom = datePair.dateFrom;
      state[fieldName].dateTo = datePair.dateTo;
    }

    function handleSubmit(event, fieldName: EIntervalName) {
      state[fieldName].selectionMode = event.selectionMode;
      state[fieldName].dateFrom = event.datePair.start;
      state[fieldName].dateTo = event.datePair.end;

      const dataToEmit: IFilterDatePickersDataToSubmit = {
        initial: {
          selectionMode: props.initial.selectionMode,
          dateFrom: props.initial.dateFrom,
          dateTo: props.initial.dateTo,
        },
        comparison: {
          selectionMode: props.comparison.selectionMode,
          dateFrom: props.comparison.dateFrom,
          dateTo: props.comparison.dateTo,
        },
      };

      if (fieldName === EIntervalName.INITIAL) {
        dataToEmit.initial = {
          selectionMode: event.selectionMode,
          dateFrom: event.datePair.start,
          dateTo: event.datePair.end,
        };

        if (props.comparison.selectionMode === EDateInterval.PREV_PERIOD) {
          const prevPeriodDatePair = getPrevPeriodDates({
            dateFrom: state.initial.dateFrom,
            dateTo: state.initial.dateTo,
          });

          dataToEmit.comparison = {
            selectionMode: EDateInterval.PREV_PERIOD,
            dateFrom: prevPeriodDatePair.dateFrom,
            dateTo: prevPeriodDatePair.dateTo,
          };
        }
      } else {
        dataToEmit.comparison = {
          selectionMode: event.selectionMode,
          dateFrom: event.datePair.start,
          dateTo: event.datePair.end,
        };
      }

      emit('submit', dataToEmit);
    }

    function handleShowDropdown(fieldName: EIntervalName) {
      state.isMenuOpen[fieldName] = true;
    }

    function handleHideDropdown(event, fieldName: EIntervalName) {
      state.isMenuOpen[fieldName] = false;

      if (event.needToRewriteState) {
        state[fieldName].selectionMode = props[fieldName].selectionMode;
        state[fieldName].dateFrom = props[fieldName].dateFrom;
        state[fieldName].dateTo = props[fieldName].dateTo;
      }
    }

    return {
      state,
      minDate,
      maxDate,
      localInitial,
      localComparison,
      handleChangeOption,
      handleShowDropdown,
      handleHideDropdown,
      handleSubmit,
    };
  },
});
