<template>
  <button
    ref="button"
    :style="buttonStyle"
    :class="buttonClass"
    type="button"
    @click="handleClick"
  >
    <template v-if="loading">
      <progress-spinner
        stroke-width="4"
        animation-duration="0.5s"
      />
    </template>
    <template v-else>
      <span
        v-if="hasIconBeforeSlot"
        class="button__icon button-icon button-icon__before"
      >
        <slot name="icon-before" />
      </span>
      <span
        v-if="iconButton"
        class="button__icon-button-inner button-icon"
      >
        <slot />
      </span>
      <span
        v-else
        class="text text__bold"
      >
        <slot />
      </span>
      <span
        v-if="hasIconAfterSlot"
        class="button__icon button-icon button-icon__after"
      >
        <slot name="icon-after" />
      </span>
    </template>
  </button>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onMounted,
} from 'vue';

import './style.scss';

export default defineComponent({
  name: 'ButtonComponent',
  props: {
    variant: {
      type: String,
      default: () => 'primary',
      validator: (value) => [
        'primary',
        'secondary',
        'secondary-negative',
        'tretiary',
        'tretiary-negative',
        'commerce',
      ]
        .includes(value),
    },
    small: {
      type: Boolean,
      default: false,
    },
    iconButton: {
      type: Boolean,
      default: false,
    },
    primaryColor: {
      type: String,
      default: () => '#4397CB',
    },
    primaryHoverColor: {
      type: String,
      default: () => '#388FC5',
    },
    primaryActiveColor: {
      type: String,
      default: () => '#2D83B8',
    },
    primaryTextColor: {
      type: String,
      default: () => '#ffffff',
    },
    block: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { slots, emit }) {
    const button = ref();
    const width = ref(0);
    const buttonStyle = computed(
      () => ({
        '--color': props.primaryColor,
        '--color-hover': props.primaryHoverColor,
        '--color-active': props.primaryActiveColor,
        '--text-color': props.primaryTextColor,
        'min-width': width.value ? `${width.value}px` : 'inherit',
      }),
    );
    const buttonClass = computed(() => [
      `button button__${props.variant}`,
      props.iconButton ? 'button__icon-button' : '',
      props.small ? 'button__small' : '',
      { button__block: props.block },
      { button__loading: props.loading },
    ]);
    const hasIconBeforeSlot = computed(() => !!slots['icon-before']);
    const hasIconAfterSlot = computed(() => !!slots['icon-after']);

    function handleClick(event) {
      emit('click', event);
    }

    onMounted(() => {
      if (button.value) {
        width.value = button.value?.clientWidth;
      }
    });

    return {
      button,
      buttonStyle,
      buttonClass,
      hasIconBeforeSlot,
      hasIconAfterSlot,
      handleClick,
    };
  },
});
</script>
