import IScenarioService
  from '@/module/product/view/FulfillmentFormPage/model/contract/service/IScenarioService';
import EScenario from '@/module/product/view/FulfillmentFormPage/model/enums/EScenario';
import ELicenseSourceName from '@/module/product/view/FulfillmentFormPage/model/enums/ELicenseSourceName';
import CreateTestGeneratorScenario
  from '@/module/product/view/FulfillmentFormPage/scenario/CreateTestGeneratorScenario';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import CreateGeneratorScenario from '@/module/product/view/FulfillmentFormPage/scenario/CreateGeneratorScenario';
import IState from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/IState';
import SendEmailToManagerScenario from '@/module/product/view/FulfillmentFormPage/scenario/SendEmailToManagerScenario';
import IScenario from '@/module/product/view/FulfillmentFormPage/model/contract/scenario/IScenario';
import EmptyScenario from '@/module/product/view/FulfillmentFormPage/scenario/EmptyScenario';
import EFormFieldBlurScenario from '@/module/product/view/FulfillmentFormPage/model/enums/EFormFieldBlurScenario';
import IFormFieldBlurScenario
  from '@/module/product/view/FulfillmentFormPage/model/contract/scenario/IFormFieldBlurScenario';
import DefaultFormFieldBlurScenario
  from '@/module/product/view/FulfillmentFormPage/scenario/formFieldBlur/DefaultFormFieldBlurScenario';
import ScenarioError from '@/module/product/view/FulfillmentFormPage/ScenarioError';
import ILocalServiceContainer from '@/module/product/view/FulfillmentFormPage/model/ILocalServiceContainer';
import LicenseSourceFieldBlurScenario
  from '@/module/product/view/FulfillmentFormPage/scenario/formFieldBlur/LicenseSourceFieldBlurScenario';
import ParametersFieldBlurScenario
  from '@/module/product/view/FulfillmentFormPage/scenario/formFieldBlur/ParametersFieldBlurScenario';
import TestGeneratorScenario from '@/module/product/view/FulfillmentFormPage/scenario/TestGeneratorScenario';

class ScenarioService implements IScenarioService {
  private readonly state: IState;

  private readonly serviceContainer: IServiceContainer;

  private readonly localServiceContainer: ILocalServiceContainer;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    localServiceContainer: ILocalServiceContainer,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.localServiceContainer = localServiceContainer;
  }

  getSaveScenarioName = (licenseSourceName: ELicenseSourceName): EScenario => {
    const map = {
      [ELicenseSourceName.SALES_LICENSE_POOL]: EScenario.SEND_EMAIL_TO_MANAGER,
      [ELicenseSourceName.SALES_OTHER]: EScenario.SEND_EMAIL_TO_MANAGER,
      [ELicenseSourceName.TEST_LICENSE_POOL]: EScenario.SEND_EMAIL_TO_MANAGER,
      [ELicenseSourceName.SALES_CREATE_GENERATOR]: EScenario.CREATE_GENERATOR,
      [ELicenseSourceName.TEST_CREATE_GENERATOR]: EScenario.CREATE_GENERATOR,
      [ELicenseSourceName.TEST_MOCK_GENERATOR]: EScenario.MOCK_GENERATOR,
      [ELicenseSourceName.DEFAULT]: EScenario.DEFAULT,
      [ELicenseSourceName.CURRENT_FULFILLMENT]: EScenario.DEFAULT,
    };

    return map[licenseSourceName];
  };

  getSaveScenario = (scenarioName: EScenario): IScenario => {
    const scenarioMap = {
      [EScenario.SEND_EMAIL_TO_MANAGER]: () => new SendEmailToManagerScenario(
        this.state,
        this.serviceContainer,
        this.localServiceContainer,
      ),
      [EScenario.CREATE_GENERATOR]: () => new CreateGeneratorScenario(
        this.state,
        this.serviceContainer,
        this.localServiceContainer,
      ),
      [EScenario.MOCK_GENERATOR]: () => new CreateTestGeneratorScenario(
        this.state,
        this.serviceContainer,
        this.localServiceContainer,
      ),
      [EScenario.TEST_GENERATOR]: () => new TestGeneratorScenario(
        this.state,
        this.serviceContainer,
        this.localServiceContainer,
      ),
      [EScenario.DEFAULT]: () => new EmptyScenario(),
    };

    return scenarioMap[scenarioName]();
  };

  getBlurScenario(scenarioName: EFormFieldBlurScenario): IFormFieldBlurScenario {
    const scenarioMap = {
      [EFormFieldBlurScenario.LICENSE_SOURCE_SCENARIO]: () => new LicenseSourceFieldBlurScenario(
        this.state,
        this.localServiceContainer,
      ),
      [EFormFieldBlurScenario.PARAMETERS_SCENARIO]: () => new ParametersFieldBlurScenario(
        this.state,
        this.localServiceContainer,
      ),
      [EFormFieldBlurScenario.DEFAULT_SCENARIO]: () => new DefaultFormFieldBlurScenario(
        this.state,
        this.serviceContainer,
        this.localServiceContainer,
      ),
    };

    if (!scenarioMap[scenarioName]) {
      throw new ScenarioError(`Scenario '${scenarioName}' not exists`);
    }

    return scenarioMap[scenarioName]();
  }

  getTestRequestScenario(): IScenario {
    return new TestGeneratorScenario(
      this.state,
      this.serviceContainer,
      this.localServiceContainer,
    );
  }
}

export default ScenarioService;
