<template>
  <ul
    class="product-list"
  >
    <li
      v-for="(item, idx) in products"
      :key="item.product.id"
      class="product-list__product product"
      @click="(event) => handleRedirectToProductCard(event, item.product.id, idx)"
    >
      <div
        class="product__image"
        :class="item.product.icon ? 'product__image--bordered' : null"
      >
        <img
          v-if="item.product.icon"
          :src="item.product.icon"
          :alt="item.product.title"
        >
        <DSIcon
          v-else
          icon="product"
          color="linked"
        />
      </div>

      <div class="product__info">
        <div class="product__name">
          <p>{{ item.product.title }} •<br> {{ item.product.subtitle }}</p>
        </div>

        <div class="product__ids">
          <p class="secondary">
            <span class="product__id">{{ $t('product.product_id') }}: {{ item.product.id }}</span>
            •
            <span class="product__sku">{{ $t('product.product_sku') }}: {{ item.product.sku || '-' }}</span>
          </p>
        </div>

        <time class="product__update-date secondary">
          {{ utilsService.date.formatSkipCurrentYearWithoutWeekDay(item.product.updateDate) }}
        </time>
        <ButtonMore
          ref="buttonMoreRef"
          class="product__button-more"
          :buttons="[
            {
              name: 'product.product_card.edit',
              handler: () => handleRedirectToEdit(item.product.id),
            },
            {
              name: 'product.product_card.delete',
              handler: () => handleDeleteProduct(item.product.id),
            },
            {
              name: 'product.product_card.copy_buy_link',
              handler: () => handleCopyBuyLink(item.buylink),
            },
          ]"
        />
      </div>
    </li>
  </ul>
</template>

<script>
import {
  inject,
} from 'vue';
import {
  mapMutations,
  mapState,
  mapActions,
} from 'vuex';

import ButtonMore from '@/components/ButtonMore/ButtonMore.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DateTimeMixin from '@/mixins/dateTimeMixin';

export default {
  name: 'ProductList',
  components: {
    DSIcon,
    ButtonMore,
  },
  mixins: [DateTimeMixin],
  setup() {
    const serviceContainer = inject('serviceContainer');

    const utilsService = serviceContainer.utilsService;

    const localisationRegistry = serviceContainer.localisationRegistry;
    const localisationOld = localisationRegistry.productsOld;

    const store = serviceContainer.resolve('store');

    async function handleCopyBuyLink(buyLink) {
      await navigator.clipboard.writeText(buyLink);

      await store.dispatch(
        'notificationsStore/pushNotification',
        {
          title: localisationOld.productCard.notification.copyLink,
          component: 'DSAlert',
          type: 'info',
        },
      );
    }

    return {
      utilsService,
      handleCopyBuyLink,
    };
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState({
      isPopupWdithControls: (state) => state.popupStore.withControls,
      isPopupOpened: (state) => state.popupStore.isOpened,
      id: (state) => state.deleteModalStore.id,
    }),
    productToDelete() {
      return this.$store.state.productListStore.products.find((item) => item.product.id === this.id)?.product;
    },
  },
  methods: {
    ...mapActions({
      handleDeleteProductClick: 'deleteModalStore/handleDeleteProductClick',
    }),
    ...mapMutations({
      togglePopUp: 'popupStore/togglePopUp',
      toggleControls: 'popupStore/toggleWithControls',
      changeTryAgainhandler: 'popupStore/changeTryAgainHandler',
      changeDeleteModalFieldValue: 'deleteModalStore/changeFieldValue',
    }),
    handleRedirectToProductCard(event, productId, idx) {
      if (this.isPopupOpened) this.handleClosePopup();

      const { target } = event;
      const button = this.$refs.buttonMoreRef[idx].$el;
      if (button.contains(target)) return;
      this.$router.push(`/products/${productId}`).catch(() => null);
    },
    handleClosePopup() {
      this.togglePopUp();

      if (this.isPopupWdithControls) {
        this.changeTryAgainhandler(null);
        this.toggleControls();
      }
    },

    handleDeleteProduct(productId) {
      this.changeDeleteModalFieldValue({ fieldName: 'id', newValue: productId });
      this.changeDeleteModalFieldValue({ fieldName: 'isOpened', newValue: true });
      this.changeDeleteModalFieldValue({
        fieldName: 'activeModalData',
        newValue: {
          dataToDelete: this.productToDelete,
          title: this.$t('modal_window.delete_product'),
          buttonKeep: this.$t('modal_window.keep'),
          buttonDelete: this.$t('modal_window.delete'),
          handleDeleteClick: () => {
            const info = [
              productId,
              this.$t('popup_messages.deleted_product'),
              `${this.productToDelete.title} • ${this.productToDelete.subtitle}`,
              this.$t('popup_messages.error'),
              this.$t('popup_messages.went_wrong'),
            ];
            return this.handleDeleteProductClick(info);
          },
        },
      });

      this.$refs.buttonMoreRef.forEach((el) => {
        el.isMenuOpened = false;
      });
    },

    handleRedirectToEdit(productId) {
      this.$router.push(`/products/edit/${productId}`).catch(() => null);
    },
  },
};
</script>

<style lang="scss">
  .product-list {
    padding: 0;
    width: 100%;

    &__product {
      margin-top: 15px;
    }
  }

  .product {
    cursor: pointer;
    width: 100%;
    display: grid;
    grid-template-columns: 64px auto;
    column-gap: 16px;
    align-items: center;

    &__image {
      height: 64px;
      object-fit: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #EBF7FF;
      border-radius: 40%;
      overflow: hidden;
    }

    &__image--bordered {
      background-color: white;
    }

    &__info {
      display: inherit;
      grid-template-columns: 670px auto;
      grid-template-rows: 1fr 1fr;
      align-items: center;
      border-bottom: 1px solid #E5E5E5;
      padding-bottom: 15px;
      column-gap: 20px;
      row-gap: 8px;
    }

    &__name {
      grid-column: 1 / 2;
      grid-row: 1 / 2;

      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 500;
      }

      br {
        display: none;
      }
    }

    &__ids {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      display: flex;
      column-gap: 5px;

      p {
        text-transform: uppercase;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &__update-date {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      justify-self: right;
    }

    &__button-more {
      justify-self: right;
      align-self: flex-start;
    }

    &__button-more .button-more {
      border: 0;
      padding: 0;
      width: 24px;
      height: 24px;

      svg path {
        fill: black
      }
    }
  }

  @media screen and (max-width: 1120px) {
    .product {
      grid-template-columns: 64px 1fr;

      &__info {
        grid-template-columns: 450px auto;
      }
    }
  }

  @media screen and (max-width: 920px) {
    .product {
      border-bottom: 1px solid #E5E5E5;
      padding: 10px 0;

      &__info {
        justify-content: space-between;
        grid-template-columns: 1fr 24px;
        border-bottom: 0;
      }

      &__name {
        overflow: hidden;
      }

      &__name br {
        display: block;
      }

      &__update-date {
        display: none;
      }

      &__ids {
        grid-column: 1 / 3;
      }
    }

    .product-list__product {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 840px) {
    .product {

      &__image {
        align-self: flex-start;
        overflow: hidden;
        border-radius: 40%;
      }

      &__image--bordered {
        border: 1px solid #E5E5E5;
      }

      &__info {
        padding-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 370px) {
    .product-list {
      width: 300px;
    }

    .product {
      width: 300px;
      column-gap: 0;

      &__info {
        margin-left: 16px;
        width: calc(300px - 64px - 16px);
      }
    }
  }
</style>
