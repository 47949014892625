export default {
  name: 'DateTimeMixin',
  methods: {
    getMonthAndDay(updateDate, locale) {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const monthsRu = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];

      const date = new Date(updateDate.replace(' ', 'T'));
      const day = date.getDate();
      const month = date.getMonth();
      return `${locale === 'en' ? months[month] : monthsRu[month]} ${day}`;
    },
  },
};
