export default (serviceContainer) => ({
  namespaced: true,

  state: {
    id: null,
    isOpened: false,
    activeModalData: null,
  },

  mutations: {
    changeFieldValue(state, { fieldName, newValue }) {
      state[fieldName] = newValue;
    },

    restore(state) {
      state.id = null;
      state.isOpened = false;
    },
  },

  actions: {
    handleKeepClick({ commit }) {
      commit('restore');
    },

    changeFieldValue({ commit }, { fieldName, newValue }) {
      commit('changeFieldValue', { fieldName, newValue });
    },

    async handleDeleteProductClick(state, info) {
      const { commit, dispatch } = state;

      const [id, title, description, titleError, descriptionError] = info;
      try {
        const api = serviceContainer.resolve('api');
        const router = serviceContainer.resolve('router');

        await api.products.deleteProduct(id);

        commit('popupStore/changeTitle', title, { root: true });
        commit('popupStore/changeDescription', description, { root: true });
        commit('productListStore/deleteProduct', id, { root: true });
        commit('deleteModalStore/changeFieldValue', { fieldName: 'isOpened', newValue: false }, { root: true });

        if (router.history.current.path === `/products/${id}`) {
          router.push('/products').catch(() => null);
        }

        dispatch('productListStore/getProducts', true, { root: true });
      } catch (error) {
        commit('popupStore/changeTitle', titleError, { root: true });
        commit('popupStore/changeDescription', descriptionError, { root: true });
        commit('popupStore/toggleWithControls', null, { root: true });
        commit('popupStore/changeTryAgainHandler', () => this.handleDeleteProductClick(state, info), { root: true });
        commit('deleteModalStore/changeFieldValue', { fieldName: 'isOpened', newValue: false }, { root: true });
      } finally {
        commit('popupStore/togglePopUp', null, { root: true });
      }
    },

    async handleDeleteEndpointClick(state, info) {
      const { commit, dispatch } = state;
      const [id, title, description, titleError, descriptionError] = info;

      try {
        const api = serviceContainer.resolve('api');
        const router = serviceContainer.resolve('router');

        await api.webhooks.deleteEndpoint(id);

        commit('popupStore/changeTitle', title, { root: true });
        commit('popupStore/changeDescription', description, { root: true });
        commit('webhooksStore/deleteEndpoint', id, { root: true });
        commit('deleteModalStore/changeFieldValue', { fieldName: 'isOpened', newValue: false }, { root: true });

        if (router.history.current.path === `/webhooks/${id}`) {
          router.push('/webhooks').catch(() => null);
        }

        dispatch('webhooksStore/getEndpoints', true, { root: true });
      } catch (error) {
        commit('popupStore/changeTitle', titleError, { root: true });
        commit('popupStore/changeDescription', descriptionError, { root: true });
        commit('popupStore/toggleWithControls', null, { root: true });
        commit('popupStore/changeTryAgainHandler', () => this.handleDeleteProductClick(state, info), { root: true });
        commit('deleteModalStore/changeFieldValue', { fieldName: 'isOpened', newValue: false }, { root: true });
      } finally {
        commit('popupStore/togglePopUp', null, { root: true });
      }
    },
  },

  getters: {
    getId(state) {
      return state.id;
    },
  },
});
