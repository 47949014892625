enum EDateInterval {
  LAST_30_DAYS = 'L30D',
  LAST_7_DAYS = 'L7D',
  LAST_4_WEEKS = 'L4W',
  LAST_3_MONTH = 'L3M',
  LAST_YEAR = 'LY',
  THIS_MONTH = 'TM',
  THIS_QUARTER = 'TQ',
  THIS_YEAR = 'TY',
  PREV_PERIOD = 'PP',
  PREV_MONTH = 'PM',
  PREV_QUARTER = 'PQ',
  PREV_YEAR = 'PY',
  NO_COMPARISON = 'NC',
  CUSTOM = 'CM',
}

export default EDateInterval;
