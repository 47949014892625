
import Vue,
{
  computed, PropType,
} from 'vue';

import EIcon from '@/module/design-system/components/models/EIcon';
import ESize from '@/module/design-system/components/models/ESize';

import DSHeader from '@/module/design-system/components/Text/DSHeader.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSParagraph from '@/module/design-system/components/Text/DSParagraph.vue';

export default Vue.extend({
  name: 'CStatusWindowComponent',
  components: {
    DSHeader,
    DSIcon,
    DSParagraph,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.L,
    },
    icon: {
      type: String as PropType<EIcon>,
      default: EIcon.UPLOAD,
    },
  },
  setup(props, { slots }) {
    const sectionClasses = computed(() => [
      'c-status-window__section',
      `c-status-window__section_${props.size}`,
    ]);

    const hasButtonsSlot = computed(() => !!slots.buttons);

    return {
      sectionClasses,
      hasButtonsSlot,
    };
  },
});
