import IFormDataHandler from '@/module/product/view/FulfillmentFormPage/model/contract/handler/IFormDataHandler';
import TFormData from '@/module/product/view/FulfillmentFormPage/model/types/TFormData';
import IFormDataSalesCreateGenerator
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataSalesCreateGenerator';
import IFormDataTestTestGenerator
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataTestTestGenerator';
import IFormDataTestCreateGenerator
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataTestCreateGenerator';
import IFormDataTestLicensePool
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataTestLicensePool';
import IFormDataSalesOther
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataSalesOther';
import IFormDataSalesLicensePool
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataSalesLicensePool';
import ELicenseSourceName from '@/module/product/view/FulfillmentFormPage/model/enums/ELicenseSourceName';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IMerchantModeService from '@/module/common/models/service/MerchantMode/IMerchantModeService';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import ILicenseSourceOption
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formConfig/ILicenseSourceOption';
import ILocalServiceContainer from '@/module/product/view/FulfillmentFormPage/model/ILocalServiceContainer';
import IHandlerService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IHandlerService';
import ELocalServiceFulfillment from '@/module/product/view/FulfillmentFormPage/model/enums/ELocalServiceFulfillment';
import ILicenseAmountService
  from '@/module/product/view/FulfillmentFormPage/model/contract/service/ILicenseAmountService';
import IHTTPMethodService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IHTTPMethodService';
import EHTTPMethod from '@/module/common/models/EHTTPMethod';
import IUtilsService from '@/module/common/service/UtilsService/contract/IUtilsService';

class FormDataHandler implements IFormDataHandler {
  private readonly serviceContainer: IServiceContainer;

  private readonly localServiceContainer: ILocalServiceContainer;

  constructor(
    serviceContainer: IServiceContainer,
    localServiceContainer: ILocalServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
    this.localServiceContainer = localServiceContainer;

    this.initStorage();
  }

  private storage: { [key in ELicenseSourceName]: TFormData } = {
    [ELicenseSourceName.SALES_CREATE_GENERATOR]: {} as IFormDataSalesCreateGenerator,
    [ELicenseSourceName.SALES_LICENSE_POOL]: {} as IFormDataSalesLicensePool,
    [ELicenseSourceName.SALES_OTHER]: {} as IFormDataSalesOther,
    [ELicenseSourceName.TEST_CREATE_GENERATOR]: {} as IFormDataTestLicensePool,
    [ELicenseSourceName.TEST_LICENSE_POOL]: {} as IFormDataTestCreateGenerator,
    [ELicenseSourceName.TEST_MOCK_GENERATOR]: {} as IFormDataTestTestGenerator,
    [ELicenseSourceName.DEFAULT]: {} as TFormData,
    [ELicenseSourceName.CURRENT_FULFILLMENT]: {} as IFormDataSalesOther,
  };

  getData = (licenseSourceName: ELicenseSourceName): TFormData => {
    const utils = this.serviceContainer.resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);

    return utils.data.cloneImmutable(this.storage[licenseSourceName]);
  };

  store = (licenseSourceName: ELicenseSourceName, formData: TFormData) => {
    const utils = this.serviceContainer.resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);

    this.storage[licenseSourceName] = utils.data.cloneImmutable(formData);
  };

  private initStorage = () => {
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const licenseAmountService = this.localServiceContainer
      .resolve<ILicenseAmountService>(ELocalServiceFulfillment.LICENSE_AMOUNT_SERVICE);
    const licenseVariantHandler = handlerService.getLicenseVariantHandler();
    const merchantModeService = this.serviceContainer
      .resolve<IMerchantModeService>(EServiceContainerInstances.MERCHANT_MODE_SERVICE);
    const httpMethodService = this.localServiceContainer
      .resolve<IHTTPMethodService>(ELocalServiceFulfillment.HTTP_METHOD_SERVICE);
    const licenseVariantList = licenseVariantHandler.getLicenseSourceList();

    if (merchantModeService.isSalesMode()) {
      this.storage[ELicenseSourceName.SALES_CREATE_GENERATOR] = {
        licenseSource: licenseVariantList.find(
          (variant) => variant.id === ELicenseSourceName.SALES_CREATE_GENERATOR,
        ) as ILicenseSourceOption,
        url: '',
        method: httpMethodService.get(EHTTPMethod.GET),
        secretKey: '',
        licenseAmount: licenseAmountService.get(true),
        sendLicense: true,
        messageToUser: '',
        parameters: [],
      };
    } else {
      this.storage[ELicenseSourceName.TEST_CREATE_GENERATOR] = {
        licenseSource: licenseVariantList.find(
          (variant) => variant.id === ELicenseSourceName.TEST_CREATE_GENERATOR,
        ) as ILicenseSourceOption,
        url: '',
        method: httpMethodService.get(EHTTPMethod.GET),
        secretKey: '',
        licenseAmount: licenseAmountService.get(true),
        sendLicense: true,
        messageToUser: '',
        parameters: [],
      };
    }
  };
}

export default FormDataHandler;
