<template>
  <div
    :class="classes"
    @click="handleParentClick"
  >
    <div
      v-if="isBeforeSlot"
      class="ds-input__before-field"
    >
      <slot
        name="before"
      />
    </div>
    <input
      :id="id"
      ref="element"
      v-model="currentValue"
      :placeholder="placeholder"
      :type="type"
      :disabled="disabled"
      class="ds-input__field"
      @blur="handleBlur"
      @focus="handleFocus"
      @input="handleInput"
      @keydown="handleKeydown"
    >
    <div
      v-if="isAfterSlot"
      class="ds-input__after-field"
    >
      <slot
        name="after"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DSInput',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: [String, undefined],
      default: '',
    },
    status: {
      type: String,
      default: 'default',
    },
    value: {
      type: [
        String,
        Number,
      ],
      required: true,
    },
    inputDelay: {
      type: [
        Number,
        null,
      ],
      required: false,
      default: null,
    },
    borderless: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    transparentDefaultBorder: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      currentValue: null,
      delay: null,
      isFocused: false,
    };
  },
  computed: {
    classes() {
      return [
        'ds-input',
        `ds-input_${this.status}`,
        this.disabled ? 'ds-input_disabled' : null,
        this.isFocused ? 'ds-input_focused' : null,
        this.borderless ? 'ds-input_borderless' : null,
        this.transparentDefaultBorder ? 'ds-input_transparent-default-border' : null,
      ];
    },
    id() {
      return `ds-input__field-id-${this._uid}`;
    },
    isBeforeSlot() {
      return !!this.$scopedSlots.before;
    },
    isAfterSlot() {
      return !!this.$scopedSlots.after;
    },
  },
  watch: {
    value() {
      this.currentValue = this.value;
    },
  },
  mounted() {
    this.currentValue = this.value;
  },
  methods: {
    handleFocus() {
      this.isFocused = true;
      this.$emit('focus');
    },
    handleIconClick() {
      this.$refs.element.focus();
      this.$emit('icon-click');
    },
    handleParentClick() {
      this.$refs.element.focus();
    },
    handleBlur() {
      this.isFocused = false;
      this.$emit('blur', this.currentValue);
    },
    handleInput() {
      if (this.inputDelay > 0) {
        clearTimeout(this.delay);
        this.delay = setTimeout(() => {
          this.$emit('input', this.currentValue);
        }, this.inputDelay);
      } else {
        this.$emit('input', this.currentValue);
      }
    },
    handleKeydown(event) {
      this.$emit('keydown', event);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import "../variables";

.ds-input {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  height: 48px;
  padding: 0 14px;
  border: 2px solid $field-border-color-primary;
  border-radius: 8px;
  background-color: $surface-bg-color-regular;
  transition: all linear .2s;
  gap: 14px;

  &_borderless {
    border: none;
  }

  &_default {
    &.ds-input_transparent-default-border {
      border-color: transparent;
    }

    &:hover {
      border-color: $field-border-color-primary-hover;
    }

    &.ds-input_focused {
      border-color: $field-border-color-primary-focus;
    }
  }

  &_error {
    border-color: $field-border-color-error;

    &:hover {
      border-color: $field-border-color-error-hover;
    }

    &.ds-input_focused {
      border-color: $field-border-color-error-focus;
    }
  }

  &_success {
    border-color: $field-border-color-success;

    &:hover {
      border-color: $field-border-color-success-hover;
    }

    &.ds-input_focused {
      border-color: $field-border-color-success-focus;
    }
  }

  &_disabled {
    pointer-events: none;
    border-color: $field-border-color-disabled;
    background-color: $surface-bg-color-grey;
    color: $text-color-disabled;
  }

  &__field {
    width: 100%;
    height: 100%;
    font-family: $text-font-ibm-plex-sans;
    font-size: $text-size-regular;
    font-weight: 400;
    line-height: 22px;
    border: none;
    outline: none;
    appearance: none;

    @mixin placeholder-styles {
      font-family: $text-font-ibm-plex-sans;
      font-size: $text-size-regular;
      color: $text-color-disabled;
      font-weight: 400;
      line-height: 22px;
    }

    &::-webkit-input-placeholder {
      @include placeholder-styles
    }

    &::-ms-input-placeholder {
      @include placeholder-styles
    }

    &::placeholder {
      @include placeholder-styles
    }
  }
}
</style>
