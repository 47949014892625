import DevToolsPage from '@/module/dev-tools/views/DevToolsPage.vue';
import FormComponentDemoPage from '@/module/dev-tools/views/FormComponentDemoPage.vue';
import defaultLayout from '@/router/defaultLayout';
import BaseComponentOverviewPage from '@/module/dev-tools/views/BaseComponentOverviewPage.vue';
import DSAbout from '@/module/dev-tools/views/DesignSystem/DSAboutPage.vue';
import DSColorScheme from '@/module/dev-tools/views/DesignSystem/DSColorSchemePage.vue';
import DSPageLayout from '@/module/dev-tools/views/DesignSystem/DSPageLayout.vue';
import ComponentDemoPage from '@/module/dev-tools/views/ComponentDemoPage.vue';
import ERouteName from '@/module/common/models/router/ERouteName';

const moduleSlug = 'dev-tools';

const routes = [
  {
    path: `/${moduleSlug}`,
    name: 'DevTools',
    components: {
      default: DevToolsPage,
      ...defaultLayout,
    },
    meta: { title: 'Dev Tools' },
  },
  {
    path: '/form-demo-page',
    name: ERouteName.FORM_COMPONENT_DEMO,
    components: {
      default: FormComponentDemoPage,
      ...defaultLayout,
    },
    meta: {
      title: 'Form Component Demo',
    },
  },
  {
    path: '/component-demo',
    components: {
      default: BaseComponentOverviewPage,
    },
    meta: {
      title: 'Component Demo',
    },
    children: [
      {
        path: 'ds-demo',
        components: {
          default: DSPageLayout,
        },
        meta: {
          title: 'DesignSystemDemo',
        },
        children: [
          {
            path: 'color-scheme',
            name: ERouteName.DS_COLOR_SCHEME,
            components: {
              default: DSColorScheme,
            },
            meta: {
              title: 'ColorScheme',
            },
          },
          {
            path: '',
            name: ERouteName.DS_ROOT,
            components: {
              default: DSAbout,
            },
            meta: {
              title: 'DSAbout',
            },
          },
          {
            path: '*',
            name: ERouteName.DS_DEFAULT,
            components: {
              default: DSAbout,
            },
            meta: {
              title: 'DSAbout',
            },
          },
        ],
      },
      {
        path: '',
        name: ERouteName.COMPONENT_DEMO_ROOT,
        components: {
          default: ComponentDemoPage,
        },
        meta: {
          title: 'Component overview',
        },
      },
      {
        path: '*',
        name: ERouteName.COMPONENT_DEMO_DEFAULT,
        components: {
          default: ComponentDemoPage,
        },
        meta: {
          title: 'Component overview',
        },
      },
    ],
  },
];

export default process.env.NODE_ENV === 'production' ? [] : routes;
