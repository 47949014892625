
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  watch,
} from 'vue';
import EColorScheme from '@/module/common/models/EColorScheme';
import DSSwitcher from '@/module/design-system/components/UI/DSSwitcher.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import CLabel from '@/module/common/components/UI/CLabel.vue';

interface IState {
  localValue: boolean,
}

export default defineComponent({
  name: 'CSwitcher',
  components: {
    CLabel,
    DSSwitcher,
    DSTextElement,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      required: false,
      default: null,
    },
    color: {
      type: String as PropType<EColorScheme>,
      default: () => EColorScheme.PRIMARY,
    },
    revert: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit, slots }) {
    const state: IState = reactive<IState>({
      localValue: false,
    });

    const textColor = computed(() => (props.color === EColorScheme.PRIMARY
      ? EColorScheme.SECONDARY
      : EColorScheme.MENU));
    const hasSlot = computed(() => !!slots['switcher-label']);

    function handleChange(event) {
      emit('change', event);
    }

    const rowClasses = computed(() => [
      'c-switcher__row',
      props.revert ? 'c-switcher__row_revert' : null,
    ]);

    onMounted(() => {
      state.localValue = props.value;
    });

    watch(props, (newValue) => {
      state.localValue = newValue.value;
    });

    return {
      state,
      textColor,
      hasSlot,
      handleChange,
      rowClasses,
    };
  },
});
