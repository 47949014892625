import axios from 'axios';

export default (serviceContainer) => ({
  namespaced: true,
  state: {
    cancellingRequestToken: axios.CancelToken.source(),
    isUpdating: false,
    isRequestRunning: true,
    isLoading: true,
    responseStatus: 200,
    totalCount: 0,
    endpoints: [],
    isInitialRequest: true,
    scrollPosition: 0,
  },
  mutations: {
    setEndpoints(state, newEndpoints) {
      state.endpoints = newEndpoints;
    },

    changeRequestRunning(state, value) {
      state.isRequestRunning = value;
    },

    setStatus(state, status) {
      state.responseStatus = status;
    },

    setTotalCount(state, value) {
      state.totalCount = value;
    },

    setCancelToken(state) {
      state.cancellingRequestToken = axios.CancelToken.source();
    },

    changeFieldValue(state, { fieldName, value }) {
      state[fieldName] = value;
    },

    updateEndpointStatus(state, value) {
      state.endpoints.forEach((endpoint) => {
        if (endpoint.id === value) {
          endpoint.status = !endpoint.status;
        }
      });
    },

    deleteEndpoint(state, id) {
      state.totalCount -= 1;
      state.endpoints = state.endpoints.filter((endpoint) => endpoint.id !== id);
    },
  },

  getters: {
    totalCount(state) {
      return state.totalCount;
    },
  },

  actions: {
    getScrollPosition({ state }, scrollPosition) {
      state.scrollPosition = scrollPosition;
    },

    async getEndpoints({ state, commit }, isOverwrite) {
      if (isOverwrite) {
        commit('changeFieldValue', { fieldName: 'isUpdating', value: true });
      }

      if (state.isRequestRunning) {
        state.cancellingRequestToken.cancel('userCancel');
      }

      commit('changeRequestRunning', true);
      commit('setCancelToken');

      try {
        const api = serviceContainer.resolve('api');

        const { status, data } = await api.webhooks.getEndpoints(
          state.cancellingRequestToken.token,
        );
        commit('setTotalCount', data.totalCount);

        if (isOverwrite) {
          commit('setEndpoints', data.endpoints);
        } else {
          const concatedEndpoints = state.endpoints.concat(data.endpoints);
          commit('setEndpoints', concatedEndpoints);
        }

        commit('setStatus', status);
        commit('changeRequestRunning', false);
        commit('changeFieldValue', { fieldName: 'isLoading', value: false });
        commit('changeFieldValue', { fieldName: 'isUpdating', value: false });
        commit('changeFieldValue', { fieldName: 'isInitialRequest', value: false });
        return { isError: false };
      } catch (error) {
        if (error.message === 'userCancel') {
          return { isError: false };
        }
        commit('setStatus', error.response.status);
        commit('changeRequestRunning', false);
        commit('changeFieldValue', { fieldName: 'isLoading', value: false });
        commit('changeFieldValue', { fieldName: 'isUpdating', value: false });
        commit('changeFieldValue', { fieldName: 'isInitialRequest', value: false });
        return { isError: true };
      }
    },
  },
});
