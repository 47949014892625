
import Vue, {
  computed,
  inject,
  onMounted,
  reactive,
} from 'vue';
import {
  Store,
} from 'vuex';

import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import CFormPageHeaderRow from '@/module/common/components/PageParts/CFormPageHeaderRow.vue';
import CFormPageLayout from '@/module/common/components/PageParts/CFormPageLayout.vue';
import CFormPageContent from '@/module/common/components/PageParts/CFormPageContent.vue';
import IState from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/IState';
import CFormComponent from '@/module/common/components/CFormComponent.vue';
import TFormData from '@/module/product/view/FulfillmentFormPage/model/types/TFormData';
import LicenseAmountService from '@/module/product/view/FulfillmentFormPage/service/LicenseAmountService';
import ScenarioService from '@/module/product/view/FulfillmentFormPage/service/ScenarioService';
import GeneratorMockService from '@/module/product/view/FulfillmentFormPage/service/GeneratorMockService';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import CInfoTextarea from '@/module/common/components/Data/CInfoTextarea.vue';
import DSTag from '@/module/design-system/components/InfoBlock/DSTag.vue';
import ELicenseSourceName from '@/module/product/view/FulfillmentFormPage/model/enums/ELicenseSourceName';
import LocalServiceContainer from '@/module/product/view/FulfillmentFormPage/LocalServiceContainer';
import HandlerService from '@/module/product/view/FulfillmentFormPage/service/HandlerService';
import ELocalServiceFulfillment from '@/module/product/view/FulfillmentFormPage/model/enums/ELocalServiceFulfillment';
import MapperService from '@/module/product/view/FulfillmentFormPage/service/MapperService';
import CRadioButton from '@/module/common/components/UI/СRadioButton.vue';
import EColorScheme from '@/module/design-system/components/models/EColorScheme';
import DSInlineAlert from '@/module/design-system/components/UI/DSInlineAlert.vue';
import HTTPMethodService from '@/module/product/view/FulfillmentFormPage/service/HTTPMethodService';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';
import TNavigationItem from '@/module/common/models/CFormPageHeaderRow/TNavigationItem';
import DSModal from '@/module/design-system/components/UI/DSModal.vue';
import ITestRequestModal from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/ITestRequestModal';
import HeaderControlsService from '@/module/product/view/FulfillmentFormPage/service/HeaderControlsService';
import EFieldName from '@/module/product/view/FulfillmentFormPage/model/enums/EFieldName';
import ILicenseSourceOption
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formConfig/ILicenseSourceOption';
import ICFormComponentSelectItemSlotProp from '@/module/common/models/CFormComponent/ICFormComponentSelectItemSlotProp';
import ILicenseSourceSlotSelectItem
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formConfig/ILicenseSourceSlotSelectItem';

export default Vue.extend({
  name: 'FulfillmentFormPage',
  components: {
    DSTag,
    CInfoTextarea,
    DSButton,
    DSTextElement,
    DSModal,
    DSInlineAlert,
    CRadioButton,
    CFormComponent,
    CFormPageContent,
    CFormPageHeaderRow,
    CFormPageLayout,
  },
  setup() {
    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;
    const localServiceContainer = new LocalServiceContainer();
    const store = serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);

    const state: IState = reactive({
      isEditMode: false,
      isLoading: true,
      isFormValid: true,
      hasGenerators: false,
      initialGeneratorType: null,
      initialLicenseSourceName: ELicenseSourceName.DEFAULT,
      licenseSourceName: ELicenseSourceName.DEFAULT,
      formData: {} as TFormData,
      formConfig: [],
      lists: {
        params: [],
      },
      modals: {
        testRequest: {} as ITestRequestModal,
      },
      isTestRequestVisible: false,
      isTestRequestProcessing: false,
    });

    const generatorMockService = new GeneratorMockService();
    const handlerService = new HandlerService(
      state,
      serviceContainer,
      localServiceContainer,
    );
    const licenseAmountService = new LicenseAmountService(serviceContainer);
    const mapperService = new MapperService(state, serviceContainer, localServiceContainer);
    const scenarioSelectService = new ScenarioService(
      state,
      serviceContainer,
      localServiceContainer,
    );
    const httpMethodService = new HTTPMethodService();
    const headerControlService = new HeaderControlsService(
      state,
      serviceContainer,
      localServiceContainer,
    );

    localServiceContainer.register(ELocalServiceFulfillment.GENERATOR_MOCK_SERVICE, generatorMockService);
    localServiceContainer.register(ELocalServiceFulfillment.HANDLER_SERVICE, handlerService);
    localServiceContainer.register(ELocalServiceFulfillment.LICENSE_AMOUNT_SERVICE, licenseAmountService);
    localServiceContainer.register(ELocalServiceFulfillment.MAPPER_SERVICE, mapperService);
    localServiceContainer.register(ELocalServiceFulfillment.SCENARIO_SERVICE, scenarioSelectService);
    localServiceContainer.register(ELocalServiceFulfillment.HTTP_METHOD_SERVICE, httpMethodService);
    localServiceContainer.register(ELocalServiceFulfillment.HEADER_CONTROL_SERVICE, headerControlService);

    const formHandler = handlerService.getFormHandler();
    const methodHandler = handlerService.getHttpMethodHandler();
    const modalHandler = handlerService.getModalHandler();

    const localisationRegistry: ILocalisationRegistry = store.getters.getLocalisationRegistry;
    const pageTitle = computed(() => localisationRegistry.fulfillment.form.title);

    const headerButtonsLeft = computed<TNavigationItem[]>(
      () => headerControlService.getLeftColumnItems(),
    );

    const headerButtonsRight = computed<TNavigationItem[]>(
      () => headerControlService.getRightColumnItems(state.formData),
    );

    onMounted(() => {
      const handler = handlerService.getInitialDataLoadHandler();

      handler.load();
    });

    function getLicenseSourceSelectItemClasses(slotData: ILicenseSourceSlotSelectItem) {
      const { item, option } = slotData;
      const classes = [
        'fulfillment-form__select-item',
      ];

      if (!item.select) {
        return classes;
      }

      const index = (item.select.list as ILicenseSourceOption[])
        .findIndex((listItem: ILicenseSourceOption) => listItem.id === option.id);
      if (index === item.select.list.length - 1) {
        classes.push('fulfillment-form__select-item_last');
      }

      return classes;
    }

    function getLicenseSourceSelectItemStatusClasses(option) {
      const classes = [
        'fulfillment-form__select-item-additional',
      ];

      if (state.formData.licenseSource?.id !== option.id) {
        classes.push('fulfillment-form__select-item-additional_hide');
      }

      return classes;
    }

    const inlineAlert = computed(() => {
      if (state.isLoading) {
        return null;
      }

      const localisation = localisationRegistry.fulfillment.form.form.alert;

      const isEmailSent = store.getters['authStore/getStatusFullfilmentSupport'] === 'send';

      if (!state.isEditMode && state.hasGenerators) {
        return null;
      }

      if (!state.isEditMode && isEmailSent) {
        return {
          text: localisation.mailSent,
          variant: EColorScheme.WARNING,
        };
      }

      if (state.isEditMode) {
        return {
          text: localisation.warningOnEdit,
          variant: EColorScheme.WARNING,
        };
      }

      return {
        text: localisation.noFulfillment,
        variant: EColorScheme.ERROR,
      };
    });

    const selectedText = computed(() => localisationRegistry.common.selected);

    function isLicenseSourceSlotSelectItem(
      slotData: ICFormComponentSelectItemSlotProp<EFieldName, undefined>,
    ): slotData is ILicenseSourceSlotSelectItem {
      return slotData.item.fieldName === 'licenseSource';
    }

    return {
      inlineAlert,
      pageTitle,
      headerButtonsLeft,
      headerButtonsRight,
      formHandler,
      methodHandler,
      modalHandler,
      state,
      selectedText,
      isLicenseSourceSlotSelectItem,
      getLicenseSourceSelectItemClasses,
      getLicenseSourceSelectItemStatusClasses,
    };
  },
});
