import EMerchantMode from '@/module/common/models/EMerchantMode';

interface IMerchantMode {
  value: boolean,
  name: EMerchantMode,
}

export default function mapAllowedMerchantModes(stringsArray: EMerchantMode[]): IMerchantMode[] {
  const merchantModesMap = {
    [EMerchantMode.TEST]: true,
    [EMerchantMode.SALES]: false,
  };

  return stringsArray.map((merchantMode) => (
    {
      name: merchantMode,
      value: merchantModesMap[merchantMode],
    }
  ));
}

export {
  IMerchantMode,
};
