<template>
  <div
    :class="classes"
  >
    <DSTextElement
      :color="color"
      class="c-percentage__text"
      variant="small-medium"
    >
      {{ percentString }}
    </DSTextElement>
  </div>
</template>

<script>
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default {
  name: 'CPercentage',
  components: {
    DSTextElement,
  },
  props: {
    percent: {
      type: Number,
      require: true,
      default: null,
    },
    inverseColor: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      let color = this.inverseColor ? 'error' : 'success';
      if (this.percent === 0) {
        color = 'disabled';
      } else if (this.percent < 0) {
        color = this.inverseColor ? 'success' : 'error';
      }

      return color;
    },
    classes() {
      return [
        'c-percentage',
        `c-percentage_${this.color}`,
      ];
    },
    percentString() {
      const numberFormatService = this.$container.numberFormatService;
      const prefix = this.percent > 0 ? '+' : '';

      return `${prefix}${numberFormatService.percentage(this.percent ?? 0)}`;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import "@/module/design-system/components/variables.scss";

.c-percentage {
  width: auto;
  padding: 4px;
  border-radius: 4px;

  &_success {
    background: transparentize($text-color-success, 0.95);
  }

  &_error {
    background: transparentize($text-color-error, 0.95);
  }

  &_disabled {
    background: transparentize($text-color-disabled, 0.95);
  }

  &__text {
    display: block;
    white-space: nowrap;
  }
}
</style>
