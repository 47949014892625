import defaultLayout from '@/router/defaultLayout';
import ERouteName from '@/module/common/models/router/ERouteName';
import DataExports from '../view/DataExportsPage.vue';

const moduleSlug = 'data-exports';

export default () => [
  {
    path: `/${moduleSlug}`,
    name: ERouteName.DATA_EXPORTS,
    components: {
      default: DataExports,
      ...defaultLayout,
    },
    meta: { title: 'titles.data_exports' },
  },
];
