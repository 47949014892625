import readProp from '@/module/common/utils/data/readProp';
import notEmptyValidator from '@/module/common/utils/validator/validators/notEmptyValidator';

enum EConditionType {
  AND = 'and',
  OR = 'or',
  EQUALS = 'equals',
  NOT_EQUALS = 'notEquals',
  NOT_EMPTY = 'notEmpty',
}

interface ICondition {
  type: EConditionType;
  field: string;
  value: unknown;
  conditionsList?: ICondition[];
}

export default {
  resolveAndCondition(conditionsList, data) {
    return conditionsList.reduce((initial, condition) => {
      if (!initial) {
        return false;
      }

      return initial && this.resolveCondition(condition, data);
    }, true);
  },
  resolveNotEmptyCondition(field: string, data: object): boolean {
    const dataValue = readProp(data, field);

    return notEmptyValidator(dataValue);
  },
  resolveEqualityCondition(field: string, data: object, value: unknown): boolean {
    const dataValue = readProp(data, field);

    return dataValue === value;
  },
  resolveOrCondition(conditionsList: ICondition[], data: object) {
    return conditionsList.reduce(
      (initial, condition) => initial || this.resolveCondition(condition, data),
      false,
    );
  },
  resolveCondition(condition: ICondition, data: object): boolean {
    let resolved = true;

    switch (condition.type) {
      case EConditionType.AND:
        resolved = this.resolveAndCondition(condition.conditionsList, data);
        break;
      case EConditionType.OR:
        resolved = this.resolveOrCondition(condition.conditionsList ?? [], data);
        break;
      case EConditionType.EQUALS:
        resolved = this.resolveEqualityCondition(condition.field, data, condition.value);
        break;
      case EConditionType.NOT_EQUALS:
        resolved = !this.resolveEqualityCondition(condition.field, data, condition.value);
        break;
      case EConditionType.NOT_EMPTY:
        resolved = this.resolveNotEmptyCondition(condition.field, data);
        break;
      default:
        throw new Error('Invalid condition type.');
    }

    return resolved;
  },
};

export {
  EConditionType,
  ICondition,
};
