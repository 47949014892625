<template>
  <li
    class="promotion-discount-list-item"
  >
    <div
      v-if="product"
      class="create-edit-promotion__product-name"
    >
      {{ product.name }}

      <span
        class="create-edit-promotion__delete-icon"
        @click="handleDeleteProduct($event, productIndex)"
      >
        <DSIcon
          icon="trash"
          size="s"
        />
      </span>
    </div>

    <template v-if="variant === 'custom'">
      <CMaskedInput
        :mask-options="maskOptions"
        :label="$t('promotions.form.amount_of_discount')"
        :value="amountOfDiscount"
        :status="discountStatus"
        @input="updateCustomAmountOfDiscount"
      />
    </template>

    <PromotionPricing
      v-if="product"
      :percent="preparedPercent"
      :variants="product.variants"
      :locale="locale"
    />
  </li>
</template>

<script>
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import PromotionPricing from '@/components/Pricing/PromotionPricing.vue';
import CMaskedInput from '@/module/common/components/UI/MaskedInput/CMaskedInput.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import SimpleVueValidatior from 'simple-vue-validator';

const { Validator } = SimpleVueValidatior;

export default {
  name: 'PromotionDiscountListItem',
  components: {
    DSIcon,
    CMaskedInput,
    PromotionPricing,
  },
  mixins: [
    SimpleVueValidatior.mixin,
  ],
  props: {
    product: {
      type: Object,
      default: null,
    },
    variant: {
      type: String,
      default: 'common',
      validator: (value) => [
        'common',
        'custom',
        'all-catalog',
      ].includes(value),
    },
    productIndex: {
      type: Number,
      default: 0,
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
    amountOfDiscount: {
      type: String,
      default: '',
    },
    submitted: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      locale: 'getLocale',
    }),
    preparedPercent() {
      const utilsService = this.$container.resolve('utilsService');
      const amountOfDiscount = utilsService.string.replaceLast(this.amountOfDiscount ?? '', ',', '.');

      return Number((amountOfDiscount || '').split('%')[0]);
    },
    maskOptions() {
      const numberFormatService = this.$container.resolve('numberFormatService');

      return {
        mask: 'num%',
        eager: true,
        blocks: {
          num: {
            mask: Number,
            scale: 6,
            radix: numberFormatService.getPercentDelimiter(),
            mapToRadix: [numberFormatService.getPercentDelimiter()],
            max: 99,
            min: 1,
          },
        },
      };
    },
    discountStatus() {
      const status = this.validation.hasError('amountOfDiscount') ? 'error' : 'default';
      const statusMap = {
        error: {
          code: 'error',
          text: this.validation.firstError('amountOfDiscount'),
        },
        default: {
          code: 'default',
        },
      };

      return statusMap[status];
    },
  },
  watch: {
    async submitted() {
      const resolve = await this.$validate();
      this.$emit('update:isListProductsValid', resolve);
    },
  },
  methods: {
    ...mapActions({
      handleDeleteProductClick: 'deleteModalStore/handleDeleteProductClick',
    }),
    ...mapMutations({
      deleteCommonProductId: 'createEditPromotionStore/deleteCommonProductIdFromList',
      deleteCustomProductId: 'createEditPromotionStore/deleteCustomProductFromList',
      changeDeleteModalFieldValue: 'deleteModalStore/changeFieldValue',
    }),
    updateCustomAmountOfDiscount(newAmount) {
      this.$emit('update:amountOfDiscount', { productIndex: this.currentIndex, newAmount });
    },
    deleteProduct(index) {
      if (this.variant === 'common') {
        this.deleteCommonProductId(index);
      }
      if (this.variant === 'custom') {
        this.deleteCustomProductId(index);
      }
      this.changeDeleteModalFieldValue({ fieldName: 'isOpened', newValue: false });
      this.$emit('remove');
    },
    async handleDeleteProduct(event, productId) {
      event.stopPropagation();
      const response = await this.$api.products.getProduct(productId);
      const productToDelete = response.data.product;
      this.changeDeleteModalFieldValue({ fieldName: 'id', newValue: productId });
      this.changeDeleteModalFieldValue({ fieldName: 'isOpened', newValue: true });
      this.changeDeleteModalFieldValue({
        fieldName: 'activeModalData',
        newValue: {
          dataToDelete: productToDelete,
          title: this.$t('modal_window.delete_product'),
          buttonKeep: this.$t('modal_window.keep'),
          buttonDelete: this.$t('modal_window.delete'),
          handleDeleteClick: () => this.deleteProduct(this.currentIndex),
        },
      });
    },
  },
  validators: {
    amountOfDiscount(value) {
      const utilsService = this.$container.resolve('utilsService');
      value = utilsService.string.replaceLast(value, ',', '.');
      value = utilsService.string.replaceLast(value, '%', '');

      const validateNum = value ? Number(`${value}`) : '';
      const minPercent = 1;
      const maxPercent = 100;

      return Validator.value(validateNum)
        .required(this.$t('validation_messages.required'))
        .greaterThanOrEqualTo(minPercent, this.$t('validation_messages.discount_percent_interval'))
        .lessThan(maxPercent, this.$t('validation_messages.discount_percent_interval'));
    },
  },
};
</script>

<style lang="scss">
.promotion-discount-list-item {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px
}

.list-item__input {
  display: flex;
  align-items: center;

  .label {
    label {
      margin: 0;
    }
  }

  .mask-input__control {
    margin-left: 10px;

    input {
      height: 32px;
      width: 93px;
    }
  }
}
</style>
