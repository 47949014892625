
import Vue, {
  inject,
} from 'vue';

import EColorScheme from '@/module/design-system/components/models/EColorScheme';
import EIcon from '@/module/design-system/models/EIcon';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IUtilsService from '@/module/common/service/UtilsService/contract/IUtilsService';

import CLabel from '@/module/common/components/UI/CLabel.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default Vue.extend({
  name: 'CInfoTextarea',
  components: {
    CLabel,
    DSIcon,
    DSTextElement,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    showCopyIcon: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;
    const utilsService = serviceContainer.resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);
    const clipboard = utilsService.clipboard;

    function copyClick() {
      clipboard.copy(props.text).then(() => {
        emit('copied');
      });
    }

    const icon = {
      icon: EIcon.COPY,
      color: EColorScheme.LINKED,
      handler: () => copyClick(),
    };

    return {
      icon,
    };
  },
});
