
import Vue, {
  computed,
  inject,
  onBeforeUnmount,
} from 'vue';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';

import CFormPageLayout from '@/module/common/components/PageParts/CFormPageLayout.vue';
import CFormPageHeaderRow from '@/module/common/components/PageParts/CFormPageHeaderRow.vue';
import CFormPageContent from '@/module/common/components/PageParts/CFormPageContent.vue';
import CFormComponent from '@/module/common/components/CFormComponent.vue';

export default Vue.extend({
  name: 'PaymentLinkFormPage',
  components: {
    CFormComponent,
    CFormPageContent,
    CFormPageHeaderRow,
    CFormPageLayout,
  },
  setup() {
    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;

    const productModule = serviceContainer.moduleProduct;

    const paymentLinkFormPage = productModule.paymentLinkFormPage;

    const formHandler = paymentLinkFormPage.formHandler;

    const state = paymentLinkFormPage.stateHandler.get();
    const headerRow = computed(() => paymentLinkFormPage.headerRowService.getHeaderRowData());
    state.formConfig = paymentLinkFormPage.formConfigHandler.get();

    onBeforeUnmount(() => {
      paymentLinkFormPage.stateHandler.clear();
    });

    return {
      state,
      headerRow,
      formHandler,
    };
  },
});
