enum EFormFieldType {
  INPUT = 'input',
  SELECT = 'select',
  FILTERED_SELECT = 'filteredSelect',
  EXPANDABLE_LIST = 'expandableList',
  MASKED_INPUT = 'maskedInput',
  BUTTON_GROUP = 'buttonGroup',
  DATE = 'date',
  TEXT_AREA = 'textarea',
  FILE_UPLOAD = 'fileUpload',
  CHECKBOX_GROUP = 'checkboxGroup',
  CHECKBOX = 'checkbox',
  SWITCHER = 'switcher',
  INPUT_LIST = 'inputList',
  INPUT_WITH_SELECT_LIST = 'inputWithSelectList',
  INPUT_WITH_SELECT = 'inputWithSelect',
  INFO_BLOCK = 'infoBlock',
  DELIMITER = 'delimiter',
  PRICING_TABLE = 'pricingTable',
  TABLE = 'table',
  SLOT = 'slot',
}

export default EFormFieldType;
