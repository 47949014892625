import ILicenseVariantHandler
  from '@/module/product/view/FulfillmentFormPage/model/contract/handler/ILicenseVariantHandler';
import ILicenseSourceOption
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formConfig/ILicenseSourceOption';
import IMerchantModeService from '@/module/common/models/service/MerchantMode/IMerchantModeService';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import { Store } from 'vuex';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';
import EMerchantMode from '@/module/common/models/EMerchantMode';
import ELicenseSourceName from '@/module/product/view/FulfillmentFormPage/model/enums/ELicenseSourceName';
import FormConfigError from '@/module/product/view/FulfillmentFormPage/FormConfigError';
import EGeneratorType from '@/module/product/view/FulfillmentFormPage/model/enums/EGeneratorType';
import IState from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/IState';

class LicenseVariantHandler implements ILicenseVariantHandler {
  private readonly state: IState;

  private readonly serviceContainer: IServiceContainer;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
  }

  getLicenseSourceList = (): ILicenseSourceOption[] => {
    const merchantModeService = this.serviceContainer
      .resolve<IMerchantModeService>(EServiceContainerInstances.MERCHANT_MODE_SERVICE);
    const merchantMode = merchantModeService.getMerchantMode();

    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const localisationRegistry: ILocalisationRegistry = store.getters.getLocalisationRegistry;
    const optionLocalisation = localisationRegistry.fulfillment.form.form.licenseSource.options;

    const licenseSourceListMap = {
      [EMerchantMode.TEST]: [
        {
          id: ELicenseSourceName.TEST_CREATE_GENERATOR,
          title: optionLocalisation.test.createGenerator.title,
          description: optionLocalisation.test.createGenerator.description,
        },
        {
          id: ELicenseSourceName.TEST_LICENSE_POOL,
          title: optionLocalisation.test.licensePool.title,
          description: optionLocalisation.test.licensePool.description,
        },
        {
          id: ELicenseSourceName.TEST_MOCK_GENERATOR,
          title: optionLocalisation.test.testGenerator.title,
          description: optionLocalisation.test.testGenerator.description,
        },
      ],
      [EMerchantMode.SALES]: [
        {
          id: ELicenseSourceName.SALES_CREATE_GENERATOR,
          title: optionLocalisation.sales.createGenerator.title,
          description: optionLocalisation.sales.createGenerator.description,
        },
        {
          id: ELicenseSourceName.SALES_LICENSE_POOL,
          title: optionLocalisation.sales.licensePool.title,
          description: optionLocalisation.sales.licensePool.description,
        },
        {
          id: ELicenseSourceName.SALES_OTHER,
          title: optionLocalisation.sales.other.title,
          description: optionLocalisation.sales.other.description,
        },
      ],
    };

    let currentLicenseSourceList = licenseSourceListMap[merchantMode];

    if (this.state.initialGeneratorType === EGeneratorType.INFORMATIONAL_EMAIL) {
      currentLicenseSourceList = [
        {
          id: ELicenseSourceName.CURRENT_FULFILLMENT,
          title: optionLocalisation.test.currentFulfillment.title,
          description: '',
        },
        ...currentLicenseSourceList,
      ];
    }

    if (!currentLicenseSourceList) {
      throw new FormConfigError('License source list does not exist');
    }

    return currentLicenseSourceList;
  };

  getByGeneratorType = (generatorType: EGeneratorType): ILicenseSourceOption => {
    const map = {
      [EGeneratorType.WEB_SERVICE]: [
        ELicenseSourceName.SALES_CREATE_GENERATOR,
        ELicenseSourceName.TEST_CREATE_GENERATOR,
      ],
      [EGeneratorType.LICENSE_POOL]: [
        ELicenseSourceName.SALES_LICENSE_POOL,
        ELicenseSourceName.TEST_LICENSE_POOL,
      ],
      [EGeneratorType.INFORMATIONAL_EMAIL]: [
        ELicenseSourceName.CURRENT_FULFILLMENT,
      ],
      [EGeneratorType.TEST_WEB_SERVICE]: [
        ELicenseSourceName.TEST_MOCK_GENERATOR,
      ],
    };

    return this.getLicenseSourceList()
      .find((item) => map[generatorType].includes(item.id)) as ILicenseSourceOption;
  };
}

export default LicenseVariantHandler;
