<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      d="M9 1.125C4.65117 1.125 1.125 4.65117 1.125 9C1.125 13.3488 4.65117 16.875 9 16.875C13.3488 16.875 16.875 13.3488 16.875 9C16.875 4.65117 13.3488 1.125 9 1.125ZM8.4375 5.20312C8.4375 5.12578 8.50078 5.0625 8.57812 5.0625H9.42188C9.49922 5.0625 9.5625 5.12578 9.5625 5.20312V9.98438C9.5625 10.0617 9.49922 10.125 9.42188 10.125H8.57812C8.50078 10.125 8.4375 10.0617 8.4375 9.98438V5.20312ZM9 12.9375C8.77921 12.933 8.56898 12.8421 8.41442 12.6844C8.25986 12.5266 8.1733 12.3146 8.1733 12.0938C8.1733 11.8729 8.25986 11.6609 8.41442 11.5031C8.56898 11.3454 8.77921 11.2545 9 11.25C9.22079 11.2545 9.43103 11.3454 9.58558 11.5031C9.74014 11.6609 9.8267 11.8729 9.8267 12.0938C9.8267 12.3146 9.74014 12.5266 9.58558 12.6844C9.43103 12.8421 9.22079 12.933 9 12.9375Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSExclamationIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>

<style scoped>

</style>
