<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :stroke="color"
      d="M20 12H4M20 17H4M20 7H4"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: 'DSBurgerMenuIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>
