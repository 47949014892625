enum EFileMimeType {
  IMAGE_GIF = 'image/gif',
  IMAGE_JPG = 'image/jpg',
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_PJPEG = 'image/pjpeg',
  IMAGE_PNG = 'image/png',
  IMAGE_X_PNG = 'image/x-png',
}

export default EFileMimeType;
