import ILicenseAmountService
  from '@/module/product/view/FulfillmentFormPage/model/contract/service/ILicenseAmountService';
import ILicenseAmount from '@/module/product/view/FulfillmentFormPage/model/interfaces/formConfig/ILicenseAmount';
import { Store } from 'vuex';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';

class LicenseAmountService implements ILicenseAmountService {
  private serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  get = (id: boolean): ILicenseAmount => this.list().find((variant) => variant.id === id) as ILicenseAmount;

  list = (): ILicenseAmount[] => {
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const localisationRegistry = store.getters.getLocalisationRegistry;
    const formLocalisation = localisationRegistry.fulfillment.form;

    return [
      {
        id: true,
        title: formLocalisation.form.licenseAmount.buttons.onePerUnit,
      },
      {
        id: false,
        title: formLocalisation.form.licenseAmount.buttons.onePerProduct,
      },
    ];
  };
}

export default LicenseAmountService;
