import axios from 'axios';

import improvedTrim from '@/helpers/improvedTrim';

export default (serviceContainer) => ({
  namespaced: true,
  state: {
    filterOptions: {
      search: '',
      type: 'all',
      status: 'all',
      sortBy: 'desc',
      offset: 0,
      limit: 20,
    },

    // counters
    totalCount: null,
    searchCount: null,

    // promotions list
    list: [],

    cancellingRequestToken: axios.CancelToken.source(),

    // loaders
    isRequestRunning: false,
    isInitialRequest: true,
    isOverwriting: false,

    responseStatus: null,
    isError: false,
  },
  mutations: {
    updateSearchValue(state, newSearchValue) {
      state.filterOptions.search = newSearchValue;
    },
    updateTypeValue(state, newTypeValue) {
      state.filterOptions.type = newTypeValue;
    },
    updateStatusValue(state, newStatusValue) {
      state.filterOptions.status = newStatusValue;
    },
    updateSortByValue(state, newSortByValue) {
      state.filterOptions.sortBy = newSortByValue;
    },
    setIsRequestRunning(state, isRunning) {
      state.isRequestRunning = isRunning;
    },
    updateResponseStatus(state, newStatus) {
      state.responseStatus = newStatus;
    },
    setIsError(state, isError) {
      state.isError = isError;
    },
    setCancellingToken(state) {
      state.cancellingRequestToken = axios.CancelToken.source();
    },
    setIsInitialRequest(state, isRunning) {
      state.isInitialRequest = isRunning;
    },
    setTotalCount(state, newCount) {
      state.totalCount = newCount;
    },
    setSearchCount(state, newCount) {
      state.searchCount = newCount;
    },
    setOffset(state, newOffset) {
      state.filterOptions.offset = newOffset;
    },
    setPromotionsList(state, newList) {
      state.list = newList;
    },
    setIsOverwriting(state, isOverwriting) {
      state.isOverwriting = isOverwriting;
    },
  },
  getters: {
    getSearchValue(state) {
      return state.filterOptions.search;
    },
    getTypeValue(state) {
      return state.filterOptions.type;
    },
    getStatusValue(state) {
      return state.filterOptions.status;
    },
    getSortByValue(state) {
      return state.filterOptions.sortBy;
    },
    getPromotionsQuery(state) {
      const offset = `?offset=${state.filterOptions.offset}`;
      const limit = `&limit=${state.filterOptions.limit}`;
      const searchString = improvedTrim(state.filterOptions.search).length
        ? `&searchString=${improvedTrim(state.filterOptions.search)}`
        : '';
      const sortByUpdateDate = `&sortByUpdateDate=${state.filterOptions.sortBy}`;
      const promotionType = state.filterOptions.type !== 'all'
        ? `&promotionType=${state.filterOptions.type}`
        : '';
      const promotionStatus = state.filterOptions.status !== 'all'
        ? `&promotionStatus=${state.filterOptions.status}`
        : '';

      return offset + limit + searchString + sortByUpdateDate + promotionType + promotionStatus;
    },
    getIsRequestRunning(state) {
      return state.isRequestRunning;
    },
    getIsInitialRequest(state) {
      return state.isInitialRequest;
    },
    getOffset(state) {
      return state.filterOptions.offset;
    },
    getLimit(state) {
      return state.filterOptions.limit;
    },
    getPromotionsList(state) {
      return state.list;
    },
    getTotalCount(state) {
      return state.totalCount;
    },
    getSearchCount(state) {
      return state.searchCount;
    },
    getIsOverwriting(state) {
      return state.isOverwriting;
    },
  },
  actions: {
    async getPromotionsList({ state, commit, getters }, isOverwrite) {
      if (getters.isRequestRunning) {
        state.cancellingRequestToken.cancel('searchCancel');
      }

      commit('setIsRequestRunning', true);
      commit('setCancellingToken');

      try {
        if (isOverwrite) {
          commit('setOffset', 0);
          commit('setIsOverwriting', true);
        }

        const api = serviceContainer.resolve('api');

        const response = await api.promotion.getPromotions(
          getters.getPromotionsQuery,
          state.cancellingRequestToken.token,
        );

        if (getters.getIsInitialRequest) commit('setTotalCount', response.data.totalCount);
        commit('setSearchCount', response.data.totalCount);

        if (isOverwrite) {
          commit('setPromotionsList', response.data.promotions);
        } else {
          commit('setPromotionsList', getters.getPromotionsList.concat(response.data.promotions));
        }

        commit('setIsInitialRequest', false);
        commit('setIsOverwriting', false);
        commit('setOffset', getters.getOffset + getters.getLimit);

        // добавить выставление статуса респонса
      } catch (error) {
        if (error.message === 'searchCancel') {
          commit('setIsError', false);
          return;
        }

        commit('setIsError', true);
        commit('setIsOverwriting', false);
      } finally {
        commit('setIsRequestRunning', false);
      }
    },
    async deletePromotion({ commit }, promotionId) {
      commit('setIsInitialRequest', true);
      commit('setIsRequestRunning', true);
      commit('setIsOverwriting');

      const api = serviceContainer.resolve('api');

      try {
        const response = await api.promotion.delete(promotionId);
        return response.status;
      } catch (error) {
        return error.response.status;
      }
    },
    async changeStatus({ commit, getters }, promotionId) {
      commit('setIsInitialRequest', true);
      commit('setIsRequestRunning', true);
      commit('setIsOverwriting');

      const promotion = getters.getPromotionsList
        .find((promotionItem) => promotionItem.promotionId === promotionId);

      const requestBody = {
        promotionType: promotion.promotionType,
        status: promotion.promotionStatus.toLowerCase() !== 'active',
      };

      const api = serviceContainer.resolve('api');

      try {
        const response = await api.promotion.setNewStatus(promotionId, requestBody);
        return response.status;
      } catch (error) {
        return error.response.status;
      }
    },
  },
});
