import IBasePageLocalisation from '@/module/common/utils/localisation/models/IBasePageLocalisation';
import IVueInstance from '@/module/common/models/IVueInstance';
import getLocalisationVue2 from '@/module/common/helpers/getLocalisationVue2';
import mapObjectSnakeKeysToCamelCaseRecursive
  from '@/module/common/utils/object/mapObjectSnakeKeysToCamelCaseRecursive';
import IFieldStatus from '@/module/design-system/components/models/FormComponent/IFieldStatus';
import Validator from '@/module/common/utils/validator/Validator';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import TTSApi from '@/module/common/tsApi/TTSApi';
import IDSIcon from '@/module/design-system/components/models/IDSIcon';
import EColorScheme from '@/module/design-system/components/models/EColorScheme';
import EIcon from '@/module/design-system/components/models/EIcon';
import EProtocols from '@/module/common/models/EProtocols';
import ENotifyVariant from '@/module/design-system/components/models/UI/ENotifyVariant';
import ICamelCaseLocalisationPopupMessages
  from '@/module/common/utils/localisation/initialisator/model/ICamelCaseLocalisationPopupMessages';
import ICamelCaseLocalisationCommon
  from '@/module/common/utils/localisation/initialisator/model/ICamelCaseLocalisationCommon';
import EValidatorType from '@/module/common/utils/validator/enum/EValidatorType';

interface ILocalisationCreateEditProductPage extends IBasePageLocalisation {
  validation_messages: {
    max_length: string;
    url: string;
    required: string;
    only_numbers: string;
  };
  modals: {
    software_registry: {
      confirm_switch_off: {
        title: string;
        text: string;
        buttons: {
          delete: string;
          keep: string;
        };
      };
      software_registry: {
        title: string;
        inputs: {
          link: {
            label: string;
            placeholder: string;
          };
          number: {
            label: string;
            placeholder: string;
          };
        };
        buttons: {
          add: string;
        };
      };
    };
  };
  software_registry: {
    label: string;
    description: string;
    tooltip: string;
    alert: {
      success: {
        text: string;
      };
      error: {
        title: string;
        text: string;
      };
      server_error: {
        title: string;
        text: string;
      };
    };
    summary: {
      label: string;
      in_registry: string;
      not_in_registry: string;
    };
  };
}

interface ICamelCaseLocalisationCreateEditProductPage {
  validationMessages: {
    maxLength: string;
    required: string;
    url: string;
    onlyNumbers: string;
  };
  modals: {
    softwareRegistry: {
      confirmSwitchOff: {
        title: string;
        text: string;
        buttons: {
          delete: string;
          keep: string;
        };
      };
      softwareRegistry: {
        title: string;
        inputs: {
          link: {
            label: string;
            placeholder: string;
          };
          number: {
            label: string;
            placeholder: string;
          };
        };
        buttons: {
          add: string;
        };
      };
    };
  };
  softwareRegistry: {
    label: string;
    description: string;
    tooltip: string;
    alert: {
      success: {
        text: string;
      };
      error: {
        title: string;
        text: string;
      };
      serverError: {
        title: string;
        text: string;
      };
    };
    summary: {
      label: string;
      inRegistry: string;
      notInRegistry: string;
    };
  };
}

enum ERegistryDataAlertStatus {
  DEFAULT = 'default',
  SUCCESS = 'success',
  ERROR = 'error',
  SERVER_ERROR = 'serverError',
}

interface IRegistryDataAlert {
  title?: string;
  text: string;
  variant: EColorScheme;
  icon?: IDSIcon;
}

function getButtonName(): string {
  return 'productTryAgain';
}

function initSuccessPopUp(popupLocalisation: ICamelCaseLocalisationPopupMessages) {
  return {
    title: popupLocalisation.savedProduct,
    text: popupLocalisation.canChange,
    type: ENotifyVariant.INFO,
    component: 'DSPopup',
  };
}

function initErrorPopUp(
  popupLocalisation: ICamelCaseLocalisationPopupMessages,
  commonLocalisation: ICamelCaseLocalisationCommon,
) {
  return {
    title: popupLocalisation.error,
    text: popupLocalisation.wentWrong,
    type: ENotifyVariant.ERROR,
    component: 'DSPopup',
    control: [
      {
        text: commonLocalisation.tryAgain,
        name: getButtonName(),
      },
    ],
  };
}

function getRegistryDataAlert(
  localisation: ICamelCaseLocalisationCreateEditProductPage,
  status: ERegistryDataAlertStatus,
): IRegistryDataAlert {
  let alertData;
  switch (status) {
    case ERegistryDataAlertStatus.SUCCESS:
      alertData = {
        text: localisation.softwareRegistry.alert.success.text,
        variant: EColorScheme.SUCCESS,
        icon: {
          color: EColorScheme.SUCCESS,
          icon: EIcon.CHECK_CIRCLE_FILLED,
        },
      };
      break;
    case ERegistryDataAlertStatus.ERROR:
      alertData = {
        title: localisation.softwareRegistry.alert.error.title,
        text: localisation.softwareRegistry.alert.error.text,
        variant: EColorScheme.ERROR,
        icon: {
          color: EColorScheme.ERROR,
        },
      };
      break;
    case ERegistryDataAlertStatus.SERVER_ERROR:
      alertData = {
        title: localisation.softwareRegistry.alert.serverError.title,
        text: localisation.softwareRegistry.alert.serverError.text,
        variant: EColorScheme.ERROR,
        icon: {
          color: EColorScheme.ERROR,
        },
      };
      break;
    default:
      break;
  }

  return alertData;
}

async function checkRegistry(tsApi: TTSApi, registrationNumber: string) {
  return tsApi.productRegistryValidator.get('', { params: { registrationNumber } });
}

function lowerCaseProtocol(url: string): string {
  const parts: string[] = url.split(':');

  if (parts.length === 1) {
    return url;
  }

  return `${(parts.shift() ?? '').toLowerCase()}:${parts.join(':')}`;
}

function validateSoftwareRegistryLinkBlur(
  value: unknown,
  localisation: ICamelCaseLocalisationCreateEditProductPage,
): IFieldStatus {
  const validator = new Validator();
  validator.addValidator(
    EValidatorType.NOT_EMPTY,
    localisation.validationMessages.required,
  );
  validator.addValidator(
    EValidatorType.URL,
    localisation.validationMessages.url,
    [EProtocols.HTTPS, EProtocols.HTTP],
  );
  validator.addValidator(
    EValidatorType.MAX_LENGTH,
    localisation.validationMessages.maxLength,
    255,
  );

  validator.validate(lowerCaseProtocol(value as string));

  const status: IFieldStatus = {
    code: validator.hasErrors() ? EValidationFieldStatusCode.ERROR : EValidationFieldStatusCode.DEFAULT,
  };

  if (validator.hasErrors()) {
    status.text = validator.getMessages()[0];
  }

  return status;
}

function validateSoftwareRegistryNumberBlur(
  value: undefined,
  localisation: ICamelCaseLocalisationCreateEditProductPage,
): IFieldStatus {
  const validator = new Validator();
  validator.addValidator(
    EValidatorType.NOT_EMPTY,
    localisation.validationMessages.required,
  );
  validator.addValidator(
    EValidatorType.ONLY_NUMBERS,
    localisation.validationMessages.onlyNumbers,
  );
  validator.validate(value);

  const status: IFieldStatus = {
    code: validator.hasErrors() ? EValidationFieldStatusCode.ERROR : EValidationFieldStatusCode.DEFAULT,
  };

  if (validator.hasErrors()) {
    status.text = validator.getMessages()[0];
  }

  return status;
}

function initLocalisation(vueInstance: IVueInstance): ICamelCaseLocalisationCreateEditProductPage {
  let localisation = getLocalisationVue2<ILocalisationCreateEditProductPage>(
    'pages.products.products.form',
    vueInstance,
  );
  if (!localisation) {
    localisation = {} as ILocalisationCreateEditProductPage;
  }

  return mapObjectSnakeKeysToCamelCaseRecursive<
    ILocalisationCreateEditProductPage,
    ICamelCaseLocalisationCreateEditProductPage
  >(localisation) as ICamelCaseLocalisationCreateEditProductPage;
}

export default initLocalisation;

export {
  validateSoftwareRegistryLinkBlur,
  validateSoftwareRegistryNumberBlur,
  checkRegistry,
  getRegistryDataAlert,
  initSuccessPopUp,
  initErrorPopUp,
  getButtonName,
  lowerCaseProtocol,
};
