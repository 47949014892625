import defaultLayout from '@/router/defaultLayout';
import ERouteName from '@/module/common/models/router/ERouteName';
import Branding from '../view/BrandingPage.vue';

const moduleSlug = 'branding';

export default () => [
  {
    path: `/${moduleSlug}`,
    name: ERouteName.BRANDING,
    components: {
      default: Branding,
      ...defaultLayout,
    },
    meta: { title: 'titles.branding' },
  },
];
