<template>
  <div
    class="ds-radio"
    @click="handleClick"
  >
    <input
      :id="id"
      ref="element"
      :checked="radioChecked"
      :name="name"
      class="ds-radio__input"
      type="radio"
    >
    <div
      class="ds-radio__icon"
    />
  </div>
</template>

<script>
export default {
  name: 'DSRadioButton',
  props: {
    name: {
      type: [
        String,
        Number,
      ],
      required: true,
    },
    value: {
      type: [
        String,
        Number,
      ],
      default: () => null,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      radioChecked: false,
    };
  },
  computed: {
    id() {
      return `ds-radio-id-${this._uid}`;
    },
  },
  watch: {
    checked() {
      this.radioChecked = this.checked;
    },
  },
  mounted() {
    this.radioChecked = this.checked;
  },
  methods: {
    handleClick() {
      this.radioChecked = true;

      this.$emit('input', this.radioChecked);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import "../variables";

.ds-radio {
  &:hover + .ds-radio__icon {
    border-color: $radio-border-color-hover;
  }

  &__input {
    position: absolute;
    z-index: -10;
    overflow: hidden;
    width: 0;
    height: 0;
    appearance: none;

    &:checked + .ds-radio__icon {
      border: 5px solid $radio-border-color-active;
    }

    &:hover + .ds-radio__icon {
      border-color: $radio-border-color-hover;
      background: red;
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
    margin: 0;
    border: 1px solid $radio-border-color-primary;
    border-radius: 50%;
    cursor: pointer;
    transition: all linear .2s;
    appearance: none;
  }
}
</style>
