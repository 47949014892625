
import Vue, {
  computed, inject, onMounted,
} from 'vue';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import CFormPageLayout from '@/module/common/components/PageParts/CFormPageLayout.vue';
import VueRouter from 'vue-router';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import CFormPageHeaderRow from '@/module/common/components/PageParts/CFormPageHeaderRow.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import EDSButtonVariant from '@/module/design-system/models/UI/DSButton/EDSButtonVariant';
import EIcon from '@/module/design-system/components/models/EIcon';
import EIconColorName from '@/module/design-system/components/models/EIconColorName';
import CFormPageContent from '@/module/common/components/PageParts/CFormPageContent.vue';
import ProductIconComponent from '@/module/product/components/ProductIconComponent.vue';
import CFormComponent from '@/module/common/components/CFormComponent.vue';
import PreloaderComponent from '@/components/Preloader/PreloaderComponent.vue';
import DSInlineAlert from '@/module/design-system/components/UI/DSInlineAlert.vue';
import CMaskedInput from '@/module/common/components/UI/MaskedInput/CMaskedInput.vue';
import CInput from '@/module/common/components/UI/CInput.vue';
import DSModal from '@/module/design-system/components/UI/DSModal.vue';
import DSTooltip from '@/module/design-system/components/InfoBlock/DSTooltip.vue';
import CSwitcherField from '@/module/common/components/UI/CSwitcherField.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import EventListenerRegister from '@/module/common/service/EventListenerRegister/EventListenerRegister';
import EEventName from '@/module/common/service/NotificationService/model/EEventName';

export default Vue.extend({
  name: 'ProductFormPage',
  components: {
    DSTextElement,
    CSwitcherField,
    DSTooltip,
    DSModal,
    CInput,
    CMaskedInput,
    DSInlineAlert,
    PreloaderComponent,
    CFormComponent,
    ProductIconComponent,
    CFormPageContent,
    DSIcon,
    CFormPageHeaderRow,
    CFormPageLayout,
    DSButton,
  },
  setup() {
    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;
    const router = serviceContainer.resolve<VueRouter>(EServiceContainerInstances.ROUTER);
    const portalModeService = serviceContainer.portalModeService;
    const localisationRegistry = serviceContainer.localisationRegistry;
    const dsButtonHelper = serviceContainer.helperService.dsButtonHelper;
    const productFormPage = serviceContainer.moduleProduct.productFormPage;
    const handlerService = serviceContainer.moduleProduct.productFormPage.handlerService;
    const formBlurHandler = handlerService.formBlurHandler;
    const softwareRegistryHandler = handlerService.softwareRegistryHandler;

    const state = serviceContainer.moduleProduct.productFormPage.getState();

    const eventListenerRegister = new EventListenerRegister();
    eventListenerRegister.register(
      EEventName.PRODUCT_TRY_AGAIN,
      () => {
        const formData = productFormPage.handlerService.formDataHandler.get();

        productFormPage.handlerService.saveHandler.save(formData);
      },
    );

    const header = computed(() => ({
      pageTitle: state.isEditMode
        ? localisationRegistry.productsOld.form.editProduct
        : localisationRegistry.productsOld.form.newProduct,
      cancelButton: dsButtonHelper.getButtonWithIcon(
        {
          text: localisationRegistry.productsOld.form.cancel,
          variant: EDSButtonVariant.TRETIARY,
        },
        async () => {
          productFormPage.clearState();

          await router.push('/products/').catch(() => null);
        },
        {
          icon: EIcon.CROSS,
          color: EIconColorName.LINKED,
        },
        true,
      ),
      saveButton: dsButtonHelper.getButton(
        {
          text: localisationRegistry.productsOld.form.save,
          variant: EDSButtonVariant.TRETIARY,
        },
        () => productFormPage.handlerService.saveHandler.save(productFormPage.getState().formData),
        true,
      ),
    }));

    const productIcon = computed(() => ({
      url: state.formData.imageUrl ?? undefined,
      title: state.formData.title ?? undefined,
      error: state.iconLoadError,
      handler: async (event: InputEvent) => {
        const productImageUploadHandler = productFormPage.handlerService.productImageUploadHandler;
        const formDataHandler = productFormPage.handlerService.formDataHandler;

        state.iconLoadError = undefined;

        try {
          const response = await productImageUploadHandler.handle(event);
          const formData = formDataHandler.get();

          formData.imageUrl = response.url;
          formDataHandler.update(formData);
        } catch (error) {
          if (productImageUploadHandler.isValidationError(error)) {
            state.iconLoadError = error.message;
          }
        }
      },
    }));

    const isValidSoftwareRegistry = computed(() => {
      const isSuccessLink = state.softwareRegistryStatus.link.code !== EValidationFieldStatusCode.ERROR;
      const isSuccessNumber = state.softwareRegistryStatus.number.code !== EValidationFieldStatusCode.ERROR;

      return isSuccessLink && isSuccessNumber;
    });

    onMounted(() => {
      state.isEditMode = !!router.currentRoute.params.id;
      state.formConfig = handlerService.formConfigHandler.get();

      if (!state.isEditMode) {
        state.isLoading = false;
      } else {
        handlerService.loadHandler.load(+router.currentRoute.params.id)
          .then(() => {
            state.isLoading = false;
          });
      }
    });

    return {
      state,
      header,
      productIcon,
      formBlurHandler,
      portalModeService,
      softwareRegistryHandler,
      isValidSoftwareRegistry,
    };
  },
});

