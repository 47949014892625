const generateInitialDiscountModels = () => ({
  'coupon-all-catalog': {
    discountPercent: null,
  },
  'coupon-common': {
    productId: [],
    discountPercent: null,
  },
  'coupon-custom': {
    products: [],
  },
  'discount-all-catalog': {
    discountPercent: null,
  },
  'discount-common': {
    productId: [],
    discountPercent: null,
  },
  'discount-custom': {
    products: [],
  },
});

// @todo перейти на this.$utils.date
const getTime = (date) => {
  const HH = date.getHours().toString();
  const MM = date.getMinutes().toString();
  return `${HH.length < 2 ? '0' : ''}${HH}:${MM.length < 2 ? '0' : ''}${MM}`;
};

// @todo перейти на this.$utils.date
const getSeparatedDate = (date) => {
  const [MM, DD, YYYY] = date.toLocaleDateString(
    'en',
    { year: 'numeric', month: '2-digit', day: '2-digit' },
  ).split('/');

  return `${YYYY}-${MM}-${DD}`;
};

export default (serviceContainer) => ({
  namespaced: true,
  state: {
    isExpirationDate: false,
    promotionData: {
      status: true,
      promotionType: 'discount',
      discountModelSeleted: 'all-catalog',
      promotionName: '',
      dateFrom: new Date(),
      timeFrom: getTime(new Date()),
      dateTo: new Date(),
      timeTo: '23:59',
      discountModel: {
        couponType: 'reusable',
        couponCode: [],
        models: generateInitialDiscountModels(),
      },
    },
    isDropdownProductsLoading: false,
    dropdownProducts: [],
    dropdownProductsTotalCount: 1,
    isRequestRunning: false,
    selectedProducts: [],
    dublicates: [],
  },
  mutations: {
    setProductsLoading(state, isLoading) {
      state.isDropdownProductsLoading = isLoading;
    },
    setProducts(state, newProducts) {
      state.dropdownProducts = newProducts;
    },
    setProductsTotalCount(state, totalCount) {
      state.dropdownProductsTotalCount = totalCount;
    },
    updatePromotionStatus(state, newStatus) {
      state.promotionData.status = newStatus;
    },
    updatePromotionType(state, newPromotionType) {
      switch (newPromotionType) {
        case 'discount':
          state.promotionData.discountModelSeleted = 'all-catalog';
          break;
        case 'coupon':
          state.promotionData.discountModelSeleted = 'all-catalog';
          break;
        default:
          break;
      }
      state.promotionData.promotionType = newPromotionType;
    },
    updatePromotionName(state, newPromotionName) {
      state.promotionData.promotionName = newPromotionName;
    },
    updateDate(state, { type, newDate }) {
      if (type === 'dateFrom' && (newDate.getTime() > state.promotionData.dateTo.getTime())) {
        state.promotionData.dateTo = newDate;
      }
      state.promotionData[type] = newDate;
    },
    updateTime(state, { type, newTime }) {
      state.promotionData[type] = newTime;
    },
    updateIsExpirationDate(state, isExpirationDate) {
      if (isExpirationDate) {
        state.promotionData.dateTo = state.promotionData.dateFrom;
        state.promotionData.timeTo = '23:59';
      }

      state.isExpirationDate = isExpirationDate;
    },
    updateDiscountModel(state, newDiscountModel) {
      state.promotionData.discountModelSeleted = newDiscountModel;
    },
    updateCustomDiscountList(state, newProduct) {
      state.promotionData.discountModel
        .models[`${state.promotionData.promotionType}-custom`].products.push(newProduct);
    },
    updateCommonProductIdList(state, newProductId) {
      state.promotionData.discountModel
        .models[`${state.promotionData.promotionType}-common`].productId.push(newProductId);
    },
    updateAllAmountOfDiscount(state, newAmount) {
      let value = newAmount.split('%')[0];
      if (value) {
        value += '%';
      }
      state.promotionData.discountModel
        .models[`${state.promotionData.promotionType}-all-catalog`].discountPercent = value;
    },
    updateCommonAmountOfDiscount(state, newAmount) {
      let value = newAmount.split('%')[0];
      if (value) {
        value += '%';
      }
      state.promotionData.discountModel
        .models[`${state.promotionData.promotionType}-common`].discountPercent = value;
    },
    updateCustomAmountOfDiscount(state, { productIndex, newAmount }) {
      let value = newAmount.split('%')[0];
      if (value) {
        value += '%';
      }
      state.promotionData.discountModel
        .models[`${state.promotionData.promotionType}-custom`]
        .products[productIndex].discountPercent = value;
    },
    deleteCommonProductIdFromList(state, productIdIndex) {
      state.promotionData.discountModel
        .models[`${state.promotionData.promotionType}-common`]
        .productId.splice(productIdIndex, 1);
    },
    deleteCustomProductFromList(state, productIndex) {
      state.promotionData.discountModel
        .models[`${state.promotionData.promotionType}-custom`]
        .products.splice(productIndex, 1);
    },
    updateCouponType(state, newCouponType) {
      state.promotionData.discountModel.couponType = newCouponType;
    },
    updateCouponCodes(state, newCouponCodes) {
      state.promotionData.discountModel.couponCode = newCouponCodes.split('\n');
    },
    setIsRequestRunning(state, isRunning) {
      state.isRequestRunning = isRunning;
    },
    replaceDiscountModel(state, { discountModelName, newModel }) {
      state.promotionData.discountModel.models[discountModelName] = newModel;
    },
    setSelectedProducts(state, newProduct) {
      state.selectedProducts = [...state.selectedProducts, newProduct];
    },
    setDublicates(state, dublicates) {
      state.dublicates = dublicates;
    },
    resetState(state) {
      Object.assign(state, {
        isExpirationDate: false,
        promotionData: {
          status: true,
          promotionType: 'discount',
          discountModelSeleted: 'all-catalog',
          promotionName: '',
          dateFrom: new Date(),
          timeFrom: getTime(new Date()),
          dateTo: new Date(),
          timeTo: '23:59',
          discountModel: {
            couponType: 'reusable',
            couponCode: [],
            models: generateInitialDiscountModels(),
          },
        },
        isDropdownProductsLoading: false,
        dropdownProducts: [],
        dropdownProductsTotalCount: 1,
        isRequestRunning: false,
        selectedProducts: [],
        dublicates: [],
      });
    },
  },
  actions: {
    async getProductsDropdown({ commit, getters }, queryObj) {
      let responseData;

      const api = serviceContainer.resolve('api');

      commit('setProductsLoading', true);
      commit('setProducts', []);
      try {
        const { data } = await api.createEditPromotion.getProductsDropdown(queryObj);

        const mappedProducts = data.products.map((item) => ({
          ...item,
          disabled: !!getters.getSelectedProductIdList?.includes(item.id),
        }));

        commit('setProducts', mappedProducts);
        commit('setProductsTotalCount', data.totalCount);
        commit('setProductsLoading', false);
        responseData = { data };
      } catch (error) {
        responseData = { error };
      }

      return Promise.resolve(responseData);
    },
    async addProductsToDropdown({ commit, getters, state }, queryObj) {
      let responseData;

      const api = serviceContainer.resolve('api');

      commit('setProductsLoading', true);
      try {
        const { data } = await api.createEditPromotion.getProductsDropdown(queryObj);

        const mappedProducts = [...state.dropdownProducts, ...data.products].map((item) => ({
          ...item,
          disabled: !!getters.getSelectedProductIdList?.includes(item.id),
        }));

        commit('setProducts', mappedProducts);
        commit('setProductsLoading', false);
      } catch {
        return false;
      }

      return Promise.resolve(responseData);
    },
    handleSelectProduct({ commit, getters }, chosenProduct) {
      const productId = chosenProduct.id;
      commit('setSelectedProducts', chosenProduct);

      if (getters.getDiscountModelString.includes('-custom')) {
        commit('updateCustomDiscountList', { productId, discountPercent: null });
      } else if (!getters.getDiscountModelString.includes('-all-catalog')) {
        commit('updateCommonProductIdList', productId);
      }
    },
    async createPromotion({ commit, getters }) {
      const api = serviceContainer.resolve('api');

      try {
        await api.createEditPromotion.create(getters.getRequestBody);
        return true;
      } catch (error) {
        const { title } = error.response.data;
        if (title) {
          const dublicates = title.split(/exists: |. Change/)[1].split(', ');
          commit('setDublicates', dublicates);
        }
        return false;
      }
    },
    async updateEditedPromotion({ commit, getters }, promotionId) {
      const api = serviceContainer.resolve('api');

      try {
        await api.createEditPromotion.updatePromotion(promotionId, getters.getRequestBody);
        return true;
      } catch (error) {
        const { title } = error.response.data;
        if (title) {
          const dublicates = title.split(/exists: |. Change/)[1].split(', ');
          commit('setDublicates', dublicates);
        }
        return false;
      }
    },
    async generateCouponCodes({ commit, getters }, amount) {
      const api = serviceContainer.resolve('api');

      try {
        const response = await api.createEditPromotion.getCodes(amount);
        if (getters.getCouponCodes.length) {
          commit('updateCouponCodes', getters.getCouponCodes.split('\n')
            .concat(response.data).join('\n'));
        } else {
          commit('updateCouponCodes', response.data.join('\n'));
        }
        return true;
      } catch (error) {
        return false;
      }
    },
    async loadPromotionToEdit({ commit, getters }, promotionId) {
      const api = serviceContainer.resolve('api');

      try {
        const response = await api.createEditPromotion.getPromotion(promotionId);
        const { data } = response;

        commit('updatePromotionStatus', data.promotionStatus.toLowerCase() === 'active');
        commit('updatePromotionName', data.promotionName);
        commit('updatePromotionType', data.promotionType);
        commit('updateDiscountModel', data.promotionDiscountModel.modelName
          .toLowerCase().replace(' ', '-'));

        const { promotionStartDate, promotionEndDate } = data;
        commit('updateDate', { type: 'dateFrom', newDate: new Date(promotionStartDate) });
        commit('updateTime', { type: 'timeFrom', newTime: getTime(new Date(promotionStartDate)) });

        if (promotionEndDate) {
          commit('updateIsExpirationDate', true);
          commit('updateDate', { type: 'dateTo', newDate: new Date(promotionEndDate) });
          commit('updateTime', { type: 'timeTo', newTime: getTime(new Date(promotionEndDate)) });
        }

        if (getters.getDiscountModel === 'custom') {
          const products = data.promotionDiscountModel.products.map((product) => (
            { productId: product.id, discountPercent: `${product.discountPercent}%` }
          ));

          commit('replaceDiscountModel', {
            discountModelName: getters.getDiscountModelString,
            newModel: { products },
          });
        } else if (getters.getDiscountModel === 'common') {
          const productId = data.promotionDiscountModel.products.map((product) => product.id);
          const discountPercent = `${data.promotionDiscountModel.totalDiscountPercent}%`;
          commit('replaceDiscountModel', {
            discountModelName: getters.getDiscountModelString,
            newModel: { productId, discountPercent },
          });
        } else if (getters.getDiscountModel === 'all-catalog') {
          const discountPercent = `${data.promotionDiscountModel.totalDiscountPercent}%`;
          commit('replaceDiscountModel', {
            discountModelName: getters.getDiscountModelString,
            newModel: { discountPercent },
          });
        }

        if (getters.getPromotionType === 'coupon') {
          const { couponType } = data.promotionStatistic.coupons[0];
          commit('updateCouponType', couponType);

          const couponCodes = data.promotionStatistic.coupons
            .map((coupon) => coupon.couponCode).join('\n');
          commit('updateCouponCodes', couponCodes);
        }
        // eslint-disable-next-line no-empty
      } catch (error) {
      }
    },
  },
  getters: {
    getDropdownProducts(state) {
      return state.dropdownProducts;
    },
    getPromotionType(state) {
      return state.promotionData.promotionType;
    },
    getPromotionStatus(state) {
      return state.promotionData.status;
    },
    getPromotionName(state) {
      return state.promotionData.promotionName;
    },
    getPromotionDateTimeFrom(state) {
      return {
        date: state.promotionData.dateFrom,
        time: state.promotionData.timeFrom,
      };
    },
    getPromotionDateTimeTo(state) {
      return {
        date: state.promotionData.dateTo,
        time: state.promotionData.timeTo,
      };
    },
    getIsExpirationDate(state) {
      return state.isExpirationDate;
    },
    getDiscountModelString(state) {
      return `${state.promotionData.promotionType}-${state.promotionData.discountModelSeleted}`;
    },
    getDiscountModelObject(state, getters) {
      return state.promotionData.discountModel.models[getters.getDiscountModelString];
    },
    getDiscountModel(state) {
      return state.promotionData.discountModelSeleted;
    },
    getCommonAmountOfDiscount(_state, getters) {
      let commonAmountOfDiscount = null;
      if (getters.getDiscountModelString.includes('-common')) {
        commonAmountOfDiscount = getters.getDiscountModelObject.discountPercent;
      }

      return commonAmountOfDiscount;
    },
    getSelectedProductIdList(_state, getters) {
      const model = getters.getDiscountModelObject;

      let selectedProductIdList = [];
      if (getters.getDiscountModel === 'custom') {
        selectedProductIdList = model.products.map((product) => product.productId);
      }

      if (getters.getDiscountModel === 'common') {
        selectedProductIdList = model.productId;
      }

      return selectedProductIdList;
    },
    getCouponType(state, getters) {
      let couponType = [];
      if (getters.getPromotionType === 'coupon') {
        couponType = state.promotionData.discountModel.couponType;
      }

      return couponType;
    },
    getCouponCodes(state, getters) {
      let couponCodes = [];
      if (getters.getPromotionType === 'coupon') {
        couponCodes = state.promotionData.discountModel.couponCode.join('\n');
      }

      return couponCodes;
    },
    getRequestBody(state, getters) {
      const utilsService = serviceContainer.resolve('utilsService');

      const discountModel = utilsService.data.cloneImmutable(getters.getDiscountModelObject);
      const discountModelString = getters.getDiscountModelString;
      const { promotionName, promotionType, status } = state.promotionData;
      const { dateFrom, timeFrom } = state.promotionData;

      const notSimpleAlgoruthmList = [
        'discount-custom',
        'coupon-custom',
      ];

      if (!notSimpleAlgoruthmList.includes(discountModelString)) {
        discountModel.discountPercent = utilsService.string.replaceLast(discountModel.discountPercent, ',', '.');
      } else {
        discountModel.products = discountModel.products.map((product) => {
          product.discountPercent = utilsService.string.replaceLast(product.discountPercent, ',', '.');

          return product;
        });
      }

      const formatedDateFrom = `${getSeparatedDate(dateFrom)} ${timeFrom}:00`;

      const body = {
        status,
        promotionType,
        promotionName,
        dateFrom: formatedDateFrom,
        discountModel,
      };

      if (state.isExpirationDate) {
        const { dateTo, timeTo } = state.promotionData;
        body.dateTo = `${getSeparatedDate(dateTo)} ${timeTo}:00`;
      }

      if (state.promotionData.promotionType === 'coupon') {
        const { couponCode } = state.promotionData.discountModel;
        body.discountModel.couponType = getters.getCouponType;
        body.discountModel.couponCode = couponCode;
      }

      return body;
    },
    getIsRequestRunning(state) {
      return state.isRequestRunning;
    },
    getSelectedProducts(state) {
      return state.selectedProducts;
    },
    getDublicates(state) {
      return state.dublicates;
    },
  },
});
