<template>
  <div class="sidebar__wrapper">
    <div
      ref="sidebar"
      v-click-outside="handleOutsideMenuTooltip"
      :class="sidebarClass"
      :style="sidebarStyle"
    >
      <div class="sidebar__linkList">
        <div
          v-for="(link, index) in linkList"
          :key="index"
          class="sidebar__item"
          @click="handleLinkClick(link, index)"
        >
          <a
            :class="getMainLinkClasses(link)"
          >
            <div
              class="sidebar__mainLinkWrapper"
              @click="link.subgroup && subgroupToggle(index)"
            >
              <DSIcon
                :icon="link.icon"
                :color="sidebarIconColor(link)"
                :class="sidebarIconClasses(link)"
              />
              <a
                :class="getLinkWhiteTextClasses(link)"
              >
                <span class="sidebar__link-text">{{ $t(link.title) }}</span>
                <span
                  v-if="link.iconSoon"
                  class="sidebar__link--soon"
                >SOON</span>
                <button
                  v-if="link.subgroup && link.subgroup.type !== 'notify'"
                  class="sidebar__linkExpand"
                  type="button"
                >
                  <DSIcon
                    icon="arrowDown"
                    :class="getArrowIconClasses(link)"
                  />
                </button>
              </a>
            </div>
          </a>
          <div
            v-if="link.subgroup && !!link.subgroup.data"
            class="sidebar__linkGroup"
            :style="linkGroupHeight(link)"
          >
            <div
              v-show="index === tooltipIndexToShow"
              :class="getTooltipClasses(index)"
            >
              <div class="sidebar__tooltipTitleWrapper">
                <p class="sidebar__tooltipTitle">
                  {{ $t(link.title) }}
                </p>
              </div>
              <div class="sidebar__tooltipLinkWrapper">
                <a
                  v-for="sublink in link.subgroup.data"
                  :key="sublink.title"
                  :class="getTooltipLinkClasses(sublink)"
                  @click="handleLinkClick(sublink)"
                >
                  <DSIcon
                    v-if="sublink.icon"
                    :icon="sublink.icon"
                    :class="sidebarIconClasses(link)"
                  />
                  {{ $t(sublink.title) }}
                  <span
                    v-if="sublink.iconSoon"
                    class="sidebar__link--soon"
                  >SOON</span>
                </a>
              </div>
            </div>
            <div
              v-for="(sublink, subLinkIndex) in link.subgroup.data"
              :key="subLinkIndex"
              :class="getSubLinkWrapperClasses(link, sublink)"
              :style="getSubLinkWrapperStyles(link)"
            >
              <a
                :class="getSubLinkClasses(link, sublink)"
                @click="handleLinkClick(sublink)"
              >
                <DSIcon
                  v-if="sublink.icon"
                  :icon="sublink.icon"
                  :class="sidebarIconClasses(link)"
                />
                {{ $t(sublink.title) }}
                <span
                  v-if="sublink.iconSoon"
                  class="sidebar__link--soon"
                >SOON</span>
              </a>
            </div>
          </div>
          <div
            v-else-if="link.subgroup && link.subgroup.notify"
            class="sidebar__linkGroup sidebar__linkGroup_notify"
            :style="linkGroupHeight(link)"
          >
            <div
              v-show="index === tooltipIndexToShow"
              :class="getTooltipClasses(index)"
            >
              <div class="sidebar__tooltipTitleWrapper">
                <p class="sidebar__tooltipTitle">
                  {{ $t(link.title) }}
                </p>
              </div>
              <div class="sidebar__tooltipLinkWrapper">
                <span
                  v-for="sublink in link.subgroup.notify"
                  :key="sublink.text"
                  :class="getTooltipLinkClasses(sublink)"
                >
                  <DSIcon
                    v-if="sublink.icon"
                    :icon="sublink.icon"
                    :class="sidebarIconClasses(link)"
                  />
                  {{ $t(sublink.text) }}
                  <span
                    v-if="sublink.iconSoon"
                    class="sidebar__link--soon"
                  >SOON</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          class="sidebar__settingsButton"
          :class="settingsButtonClasses"
          type="button"
        >
          <div class="sidebar__settingsButtonWrapper">
            <div
              class="sidebar__icon"
              :class="settingsIconFullClasses"
            >
              <DSIcon
                icon="gear"
                color="primary-button"
                size="s"
              />
            </div>
            {{ isMenuMinimized ? '' : $t('sidebar.sidebarButton') }}
            <span
              v-if="!isMenuMinimized"
              class="sidebar__link--soon sidebar__link--soon_settings"
            >SOON</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import { defineComponent } from 'vue';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

export default defineComponent({
  name: 'SidebarComponent',
  components: {
    DSIcon,
  },
  props: {
    menuMinimizedHandler: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      sublinkHeight: 36,
      lastWindowWidth: 1200,
    };
  },
  computed: {
    ...mapGetters({
      isMenuMinimized: 'commonStatusesStore/getIsMenuMinimized',
      esupportLink: 'authStore/getEsupportLink',
    }),
    ...mapState({
      tooltipIndexToShow: (state) => state.productLayoutStore.tooltipIndexToShow,
      linkList: (state) => state.productLayoutStore.linkList,
    }),
    sidebarClass() {
      return {
        sidebar: true,
        'sidebar--full': !this.isMenuMinimized,
        'sidebar--mini': this.isMenuMinimized,
      };
    },
    sidebarStyle() {
      return [
        this.tooltipIndexToShow ? { overflowY: 'visible' } : { overflowY: 'scroll' },
      ];
    },
    settingsButtonClasses() {
      return {
        sidebar__settingsButton: true,
        sidebar__settingsButtonFull: !this.isMenuMinimized,
      };
    },
    settingsIconFullClasses() {
      return {
        sidebar__settingsIconFull: !this.isMenuMinimized,
      };
    },
  },
  mounted() {
    if (window.innerWidth > 670) {
      this.linkList.forEach((rootItem, index) => {
        const isContainsCurrentRoute = this.isCurrentRoute(rootItem) || this.isSubGroupContainsCurrentRoute(rootItem);
        if (isContainsCurrentRoute) {
          this.subgroupToggle(index);
        }
      });
    }
  },
  beforeDestroy() {
    if (window.innerWidth > 670) {
      this.linkList.forEach((rootItem, index) => {
        const isOpenedSubGroup = this.isCurrentRoute(rootItem) || (rootItem.subgroup?.show ?? false);
        if (isOpenedSubGroup) {
          this.subgroupToggle(index);
        }
      });
    }
  },
  methods: {
    ...mapMutations({
      changeTooltipIndex: 'productLayoutStore/changeTooltipIndex',
      subgroupToggle: 'productLayoutStore/subgroupToggle',
    }),
    ...mapActions({
      updateMenuStatus: 'commonStatusesStore/updateMenuStatus',
    }),
    getMainLinkClasses(link) {
      const isCurrentRoute = this.isCurrentRoute(link) || this.isSubGroupContainsCurrentRoute(link);
      const isSubGroupNotOpened = !this.isMenuMinimized && (!link.subgroup || !link.subgroup.show);

      return {
        sidebar__mainLink: true,
        sidebar__linkActive: isCurrentRoute && (this.isMenuMinimized || isSubGroupNotOpened),
      };
    },
    isSubGroupContainsCurrentRoute(link) {
      return (link.subgroup?.data ?? []).reduce(
        (isCurrentRoute, subItem) => isCurrentRoute || this.isCurrentRoute(subItem),
        false,
      );
    },
    getLinkWhiteTextClasses(link) {
      const isCurrentRoute = this.isCurrentRoute(link);
      const isSubGroupNotOpened = !this.isMenuMinimized && (!link.subgroup || !link.subgroup.show);

      return {
        sidebar__link: true,
        'sidebar__linkActive--white-text': isCurrentRoute && (this.isMenuMinimized || isSubGroupNotOpened),
      };
    },
    getArrowIconClasses(link) {
      return {
        'sidebar__linkExpand--active': link.subgroup.show,
      };
    },
    getTooltipClasses(index) {
      return {
        sidebar__tooltip: true,
        sidebar__tooltipAppearance: index === this.tooltipIndexToShow,
      };
    },
    getTooltipLinkClasses(link) {
      return {
        sidebar__tooltipLink: true,
        'sidebar__tooltipLink--active': this.isCurrentRoute(link),
        'sidebar__sublink--with-icon': link.icon,
      };
    },
    getSubLinkWrapperClasses(link, subLink) {
      return {
        sidebar__sublinkWrapper: true,
        sidebar__linkActive:
          this.isCurrentRoute(subLink)
          && link.subgroup.show
          && !this.isMenuMinimized,
        sidebar__sublinkWrapper_big: subLink.icon,
      };
    },
    getSubLinkWrapperStyles(link) {
      return [
        this.isMenuMinimized || !link.subgroup.show
          ? { pointerEvents: 'none' }
          : { pointerEvents: 'auto' },
      ];
    },
    getSubLinkClasses(link, subLink) {
      return {
        sidebar__link: true,
        sidebar__sublink: true,
        'sidebar__link--full': !this.isMenuMinimized || link.subgroup.show,
        'sidebar__link--mini': this.isMenuMinimized || !link.subgroup.show,
        'sidebar__linkActive--white-text': this.isCurrentRoute(subLink),
        'sidebar__sublink--with-icon': subLink.icon,
      };
    },
    isCurrentRoute(link) {
      return link.to === this.$route.fullPath || `${link.to}/` === this.$route.fullPath;
    },
    handleLinkClick(linkInfo, index) {
      if (!linkInfo.subgroup) {
        if (linkInfo.to === 'esupport') {
          window.open(this.esupportLink);
        } else if (linkInfo.to !== 'nowhere') {
          this.$router.push(linkInfo.to).catch(() => null);
        }
      } else if (this.isMenuMinimized) {
        this.changeTooltipIndex(index);
      }
    },
    handleOutsideMenuTooltip() {
      this.changeTooltipIndex(null);
    },
    linkGroupHeight(link) {
      return [
        this.isMenuMinimized || !link.subgroup.show || !link.subgroup.data
          ? { maxHeight: '0px' }
          : { maxHeight: `${link.subgroup.data.length * this.sublinkHeight + 44}px` },
      ];
    },
    isActiveSidebarItem(link) {
      return this.isCurrentRoute(link)
        && (this.isMenuMinimized || (!this.isMenuMinimized && (!link.subgroup || !link.subgroup.show)));
    },
    sidebarIconColor(link) {
      return this.isActiveSidebarItem(link) ? 'menu-active' : 'menu';
    },
    sidebarIconClasses(link) {
      return [
        'sidebar__icon',
        this.isActiveSidebarItem(link) ? 'sidebar__icon--active' : null,
      ];
    },
    resizeHandler() {
      if ((this.lastWindowWidth >= 992 && window.innerWidth < 992)
        || (this.lastWindowWidth < 992 && window.innerWidth >= 992)) {
        this.updateMenuStatus(window.innerWidth < 992);

        this.lastWindowWidth = window.innerWidth;
      }
    },
  },
  created() {
    window.addEventListener('resize', this.resizeHandler);
    window.dispatchEvent(new Event('resize'));
  },
});
</script>

<style lang="scss" scoped>
@import "style";
</style>
