import ILocalServiceContainer from '@/module/product/view/FulfillmentFormPage/model/ILocalServiceContainer';
import ELocalServiceFulfillment from '@/module/product/view/FulfillmentFormPage/model/enums/ELocalServiceFulfillment';
import ServiceContainerException from '@/module/common/service/ServiceContainer/ServiceContainerException';

class LocalServiceContainer implements ILocalServiceContainer {
  readonly serviceMap: Record<ELocalServiceFulfillment, unknown> = {} as Record<ELocalServiceFulfillment, unknown>;

  register<Type>(serviceName: ELocalServiceFulfillment, instance: Type): void {
    this.serviceMap[serviceName] = instance;
  }

  resolve<Type>(serviceName: ELocalServiceFulfillment): Type {
    if (!this.serviceMap[serviceName]) {
      throw new ServiceContainerException(`Service '${serviceName}' not registered.`);
    }

    return this.serviceMap[serviceName] as Type;
  }
}

export default LocalServiceContainer;
