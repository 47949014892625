import mapObjectSnakeKeysToCamelCaseRecursive
  from '@/module/common/utils/object/mapObjectSnakeKeysToCamelCaseRecursive';
import hasValue from '@/module/common/utils/data/hasValue';
import isEmpty from '@/module/common/utils/data/isEmpty';
import isArray from '@/module/common/utils/data/isArray';
import isObject from '@/module/common/utils/data/isObject';
import readProp from '@/module/common/utils/data/readProp';

export default function data() {
  return {
    hasValue,
    isArray,
    isEmpty,
    isObject,
    mapObjectSnakeKeysToCamelCaseRecursive,
    readProp,
  };
}
