<template>
  <div
    class="coupon"
    :class="{ coupon_active: active, coupon_small: type === 'small' }"
  >
    <span class="coupon__text">{{ text }}</span>
    <div
      v-if="active"
      class="coupon__icon"
      @click="copyText"
    >
      <DSIcon
        icon="copy"
        color="linked"
        dise="s"
      />
    </div>
  </div>
</template>

<script>
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';

export default {
  name: 'CouponComponent',
  components: {
    DSIcon,
  },
  props: {
    text: {
      type: String,
      default: 'Coupon name',
    },
    active: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },
  methods: {
    copyText() {
      navigator.clipboard.writeText(this.text);
      // eslint-disable-next-line no-alert
      alert(`${this.$t('promotions.promotion_card.coupons.alert_text')}: ${this.text}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.coupon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  width: fit-content;
  border-radius: 8px;
  color: #888888;
  background-color: #F8F8FA;
  padding: 0 11px 0 16px;
  overflow: hidden;
  pointer-events: none;

  &_active {
    color: #000000;
    background-color: #EBF7FF;
    pointer-events: all;
  }

  &_small {
    position: relative;
    width: 204px;
    background-color: #FFFFFF;
    outline: 2px solid #ECEEF2;

    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 50px;
      right: 30px;
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    }

    .coupon__text {
      width: 155px;
      overflow: hidden;
    }
  }

  &__text {
    font-family: $text-ibm-plex-sans;
    font-size: 14px;
    font-weight: 500;
  }

  &__icon {
    display: flex;
    align-items: center;
    margin: 0 -5px 0 5px;
    padding: 6px 5px;
    border-radius: 5px;
    z-index: 10;
    cursor: pointer;

    &:hover {
      background-color: #ECEEF2;
    }
  }
}
</style>
