import IAuthorizationApi from '@/api/Auth/IAuthorizationApi';
import { AxiosInstance, AxiosResponse } from 'axios';
import IIsTokenExistsResponse from '@/api/Auth/model/IIsTokenExistsResponse';
import ISSOLinkResponse from '@/api/Auth/model/ISSOLinkResponse';
import IAuthResponse from '@/api/Auth/model/IAuthResponse';
import mapAllowedMerchantModes from '@/module/common/utils/mapAllowedMerchantModes';

class AuthorizationApi implements IAuthorizationApi {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  isTokenExists = (): Promise<AxiosResponse<IIsTokenExistsResponse>> => this.client.post('/vendor/auth/check');

  getSSOLink = (): Promise<AxiosResponse<ISSOLinkResponse>> => this.client.get('/sso/link');

  getSSOLogoutLink = (): Promise<AxiosResponse<ISSOLinkResponse>> => this.client.get('/sso/link-logout');

  auth = (code: string | number): Promise<AxiosResponse<IAuthResponse>> => this.client
    .post('/vendor/auth', { code, redirectUri: `${window.location.origin}/ssoAuth` })
    .then((response) => {
      response.data.allowedVendorModes = mapAllowedMerchantModes(response.data.allowedVendorModes);

      return Promise.resolve(response);
    });

  logout = () => this.client.post('logout');
}

export default AuthorizationApi;
