import IScenario from '@/module/common/models/interface/IScenario';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import ICreateRequestData
  from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/interface/ICreateRequestData';
import EFieldName from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/enum/EFieldName';
import VueRouter from 'vue-router';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import {
  Store,
} from 'vuex';
import INotificationService from '@/module/common/service/NotificationService/INotificationService';
import ENotificationType from '@/module/common/service/NotificationService/model/ENotificationType';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';

class CreatePaymentLinkScenario implements IScenario {
  private readonly serviceContainer: IServiceContainer;

  private successHandler = (): void => {
    const localisationRegistry = this.serviceContainer.localisationRegistry;
    const localisation = localisationRegistry.paymentLinkForm;

    const router = this.serviceContainer
      .resolve<VueRouter>(EServiceContainerInstances.ROUTER);

    const store = this.serviceContainer
      .resolve<Store<unknown>>(EServiceContainerInstances.STORE);

    const notificationService = this.serviceContainer
      .resolve<INotificationService>(EServiceContainerInstances.NOTIFICATION_SERVICE);

    notificationService.push(
      ENotificationType.INFO_ALERT,
      {
        title: localisation.notification.create.title,
        text: localisation.notification.create.text,
      },
    );

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    store.dispatch('authStore/refreshInfo').catch(() => {});

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    router.push('/payment-links').catch(() => {});
  };

  private errorHandler = (): void => {
    const notificationService = this.serviceContainer
      .resolve<INotificationService>(EServiceContainerInstances.NOTIFICATION_SERVICE);

    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);

    const localisationRegistry: ILocalisationRegistry = store.getters.getLocalisationRegistry;
    const popUpLocalisation = localisationRegistry.popUp;

    notificationService.push(
      ENotificationType.ERROR_POPUP,
      {
        text: popUpLocalisation.wentWrong,
        title: popUpLocalisation.error,
      },
    );
  };

  constructor(
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
  }

  run = (): void => {
    const apiService = this.serviceContainer.apiService;
    const paymentLinkApi = apiService.paymentLink;

    const paymentLinkFormPage = this.serviceContainer.moduleProduct.paymentLinkFormPage;

    const state = paymentLinkFormPage.stateHandler.get();

    const formDataHandler = paymentLinkFormPage.formDataHandler;

    const formData = formDataHandler.get();
    const requestData: ICreateRequestData = {
      paymentLinkName: formData[EFieldName.NAME],
      paymentDescription: formData[EFieldName.PAYMENT_DESCRIPTION],
      amount: formData[EFieldName.AMOUNT].replace(',', '.'),
      currency: formData[EFieldName.CURRENCY].value,
      paymentMethod: formData[EFieldName.PAYMENT_METHOD].value,
      paymentId: formData[EFieldName.PAYMENT_ID],
      customer: {
        email: formData[EFieldName.CUSTOMER_EMAIL],
        firstName: formData[EFieldName.CUSTOMER_FIRST_NAME],
        lastName: formData[EFieldName.CUSTOMER_LAST_NAME],
      },
      recurringIndicator: false,
      returnSuccessUrl: formData[EFieldName.RETURN_SUCCESS_URL],
    };

    state.isLoading = true;

    paymentLinkApi.create(requestData)
      .then(this.successHandler)
      .catch(this.errorHandler)
      .finally(() => {
        state.isLoading = false;
      });
  };
}

export default CreatePaymentLinkScenario;
