<template>
  <div
    v-click-outside="handleClickOutside"
    class="coupon-dropdown"
  >
    <div
      class="coupon-dropdown__main"
      @click="toggleDropdown"
    >
      <span class="coupon-dropdown__main-value">{{ modelValue }}</span>
      <DSIcon
        class="coupon-dropdown__main-icon"
        :class="{ 'coupon-dropdown__main-icon_rotate': isDropdownOpened }"
        icon="arrowDown"
        color="linked"
      />
    </div>
    <div
      v-if="isDropdownOpened"
      class="coupon-dropdown__list-wrapper"
    >
      <form
        class="coupon-dropdown__form"
        @submit.prevent
      >
        <label
          v-for="item in optionsList"
          :key="item.value"
          class="coupon-dropdown__form-item"
        >
          <input
            class="coupon-dropdown__form-radio"
            type="radio"
            name="coupon-dropdown"
            :value="item.value"
            :checked="selectedOption === item.value"
            @click="changeOption(item.value)"
          >
          <span class="coupon-dropdown__form-radio-text">{{ item.text }}</span>
        </label>
        <input
          v-if="selectedOption === 'custom'"
          class="coupon-dropdown__form-input"
          type="number"
          :value="modelValue"
          @input="changeValue(+$event.target.value)"
        >
      </form>
    </div>
  </div>
</template>

<script>
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';

export default {
  name: 'CouponDropdown',
  components: {
    DSIcon,
  },
  props: {
    selectedOption: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isDropdownOpened: false,
      optionsList: [
        { value: '10', text: '10' },
        { value: '100', text: '100' },
        { value: '500', text: '500' },
        { value: 'custom', text: 'Custom' },
      ],
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpened = !this.isDropdownOpened;
    },
    handleClickOutside() {
      this.isDropdownOpened = false;
    },
    changeValue(value) {
      const newValue = value > 1000 ? 1000 : value;
      this.$emit('update:modelValue', newValue);
      this.$forceUpdate();
    },
    changeOption(value) {
      this.$emit('update:selectedOption', value);
      if (value !== 'custom') {
        this.changeValue(+value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.coupon-dropdown {
  position: relative;
  font-family: $text-ibm-plex-sans;
  font-size: 16px;
  font-weight: 500;

  &__main {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 100%;
    line-height: 1;
    background: $app-button-secondary-color;
    color: $app-button-secondary-text;
    border-radius: 0 8px 8px 0;
    padding: 0 12px;
    fill: $app-button-secondary-text;
    cursor: pointer;
    user-select: none;

    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      left: 0;
      width: 2px;
      height: 28px;
      background-color: rgba($color: #4397CB, $alpha: 0.4);
    }

    &:hover {
      background: $app-button-hover-secondary-color;
      color: $app-button-secondary-text;
    }

    &:active {
      background: $app-button-active-secondary-color;
    }

    &-value {
      margin-right: 5px;
    }

    &-icon {
      transition: 0.2s;

      &_rotate {
        transform: rotateZ(-180deg);
      }
    }
  }

  &__list-wrapper {
    position: absolute;
    width: 167px;
    top: calc(100% + 10px);
    background-color: white;
    padding: 12px 8px;
    border: 2px solid #ECEEF2;
    border-radius: 8px;
    z-index: 100;
  }

  &__form {
    display: flex;
    flex-direction: column;

    &-item {
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      margin: 14px 10px;
    }

    &-radio {
      visibility: hidden;

      &:checked {
        & ~ .coupon-dropdown__form-radio-text::before {
          border: 5px solid #4397CB;
        }
      }
    }

    &-radio-text {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 1.5px;
        left: -20px;
        width: 16px;
        height: 16px;
        border: 1px solid #C7C7CD;
        border-radius: 100%;
      }
    }

    &-input {
      width: 100%;
      line-height: 22px;
      padding: 5px 16px;
      border: 2px solid #ECEEF2;
      border-radius: 8px;
      color: rgba($color: #000000, $alpha: 0.65);
      transition: .1s ease-out;
      outline: none;
      -webkit-appearance: none;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &:hover {
        border: 2px solid $app-grey-textarea-border-hover;
      }
      &:focus {
        border: 2px solid #4397CB;
      }
    }
  }
}
</style>
