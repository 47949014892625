export default function metrics(instance) {
  return {
    get(data) {
      const parts = [
        `dimension=${data.dimension}`,
        `date[dateFrom]=${data.dateFrom}`,
        `date[dateTo]=${data.dateTo}`,
      ];

      const query = parts.filter((part) => !!part).join('&');
      return instance.get(`/vendor/dashboard?${query}`);
    },
  };
}
