<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 22.5C9.21523 22.5 6.54451 21.3938 4.57538 19.4246C2.60625 17.4555 1.5 14.7848 1.5 12C1.5 9.21523 2.60625 6.54451 4.57538 4.57538C6.54451 2.60625 9.21523 1.5 12 1.5C14.7848 1.5 17.4555 2.60625 19.4246 4.57538C21.3938 6.54451 22.5 9.21523 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5Z"
    />
    <path
      fill="white"
      d="M17.7474 8.23151C17.6746 8.15815 17.5881 8.09993 17.4927 8.06019C17.3973 8.02046 17.295 8 17.1917 8C17.0884 8 16.9861 8.02046 16.8907 8.06019C16.7953 8.09993 16.7088 8.15815 16.636 8.23151L10.8052 14.0701L8.3555 11.6126C8.27996 11.5396 8.19078 11.4822 8.09306 11.4437C7.99534 11.4052 7.891 11.3863 7.78598 11.3881C7.68096 11.39 7.57733 11.4124 7.481 11.4543C7.38467 11.4962 7.29754 11.5566 7.22456 11.6322C7.15159 11.7077 7.09421 11.7969 7.0557 11.8946C7.01719 11.9923 6.9983 12.0967 7.00012 12.2017C7.00194 12.3067 7.02442 12.4103 7.06629 12.5066C7.10815 12.603 7.16858 12.6901 7.24413 12.7631L10.2495 15.7685C10.3223 15.8418 10.4089 15.9001 10.5042 15.9398C10.5996 15.9795 10.7019 16 10.8052 16C10.9085 16 11.0108 15.9795 11.1062 15.9398C11.2016 15.9001 11.2881 15.8418 11.3609 15.7685L17.7474 9.38201C17.8268 9.30872 17.8902 9.21977 17.9336 9.12077C17.977 9.02176 17.9993 8.91485 17.9993 8.80676C17.9993 8.69867 17.977 8.59176 17.9336 8.49275C17.8902 8.39375 17.8268 8.3048 17.7474 8.23151Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSCheckCircleFilledIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>
