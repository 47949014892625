<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.729 8.002L4.263 10.47C4.17941 10.5536 4.11311 10.6528 4.06787 10.762C4.02264 10.8712 3.99935 10.9883 3.99935 11.1065C3.99935 11.2247 4.02264 11.3418 4.06787 11.451C4.11311 11.5602 4.17941 11.6594 4.263 11.743C4.34659 11.8266 4.44582 11.8929 4.55503 11.9381C4.66424 11.9834 4.78129 12.0066 4.8995 12.0066C5.01771 12.0066 5.13476 11.9834 5.24397 11.9381C5.35318 11.8929 5.45241 11.8266 5.536 11.743L8 9.275L10.465 11.743C10.5479 11.8295 10.6473 11.8985 10.7572 11.9461C10.8672 11.9937 10.9855 12.0188 11.1054 12.0201C11.2252 12.0213 11.344 11.9986 11.4549 11.9533C11.5658 11.908 11.6666 11.841 11.7513 11.7563C11.836 11.6715 11.9029 11.5707 11.9481 11.4598C11.9933 11.3488 12.0159 11.2299 12.0146 11.1101C12.0133 10.9903 11.988 10.872 11.9403 10.7621C11.8927 10.6522 11.8235 10.5529 11.737 10.47L9.273 8.002L11.738 5.533C11.8245 5.45015 11.8937 5.35085 11.9413 5.24093C11.989 5.131 12.0143 5.01267 12.0156 4.89287C12.0169 4.77306 11.9943 4.6542 11.9491 4.54324C11.9039 4.43229 11.837 4.33148 11.7523 4.24673C11.6676 4.16197 11.5668 4.09498 11.4559 4.04968C11.345 4.00438 11.2262 3.98168 11.1064 3.98292C10.9865 3.98416 10.8682 4.0093 10.7582 4.05688C10.6483 4.10446 10.5489 4.17352 10.466 4.26L8 6.728L5.536 4.26C5.36719 4.09119 5.13823 3.99635 4.8995 3.99635C4.66077 3.99635 4.43181 4.09119 4.263 4.26C4.09419 4.42881 3.99935 4.65777 3.99935 4.8965C3.99935 5.13523 4.09419 5.36419 4.263 5.533L6.729 8.002ZM8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSCrossInsideCircleIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>
