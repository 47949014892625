import ENotifyVariant from '@/module/design-system/components/models/UI/ENotifyVariant';
import ICamelCaseLocalisationCommon
  from '@/module/common/utils/localisation/initialisator/model/ICamelCaseLocalisationCommon';
import IVueInstance from '@/module/common/models/IVueInstance';
import getLocalisationVue2 from '@/module/common/helpers/getLocalisationVue2';
import mapObjectSnakeKeysToCamelCaseRecursive
  from '@/module/common/utils/object/mapObjectSnakeKeysToCamelCaseRecursive';

interface ILocalisationPromotionForm {
  new_promotion: string;
  edit_promotion: string;
  cancel: string;
  save: string;
  details: string;
  type: {
    discount: string;
    coupon: string;
    tooltip: string;
  },
  discount: string;
  coupon: string;
  name: string;
  name_placeholder: string;
  find_product: string;
  added_product: string;
  status_active: string;
  status_disabled: string;
  add_date: {
    title: string;
    tooltip: string;
  },
  amount_of_discount: string;
  add_product: string;
  start_date: string;
  coupon_settings: {
    title: string;
    tooltip: string;
  },
  reusable: string;
  disposable: string;
  coupon_codes: string;
  generate: string;
  discount_model: {
    title: string;
    tooltip: string;
    all_catalog: {
      title: string;
      hint: string;
    },
    common: {
      title: string;
      hint: string;
    },
    custom: {
      title: string;
      hint: string;
    }
  },
  popup: {
    title: {
      error: string;
      save: string;
      update: string;
    },
    description: {
      error: string;
      duplicates: string;
      save: string;
      update: string;
    }
  }
}

interface ICamelCaseLocalisationPromotionForm {
  newPromotion: string;
  editPromotion: string;
  cancel: string;
  save: string;
  details: string;
  type: {
    discount: string;
    coupon: string;
    tooltip: string;
  },
  discount: string;
  coupon: string;
  name: string;
  namePlaceholder: string;
  findProduct: string;
  addedProduct: string;
  statusActive: string;
  statusDisabled: string;
  addDate: {
    title: string;
    tooltip: string;
  },
  amountOfDiscount: string;
  addProduct: string;
  startDate: string;
  couponSettings: {
    title: string;
    tooltip: string;
  },
  reusable: string;
  disposable: string;
  couponCodes: string;
  generate: string;
  discountModel: {
    title: string;
    tooltip: string;
    allCatalog: {
      title: string;
      hint: string;
    },
    common: {
      title: string;
      hint: string;
    },
    custom: {
      title: string;
      hint: string;
    }
  },
  popup: {
    title: {
      error: string;
      save: string;
      update: string;
    },
    description: {
      error: string;
      duplicates: string;
      save: string;
      update: string;
    }
  }
}

function initLocalisation(vueInstance: IVueInstance): ICamelCaseLocalisationPromotionForm {
  let localisation = getLocalisationVue2<ILocalisationPromotionForm>(
    'promotions.form',
    vueInstance,
  );
  if (!localisation) {
    localisation = {} as ILocalisationPromotionForm;
  }

  return mapObjectSnakeKeysToCamelCaseRecursive<
    ILocalisationPromotionForm,
    ICamelCaseLocalisationPromotionForm
  >(localisation) as ICamelCaseLocalisationPromotionForm;
}

function getButtonName(): string {
  return 'promotionTryAgain';
}

function initCreatedPopUp(localisation: ICamelCaseLocalisationPromotionForm) {
  return {
    title: localisation.popup.title.save,
    text: localisation.popup.description.save,
    type: ENotifyVariant.INFO,
    component: 'DSPopup',
  };
}

function initUpdatedPopUp(localisation: ICamelCaseLocalisationPromotionForm) {
  return {
    title: localisation.popup.title.update,
    text: localisation.popup.description.update,
    type: ENotifyVariant.INFO,
    component: 'DSPopup',
  };
}

function initDuplicatesPopUp(localisation: ICamelCaseLocalisationPromotionForm) {
  return {
    title: localisation.popup.title.error,
    text: localisation.popup.description.duplicates,
    type: ENotifyVariant.ERROR,
    component: 'DSPopup',
  };
}

function initErrorPopUp(
  localisation: ICamelCaseLocalisationPromotionForm,
  commonLocalisation: ICamelCaseLocalisationCommon,
) {
  return {
    title: localisation.popup.title.error,
    text: localisation.popup.description.error,
    type: ENotifyVariant.ERROR,
    component: 'DSPopup',
    control: [
      {
        text: commonLocalisation.tryAgain,
        name: getButtonName(),
      },
    ],
  };
}

export default initLocalisation;

export {
  getButtonName,
  initCreatedPopUp,
  initUpdatedPopUp,
  initDuplicatesPopUp,
  initErrorPopUp,
};
