
import Vue, {
  computed,
} from 'vue';

import DSAlert from '@/module/design-system/components/UI/DSAlert.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSPopUp from '@/module/design-system/components/UI/PopUp/DSPopUp.vue';

export default Vue.extend({
  name: 'CNotificationsList',
  components: {
    DSIcon,
    DSAlert,
    DSPopUp,
  },
  props: {
    list: {
      type: Map,
      required: true,
    },
  },
  setup(props) {
    const notifications = computed(() => Array.from(props.list.values()));

    return {
      notifications,
    };
  },
});
