
import { defineComponent, inject, reactive } from 'vue';
import COverviewPageLayout from '@/module/common/components/PageParts/COverviewPageLayout.vue';
import COverviewPageHeaderRow from '@/module/common/components/PageParts/COverviewPageHeaderRow.vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import generateProductList from '@/module/dev-tools/service/generateProductList';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import { Store } from 'vuex';

export default defineComponent({
  name: 'DevToolsPage',
  components: {
    DSButton,
    COverviewPageHeaderRow,
    COverviewPageLayout,
  },
  setup() {
    const api = inject('api');
    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;
    const store = serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    store.dispatch('authStore/refreshInfo');

    const state = reactive({
      generateProductLoading: false,
    });

    async function handleGenerateProductsClick() {
      state.generateProductLoading = true;
      const productList = generateProductList();

      const requests = productList.map(
        // eslint-disable-next-line
        (product) => (api as { [key: string]: { [key: string]: any } }).products.createProduct(product),
      );
      await Promise.all(requests);
      state.generateProductLoading = false;
    }

    return {
      state,
      handleGenerateProductsClick,
    };
  },
});
