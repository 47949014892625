<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      d="M22.125 5.28516C21.6094 5.28516 21.168 5.48915 20.8008 5.89714C20.4336 6.30512 20.25 6.79557 20.25 7.36849C20.25 7.57682 20.2891 7.80252 20.3672 8.04557L15.7969 12.4206C15.4375 12.1081 15.0469 11.9518 14.625 11.9518C14.125 11.9518 13.6875 12.1515 13.3125 12.5508L9.72656 10.5456C9.69531 10.0074 9.50391 9.55165 9.15234 9.17839C8.80078 8.80512 8.375 8.61849 7.875 8.61849C7.53125 8.61849 7.21484 8.70964 6.92578 8.89193C6.63672 9.07422 6.41016 9.32595 6.24609 9.64714C6.08203 9.96832 6 10.3199 6 10.7018C6 10.997 6.0625 11.2921 6.1875 11.5872L2.53125 16.2487C2.3125 16.1619 2.09375 16.1185 1.875 16.1185C1.35938 16.1185 0.917969 16.3225 0.550781 16.7305C0.183594 17.1385 0 17.6289 0 18.2018C0 18.7747 0.183594 19.2652 0.550781 19.6732C0.917969 20.0812 1.35938 20.2852 1.875 20.2852C2.39062 20.2852 2.83203 20.0812 3.19922 19.6732C3.56641 19.2652 3.75 18.7747 3.75 18.2018C3.75 17.9414 3.70312 17.681 3.60938 17.4206L7.3125 12.681C7.51562 12.7504 7.70312 12.7852 7.875 12.7852C8.14062 12.7852 8.39062 12.7244 8.625 12.6029C8.85938 12.4813 9.07031 12.3164 9.25781 12.1081L12.75 14.0612C12.75 14.339 12.8008 14.6037 12.9023 14.8555C13.0039 15.1072 13.1367 15.3242 13.3008 15.5065C13.4648 15.6888 13.6641 15.8364 13.8984 15.9492C14.1328 16.0621 14.375 16.1185 14.625 16.1185C14.7656 16.1185 14.9062 16.1011 15.0469 16.0664C15.1875 16.0317 15.3203 15.9796 15.4453 15.9102C15.5703 15.8407 15.6875 15.7582 15.7969 15.6628C15.9062 15.5673 16.0039 15.4588 16.0898 15.3372C16.1758 15.2157 16.25 15.0855 16.3125 14.9466C16.375 14.8077 16.4219 14.6602 16.4531 14.5039C16.4844 14.3477 16.5 14.1914 16.5 14.0352V13.931L21.375 9.26953C21.6094 9.39106 21.8594 9.45182 22.125 9.45182C22.6406 9.45182 23.082 9.24783 23.4492 8.83984C23.8164 8.43186 24 7.94141 24 7.36849C24 6.79557 23.8164 6.30512 23.4492 5.89714C23.082 5.48915 22.6406 5.28516 22.125 5.28516Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSLineGraph',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>

<style scoped>

</style>
