import IDSButtonHelper from '@/module/common/helpers/contract/IDSButtonHelper';
import IDSButton from '@/module/design-system/models/UI/DSButton/IDSButton';
import TButtonHandler from '@/module/common/models/helpers/getButton/TButtonHandler';
import IButtonConfig from '@/module/common/models/helpers/getButton/IButtonConfig';
import IDSIcon from '@/module/design-system/components/models/IDSIcon';
import IButtonWithIconConfig from '@/module/common/models/helpers/getButton/IButtonWithIconConfig';

class DSButtonHelper implements IDSButtonHelper {
  getButton = (config: IDSButton, handler: TButtonHandler, visible: boolean): IButtonConfig => ({
    config,
    handler,
    visible,
  });

  getButtonWithIcon = (
    config: IDSButton,
    handler: TButtonHandler,
    icon: IDSIcon,
    visible: boolean,
  ): IButtonWithIconConfig => ({
    ...this.getButton(config, handler, visible),
    icon,
    visible,
  });
}

export default DSButtonHelper;
