<template>
  <div
    class="ds-checkbox"
    @click="handleClick"
  >
    <input
      :id="id"
      ref="element"
      :checked="checkboxChecked"
      class="ds-checkbox__input"
      type="checkbox"
    >
    <DSIcon
      class="ds-checkbox__icon"
      color="linked"
      icon="checkboxOn"
      size="s"
    />
  </div>
</template>

<script>
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';

export default {
  name: 'DSCheckbox',
  components: {
    DSIcon,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      checkboxChecked: false,
    };
  },
  computed: {
    id() {
      return `ds-checkbox-id-${this._uid}`;
    },
  },
  watch: {
    value() {
      this.checkboxChecked = this.value;
    },
  },
  mounted() {
    this.checkboxChecked = this.value;
  },
  methods: {
    handleClick() {
      this.checkboxChecked = !this.checkboxChecked;

      this.$emit('input', this.checkboxChecked);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import "../variables";

.ds-checkbox {
  width: 18px;
  height: 18px;
  margin: 0;
  border: 1px solid $checkbox-border-color-primary;
  border-radius: 4px;
  transition: all linear .2s;
  appearance: none;

  &__input {
    position: absolute;
    z-index: -10;
    overflow: hidden;
    width: 0;
    height: 0;

    &:checked + .ds-checkbox__icon {
      display: block;
    }
  }

  &__icon {
    display: none;
    margin: -1px;
  }

  &:hover {
    border: 1px solid $checkbox-border-color-hover;
  }
}
</style>
