import validateFiles from '@/module/common/components/UI/CFileUpload/validateFiles';
import IFile from '@/module/common/models/UI/FileUpload/IFile';
import EFileStatus from '@/module/common/models/UI/FileUpload/EFileStatus';
import IFormField from '@/module/design-system/components/models/FormComponent/IFormField';
import EAccountActivateFileType from '@/module/merchant/models/EAccountActivateFileType';
import IFileStorageFrontendModel from '@/module/merchant/models/IFileStorageFrontendModel';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import TTSApi from '@/module/common/tsApi/TTSApi';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import downloadFile from '@/module/common/utils/file/downloadFile';
import fileExtensionToLowerCase from '@/module/common/utils/file/fileExtensionToLowerCase';
import IValidatorHandler from '@/module/merchant/view/AccountActivatePage/Validator/IValidatorHandler';
import IPageState from '@/module/merchant/view/AccountActivatePage/models/IPageState';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';
import IFormDraftHandler
  from '@/module/merchant/view/AccountActivatePage/Handler/contract/IFormDraftHandler';
import EFieldBlock from '@/module/merchant/view/AccountActivatePage/models/EFieldBlock';
import EFieldName from '@/module/merchant/view/AccountActivatePage/models/EFieldName';
import IFormConfigHandler
  from '@/module/merchant/view/AccountActivatePage/Handler/contract/IFormConfigHandler';
import THandleFieldBlurParams from '@/module/merchant/view/AccountActivatePage/models/THandleFieldBlurParams';
import IFileUploadHandler
  from '@/module/merchant/view/AccountActivatePage/Handler/contract/IFileUploadHandler';

class FileUploadHandler implements IFileUploadHandler {
  private readonly state: IPageState;

  private readonly serviceContainer: IServiceContainer;

  private readonly validationHandler: IValidatorHandler;

  private readonly formConfigHandler: IFormConfigHandler;

  private readonly localisationRegistry: ILocalisationRegistry;

  private readonly draftHandler: IFormDraftHandler;

  constructor(
    state: IPageState,
    serviceContainer: IServiceContainer,
    validationHandler: IValidatorHandler,
    formConfigHandler: IFormConfigHandler,
    localisationRegistry: ILocalisationRegistry,
    draftHandler: IFormDraftHandler,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.validationHandler = validationHandler;
    this.formConfigHandler = formConfigHandler;
    this.localisationRegistry = localisationRegistry;
    this.draftHandler = draftHandler;
  }

  // eslint-disable-next-line class-methods-use-this
  deleteFileHandler = (data) => {
    const utilsService = this.serviceContainer.utilsService;

    const { file, formConfig: field } = data;
    const fieldBlock = field.fieldBlock;
    const fieldName = field.fieldName;

    if (file.fileModel?.s3ObjectKey) {
      const formDataClone = utilsService.data.cloneImmutable(this.state.formData);
      formDataClone[fieldBlock][fieldName] = formDataClone[fieldBlock][fieldName]
        .map((formFieldFile) => {
          if (formFieldFile.fileModel?.s3ObjectKey === file.fileModel?.s3ObjectKey) {
            formFieldFile.status = 'deleting';
          }

          return formFieldFile;
        });

      this.state.formData = utilsService.data.cloneImmutable(formDataClone);

      this.deleteFile(file.fileModel.s3ObjectKey).then(() => {
        const formData = utilsService.data.cloneImmutable(this.state.formData);

        formData[fieldBlock][fieldName] = formData[fieldBlock][fieldName]
          .filter((formFile) => formFile.fileModel?.s3ObjectKey !== file.fileModel?.s3ObjectKey);

        formData[fieldBlock][fieldName] = formData[fieldBlock][fieldName]
          .filter((file) => file.status === EFileStatus.SUCCESS);

        this.state.formData = utilsService.data.cloneImmutable(formData);
        const tabListConfig = this.formConfigHandler.getTabListConfig();
        this.state.formDraft = this.draftHandler.updateDraft(tabListConfig, this.state.formDraft);
        this.draftHandler.saveDraft(this.state.formDraft);
      });
    } else {
      const formDataClone = utilsService.data.cloneImmutable(this.state.formData);

      formDataClone[fieldBlock][fieldName] = formDataClone[fieldBlock][fieldName]
        .filter((fileClone) => fileClone.fileName !== file.fileName);

      this.state.formData = utilsService.data.cloneImmutable(formDataClone);
    }
  };

  // eslint-disable-next-line class-methods-use-this
  downloadFileHandler = async (data) => {
    const { file } = data;
    if (file.status !== 'success') {
      return;
    }

    await this.handleDownloadFile(file);
  };

  handleDownloadFile = async (file) => {
    const tsApi = this.serviceContainer.resolve<TTSApi>(EServiceContainerInstances.TS_API);
    if (!file.fileModel?.s3ObjectKey) {
      return;
    }

    const response = await tsApi.filesStorage.get(file.fileModel.s3ObjectKey, { responseType: 'blob' });

    downloadFile(response.data, file);
  };

  // eslint-disable-next-line class-methods-use-this
  getFileType = (
    item: IFormField<EFieldName, EFieldBlock>,
  ): EAccountActivateFileType => {
    const fileTypeMap = {
      [EFieldBlock.BUSINESS_REPRESENTATIVE]: {
        [EFieldName.DOCUMENT_PASSPORT]: EAccountActivateFileType.DOCUMENT_PASSPORT,
        [EFieldName.DOCUMENT_SNILS]: EAccountActivateFileType.DOCUMENT_SNILS,
        [EFieldName.DOCUMENT_INDIVIDUAL_ENTREPRENEUR]:
        EAccountActivateFileType.DOCUMENT_INDIVIDUAL_ENTREPRENEUR,
        [EFieldName.DOCUMENT_COMPANY]: EAccountActivateFileType.DOCUMENT_COMPANY,
      },
    };

    return fileTypeMap[item.fieldBlock]?.[item.fieldName] ?? EAccountActivateFileType.NONE;
  };

  // eslint-disable-next-line class-methods-use-this
  processUploadFiles = (data: THandleFieldBlurParams) => {
    const utilsService = this.serviceContainer.utilsService;

    const files: IFile[] = data.value as IFile[];
    const { fieldBlock, fieldName } = data.field;

    const formData = utilsService.data.cloneImmutable(data.formData);

    const successedFiles = files.filter((file) => file.status === 'success');
    const filesForValidate = files.filter((file) => file.status !== 'success');
    // TODO: typing
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const validated = validateFiles(filesForValidate, data.field?.fileUpload?.options);

    formData[fieldBlock][fieldName] = [...validated, ...successedFiles];

    this.state.formData = formData;

    validated.forEach((validatedFile) => {
      if (!validatedFile.fileModel?.s3ObjectKey && [EFileStatus.DEFAULT].includes(validatedFile.status)) {
        const formData = utilsService.data.cloneImmutable(this.state.formData);

        if (!validatedFile.fileModel?.fileType) {
          validatedFile.fileModel = validatedFile.fileModel ?? {} as IFileStorageFrontendModel;
          validatedFile.fileModel.fileType = this.getFileType(data.field);
        }

        const fileIndex = formData[fieldBlock][fieldName]
          .findIndex((formFieldFile) => formFieldFile.fileName === validatedFile.fileName);
        formData[fieldBlock][fieldName][fileIndex].status = EFileStatus.LOADING;

        this.state.formData = utilsService.data.cloneImmutable(formData);

        this.loadFile(validatedFile).then((response) => {
          const formData = utilsService.data.cloneImmutable(this.state.formData);
          const fileIndex = formData[fieldBlock][fieldName]
            .findIndex((formFieldFile) => formFieldFile.fileName === validatedFile.fileName);

          formData[fieldBlock][fieldName][fileIndex].status = EFileStatus.SUCCESS;

          formData[fieldBlock][fieldName][fileIndex].fileModel = response.data;

          this.state.formData = utilsService.data.cloneImmutable(formData);

          const localFormFieldStatusList = utilsService.data.cloneImmutable(this.state.formFieldStatusList);
          this.state.formFieldStatusList = this.validationHandler
            .validateField(data.field, this.state.formData, localFormFieldStatusList);

          const tabListConfig = this.formConfigHandler.getTabListConfig();
          this.state.formDraft = this.draftHandler.updateDraft(tabListConfig, this.state.formDraft);
          this.draftHandler.saveDraft(this.state.formDraft);
        }).catch(() => {
          const formData = utilsService.data.cloneImmutable(this.state.formData);
          formData[fieldBlock][fieldName] = formData[fieldBlock][fieldName]
            .map((formFieldFile) => {
              if (formFieldFile.fileName === validatedFile.fileName) {
                formFieldFile.status = 'error';
                formFieldFile.errors = [
                  {
                    type: 'load_error',
                    message: this.localisationRegistry.accountActivate.fileUpload.validationMessages.loadError,
                  },
                ];
              }

              return formFieldFile;
            });

          this.state.formData = utilsService.data.cloneImmutable(formData);

          const localFormFieldStatusList = utilsService.data.cloneImmutable(this.state.formFieldStatusList);
          this.state.formFieldStatusList = this.validationHandler
            .validateField(data.field, this.state.formData, localFormFieldStatusList);
        });
      }
    });
  };

  deleteFile = (s3ObjectKey) => {
    const tsApi = this.serviceContainer.resolve<TTSApi>(EServiceContainerInstances.TS_API);

    return tsApi.filesStorage.delete(s3ObjectKey);
  };

  loadFile = async (file) => {
    const tsApi = this.serviceContainer.resolve<TTSApi>(EServiceContainerInstances.TS_API);

    const formDataFile = new FormData();
    formDataFile.append('file', fileExtensionToLowerCase(file.file));
    formDataFile.append('fileType', file.fileModel.fileType);

    return tsApi.filesStorage.create(formDataFile);
  };
}

export default FileUploadHandler;
