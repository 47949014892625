enum EIcon {
  ALERT = 'alert',
  ANALYTICS = 'analytics',
  ARROW_DOWN = 'arrowDown',
  ARROW_INSIDE_CIRCLE = 'arrowInsideCircle',
  ARROW_LEFT = 'arrowLeft',
  ARROW_OPPOSITELY_DIRECTED = 'arrowOppositelyDirected',
  ARROW_RETURN = 'arrowReturn',
  ARROW_RIGHT = 'arrowRight',
  ARROW_UP = 'arrowUp',
  ATTACH = 'attach',
  BAG = 'bag',
  BAR_GRAPH = 'barGraph',
  BELL = 'bell',
  BILL = 'bill',
  BLIND_EYE = 'blindEye',
  BRANDING = 'branding',
  BURGER_MENU = 'burgerMenu',
  CALENDAR = 'calendar',
  CAMERA = 'camera',
  CHECK_CIRCLE = 'checkCircle',
  CHECK_CIRCLE_FILLED = 'checkCircleFilled',
  CHECKBOX_ON = 'checkboxOn',
  CLOCK = 'clock',
  COPY = 'copy',
  CROSS = 'cross',
  CROSS_CIRCLE = 'crossCircle',
  CROSS_INSIDE_CIRCLE = 'crossInsideCircle',
  DATA_EXPORTS = 'dataExports',
  DEVELOPERS = 'developers',
  DOTS = 'dots',
  DOUBLE_ARROW_LEFT = 'doubleArrowLeft',
  DOWNLOAD = 'download',
  EDIT = 'edit',
  ELLIPSIS = 'ellipsis',
  EXCLAMATION = 'exclamation',
  EXPORT = 'export',
  EYE = 'eye',
  FLAG_RU = 'flag-ru',
  GEAR = 'gear',
  HOME = 'home',
  INFO = 'info',
  LINE_GRAPH = 'lineGraph',
  PAYOUTS = 'payouts',
  PLUS = 'plus',
  PRINT = 'print',
  PRODUCT = 'product',
  PROFILE = 'profile',
  PROMOTIONS = 'promotions',
  QUESTION = 'question',
  REFRESH = 'refresh',
  SEARCH = 'search',
  SELECT_ARROW = 'selectArrow',
  SERVICES = 'services',
  SORT = 'sort',
  SUBTRACT = 'subtract',
  TABLE_GRID = 'tableGrid',
  TAIL_ARROW_LEFT = 'tailArrowLeft',
  TAIL_ARROW_RIGHT = 'tailArrowRight',
  TRASH = 'trash',
  UPLOAD = 'upload',
  WARN_CIRCLE = 'warnCircle',
}

export default EIcon;
