// TODO: Удалить решения с передачей query строкой.
//  Сбор строки запроса - обязанность сервиса апи.
export default function reportsModule(instance) {
  return {
    getCurrency() {
      return instance.get('/vendor/report/list/currency');
    },
    getReportsOverview(query, token) {
      return instance.get(`/vendor/report/overview${query}`, { cancelToken: token });
    },
    getReportsTable(query, token) {
      return instance.get(`/vendor/report/overview/table${query}`, { cancelToken: token });
    },
    getTableExportLink(format, query) {
      return instance.get(`/vendor/report/overview/table/${format}${query}`);
    },
  };
}
