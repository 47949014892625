<template>
  <div
    class="c-input-list"
  >
    <div
      class="c-input-list__list"
    >
      <div
        v-for="(item, index) in list"
        :key="index"
        class="c-input-list__item"
      >
        <slot
          :index="index"
          :item="item"
          :status="statuses[index]"
          name="before"
        />
        <div class="c-input-list__input">
          <DSInput
            :value="item.value"
            :status="getFieldStatus(index)"
            :placeholder="placeholder"
            :input-delay="inputDelay"
            @input="handleInput($event, index)"
            @blur="handleBlur($event, index)"
          />
          <DSIcon
            class="c-input-list__icon"
            icon="trash"
            size="l"
            @click="handleDeleteItem(index)"
          />
        </div>
        <slot
          :index="index"
          :item="item"
          :status="statuses[index]"
          name="after"
        />
      </div>
    </div>
    <div
      v-if="addAvailable"
      class="c-input-list__footer"
    >
      <DSButton
        :text="addButtonText"
        variant="tretiary"
        @click="handleAddItem"
      >
        <template
          #icon-before
        >
          <DSIcon
            color="linked"
            icon="plus"
          />
        </template>
      </DSButton>
    </div>
  </div>
</template>

<script>
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSInput from '@/module/design-system/components/UI/DSInput.vue';

export default {
  name: 'CInputList',
  components: {
    DSButton,
    DSIcon,
    DSInput,
  },
  props: {
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
    maxItems: {
      type: Number,
      default: null,
    },
    addButtonText: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    statuses: {
      type: Array,
      default: () => [],
    },
    inputDelay: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      list: [],
    };
  },
  computed: {
    addAvailable() {
      return this.list.length < this.maxItems;
    },
  },
  watch: {
    value() {
      this.list = [...this.value];
    },
  },
  mounted() {
    this.list = this.value.length > 0 ? [...this.value] : this.list;
  },
  methods: {
    getFieldStatus(index) {
      return this.statuses[index]?.code ?? 'default';
    },
    handleInput(value, index) {
      this.list[index].value = value;
      this.$emit('input', this.list);
    },
    handleBlur(value, index) {
      this.list[index].value = value;
      this.$emit('blur', this.list);
    },
    handleAddItem() {
      this.list.push({
        value: '',
      });
      this.$emit('add-item', this.list);
    },
    handleDeleteItem(index) {
      this.list = [...this.list.filter((item, itemIndex) => itemIndex !== index)];
      this.$emit('input', this.list);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.c-input-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  gap: 20px;

  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    gap: 20px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
  }

  &__input {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100%;
    gap: 20px;
  }

  &__icon {
    cursor: pointer;
  }
}
</style>
