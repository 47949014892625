<template>
  <div
    :class="variant === 'spaced' ? 'button-group__spaced' : 'button-group'"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'ButtonSet',
  props: {
    variant: {
      type: String,
      default: 'stucked',
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import './styles';
</style>
