import camelCaseToSnake from '@/module/common/utils/string/camelCaseToSnake';

export default function mapObjectCamelCaseToSnakeKeysRecursive<T, R>(object: T | object): R | object {
  return Object.entries(object).reduce((changed, pair) => {
    const [key, value] = pair;
    const changedKey = camelCaseToSnake(key);

    changed[changedKey] = typeof value === 'object' ? mapObjectCamelCaseToSnakeKeysRecursive(value) : value;

    return changed;
  }, {} as R | object);
}
