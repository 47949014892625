import IValidateHandler from '@/module/common/components/FormComponent/ValidatorHandler/contract/IValidateHandler';
import IFormFieldItem from '@/module/design-system/components/models/FormComponent/IFormFieldItem';
import IFormConfigBlock from '@/module/design-system/components/models/FormComponent/IFormConfigBlock';
import IConfig from '@/module/common/components/FormComponent/ValidatorHandler/contract/IConfig';
import IFieldStatusHandler
  from '@/module/common/components/FormComponent/ValidatorHandler/contract/IFieldStatusHandler';
import cloneImmutable from '@/module/common/utils/data/cloneImmutable';

class ValidateHandler<
  ConfigMap,
  FormData extends object,
  FieldName,
  FieldBlockName = undefined
> implements IValidateHandler<ConfigMap, FormData, FieldName, FieldBlockName> {
  private readonly config: IConfig<ConfigMap, FieldName, FieldBlockName>;

  private readonly fieldStatusHandler: IFieldStatusHandler;

  constructor(
    config: IConfig<ConfigMap, FieldName, FieldBlockName>,
    fieldStatusHandler: IFieldStatusHandler,
  ) {
    this.config = config;
    this.fieldStatusHandler = fieldStatusHandler;
  }

  getConfig(): IConfig<ConfigMap, FieldName, FieldBlockName> {
    return this.config;
  }

  validateBlock = (
    formConfig: IFormConfigBlock<FieldName, FieldBlockName>[],
    formData: FormData,
  ): IFormConfigBlock<FieldName, FieldBlockName>[] => formConfig.reduce((blockList, block) => {
    block.items = block.items.map((field) => this.validateField(field, formData));

    blockList.push(block);
    return blockList;
  }, [] as IFormConfigBlock<FieldName, FieldBlockName>[]);

  validateField = (
    fieldConfig: IFormFieldItem<FieldName, FieldBlockName>,
    formData: FormData,
  ): IFormFieldItem<FieldName, FieldBlockName> => {
    const localFieldConfig = cloneImmutable(fieldConfig);
    const config = this.getConfig();
    const { fieldName, fieldBlock } = localFieldConfig;

    if (!config.hasValidator(fieldName, fieldBlock)) {
      return localFieldConfig;
    }

    const validator = config.getValidator(fieldName, fieldBlock);

    let value;
    if (fieldBlock) {
      value = formData[fieldBlock as unknown as string][fieldName as unknown as string];
    } else {
      value = formData[fieldName as unknown as string];
    }

    validator.validate(value, formData);

    localFieldConfig.status = this.fieldStatusHandler.getStatus(validator);

    return localFieldConfig;
  };
}

export default ValidateHandler;
