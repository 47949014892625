
import Vue, {
  computed, PropType,
} from 'vue';

import TWidget from '@/module/dashboard/models/IWidgetConfig';

import DashboardWidgetTitle from '@/module/dashboard/components/WidgetList/DashboardWidgetTitle.vue';
import DashboardWidgetMetrics from '@/module/dashboard/components/WidgetList/DashboardWidgetMetrics.vue';

export default Vue.extend({
  name: 'DashboardWidgetArea',
  components: {
    DashboardWidgetMetrics,
    DashboardWidgetTitle,
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    widget: {
      type: Object as PropType<TWidget>,
      required: true,
    },
  },
  setup(props) {
    const classes = computed(() => [
      'dashboard-widget-area',
      `dashboard-widget-area_${props.widget.config?.type}`,
    ]);

    return {
      classes,
    };
  },
});
