function addDays(days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

export default function getDaysInPeriod(startDate, endDate) {
  const dates = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    const dd = currentDate.getDate();
    const mm = currentDate.getMonth() + 1;
    const yyyy = currentDate.getFullYear();
    const computeDD = dd < 9 ? `0${dd}` : dd;
    const computeMM = mm < 9 ? `0${mm}` : mm;
    dates.push(`${computeDD}.${computeMM}.${yyyy}`);
    currentDate = addDays.call(currentDate, 1);
  }
  return dates;
}
