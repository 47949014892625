import IFieldBlurScenario from '@/module/product/view/ProductFormPage/scenario/contract/IFieldBlurScenario';
import THandleFieldBlurParams from '@/module/product/view/ProductFormPage/model/THandleFieldBlurParams';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';

abstract class BaseFieldBlurScenario implements IFieldBlurScenario {
  protected serviceContainer: IServiceContainer;

  public constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  abstract run(item: THandleFieldBlurParams);

  protected updateFromData = (item: THandleFieldBlurParams) => {
    const productFormPage = this.serviceContainer.moduleProduct.productFormPage;

    const formData = productFormPage.handlerService.formDataHandler.get();
    formData[item.field.fieldName] = item.value;

    productFormPage.handlerService.formDataHandler.update(formData);
  };

  protected updateFromConfig = () => {
    const productFormPage = this.serviceContainer.moduleProduct.productFormPage;

    const formConfig = productFormPage.handlerService.formConfigHandler.get();
    productFormPage.handlerService.formConfigHandler.update(formConfig);
  };
}

export default BaseFieldBlurScenario;
