<template>
  <div
    v-click-outside="handleClickOutside"
    class="button-more__layout"
  >
    <button
      :class="buttonClasses"
      type="button"
      @click="handleTogglePopover"
    >
      <slot />
      <svg
        v-if="!isDefaultSlot"
        width="16"
        height="4"
        viewBox="0 0 16 4"
        xmlns="http://www.w3.org/2000/svg"
      >
        <!-- eslint-disable vue/max-len max-len -->
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14 0C15.1 0 16 0.9 16 2C16 3.1 15.1 4 14 4C12.9 4 12 3.1 12 2C12 0.9 12.9 0 14 0ZM8 4C6.9 4 6 3.1 6 2C6 0.9 6.9 0 8 0C9.1 0 10 0.9 10 2C10 3.1 9.1 4 8 4ZM2 4C0.9 4 0 3.1 0 2C0 0.9 0.9 0 2 0C3.1 0 4 0.9 4 2C4 3.1 3.1 4 2 4Z"
        />
        <!-- eslint-enable vue/max-len max-len -->
      </svg>
    </button>

    <div
      v-if="isMenuOpened"
      class="button-more__dropdown"
    >
      <button
        v-for="button of buttons"
        :key="button.name"
        class="button-more__option-buttons"
        type="button"
        @click="(event) => handleClickButton(event, button)"
      >
        {{ $t(button.name) }}
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ButtonMore',
  props: {
    buttons: {
      type: Array,
      default: () => [],
    },
    variant: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isMenuOpened: false,
    };
  },
  computed: {
    isDefaultSlot() {
      return !!this.$scopedSlots.default;
    },
    buttonClasses() {
      return [
        'button-more',
        this.variant ? `button-more_${this.variant}` : null,
      ];
    },
  },
  methods: {
    handleTogglePopover(event) {
      event.stopPropagation();
      this.isMenuOpened = !this.isMenuOpened;
    },
    handleClickOutside() {
      if (!this.isMenuOpened) return;
      if (this.isMenuOpened) this.isMenuOpened = false;
    },
    handleClickButton(event, button) {
      event.stopPropagation();
      if (button.close) this.handleClickOutside();
      button.handler();
    },
  },
};
</script>

<style lang="scss">
.button-more.button-more_gray-square {
  width: auto;
  height: auto;
  padding: 8px;
  border-radius: 12px;
  background-color: #e5e5e5;
}
@import 'style.scss';
</style>
