enum ETextVariants {
  MEDIUM = 'medium',
  REGULAR = 'regular',
  ITALIC = 'italic',
  SMALL = 'small',
  SMALL_MEDIUM = 'small-medium',
  X_SMALL = 'xsmall',
  X_SMALL_MEDIUM = 'xsmall-medium',
}

export default ETextVariants;
