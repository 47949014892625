import Profile from '@/module/merchant/view/ProfilePage.vue';
import defaultLayout from '@/router/defaultLayout';
import ERouteName from '@/module/common/models/router/ERouteName';
import AccountActivate from '../view/AccountActivatePage.vue';

const moduleSlug = 'merchant';

export default () => [
  {
    path: `/${moduleSlug}/account/profile`,
    name: ERouteName.PROFILE,
    components: {
      default: Profile,
      ...defaultLayout,
    },
    meta: { title: 'titles.profile' },
  },
  {
    path: `/${moduleSlug}/account/activate`,
    name: ERouteName.ACCOUNT_ACTIVATE,
    components: {
      default: AccountActivate,
    },
    meta: { title: 'titles.account_activate' },
  },
];
