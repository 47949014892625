
import Vue, {
  computed, PropType, reactive, watch,
} from 'vue';
import IDSInputConfig from '@/module/common/models/DSConfig/UI/DSInput/IDSInputConfig';
import IDSSelectConfig from '@/module/common/models/DSConfig/UI/DSSelect/IDSSelectConfig';
import IDSIcon from '@/module/design-system/components/models/IDSIcon';
import ICInputWithSelectListValue from '@/module/common/models/UI/CInputWithSelectList/ICInputWithSelectListValue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import cloneImmutable from '@/module/common/utils/data/cloneImmutable';
import CLabel from '@/module/common/components/UI/CLabel.vue';
import CInputWithSelect from '@/module/common/components/UI/CInputWithSelect.vue';
import ICInputWithSelectValue from '@/module/design-system/components/models/UI/ICInputWithSelectValue';
import IButtonWithIconConfig from '@/module/common/models/helpers/getButton/IButtonWithIconConfig';

export default Vue.extend({
  name: 'CInputWithSelectList',
  components: {
    CInputWithSelect,
    CLabel,
    DSButton,
    DSIcon,
  },
  props: {
    inputConfig: {
      type: Object as PropType<IDSInputConfig>,
      required: true,
    },
    selectConfig: {
      type: Object as PropType<IDSSelectConfig>,
      required: true,
    },
    iconConfig: {
      type: Object as PropType<IDSIcon>,
      required: true,
    },
    buttonConfig: {
      type: Object as PropType<IButtonWithIconConfig>,
      required: true,
    },
    maxItems: {
      type: Number,
      default: () => null,
    },
    itemLabel: {
      type: String,
      default: () => null,
    },
    value: {
      type: Array as PropType<ICInputWithSelectListValue[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const state: { value: ICInputWithSelectListValue[] } = reactive({
      value: [],
    });

    function getNextId(list: ICInputWithSelectListValue[]): number {
      const ids = list
        .filter((item) => item.id !== undefined)
        .map((item) => item.id);

      return ids.length > 0 ? Math.max(...ids as number[]) + 1 : 1;
    }

    function changeStateValue(list: ICInputWithSelectListValue[]): void {
      const value = cloneImmutable(list);
      let nextId = getNextId(value);

      state.value = value.map((item) => {
        if (!item.id) {
          item.id = nextId;
          nextId += 1;
        }

        return item;
      });
    }

    watch(() => props.value, (newValue) => {
      changeStateValue(newValue);
    });

    changeStateValue(props.value);

    function handleAddItem() {
      const nextId = getNextId(state.value);

      const newItem: ICInputWithSelectListValue = {
        id: nextId,
        iconConfig: cloneImmutable(props.iconConfig),
        inputConfig: cloneImmutable(props.inputConfig),
        selectConfig: cloneImmutable(props.selectConfig),
        value: {
          inputValue: '',
          selectValue: {},
        },
      };

      state.value.push(newItem);

      emit('add-item', { list: cloneImmutable(state.value), item: newItem });
    }

    function handleDeleteItem(id: number) {
      const item = state.value.find((listItem) => listItem.id === id);
      state.value = state.value.filter((listItem) => listItem.id !== id);

      emit('delete-item', { list: cloneImmutable(state.value), item });
    }

    function handleInputBlur(id: number, value: ICInputWithSelectValue) {
      const item = cloneImmutable(state.value.find((listItem) => listItem.id === id)) as ICInputWithSelectListValue;
      item.value = cloneImmutable(value);

      state.value = state.value.map((listItem) => {
        if (listItem.id === id) {
          return cloneImmutable(item);
        }

        return listItem;
      });

      emit('input-blur', { list: cloneImmutable(state.value), item });
    }

    function handleInputInput(id: number, value: ICInputWithSelectValue) {
      const item = cloneImmutable(state.value.find((listItem) => listItem.id === id)) as ICInputWithSelectListValue;
      item.value = cloneImmutable(value);

      state.value = state.value.map((listItem) => {
        if (listItem.id === id) {
          return cloneImmutable(item);
        }

        return listItem;
      });

      emit('input-input', { list: cloneImmutable(state.value), item });
    }

    function handleSelectBlur(id: number, value: ICInputWithSelectValue) {
      const item = cloneImmutable(state.value.find((listItem) => listItem.id === id)) as ICInputWithSelectListValue;
      item.value = cloneImmutable(value);

      state.value = state.value.map((listItem) => {
        if (listItem.id === id) {
          return cloneImmutable(item);
        }

        return listItem;
      });

      emit('select-blur', { list: cloneImmutable(state.value), item });
    }

    const isAddButtonShow = computed(() => {
      if (props.maxItems as unknown as null === null) {
        return true;
      }

      return state.value.length < props.maxItems;
    });

    function getLabel(index) {
      return `${props.itemLabel} ${index + 1}`;
    }

    return {
      state,
      isAddButtonShow,
      getLabel,
      handleAddItem,
      handleDeleteItem,
      handleInputBlur,
      handleInputInput,
      handleSelectBlur,
    };
  },
});
