<template>
  <div class="dropdown-min">
    <ButtonComponent
      class="dropdown-min__drop-button"
      :class="isOpened ? 'dropdown-min__drop-button--active' : null"
      variant="tretiary"
      icon-button
      @click="handleToggleMenu"
    >
      <DSIcon
        icon="arrowOppositelyDirected"
      />
    </ButtonComponent>

    <div
      v-if="isOpened"
      class="dropdown-min__list"
    >
      <button
        v-for="option of options"
        :key="option"
        class="dropdown-min__option"
        type="button"
        @click="handleSelectedChange(option)"
      >
        <span
          class="dropdown-min__radio-button"
          :class="modelValue === option ? 'dropdown-min__radio-button--active' : null"
        />
        {{ $t(option) }}
      </button>
    </div>
  </div>
</template>

<script>
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import ButtonComponent from '../Button/ButtonComponent.vue';

export default {
  name: 'DropdownMin',
  components: {
    DSIcon,
    ButtonComponent,
  },
  props: {
    options: {
      type: Array,
      default: () => ['option 1', 'option 2', 'other options'],
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpened: false,
    };
  },
  methods: {
    handleToggleMenu() {
      this.isOpened = !this.isOpened;
    },
    handleSelectedChange(value) {
      this.$emit('update:modelValue', value);
      this.handleToggleMenu();
    },
  },
};
</script>

<style lang="scss">
.dropdown-min {
  position: relative;

  &__list {
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 220px;
    background-color: white;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 4px 24px 0px #0000001A;
    border-radius: 8px;
    padding: 8px 12px;
  }

  &__option {
    display: flex;
    align-items: center;
    font-family: $text-ibm-plex-sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    border: 0;
    background-color: transparent;
    padding: 15px 12px;
    white-space: nowrap;
  }

  &__radio-button {
    display: inline-block;
    margin-right: 8px;
    height: 16px;
    width: 16px;
    min-width: 16px;
    background-color: transparent;
    border: 1px solid #C7C7CD;
    border-radius: 50%;

    &--active {
      border: 5px solid #4397CB;
    }
  }

  &__drop-button {
    span {
      width: 20px;
    }

    svg path {
      fill: #ABACAC;
    }

    &:hover {
      background-color: transparent;
    }
  }

  &__drop-button--active,
  &__drop-button--active:hover {
    svg path {
      fill: black;
    }

    background-color: #E5E5E5;
  }
}
</style>
