import { render, staticRenderFns } from "./CInfoBlock.vue?vue&type=template&id=08e37203&scoped=true&"
import script from "./CInfoBlock.vue?vue&type=script&lang=ts&"
export * from "./CInfoBlock.vue?vue&type=script&lang=ts&"
import style0 from "./CInfoBlock.vue?vue&type=style&index=0&id=08e37203&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08e37203",
  null
  
)

export default component.exports