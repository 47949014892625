var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"product-list"},_vm._l((_vm.products),function(item,idx){return _c('li',{key:item.product.id,staticClass:"product-list__product product",on:{"click":(event) => _vm.handleRedirectToProductCard(event, item.product.id, idx)}},[_c('div',{staticClass:"product__image",class:item.product.icon ? 'product__image--bordered' : null},[(item.product.icon)?_c('img',{attrs:{"src":item.product.icon,"alt":item.product.title}}):_c('DSIcon',{attrs:{"icon":"product","color":"linked"}})],1),_c('div',{staticClass:"product__info"},[_c('div',{staticClass:"product__name"},[_c('p',[_vm._v(_vm._s(item.product.title)+" •"),_c('br'),_vm._v(" "+_vm._s(item.product.subtitle))])]),_c('div',{staticClass:"product__ids"},[_c('p',{staticClass:"secondary"},[_c('span',{staticClass:"product__id"},[_vm._v(_vm._s(_vm.$t('product.product_id'))+": "+_vm._s(item.product.id))]),_vm._v(" • "),_c('span',{staticClass:"product__sku"},[_vm._v(_vm._s(_vm.$t('product.product_sku'))+": "+_vm._s(item.product.sku || '-'))])])]),_c('time',{staticClass:"product__update-date secondary"},[_vm._v(" "+_vm._s(_vm.utilsService.date.formatSkipCurrentYearWithoutWeekDay(item.product.updateDate))+" ")]),_c('ButtonMore',{ref:"buttonMoreRef",refInFor:true,staticClass:"product__button-more",attrs:{"buttons":[
          {
            name: 'product.product_card.edit',
            handler: () => _vm.handleRedirectToEdit(item.product.id),
          },
          {
            name: 'product.product_card.delete',
            handler: () => _vm.handleDeleteProduct(item.product.id),
          },
          {
            name: 'product.product_card.copy_buy_link',
            handler: () => _vm.handleCopyBuyLink(item.buylink),
          },
        ]}})],1)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }