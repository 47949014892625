<template>
  <button
    class="scroll-top-button"
    type="button"
    @click="handleScrollToZero"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="7"
      viewBox="0 0 14 8"
    >
      <!-- eslint-disable vue/max-len max-len -->
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.305676 7.69398C0.713254 8.10201 1.37407 8.102 1.78165 7.69398L7 2.4699L12.2183 7.69398C12.6259 8.10201 13.2867 8.10201 13.6943 7.69398C14.1019 7.28595 14.1019 6.62441 13.6943 6.21639L7.79044 0.30602C7.5733 0.0886416 7.28428 -0.0129276 6.99997 0.00131307C6.71568 -0.0129143 6.42669 0.0886558 6.20956 0.306022L0.305676 6.21639C-0.101902 6.62441 -0.101902 7.28595 0.305676 7.69398Z"
      />
      <!-- eslint-enable vue/max-len max-len -->
    </svg>
  </button>
</template>

<script>
export default {
  name: 'ScrollTopButton',
  props: {
    handleScrollToZero: {
      type: Function,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.scroll-top-button {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 0;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;

  &:hover {
    background-color: #F0F0F0;
    transition: 0.1s;
  }
}
</style>
