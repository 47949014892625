
import Vue, {
  computed,
  inject,
  onMounted,
  PropType,
  reactive,
  watch,
} from 'vue';
import IDSInputConfig from '@/module/common/models/DSConfig/UI/DSInput/IDSInputConfig';
import IDSSelectConfig from '@/module/common/models/DSConfig/UI/DSSelect/IDSSelectConfig';
import IDSIcon from '@/module/design-system/components/models/IDSIcon';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import ICInputWithSelectValue from '@/module/design-system/components/models/UI/ICInputWithSelectValue';
import CSelect from '@/module/common/components/UI/CSelect.vue';
import CInput from '@/module/common/components/UI/CInput.vue';
import ECInputWithSelectMode from '@/module/common/models/ECInputWithSelectMode';
import CLabel from '@/module/common/components/UI/CLabel.vue';
import DSSelect from '@/module/design-system/components/UI/DSSelect.vue';
import DSInput from '@/module/design-system/components/UI/DSInput.vue';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import EColorScheme from '@/module/design-system/components/models/EColorScheme';
import mapFieldStatusToColorScheme from '@/module/common/service/FormComponent/mappers/mapFieldStatusToColorScheme';
import formFieldStatusCodeMapper from '@/module/common/service/FormComponent/mappers/formFieldStatusMapper';
import CMaskedInput from '@/module/common/components/UI/MaskedInput/CMaskedInput.vue';

export default Vue.extend({
  name: 'CInputWithSelect',
  components: {
    CMaskedInput,
    DSInput,
    DSSelect,
    CLabel,
    CInput,
    CSelect,
    DSIcon,
    DSTextElement,
  },
  props: {
    label: {
      type: String,
      default: () => null,
    },
    tooltip: {
      type: String,
      default: () => null,
    },
    inputConfig: {
      type: Object as PropType<IDSInputConfig>,
      required: true,
    },
    selectConfig: {
      type: Object as PropType<IDSSelectConfig>,
      required: true,
    },
    iconConfig: {
      type: Object as PropType<IDSIcon>,
      default: () => null,
    },
    value: {
      type: Object as PropType<ICInputWithSelectValue>,
      default: () => ({
        inputValue: '',
        selectValue: {},
      }),
    },
    mode: {
      type: String as PropType<ECInputWithSelectMode>,
      default: () => ECInputWithSelectMode.DEFAULT,
    },
  },
  setup(props, { emit }) {
    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;
    const utilsService = serviceContainer.utilsService;
    const state = reactive({
      focus: false,
      isEditing: false,
      value: {
        inputValue: '',
        selectValue: {},
      },
    });

    function handleInputKeydown(event) {
      state.isEditing = true;

      emit('keydown', event);
    }

    watch(() => props.value, (newValue) => {
      state.value = utilsService.data.cloneImmutable(newValue);
    });

    onMounted(() => {
      state.value = utilsService.data.cloneImmutable(props.value);
    });

    function handleInputBlur() {
      emit('input-blur', utilsService.data.cloneImmutable(state.value));
    }

    function handleInputInput() {
      emit('input-input', utilsService.data.cloneImmutable(state.value));
    }

    function handleSelectBlur() {
      emit('select-blur', utilsService.data.cloneImmutable(state.value));
    }

    function handleIconClick() {
      emit('icon-click', utilsService.data.cloneImmutable(state.value));
    }

    function handleMonolithInputFocus() {
      state.focus = true;
    }

    function handleMonolithInputBlur() {
      state.focus = false;
      state.isEditing = false;

      emit('input-blur', utilsService.data.cloneImmutable(state.value));
    }

    function handleMonolithSelectBlur() {
      state.focus = false;
      emit('select-blur', utilsService.data.cloneImmutable(state.value));
    }

    const cInputWithSelectClasses = computed(() => [
      'c-input-with-select__controls',
      props.mode === ECInputWithSelectMode.HORIZONTAL ? 'c-input-with-select_horizontal' : null,
    ]);

    const status = computed(() => props.inputConfig.status ?? { code: EValidationFieldStatusCode.DEFAULT });

    const mappedStatusCode = computed(() => formFieldStatusCodeMapper(status.value.code));

    const isMonolithMode = computed(() => props.mode === ECInputWithSelectMode.MONOLITH);

    const statusTextColor = computed(() => {
      if (state.isEditing) {
        return EColorScheme.SECONDARY;
      }
      return mapFieldStatusToColorScheme(mappedStatusCode.value);
    });

    const monolithClasses = computed(() => [
      'c-input-with-select_monolith',
      `c-input-with-select_monolith_${statusTextColor.value}`,
      state.focus ? 'c-input-with-select_monolith_focused' : null,
    ]);

    return {
      state,
      status,
      cInputWithSelectClasses,
      isMonolithMode,
      monolithClasses,
      statusTextColor,
      handleInputBlur,
      handleInputInput,
      handleSelectBlur,
      handleIconClick,
      handleMonolithInputFocus,
      handleMonolithInputBlur,
      handleMonolithSelectBlur,
      handleInputKeydown,
    };
  },
});
