
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import {
  computed,
  defineComponent, PropType,
} from 'vue';
import ENotifyVariant from '@/module/design-system/components/models/UI/ENotifyVariant';

export default defineComponent({
  name: 'DSAlert',
  components: {
    DSTextElement,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    variant: {
      type: String as PropType<ENotifyVariant>,
      default: ENotifyVariant.INFO,
    },
  },
  setup(props) {
    const textColorsMap = {
      [ENotifyVariant.INFO]: 'menu',
    };
    const textColor = computed(() => textColorsMap[props.variant]);
    const wrapperClasses = computed(() => [
      'ds-alert',
      `ds-alert_${props.variant}`,
    ]);

    return {
      textColor,
      wrapperClasses,
    };
  },
});
