export default function account(instance) {
  return {
    activateIndividualAccount(payload) {
      return instance.post('vendor/account/individual/activate', payload);
    },
    activateSelfEmployedAccount(payload) {
      return instance.post('vendor/account/self-employed/activate', payload);
    },
    activateIndividualEntrepreneurAccount(payload) {
      return instance.post('vendor/account/individual-entrepreneur/activate', payload);
    },
    activateCompanyAccount(payload) {
      return instance.post('vendor/account/company/activate', payload);
    },
    getAccountInfo(payload) {
      return instance.get('merchant/account', payload);
    },
    logout() {
      return instance.post('logout');
    },
  };
}
