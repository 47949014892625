import IInitialDataLoadHandler
  from '@/module/product/view/FulfillmentFormPage/model/contract/handler/IInitialDataLoadHandler';
import IRESTApi from '@/module/common/service/RESTApi/contract/IRESTApi';
import IFulfillmentParametersResponseModel
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/IFulfillmentParametersResponseModel';
import IFulfillmentBackendModel
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/IFulfillmentBackendModel';
import IState from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/IState';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import TTSApi from '@/module/common/tsApi/TTSApi';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import VueRouter from 'vue-router';
import { Store } from 'vuex';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';
import ILocalServiceContainer from '@/module/product/view/FulfillmentFormPage/model/ILocalServiceContainer';
import IHandlerService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IHandlerService';
import ELocalServiceFulfillment from '@/module/product/view/FulfillmentFormPage/model/enums/ELocalServiceFulfillment';
import IMapperService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IMapperService';
import INotificationService from '@/module/common/service/NotificationService/INotificationService';
import ENotificationType from '@/module/common/service/NotificationService/model/ENotificationType';
import IUtilsService from '@/module/common/service/UtilsService/contract/IUtilsService';

class InitialDataLoadHandler implements IInitialDataLoadHandler {
  private readonly state: IState;

  private readonly serviceContainer: IServiceContainer;

  private readonly localServiceContainer: ILocalServiceContainer;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    localServiceContainer: ILocalServiceContainer,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.localServiceContainer = localServiceContainer;
  }

  load = () => {
    const notificationService = this.serviceContainer
      .resolve<INotificationService>(EServiceContainerInstances.NOTIFICATION_SERVICE);
    const utilsService = this.serviceContainer
      .resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const formDataHandler = handlerService.getFormDataHandler();
    const formConfigHandler = handlerService.getFormConfigHandler();
    const tsApi = this.serviceContainer.resolve<TTSApi>(EServiceContainerInstances.TS_API);
    const router = this.serviceContainer.resolve<VueRouter>(EServiceContainerInstances.ROUTER);
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const localisationRegistry = store.getters.getLocalisationRegistry;
    const paramsOptions = localisationRegistry.fulfillment.form.form.parameters.options;

    this.state.isLoading = true;

    const requests = [
      (tsApi.fulfillmentParameters as IRESTApi<IFulfillmentParametersResponseModel>).list(),
      (tsApi.fulfillment as IRESTApi<IFulfillmentBackendModel>).list(),
    ];

    Promise.all(requests)
      .then((responses) => {
        this.state.lists.params = responses[0].data
          .map((val) => {
            let key = val.key.toLowerCase().replace('{', '').replace('}', '');
            key = utilsService.string.snakeToCamelCase(key);

            val.value = paramsOptions[key] ?? val.value;

            return val;
          });

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.state.hasGenerators = responses[1]?.data?.totalCount > 0;

        if (router.currentRoute.params.id) {
          this.state.isEditMode = true;

          (tsApi.fulfillment as IRESTApi<IFulfillmentBackendModel>)
            .get(router.currentRoute.params.id).then((response) => {
              this.state.initialGeneratorType = response.data.type ?? null;

              const mapperService = this.localServiceContainer
                .resolve<IMapperService>(ELocalServiceFulfillment.MAPPER_SERVICE);
              const entityToFormDataMapper = mapperService.getIFulfillmentBackendModelToFormDataMapper();
              const formData = entityToFormDataMapper.map(response.data);
              const licenseSourceHandler = handlerService.getLicenseSourceHandler();
              const licenseSourceName = licenseSourceHandler.getLicenseSourceName(formData);
              formDataHandler.store(licenseSourceName, formData);

              this.state.initialLicenseSourceName = licenseSourceName;
              this.state.licenseSourceName = licenseSourceName;

              this.state.formData = formDataHandler.getData(licenseSourceName);
              this.state.formConfig = formConfigHandler.getConfig(licenseSourceName);

              this.state.isLoading = false;
            }).catch(() => {
              const localisationRegistry: ILocalisationRegistry = store.getters.getLocalisationRegistry;
              const popUpLocalisation = localisationRegistry.popUp;

              notificationService.push(
                ENotificationType.ERROR_POPUP,
                {
                  text: popUpLocalisation.wentWrong,
                  title: popUpLocalisation.error,
                },
              );

              this.state.isLoading = false;
            });
        } else {
          this.state.isLoading = false;
        }

        this.state.formData = formDataHandler.getData(this.state.licenseSourceName);
        this.state.formConfig = formConfigHandler.getConfig(this.state.licenseSourceName);
      })
      .catch(() => {
        const localisationRegistry: ILocalisationRegistry = store.getters.getLocalisationRegistry;
        const popUpLocalisation = localisationRegistry.popUp;

        notificationService.push(
          ENotificationType.ERROR_POPUP,
          {
            text: popUpLocalisation.wentWrong,
            title: popUpLocalisation.error,
          },
        );

        this.state.isLoading = false;

        this.state.formData = formDataHandler.getData(this.state.licenseSourceName);
        this.state.formConfig = formConfigHandler.getConfig(this.state.licenseSourceName);
      });
  };
}

export default InitialDataLoadHandler;
