import EColorScheme from '@/module/design-system/components/models/EColorScheme';
import EHeaderSize from '@/module/design-system/components/models/EHeaderSize';
import IApiKeysModel from '@/module/developers/models/IApiKeysModel';
import ETextVariants from '@/module/common/models/ETextVariants';
import { reactive } from 'vue';
import { ILocalisationApiKeys } from '@/module/developers/views/ApiKeys.func';
import EMerchantMode from '@/module/common/models/EMerchantMode';

interface IStateApiKeysList {
  wrapper: {
    color: EColorScheme,
    borderColor: EColorScheme,
  },
  login: {
    headerSize: EHeaderSize;
    text: string;
  },
  password: {
    color: EColorScheme;
    text: string;
  }
  date: {
    color: EColorScheme;
    variant: ETextVariants;
    text: string;
  },
}

function initState(): IStateApiKeysList {
  return reactive({
    wrapper: {
      color: EColorScheme.PRIMARY_BUTTON,
      borderColor: EColorScheme.SECONDARY,
    },
    date: {
      color: EColorScheme.SECONDARY,
      text: '',
      variant: ETextVariants.SMALL,
    },
    login: {
      headerSize: EHeaderSize.H4,
      text: '',
    },
    password: {
      color: EColorScheme.SECONDARY,
      text: '',
    },
  });
}

function getRollButtonText(
  merchantMode: EMerchantMode,
  localisation: ILocalisationApiKeys,
): string {
  return localisation.roll_password.buttons.roll_password[merchantMode];
}

function updateState(
  utils,
  state: IStateApiKeysList,
  item: IApiKeysModel,
  localisation: ILocalisationApiKeys,
) {
  state.login.text = item.login ?? '**********';
  if (item.password) {
    state.password.text = `${localisation.roll_password.password.toUpperCase()}: ****${item.password}`;
  } else {
    state.password.text = `${localisation.roll_password.password.toUpperCase()}: ********`;
  }

  let dateText = '';
  if (utils?.date?.formatSkipCurrentYearWithoutWeekDay && item.updatedAt) {
    dateText = utils.date.formatSkipCurrentYearWithoutWeekDay(item.updatedAt);
  }

  state.date.text = dateText;
}

export default initState;

export {
  IStateApiKeysList,
  getRollButtonText,
  updateState,
};
