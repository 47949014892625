enum EDSButtonVariant {
  PRIMARY ='primary',
  SECONDARY ='secondary',
  SECONDARY_NEGATIVE ='secondary-negative',
  TRETIARY ='tretiary',
  TRETIARY_GRAY ='tretiary-gray',
  TRETIARY_NEGATIVE ='tretiary-negative',
  COMMERCE ='commerce',
}

export default EDSButtonVariant;
