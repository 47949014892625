import {
  AxiosInstance,
  AxiosResponse,
} from 'axios';
import IFulfillmentTestRequestBackendModel
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/IFulfillmentTestRequestBackendModel';
import ITestRequestResponse from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/ITestRequestResponse';
import IFulfillmentTestRequestApi from '@/module/common/service/API/contract/IFulfillmentTestRequestApi';

class FulfillmentTestRequestApi implements IFulfillmentTestRequestApi {
  private readonly client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  async fulfillmentTestRequest(
    options: IFulfillmentTestRequestBackendModel,
  ): Promise<AxiosResponse<ITestRequestResponse>> {
    return this.client.post('vendor/fulfillment-test-request', options);
  }
}

export default FulfillmentTestRequestApi;
