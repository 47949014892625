export default function camelCaseToSnake(value: string): string {
  const letters = value.split('').map((letter, index) => {
    const lowerCased = letter.toLowerCase();
    if (lowerCased !== letter) {
      return index === 0 ? lowerCased : `_${lowerCased}`;
    }

    return letter;
  });

  return letters.join('');
}
