
import Vue, {
  computed,
  onMounted,
  PropType,
  reactive,
  watch,
  nextTick,
  inject,
  ref,
} from 'vue';

import IFieldStatus from '@/module/design-system/components/models/FormComponent/IFieldStatus';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import EColorScheme from '@/module/design-system/components/models/EColorScheme';

import CLabel from '@/module/common/components/UI/CLabel.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import DSTextarea from '@/module/design-system/components/UI/DSTextarea.vue';

import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IUtils from '@/plugins/utils/IUtils';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import formFieldStatusCodeMapper from '@/module/common/service/FormComponent/mappers/formFieldStatusMapper';
import EDSFieldStatus from '@/module/design-system/models/UI/EDSFieldStatus';

export default Vue.extend({
  name: 'CTextarea',
  components: {
    CLabel,
    DSTextarea,
    DSTextElement,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: 0,
    },
    minRowsCount: {
      type: Number,
      default: 5,
    },
    maxRowsCount: {
      type: Number,
      default: 5,
    },
    maxCharsInRow: {
      type: Number,
      default: 0,
    },
    description: {
      type: String,
      default: '',
    },
    status: {
      type: Object as PropType<IFieldStatus>,
      default: () => ({
        code: EValidationFieldStatusCode.DEFAULT,
      }),
    },
  },
  setup(props, { emit }) {
    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;
    const utils = serviceContainer.resolve<IUtils>(EServiceContainerInstances.UTILS);

    const state = reactive<{localValue: string; isEditing:boolean;}>({
      localValue: '',
      isEditing: false,
    });

    const scrollbar = ref();
    const textarea = ref();

    const mappedStatusCode = computed(() => formFieldStatusCodeMapper(props.status.code));
    const statusCode = computed(() => (state.isEditing ? EDSFieldStatus.DEFAULT : mappedStatusCode.value));
    const statusTextColor = computed(() => (state.isEditing ? EColorScheme.SECONDARY : mappedStatusCode.value));
    const textareaBlockClasses = computed(() => [
      'c-textarea__block',
      `c-textarea__block_${statusCode.value}`,
    ]);
    const charsLeft = computed(() => props.maxLength - props.value.length);

    function setMaxHeight() {
      if (textarea?.value?.$el) {
        const textAreaStyles = utils.style.getComputedStyle(textarea.value.$el);
        const lineHeight = utils.style.clearUnit(textAreaStyles.lineHeight);
        const paddingTop = utils.style.clearUnit(textAreaStyles.paddingTop);
        const paddingBottom = utils.style.clearUnit(textAreaStyles.paddingBottom);

        const rowsTotalHeight = lineHeight * props.maxRowsCount;
        const scrollbarMaxHeight = paddingTop + rowsTotalHeight + paddingBottom;

        if (scrollbar?.value?.$el) {
          scrollbar.value.$el.style.maxHeight = `${scrollbarMaxHeight}px`;
        }
      }
    }

    function updateHeight() {
      if (textarea?.value?.$el) {
        const textareaElement = textarea.value.$el;
        textareaElement.style.height = 'auto';
        textareaElement.style.height = `${textareaElement.scrollHeight}px`;
      }
    }

    onMounted(() => {
      state.localValue = props.value;
      setMaxHeight();
      nextTick(() => updateHeight());
    });

    watch(() => props.value, (newValue) => {
      state.localValue = newValue;
      nextTick(() => updateHeight());
    });

    function handleInput(event) {
      if (props.maxCharsInRow > 0) {
        state.localValue = event.replace(/(.{30})([^\n\r]{1,2})/g, '$1\n$2');
      } else {
        state.localValue = event;
      }

      emit('input', state.localValue);

      nextTick(() => updateHeight());
    }

    function handleKeydown(event) {
      state.isEditing = true;

      emit('keyup', event);
    }

    function handleBlur(event) {
      state.isEditing = false;

      emit('blur', event);
    }

    return {
      state,
      textarea,
      scrollbar,
      statusCode,
      statusTextColor,
      textareaBlockClasses,
      charsLeft,
      handleInput,
      handleBlur,
      handleKeydown,
    };
  },
});
