import TabException from '@/module/merchant/view/AccountActivatePage/TabException';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';
import checkCondition from '@/module/common/helpers/checkCondition';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import { Store } from 'vuex';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import EPageTab from '@/module/merchant/view/AccountActivatePage/models/EPageTab';
import IValidatorHandler from '@/module/merchant/view/AccountActivatePage/Validator/IValidatorHandler';
import IPageState from '@/module/merchant/view/AccountActivatePage/models/IPageState';
import IFormConfigHandler from '@/module/merchant/view/AccountActivatePage/Handler/contract/IFormConfigHandler';
import IFormConfig from '@/module/merchant/view/AccountActivatePage/models/IFormConfig';
import ITabHandler from '@/module/merchant/view/AccountActivatePage/Handler/contract/ITabHandler';
import ITab from '@/module/merchant/view/AccountActivatePage/models/ITab';
import ETabStatus from '@/module/merchant/view/AccountActivatePage/models/ETabStatus';

class TabHandler implements ITabHandler {
  private readonly state: IPageState;

  private readonly serviceContainer: IServiceContainer;

  private readonly simpleValidatorHandler: IValidatorHandler;

  private readonly emptyFieldValidatorHandler: IValidatorHandler;

  private readonly formConfigHandler: IFormConfigHandler;

  private readonly localisationRegistry: ILocalisationRegistry;

  constructor(
    state: IPageState,
    serviceContainer: IServiceContainer,
    simpleValidatorHandler: IValidatorHandler,
    emptyFieldValidatorHandler: IValidatorHandler,
    formConfigHandler: IFormConfigHandler,
    localisationRegistry: ILocalisationRegistry,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.simpleValidatorHandler = simpleValidatorHandler;
    this.emptyFieldValidatorHandler = emptyFieldValidatorHandler;
    this.formConfigHandler = formConfigHandler;
    this.localisationRegistry = localisationRegistry;
  }

  changeTab = (tab: ITab): void => {
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);

    if (tab.id === EPageTab.SUMMARY) {
      this.formConfigHandler.getTabListConfig()
        .filter((tabConfig) => tabConfig.id !== EPageTab.SUMMARY)
        .forEach((tabConfig) => {
          this.simpleValidatorHandler.validateTab(tabConfig, this.state.formData);
        });
    } else {
      const prevTabConfig = this.formConfigHandler.getFormConfig(this.state.activeTab);
      this.emptyFieldValidatorHandler.validateTab(prevTabConfig, this.state.formData);
    }

    this.state.activeTab = tab;

    this.state.activeTabConfig = this.formConfigHandler.getFormConfig(tab);

    store.getters.getScrollHostRef.$el.scrollTop = 0;
  };

  nextTab = (): void => {
    const index = this.state.tabs.findIndex((tab) => tab.id === this.state.activeTab.id);

    if (index === -1) {
      throw new TabException(`Active tab '${this.state.activeTab}' not found`);
    }

    if (this.state.tabs[index + 1]) {
      this.changeTab(this.state.tabs[index + 1]);
    }
  };

  goToTabFirstElement = (tabConfig: IFormConfig) => {
    let firstId: string | null = null;

    tabConfig.formConfig.forEach((formConfigItem) => {
      formConfigItem.items.forEach((field) => {
        if (!field.conditionsList || checkCondition.resolveAndCondition(field.conditionsList, this.state.formData)) {
          const { fieldBlock, fieldName } = field;
          const fieldStatusCode = this.state.formFieldStatusList[fieldBlock][fieldName].code;

          if (!firstId && fieldStatusCode === EValidationFieldStatusCode.ERROR) {
            firstId = `${fieldBlock}.${fieldName}`;
          }
        }
      });
    });

    const tab = this.state.tabs
      .find((tabItem) => tabItem.id === tabConfig.id) as ITab;
    this.changeTab(tab);

    if (firstId) {
      const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);

      setTimeout(() => {
        const element = document.getElementById(firstId as string);

        store.getters.getScrollHostRef.$el.scrollTop = element?.offsetTop;
      }, 200);
    }
  };

  isTabStatusSuccess = (tab: ITab): boolean => {
    const tabItem = this.state.tabs.find((tabItem) => tabItem.id === tab.id);

    if (!tabItem) {
      throw new TabException(`Tab '${tab.id}' not found`);
    }

    return tabItem.status === ETabStatus.SUCCESS;
  };

  isTabStatusError = (tab: ITab): boolean => {
    const tabItem = this.state.tabs.find((tabItem) => tabItem.id === tab.id);

    if (!tabItem) {
      throw new TabException(`Tab '${tab.id}' not found`);
    }

    return tabItem.status === ETabStatus.ERROR;
  };

  // eslint-disable-next-line class-methods-use-this
  isSummaryTab = (tab: ITab): boolean => tab.id === EPageTab.SUMMARY;
}

export default TabHandler;
