enum EFieldName {
  AMOUNT = 'amount',
  CURRENCY = 'currency',
  CUSTOMER_EMAIL = 'customerEmail ',
  CUSTOMER_FIRST_NAME = 'customerFirstName ',
  CUSTOMER_LAST_NAME = 'customerLastName ',
  NAME = 'name',
  PAYMENT_DESCRIPTION = 'paymentDescription ',
  PAYMENT_ID = 'paymentId',
  PAYMENT_METHOD = 'paymentMethod',
  RECURRING_INDICATOR = 'recurringIndicator',
  RETURN_SUCCESS_URL = 'returnSuccessUrl ',
}

export default EFieldName;
