import IMapperService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IMapperService';
import IFormDataToFulfillmentBackendModelMapper
  from '@/module/product/view/FulfillmentFormPage/model/contract/mapper/IFormDataToFulfillmentBackendModelMapper';
import IFulfillmentBackendModelToFormDataMapper
  from '@/module/product/view/FulfillmentFormPage/model/contract/mapper/IFulfillmentBackendModelToFormDataMapper';
import FormDataToFulfillmentBackendModelMapper
  from '@/module/product/view/FulfillmentFormPage/mapper/FormDataToFulfillmentBackendModelMapper';
import FulfillmentBackendModelToFormDataMapper
  from '@/module/product/view/FulfillmentFormPage/mapper/FulfillmentBackendModelToFormDataMapper';
import IState from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/IState';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import ILocalServiceContainer from '@/module/product/view/FulfillmentFormPage/model/ILocalServiceContainer';

class MapperService implements IMapperService {
  private readonly state: IState;

  private readonly serviceContainer: IServiceContainer;

  private readonly localServiceContainer: ILocalServiceContainer;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    localServiceContainer: ILocalServiceContainer,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.localServiceContainer = localServiceContainer;
  }

  getIFormDataToFulfillmentBackendModelMapper(): IFormDataToFulfillmentBackendModelMapper {
    return new FormDataToFulfillmentBackendModelMapper();
  }

  getIFulfillmentBackendModelToFormDataMapper(): IFulfillmentBackendModelToFormDataMapper {
    return new FulfillmentBackendModelToFormDataMapper(
      this.state,
      this.serviceContainer,
      this.localServiceContainer,
    );
  }
}

export default MapperService;
