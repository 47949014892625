import IFormHandler from '@/module/product/view/FulfillmentFormPage/model/contract/handler/IFormHandler';
import IState from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/IState';
import THandleFieldBlurParams from '@/module/product/view/FulfillmentFormPage/model/types/THandleFieldBlurParams';
import EFieldName from '@/module/product/view/FulfillmentFormPage/model/enums/EFieldName';
import ILocalServiceContainer from '@/module/product/view/FulfillmentFormPage/model/ILocalServiceContainer';
import ELocalServiceFulfillment from '@/module/product/view/FulfillmentFormPage/model/enums/ELocalServiceFulfillment';
import IScenarioService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IScenarioService';
import EFormFieldBlurScenario from '@/module/product/view/FulfillmentFormPage/model/enums/EFormFieldBlurScenario';
import IHandlerService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IHandlerService';

class FormHandler implements IFormHandler {
  private readonly state: IState;

  private readonly localServiceContainer: ILocalServiceContainer;

  constructor(
    state: IState,
    localServiceContainer: ILocalServiceContainer,
  ) {
    this.state = state;
    this.localServiceContainer = localServiceContainer;
  }

  handleFormFieldBlur = (item: THandleFieldBlurParams) => {
    const scenarioSelectService = this.localServiceContainer
      .resolve<IScenarioService>(ELocalServiceFulfillment.SCENARIO_SERVICE);
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);

    const pageValidatorHandler = handlerService.getPageValidatorHandler();
    const formDataHandler = handlerService.getFormDataHandler();
    const formConfigHandler = handlerService.getFormConfigHandler();

    let blurScenarioName = EFormFieldBlurScenario.DEFAULT_SCENARIO;
    if (item.field.fieldName === EFieldName.LICENSE_SOURCE) {
      blurScenarioName = EFormFieldBlurScenario.LICENSE_SOURCE_SCENARIO;
    } else if (item.field.fieldName === EFieldName.PARAMETERS) {
      blurScenarioName = EFormFieldBlurScenario.PARAMETERS_SCENARIO;
    }

    pageValidatorHandler.validateField(item);

    const scenario = scenarioSelectService.getBlurScenario(blurScenarioName);
    scenario.run(item);

    this.state.formData = formDataHandler.getData(this.state.licenseSourceName);
    this.state.formConfig = formConfigHandler.getConfig(this.state.licenseSourceName);
  };

  handleSaveClick = () => {
    const scenarioSelectService = this.localServiceContainer
      .resolve<IScenarioService>(ELocalServiceFulfillment.SCENARIO_SERVICE);
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);

    const pageValidatorHandler = handlerService.getPageValidatorHandler();
    pageValidatorHandler.validateForm();

    if (!this.state.isFormValid) {
      const formDataHandler = handlerService.getFormDataHandler();
      const formConfigHandler = handlerService.getFormConfigHandler();

      this.state.formData = formDataHandler.getData(this.state.licenseSourceName);
      this.state.formConfig = formConfigHandler.getConfig(this.state.licenseSourceName);

      return;
    }

    const scenarioName = scenarioSelectService.getSaveScenarioName(this.state.licenseSourceName);
    const scenario = scenarioSelectService.getSaveScenario(scenarioName);

    scenario.run();
  };

  handleTestClick = () => {
    const scenarioSelectService = this.localServiceContainer
      .resolve<IScenarioService>(ELocalServiceFulfillment.SCENARIO_SERVICE);
    const scenario = scenarioSelectService.getTestRequestScenario();

    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const pageValidatorHandler = handlerService.getPageValidatorHandler();
    pageValidatorHandler.validateFormForTestRequest();

    if (!this.state.isFormValid) {
      const formDataHandler = handlerService.getFormDataHandler();
      const formConfigHandler = handlerService.getFormConfigHandler();

      this.state.formData = formDataHandler.getData(this.state.licenseSourceName);
      this.state.formConfig = formConfigHandler.getConfig(this.state.licenseSourceName);

      return;
    }

    scenario.run();
  };
}

export default FormHandler;
