import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';

import IHandleFieldBlurParams
  from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/interface/IHandleFieldBlurParams';
import IPageValidateHandler
  from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/contract/IPageValidatorHandler';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';

import checkCondition from '@/module/common/helpers/checkCondition';

class PageValidateHandler implements IPageValidateHandler {
  private serviceContainer: IServiceContainer;

  constructor(
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
  }

  validateField = (item: IHandleFieldBlurParams): void => {
    const paymentLinkFormPage = this.serviceContainer.moduleProduct.paymentLinkFormPage;
    const handlerService = paymentLinkFormPage.validateHandlerService;

    const validateFieldHandler = handlerService.fieldValidatorHandler;
    const formConfigHandler = paymentLinkFormPage.formConfigHandler;

    let formConfig = formConfigHandler.get();

    formConfig = formConfig.map((formBlock) => {
      formBlock.items = formBlock.items.map((blockItem) => {
        if (blockItem.id === item.field.id) {
          return validateFieldHandler.validateField(blockItem, item.formData);
        }

        return blockItem;
      });

      return formBlock;
    });

    formConfigHandler.update(formConfig);
  };

  validateForm = (): void => {
    const paymentLinkFormPage = this.serviceContainer.moduleProduct.paymentLinkFormPage;

    const validateFormHandler = paymentLinkFormPage.validateHandlerService.formValidatorHandler;
    const formConfigHandler = paymentLinkFormPage.formConfigHandler;
    const formDataHandler = paymentLinkFormPage.formDataHandler;

    const state = paymentLinkFormPage.stateHandler.get();
    const formData = formDataHandler.get();
    let formConfig = formConfigHandler.get();

    let isValidForm = true;

    formConfig = formConfig.map((formBlock) => {
      formBlock.items = formBlock.items.map((item) => {
        if (item.conditionsList && !checkCondition.resolveAndCondition(item.conditionsList, formData)) {
          return item;
        }

        const mappedFormFieldItem = validateFormHandler.validateField(item, formData);

        if (mappedFormFieldItem.status?.code === EValidationFieldStatusCode.ERROR) {
          isValidForm = false;
        }

        return mappedFormFieldItem;
      });

      return formBlock;
    });

    formConfigHandler.update(formConfig);

    state.isFormValid = isValidForm;
  };
}

export default PageValidateHandler;
