import EFieldName from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/enum/EFieldName';
import EFormFieldType from '@/module/design-system/components/models/FormComponent/EFormFieldType';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';

import IFormConfigBlock from '@/module/design-system/components/models/FormComponent/IFormConfigBlock';
import IFormConfigHandler
  from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/contract/IFormConfigHandler';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';

import TFormConfig from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/type/TFormConfig';

class FormConfigHandler implements IFormConfigHandler {
  private serviceContainer: IServiceContainer;

  private _formConfig?: TFormConfig;

  constructor(
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
  }

  get = (): IFormConfigBlock<EFieldName, undefined>[] => {
    if (!this._formConfig) {
      const localisationRegistry = this.serviceContainer.localisationRegistry;
      const localisation = localisationRegistry.paymentLinkForm;

      const state = this.serviceContainer.moduleProduct.paymentLinkFormPage.stateHandler.get();

      this._formConfig = [
        {
          items: [
            {
              id: 1,
              type: EFormFieldType.INPUT,
              fieldName: EFieldName.NAME,
              fieldBlock: undefined,
              label: localisation.form.name.title,
              description: {
                default: localisation.form.name.description,
              },
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
          ],
        },
        {
          header: localisation.form.orderDataHeader.title,
          items: [
            {
              id: 3,
              type: EFormFieldType.INPUT,
              fieldName: EFieldName.PAYMENT_DESCRIPTION,
              fieldBlock: undefined,
              label: localisation.form.paymentDescription.title,
              description: {
                default: localisation.form.paymentDescription.description,
              },
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            {
              id: 4,
              type: EFormFieldType.MASKED_INPUT,
              fieldName: EFieldName.AMOUNT,
              fieldBlock: undefined,
              label: localisation.form.amount.title,
              maskOptions: {
                mask: 'NumberConstructor',
                min: 0.01,
                scale: 2,
              },
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            {
              id: 5,
              type: EFormFieldType.SELECT,
              fieldName: EFieldName.CURRENCY,
              fieldBlock: undefined,
              label: localisation.form.currency.title,
              select: {
                idField: 'id',
                titleField: 'title',
                list: state.currencyList,
              },
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            {
              id: 6,
              type: EFormFieldType.SELECT,
              fieldName: EFieldName.PAYMENT_METHOD,
              fieldBlock: undefined,
              label: localisation.form.paymentMethod.title,
              placeholder: localisation.form.paymentMethod.placeholder,
              select: {
                idField: 'value',
                titleField: 'title',
                list: state.paymentMethodList,
              },
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            {
              id: 7,
              type: EFormFieldType.INPUT,
              fieldName: EFieldName.RETURN_SUCCESS_URL,
              fieldBlock: undefined,
              label: localisation.form.returnSuccessUrl.title,
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            {
              id: 2,
              type: EFormFieldType.MASKED_INPUT,
              fieldName: EFieldName.PAYMENT_ID,
              fieldBlock: undefined,
              label: localisation.form.paymentId.title,
              maskOptions: {
                mask: /^[\w-]+$/,
              },
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
          ],
        },
        {
          header: localisation.form.customerDataHeader.title,
          items: [
            {
              id: 8,
              type: EFormFieldType.INPUT,
              fieldName: EFieldName.CUSTOMER_FIRST_NAME,
              fieldBlock: undefined,
              label: localisation.form.customerFirstName.title,
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            {
              id: 9,
              type: EFormFieldType.INPUT,
              fieldName: EFieldName.CUSTOMER_LAST_NAME,
              fieldBlock: undefined,
              label: localisation.form.customerLastName.title,
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            {
              id: 10,
              type: EFormFieldType.INPUT,
              fieldName: EFieldName.CUSTOMER_EMAIL,
              fieldBlock: undefined,
              label: localisation.form.customerEmail.title,
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
          ],
        },
      ];
    }

    return this._formConfig;
  };

  update = (
    formConfig: TFormConfig,
  ): TFormConfig => {
    const utilsService = this.serviceContainer.utilsService;
    const paymentLinkFormPage = this.serviceContainer.moduleProduct.paymentLinkFormPage;
    const state = paymentLinkFormPage.stateHandler.get();

    state.formConfig = utilsService.data.cloneImmutable(formConfig);

    return state.formConfig;
  };
}

export default FormConfigHandler;
