import IProductMapperService from '@/module/product/service/contract/IProductMapperService';
import TProductBackend from '@/module/product/model/Api/Product/TProduct';
import TProductFrontend from '@/module/product/model/Product/TProduct';
import IAvailableForOption from '@/module/product/model/Product/Fields/AvailableFor/IAvailableForOption';
import ILicenseTypeOption from '@/module/product/model/Product/Fields/LicenseType/ILicenseTypeOption';
import TPricingModelOption from '@/module/product/model/Product/Fields/PricingModel/Option/TPricingModelOption';
import EPricingModelName from '@/module/product/model/Product/Fields/PricingModel/EPricingModelName';
import IOnePriceProductBackend from '@/module/product/model/Api/Product/IOnePriceProduct';
import IOnePriceForEachCurrencyProductBackend
  from '@/module/product/model/Api/Product/IOnePriceForEachCurrencyProduct';
import IVolumePriceProductBackend from '@/module/product/model/Api/Product/IVolumePriceProduct';
import IVolumePriceAndOptionsProductBackend
  from '@/module/product/model/Api/Product/IVolumePriceAndOptionsProduct';
import IOnePriceProductFrontend from '@/module/product/model/Product/IOnePriceProduct';
import IOnePriceForEachCurrencyProductFrontend
  from '@/module/product/model/Product/IOnePriceForEachCurrencyProduct';
import IVolumePriceProductFrontend from '@/module/product/model/Product/IVolumePriceProduct';
import IVolumePriceAndOptionsProductFrontend
  from '@/module/product/model/Product/IVolumePriceAndOptionsProduct';
import IProductTypeOptionCheckHelper
  from '@/module/product/helper/ProductTypeOptionCheckHelper/IProductTypeOptionCheckHelper';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';

class ProductMapperService implements IProductMapperService {
  private readonly serviceContainer: IServiceContainer;

  private readonly productTypeOptionCheckHelper: IProductTypeOptionCheckHelper;

  constructor(
    serviceContainer: IServiceContainer,
    productTypeOptionCheckHelper: IProductTypeOptionCheckHelper,
  ) {
    this.serviceContainer = serviceContainer;
    this.productTypeOptionCheckHelper = productTypeOptionCheckHelper;
  }

  private isBackendModelOnePriceProduct = (
    product: TProductBackend,
  ): product is IOnePriceProductBackend => product.pricingModel === EPricingModelName.ONE_PRICE;

  // @TODO придумать как длину строк поправить
  /* eslint-disable vue/max-len, max-len */
  private isBackendModelOnePriceForEachCurrencyProduct = (
    product: TProductBackend,
  ): product is IOnePriceForEachCurrencyProductBackend => product.pricingModel === EPricingModelName.ONE_PRICE_FOR_EACH_CURRENCY;

  private isBackendModelVolumePriceProduct = (
    product: TProductBackend,
  ): product is IVolumePriceProductBackend => product.pricingModel === EPricingModelName.VOLUME_PRICING;

  private isBackendModelVolumePriceAndOptionsProduct = (
    product: TProductBackend,
  ): product is IVolumePriceAndOptionsProductBackend => product.pricingModel === EPricingModelName.VOLUME_PRICING_AND_OPTIONS;

  private getAvailableFor = (product: TProductBackend): IAvailableForOption => {
    const moduleProduct = this.serviceContainer.moduleProduct;

    return moduleProduct.availableForService.get(product.availableFor);
  };

  private getLicenseType = (product: TProductBackend): ILicenseTypeOption => {
    const moduleProduct = this.serviceContainer.moduleProduct;

    return moduleProduct.licenseTypeService.get(product.licenseType);
  };

  private getPricingModel = (product: TProductBackend): TPricingModelOption => {
    const moduleProduct = this.serviceContainer.moduleProduct;

    return moduleProduct.pricingModelService.get(product.pricingModel);
  };

  private mapOnePriceBackendModel = (
    product: IOnePriceProductBackend,
  ): IOnePriceProductFrontend => {
    const availableFor = this.getAvailableFor(product);
    const licenseType = this.getLicenseType(product);
    const pricingModel = this.getPricingModel(product);

    if (!this.productTypeOptionCheckHelper.isOnePriceOption(pricingModel)) {
      throw new Error(`Price type '${pricingModel.id}' is not assignable to '${product.pricingModel}'`);
    }

    return {
      ...product,
      description: product.description ?? '',
      renewal: !!product.renewalTitle || !!product.renewalTerm || !!product.renewalSubtitle,
      trial: !!product.trialTitle || !!product.trialTerm || !!product.trialSubtitle,
      availableFor,
      licenseType,
      pricingModel,
    };
  };

  private mapOnePriceForEachCurrencyProductBackendModel = (
    product: IOnePriceForEachCurrencyProductBackend,
  ): IOnePriceForEachCurrencyProductFrontend => {
    const availableFor = this.getAvailableFor(product);
    const licenseType = this.getLicenseType(product);
    const pricingModel = this.getPricingModel(product);

    if (!this.productTypeOptionCheckHelper.isOnePriceEachCurrencyPricingModelOption(pricingModel)) {
      throw new Error(`Price type '${pricingModel.id}' is not assignable to '${product.pricingModel}'`);
    }

    return {
      ...product,
      description: product.description ?? '',
      renewal: !!product.renewalTitle || !!product.renewalTerm || !!product.renewalSubtitle,
      trial: !!product.trialTitle || !!product.trialTerm || !!product.trialSubtitle,
      availableFor,
      licenseType,
      pricingModel,
    };
  };

  private mapVolumePriceProductBackendModel = (
    product: IVolumePriceProductBackend,
  ): IVolumePriceProductFrontend => {
    const availableFor = this.getAvailableFor(product);
    const licenseType = this.getLicenseType(product);
    const pricingModel = this.getPricingModel(product);

    if (!this.productTypeOptionCheckHelper.isVolumePricePricingModelOption(pricingModel)) {
      throw new Error(`Price type '${pricingModel.id}' is not assignable to '${product.pricingModel}'`);
    }

    return {
      ...product,
      description: product.description ?? '',
      renewal: !!product.renewalTitle || !!product.renewalTerm || !!product.renewalSubtitle,
      trial: !!product.trialTitle || !!product.trialTerm || !!product.trialSubtitle,
      availableFor,
      licenseType,
      pricingModel,
    };
  };

  private mapVolumePriceAndOptionsProductBackendModel = (
    product: IVolumePriceAndOptionsProductBackend,
  ): IVolumePriceAndOptionsProductFrontend => {
    const availableFor = this.getAvailableFor(product);
    const licenseType = this.getLicenseType(product);
    const pricingModel = this.getPricingModel(product);

    if (!this.productTypeOptionCheckHelper.isVolumePriceAndOptionsPricingModelOption(pricingModel)) {
      throw new Error(`Price type '${pricingModel.id}' is not assignable to '${product.pricingModel}'`);
    }

    return {
      ...product,
      description: product.description ?? '',
      renewal: !!product.renewalTitle || !!product.renewalTerm || !!product.renewalSubtitle,
      trial: !!product.trialTitle || !!product.trialTerm || !!product.trialSubtitle,
      availableFor,
      licenseType,
      pricingModel,
    };
  };

  mapProductFormBackendToFrontendModel(product: TProductBackend): TProductFrontend {
    let mappedProduct = {} as TProductFrontend;

    if (this.isBackendModelOnePriceProduct(product)) {
      mappedProduct = this.mapOnePriceBackendModel(product);
    }
    if (this.isBackendModelOnePriceForEachCurrencyProduct(product)) {
      mappedProduct = this.mapOnePriceForEachCurrencyProductBackendModel(product);
    }
    if (this.isBackendModelVolumePriceProduct(product)) {
      mappedProduct = this.mapVolumePriceProductBackendModel(product);
    }
    if (this.isBackendModelVolumePriceAndOptionsProduct(product)) {
      mappedProduct = this.mapVolumePriceAndOptionsProductBackendModel(product);
    }

    return mappedProduct;
  }

  private isFrontendModelOnePriceProduct = (
    product: TProductFrontend,
  ): product is IOnePriceProductFrontend => product.pricingModel.id === EPricingModelName.ONE_PRICE;

  // @TODO придумать как длину строк поправить
  /* eslint-disable vue/max-len, max-len */
  private isFrontendModelOnePriceForEachCurrencyProduct = (
    product: TProductFrontend,
  ): product is IOnePriceForEachCurrencyProductFrontend => product.pricingModel.id === EPricingModelName.ONE_PRICE_FOR_EACH_CURRENCY;

  private isFrontendModelVolumePriceProduct = (
    product: TProductFrontend,
  ): product is IVolumePriceProductFrontend => product.pricingModel.id === EPricingModelName.VOLUME_PRICING;

  private isFrontendModelVolumePriceAndOptionsProduct = (
    product: TProductFrontend,
  ): product is IVolumePriceAndOptionsProductFrontend => product.pricingModel.id === EPricingModelName.VOLUME_PRICING_AND_OPTIONS;

  private mapOnePriceFrontendModel = (
    product: IOnePriceProductFrontend,
  ): IOnePriceProductBackend => {
    const mappedProduct = {
      id: product.id ?? null,
      licenseType: product.licenseType.id,
      title: product.title,
      subtitle: product.subtitle,
      availableFor: product.availableFor.id,
      licenseTerm: product.licenseTerm,
      price: product.price,
      trialCheckbox: product.trial,
      renewalCheckbox: product.renewal,
      softwareRegistry: product.softwareRegistry,
      fulfillmentId: product.fulfillmentId,
    } as IOnePriceProductBackend;

    if ((product.imageUrl ?? '').length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mappedProduct.imageUrl = product.imageUrl;
    }

    if (product.description?.length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mappedProduct.description = product.description;
    }

    if (product.trial) {
      if ((product.trialTitle ?? '').length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedProduct.trialTitle = product.trialTitle;
      }

      if ((product.trialSubtitle ?? '').length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedProduct.trialSubtitle = product.trialSubtitle;
      }

      if ((product.trialSku ?? '').length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedProduct.trialSku = product.trialSku;
      }

      mappedProduct.trialTerm = product.trialTerm;
    }

    if (product.renewal) {
      if ((product.renewalTitle ?? '').length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedProduct.renewalTitle = product.renewalTitle;
      }

      if ((product.renewalSubtitle ?? '').length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedProduct.renewalSubtitle = product.renewalSubtitle;
      }

      mappedProduct.renewalPrice = product.renewalPrice;
      mappedProduct.renewalTerm = product.renewalTerm;
    }

    return mappedProduct;
  };

  private mapOnePriceForEachCurrencyProductFrontendModel = (
    product: IOnePriceForEachCurrencyProductFrontend,
  ): IOnePriceForEachCurrencyProductBackend => {
    const mappedProduct = {
      id: product.id ?? null,
      licenseType: product.licenseType.id,
      title: product.title,
      subtitle: product.subtitle,
      availableFor: product.availableFor.id,
      licenseTerm: product.licenseTerm,
      price: product.price,
      trialCheckbox: product.trial,
      renewalCheckbox: product.renewal,
      softwareRegistry: product.softwareRegistry,
      fulfillmentId: product.fulfillmentId,
    } as IOnePriceForEachCurrencyProductBackend;

    if ((product.imageUrl ?? '').length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mappedProduct.imageUrl = product.imageUrl;
    }

    if (product.description?.length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mappedProduct.description = product.description;
    }

    if (product.trial) {
      if ((product.trialTitle ?? '').length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedProduct.trialTitle = product.trialTitle;
      }

      if ((product.trialSubtitle ?? '').length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedProduct.trialSubtitle = product.trialSubtitle;
      }

      if ((product.trialSku ?? '').length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedProduct.trialSku = product.trialSku;
      }

      mappedProduct.trialTerm = product.trialTerm;
    }

    if (product.renewal) {
      if ((product.renewalTitle ?? '').length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedProduct.renewalTitle = product.renewalTitle;
      }

      if ((product.renewalSubtitle ?? '').length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedProduct.renewalSubtitle = product.renewalSubtitle;
      }

      mappedProduct.renewalPrice = product.renewalPrice;
      mappedProduct.renewalTerm = product.renewalTerm;
    }

    return mappedProduct;
  };

  private mapVolumePriceProductFrontendModel = (
    product: IVolumePriceProductFrontend,
  ): IVolumePriceProductBackend => {
    const mappedProduct = {
      id: product.id ?? null,
      licenseType: product.licenseType.id,
      title: product.title,
      subtitle: product.subtitle,
      availableFor: product.availableFor.id,
      licenseTerm: product.licenseTerm,
      price: product.price,
      trialCheckbox: product.trial,
      renewalCheckbox: product.renewal,
      softwareRegistry: product.softwareRegistry,
      fulfillmentId: product.fulfillmentId,
    } as IVolumePriceProductBackend;

    if ((product.imageUrl ?? '').length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mappedProduct.imageUrl = product.imageUrl;
    }

    if (product.description?.length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mappedProduct.description = product.description;
    }

    if (product.trial) {
      if ((product.trialTitle ?? '').length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedProduct.trialTitle = product.trialTitle;
      }

      if ((product.trialSubtitle ?? '').length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedProduct.trialSubtitle = product.trialSubtitle;
      }

      if ((product.trialSku ?? '').length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedProduct.trialSku = product.trialSku;
      }

      mappedProduct.trialTerm = product.trialTerm;
    }

    if (product.renewal) {
      if ((product.renewalTitle ?? '').length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedProduct.renewalTitle = product.renewalTitle;
      }

      if ((product.renewalSubtitle ?? '').length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedProduct.renewalSubtitle = product.renewalSubtitle;
      }

      mappedProduct.renewalPrice = product.renewalPrice;
      mappedProduct.renewalTerm = product.renewalTerm;
    }

    return mappedProduct;
  };

  private mapVolumePriceAndOptionsProductFrontendModel = (
    product: IVolumePriceAndOptionsProductFrontend,
  ): IVolumePriceAndOptionsProductBackend => {
    const mappedProduct = {
      id: product.id ?? null,
      licenseType: product.licenseType.id,
      title: product.title,
      subtitle: product.subtitle,
      availableFor: product.availableFor.id,
      licenseTerm: product.licenseTerm,
      price: product.price,
      trialCheckbox: product.trial,
      renewalCheckbox: product.renewal,
      softwareRegistry: product.softwareRegistry,
      fulfillmentId: product.fulfillmentId,
    } as IVolumePriceAndOptionsProductBackend;

    if ((product.imageUrl ?? '').length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mappedProduct.imageUrl = product.imageUrl;
    }

    if (product.description?.length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mappedProduct.description = product.description;
    }

    if (product.trial) {
      if ((product.trialTitle ?? '').length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedProduct.trialTitle = product.trialTitle;
      }

      if ((product.trialSubtitle ?? '').length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedProduct.trialSubtitle = product.trialSubtitle;
      }

      if ((product.trialSku ?? '').length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedProduct.trialSku = product.trialSku;
      }

      mappedProduct.trialTerm = product.trialTerm;
    }

    if (product.renewal) {
      if ((product.renewalTitle ?? '').length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedProduct.renewalTitle = product.renewalTitle;
      }

      if ((product.renewalSubtitle ?? '').length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mappedProduct.renewalSubtitle = product.renewalSubtitle;
      }

      mappedProduct.renewalPrice = product.renewalPrice;
      mappedProduct.renewalTerm = product.renewalTerm;
    }

    return mappedProduct;
  };

  mapProductFormFrontendToBackendModel(product: TProductFrontend): TProductBackend {
    let mappedProduct = {} as TProductBackend;

    if (this.isFrontendModelOnePriceProduct(product)) {
      mappedProduct = this.mapOnePriceFrontendModel(product);
    }
    if (this.isFrontendModelOnePriceForEachCurrencyProduct(product)) {
      mappedProduct = this.mapOnePriceForEachCurrencyProductFrontendModel(product);
    }
    if (this.isFrontendModelVolumePriceProduct(product)) {
      mappedProduct = this.mapVolumePriceProductFrontendModel(product);
    }
    if (this.isFrontendModelVolumePriceAndOptionsProduct(product)) {
      mappedProduct = this.mapVolumePriceAndOptionsProductFrontendModel(product);
    }

    return mappedProduct;
  }
}

export default ProductMapperService;
