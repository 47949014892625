import IPortalModeService from '@/module/common/service/PortalMode/IPortalModeService';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EPortalMode from '@/module/common/service/PortalMode/EPortalMode';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import { Store } from 'vuex';
import EMerchantMode from '@/module/common/models/EMerchantMode';
import IProductFormPageHandler from '@/module/common/service/PortalMode/PageHandler/IProductFormPageHandler';
import ProductFormPageHandler from '@/module/common/service/PortalMode/PageHandler/ProductFormPageHandler';
import PortalModeException from '@/module/common/service/PortalMode/PortalModeException';
import ESalesArea from '@/module/common/models/ESalesArea';

class PortalModeService implements IPortalModeService {
  private readonly serviceContainer: IServiceContainer;

  private productFormPageHandler?: IProductFormPageHandler;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  getPortalMode = (): EPortalMode => {
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const merchantMode: EMerchantMode = store.getters['authStore/getMerchantMode'];

    if (merchantMode === EMerchantMode.TEST) {
      return EPortalMode.ALL;
    }

    const salesArea: ESalesArea = store.getters['authStore/getSalesArea'];
    const merchantCountry = store.getters.getCountry;
    const merchantCountryKey = merchantCountry === 'RU' ? 'russia' : 'global';

    const map = {
      global: {
        global: EPortalMode.GLOBAL_TO_GLOBAL,
        russia: EPortalMode.GLOBAL_TO_RUSSIA,
      },
      russia: {
        global: EPortalMode.RUSSIA_TO_GLOBAL,
        russia: EPortalMode.RUSSIA_TO_RUSSIA,
      },
    };

    if (!map[merchantCountryKey][salesArea]) {
      throw new PortalModeException(`Preset for country '${merchantCountry}' & sales area '${salesArea}' not exist.`);
    }

    return map[merchantCountryKey][salesArea];
  };

  getProductFormPageHandler = (): IProductFormPageHandler => {
    if (!this.productFormPageHandler) {
      this.productFormPageHandler = new ProductFormPageHandler(this.serviceContainer, this);
    }

    return this.productFormPageHandler;
  };
}

export default PortalModeService;
