enum EGridTitleKey {
  NAME = 'name',
  PAYMENT_LINK = 'payment-link',
  CREATED_AT = 'created-at',
  AMOUNT = 'amount',
  STATUS = 'status',
  CONTROL = 'control',
  COMPUTED_INFO = 'computed-info'
}

export default EGridTitleKey;
