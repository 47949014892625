import THandleFieldBlurParams from '@/module/product/view/FulfillmentFormPage/model/types/THandleFieldBlurParams';
import IFormDataTestCreateGenerator
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataTestCreateGenerator';
import IFormDataSalesCreateGenerator
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataSalesCreateGenerator';
import ICInputWithSelectListValue from '@/module/common/models/UI/CInputWithSelectList/ICInputWithSelectListValue';
import IFulfillmentParametersBackendModel
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/IFulfillmentParametersResponseModel';
import AbstractBlurFieldScenario
  from '@/module/product/view/FulfillmentFormPage/scenario/formFieldBlur/AbstractBlurFieldScenario';
import IHandlerService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IHandlerService';
import ELocalServiceFulfillment from '@/module/product/view/FulfillmentFormPage/model/enums/ELocalServiceFulfillment';

class ParametersFieldBlurScenario extends AbstractBlurFieldScenario {
  run = (item: THandleFieldBlurParams): void => {
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);

    const formDataHandler = handlerService.getFormDataHandler();
    const licenseSourceHandler = handlerService.getLicenseSourceHandler();
    const paramsValidateHandler = handlerService.getParamsValidateHandler();
    const emptyFieldStatusHandler = handlerService.getEmptyFieldStatusHandler();

    const licenseSourceName = licenseSourceHandler.getLicenseSourceName(item.formData);
    let formData = formDataHandler
      .getData(licenseSourceName) as IFormDataTestCreateGenerator | IFormDataSalesCreateGenerator;
    const prevFormData = formDataHandler
      .getData(licenseSourceName) as IFormDataTestCreateGenerator | IFormDataSalesCreateGenerator;

    const paramsMap = formData.parameters.reduce((map, param, index) => {
      const key = param.id ?? `id${index + 1}`;
      map[key] = param;

      return map;
    }, {});

    formData.parameters = (item.value as ICInputWithSelectListValue[]).map((val) => {
      if (val.value.inputValue) {
        return val;
      }

      const selectValue = val.value.selectValue as IFulfillmentParametersBackendModel;

      if (!paramsMap[`${val.id}`]) {
        val.value.inputValue = selectValue.default;

        return val;
      }

      const paramsMapSelectValue = paramsMap[`${val.id}`].value.selectValue;
      const paramsMapInputValue = paramsMap[`${val.id}`].value.inputValue;

      if (paramsMapSelectValue.key !== selectValue.key && paramsMapInputValue === val.value.inputValue) {
        val.value.inputValue = selectValue.default;

        return val;
      }

      return val;
    });

    formData = paramsValidateHandler.validate(
      formData as IFormDataTestCreateGenerator | IFormDataSalesCreateGenerator,
      emptyFieldStatusHandler,
      prevFormData,
    );

    formDataHandler.store(licenseSourceName, formData);
  };
}

export default ParametersFieldBlurScenario;
