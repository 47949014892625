import extractFileExtension from '@/module/common/utils/file/extractFileExtension';
import extractFileName from '@/module/common/utils/file/extractFileName';
import changeFileName from '@/module/common/utils/file/changeFileName';

function fileExtensionToLowerCase(file: File): File {
  const name = extractFileName(file.name);
  const extension = extractFileExtension(file.name);

  return changeFileName(file, `${name}.${extension.toLowerCase()}`);
}

export default fileExtensionToLowerCase;
