import {
  AxiosResponse,
  AxiosInstance,
} from 'axios';

import ICreateRequestData
  from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/interface/ICreateRequestData';
import IPaymentLinkApi from '@/module/product/api/contract/IPaymentLinkApi';
import IPaymentLinkBackendModel from '@/module/product/view/PaymentLink/model/interface/IPaymentLinkBackendModel';
import IListRequestOptions from '@/module/product/view/PaymentLink/PaymentLinkPage/model/interface/IListRequestOptions';
import IListResponse from '@/module/product/view/PaymentLink/PaymentLinkPage/model/interface/IListResponse';

class PaymentLinkApi implements IPaymentLinkApi {
  private readonly client: AxiosInstance;

  constructor(
    client: AxiosInstance,
  ) {
    this.client = client;
  }

  async create(data: ICreateRequestData): Promise<AxiosResponse<{ result: string }>> {
    return this.client.post('/vendor/payment-link', data);
  }

  async get(id: number): Promise<AxiosResponse<IPaymentLinkBackendModel>> {
    return this.client.get(`/vendor/payment-link/${id}`);
  }

  async list(options: IListRequestOptions): Promise<AxiosResponse<IListResponse>> {
    const sortByCreateDate = options.sortByCreateDate ?? 'desc';
    const parts = [
      `limit=${options.limit}`,
      `offset=${options.offset}`,
      `sortByCreateDate=${sortByCreateDate}`,
    ];

    if (options.searchString) {
      parts.push(`searchString=${encodeURIComponent(options.searchString)}`);
    }

    const query = parts.filter((part) => !!part).join('&');

    return this.client.get(`/vendor/payment-link?${query}`);
  }

  async delete(id: number): Promise<AxiosResponse<{ result: string }>> {
    return this.client.delete(`/vendor/payment-link/${id}`);
  }
}

export default PaymentLinkApi;
