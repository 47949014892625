<template>
  <COverviewPageLayout
    :is-loading="isLoading"
    :error-status="404"
    :show-error="isError"
  >
    <template
      #header
    >
      <header class="promotion-card__header">
        <DSButton
          :text="$t('promotions.promotion_card.back')"
          class="promotion-card__header-button"
          variant="tretiary"
          @click="$router.back()"
        >
          <template
            #icon-before
          >
            <DSIcon
              icon="tailArrowLeft"
              color="linked"
            />
          </template>
        </DSButton>
        <DSButton
          :text="$t('promotions.promotion_card.edit')"
          class="promotion-card__header-button"
          variant="tretiary"
          @click="$router.push(`/promotions/edit/${promotion.promotionId}`).catch(() => null)"
        >
          <template
            #icon-before
          >
            <DSIcon
              icon="edit"
              color="linked"
            />
          </template>
        </DSButton>
        <ButtonMore
          class="promotion-card__header-button promotion-card__header-button--more"
          :buttons="changeStatusButtons"
        />
      </header>
    </template>

    <template
      #content
    >
      <ModalComponent
        v-if="showModal"
        class="promotion-card-modal"
        @closeModal="showModal = false"
        @submitModal="handleChangeStatusPromotion('delete')"
      >
        <div class="promotion-card-modal__title">
          <DSHeader
            size="3"
            :text="$t('promotions.promotion_card.modal.delete.title')"
          />
          <ButtonComponent
            variant="tretiary-negative"
            :icon-button="true"
            :small="true"
            @click="showModal = false"
          >
            <DSIcon
              icon="cross"
              color="primary"
            />
          </ButtonComponent>
        </div>
        <div class="promotion-card-modal__description">
          <p class="promotion-card-modal__name">
            {{ promotion.promotionName }}
          </p>
          <p class="promotion-card-modal__date">
            {{ transformDate(promotion.promotionStartDate) }} - {{ transformDate(promotion.promotionEndDate) }} •
            {{ promotion.promotionStatistic.promotionUses }}
          </p>
        </div>
        <button-group
          class="promotion-card-modal__button-group"
          variant="spaced"
        >
          <ButtonComponent @click="showModal = false">
            {{ $t('promotions.promotion_card.modal.delete.keep') }}
          </ButtonComponent>
          <ButtonComponent
            variant="secondary-negative"
            @click="handleChangeStatusPromotion('delete')"
          >
            {{ $t('promotions.promotion_card.modal.delete.delete') }}
          </ButtonComponent>
        </button-group>
      </ModalComponent>

      <section class="promotion-card__section promotion-card__section--underlined">
        <PromoTags :list="tagList" />
        <DSHeader
          class="promotion-card__title"
          size="2"
          :text="promotion.promotionName"
        />
      </section>

      <section class="promotion-card__section">
        <DSHeader
          class="promotion-card__section-title"
          size="3"
          :text="$t('promotions.promotion_card.details.title')"
        />
        <div class="promotion-card__row">
          <p class="secondary">
            {{ $t('promotions.promotion_card.details.status') }}
          </p>
          <p>{{ $t(`promotions.promotion_card.details.statuses.${promotion.promotionStatus}`) }}</p>
        </div>
        <div class="promotion-card__row">
          <p class="secondary">
            {{ $t('promotions.promotion_card.details.start_date') }}
          </p>
          <p>{{ transformDate(promotion.promotionStartDate) }}</p>
        </div>
        <div
          v-if="promotion.promotionEndDate"
          class="promotion-card__row"
        >
          <p class="secondary">
            {{ $t('promotions.promotion_card.details.end_date') }}
          </p>
          <p>{{ transformEndDate(promotion.promotionEndDate) }}</p>
        </div>
        <div class="promotion-card__row">
          <p class="secondary">
            {{ $t('promotions.promotion_card.details.discount_model') }}
          </p>
          <div class="promotion-card__tooltiped">
            <!-- eslint-disable vue/max-len max-len -->
            <p>
              {{
                $t(`promotions.promotion_card.details.discount_models.${promotion.promotionDiscountModel.modelName}.title`)
              }}
            </p>
            <DSTooltip
              class="promotion-card__tooltip"
              :text="$t(`promotions.promotion_card.details.discount_models.${promotion.promotionDiscountModel.modelName}.tooltip`)"
            >
              <template
                #tooltip-icon
              >
                <div
                  class="promotion-card-tooltip__icon"
                >
                  <DSIcon
                    icon="info"
                    color="disabled"
                    size="s"
                  />
                </div>
              </template>
            </DSTooltip>
            <!-- eslint-enable vue/max-len max-len -->
          </div>
        </div>
      </section>

      <section
        v-if="promotion.promotionType === 'coupon'"
        class="promotion-card__section"
      >
        <DSHeader
          class="promotion-card__section-title"
          size="3"
          :text="$t('promotions.promotion_card.coupons.title')"
        />
        <InlineAlert
          v-if="activeCouponsNumber < 1"
          class="inline-alert"
          variant="alert"
          :title="$t('promotions.promotion_card.coupons.have_no_coupons')"
        />
        <div class="promotion-card__row">
          <p class="secondary">
            {{ $t('promotions.promotion_card.coupons.type') }}
          </p>
          <div
            class="promotion-card__tooltiped"
          >
            <p class="primary">
              {{ $t(`promotions.promotion_card.coupons.coupons_type.${couponsType}.title`) }}
            </p>
            <DSTooltip
              class="promotion-card__tooltip"
              :text="$t(`promotions.promotion_card.coupons.coupons_type.${couponsType}.tooltip`)"
            >
              <template
                #tooltip-icon
              >
                <div
                  class="promotion-card-tooltip__icon"
                >
                  <DSIcon
                    icon="info"
                    color="disabled"
                    size="s"
                  />
                </div>
              </template>
            </DSTooltip>
          </div>
        </div>
        <div class="promotion-card__row">
          <p class="secondary">
            {{ $t('promotions.promotion_card.coupons.all') }}
          </p>
          <p class="primary">
            {{ allCouponsNumber }}
          </p>
        </div>
        <div class="promotion-card__row">
          <p class="secondary">
            {{ $t('promotions.promotion_card.coupons.active') }}
          </p>
          <p class="primary">
            {{ activeCouponsNumber }}
          </p>
        </div>
        <div class="promotion-card__row">
          <p class="secondary">
            {{ $t('promotions.promotion_card.coupons.uses.title') }}
          </p>
          <div
            class="promotion-card__tooltiped"
          >
            <p class="primary">
              {{ promotion.promotionStatistic.promotionUses }}
            </p>
            <DSTooltip
              class="promotion-card__tooltip"
              :text="usesTooltip"
            >
              <template
                #tooltip-icon
              >
                <div
                  class="promotion-card-tooltip__icon"
                >
                  <DSIcon
                    icon="info"
                    color="disabled"
                    size="s"
                  />
                </div>
              </template>
            </DSTooltip>
          </div>
        </div>
        <ul
          v-if="allCouponsNumber < 5"
          class="promotion-card__coupons"
        >
          <li
            v-for="coupon in promotion.promotionStatistic.coupons"
            :key="coupon.couponId"
            class="promotion-card__coupon"
          >
            <CouponComponent
              :text="coupon.couponCode"
              :active="coupon.couponType === 'reusable' || coupon.couponUses === 0"
            />
          </li>
        </ul>
        <ButtonComponent
          v-else
          class="promotion-card__download-button"
          variant="secondary"
          @click="saveAllCoupons"
        >
          <template #icon-before>
            <DSIcon
              icon="download"
              color="linked"
            />
          </template>
          {{ $t('promotions.promotion_card.coupons.download_button') }}
        </ButtonComponent>
      </section>

      <section
        class="promotion-card__section"
      >
        <div
          class="promotion-card__section-header"
        >
          <DSHeader
            class="promotion-card__section-title"
            size="3"
            :text="headerText"
          />
          <DSTooltip
            v-if="discountModel !== 'All Catalog'"
            class="promotion-card__section-tooltip"
            :text="$t('promotions.promotion_card.discount.tooltip')"
          >
            <template
              #tooltip-icon
            >
              <div
                class="promotion-card-tooltip__icon_big"
              >
                <DSIcon
                  icon="info"
                  color="disabled"
                  size="s"
                />
              </div>
            </template>
          </DSTooltip>
        </div>
        <div
          v-if="discountModel !== 'All Catalog'"
          class="promotion-card__products"
        >
          <PromotionProduct
            v-for="(product, index) in promotion.promotionDiscountModel.products"
            :key="product.id + index"
            :product="product"
            :locale="locale"
            :total-percent="+promotion.promotionDiscountModel.totalDiscountPercent"
          />
        </div>
      </section>
    </template>
  </COverviewPageLayout>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';

import {
  saveAs,
} from 'file-saver';
import initPromotionTags from '@/constants/initPromotionTags';

import ButtonComponent from '@/components/Button/ButtonComponent.vue';
import ButtonGroup from '@/components/ButtonGroup/ButtonGroup.vue';
import ButtonMore from '@/components/ButtonMore/ButtonMore.vue';
import CouponComponent from '@/components/Coupon/index.vue';
import COverviewPageLayout from '@/module/common/components/PageParts/COverviewPageLayout.vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSHeader from '@/module/design-system/components/Text/DSHeader.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSTooltip from '@/module/design-system/components/InfoBlock/DSTooltip.vue';
import InlineAlert from '@/components/InlineAlert/InlineAlert.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import PromoTags from '@/components/PromoTags/index.vue';
import PromotionProduct from '@/components/PromotionProduct/index.vue';

export default {
  name: 'PromotionCardPage',
  components: {
    ButtonComponent,
    ButtonGroup,
    ButtonMore,
    CouponComponent,
    COverviewPageLayout,
    DSButton,
    DSHeader,
    DSIcon,
    DSTooltip,
    InlineAlert,
    ModalComponent,
    PromoTags,
    PromotionProduct,
  },
  data() {
    return {
      isLoading: true,
      isError: false,
      showModal: false,
      promotionsTags: {},
    };
  },
  computed: {
    ...mapGetters({
      locale: 'getLocale',
      isMenuMinimized: 'commonStatusesStore/getIsMenuMinimized',
      promotion: 'promotionStore/getPromotionCard',
      couponsType: 'promotionStore/getCouponsType',
      allCoupons: 'promotionStore/getAllCoupons',
      allCouponsNumber: 'promotionStore/getAllCouponsNumber',
      activeCouponsNumber: 'promotionStore/getActiveCouponsNumber',
      user: 'authStore/getUser',
    }),
    changeStatusButtons() {
      const buttons = [{
        name: 'promotions.promotion_card.popover.delete',
        close: true,
        handler: () => {
          this.showModal = true;
        },
      }];
      if (this.promotion.promotionStatus === 'Active') {
        buttons.unshift({
          name: 'promotions.promotion_card.popover.disable',
          close: true,
          handler: () => this.handleChangeStatusPromotion('disable'),
        });
      }
      if (this.promotion.promotionStatus === 'Inactive') {
        buttons.unshift({
          name: 'promotions.promotion_card.popover.activate',
          close: true,
          handler: () => this.handleChangeStatusPromotion('activate'),
        });
      }
      return buttons;
    },
    tagList() {
      const tags = [];
      const promotionType = this.promotion.promotionType.toLowerCase();
      const promotionStatus = this.promotion.promotionStatus.toLowerCase();

      if (this.promotionsTags[promotionType]) {
        tags.push(this.promotionsTags[promotionType]);
      }
      if (this.promotionsTags[promotionStatus]) {
        tags.push(this.promotionsTags[promotionStatus]);
      }

      return tags;
    },
    discountModel() {
      return this.promotion.promotionDiscountModel.modelName;
    },
    usesTooltip() {
      let tooltip = this.$t('promotions.promotion_card.coupons.uses.tooltip.default');

      if (this.allCouponsNumber < 5) {
        tooltip += ` ${this.$t('promotions.promotion_card.coupons.uses.tooltip.copy')}`;
      }

      return tooltip;
    },
    headerText() {
      const discountTitle = this.$t('promotions.promotion_card.discount.title');
      const numberFormatService = this.$container.numberFormatService;

      if (this.discountModel !== 'Custom') {
        const totalDiscountPercent = numberFormatService
          .percentageForPromo(this.promotion.promotionDiscountModel.totalDiscountPercent);
        return `${discountTitle} - ${totalDiscountPercent}`;
      }
      return discountTitle;
    },
  },
  async mounted() {
    const response = await this.loadPromotionCard(this.$route.params.id);
    this.isError = !!response.error;
    this.isLoading = false;

    this.promotionsTags = initPromotionTags(this);
  },
  destroyed() {
    this.setCard({});
  },
  methods: {
    ...mapActions({
      loadPromotionCard: 'promotionStore/loadPromotionCard',
      changeStatusPromotion: 'promotionStore/changeStatus',
    }),
    ...mapMutations({
      setCard: 'promotionStore/setCard',
      togglePopUp: 'popupStore/togglePopUp',
      togglePopUpError: 'popupStore/toggleError',
      toggleWithControls: 'popupStore/toggleWithControls',
      changeTryAgainHandler: 'popupStore/changeTryAgainHandler',
      fiveSecondsShowPopUp: 'popupStore/fiveSecondsShowPopUp',
      changePopUpTitle: 'popupStore/changeTitle',
      changePopUpDescription: 'popupStore/changeDescription',
    }),
    async handleChangeStatusPromotion(newStatus) {
      this.showModal = false;
      this.changePopUpTitle('');
      const response = await this.changeStatusPromotion(newStatus);
      if (response.status >= 400) {
        this.changePopUpTitle(this.$t('promotions.promotion_card.modal.delete.error'));
        this.changePopUpDescription(this.$t('promotions.promotion_card.modal.delete.reject'));
        this.toggleWithControls();
        this.togglePopUpError();
        this.togglePopUp();
        this.changeTryAgainHandler(() => this.handleChangeStatusPromotion(newStatus));
      } else if (newStatus === 'delete') {
        this.changePopUpTitle(this.$t('promotions.promotion_card.modal.delete.success'));
        this.changePopUpDescription(this.promotion.promotionName);
        this.fiveSecondsShowPopUp();
        this.$router.push({ name: 'PromotionsOverview' }).catch(() => null);
      }
    },
    transformDate(date) {
      return this.$utils.date.formatSkipCurrentYearWithTime(date);
    },
    transformEndDate(dateString) {
      const date = this.$utils.date.getPromotionExpiredDate(dateString);

      return date ? this.$utils.date.formatSkipCurrentYearWithTime(date) : '—';
    },
    saveAllCoupons() {
      const result = ['Coupons;Uses\r\n'];
      const data = this.allCoupons.map((el) => `${el.couponCode};${el.couponUses}\r\n`);
      const date = new Date().toISOString()
        .split('T')[0].split('-')
        .join('');
      result.push(...data);
      saveAs(
        new Blob(result, { type: 'text/csv;charset=utf-8' }),
        `Coupons_${this.promotion.promotionName}_${date}_${this.user.authorId}.csv`,
      );
    },
  },
};
</script>

<style lang="scss">
.promotion-card-tooltip__icon {
  width: 18px;
  height: 18px;

  &_big {
    width: 24px;
    height: 24px;
  }
}

.promotion-card-modal {
  &__title {
    display: flex;
    justify-content: space-between;

    svg path {
      fill: black;
    }
  }

  &__description {
    margin: 20px 0;
  }

  &__name {
    font-weight: 500;
    margin-bottom: 8px;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__date {
    color: #888888;
  }

  &__button-group {
    @media screen and (max-width: 370px) {
      flex-direction: column;

      button {
        justify-content: center;

        &:nth-child(2) {
          margin-top: 10px;
        }
      }
    }
  }
}

.promotion-card {
  &__layout {
    height: 100%;
  }

  &__preloader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__header {
    position: relative;
    display: grid;
    grid-template-columns: 1fr min-content min-content;
    column-gap: 10px;
    align-items: center;

    @media screen and (max-width: 370px) {
      &::after {
        content: "";
        position: absolute;
        display: inline-block;
        height: 1px;
        background-color: #E5E5E5;
        top: 48px;
        left: -1000px;
        right: -1000px;
      }
    }
  }

  &__header-button {
    width: fit-content;

    &--more {
      height: 40px;
      width: 40px;

      button {
        border: 0;
      }

      svg {
        height: 4px;
        width: 18px;
      }

      svg path {
        fill: black;
      }
    }
  }

  &__section {
    padding: 32px 0;

    @media screen and (max-width: 370px) {
      border-bottom: 1px solid #E5E5E5;
    }

    &-header {
      display: flex;
    }

    &-title {
      margin-right: 6px;
    }
  }

  &__section--underlined {
    border-bottom: 1px solid #E5E5E5;
  }

  &__title {
    white-space: nowrap;
  }

  &__row {
    display: grid;
    grid-template-columns: 72px 1fr;
    column-gap: 40px;
    margin-top: 20px;

    @media screen and (max-width: 370px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__tooltiped {
    display: flex;
    align-items: center;

    & > p {
      margin-right: 6px;
    }
  }

  &__coupons {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0;
  }

  &__coupon {
    margin: 12px 12px 0 0;
  }

  &__download-button {
    margin-top: 16px;
  }

  &__products {
    margin-top: -8px;
  }

  .inline-alert {
    .inline-alert__icon {
      line-height: 0;
    }

    .inline-alert__title {
      line-height: 24px;
    }

    .inline-alert__subtitle,
    .inline-alert__controls {
      display: none;
    }
  }

  .primary {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    @media screen and (max-width: 370px) {
      margin-bottom: 8px;
    }
  }

  .secondary {
    @media screen and (max-width: 370px) {
      margin-bottom: 8px;
    }
  }
}
</style>
