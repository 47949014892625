import defaultLayout from '@/router/defaultLayout';
import ERouteName from '@/module/common/models/router/ERouteName';
import PrivacyNotice from '../view/PrivacyNoticePage.vue';

export default () => [
  {
    path: '/privacy-notice',
    name: ERouteName.PRIVACY_NOTICE,
    components: {
      default: PrivacyNotice,
      ...defaultLayout,
    },
    meta: { title: 'pages.privacy_notice.title' },
  },
];
