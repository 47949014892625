import axios from 'axios';
import getMonthYearsInPeriod from '@/helpers/getMonthYearsInPeriod';
import getDaysInPeriod from '@/helpers/getDaysInPeriod';

const getLastMonthDates = () => {
  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();

  return {
    dateFrom: new Date(year, month - 1, 1),
    dateTo: new Date(year, month, 0),
    selectionMode: 'L30D',
  };
};

function getSeparatedDate(date) {
  return date.toLocaleDateString(
    'en',
    { year: 'numeric', month: '2-digit', day: '2-digit' },
  ).split('/');
}

export default (serviceContainer) => ({
  namespaced: true,
  state: {
    activeGraphicType: 'bar',
    firstMetric: {
      name: 'reports.overview.metric_list.net_revenue', value: 'Net Revenue', id: 'net_revenue', color: '#AFB5FF',
    },
    secondMetric: {
      name: 'reports.overview.metric_list.select_a_metric',
      value: 'Select a metric',
      id: 'select_a_metric',
    },
    dimension: 'daily',
    licenseType: 'all',
    orderCurrency: 0,
    dateFirst: getLastMonthDates(),
    dateSecond: {
      dateFrom: null,
      dateTo: null,
      selectionMode: 'NC',
    },
    initialTotals: null,
    comparisonTotals: null,
    initialRows: null,
    initialGraphicRows: null,
    comparisonRows: null,
    tableDimension: 'daily',
    tableMetric: ['revenue', 'fees', 'taxes', 'refunds', 'net_revenue', 'orders', 'average_orders'],
    tableData: null,
    sortedByField: null,
    sortOrder: null,
    isTableLoading: false,
    isChartLoading: false,
    isInitialRequest: true,
    isGraphicLoading: false,
    isFirstDatePairRequest: false,
    isSecondDatePairRequest: false,
    errorStatusCode: null,
    dateFirstCancelToken: axios.CancelToken.source(),
    dateSecondCancelToken: axios.CancelToken.source(),
    tableCancelToken: axios.CancelToken.source(),
  },
  mutations: {
    updateDate(state, { type, datePair }) {
      const { start, end } = datePair;
      state[type].dateFrom = start;
      state[type].dateTo = end;
    },
    updateMode(state, { type, newMode }) {
      state[type].selectionMode = newMode;
    },
    updateDimension(state, newDimension) {
      state.dimension = newDimension;
    },
    updateTableDimension(state, newTableDimension) {
      state.tableDimension = newTableDimension;
      state.sortedByField = null;
      state.sortOrder = null;
    },
    updateTableMetric(state, newTableMetric) {
      state.tableMetric = newTableMetric;
    },
    updateLicenseType(state, newLicenseType) {
      state.licenseType = newLicenseType;
    },
    updateOrderCurrency(state, newOrderCurrency) {
      state.orderCurrency = newOrderCurrency;
    },
    setActiveGraphic(state, newType) {
      state.activeGraphicType = newType;
    },
    setFirstMetric(state, newMetric) {
      state.firstMetric = newMetric;
    },
    setSecondMetric(state, newMetric) {
      state.secondMetric = newMetric;
    },
    setTotals(state, { isComparison, newTotals }) {
      state[isComparison ? 'comparisonTotals' : 'initialTotals'] = newTotals;
    },
    setRows(state, { isComparison, newRows }) {
      state[isComparison ? 'comparisonGraphicRows' : 'initialGraphicRows'] = [...newRows];
      state[isComparison ? 'comparisonRows' : 'initialRows'] = newRows;
    },
    setTableData(state, newTableData) {
      state.tableData = newTableData;
    },
    setSortedByField(state, fieldName) {
      if (!state.sortedByField || state.sortedByField !== fieldName) {
        state.sortedByField = fieldName;
        state.sortOrder = 'desc';
      } else {
        state.sortOrder = state.sortOrder === 'desc' ? 'asc' : 'desc';
      }
    },
    setErrorStatusCode(state, newStatus) {
      state.errorStatusCode = newStatus;
    },
    setIsTableLoading(state, isLoading) {
      state.isTableLoading = isLoading;
    },
    setIsGraphicLoading(state, isLoading) {
      state.isGraphicLoading = isLoading;
    },
    setIsChartLoading(state, isLoading) {
      state.isChartLoading = isLoading;
    },
    setCancelToken(state, tokenFor = 'dateFirst') {
      const token = `${tokenFor}CancelToken`;

      state[token] = axios.CancelToken.source();
    },
    setIsFirstDatePairRequest(state, isRunning) {
      state.isFirstDatePairRequest = isRunning;
    },
    setIsSecondDatePairRequest(state, isRunning) {
      state.isSecondDatePairRequest = isRunning;
    },
  },
  getters: {
    getFirstMetric(state) {
      return state.firstMetric;
    },
    getSecondMetric(state) {
      return state.secondMetric;
    },
    getFirstDatePair(state) {
      return state.dateFirst;
    },
    getSecondDatePair(state) {
      return state.dateSecond;
    },
    getSelectionModes(state) {
      return {
        first: state.dateFirst.selectionMode,
        second: state.dateSecond.selectionMode,
      };
    },
    getDimension(state) {
      return state.dimension;
    },
    getTableDimension(state) {
      return state.tableDimension;
    },
    getTableMetric(state) {
      return state.tableMetric;
    },
    getLicenseType(state) {
      return state.licenseType;
    },
    getOrderCurrency(state) {
      return state.orderCurrency;
    },
    getQueryParams(state) {
      const separate = `separate=${state.licenseType === 'separate' ? 1 : 0}`;
      const dimension = `&dimension=${state.dimension}`;
      const tableDimension = `&dimension=${state.tableDimension}`;

      const orderCurrency = state.orderCurrency ? `&filters[orderCurrency]=${state.orderCurrency}` : '';
      const licenseType = ['all', 'separate'].includes(state.licenseType)
        ? ''
        : `&filters[licenseType]=${state.licenseType}`;

      return {
        separate,
        dimension,
        tableDimension,
        orderCurrency,
        licenseType,
      };
    },
    getFirstDatePairQuery(state) {
      const [MMF, DDF, YYYYF] = getSeparatedDate(state.dateFirst.dateFrom);
      const dateFromQuery = `&date[dateFrom]=${YYYYF}-${MMF}-${DDF} 00:00:00`;

      const [MMT, DDT, YYYYT] = getSeparatedDate(state.dateFirst.dateTo);
      const dateToQuery = `&date[dateTo]=${YYYYT}-${MMT}-${DDT} 23:59:59`;

      return { dateFromQuery, dateToQuery };
    },
    getSecondDatePairQuery(state) {
      if (state.licenseType === 'separate' || state.dateSecond.selectionMode === 'NC') {
        const [MM, DD, YYYY] = getSeparatedDate(state.dateFirst.dateFrom);
        const dateTo = new Date(YYYY, MM - 1, DD - 1);
        const dateFrom = new Date(
          dateTo.getTime() - (
            state.dateFirst.dateTo.getTime() - state.dateFirst.dateFrom.getTime()
          ),
        );

        const [MMF, DDF, YYYYF] = getSeparatedDate(dateFrom);
        const [MMT, DDT, YYYYT] = getSeparatedDate(dateTo);

        const dateFromQuery = `&date[dateFrom]=${YYYYF}-${MMF}-${DDF} 00:00:00`;
        const dateToQuery = `&date[dateTo]=${YYYYT}-${MMT}-${DDT} 23:59:59`;

        return { dateFromQuery, dateToQuery };
      }

      const [MMF, DDF, YYYYF] = getSeparatedDate(state.dateSecond.dateFrom);
      const dateFromQuery = `&date[dateFrom]=${YYYYF}-${MMF}-${DDF} 00:00:00`;

      const [MMT, DDT, YYYYT] = getSeparatedDate(state.dateSecond.dateTo);
      const dateToQuery = `&date[dateTo]=${YYYYT}-${MMT}-${DDT} 23:59:59`;

      return { dateFromQuery, dateToQuery };
    },
    getInitialReportsQuery(state, getters) {
      const {
        separate, dimension, orderCurrency, licenseType,
      } = getters.getQueryParams;
      const { dateFromQuery, dateToQuery } = getters.getFirstDatePairQuery;

      return `?${separate}${dimension}${dateFromQuery}${dateToQuery}${licenseType}${orderCurrency}`;
    },
    getComparisonReportsQuery(state, getters) {
      const {
        separate, dimension, orderCurrency, licenseType,
      } = getters.getQueryParams;
      const { dateFromQuery, dateToQuery } = getters.getSecondDatePairQuery;

      return `?${separate}${dimension}${dateFromQuery}${dateToQuery}${licenseType}${orderCurrency}`;
    },
    getReportsTableQuery(state, getters) {
      const {
        separate, tableDimension, orderCurrency, licenseType,
      } = getters.getQueryParams;
      const firstDatePair = getters.getFirstDatePairQuery;
      const secondDatePair = getters.getSecondDatePairQuery;
      const initialDateQuery = firstDatePair.dateFromQuery + firstDatePair.dateToQuery;
      const comparisonDateQuery = (secondDatePair.dateFromQuery + secondDatePair.dateToQuery)
        .replaceAll('date[', 'compare[');

      const dateQuery = initialDateQuery + (state.dateSecond.selectionMode !== 'NC' && state.licenseType !== 'separate'
        ? comparisonDateQuery
        : '');

      return `?${separate}${tableDimension}${dateQuery}${orderCurrency}${licenseType}`;
    },
    getInitialTotals(state) {
      return state.initialTotals;
    },
    getComparisonTotals(state) {
      return state.comparisonTotals;
    },
    getTableData(state) {
      return state.tableData;
    },
    getGraphicData(state) {
      return state.initialGraphicRows;
    },
    getCompareGraphicData(state) {
      return state.comparisonRows;
    },
    getSortedByField(state) {
      return state.sortedByField;
    },
    getSortOrder(state) {
      return state.sortOrder;
    },
    getErrorStatusCode(state) {
      return state.errorStatusCode;
    },
    getIsTableLoading(state) {
      return state.isTableLoading;
    },
    getIsGraphicLoading(state) {
      return state.isGraphicLoading;
    },
    getIsChartLoading(state) {
      return state.isChartLoading;
    },
    getFilterOptions(state) {
      return {
        dateFirst: state.dateFirst,
        dateSecond: state.dateSecond,
        dimension: state.dimension,
        orderCurrency: state.orderCurrency,
        licenseType: state.licenseType,
        tableDimension: state.tableDimension,
      };
    },
    getSeparateInitialGraphicData(state) {
      let result = [];
      if (state.licenseType === 'separate') {
        const datesPeriod = state.dimension === 'monthly'
          ? getMonthYearsInPeriod(state.dateFirst.dateFrom, state.dateFirst.dateTo)
          : getDaysInPeriod(state.dateFirst.dateFrom, state.dateFirst.dateTo);
        const filteredRawData = state.initialGraphicRows.filter((item) => item.license_type === 'initial');

        if (datesPeriod.length === filteredRawData.length) {
          result = filteredRawData;
        } else {
          const keyValueData = filteredRawData.reduce((map, obj) => {
            map[obj.date] = obj;
            return map;
          }, {});

          datesPeriod.forEach((date) => {
            let value = keyValueData[date];
            if (!value) {
              value = {
                date,
                license_type: 'initial',
                revenue: {
                  value: 0,
                  percentByTotals: 0,
                },
                fees: {
                  value: 0,
                  percentByTotals: 0,
                },
                taxes: {
                  value: 0,
                  percentByTotals: 0,
                },
                orders: {
                  value: 0,
                  percentByTotals: 0,
                },
                average_orders: 0,
                refunds: {
                  value: 0,
                  percentByTotals: 0,
                },
                net_revenue: {
                  value: 0,
                  percentByTotals: 0,
                },
              };
            }

            result.push(value);
          });
        }
      }
      return result;
    },
    getSeparateRenewalGraphicData(state) {
      let result = [];
      if (state.licenseType === 'separate') {
        const datesPeriod = state.dimension === 'monthly'
          ? getMonthYearsInPeriod(state.dateFirst.dateFrom, state.dateFirst.dateTo)
          : getDaysInPeriod(state.dateFirst.dateFrom, state.dateFirst.dateTo);
        const filteredRawData = state.initialGraphicRows.filter((item) => item.license_type === 'renewal');

        if (datesPeriod.length === filteredRawData.length) {
          result = filteredRawData;
        } else {
          const keyValueData = filteredRawData.reduce((map, obj) => {
            map[obj.date] = obj;
            return map;
          }, {});

          datesPeriod.forEach((date) => {
            let value = keyValueData[date];
            if (!value) {
              value = {
                date,
                license_type: 'renewal',
                revenue: {
                  value: 0,
                  percentByTotals: 0,
                },
                fees: {
                  value: 0,
                  percentByTotals: 0,
                },
                taxes: {
                  value: 0,
                  percentByTotals: 0,
                },
                orders: {
                  value: 0,
                  percentByTotals: 0,
                },
                average_orders: 0,
                refunds: {
                  value: 0,
                  percentByTotals: 0,
                },
                net_revenue: {
                  value: 0,
                  percentByTotals: 0,
                },
              };
            }
            result.push(value);
          });
        }
      }

      return result;
    },
  },
  actions: {
    async getFirstDatePairData({ state, getters, commit }, setTableData) {
      if (state.isFirstDatePairRequest) {
        state.dateFirstCancelToken.cancel('firstDateCancel');
      }

      commit('setIsFirstDatePairRequest', true);
      commit('setCancelToken', 'dateFirst');
      commit('setIsChartLoading', true);

      const api = serviceContainer.resolve('api');

      try {
        const { data } = await api.reports.getReportsOverview(
          getters.getInitialReportsQuery,
          state.dateFirstCancelToken.token,
        );
        commit('setTotals', { isComparison: false, newTotals: data.totals[0] });
        commit('setRows', { isComparison: false, newRows: data.rows });

        if (setTableData) commit('setTableData', data);
        commit('setIsFirstDatePairRequest', false);
      } catch (error) {
        if (error.message === 'firstDateCancel') {
          return;
        }

        commit('setIsFirstDatePairRequest', false);
        commit('setErrorStatusCode', error.response.status);
      }
    },
    async getSecondDatePairData({ state, getters, commit }) {
      if (state.isSecondDatePairRequest) {
        state.dateSecondCancelToken.cancel('secondDateCancel');
      }

      commit('setIsSecondDatePairRequest', true);
      commit('setIsChartLoading', true);
      commit('setCancelToken', 'dateSecond');

      const api = serviceContainer.resolve('api');

      try {
        const { data } = await api.reports.getReportsOverview(
          getters.getComparisonReportsQuery,
          state.dateSecondCancelToken.token,
        );
        commit('setTotals', { isComparison: true, newTotals: data.totals[0] });
        commit('setRows', { isComparison: true, newRows: data.rows });
      } catch (error) {
        if (error.message === 'secondDateCancel') {
          return;
        }

        commit('setErrorStatusCode', error.response.status);
        commit('setIsSecondDatePairRequest', true);
      }
    },
    async getReportsTableData({
      state, getters, commit, dispatch,
    }) {
      if (state.isTableLoading) {
        state.tableCancelToken.cancel('tableCancel');
      }

      commit('setIsTableLoading', true);
      commit('setCancelToken', 'table');

      const isSeparate = state.licenseType === 'separate';
      const isSeconDateEmpty = state.dateSecond.selectionMode === 'NC';
      const isDimensionsSame = !(state.tableDimension !== 'daily');

      const api = serviceContainer.resolve('api');

      try {
        const { data } = await api.reports.getReportsTable(
          getters.getReportsTableQuery,
          state.tableCancelToken.token,
        );
        if (!isSeconDateEmpty || isSeparate) {
          const groupBy = isSeparate ? 2 : 3;
          const gorupedTotals = await dispatch('groupRows', { rows: data.totals, groupBy });
          const groupedRows = await dispatch('groupRows', { rows: data.rows, groupBy });

          commit('setTableData', {
            totals: !isDimensionsSame || isSeparate ? gorupedTotals : groupedRows,
            rows: !isDimensionsSame || isSeparate ? groupedRows : [],
          });
          return;
        }

        commit('setTableData', data);
      } catch (error) {
        commit('setErrorStatusCode', error.response.status);
      }
    },
    async getReportsOverviewData({
      state, commit, dispatch,
    }, requestOptions) {
      const { getFirstDatePairData, getSecondDatePairData, getTableData } = requestOptions;

      const rewriteTableData = !getTableData && state.dimension === state.tableDimension
        && state.licenseType !== 'separate';
      if (rewriteTableData || getTableData) {
        commit('setIsTableLoading', true);
      } else {
        commit('setIsGraphicLoading', true);
      }

      if (getFirstDatePairData || getSecondDatePairData) {
        commit('setIsChartLoading', true);
      }

      if (getFirstDatePairData) await dispatch('getFirstDatePairData', rewriteTableData);

      if (getSecondDatePairData) await dispatch('getSecondDatePairData');

      if (getTableData && !rewriteTableData) await dispatch('getReportsTableData');

      commit('setIsGraphicLoading', false);
      commit('setIsTableLoading', false);
      commit('setIsChartLoading', false);
    },
    sortTableDataRows({ commit, state, getters }) {
      const { totals, rows } = getters.getTableData;
      const dimensionFieldNames = {
        title: 'product_title',
        titleAndSubtitle: 'product_title',
        sku: 'productsku',
        orders: 'subscription_type',
      };

      const sortedRows = rows.sort((...currentRows) => {
        const isRowsGrouped = state.dateSecond.selectionMode !== 'NC' || state.licenseType === 'separate';
        const rowA = isRowsGrouped ? currentRows[0][0] : currentRows[0];
        const rowB = isRowsGrouped ? currentRows[1][0] : currentRows[1];

        let valueA; let
          valueB;

        const sortedByField = state.sortedByField;

        if (sortedByField !== 'dimension') {
          if (sortedByField === 'average_orders') {
            valueA = rowA[sortedByField];
            valueB = rowB[sortedByField];
          } else {
            valueA = rowA[sortedByField] ? rowA[sortedByField].value : rowA[sortedByField];
            valueB = rowB[sortedByField] ? rowB[sortedByField].value : rowB[sortedByField];
          }
        } else if (sortedByField === 'dimension' && state.tableDimension === 'daily') {
          const [DDA, MMA, YYYYA] = rowA.date.split('.');
          valueA = new Date(`${MMA}/${DDA}/${YYYYA}`).getTime();

          const [DDB, MMB, YYYYB] = rowB.date.split('.');
          valueB = new Date(`${MMB}/${DDB}/${YYYYB}`).getTime();
        } else if (sortedByField === 'dimension' && state.tableDimension !== 'daily') {
          valueA = rowA[dimensionFieldNames[state.tableDimension]];
          valueB = rowB[dimensionFieldNames[state.tableDimension]];
        }

        if (valueA < valueB) {
          return state.sortOrder === 'asc' ? -1 : 1;
        }
        return state.sortOrder === 'asc' ? 1 : -1;
      });

      commit('setTableData', {
        totals,
        rows: sortedRows,
      });
    },
    groupRows(_store, { rows, groupBy }) {
      const groupedRows = [];

      for (let index = 0; index < rows.length; index += 1) {
        if (index % groupBy === 0) {
          groupedRows.push([]);
        }

        groupedRows[groupedRows.length - 1].push(rows[index]);
      }

      return groupedRows;
    },
    async getExportLink({ getters }) {
      const api = serviceContainer.resolve('api');

      try {
        const response = await api.reports.getTableExportLink('csv', getters.getReportsTableQuery);
        return response.data.url;
      } catch (error) {
        return null;
      }
    },
    setFiltersToLocalStorage({ getters }) {
      window.localStorage.setItem(
        'reportsOverviewFilters',
        JSON.stringify(getters.getFilterOptions),
      );
    },
    getFiltersFromLocalStorage({ getters, commit }) {
      const filters = window.localStorage.getItem('reportsOverviewFilters');
      if (!filters || JSON.stringify(getters.getFilterOptions) === filters) return;

      const {
        dateFirst,
        dateSecond,
        dimension,
        orderCurrency,
        licenseType,
        tableDimension,
      } = JSON.parse(filters);

      commit('updateDate', {
        type: 'dateFirst',
        datePair: {
          start: new Date(dateFirst.dateFrom.toString()),
          end: new Date(dateFirst.dateTo.toString()),
        },
      });
      commit('updateMode', {
        type: 'dateFirst',
        newMode: dateFirst.selectionMode,
      });

      commit('updateDate', {
        type: 'dateSecond',
        datePair: {
          start: dateSecond.dateFrom ? new Date(dateSecond.dateFrom.toString()) : null,
          end: dateSecond.dateTo ? new Date(dateSecond.dateTo.toString()) : null,
        },
      });
      commit('updateMode', {
        type: 'dateSecond',
        newMode: dateSecond.selectionMode,
      });

      commit('updateDimension', dimension);
      commit('updateOrderCurrency', orderCurrency);
      commit('updateLicenseType', licenseType);
      commit('updateTableDimension', tableDimension);
    },
  },
});
