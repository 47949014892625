<template>
  <!-- TODO: вынести perfect-scrollbar за пределы компонента -->
  <perfect-scrollbar
    ref="scroll"
  >
    <div
      class="tab-line"
    >
      <DSTabItem
        v-for="(tab, index) in tabList"
        :ref="`tab-${index}`"
        :key="index"
        :tab="tab"
        :variant="currentTab[idField] === tab[idField] ? 'active' : undefined"
        @click="handleClick(tab)"
      >
        <template
          #icon
        >
          <slot
            :tab="tab"
          />
        </template>
      </DSTabItem>
    </div>
  </perfect-scrollbar>
</template>

<script>
import DSTabItem from '@/module/design-system/components/UI/Tab/DSTabItem.vue';

export default {
  name: 'DSTabLine',
  components: {
    DSTabItem,
  },
  props: {
    tabList: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: Object,
      default: () => ({}),
    },
    titleField: {
      type: String,
      default: 'title',
    },
    idField: {
      type: String,
      default: 'id',
    },
  },
  data() {
    return {
      currentTab: {},
    };
  },
  watch: {
    activeTab() {
      this.currentTab = this.activeTab;

      this.$nextTick(() => {
        const tabIndex = this.tabList.findIndex((tab) => this.currentTab[this.idField] === tab[this.idField]);
        this.$refs.scroll.$el.scrollLeft = this.$refs[`tab-${tabIndex}`][0].$el.offsetLeft;
      });
    },
  },
  mounted() {
    this.currentTab = this.activeTab;
  },
  methods: {
    handleClick(tab) {
      this.currentTab = tab;
      this.$emit('clickTab', tab);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.tab-line {
  display: flex;
  gap: 8px;
}
</style>
