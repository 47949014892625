import IAuthorizeService from '@/module/common/service/AuthorizeService/IAuthorizeService';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import { Store } from 'vuex';
import { AxiosRequestConfig } from 'axios';
import ILocalStorageService from '@/module/common/service/LocalStorage/ILocalStorageService';
import ELocalStorageKeys from '@/module/common/service/LocalStorage/ELocalStorageKeys';
import IAuthorizationApi from '@/api/Auth/IAuthorizationApi';
import IUtilsService from '@/module/common/service/UtilsService/contract/IUtilsService';

class AuthorizeService implements IAuthorizeService {
  private readonly serviceContainer: IServiceContainer;

  private authorizationAttempts = 0;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  checkToken = async (): Promise<boolean> => {
    if (!this.isAuthorised()) {
      return Promise.resolve(false);
    }

    const api: { authorizationApi: IAuthorizationApi } = this.serviceContainer.resolve(EServiceContainerInstances.API);

    try {
      const response = await api.authorizationApi.isTokenExists();

      return Promise.resolve(response.data.exists);
    } catch (e) {
      return Promise.resolve(false);
    }
  };

  private isAuthorised = (): boolean => {
    const localStorageService = this.serviceContainer
      .resolve<ILocalStorageService>(EServiceContainerInstances.LOCAL_STORAGE_SERVICE);

    return !!localStorageService.read(ELocalStorageKeys.AUTH_TOKEN);
  };

  goToSSO = async () => {
    const api: { authorizationApi: IAuthorizationApi } = this.serviceContainer.resolve(EServiceContainerInstances.API);

    const response = await api.authorizationApi.getSSOLink();
    const { link } = response.data;

    window.location.href = `${link}&redirect_uri=${window.location.origin}/ssoAuth`;
  };

  auth = async (ssoCode?: string | number): Promise<boolean> => {
    const api: { authorizationApi: IAuthorizationApi } = this.serviceContainer.resolve(EServiceContainerInstances.API);
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);
    const utilsService = this.serviceContainer.resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);
    const localStorageService = this.serviceContainer
      .resolve<ILocalStorageService>(EServiceContainerInstances.LOCAL_STORAGE_SERVICE);

    if (utilsService.typeCheck.isUndefined(ssoCode)) {
      return this.logout();
    }

    try {
      const response = await api.authorizationApi.auth(ssoCode);

      store.commit('authStore/updateAllowedMerchantModes', response.data.allowedVendorModes);

      localStorageService.write(ELocalStorageKeys.AUTH_TOKEN, response.data.accessToken);

      this.authorizationAttempts = 0;

      return Promise.resolve(true);
    } catch (e) {
      if (this.authorizationAttempts < 3) {
        this.authorizationAttempts += 1;

        return this.auth(ssoCode);
      }

      this.authorizationAttempts = 0;
      await this.logout();

      return Promise.resolve(false);
    }
  };

  logout = async () => {
    const api: { authorizationApi: IAuthorizationApi } = this.serviceContainer.resolve(EServiceContainerInstances.API);
    const localStorageService = this.serviceContainer
      .resolve<ILocalStorageService>(EServiceContainerInstances.LOCAL_STORAGE_SERVICE);

    const response = await api.authorizationApi.getSSOLogoutLink();
    const { link } = response.data;

    try {
      await api.authorizationApi.logout();

      localStorageService.clear();

      window.location.href = `${link}?back=${window.location.origin}`;

      return Promise.resolve(true);
    } catch (e) {
      window.location.href = `${link}?back=${window.location.origin}`;
      return Promise.resolve(true);
    }
  };

  signRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const localStorageService = this.serviceContainer
      .resolve<ILocalStorageService>(EServiceContainerInstances.LOCAL_STORAGE_SERVICE);

    const authToken = localStorageService.read(ELocalStorageKeys.AUTH_TOKEN);

    if (!config.headers) {
      config.headers = {};
    }

    config.headers.Authorization = authToken ? `Bearer ${authToken}` : null;
    config.headers['X-Vendor-Mode'] = localStorageService.read(ELocalStorageKeys.MERCHANT_MODE);

    return config;
  };
}

export default AuthorizeService;
