<template>
  <div
    v-click-outside="handleClickOutside"
    class="c-menu-dropdown"
  >
    <div
      ref="toggler"
      class="c-menu-dropdown__toggler"
      @click="handleClick"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
    >
      <slot />
    </div>

    <div
      v-if="isOpenFullModal || isOpenHoverModal"
      :class="modalClasses"
    >
      <div
        :style="triangleStyle"
        class="c-menu-dropdown__triangle"
      />
      <div
        v-for="(list, index) in currentMenuList"
        :key="index"
        :class="listClasses(index)"
      >
        <template
          v-for="(item, listIndex) in list"
        >
          <div
            v-if="item.type === 'link'"
            :key="getItemKey(item, listIndex)"
            class="c-menu-dropdown__item c-menu-dropdown__item_link"
            @click="handleClickLinkItem($event, item)"
            @mouseenter="handleItemMouseEnter(item)"
            @mouseleave="handleItemMouseLeave(item)"
          >
            <DSLink
              :is-router-link="getIsRouterLink(item)"
              :target="item.target"
              :title="item.title"
              :url="item.url"
              :variant="getTitleVariant(item)"
              class="c-menu-dropdown__text"
              color="menu"
            >
              <template
                v-if="item.icon"
                #icon-before
              >
                <DSIcon
                  :color="getItemIconColor(item)"
                  :icon="item.icon"
                  size="xs"
                />
              </template>
            </DSLink>
          </div>
          <div
            v-else-if="item.type === 'email'"
            :key="listIndex"
            class="c-menu-dropdown__item"
          >
            <DSTextElement
              :variant="getTitleVariant(item)"
              class="c-menu-dropdown__title"
              color="menu"
            >
              {{ item.title }}
            </DSTextElement>
            <DSLink
              :download="item.download"
              :is-router-link="false"
              :title="item.email"
              :url="item.url"
              :variant="getTextVariant(item)"
              class="c-menu-dropdown__text"
            />
          </div>
          <div
            v-else
            :key="listIndex"
            class="c-menu-dropdown__item"
          >
            <DSTextElement
              :variant="getTitleVariant(item)"
              class="c-menu-dropdown__title"
              color="menu"
            >
              {{ item.title }}
            </DSTextElement>
            <DSTextElement
              v-if="item.text"
              :variant="getTextVariant(item)"
              class="c-menu-dropdown__text"
              color="menu"
            >
              {{ item.text }}
            </DSTextElement>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import DSLink from '@/module/design-system/components/Text/DSLink.vue';
import {
  mapGetters,
} from 'vuex';

export default {
  name: 'CDropdownMenu',
  components: {
    DSIcon,
    DSLink,
    DSTextElement,
  },
  props: {
    menuItemListFull: {
      type: Array,
      default: () => [],
    },
    menuItemListHover: {
      type: Array,
      default: () => [],
    },
    position: {
      type: String,
      default: 'bottom-left',
    },
  },
  data() {
    return {
      isOpenFullModal: false,
      isOpenHoverModal: false,
      iconColorMap: new Map(),
    };
  },
  computed: {
    ...mapGetters({
      isMerchantActivated: 'authStore/getIsMerchantActivated',
    }),
    currentMenuList() {
      let currentList = this.menuItemListFull;

      if (this.menuItemListFull.length > 0 && this.menuItemListHover.length > 0) {
        currentList = this.isOpenFullModal ? this.menuItemListFull : this.menuItemListHover;
      } else if (this.menuItemListHover.length > 0) {
        currentList = this.menuItemListHover;
      }

      return currentList;
    },
    modalClasses() {
      return [
        'c-menu-dropdown__modal',
        `c-menu-dropdown__modal_position_${this.position}`,
      ];
    },
    triangleStyle() {
      const halfOfParentsWidth = this.$refs.toggler.offsetWidth / 2;

      const styleMap = {
        'top-left': {
          top: '100%',
          right: `${halfOfParentsWidth}px`,
          transform: 'translateX(50%)',
        },
        'top-center': {
          top: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
        },
        'top-right': {
          top: '100%',
          left: `${halfOfParentsWidth}px`,
          transform: 'translateX(-50%)',
        },
        'center-left': {
          top: '50%',
          transform: 'translateY(-50%)',
        },
        'center-right': {
          top: '50%',
          transform: 'translateY(-50%)',
        },
        'bottom-left': {
          bottom: '100%',
          right: `${halfOfParentsWidth}px`,
          transform: 'translateX(50%) rotate(180deg)',
        },
        'bottom-center': {
          bottom: '100%',
          left: '50%',
          transform: 'translateX(-50%) rotate(180deg)',
        },
        'bottom-right': {
          bottom: '100%',
          left: `${halfOfParentsWidth}px`,
          transform: 'translateX(-50%) rotate(180deg)',
        },
      };

      return styleMap[this.position];
    },
  },
  methods: {
    listClasses(index) {
      return [
        'c-menu-dropdown__list',
        index > 0 ? 'c-menu-dropdown__list_bordered' : null,
      ];
    },
    handleMouseEnter() {
      if (this.menuItemListHover.length === 0) {
        return;
      }

      this.isOpenHoverModal = true;
      this.$emit('hoverMenuToggle', this.isOpenHoverModal);
    },
    handleMouseLeave() {
      if (this.menuItemListHover.length === 0) {
        return;
      }

      this.isOpenHoverModal = false;
      this.$emit('hoverMenuToggle', this.isOpenHoverModal);
    },
    handleClick() {
      if (this.menuItemListFull.length === 0) {
        return;
      }

      this.isOpenFullModal = !this.isOpenFullModal;
      this.$emit('fullMenuToggle', this.isOpenFullModal);
    },
    handleClickOutside() {
      if (this.menuItemListFull.length > 0 && this.isOpenFullModal) {
        this.isOpenFullModal = false;
        this.$emit('fullMenuToggle', false);
      }
    },
    handleItemMouseEnter(item) {
      const iconColorMap = new Map(this.iconColorMap.entries());
      iconColorMap.set(item, 'menu-active');

      this.iconColorMap = iconColorMap;
    },
    handleItemMouseLeave(item) {
      const iconColorMap = new Map(this.iconColorMap.entries());
      iconColorMap.set(item, 'surface-icons');

      this.iconColorMap = iconColorMap;
    },
    handleClickLinkItem($event, item) {
      if (item.isRouterLink) {
        this.isOpenFullModal = false;
      }

      this.$emit('linkClick', $event, item);
    },
    getItemKey(item, index) {
      return `menu-${item.name ?? index}`;
    },
    getItemIconColor(item) {
      return this.iconColorMap.get(item);
    },
    getTitleVariant(item) {
      return item.titleVariant ?? 'xsmall';
    },
    getTextVariant(item) {
      return item.textVariant ?? 'xsmall';
    },
    getIsRouterLink(item) {
      return item.isRouterLink ?? false;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import "@/module/design-system/components/variables.scss";
@import "@/module/common/components/variables.scss";

.c-menu-dropdown {
  position: relative;

  &__modal {
    position: absolute;
    z-index: 5;
    padding: 8px 0;
    font-size: 12px;
    border-radius: 4px;
    background-color: $menu-bg-color;
    box-shadow: 0 6px 24px -4px rgba(0, 0, 0, 0.08), 0 4px 12px -6px rgba(0, 0, 0, 0.12);

    &_position {
      &_top-left {
        right: 0;
        bottom: 100%;
        margin-bottom: 7px;
      }

      &_top-center {
        bottom: 100%;
        left: 50%;
        margin-bottom: 7px;
        transform: translateX(-50%);
      }

      &_top-right {
        bottom: 100%;
        left: 0;
        margin-bottom: 7px;
      }

      &_center-left {
        top: 50%;
        right: 100%;
        margin-right: 7px;
        transform: translateY(-50%);
      }

      &_center-right {
        top: 50%;
        left: 100%;
        margin-left: 7px;
        transform: translateY(-50%);
      }

      &_bottom-left {
        top: 100%;
        right: 0;
        margin-top: 18px;
      }

      &_bottom-center {
        top: 100%;
        left: 50%;
        margin-top: 18px;
        transform: translateX(-50%);
      }

      &_bottom-right {
        top: 100%;
        left: 0;
        margin-top: 18px;
      }
    }

    &_on-hover {
      display: none;
    }
  }

  &__triangle {
    position: absolute;
    display: block;
    border-top: 4px solid $menu-bg-color;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
  }

  &__list {
    padding: 0 12px;

    &_bordered {
      margin-top: 8px;
      padding-top: 8px;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
  }

  &__item {
    display: block;
    padding: 5px 8px;
    white-space: nowrap;
    border-radius: 4px;

    &_link {
      transition: all linear .2s;

      &:hover {
        background-color: $menu-item-bg-color-hover;
      }
    }
  }

  &__title {
    display: block;
  }

  &__text {
    display: block;
  }

  &__title + &__text {
    margin-top: 4px;
  }
}

@media (hover: hover) and (pointer: fine) {
  .c-menu-dropdown {
    &__modal {
      &_on-hover {
        display: block;
      }
    }
  }
}
</style>
