import THandleFieldBlurParams from '@/module/product/view/ProductFormPage/model/THandleFieldBlurParams';
import BaseFieldBlurScenario from '@/module/product/view/ProductFormPage/scenario/BaseFieldBlurScenario';
import ITermValue from '@/module/product/view/ProductFormPage/model/ITermValue';

class TermFieldBlurScenario extends BaseFieldBlurScenario {
  run = (item: THandleFieldBlurParams) => {
    const termService = this.serviceContainer.moduleProduct.termService;
    const productFormPage = this.serviceContainer.moduleProduct.productFormPage;

    const localValue = item.value as ITermValue;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    item.value.selectValue = termService
      .list(localValue.inputValue)
      .find((term) => term.id === localValue.selectValue.id) as unknown as ITermValue;

    this.updateFromData(item);

    const formData = productFormPage.handlerService.formDataHandler.get();
    productFormPage.handlerService.validateHandler.validateField(item.field.fieldName, formData);

    this.updateFromConfig();
  };
}

export default TermFieldBlurScenario;
