enum EIconColorCode {
  DISABLED = '#cccccc',
  ERROR = '#ff4444',
  LINKED = '#4397cb',
  MENU = '#919190',
  MENU_ACTIVE = '#ffffff',
  NAVIGATION = '#c70d45',
  PRIMARY = '#000000',
  PRIMARY_BUTTON = '#ffffff',
  SECONDARY = '#888888',
  SUCCESS = '#27ae60',
  SURFACE_ICONS = '#abacac',
  WARNING = '#f2994a',
  ACCENT = '#00489c',
}

export default EIconColorCode;
