<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      d="M28 11.9201C27.9861 11.7976 27.9593 11.6769 27.92 11.5601V11.4401C27.8559 11.303 27.7704 11.177 27.6667 11.0667L19.6667 3.06675C19.5564 2.96304 19.4304 2.87752 19.2933 2.81341C19.2535 2.80776 19.2131 2.80776 19.1733 2.81341C19.0379 2.73574 18.8883 2.68587 18.7333 2.66675H13.3333C12.2725 2.66675 11.2551 3.08818 10.5049 3.83832C9.75476 4.58847 9.33333 5.60588 9.33333 6.66675V8.00008H8C6.93913 8.00008 5.92172 8.42151 5.17157 9.17165C4.42143 9.9218 4 10.9392 4 12.0001V25.3334C4 26.3943 4.42143 27.4117 5.17157 28.1618C5.92172 28.912 6.93913 29.3334 8 29.3334H18.6667C19.7275 29.3334 20.7449 28.912 21.4951 28.1618C22.2452 27.4117 22.6667 26.3943 22.6667 25.3334V24.0001H24C25.0609 24.0001 26.0783 23.5787 26.8284 22.8285C27.5786 22.0784 28 21.0609 28 20.0001V12.0001V11.9201ZM20 7.21341L23.4533 10.6667H21.3333C20.9797 10.6667 20.6406 10.5263 20.3905 10.2762C20.1405 10.0262 20 9.68704 20 9.33342V7.21341ZM20 25.3334C20 25.687 19.8595 26.0262 19.6095 26.2762C19.3594 26.5263 19.0203 26.6667 18.6667 26.6667H8C7.64638 26.6667 7.30724 26.5263 7.05719 26.2762C6.80714 26.0262 6.66667 25.687 6.66667 25.3334V12.0001C6.66667 11.6465 6.80714 11.3073 7.05719 11.0573C7.30724 10.8072 7.64638 10.6667 8 10.6667H9.33333V20.0001C9.33333 21.0609 9.75476 22.0784 10.5049 22.8285C11.2551 23.5787 12.2725 24.0001 13.3333 24.0001H20V25.3334ZM25.3333 20.0001C25.3333 20.3537 25.1929 20.6928 24.9428 20.9429C24.6928 21.1929 24.3536 21.3334 24 21.3334H13.3333C12.9797 21.3334 12.6406 21.1929 12.3905 20.9429C12.1405 20.6928 12 20.3537 12 20.0001V6.66675C12 6.31313 12.1405 5.97399 12.3905 5.72394C12.6406 5.47389 12.9797 5.33341 13.3333 5.33341H17.3333V9.33342C17.3333 10.3943 17.7548 11.4117 18.5049 12.1618C19.2551 12.912 20.2725 13.3334 21.3333 13.3334H25.3333V20.0001Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSCopyIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>

<style scoped>

</style>
