import defaultLayout from '@/router/defaultLayout';
import DashboardOverviewPage from '@/module/dashboard/view/DashboardOverviewPage.vue';
import ERouteName from '@/module/common/models/router/ERouteName';

export default () => [
  {
    path: '/',
    name: ERouteName.DASHBOARD,
    components: {
      default: DashboardOverviewPage,
      ...defaultLayout,
    },
    meta: { title: 'pages.dashboard.title' },
  },
];
