<template>
  <div
    v-click-outside="handleClickOutside"
    :class="classes"
  >
    <select
      :id="id"
      ref="element"
      class="ds-select__select"
    >
      <option
        v-for="option in offsetList"
        :key="option[idField]"
        :value="option[idField]"
      >
        {{ option[titleField] }}
      </option>
    </select>

    <div
      v-if="iconOnly"
      class="ds-select__icon"
      @click="handleButtonClick"
      @mouseenter="handleAltIconMouseEnter"
      @mouseleave="handleAltIconMouseLeave"
    >
      <DSIcon
        :color="alternateIconColor"
        :icon="alternateIcon"
      />
    </div>
    <div
      v-else
      :class="fieldClasses"
      @click="handleButtonClick"
    >
      <DSTextElement
        :color="fieldColor"
        variant="medium"
      >
        {{ fieldText }}
      </DSTextElement>
      <DSIcon
        :icon="icon"
        class="ds-select__field-arrow"
      />
    </div>

    <div
      v-show="isDropdownOpened"
      ref="dropdown"
      :class="dropDownClasses"
    >
      <PerfectScrollbar
        ref="scrollbar"
        class="ds-select__option-list-wrapper"
        @ps-scroll-y="handleScrollY"
        @touchmove.stop
      >
        <slot
          name="before-list"
        />

        <ul
          class="ds-select__option-list"
        >
          <li
            v-for="option in offsetList"
            :key="option[idField]"
            :class="optionItemClasses(option)"
            @click="handleOptionClick(option)"
          >
            <template
              v-if="isListItemSlot"
            >
              <slot
                :option="option"
                name="list-item"
              />
            </template>
            <div
              v-if="!isListItemSlot"
              class="ds-select__option"
            >
              <DSTextElement>
                {{ option[titleField] }}
              </DSTextElement>
            </div>
          </li>
        </ul>

        <slot
          name="after-list"
        />
      </PerfectScrollbar>
    </div>
  </div>
</template>

<script>
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';

export default {
  name: 'DSSelect',
  components: {
    DSTextElement,
    DSIcon,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: 'default',
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      required: true,
    },
    titleField: {
      type: String,
      default: 'title',
    },
    idField: {
      type: String,
      default: 'id',
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    alternateIcon: {
      type: String,
      default: 'arrowOppositelyDirected',
    },
    offset: {
      type: Number,
      default: null,
    },
    borderless: {
      type: Boolean,
      default: () => false,
    },
    paddingless: {
      type: Boolean,
      default: () => false,
    },
    size: {
      type: String, // @TODO перейти на EDSSelectSize
      default: () => 'md',
    },
    dropdownPosition: {
      type: String, // @TODO перейти на EDSSelectDropdownPosition
      default: () => 'bottom',
    },
    transparent: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isDropdownOpened: false,
      isAlternateIconHovered: false,
      isOnTop: false,
      isOnLeft: false,
      isOnRight: false,
      selectedOption: {},
      offsetList: [],
      currentOffset: null,
    };
  },
  computed: {
    classes() {
      return [
        'ds-select',
        this.isDropdownOpened ? 'ds-select_open' : null,
      ];
    },
    fieldText() {
      return this.selectedOption[this.titleField]
        ? this.selectedOption[this.titleField]
        : this.placeholder;
    },
    fieldClasses() {
      return [
        'ds-select__field',
        `ds-select__field_${this.status}`,
        `ds-select__field_size_${this.size}`,
        this.selectedOption[this.titleField] ? 'ds-select__field_filled' : 'ds-select__field_empty',
        this.borderless ? 'ds-select__field_borderless' : null,
        this.paddingless ? 'ds-select__field_paddingless' : null,
        this.transparent ? 'ds-select__field_transparent' : null,
      ];
    },
    fieldColor() {
      return this.selectedOption[this.titleField] ? 'primary' : 'disabled';
    },
    dropdownPositionClass() {
      let positionY = this.dropdownPosition;

      if (positionY === 'bottom') {
        positionY = this.isOnTop ? 'top' : 'bottom';
      }
      // @TODO добавить проверку достаточности места сверху над селектом при dropdownPosition = top
      return `ds-select__dropdown_position_${positionY}`;
    },
    dropDownClasses() {
      return [
        'ds-select__dropdown',
        this.dropdownPositionClass,
        this.isOnLeft ? 'ds-select__dropdown_position_left' : null,
      ];
    },
    id() {
      return `ds-select-id-${this._uid}`;
    },
    icon() {
      return this.isDropdownOpened ? 'arrowUp' : 'arrowDown';
    },
    alternateIconColor() {
      return this.isDropdownOpened || this.isAlternateIconHovered ? 'primary' : 'surface-icons';
    },
    isListItemSlot() {
      return !!this.$scopedSlots['list-item'];
    },
  },
  watch: {
    value() {
      this.selectedOption = this.value;
    },
    list() {
      this.initOffsetList();
      if (this.isDropdownOpened === true) {
        this.$nextTick(() => {
          this.isEnoughBottomSpace();
          this.isEnoughLeftSpace();
        });
      }
    },
  },
  mounted() {
    this.currentOffset = this.offset;
    this.initOffsetList();

    this.selectedOption = this.value;
  },
  methods: {
    optionItemClasses(option) {
      return [
        'ds-select__option-item',
        `ds-select__option-item_${this.selectedOption[this.idField] === option[this.idField] ? 'active' : 'inactive'}`,
      ];
    },
    handleClickOutside() {
      this.isDropdownOpened = false;
    },
    handleAltIconMouseEnter() {
      this.isAlternateIconHovered = true;
    },
    handleAltIconMouseLeave() {
      this.isAlternateIconHovered = false;
    },
    handleButtonClick() {
      this.isDropdownOpened = !this.isDropdownOpened;
      if (this.isDropdownOpened) {
        this.$nextTick(() => {
          this.isEnoughBottomSpace();
          this.isEnoughLeftSpace();
        });
      }
    },
    isEnoughBottomSpace() {
      this.isOnTop = false;

      this.$nextTick(() => {
        const componentRect = this.$refs.dropdown.getBoundingClientRect();

        this.isOnTop = document.body.offsetHeight < componentRect.bottom;
      });
    },
    isEnoughLeftSpace() {
      this.isOnLeft = false;

      this.$nextTick(() => {
        const componentRect = this.$refs.dropdown.getBoundingClientRect();
        this.isOnLeft = document.body.offsetWidth < componentRect.right;
      });
    },
    handleOptionClick(option) {
      this.selectedOption = option;
      this.isDropdownOpened = false;

      this.$emit('input', option);
      this.$emit('blur', option);
    },
    handleScrollY() {
      if (this.$refs.scrollbar.ps.reach.y === 'end') {
        this.initOffsetList();
        this.$emit('scroll-y-reach-end');
      }
    },
    initOffsetList() {
      if (this.currentOffset) {
        const increasedOffset = this.currentOffset + this.offset;
        this.currentOffset = increasedOffset <= this.list.length ? increasedOffset : this.list.length;

        this.offsetList = this.list.slice(0, this.currentOffset);
      } else {
        this.offsetList = [...this.list];
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import "@/module/design-system/components/variables.scss";

.ds-select {
  position: relative;

  &_open &__icon {
    background-color: $button-bg-color-secondary-hover;
  }

  &__select {
    position: absolute;
    z-index: -10;
    overflow: hidden;
    width: 0;
    height: 0;
    border: none;
    outline: none;

    &:hover + .ds-select__field {
      &_default {
        border-color: $field-border-color-primary-hover;
      }

      &_error {
        border-color: $field-border-color-error-hover;
      }

      &_success {
        border-color: $field-border-color-success-hover;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    cursor: pointer;
    transition: all linear .2s;

    &:hover {
      background-color: $button-bg-color-secondary-hover;
    }
  }

  &__field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    font-family: $text-font-ibm-plex-sans;
    font-size: $text-size-regular;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    border: 2px solid $field-border-color-primary;
    border-radius: 8px;
    outline: none;
    background-color: $surface-bg-color-regular;
    cursor: pointer;
    transition: all linear .2s;

    &_size {
      &_md {
        height: 48px;
        padding: 0 14px;
      }

      &_xxs {
        width: min-content;
        height: 21px;
      }
    }

    &.ds-select__field_paddingless {
      padding: 0;
    }

    &.ds-select__field_borderless {
      border: none;
    }

    &.ds-select__field_transparent {
      background-color: transparent;
    }

    &_filled {
      color: $text-color-primary;
    }

    &_empty {
      color: $text-color-disabled;
    }

    &_default {
      &:hover {
        border-color: $field-border-color-primary-hover;
      }

      &:focus {
        border-color: $field-border-color-primary-focus;
      }
    }

    &_error {
      border-color: $field-border-color-error;

      &:hover {
        border-color: $field-border-color-error-hover;
      }

      &:focus {
        border-color: $field-border-color-error-focus;
      }
    }

    &_success {
      border-color: $field-border-color-success;

      &:hover {
        border-color: $field-border-color-success-hover;
      }

      &:focus {
        border-color: $field-border-color-success-focus;
      }
    }

    &_disabled {
      pointer-events: none;
      border-color: $field-border-color-disabled;
    }
  }

  &__dropdown {
    position: absolute;
    z-index: 6;
    width: 100%;
    min-width: min-content;
    padding: 12px 6px;
    border: 1px solid $surface-stroke-color;
    border-radius: 8px;
    background-color: $surface-bg-color-regular;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);

    &_position {
      &_top {
        bottom: 100%;
        margin-bottom: 8px;
      }

      &_bottom {
        top: 100%;
        margin-top: 8px;
      }

      &_left {
        right: 100%;
        transform: translateX(50%);
        margin-right: 16px;
      }
    }
  }

  &__option-list-wrapper {
    max-height: 240px;
  }

  &__option {
    padding: 12px;
  }

  &__option-list {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  &__option-item {
    border-radius: 8px;
    cursor: pointer;
    gap: 4px;

    &:hover {
      background-color: $button-bg-color-secondary-hover;
    }
  }
}
</style>
