import IFulfillmentBackendModelToFormDataMapper
  from '@/module/product/view/FulfillmentFormPage/model/contract/mapper/IFulfillmentBackendModelToFormDataMapper';
import IFulfillmentBackendModel
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/IFulfillmentBackendModel';
import IFormDataTestCreateGenerator
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataTestCreateGenerator';
import IFormDataSalesCreateGenerator
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataSalesCreateGenerator';
import IState from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/IState';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EHTTPMethod from '@/module/common/models/EHTTPMethod';
import EFulfillmentAllsoftHTTPMethod
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/EFulfillmentAllsoftHTTPMethod';
import ICInputWithSelectListValue from '@/module/common/models/UI/CInputWithSelectList/ICInputWithSelectListValue';
import IFulfillmentParametersBackendModel
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/IFulfillmentParametersResponseModel';
import IGeneratorMockService
  from '@/module/product/view/FulfillmentFormPage/model/contract/service/IGeneratorMockService';
import EGeneratorType from '@/module/product/view/FulfillmentFormPage/model/enums/EGeneratorType';
import ILocalServiceContainer from '@/module/product/view/FulfillmentFormPage/model/ILocalServiceContainer';
import ELocalServiceFulfillment from '@/module/product/view/FulfillmentFormPage/model/enums/ELocalServiceFulfillment';
import IHandlerService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IHandlerService';
import ILicenseAmountService
  from '@/module/product/view/FulfillmentFormPage/model/contract/service/ILicenseAmountService';
import TFormData from '@/module/product/view/FulfillmentFormPage/model/types/TFormData';
import IFormDataTestLicensePool
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataTestLicensePool';
import IFormDataSalesLicensePool
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataSalesLicensePool';
import IFormDataSalesOther
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataSalesOther';

class FulfillmentBackendModelToFormDataMapper implements IFulfillmentBackendModelToFormDataMapper {
  private readonly state: IState;

  private readonly serviceContainer: IServiceContainer;

  private readonly localServiceContainer: ILocalServiceContainer;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    localServiceContainer: ILocalServiceContainer,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.localServiceContainer = localServiceContainer;
  }

  map = (data: IFulfillmentBackendModel): TFormData => {
    const generatorMockService = this.localServiceContainer
      .resolve<IGeneratorMockService>(ELocalServiceFulfillment.GENERATOR_MOCK_SERVICE);
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const licenseAmountService = this.localServiceContainer
      .resolve<ILicenseAmountService>(ELocalServiceFulfillment.LICENSE_AMOUNT_SERVICE);
    const licenseVariantHandler = handlerService.getLicenseVariantHandler();
    const dataMock = generatorMockService.getMock();

    let generatorType = data.type;
    let licenseSource = licenseVariantHandler.getByGeneratorType(generatorType);

    let formData: TFormData = {
      id: data.id,
    } as TFormData;

    if (generatorType === EGeneratorType.WEB_SERVICE) {
      if (
        data.webService.url === dataMock.webService.url
        && data.webService.method === dataMock.webService.method
        && data.webService.sendToCustomer === dataMock.webService.sendToCustomer
        && data.webService.secretKey === dataMock.webService.secretKey
        && data.webService.quantityKeysItemsMatches === dataMock.webService.quantityKeysItemsMatches
        && (!data.webService.parameters || data.webService.parameters.length === 0)
      ) {
        generatorType = EGeneratorType.TEST_WEB_SERVICE;
        licenseSource = licenseVariantHandler.getByGeneratorType(generatorType);
      }

      const methodMap = {
        [EFulfillmentAllsoftHTTPMethod.POST_JSON]: EHTTPMethod.POST,
        [EFulfillmentAllsoftHTTPMethod.GET]: EHTTPMethod.GET,
      };

      formData = {
        ...formData,
        url: data.webService.url,
        method: {
          id: methodMap[data.webService.method],
          title: methodMap[data.webService.method].toUpperCase(),
        },
        secretKey: data.webService.secretKey,
        licenseAmount: licenseAmountService.get(data.webService.quantityKeysItemsMatches),
        sendLicense: data.webService.sendToCustomer,
        messageToUser: data.webService.sendToCustomer ? data.customerNotification : '',
        parameters: this.mapParameters(data),
      } as IFormDataTestCreateGenerator | IFormDataSalesCreateGenerator;
    }

    if (generatorType === EGeneratorType.LICENSE_POOL) {
      formData = {
        ...formData,
        messageToManager: '',
      } as IFormDataTestLicensePool | IFormDataSalesLicensePool;
    }

    if (generatorType === EGeneratorType.INFORMATIONAL_EMAIL) {
      formData = {
        ...formData,
        messageToManager: '',
      } as IFormDataSalesOther;
    }

    formData = {
      ...formData,
      licenseSource,
    };

    return formData;
  };

  private mapParameters = (data: IFulfillmentBackendModel): ICInputWithSelectListValue[] => {
    if (!data.webService.parameters) {
      return [];
    }

    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const formConfigHandler = handlerService.getFormConfigHandler();

    return data.webService.parameters.map((parameter, index) => {
      const selectValue = this.state.lists.params
        .find((parameterItem) => parameterItem.key === parameter.value) ?? {} as IFulfillmentParametersBackendModel;

      return {
        id: index + 1,
        ...formConfigHandler.getParamsConfig(),
        value: {
          inputValue: parameter.name ?? selectValue.default ?? '',
          selectValue,
        },
      };
    });
  };
}

export default FulfillmentBackendModelToFormDataMapper;
