import THandleFieldBlurParams from '@/module/product/view/ProductFormPage/model/THandleFieldBlurParams';
import EPricingModelName from '@/module/product/model/Product/Fields/PricingModel/EPricingModelName';
import BaseFieldBlurScenario from '@/module/product/view/ProductFormPage/scenario/BaseFieldBlurScenario';
import EFieldName from '@/module/product/view/ProductFormPage/model/EFieldName';
import TPricingTableValue from '@/module/product/model/TPricingTableValue';

class PriceBlurScenario extends BaseFieldBlurScenario {
  run = (item: THandleFieldBlurParams) => {
    const productFormPage = this.serviceContainer.moduleProduct.productFormPage;
    const formDataHandler = productFormPage.handlerService.formDataHandler;
    const utilsService = this.serviceContainer.utilsService;
    const pricingTableHelper = this.serviceContainer.moduleProduct.pricingTableHelper;

    this.updateFromData(item);
    let formData = utilsService.data.cloneImmutable(formDataHandler.get());

    if (formData.pricingModel.id === EPricingModelName.ONE_PRICE) {
      productFormPage.handlerService.validateHandler.validateField(item.field.fieldName, formData);

      this.updateFromConfig();

      return;
    }

    if (item.field.fieldName !== EFieldName.PRICE) {
      productFormPage.handlerService.validateHandler.validateField(item.field.fieldName, formData);

      this.updateFromConfig();

      return;
    }

    const value = item.value as TPricingTableValue;
    const renewalPrice:TPricingTableValue = formData.renewalPrice as TPricingTableValue;
    if (
      pricingTableHelper.isVolumePrice(value)
      && pricingTableHelper.isVolumePrice(renewalPrice)
      && value.value.columns.length !== renewalPrice.value.columns.length
    ) {
      formData = pricingTableHelper.cloneRenewalFromBasePrice(formData);
    } else if (
      pricingTableHelper.isVolumePriceAndOptions(value)
      && pricingTableHelper.isVolumePriceAndOptions(renewalPrice)
      && value.value.length !== renewalPrice.value.length
    ) {
      formData = pricingTableHelper.cloneRenewalFromBasePrice(formData);
    }

    productFormPage.handlerService.validateHandler.validateField(item.field.fieldName, formData);

    formDataHandler.update(formData);
    this.updateFromConfig();
  };
}

export default PriceBlurScenario;
