var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-table"},[(_vm.products.length)?_c('div',{staticClass:"product-table__row product-table__head"},[_c('p',{staticClass:"secondary"},[_vm._v(" "+_vm._s(_vm.$t('product.product_table.titlesubtitle').toUpperCase())+" ")]),_c('p',{staticClass:"secondary"},[_vm._v(" "+_vm._s(_vm.$t('product.product_table.id').toUpperCase())+" ")]),_c('p',{staticClass:"secondary"},[_vm._v(" "+_vm._s(_vm.$t('product.product_table.sku').toUpperCase())+" ")]),_c('p',{staticClass:"secondary"},[_vm._v(" "+_vm._s(_vm.$t('product.product_table.last_edit').toUpperCase())+" ")])]):_vm._e(),_vm._l((_vm.products),function(item,idx){return _c('div',{key:item.product.id,staticClass:"product-table__row",on:{"click":(event) => _vm.handleRedirectToProductCard(event, item.product.id, idx)}},[_c('p',{staticClass:"product-table__name"},[_vm._v(" "+_vm._s(item.product.title)+" "+_vm._s(item.product.subtitle)+" ")]),_c('p',{staticClass:"product-table__id"},[_vm._v(" "+_vm._s(item.product.id)+" ")]),_c('p',{staticClass:"product-table__sku"},[_vm._v(" "+_vm._s(_vm.cutExtraLines(item.product.sku).visible || '-')+" "),(_vm.cutExtraLines(item.product.sku).howMore)?_c('span',{staticClass:"secondary"},[_vm._v(" "+_vm._s(_vm.cutExtraLines(item.product.sku).howMore)+" ")]):_vm._e()]),_c('p',{staticClass:"product-table__update-date"},[_vm._v(" "+_vm._s(_vm.utilsService.date.formatSkipCurrentYearWithoutWeekDay(item.product.updateDate))+" ")]),_c('ButtonMore',{ref:"buttonMoreRef",refInFor:true,staticClass:"product-table__button-more",attrs:{"buttons":[
        {
          name: 'product.product_card.edit',
          handler: () => _vm.handleRedirectToEdit(item.product.id),
        },
        {
          name: 'product.product_card.delete',
          handler: () => _vm.handleDeleteProduct(item.product.id),
        },
        {
          name: 'product.product_card.copy_buy_link',
          handler: () => _vm.handleCopyBuyLink(item.buylink),
        },
      ]}})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }