import moment from 'moment';

function prepareData(payload) {
  const newData = payload;

  if (payload.discountModel.discountPercent) {
    newData.discountModel.discountPercent = payload.discountModel.discountPercent.split('%')[0];
  } else {
    newData.discountModel.products = payload.discountModel.products
      .map((el) => ({ ...el, discountPercent: el.discountPercent.split('%')[0] }));
  }

  newData.dateFrom = moment(newData.dateFrom).format();
  if (newData.dateTo) {
    newData.dateTo = moment(newData.dateTo).format();
  }

  return newData;
}

export default function createEditPromotionModule(instance) {
  return {
    getProductsDropdown({
      offset = 0, limit = 10, searchString = '', sortByUpdateDate = 'desc',
    }) {
      const search = searchString ? `&searchString=${searchString}` : '';
      return instance
        .get(`/vendor/promotion/product?offset=${offset}&limit=${limit}&sortByUpdateDate=${sortByUpdateDate}${search}`);
    },
    create(payload) {
      return instance.post('/vendor/promotion', prepareData(payload));
    },
    getCodes(amount) {
      return instance.get(`/vendor/promotion/coupon/code?couponsAmount=${amount}`);
    },
    getPromotion(promotionId) {
      return instance.get(`/vendor/promotion/${promotionId}`);
    },
    updatePromotion(promotionId, payload) {
      return instance.put(`/vendor/promotion/${promotionId}`, prepareData(payload));
    },
  };
}
