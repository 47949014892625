import IApiKeysModel from '@/module/developers/models/IApiKeysModel';
import IDSLinkConfig from '@/module/common/models/Text/IDSLinkConfig';
import IDSTextElementConfig from '@/module/common/models/Text/IDSTextElementConfig';
import ETextVariants from '@/module/common/models/ETextVariants';
import IBasePageLocalisation from '@/module/common/utils/localisation/models/IBasePageLocalisation';
import ICStatusWindowComponentConfig from '@/module/common/models/ICStatusWindowComponentConfig';
import IButtonConfig from '@/module/common/models/helpers/getButton/IButtonConfig';
import {
  reactive,
} from 'vue';
import IRESTApi from '@/module/common/service/RESTApi/contract/IRESTApi';
import IVueInstance from '@/module/common/models/IVueInstance';
import mapObjectSnakeKeysToCamelCaseRecursive
  from '@/module/common/utils/object/mapObjectSnakeKeysToCamelCaseRecursive';
import EMerchantMode from '@/module/common/models/EMerchantMode';
import ENotifyVariant from '@/module/design-system/components/models/UI/ENotifyVariant';

const rootLocalisationKey = 'pages.developers.api_keys';

enum EApiKeyMode {
  NEW = 'new',
  REROLLED = 'rerolled',
}

interface IBaseApiKeyPreviewModal {
  title: string;
  blocks: {
    login?: {
      title: string;
    };
    keepInSafe: {
      title: string;
      text: string;
    };
    password: {
      title: string;
    };
  },
}

interface IApiKeyPreviewModal extends IBaseApiKeyPreviewModal {
  cancelHandler: () => void;
  copyHandler: () => void;
  buttons: {
    done: IButtonConfig;
  };
}

interface ILocalisationItemInfoBlock {
  link: string;
  title: string;
  text: string;
}

interface ILocalisationLoginApiKeyBlock {
  title: string;
  buttons: {
    done: string;
  };
  blocks: {
    login?: {
      title: string;
    };
    keep_in_safe: {
      title: string;
      text: string;
    };
    password: {
      title: string;
    };
  };
}

interface ILocalisationApiKeys extends IBasePageLocalisation {
  error: {
    something_went_wrong: string,
  },
  notify: {
    password_copied: string;
  };
  buttons: {
    how_it_works: string;
    how_it_works_link: string;
  };
  test_mode_alert: {
    title: string;
    text: string;
  }
  banner: {
    title: string;
    subtitle: string;
    buttons: {
      create_password: {
        [key in EMerchantMode]: string;
      };
    };
  };
  info_block: {
    title: string;
    items: ILocalisationItemInfoBlock[];
  };
  login_password: {
    new: ILocalisationLoginApiKeyBlock;
    rerolled: ILocalisationLoginApiKeyBlock;
  };
  roll_password: {
    password: string,
    buttons: {
      roll_password: {
        [key in EMerchantMode]: string;
      };
    };
    modal: {
      title: string;
      text: string;
      buttons: {
        continue: string;
        cancel: string;
      }
    }
  }
}

interface IStateApiKeysPage {
  apiKeysListForPreview: IApiKeysModel[],
  apiKeyForUpdate: IApiKeysModel | null,
  apiKeyAfterGenerate: IApiKeysModel | null,
  apiKeyLoadMode: EApiKeyMode | null,
  loading: boolean,
}

interface IConfirmRollModal {
  title: string;
  text: string;
  cancelHandler: () => void;
  buttons: {
    continue: IButtonConfig;
    cancel: IButtonConfig;
  };
}

interface IInfoBlockItem {
  link: IDSLinkConfig,
  text: IDSTextElementConfig,
}

interface IInfoBlock {
  title: string;
  items: IInfoBlockItem[];
}

interface IMerchantModeAlert {
  visible: boolean;
  variant: string;
  title: string;
  subtitle: string;
}

interface IStatusWindowButtons {
  create: IButtonConfig,
  howItWorks: IButtonConfig,
}

interface IStatusWindow {
  config: ICStatusWindowComponentConfig;
  buttons: IStatusWindowButtons,
}

function openConfirmRollModal(state: IStateApiKeysPage, apiKey: IApiKeysModel) {
  state.apiKeyForUpdate = apiKey;
}

function openHowItWorksLink(localisation: ILocalisationApiKeys) {
  if (localisation.buttons.how_it_works_link.length > 0) {
    window.open(localisation.buttons.how_it_works_link);
  }
}

function closeApiKeyPreviewModal(state: IStateApiKeysPage) {
  state.apiKeyLoadMode = null;
  state.apiKeyAfterGenerate = null;
}

function closeConfirmRollModal(state: IStateApiKeysPage) {
  state.apiKeyForUpdate = null;
}

function getCreateButtonText(merchantMode: EMerchantMode, localisation: ILocalisationApiKeys): string {
  return localisation.banner.buttons.create_password[merchantMode];
}

async function refreshApiKeys(
  state: IStateApiKeysPage,
  api: IRESTApi<IApiKeysModel> | undefined,
  vueInstance: IVueInstance | undefined,
  localisation: ILocalisationApiKeys,
) {
  if (!api) {
    return;
  }

  try {
    const response = await api.list();
    state.apiKeysListForPreview = response?.data ?? [];
  } catch (e) {
    vueInstance?.$store?.dispatch(
      'notificationsStore/pushNotification',
      {
        text: localisation.error.something_went_wrong,
        type: ENotifyVariant.INFO,
      },
    );
  }
}

async function updateApiKeyHandler(
  state: IStateApiKeysPage,
  api: IRESTApi<IApiKeysModel> | undefined,
  vueInstance: IVueInstance | undefined,
  localisation: ILocalisationApiKeys,
) {
  if (!api) {
    return;
  }

  if (state.apiKeysListForPreview.length > 0) {
    state.apiKeysListForPreview = [
      {
        ...state.apiKeysListForPreview[0] ?? {},
        password: undefined,
        id: undefined,
        updatedAt: undefined,
      } as IApiKeysModel,
    ];
  } else {
    state.apiKeysListForPreview = [];
  }

  try {
    if (state.apiKeyForUpdate) {
      const { id, login } = state.apiKeyForUpdate;
      const response = await api.update(id as number, { login });
      state.apiKeyAfterGenerate = response.data;

      state.apiKeyLoadMode = EApiKeyMode.REROLLED;

      closeConfirmRollModal(state);

      await refreshApiKeys(state, api, vueInstance, localisation);
    }
  } catch (e) {
    vueInstance?.$store?.dispatch(
      'notificationsStore/pushNotification',
      {
        text: localisation.error.something_went_wrong,
        type: ENotifyVariant.INFO,
      },
    );
  }
}

async function createApiKeyHandler(
  state: IStateApiKeysPage,
  api: IRESTApi<IApiKeysModel> | undefined,
  vueInstance: IVueInstance | undefined,
  localisation: ILocalisationApiKeys,
) {
  if (!api) {
    return;
  }

  try {
    const response = await api.create();
    state.apiKeyAfterGenerate = response.data;

    state.apiKeyLoadMode = EApiKeyMode.NEW;

    await refreshApiKeys(state, api, vueInstance, localisation);
  } catch (e) {
    vueInstance?.$store?.dispatch(
      'notificationsStore/pushNotification',
      {
        text: localisation.error.something_went_wrong,
        type: ENotifyVariant.INFO,
      },
    );
  }
}

function initState(): IStateApiKeysPage {
  return reactive({
    apiKeysListForPreview: [],
    apiKeyForUpdate: null,
    apiKeyAfterGenerate: null,
    apiKeyLoadMode: null,
    loading: true,
  });
}

function initInfoBlock(localisation: ILocalisationApiKeys): IInfoBlock {
  const items = localisation.info_block.items
    .map((infoBlockItem) => ({
      link: {
        url: infoBlockItem.link,
        title: infoBlockItem.title,
        variant: ETextVariants.MEDIUM,
        isRouterLink: false,
        target: '_blank',
      },
      text: {
        text: infoBlockItem.text,
      },
    }));

  return {
    title: localisation.info_block.title,
    items,
  };
}

function initMerchantModeAlert(localization: ILocalisationApiKeys): IMerchantModeAlert {
  return reactive({
    visible: true,
    variant: 'accent',
    title: localization.test_mode_alert.title,
    subtitle: localization.test_mode_alert.text,
  });
}

function initStatusWindow(buttons: IStatusWindowButtons, localisation: ILocalisationApiKeys): IStatusWindow {
  return {
    config: {
      title: localisation.banner.title,
      subtitle: localisation.banner.subtitle,
    },
    buttons,
  };
}

function initApiKeyPreviewModal(
  localisation: ILocalisationApiKeys,
  state: IStateApiKeysPage,
  handlers: {
    cancelHandler: () => void,
    copyHandler: () => void,
  },
  buttons: {
    done: IButtonConfig,
  },
): IApiKeyPreviewModal {
  const blocks = mapObjectSnakeKeysToCamelCaseRecursive<
    ILocalisationLoginApiKeyBlock,
    IBaseApiKeyPreviewModal
    >(localisation.login_password[state.apiKeyLoadMode as EApiKeyMode]) as IBaseApiKeyPreviewModal;

  return {
    ...blocks,
    ...handlers,
    buttons,
  };
}

function initConfirmRollModal(
  localisation: ILocalisationApiKeys,
  cancelHandler: () => void,
  buttons: {
    cancel: IButtonConfig,
    continue: IButtonConfig,
  },
): IConfirmRollModal {
  return {
    buttons,
    cancelHandler,
    text: localisation.roll_password.modal.text,
    title: localisation.roll_password.modal.title,
  };
}

function copyApiKeyHandler(localisation: ILocalisationApiKeys, vueInstance: IVueInstance | undefined) {
  vueInstance?.$store?.dispatch(
    'notificationsStore/pushNotification',
    {
      title: localisation.notify.password_copied,
      component: 'DSAlert',
      type: ENotifyVariant.INFO,
    },
  );
}

export default initState;

export {
  rootLocalisationKey,
  createApiKeyHandler,
  refreshApiKeys,
  initInfoBlock,
  initMerchantModeAlert,
  initStatusWindow,
  initApiKeyPreviewModal,
  initConfirmRollModal,
  updateApiKeyHandler,
  closeApiKeyPreviewModal,
  closeConfirmRollModal,
  openConfirmRollModal,
  copyApiKeyHandler,
  openHowItWorksLink,
  getCreateButtonText,
  ILocalisationApiKeys,
  IStateApiKeysPage,
  IInfoBlockItem,
  IApiKeyPreviewModal,
  IBaseApiKeyPreviewModal,
  EApiKeyMode,
};
