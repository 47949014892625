// eslint-disable-next-line max-len
import IFormDataMapper from '@/module/merchant/view/AccountActivatePage/DataMapper/IFormDataMapper';
import IFormFieldItem from '@/module/design-system/components/models/FormComponent/IFormFieldItem';
import cloneImmutable from '@/module/common/utils/data/cloneImmutable';
import EFieldName from '@/module/merchant/view/AccountActivatePage/models/EFieldName';
import EFieldBlock from '@/module/merchant/view/AccountActivatePage/models/EFieldBlock';
import TFormData
  from '@/module/merchant/view/AccountActivatePage/models/FormData/TFormData';
import TFromDataDraft
  from '@/module/merchant/view/AccountActivatePage/models/Draft/TFromDataDraft';
import IBusinessType from '@/module/merchant/models/BuisnessType/IBusinessType';
import IKontragentioService from '@/module/merchant/view/AccountActivatePage/service/contract/IKontragentioService';
import IFormConfigHandler
  from '@/module/merchant/view/AccountActivatePage/Handler/contract/IFormConfigHandler';

class FormDataKontragentioClearMapper implements IFormDataMapper {
  private readonly formConfigHandler: IFormConfigHandler;

  private readonly kontragentioService: IKontragentioService;

  constructor(
    formConfigHandler: IFormConfigHandler,
    kontragentioService: IKontragentioService,
  ) {
    this.formConfigHandler = formConfigHandler;
    this.kontragentioService = kontragentioService;
  }

  // eslint-disable-next-line class-methods-use-this
  mapFieldValue(
    formFieldConfigItem: IFormFieldItem<EFieldName, EFieldBlock>,
    data: TFormData | TFromDataDraft,
  ) {
    const clearFieldsMap = this.kontragentioService
      .getFieldsByBusinessType((data.businessStructure.businessType as IBusinessType).id);

    const formData = cloneImmutable(data);
    Object.keys(clearFieldsMap).forEach((fieldBlock) => {
      clearFieldsMap[fieldBlock].forEach((fieldName) => {
        if (
          fieldName === EFieldBlock.BUSINESS_REPRESENTATIVE
          && fieldName === EFieldName.DATE_OF_BIRTH
        ) {
          formData[fieldBlock][fieldName] = this.formConfigHandler.getMaxDateOfBirth();
        } else {
          formData[fieldBlock][fieldName] = '';
        }
      });
    });

    return formData;
  }
}

export default FormDataKontragentioClearMapper;
