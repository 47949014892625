import IFormDataHandler from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/contract/IFormDataHandler';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IFormData from '@/module/product/view/PaymentLink/PaymentLinkFormPage/model/interface/IFormData';

class FormDataHandler implements IFormDataHandler {
  private serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  get = (): IFormData => {
    const utilsService = this.serviceContainer.utilsService;
    const paymentLinkFormPage = this.serviceContainer.moduleProduct.paymentLinkFormPage;

    return utilsService.data.cloneImmutable(paymentLinkFormPage.stateHandler.get().formData);
  };

  update = (formData: IFormData): IFormData => {
    const utilsService = this.serviceContainer.utilsService;
    const paymentLinkFormPage = this.serviceContainer.moduleProduct.paymentLinkFormPage;
    const state = paymentLinkFormPage.stateHandler.get();

    state.formData = utilsService.data.cloneImmutable(formData);

    return state.formData;
  };
}

export default FormDataHandler;
