<template>
  <svg
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :stroke="color"
      clip-rule="evenodd"
      d="M9 3H15V9H18.034C18.1132 8.99994 18.1906 9.02336 18.2564 9.06731C18.3222 9.11126 18.3735 9.17376 18.4038 9.24688C18.4341 9.32001 18.4421 9.40049 18.4266 9.47812C18.4112 9.55576 18.373 9.62706 18.317 9.683L12.283 15.717C12.2459 15.7542 12.2017 15.7837 12.1532 15.8038C12.1046 15.824 12.0526 15.8343 12 15.8343C11.9474 15.8343 11.8954 15.824 11.8468 15.8038C11.7983 15.7837 11.7541 15.7542 11.717 15.717L5.683 9.683C5.627 9.62706 5.58885 9.55576 5.57339 9.47812C5.55793 9.40049 5.56585 9.32001 5.59615 9.24688C5.62645 9.17376 5.67777 9.11126 5.74361 9.06731C5.80944 9.02336 5.88684 8.99994 5.966 9H9V3ZM6 18H18C18.2652 18 18.5196 18.1054 18.7071 18.2929C18.8946 18.4804 19 18.7348 19 19C19 19.2652 18.8946 19.5196 18.7071 19.7071C18.5196 19.8946 18.2652 20 18 20H6C5.73478 20 5.48043 19.8946 5.29289 19.7071C5.10536 19.5196 5 19.2652 5 19C5 18.7348 5.10536 18.4804 5.29289 18.2929C5.48043 18.1054 5.73478 18 6 18Z"
      fill-rule="evenodd"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSDownloadIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>
