import EMerchantMode from '@/module/common/models/EMerchantMode';
import getLocalStorageItem from '@/module/common/utils/localStorage/getLocalStorageItem';
import setLocalStorageItem from '@/module/common/utils/localStorage/setLocalStorageItem';

const storageKey = 'merchantModeAlertStatuses';

enum EPage {
  DASHBOARD = 'dashboard',
  PRODUCTS = 'products',
  PROMOTIONS = 'promotions',
  CHECKOUT_LINKS = 'checkoutLinks',
  WEBHOOKS = 'webhooks',
  API_KEYS = 'apiKeys',
  PAYMENT_LINKS = 'paymentLinks',
}

enum EMerchantModeAlertStatus {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
}

type TMerchantModeAlertStatuses = {
  [key in EPage]?: EMerchantModeAlertStatus;
}

function getIsMerchantModeAlertVisible(
  page: EPage,
  merchantMode:EMerchantMode,
): boolean {
  if (merchantMode === EMerchantMode.SALES) {
    return false;
  }

  const merchantModeAlertStatuses: TMerchantModeAlertStatuses = getLocalStorageItem(
    storageKey,
    {},
  );

  if (merchantModeAlertStatuses !== null && merchantModeAlertStatuses[page] !== undefined) {
    return merchantModeAlertStatuses[page] === EMerchantModeAlertStatus.VISIBLE;
  }

  return true;
}

function setMerchantModeAlertStatus(page: EPage, status: EMerchantModeAlertStatus) {
  const merchantModeAlertStatuses = getLocalStorageItem(storageKey, {});

  merchantModeAlertStatuses[page] = status;

  setLocalStorageItem(storageKey, merchantModeAlertStatuses);
}

function clearMerchantModeAlert() {
  localStorage.removeItem(storageKey);
}

export {
  getIsMerchantModeAlertVisible,
  setMerchantModeAlertStatus,
  clearMerchantModeAlert,
  EPage,
  EMerchantModeAlertStatus,
};
