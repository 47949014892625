import LocalisationRegistry from '@/module/common/utils/localisation/LocalisationRegistry';
import { Store } from 'vuex';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';

export default function initLocalisationRegistry(serviceContainer: IServiceContainer, store: Store<unknown>) {
  const localisationRegistry = new LocalisationRegistry(serviceContainer);

  serviceContainer.register<ILocalisationRegistry>(
    EServiceContainerInstances.LOCALISATION_REGISTRY,
    localisationRegistry,
  );

  store.dispatch('changeLocalisationRegistry', localisationRegistry);
}
