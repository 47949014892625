import isArray from '@/module/common/utils/data/isArray';
import isObject from '@/module/common/utils/data/isObject';

function isArrayEmpty(array: []): boolean {
  return array.length === 0;
}

function isObjectEmpty(object: object): boolean {
  return Object.keys(object).length === 0;
}

export default function isEmpty(value: unknown): boolean {
  if (isArray(value)) {
    return isArrayEmpty(value as []);
  }

  if (isObject(value)) {
    return isObjectEmpty(value as object);
  }

  return !value;
}
