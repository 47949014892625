export default class ParseProductDTO {
  constructor(formDataToEdit) {
    this.formDataToEdit = formDataToEdit;
    this.currencies = ['USD', 'EUR', 'GBP', 'CAD', 'AUD', 'NZD'];
  }

  getMain() {
    const {
      image,
      licenseType,
      title,
      subtitle,
      description,
      availableFor,
      fulfillmentId,
    } = this.formDataToEdit;

    return {
      imageUrl: image,
      licenseType,
      fulfillmentId,
      title,
      subtitle,
      description,
      availableFor,
      ...this.getParsedTerm('licenseTerm', 'licenseTerm'),
    };
  }

  getParsedTerm(termName, parsedTermName) {
    const term = this.formDataToEdit[termName];
    const parsedTerm = {};

    if (termName === 'licenseTerm' && term && term !== '0') {
      parsedTerm[`${parsedTermName}Checkbox`] = true;
    }

    if (term === '0' || !term) {
      parsedTerm[`${parsedTermName}Amount`] = '1';
      parsedTerm[`${parsedTermName}Type`] = 'Month';
      return parsedTerm;
    }
    const amount = term.substring(1, term.length - 1);
    const typeChar = term[term.length - 1];
    let type = 'Month';

    switch (typeChar) {
      case 'Y':
        type = 'Year';
        break;
      case 'D':
        type = 'Day';
        break;
      default:
        break;
    }

    parsedTerm[`${parsedTermName}Amount`] = amount;
    parsedTerm[`${parsedTermName}Type`] = type;

    return parsedTerm;
  }

  getRenewal() {
    const {
      renewalSubtitle,
      renewalTitle,
    } = this.formDataToEdit;

    if (!renewalSubtitle && !renewalTitle) {
      return {
        renewalCheckbox: false,
        renewalSubtitle: '',
        renewalTerm: '',
        renewalTitle: '',
        ...this.getParsedTerm('renewalTerm', 'renewalTerm'),
        renewalPrice: this.getPrice(true),
      };
    }

    return {
      renewalCheckbox: true,
      renewalTitle,
      renewalSubtitle,
      ...this.getParsedTerm('renewalTerm', 'renewalTerm'),
      renewalPrice: this.getPrice(true),
    };
  }

  getTrial() {
    const {
      trialSubtitle,
      trialTitle,
      trialSku,
    } = this.formDataToEdit;

    if (!trialSubtitle && !trialTitle && !trialSku) {
      return {
        trialCheckbox: false,
        trialSku: '',
        trialSubtitle: '',
        trialTitle: '',
        ...this.getParsedTerm('trialTerm', 'trialTerm'),
      };
    }

    return {
      trialSku,
      trialSubtitle,
      trialTitle,
      trialCheckbox: true,
      ...this.getParsedTerm('trialTerm', 'trialTerm'),
    };
  }

  getPrice(renewal = false) {
    const priceType = renewal ? 'renewalPrice' : 'price';
    const variants = this.formDataToEdit[priceType];
    if (variants) {
      if (variants[0].price.common) {
        return variants;
      }

      return variants.map((variant) => {
        Object.values(this.currencies).forEach((currency) => {
          if (!variant.price[currency]) {
            variant.price[currency] = { currency, price: '' };
          }
        });

        return variant;
      });
    }
    if (this.formDataToEdit.price[0].price.common) {
      return this.formDataToEdit.price.map(() => ({
        to: null,
        from: null,
        sku: '',
        price: {
          common: {
            currency: 'USD',
            price: '',
          },
        },
      }));
    }
    return this.formDataToEdit.price.map(() => ({
      to: null,
      from: null,
      sku: '',
      price: {
        USD: { currency: 'USD', price: '' },
        EUR: { currency: 'EUR', price: '' },
        GBP: { currency: 'GBP', price: '' },
        CAD: { currency: 'CAD', price: '' },
        AUD: { currency: 'AUD', price: '' },
        NZD: { currency: 'NZD', price: '' },
      },
    }));
  }

  getEditProduct() {
    const main = this.getMain();
    const renewal = this.getRenewal();
    const trial = this.getTrial();
    const price = this.getPrice();

    return {
      ...main,
      ...renewal,
      ...trial,
      price,
    };
  }
}
