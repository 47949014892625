import ENotifyVariant from '@/module/design-system/components/models/UI/ENotifyVariant';
import ICamelCaseLocalisationCommon
  from '@/module/common/utils/localisation/initialisator/model/ICamelCaseLocalisationCommon';
import IVueInstance from '@/module/common/models/IVueInstance';
import getLocalisationVue2 from '@/module/common/helpers/getLocalisationVue2';
import mapObjectSnakeKeysToCamelCaseRecursive
  from '@/module/common/utils/object/mapObjectSnakeKeysToCamelCaseRecursive';

interface ILocalisationWebhookForm {
  'create_title': string,
  'edit_title': string,
  'cancel': string,
  'save': string,
  'endpoint_field': {
    'label': string,
    'placeholder': string,
    'tooltip': string
  },
  'events_field': {
    'label': string,
    'tooltip': string
  },
  'secret_key_field': {
    'label': string,
    'tooltip': string,
    'placeholder': string
  },
  'how_endpoint_works': {
    'label': string
  },
  'error_message': {
    'required': string,
    'url_max_length': string,
    'secret_key_max_length': string,
    'https_only': string,
    'invalid_format': string
  },
  'popup': {
    'head': {
      'updated': string,
      'created': string,
      'error': string,
      'already_exists': string
    },
    'description': {
      'updated': string,
      'created': string,
      'error': string,
      'already_exists': string
    }
  }
}

interface ICamelCaseLocalisationWebhookForm {
  'createTitle': string,
  'editTitle': string,
  'cancel': string,
  'save': string,
  'endpointField': {
    'label': string,
    'placeholder': string,
    'tooltip': string
  },
  'eventsField': {
    'label': string,
    'tooltip': string
  },
  'secretKeyField': {
    'label': string,
    'tooltip': string,
    'placeholder': string
  },
  'howEndpointWorks': {
    'label': string
  },
  'errorMessage': {
    'required': string,
    'urlMaxLength': string,
    'secretKeyMaxLength': string,
    'httpsOnly': string,
    'invalidFormat': string
  },
  'popup': {
    'head': {
      'updated': string,
      'created': string,
      'error': string,
      'alreadyExists': string
    },
    'description': {
      'updated': string,
      'created': string,
      'error': string,
      'alreadyExists': string
    }
  }
}

function initLocalisation(vueInstance: IVueInstance): ICamelCaseLocalisationWebhookForm {
  let localisation = getLocalisationVue2<ILocalisationWebhookForm>(
    'webhooks.form',
    vueInstance,
  );
  if (!localisation) {
    localisation = {} as ILocalisationWebhookForm;
  }

  return mapObjectSnakeKeysToCamelCaseRecursive<
    ILocalisationWebhookForm,
    ICamelCaseLocalisationWebhookForm
  >(localisation) as ICamelCaseLocalisationWebhookForm;
}

function getButtonName(): string {
  return 'webhookTryAgain';
}

function initCreatedPopUp(localisation: ICamelCaseLocalisationWebhookForm) {
  return {
    title: localisation.popup.head.created,
    text: localisation.popup.description.created,
    type: ENotifyVariant.INFO,
    component: 'DSPopup',
  };
}

function initUpdatedPopUp(localisation: ICamelCaseLocalisationWebhookForm) {
  return {
    title: localisation.popup.head.updated,
    text: localisation.popup.description.updated,
    type: ENotifyVariant.INFO,
    component: 'DSPopup',
  };
}

function initAlreadyExistsPopUp(localisation: ICamelCaseLocalisationWebhookForm) {
  return {
    title: localisation.popup.head.alreadyExists,
    text: localisation.popup.description.alreadyExists,
    type: ENotifyVariant.ERROR,
    component: 'DSPopup',
  };
}

function initErrorPopUp(
  localisation: ICamelCaseLocalisationWebhookForm,
  commonLocalisation: ICamelCaseLocalisationCommon,
) {
  return {
    title: localisation.popup.head.error,
    text: localisation.popup.description.error,
    type: ENotifyVariant.ERROR,
    component: 'DSPopup',
    control: [
      {
        text: commonLocalisation.tryAgain,
        name: getButtonName(),
      },
    ],
  };
}

export default initLocalisation;

export {
  getButtonName,
  initCreatedPopUp,
  initUpdatedPopUp,
  initAlreadyExistsPopUp,
  initErrorPopUp,
};
