export default function mapCreateEditProductsCurrency(price, serviceContainer = undefined) {
  return price.reduce((prices, priceItem) => {
    priceItem.price = Object.keys(priceItem.price).reduce((priceItemPrice, priceKey) => {
      const priceElement = priceItem.price[priceKey];

      if (serviceContainer) {
        const numberFormatService = serviceContainer.resolve('numberFormatService');

        priceElement.price = numberFormatService.getInputPriceValue(priceElement.price);
      }

      priceItemPrice[priceKey] = priceElement;

      return priceItemPrice;
    }, {});

    prices.push(priceItem);

    return prices;
  }, []);
}
