import isObject from '@/module/common/utils/data/isObject';
import isArray from '@/module/common/utils/data/isArray';

function cloneImmutable<T>(value: T): T {
  if (isArray(value)) {
    const currentValue = value as unknown as [];

    return currentValue.map((item) => cloneImmutable(item)) as unknown as T;
  }
  if (isObject(value)) {
    const fileType = (value as unknown as object).constructor?.name;

    if (fileType !== 'Object') {
      return value as unknown as T;
    }

    const currentValue = value as unknown as object;
    return Object.keys(currentValue).reduce((data, key) => {
      data[key] = cloneImmutable(currentValue[key]);

      return data;
    }, {}) as T;
  }

  return value as unknown as T;
}

export default cloneImmutable;
