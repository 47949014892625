import { render, staticRenderFns } from "./DSExclamationIcon.vue?vue&type=template&id=b161e478&scoped=true&"
import script from "./DSExclamationIcon.vue?vue&type=script&lang=js&"
export * from "./DSExclamationIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b161e478",
  null
  
)

export default component.exports