export default (serviceContainer) => ({
  namespaced: true,
  state: {
    businessStructure: {
      country: {},
      addressLine1: '',
      addressLine2: '',
      legalAddress: '',
      actualAddress: '',
      townOrCity: '',
      postalCode: '',
      businessType: {},
      sellingArea: {},
    },
    businessRepresentative: {
      firstName: '',
      lastName: '',
      middleName: '',
      identificationNumber: '',
      jobTitle: '',
      representative: {},
      document: '',
      email: '',
      phoneNumber: '',
      dateOfBirth: '',
      documentIndividualEntrepreneur: [],
      documentCompany: [],
      documentPassport: [],
      documentSnils: [],
    },
    businessDetails: {
      individual: {},
      legalBusinessName: '',
      taxIdentificationCode: '',
      doingBusinessAs: '',
      businessWebsite: '',
      productDescription: '',
      kpp: '',
      ogrn: '',
      passport: '',
      passportDate: '',
      passportCode: '',
      snils: '',
      documentOgrnip: '',
      taxationSystem: {},
      productTaxCategory: [],
    },
    supportDetails: {
      phoneNumber: '',
      email: '',
    },
    accountForPayouts: {
      currency: {},
      country: {},
      bankName: '',
      iban: '',
      ibanConfirm: '',
      vatNumber: '',
      swift: '',
      bik: '',
      ks: '',
      rs: '',
    },
    taxCalculation: {
      taxCalculation: {},
    },
    countryList: [],
    isSavingInProgress: false,
    isAccountActivateError: false,
    isEmptyDraft: true,
  },
  mutations: {
    setBusinessStructure(state, payload) {
      state.businessStructure = payload;
    },
    setBusinessRepresentative(state, payload) {
      state.businessRepresentative = payload;
    },
    setBusinessDetails(state, payload) {
      state.businessDetails = payload;
    },
    setSupportDetails(state, payload) {
      state.supportDetails = payload;
    },
    setAccountForPayouts(state, payload) {
      state.accountForPayouts = payload;
    },
    setTaxCalculation(state, payload) {
      state.taxCalculation = payload;
    },
    setCountryList(state, payload) {
      state.countryList = payload;
    },
    setIsSavingInProgress(state, payload) {
      state.isSavingInProgress = payload;
    },
    setFormData(state, payload) {
      state.businessStructure = payload.businessStructure;
      state.businessRepresentative = payload.businessRepresentative;
      state.businessDetails = payload.businessDetails;
      state.supportDetails = payload.supportDetails;
      state.accountForPayouts = payload.accountForPayouts;
      state.taxCalculation = payload.taxCalculation;
    },
    setIsAccountActivateError(state, payload) {
      state.isAccountActivateError = payload;
    },
    setIsEmptyDraft(state, payload) {
      state.isEmptyDraft = payload;
    },
  },
  getters: {
    getBusinessStructure(state) {
      return state.businessStructure;
    },
    getBusinessRepresentative(state) {
      return state.businessRepresentative;
    },
    getBusinessDetails(state) {
      return state.businessDetails;
    },
    getSupportDetails(state) {
      return state.supportDetails;
    },
    getAccountForPayouts(state) {
      return state.accountForPayouts;
    },
    getTaxCalculation(state) {
      return state.taxCalculation;
    },
    getCountryList(state) {
      return state.countryList;
    },
    getIsSavingInProgress(state) {
      return state.isSavingInProgress;
    },
    getFormData(state) {
      return {
        businessStructure: state.businessStructure,
        businessRepresentative: state.businessRepresentative,
        businessDetails: state.businessDetails,
        supportDetails: state.supportDetails,
        accountForPayouts: state.accountForPayouts,
        taxCalculation: state.taxCalculation,
      };
    },
    getIsAccountActivateError(state) {
      return state.isAccountActivateError;
    },
    getIsEmptyDraft(state) {
      return state.isEmptyDraft;
    },
  },
  actions: {
    async refreshCountryList({ commit }) {
      const api = serviceContainer.resolve('api');

      const response = await api.common.getCountryList();

      commit('setCountryList', response?.data ?? []);
    },
    async clearForm({ commit }) {
      commit('setFormData', {
        businessStructure: {
          country: {},
          addressLine1: '',
          addressLine2: '',
          legalAddress: '',
          actualAddress: '',
          townOrCity: '',
          postalCode: '',
          businessType: {},
        },
        businessRepresentative: {
          firstName: '',
          lastName: '',
          middleName: '',
          identificationNumber: '',
          jobTitle: '',
          representative: {},
          document: '',
          email: '',
          phoneNumber: '',
          dateOfBirth: '',
          documentIndividualEntrepreneur: [],
          documentCompany: [],
          documentPassport: [],
          documentSnils: [],
        },
        businessDetails: {
          individual: {},
          legalBusinessName: '',
          taxIdentificationCode: '',
          doingBusinessAs: '',
          businessWebsite: '',
          productDescription: '',
          kpp: '',
          ogrn: '',
          passport: '',
          passportDate: '',
          passportCode: '',
          snils: '',
          documentOgrnip: '',
          taxationSystem: {},
          productTaxCategory: [],
        },
        supportDetails: {
          phoneNumber: '',
          email: '',
        },
        accountForPayouts: {
          currency: {},
          country: {},
          bankName: '',
          iban: '',
          ibanConfirm: '',
          vatNumber: '',
          swift: '',
          bik: '',
          ks: '',
          rs: '',
        },
        taxCalculation: {
          taxCalculation: {},
        },
      });
    },
    async saveForm({
      commit,
    }, data) {
      commit('setIsSavingInProgress', true);
      const api = serviceContainer.resolve('api');

      const { individualButtonList, typeOfBusinessList, data: formData } = data;

      const typeOfBusiness = formData.business_structure.type_of_business;
      const isCompany = typeOfBusiness === typeOfBusinessList.company.value;
      const { individual } = formData.business_details;

      try {
        if (isCompany) {
          await api.account.activateCompanyAccount({ formData });
        } else if (individual === individualButtonList.individualEntrepreneur.value) {
          await api.account.activateIndividualEntrepreneurAccount({ formData });
        } else if (individual === individualButtonList.individual.value) {
          await api.account.activateIndividualAccount({ formData });
        } else if (individual === individualButtonList.selfEmployed.value) {
          await api.account.activateSelfEmployedAccount({ formData });
        }
      } catch (e) {
        commit('setIsAccountActivateError', true);
      }

      commit('setIsSavingInProgress', false);
    },
    async updateDraft(_store, formData) {
      const payload = {
        formName: 'account_activate',
        formData,
      };
      const api = serviceContainer.resolve('api');

      await api.common.setDraft(payload);
    },
    async refreshDraft({
      getters,
      commit,
      dispatch,
    }, { salesArea }) {
      const api = serviceContainer.resolve('api');

      const response = await api.common.refreshDraft('account_activate');
      const formData = response?.data?.formData ?? {};

      const businessStructure = formData.businessStructure;
      const isRussia = businessStructure?.country?.code === 'RU';

      if (!salesArea) {
        salesArea = isRussia ? 'russia' : 'global';

        dispatch('authStore/changeSalesArea', salesArea, { root: true });
      }

      commit('setIsEmptyDraft', Object.keys(formData).length === 0);

      const blockNames = [
        'businessStructure',
        'businessRepresentative',
        'businessDetails',
        'supportDetails',
        'accountForPayouts',
        'taxCalculation',
      ];

      const fileFields = [
        'businessRepresentative.documentIndividualEntrepreneur',
        'businessRepresentative.documentCompany',
        'businessRepresentative.documentPassport',
        'businessRepresentative.documentSnils',
      ];

      const fieldsObjectInitial = [
        'businessStructure.country',
        'businessStructure.sellingArea',
        'businessStructure.businessType',
        'businessDetails.individual',
        'accountForPayouts.currency',
        'accountForPayouts.country',
        'taxCalculation.taxCalculation',
        'businessDetails.taxationSystem',
        'businessDetails.productTaxCategory',
      ];

      blockNames.forEach((blockName) => {
        const getterName = `get${blockName.charAt(0).toUpperCase()}${blockName.slice(1)}`;
        const setterName = `set${blockName.charAt(0).toUpperCase()}${blockName.slice(1)}`;
        const formBlock = formData[blockName] ?? {};
        const currentData = getters[getterName];

        const newBlock = Object.keys(currentData).reduce((newData, fieldName) => {
          const key = `${blockName}.${fieldName}`;

          let fieldData;
          if (salesArea === 'russia' && key === 'businessRepresentative.jobTitle') {
            const currentRepresentative = currentData.representative?.id ?? null;
            const draftRepresentative = formBlock.representative?.id ?? null;

            if (!draftRepresentative) {
              if (!currentRepresentative || currentRepresentative === 'header') {
                if (formBlock[fieldName] && formBlock[fieldName].length > 0) {
                  fieldData = formBlock[fieldName];
                } else {
                  fieldData = formBlock[fieldName] === '' ? '' : null;
                }
              }
            } else if (draftRepresentative === 'header') {
              if (formBlock[fieldName] && formBlock[fieldName].length > 0) {
                fieldData = formBlock[fieldName];
              } else {
                fieldData = formBlock[fieldName] === '' ? '' : null;
              }
            } else {
              fieldData = formBlock[fieldName] ?? currentData[fieldName];
            }
          } else if (fileFields.includes(key)) {
            fieldData = formBlock[fieldName]?.length > 0 ? formBlock[fieldName] : currentData[fieldName];
          } else {
            fieldData = formBlock[fieldName] ?? currentData[fieldName];
          }

          if (fieldsObjectInitial.indexOf(key) !== -1 && Array.isArray(fieldData) && fieldData.length === 0) {
            fieldData = {};
          }

          newData[fieldName] = fieldData;

          return newData;
        }, {});

        commit(setterName, newBlock);
      });
    },
    clearErrors({ commit }) {
      commit('setIsAccountActivateError', false);
    },
  },
});
