<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.1881 6.27296L17.7281 9.81296L9.10411 18.435C8.33102 19.2082 7.37981 19.7796 6.33411 20.099L3.43111 20.985C3.37333 21.0023 3.31192 21.0037 3.25341 20.989C3.19489 20.9742 3.14147 20.9439 3.0988 20.9013C3.05614 20.8586 3.02583 20.8052 3.01109 20.7467C2.99635 20.6882 2.99774 20.6267 3.01511 20.569L3.90111 17.667C4.22035 16.6209 4.79178 15.6693 5.56511 14.896L14.1881 6.27296ZM15.2491 5.21296L17.0181 3.44296C17.2994 3.16176 17.6809 3.00378 18.0786 3.00378C18.4764 3.00378 18.8578 3.16176 19.1391 3.44296L20.5571 4.86196C20.8383 5.14326 20.9963 5.52472 20.9963 5.92246C20.9963 6.32021 20.8383 6.70167 20.5571 6.98296L18.7901 8.75196L15.2501 5.21196L15.2491 5.21296Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSEditIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>

<style scoped>

</style>
