import IProductIconApi from '@/module/product/api/contract/IProductIconApi';
import { AxiosInstance, AxiosResponse } from 'axios';
import IProductIconResponse from '@/module/product/model/Api/Product/IProductIconResponse';

class ProductIconApi implements IProductIconApi {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  upload(data: FormData): Promise<AxiosResponse<IProductIconResponse>> {
    return this.client.post('vendor/product/icon', data);
  }
}

export default ProductIconApi;
