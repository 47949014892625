<template>
  <component
    :is="tag"
    :class="classObject"
  >
    {{ text }}
  </component>
</template>
<script>
export default {
  name: 'HeaderText',
  props: {
    size: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    tag() {
      let resultTag;

      switch (this.size) {
        case '1':
          resultTag = 'h1';
          break;
        case '2':
          resultTag = 'h2';
          break;
        case '3':
          resultTag = 'h3';
          break;
        default:
          break;
      }
      return resultTag;
    },

    classObject() {
      return {
        header1: this.size === '1',
      };
    },

  },
};
</script>

<style lang="scss">

h1, h2, h3 {
  font-family: $text-proxima-nova;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 24px;
}

@media screen and (max-width: 920px) {
  h1 {
    font-size: 40px;
    line-height: 44px;
  }
}

</style>
