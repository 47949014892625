import ITypeCheckTool from '@/module/common/service/UtilsService/contract/ITypeCheckTool';

class TypeCheckTool implements ITypeCheckTool {
  isArray = (value: unknown): value is [] => Array.isArray(value);

  isNull = (value: unknown): value is null => value === null;

  isNumber = (value: unknown): value is number => typeof value === 'number';

  isSet = <T = unknown>(value: unknown): value is T => !this.isNull(value) && !this.isUndefined(value);

  isObject = (value: unknown): value is object => Object.prototype.toString.call(value) === '[object Object]';

  isString = (value: unknown): value is string => typeof value === 'string';

  isUndefined = (value: unknown): value is undefined => value === undefined;

  isSameType = (value1: unknown, value2: unknown): boolean => typeof value1 === typeof value2;
}

export default TypeCheckTool;
