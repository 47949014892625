import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import EDraftName from '@/api/EDraftName';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
// eslint-disable-next-line max-len
import IFormDataMapper
  from '@/module/merchant/view/AccountActivatePage/DataMapper/IFormDataMapper';
import TFromDataDraft
  from '@/module/merchant/view/AccountActivatePage/models/Draft/TFromDataDraft';
import IFormConfig from '@/module/merchant/view/AccountActivatePage/models/IFormConfig';
import IPageState from '@/module/merchant/view/AccountActivatePage/models/IPageState';
import IFormDraftHandler
  from '@/module/merchant/view/AccountActivatePage/Handler/contract/IFormDraftHandler';

class FormDraftHandler implements IFormDraftHandler {
  private readonly state: IPageState;

  private readonly serviceContainer: IServiceContainer;

  private readonly toDraftMapper: IFormDataMapper;

  constructor(
    state: IPageState,
    serviceContainer: IServiceContainer,
    toDraftMapper: IFormDataMapper,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.toDraftMapper = toDraftMapper;
  }

  updateDraft = (
    tabListConfig: IFormConfig[],
    draft: TFromDataDraft,
  ): TFromDataDraft => {
    const utilsService = this.serviceContainer.utilsService;
    const formDraft = utilsService.data.cloneImmutable(draft);

    return tabListConfig
      .reduce((draft, formConfigItem) => formConfigItem.formConfig
        .reduce((formConfigData, config) => config.items
          .reduce((fieldData, field) => {
            const { fieldBlock } = field;

            if (!fieldData[fieldBlock] || utilsService.typeCheck.isArray(fieldData[fieldBlock])) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              fieldData[fieldBlock] = {};
            }

            fieldData = this.toDraftMapper.mapFieldValue(field, fieldData);

            return fieldData;
          }, formConfigData), draft), formDraft) as TFromDataDraft;
  };

  saveDraft = async (draft: TFromDataDraft) => {
    const utilsService = this.serviceContainer.utilsService;
    const formData = utilsService.data.cloneImmutable(draft);
    const api = this.serviceContainer.resolve(EServiceContainerInstances.API);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await api.common.setDraft({ formData, formName: EDraftName.ACCOUNT_ACTIVATE });
  };
}

export default FormDraftHandler;
