<template>
  <div
    v-click-outside="handleClickOutside"
    class="date-picker"
  >
    <button
      class="date-picker__dropdown-button"
      :class="[
        isMenuOpened
          ? 'date-picker__dropdown-button--active active'
          : null,
      ]"
      type="button"
      @click="handleToggleDropdown"
    >
      <span
        class="date-picker__icon date-picker__dropdown-icon-left"
      >
        <DSIcon
          icon="calendar"
        />
      </span>
      <span class="date-picker__dropdown-text">
        {{ shortDate ? formattedShortDate : formattedDate }}
      </span>
      <span
        class="date-picker__icon date-picker__dropdown-icon-right"
      >
        <DSIcon
          icon="arrowDown"
        />
      </span>
    </button>

    <div
      v-if="isMenuOpened"
      class="date-picker__dropdown"
    >
      <vDatePicker
        v-if="isMenuOpened"
        ref="datePickerRef"
        v-model="modelValue"
        class="date-picker__calendar date-picker__options"
        trim-weeks
        :attributes="attributes"
        :popover="popoverOptions"
        :locale="localeSetting"
        :min-date="minDate"
        :max-date="maxDate"
        @dayclick="handleDayClick"
      />

      <div class="date-picker__controls date-picker__options">
        <button
          class="date-picker__button date-picker__submit-button"
          type="button"
          @click="handleSubmitClick"
        >
          {{ $t("reports.overview.date_picker_controls.apply") }}
        </button>
        <button
          class="date-picker__button date-picker__cancel-button"
          type="button"
          @click="handleCancelClick"
        >
          {{ $t("reports.overview.date_picker_controls.cancel") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import { DatePicker as vDatePicker } from 'v-calendar';
import { mapGetters } from 'vuex';

export default {
  name: 'DatePicker',
  components: {
    DSIcon,
    vDatePicker,
  },
  props: {
    isComparisonMode: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Date,
      default: () => new Date(),
    },
    localeOptions: {
      type: Array,
      default: () => [
        { id: 'en', firstDayOfWeek: 2, masks: { weekdays: 'WW' } },
        { id: 'ru', masks: { weekdays: 'WW' } },
      ],
    },
    shortDate: {
      type: Boolean,
      default: false,
    },
    maxDate: {
      type: Date,
      default: null,
    },
    minDate: {
      type: Date,
      default: null,
    },
  },
  data() {
    return {
      modelValue: this.value,
      dateStringOptions: { year: 'numeric', month: '2-digit', day: '2-digit' },
      optionsButtons: [
        {
          value: 'TY', // 'This Year -> TY'
          name: 'reports.overview.date_picker_options.this_year',
        },
        {
          value: 'TM',
          name: 'reports.overview.date_picker_options.this_month',
        },
        {
          value: 'LY',
          name: 'reports.overview.date_picker_options.last_year',
        },
        {
          value: 'LM',
          name: 'reports.overview.date_picker_options.last_month',
        },
        {
          value: 'LW',
          name: 'reports.overview.date_picker_options.last_week',
        },
        {
          value: 'YD', // Yesterday
          name: 'reports.overview.date_picker_options.yesterday',
        },
        {
          value: 'CM',
          name: 'reports.overview.date_picker_options.custom',
        },
      ],
      comparisonButtons: [
        {
          value: 'TY', // 'This Year -> TY'
          name: 'reports.overview.date_picker_options.this_year',
        },
        {
          value: 'TM',
          name: 'reports.overview.date_picker_options.this_month',
        },
        {
          value: 'LY',
          name: 'reports.overview.date_picker_options.last_year',
        },
        {
          value: 'LM',
          name: 'reports.overview.date_picker_options.last_month',
        },
        {
          value: 'LW',
          name: 'reports.overview.date_picker_options.last_week',
        },
        {
          value: 'YD', // Yesterday
          name: 'reports.overview.date_picker_options.yesterday',
        },
        {
          value: 'CM',
          name: 'reports.overview.date_picker_options.custom',
        },
        {
          value: 'NC',
          name: 'reports.overview.date_picker_options.no_comparison',
        },
      ],
      monthNamesList: [
        'months_short.jan',
        'months_short.feb',
        'months_short.mar',
        'months_short.apr',
        'months_short.may',
        'months_short.jun',
        'months_short.jul',
        'months_short.aug',
        'months_short.sep',
        'months_short.oct',
        'months_short.nov',
        'months_short.dec',
      ],
      dayNamesList: [
        'week_days_short.mon',
        'week_days_short.tue',
        'week_days_short.wed',
        'week_days_short.thu',
        'week_days_short.fri',
        'week_days_short.sat',
        'week_days_short.sun',
      ],
      isMenuOpened: false,
    };
  },
  computed: {
    ...mapGetters({
      locale: 'getLocale',
    }),
    localeSetting() {
      return this.localeOptions.find((el) => el.id === this.locale);
    },
    formattedDate() {
      return this.$utils.date.formatWithoutSkipCurrentYear(this.modelValue);
    },
    formattedShortDate() {
      return this.$utils.date.getFormat(this.modelValue, 'DD/MM/YYYY');
    },
    attributes() {
      return [
        {
          dates: this.modelValue,
        },
      ];
    },
    popoverOptions() {
      return {
        isInteractive: true,
        keepVisibleOnInput: true,
        placement: 'bottom',
        positionFixed: true,
      };
    },
  },
  watch: {
    value(value) {
      this.modelValue = value;
    },
  },
  methods: {
    handleDayClick(value) {
      this.modelValue = value.date;
      this.$refs.datePickerRef.focusDate(value);
    },
    handleSubmitClick() {
      this.$emit('update:value', this.modelValue);
      this.isMenuOpened = false;
    },
    handleCancelClick() {
      this.modelValue = this.value;
      this.isMenuOpened = !this.isMenuOpened;
    },
    async handleToggleDropdown() {
      this.modelValue = this.value;
      this.isMenuOpened = !this.isMenuOpened;
    },
    async handleClickOutside() {
      this.modelValue = this.value;
      if (this.isMenuOpened) {
        this.isMenuOpened = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../module/design-system/components/variables";
// Здесь !important оставить, т.к.
// перезаписываются стили компоненты датапикера
.date-picker {
  display: flex;
  position: relative;
  width: 269px;

  &__dropdown-button {
    border: 2px solid #eceef2;
    width: 100%;
    height: 48px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
  }

  &__dropdown-text {
    font-family: $text-font-ibm-plex-sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    width: 100%;
    text-align: left;
  }

  &__dropdown {
    z-index: 5;
    position: absolute;
    left: 0;
    top: calc(100% + 10px);
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 24px 0 #0000001a;
    width: max-content;
    min-width: 271px;
    border-radius: 8px;
    background-color: white;
  }

  &__options {
    border: 2px solid #eceef2;
    border-top-width: 0.5px;
    border-bottom-width: 0.5px;

    &:first-child {
      border-top-width: 2px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  &__input-group {
    align-items: center;
    justify-content: space-between;
    margin: 12px;
    display: flex;
  }

  &__input {
    display: flex;
    font-family: $text-font-ibm-plex-sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0;
    border: 2px solid #eceef2;
    border-radius: 8px;
    width: 130px;
    padding: 5px 16px;
    color: #000000a6;
  }

  &__invalid {
    border-color: #eb0019;
  }

  &__icon {
    display: flex;
    margin: 10px;

    svg {
      fill: #b4b4b3;
    }
  }

  &__dropdown-button--active {
    border-color: #4397cb;
  }

  &__dropdown-button--active &__dropdown-icon-right {
    transition: 0.2s;
    transform: rotate(180deg);
  }

  &__arrow-icon {
    transform: scaleX(-1);
  }

  &__option-list {
    margin: 12px 8px;
    padding: 0;
    display: grid;
    grid-template-columns: max-content max-content;
  }

  &__option-item {
    padding: 10px 12px;
    display: flex;
    align-items: center;
    font-family: $text-font-ibm-plex-sans;
    color: #282828;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
  }

  &__controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom-width: 2px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    transition: 0.2s;
  }

  &__button {
    margin: 0;
    border: none;
    background-color: transparent;
    height: 50px;
    font-family: $text-font-ibm-plex-sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    &:hover {
      background-color: #f5f5f5;
    }

    &:active {
      background-color: #dddddd;
    }
  }

  &__submit-button {
    border-right: 0.5px solid #eceef2;
    color: #2d83b8;
  }

  &__cancel-button {
    border-left: 0.5px solid #eceef2;
    color: #ff4444;
  }

  &__calendar {
    &.vc-container,
    &.vc-pane {
      border-radius: 0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border: 2px solid #eceef2;
      border-bottom-width: 1px;
    }

    .vc-pane-container {
      --blue-200: #efeff1;
      --blue-600: #388fc4;
      --blue-700: #2d83b8;
      --blue-900: #282828;
      --font-bold: 500;
      --rounded-full: 8px;
    }

    .vc-pane-layout {
    // width: ;
    padding: 16px 3px;
    }

    .vc-arrows-container.title-center {
    padding: 24px 45px;
    }

    .vc-header {
      padding-bottom: 10px;
      height: 40px;
      align-items: baseline !important;
    }

    .vc-arrow svg {
      height: 20px;
      width: 20px;
      fill: #92929d;
    }

    .vc-title {
      font-family: $text-font-ibm-plex-sans;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 22px !important;
      letter-spacing: 0;
      color: #2d83b8 !important;
      text-transform: capitalize !important;
    }

    .vc-weekday {
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 16px !important;
      letter-spacing: 0;
      color: #92929d !important;
      text-transform: uppercase !important;
    }

    .vc-day-content {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0;
      text-align: center;
    }

    .vc-day.is-not-in-month * {
      pointer-events: initial !important;
      opacity: 0.3 !important;
      color: #eb0019;
    }

    .vc-day.is-not-in-month span[aria-disabled="true"] {
      pointer-events: none !important;
      opacity: 0.3 !important;
      color: #eb0019;
    }

    .vc-day.is-not-in-month > .vc-highlights *,
    .vc-day.is-not-in-month > .vc-highlights {
      opacity: 1 !important;
    }

    .vc-day.is-not-in-month > .vc-highlights ~ .vc-day-content {
      opacity: 0.5 !important;
    }

    .vc-day.is-not-in-month > .vc-highlights ~ .vc-day-box-center-bottom * {
      opacity: 1 !important;
    }

    .vc-day.is-not-in-month > .vc-highlights > .vc-highlight {
      opacity: 0.5 !important;
    }

    .vc-highlights.vc-day-layer .vc-highlight,
    .vc-day-content,
    .vc-day {
      height: 36px !important;
      width: 36px !important;
    }

    .is-disabled {
      pointer-events: none;
    }

    .vc-day-content,
    .vc-day {
      position: relative;
      font-family: $text-font-ibm-plex-sans;
      margin: 2px 0;
    }

    .vc-bars {
      position: absolute;
      width: 15px !important;
      height: 3px;
      bottom: 7px;
      left: 50%;
      transform: translate(-50%);
      border-radius: 4px;
      overflow: hidden;
      z-index: 2;
    }

    .vc-highlights ~ * .vc-bars {
      --blue-600: white;
    }

    .vc-day.weekday-position-1 .vc-highlights {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }

    .vc-day.weekday-position-7 .vc-highlights {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }

    .vc-day.weekday-position-6,
    .vc-day.weekday-position-7 {
      color: #ff4444;
      --blue-900: #ff4444;
    }

    .vc-highlight {
      border-width: 0 !important;
    }

    .vc-day-layer.vc-day-box-center-center {
      --white: var(--blue-600);
    }

    .vc-highlights .vc-highlight.vc-highlight-base-start,
    .vc-day.is-not-in-month
      > .vc-highlights
      .vc-highlight.vc-highlight-base-start {
      opacity: 1 !important;
      --blue-200: var(--blue-600);
      width: 36px !important;
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }

    .vc-highlights .vc-highlight.vc-highlight-base-end,
    .vc-day.is-not-in-month
      > .vc-highlights
      .vc-highlight.vc-highlight-base-end {
      opacity: 1 !important;
      --blue-200: var(--blue-600);
      width: 36px !important;
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }

    .vc-day.is-not-in-month
      > .vc-highlights.vc-day-layer.vc-day-box-center-bottom,
    .vc-day.is-not-in-month
      > .vc-highlights.vc-day-layer.vc-day-box-center-bottom
      * {
      opacity: 1 !important;
    }

    .vc.highlights
      ~ .vc-day.is-not-in-month
      .vc-day-layer.vc-day-box-center-bottom,
    .vc.highlights
      ~ .vc-day.is-not-in-month
      .vc-day-layer.vc-day-box-center-bottom
      * {
      --blue-600: white;
      opacity: 1 !important;
    }

    .vc-day-content:hover,
    .vc-day-content:focus {
      background-color: inherit !important;
    }
  }
}
</style>
