<template>
  <div
    class="c-expandable-list"
  >
    <div
      v-if="selectedItemList.length > 0"
      class="c-expandable-list__list"
    >
      <CExpandableListItem
        v-for="(item) in selectedItemList"
        :key="item.id"
        :item="item"
        :title-field="titleField"
        :url-field="urlField"
        @remove-item="$emit('remove-item', $event)"
      >
        <template
          #list-item
        >
          <slot
            :item="item"
            name="list-item"
          />
        </template>
      </CExpandableListItem>
    </div>
    <template
      v-if="showAddItemField"
    >
      <DSFilteredSelect
        v-if="withSearch"
        :list="itemList"
        :id-field="idField"
        :title-field="titleField"
        :placeholder="selectPlaceholder"
        :status="status.code"
        class="c-expandable-list__select"
        @input="handleSelectInput"
        @scroll-y-reach-end="$emit('scroll-y-reach-end')"
        @search-input="$emit('filtered-select-search-input', $event)"
      >
        <template
          #before-list
        >
          <slot
            name="select-before-list"
          />
        </template>
        <template
          #list-item="slotProps"
        >
          <slot
            :option="slotProps.option"
            name="select-item"
          />
        </template>
        <template
          #after-list
        >
          <slot
            name="select-after-list"
          />
        </template>
      </DSFilteredSelect>
      <DSSelect
        v-else
        :list="itemList"
        :placeholder="selectPlaceholder"
        :status="status.code"
        class="c-expandable-list__select"
        @input="handleSelectInput"
        @scroll-y-reach-end="$emit('scroll-y-reach-end')"
      >
        <template
          #before-list
        >
          <slot
            name="select-before-list"
          />
        </template>

        <template
          #list-item="slotProps"
        >
          <slot
            :option="slotProps.option"
            name="select-item"
          />
        </template>

        <template
          #after-list
        >
          <slot
            name="select-after-list"
          />
        </template>
      </DSSelect>
    </template>
    <DSButton
      :disabled="showAddItemField"
      :text="addButtonText"
      variant="tretiary"
      @click="handleClickAddItem"
    >
      <template
        #icon-before
      >
        <DSIcon
          icon="plus"
          :color="buttonIconColor"
        />
      </template>
    </DSButton>
    <DSTextElement
      v-if="status.text"
      :color="status.code"
      class="c-expandable-list__comment"
      variant="small"
    >
      {{ status.text }}
    </DSTextElement>
  </div>
</template>

<script>
import CExpandableListItem from '@/module/common/components/UI/ExpandableList/CExpandableListItem.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSFilteredSelect from '@/module/design-system/components/UI/DSFiltredSelect.vue';
import DSSelect from '@/module/design-system/components/UI/DSSelect.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';

export default {
  name: 'CExpandableList',
  components: {
    DSIcon,
    DSFilteredSelect,
    DSTextElement,
    DSButton,
    DSSelect,
    CExpandableListItem,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    selectPlaceholder: {
      type: String,
      default: '',
    },
    addButtonText: {
      type: String,
      default: '',
    },
    itemList: {
      type: Array,
      default: () => [],
    },
    titleField: {
      type: String,
      default: 'title',
    },
    idField: {
      type: String,
      default: 'id',
    },
    urlField: {
      type: String,
      default: 'url',
    },
    selectedItemList: {
      type: Array,
      default: () => [],
    },
    status: {
      type: Object,
      default: () => ({
        code: 'default',
      }),
    },
    withSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showAddItemField: true,
    };
  },
  computed: {
    buttonIconColor() {
      return this.showAddItemField ? 'disabled' : 'linked';
    },
  },
  methods: {
    handleClickAddItem() {
      this.showAddItemField = true;
    },
    handleSelectInput(event) {
      this.showAddItemField = false;
      this.$emit('add-item', event);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.c-expandable-list {
  &__title {
    margin-bottom: 20px;
  }

  &__select {
    margin-bottom: 20px;
    min-height: 18px;
  }

  &__comment {
    display: block;
    margin-top: 8px;
  }
}
</style>
