import TFormData from '@/module/product/model/FormData/TFormData';
import TProduct from '@/module/product/model/Product/TProduct';
import EPricingModelName from '@/module/product/model/Product/Fields/PricingModel/EPricingModelName';
import IOnePriceFormData from '@/module/product/model/FormData/IOnePriceFormData';
import IVolumePriceFormData from '@/module/product/model/FormData/IVolumePriceFormData';
import IVolumePriceAndOptionsFormData from '@/module/product/model/FormData/IVolumePriceAndOptionsFormData';
import IOnePriceForEachCurrencyFormData from '@/module/product/model/FormData/IOnePriceForEachCurrencyFormData';
import IOnePriceProduct from '@/module/product/model/Product/IOnePriceProduct';
import IVolumePriceProduct from '@/module/product/model/Product/IVolumePriceProduct';
import IVolumePriceAndOptionsProduct
  from '@/module/product/model/Product/IVolumePriceAndOptionsProduct';
import IOnePriceForEachCurrencyProduct
  from '@/module/product/model/Product/IOnePriceForEachCurrencyProduct';
import IModelToFormDataMapper from '@/module/product/view/ProductFormPage/mapper/contract/IModelToFormDataMapper';
import ECurrency from '@/module/merchant/models/ECurrency';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import IVolumePriceColumn from '@/module/product/model/FormData/Price/IVolumePriceColumn';
import IVolumePricingAndOptionsColumn
  from '@/module/product/model/FormData/Price/IVolumePricingAndOptionsColumn';
import IOnePriceForEachCurrencyColumn
  from '@/module/product/model/FormData/Price/IOnePriceForEachCurrencyColumn';

class ModelToFormDataMapper implements IModelToFormDataMapper {
  private serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  map = (product: TProduct): TFormData => {
    if (this.isOnePrice(product)) {
      return this.mapOnePrice(product);
    }

    if (this.isVolumePrice(product)) {
      return this.mapVolumePrice(product);
    }

    if (this.isVolumePricingAndOptions(product)) {
      return this.mapVolumePriceAndOptions(product);
    }

    if (this.isOnePriceForEachCurrency(product)) {
      return this.mapOnePriceForEachCurrency(product);
    }

    throw new Error('Unsupported price type');
  };

  private mapOnePrice = (product: IOnePriceProduct): IOnePriceFormData => {
    const licenseTermService = this.serviceContainer.moduleProduct.termService;

    const formData: IOnePriceFormData = {
      availableFor: product.availableFor,
      description: product.description,
      enableLicenseTerm: !!product.licenseTerm && product.licenseTerm !== '0',
      fulfillmentId: product.fulfillmentId,
      id: product.id,
      imageUrl: product.imageUrl,
      licenseTerm: licenseTermService.parse(product.licenseTerm),
      licenseType: product.licenseType,
      price: {
        inputValue: this.mapPriceValue(product.price[0].price.common.price),
        selectValue: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          id: product.price[0].price.common.currency.toUpperCase() as ECurrency,
          title: product.price[0].price.common.currency.toUpperCase(),
        },
      },
      pricingModel: product.pricingModel,
      renewal: product.renewal,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      renewalPrice: product.renewal && product.renewalPrice ? {
        inputValue: this.mapPriceValue(product.renewalPrice[0].price.common.price ?? ''),
        selectValue: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          id: product.renewalPrice[0].price.common.currency.toUpperCase() as ECurrency,
          title: product.renewalPrice[0].price.common.currency.toUpperCase(),
        },
      } : null,
      renewalSku: product.renewal && product.renewalPrice ? product.renewalPrice[0].sku : '',
      renewalSubtitle: product.renewalSubtitle,
      renewalTerm: licenseTermService.parse(product.renewalTerm),
      renewalTitle: product.renewalTitle,
      sku: product.price[0].sku,
      subtitle: product.subtitle,
      title: product.title,
      trial: product.trial,
      trialSku: product.trialSku,
      trialSubtitle: product.trialSubtitle,
      trialTerm: licenseTermService.parse(product.trialTerm),
      trialTitle: product.trialTitle,
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (product.softwareRegistry) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formData.softwareRegistry = product.softwareRegistry;
    }

    return formData;
  };

  private mapVolumePrice = (
    product: IVolumePriceProduct,
  ): IVolumePriceFormData => {
    const licenseTermService = this.serviceContainer.moduleProduct.termService;

    const formData: IVolumePriceFormData = {
      availableFor: product.availableFor,
      description: product.description,
      enableLicenseTerm: !!product.licenseTerm && product.licenseTerm !== '0',
      fulfillmentId: product.fulfillmentId,
      id: product.id,
      imageUrl: product.imageUrl,
      licenseTerm: licenseTermService.parse(product.licenseTerm),
      licenseType: product.licenseType,
      price: {
        pricingModelName: EPricingModelName.VOLUME_PRICING,
        value: {
          columns: product.price.map((priceItem, index) => ({
            id: index + 1,
            from: {
              value: priceItem.from,
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            to: {
              value: priceItem.to,
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            sku: {
              value: priceItem.sku,
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            price: {
              value: this.mapPriceValue(priceItem.price.common.price),
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
          } as IVolumePriceColumn)),
          currency: {
            id: product.price[0].price.common.currency.toUpperCase() as ECurrency,
            title: product.price[0].price.common.currency.toUpperCase() as ECurrency,
          },
        },
      },
      pricingModel: product.pricingModel,
      renewal: product.renewal,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      renewalPrice: product.renewal && product.renewalPrice ? {
        pricingModelName: EPricingModelName.VOLUME_PRICING,
        value: {
          columns: product.renewalPrice.map((priceItem, index) => ({
            id: index + 1,
            from: {
              value: priceItem.from,
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            to: {
              value: priceItem.to,
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            sku: {
              value: priceItem.sku,
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            price: {
              value: this.mapPriceValue(priceItem.price.common.price),
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
          } as IVolumePriceColumn)),
          currency: {
            id: product.renewalPrice[0].price.common.currency.toUpperCase() as ECurrency,
            title: product.renewalPrice[0].price.common.currency.toUpperCase() as ECurrency,
          },
        },
      } : null,
      renewalSubtitle: product.renewalSubtitle,
      renewalTerm: licenseTermService.parse(product.renewalTerm),
      renewalTitle: product.renewalTitle,
      sku: product.price[0].sku,
      subtitle: product.subtitle,
      title: product.title,
      trial: product.trial,
      trialSku: product.trialSku,
      trialSubtitle: product.trialSubtitle,
      trialTerm: licenseTermService.parse(product.trialTerm),
      trialTitle: product.trialTitle,
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (product.softwareRegistry) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formData.softwareRegistry = product.softwareRegistry;
    }

    return formData;
  };

  private mapVolumePriceAndOptions = (
    product: IVolumePriceAndOptionsProduct,
  ): IVolumePriceAndOptionsFormData => {
    const licenseTermService = this.serviceContainer.moduleProduct.termService;

    const formData: IVolumePriceAndOptionsFormData = {
      availableFor: product.availableFor,
      description: product.description,
      enableLicenseTerm: !!product.licenseTerm && product.licenseTerm !== '0',
      fulfillmentId: product.fulfillmentId,
      id: product.id,
      imageUrl: product.imageUrl,
      licenseTerm: licenseTermService.parse(product.licenseTerm),
      licenseType: product.licenseType,
      price: {
        pricingModelName: EPricingModelName.VOLUME_PRICING_AND_OPTIONS,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value: product.price.map((priceItem, index) => {
          const portalModeService = this.serviceContainer.portalModeService;
          const currencyList = portalModeService.getProductFormPageHandler().getSellingCurrencyList();

          const result = {
            id: index + 1,
            from: {
              value: priceItem.from,
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            to: {
              value: priceItem.to,
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            sku: {
              value: priceItem.sku,
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
          } as unknown as IVolumePricingAndOptionsColumn;

          currencyList.forEach((currency) => {
            const currencyKey = currency.toUpperCase();

            result[currencyKey] = {
              value: this.mapPriceValue(priceItem.price?.[currencyKey]?.price ?? ''),
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            };
          });

          return result;
        }),
      },
      pricingModel: product.pricingModel,
      renewal: product.renewal,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      renewalPrice: product.renewal && product.renewalPrice ? {
        pricingModelName: EPricingModelName.VOLUME_PRICING_AND_OPTIONS,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value: product.renewalPrice.map((priceItem, index) => {
          const portalModeService = this.serviceContainer.portalModeService;
          const currencyList = portalModeService.getProductFormPageHandler().getSellingCurrencyList();

          const result = {
            id: index + 1,
            from: {
              value: priceItem.from,
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            to: {
              value: priceItem.to,
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
            sku: {
              value: priceItem.sku,
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            },
          } as unknown as IVolumePricingAndOptionsColumn;

          currencyList.forEach((currency) => {
            const currencyKey = currency.toUpperCase();

            result[currencyKey] = {
              value: this.mapPriceValue(priceItem.price?.[currencyKey]?.price ?? ''),
              status: {
                code: EValidationFieldStatusCode.DEFAULT,
              },
            };
          });

          return result;
        }),
      } : null,
      renewalSubtitle: product.renewalSubtitle,
      renewalTerm: licenseTermService.parse(product.renewalTerm),
      renewalTitle: product.renewalTitle,
      sku: product.price[0].sku,
      subtitle: product.subtitle,
      title: product.title,
      trial: product.trial,
      trialSku: product.trialSku,
      trialSubtitle: product.trialSubtitle,
      trialTerm: licenseTermService.parse(product.trialTerm),
      trialTitle: product.trialTitle,
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (product.softwareRegistry) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formData.softwareRegistry = product.softwareRegistry;
    }

    return formData;
  };

  private mapOnePriceForEachCurrency = (
    product: IOnePriceForEachCurrencyProduct,
  ): IOnePriceForEachCurrencyFormData => {
    const licenseTermService = this.serviceContainer.moduleProduct.termService;
    const portalModeService = this.serviceContainer.portalModeService;
    const currencyList = portalModeService.getProductFormPageHandler().getSellingCurrencyList();

    const priceItem: IOnePriceForEachCurrencyColumn = {
      sku: {
        value: product.price[0].sku,
        status: {
          code: EValidationFieldStatusCode.DEFAULT,
        },
      },
    } as IOnePriceForEachCurrencyColumn;

    let renewalPriceItem: IOnePriceForEachCurrencyColumn
      | null = null;
    if (product.renewal && product.renewalPrice) {
      renewalPriceItem = {
        sku: {
          value: product.renewalPrice[0].sku,
          status: {
            code: EValidationFieldStatusCode.DEFAULT,
          },
        },
      } as IOnePriceForEachCurrencyColumn;
    }

    currencyList.forEach((currency) => {
      const currencyKey = currency.toUpperCase();

      priceItem[currencyKey] = {
        value: this.mapPriceValue(product.price[0].price?.[currencyKey]?.price ?? ''),
        status: {
          code: EValidationFieldStatusCode.DEFAULT,
        },
      };

      if (renewalPriceItem) {
        renewalPriceItem[currencyKey] = {
          value: this.mapPriceValue(product.renewalPrice?.[0].price?.[currencyKey]?.price ?? ''),
          status: {
            code: EValidationFieldStatusCode.DEFAULT,
          },
        };
      }
    });

    const formData: IOnePriceForEachCurrencyFormData = {
      availableFor: product.availableFor,
      description: product.description,
      enableLicenseTerm: !!product.licenseTerm && product.licenseTerm !== '0',
      fulfillmentId: product.fulfillmentId,
      id: product.id,
      imageUrl: product.imageUrl,
      licenseTerm: licenseTermService.parse(product.licenseTerm),
      licenseType: product.licenseType,
      price: {
        pricingModelName: EPricingModelName.ONE_PRICE_FOR_EACH_CURRENCY,
        value: priceItem,
      },
      pricingModel: product.pricingModel,
      renewal: product.renewal,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      renewalPrice: product.renewal && product.renewalPrice ? {
        pricingModelName: EPricingModelName.ONE_PRICE_FOR_EACH_CURRENCY,
        value: renewalPriceItem,
      } : null,
      renewalSubtitle: product.renewalSubtitle,
      renewalTerm: licenseTermService.parse(product.renewalTerm),
      renewalTitle: product.renewalTitle,
      sku: product.price[0].sku,
      subtitle: product.subtitle,
      title: product.title,
      trial: product.trial,
      trialSku: product.trialSku,
      trialSubtitle: product.trialSubtitle,
      trialTerm: licenseTermService.parse(product.trialTerm),
      trialTitle: product.trialTitle,
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (product.softwareRegistry) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formData.softwareRegistry = product.softwareRegistry;
    }

    return formData;
  };

  private isOnePrice(product: TProduct): product is IOnePriceProduct {
    return product.pricingModel.id === EPricingModelName.ONE_PRICE;
  }

  private isVolumePrice(product: TProduct): product is IVolumePriceProduct {
    return product.pricingModel.id === EPricingModelName.VOLUME_PRICING;
  }

  private isVolumePricingAndOptions(
    product: TProduct,
  ): product is IVolumePriceAndOptionsProduct {
    return product.pricingModel.id === EPricingModelName.VOLUME_PRICING_AND_OPTIONS;
  }

  private isOnePriceForEachCurrency(
    product: TProduct,
  ): product is IOnePriceForEachCurrencyProduct {
    return product.pricingModel.id === EPricingModelName.ONE_PRICE_FOR_EACH_CURRENCY;
  }

  private mapPriceValue(
    value: string,
  ): string {
    const numberFormatService = this.serviceContainer.numberFormatService;
    const utilsService = this.serviceContainer.utilsService;
    const delimiter = numberFormatService.getMoneyDelimiter();

    return utilsService.string.replaceLast(value, '.', delimiter);
  }
}

export default ModelToFormDataMapper;
