
import {
  defineComponent,
  onMounted,
  PropType,
  reactive,
  watch,
} from 'vue';
import CLabel from '@/module/common/components/UI/CLabel.vue';
import CCheckbox from '@/module/common/components/UI/CCheckbox.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

interface ICCheckboxItem {
  id: string | number;
  value: boolean;
  title: string;
}

export default defineComponent({
  name: 'CCheckboxGroup',
  props: {
    list: {
      type: Array as PropType<ICCheckboxItem[]>,
      default: () => [],
    },
    value: {
      type: Array as PropType<ICCheckboxItem[]>,
      default: () => [],
    },
    label: {
      type: String,
      default: () => '',
    },
    tooltip: {
      type: String,
      default: () => '',
    },
    status: {
      type: Object as PropType<{ code: string; text: string; }>,
      default: () => ({}),
    },
  },
  components: {
    CLabel,
    CCheckbox,
    DSTextElement,
  },
  setup(props, { emit }) {
    const state = reactive<{ list: ICCheckboxItem[] }>({
      list: [],
    });

    function updateList(list: ICCheckboxItem[], values: ICCheckboxItem[]): ICCheckboxItem[] {
      return list.map((item) => {
        const currentValue = values.find((valueItem) => valueItem.id === item.id) ?? item;

        return { ...item, value: currentValue.value };
      });
    }

    onMounted(() => {
      state.list = updateList(props.list, props.value);
    });

    watch(() => props.list, (newValue: ICCheckboxItem[]) => {
      state.list = updateList(newValue, props.value);
    });

    watch(() => props.value, (newValue: ICCheckboxItem[]) => {
      state.list = updateList(props.list, newValue);
    });

    function handleChange() {
      emit('input', state.list);
    }

    return {
      state,
      handleChange,
    };
  },
});
