import IValidateHandler
  from '@/module/product/view/FulfillmentFormPage/model/contract/handler/validate/IValidateHandler';
import THandleFieldBlurParams from '@/module/product/view/FulfillmentFormPage/model/types/THandleFieldBlurParams';
import IState from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/IState';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import ILocalServiceContainer from '@/module/product/view/FulfillmentFormPage/model/ILocalServiceContainer';
import IHandlerService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IHandlerService';
import ELocalServiceFulfillment from '@/module/product/view/FulfillmentFormPage/model/enums/ELocalServiceFulfillment';
import IFormDataTestCreateGenerator
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataTestCreateGenerator';
import IFormDataSalesCreateGenerator
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataSalesCreateGenerator';
import IScenarioService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IScenarioService';
import EScenario from '@/module/product/view/FulfillmentFormPage/model/enums/EScenario';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import checkCondition from '@/module/common/helpers/checkCondition';
import EFieldName from '@/module/product/view/FulfillmentFormPage/model/enums/EFieldName';

class ValidateHandler implements IValidateHandler {
  private state: IState;

  private serviceContainer: IServiceContainer;

  private localServiceContainer: ILocalServiceContainer;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    localServiceContainer: ILocalServiceContainer,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.localServiceContainer = localServiceContainer;
  }

  validateField = (item: THandleFieldBlurParams): void => {
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);

    const validateFieldHandler = handlerService.getFieldValidatorHandler();
    const formConfigHandler = handlerService.getFormConfigHandler();

    let formConfig = formConfigHandler.getConfig(this.state.licenseSourceName);

    formConfig = formConfig.map((formBlock) => {
      formBlock.items = formBlock.items.map((blockItem) => {
        if (blockItem.id === item.field.id) {
          return validateFieldHandler.validateField(blockItem, item.formData);
        }

        return blockItem;
      });

      return formBlock;
    });

    formConfigHandler.store(this.state.licenseSourceName, formConfig);
  };

  validateForm = (): void => {
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const scenarioService = this.localServiceContainer
      .resolve<IScenarioService>(ELocalServiceFulfillment.SCENARIO_SERVICE);

    const validateFormHandler = handlerService.getFormValidatorHandler();
    const formConfigHandler = handlerService.getFormConfigHandler();
    const formDataHandler = handlerService.getFormDataHandler();

    let formData = formDataHandler.getData(this.state.licenseSourceName);
    let formConfig = formConfigHandler.getConfig(this.state.licenseSourceName);

    const scenarioName = scenarioService.getSaveScenarioName(this.state.licenseSourceName);

    if ([EScenario.DEFAULT, EScenario.MOCK_GENERATOR].includes(scenarioName)) {
      return;
    }

    let isValidParams = true;
    let isValidForm = true;

    if (scenarioName === EScenario.CREATE_GENERATOR) {
      const paramsValidateHandler = handlerService.getParamsValidateHandler();
      const simpleFieldStatusHandler = handlerService.getSimpleFieldStatusHandler();

      formData = paramsValidateHandler.validate(
        formData as IFormDataTestCreateGenerator | IFormDataSalesCreateGenerator,
        simpleFieldStatusHandler,
      );

      formData.parameters.forEach((parameterItem) => {
        if (parameterItem.inputConfig.status?.code === EValidationFieldStatusCode.ERROR) {
          isValidParams = false;
        }
        if (parameterItem.selectConfig.status?.code === EValidationFieldStatusCode.ERROR) {
          isValidParams = false;
        }
      });
    }

    formConfig = formConfig.map((formBlock) => {
      formBlock.items = formBlock.items.map((item) => {
        if (item.conditionsList && !checkCondition.resolveAndCondition(item.conditionsList, formData)) {
          return item;
        }

        const mappedFormFieldItem = validateFormHandler.validateField(item, formData);

        if (mappedFormFieldItem.status?.code === EValidationFieldStatusCode.ERROR) {
          isValidForm = false;
        }

        return mappedFormFieldItem;
      });

      return formBlock;
    });

    formDataHandler.store(this.state.licenseSourceName, formData);
    formConfigHandler.store(this.state.licenseSourceName, formConfig);

    this.state.isFormValid = isValidForm && isValidParams;
  };

  validateFormForTestRequest = (): void => {
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const scenarioService = this.localServiceContainer
      .resolve<IScenarioService>(ELocalServiceFulfillment.SCENARIO_SERVICE);

    const validateFormHandler = handlerService.getFormValidatorHandler();
    const formConfigHandler = handlerService.getFormConfigHandler();
    const formDataHandler = handlerService.getFormDataHandler();

    let formData = formDataHandler.getData(this.state.licenseSourceName);
    let formConfig = formConfigHandler.getConfig(this.state.licenseSourceName);

    const scenarioName = scenarioService.getSaveScenarioName(this.state.licenseSourceName);

    if (scenarioName !== EScenario.CREATE_GENERATOR) {
      return;
    }

    let isValidParams = true;
    let isValidForm = true;

    const paramsValidateHandler = handlerService.getParamsValidateHandler();
    const simpleFieldStatusHandler = handlerService.getSimpleFieldStatusHandler();

    formData = paramsValidateHandler.validate(
      formData as IFormDataTestCreateGenerator | IFormDataSalesCreateGenerator,
      simpleFieldStatusHandler,
    );

    formData.parameters.forEach((parameterItem) => {
      if (parameterItem.inputConfig.status?.code === EValidationFieldStatusCode.ERROR) {
        isValidParams = false;
      }
      if (parameterItem.selectConfig.status?.code === EValidationFieldStatusCode.ERROR) {
        isValidParams = false;
      }
    });

    const validatedFields = [EFieldName.URL, EFieldName.SECRET_KEY];

    formConfig = formConfig.map((formBlock) => {
      formBlock.items = formBlock.items.map((item) => {
        const isFieldHidden = item.conditionsList && !checkCondition.resolveAndCondition(item.conditionsList, formData);

        if (isFieldHidden || !validatedFields.includes(item.fieldName)) {
          return item;
        }

        const mappedFormFieldItem = validateFormHandler.validateField(item, formData);

        if (mappedFormFieldItem.status?.code === EValidationFieldStatusCode.ERROR) {
          isValidForm = false;
        }

        return mappedFormFieldItem;
      });

      return formBlock;
    });

    formDataHandler.store(this.state.licenseSourceName, formData);
    formConfigHandler.store(this.state.licenseSourceName, formConfig);

    this.state.isFormValid = isValidForm && isValidParams;
  };
}

export default ValidateHandler;
