<template>
  <div
    :class="buttonGroupClass"
  >
    <button
      v-for="button in buttonList"
      :key="button[idField]"
      :class="buttonClass(button)"
      type="button"
      @click="handleButtonClick(button)"
    >
      <DSTextElement
        :variant="buttonTextVariant"
        :color="buttonTextColor(button)"
      >
        {{ button[titleField] }}
      </DSTextElement>
    </button>
  </div>
</template>

<script>
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

export default {
  name: 'DSButtonGroup',
  components: {
    DSTextElement,
  },
  props: {
    buttonList: {
      type: Array,
      required: true,
    },
    variant: {
      type: String,
      default: 'stuck',
    },
    value: {
      type: Object,
      required: true,
    },
    idField: {
      type: String,
      default: 'id',
    },
    titleField: {
      type: String,
      default: 'title',
    },
  },
  data() {
    return {
      activeButton: {},
    };
  },
  computed: {
    buttonGroupClass() {
      return [
        'ds-button-group',
        `ds-button-group_variant_${this.variant}`,
      ];
    },
    buttonTextVariant() {
      return 'medium';
    },
  },
  watch: {
    value() {
      this.activeButton = this.value;
    },
  },
  mounted() {
    this.activeButton = this.value;
  },
  methods: {
    buttonClass(button) {
      return [
        'ds-button-group__button',
        `ds-button-group__button_variant_${this.variant}`,
        `ds-button-group__button_${this.activeButton[this.idField] === button[this.idField] ? 'active' : 'inactive'}`,
      ];
    },
    handleButtonClick(button) {
      this.activeButton = button;

      this.$emit('click', button);
    },
    buttonTextColor(button) {
      return this.activeButton[this.idField] === button[this.idField] ? 'linked' : 'secondary';
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import "../variables";

.ds-button-group {
  display: flex;
  flex-wrap: wrap;

  &_variant {
    &_spaced {
      gap: 12px;
    }
  }

  &__button {
    appearance: none;
    outline: none;
    border: 2px solid $button-border-color;
    background-color: #ffffff;

    &_variant {
      &_stuck {
        line-height: 22px;
        padding: 11px 32px;
        border-left-width: 0;
        border-right-width: 0;

        &:first-child {
          border-left-width: 2px;
          border-radius: 8px 0 0 8px;
        }

        &:last-child {
          border-right-width: 2px;
          border-radius: 0 8px 8px 0;
        }
      }

      &_spaced {
        line-height: 20px;
        border-width: 1px;
        padding: 4px 10px;
        border-radius: 4px;
      }
    }

    &_active {
      background-color: #EBF7FF;
      border-color: #EBF7FF;
    }
  }
}
</style>
