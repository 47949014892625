import {
  PriceValue,
} from '@/module/product/model/ProductModel';
import EPriceType from '@/module/product/model/EPriceType';

function getProductPriceCount(product) {
  const variants = product?.variants ?? [];

  return variants.reduce((count, variant) => {
    count += Object.keys(variant.price).length;

    return count;
  }, 0);
}

function getProductPrice(product): null | PriceValue {
  let price: null | PriceValue = null;

  if (getProductPriceCount(product) === 1) {
    price = Object.values(product.variants[0].price)[0] as PriceValue;
  }

  return price;
}

function getProductMinQuantity(product) {
  const variants = product.variants
    .map((variant) => variant.from)
    .filter((fromValue) => !!fromValue);

  return variants.length === 0 ? 1 : Math.min(...variants);
}

function getProductMaxQuantity(product) {
  const variants = product.variants
    .map((variant) => variant.to)
    .filter((fromValue) => !!fromValue);

  return variants.length === 0 ? null : Math.max(...variants);
}

function isProductSelected(product, selectedProductList, utils: { array }): boolean {
  return utils.array.includesObject(selectedProductList, product, 'product.id');
}

function isOnePricePrice(product) {
  if (product.variants.length > 1) {
    return false;
  }

  if (!product.variants[0].price.common || Object.keys(product.variants[0].price).length > 1) {
    return false;
  }

  return !(product.variants[0].from !== null);
}

function isVolumePricePrice(product) {
  const isOnlyCommonPrices = product.variants.reduce((isOnlyCommon, variant) => {
    if (!variant.price.common || Object.keys(variant.price).length > 1) {
      return false;
    }

    return isOnlyCommon;
  }, true);

  const isFromNotNullable = product.variants.reduce((isNotNullable, variant) => {
    if (variant.from === null) {
      return false;
    }

    return isNotNullable;
  }, true);

  return isOnlyCommonPrices && isFromNotNullable;
}

function isOnePriceEachCurrency(product) {
  const isNoCommonPrices = product.variants.reduce((isNoCommon, variant) => {
    if (variant.price.common) {
      return false;
    }

    return isNoCommon;
  }, true);

  const isNotEmptyPrices = product.variants.reduce((isNotEmpty, variant) => {
    if (Object.keys(variant.price).length === 0) {
      return false;
    }

    return isNotEmpty;
  }, true);

  const isFromNullable = product.variants.reduce((isNullable, variant) => {
    if (variant.from !== null) {
      return false;
    }

    return isNullable;
  }, true);

  return isNoCommonPrices && isNotEmptyPrices && isFromNullable;
}

function isVolumePriceAndOptions(product) {
  const isNoCommonPrices = product.variants.reduce((isNoCommon, variant) => {
    if (variant.price.common) {
      return false;
    }

    return isNoCommon;
  }, true);

  const isNotEmptyPrices = product.variants.reduce((isNotEmpty, variant) => {
    if (Object.keys(variant.price).length === 0) {
      return false;
    }

    return isNotEmpty;
  }, true);

  const isFromNotNullable = product.variants.reduce((isNotNullable, variant) => {
    if (variant.from === null) {
      return false;
    }

    return isNotNullable;
  }, true);

  return isNoCommonPrices && isNotEmptyPrices && isFromNotNullable;
}

function getProductPriceType(product): EPriceType {
  if (isOnePricePrice(product)) {
    return EPriceType.ONE_PRICE;
  }

  if (isVolumePricePrice(product)) {
    return EPriceType.VOLUME_PRICE;
  }

  if (isOnePriceEachCurrency(product)) {
    return EPriceType.ONE_PRICE_EACH_CURRENCY;
  }

  if (isVolumePriceAndOptions(product)) {
    return EPriceType.VOLUME_PRICE_AND_OPTIONS;
  }

  return EPriceType.UNKNOWN;
}

export default function mapProducts(productList, selectedProductList, utils: { array}) {
  return productList.map((product) => {
    const productPriceType = getProductPriceType(product);

    return {
      product: { ...product },
      priceGroup: product.priceGroup
        ? { ...product.priceGroup }
        : {
          priceGroupId: product.id,
          quantity: getProductMinQuantity(product),
          autoRenew: false,
        },
      price: getProductPrice(product),
      priceCount: getProductPriceCount(product),
      productPriceType,
      isSelected: isProductSelected(product, selectedProductList, utils),
      url: `/products/${product.id}`,
      autoRenewAvailable: product.autoRenew,
      minQuantity: getProductMinQuantity(product),
      maxQuantity: getProductMaxQuantity(product),
    };
  });
}

export {
  isOnePriceEachCurrency,
  isOnePricePrice,
  isVolumePricePrice,
  isVolumePriceAndOptions,
  getProductPriceType,
  getProductMinQuantity,
  getProductMaxQuantity,
  getProductPrice,
  getProductPriceCount,
};
