<template>
  <div ref="intersectionRef">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'IntersectionObsever',
  mounted() {
    this.intersectionHandler();
  },
  methods: {
    intersectionHandler() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.$emit('intersecting', 'kek');
          }
        });
      }, { threshold: 0.5 });

      observer.observe(this.$refs.intersectionRef);
    },
  },
};
</script>
