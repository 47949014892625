<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      d="M6.336 12.7309L12.7008 6.36609C13.0073 6.05966 13.3712 5.81661 13.7716 5.6508C14.172 5.48499 14.6012 5.39968 15.0346 5.39974C15.468 5.3998 15.8972 5.48522 16.2976 5.65113C16.698 5.81704 17.0618 6.06018 17.3682 6.36669C17.6746 6.6732 17.9177 7.03705 18.0835 7.43749C18.2493 7.83793 18.3346 8.26711 18.3345 8.70051C18.3345 9.13392 18.2491 9.56308 18.0832 9.96347C17.9173 10.3639 17.6741 10.7277 17.3676 11.0341L9.7308 18.6709C9.4493 18.9524 9.0675 19.1105 8.6694 19.1105C8.2713 19.1105 7.8895 18.9524 7.608 18.6709C7.3265 18.3894 7.16835 18.0076 7.16835 17.6095C7.16835 17.2114 7.3265 16.8296 7.608 16.5481L14.3964 9.75969C14.5554 9.58908 14.6419 9.36342 14.6378 9.13026C14.6337 8.8971 14.5392 8.67464 14.3743 8.50974C14.2094 8.34485 13.987 8.25039 13.7538 8.24628C13.5207 8.24217 13.295 8.32871 13.1244 8.48769L6.336 15.2761C5.71698 15.8951 5.36922 16.7347 5.36922 17.6101C5.36922 18.4855 5.71698 19.3251 6.336 19.9441C6.95501 20.5631 7.79458 20.9109 8.67 20.9109C9.54542 20.9109 10.385 20.5631 11.004 19.9441L18.6396 12.3061C19.5776 11.3459 20.0993 10.0547 20.0915 8.71239C20.0837 7.3701 19.547 6.08501 18.5978 5.13585C17.6487 4.18668 16.3636 3.64999 15.0213 3.64218C13.679 3.63436 12.3877 4.15605 11.4276 5.09409L5.064 11.4577C4.90502 11.6283 4.81848 11.854 4.82259 12.0871C4.8267 12.3203 4.92116 12.5427 5.08605 12.7076C5.25095 12.8725 5.47341 12.967 5.70657 12.9711C5.93973 12.9752 6.16539 12.8887 6.336 12.7297V12.7309Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSAttachIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>
