// TODO: Удалить решения с передачей query строкой.
//  Сбор строки запроса - обязанность сервиса апи.
export default function webhookModule(instance) {
  return {
    getEndpoints(token) {
      return instance.get('/vendor/endpoint', { cancelToken: token });
    },
    updateEndpoint(id, payload) {
      return instance.put(`/vendor/endpoint/${id}`, payload);
    },
    deleteEndpoint(id) {
      return instance.delete(`/vendor/endpoint/${id}`);
    },
    createEndpoint(payload) {
      return instance.post('/vendor/endpoint', payload);
    },
    getEndpoint(id) {
      return instance.get(`/vendor/endpoint/${id}`);
    },
  };
}
