export default function state(instance) {
  return {
    get() {
      return instance.get('/vendor/dashboard/state');
    },
    update(payload) {
      const data = {
        dashboardState: payload,
      };

      return instance.put('/vendor/dashboard/state', data);
    },
  };
}
