<template>
  <svg
    width="100%"
    height="100%"
    class="svg"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :color="color"
      d="M10 12C10 12.3956 10.1173 12.7822 10.3371 13.1111C10.5568 13.44 10.8692 13.6964 11.2346 13.8478C11.6001 13.9991 12.0022 14.0387 12.3902 13.9616C12.7781 13.8844 13.1345 13.6939 13.4142 13.4142C13.6939 13.1345 13.8844 12.7781 13.9616 12.3902C14.0387 12.0022 13.9991 11.6001 13.8478 11.2346C13.6964 10.8692 13.44 10.5568 13.1111 10.3371C12.7822 10.1173 12.3956 10 12 10C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12ZM17 12C17 12.3956 17.1173 12.7822 17.3371 13.1111C17.5568 13.44 17.8692 13.6964 18.2346 13.8478C18.6001 13.9991 19.0022 14.0387 19.3902 13.9616C19.7781 13.8844 20.1345 13.6939 20.4142 13.4142C20.6939 13.1345 20.8844 12.7781 20.9616 12.3902C21.0387 12.0022 20.9991 11.6001 20.8478 11.2346C20.6964 10.8692 20.44 10.5568 20.1111 10.3371C19.7822 10.1173 19.3956 10 19 10C18.4696 10 17.9609 10.2107 17.5858 10.5858C17.2107 10.9609 17 11.4696 17 12ZM3 12C3 12.3956 3.1173 12.7822 3.33706 13.1111C3.55682 13.44 3.86918 13.6964 4.23463 13.8478C4.60009 13.9991 5.00222 14.0387 5.39018 13.9616C5.77814 13.8844 6.13451 13.6939 6.41421 13.4142C6.69392 13.1345 6.8844 12.7781 6.96157 12.3902C7.03874 12.0022 6.99913 11.6001 6.84776 11.2346C6.69638 10.8692 6.44004 10.5568 6.11114 10.3371C5.78224 10.1173 5.39556 10 5 10C4.46957 10 3.96086 10.2107 3.58579 10.5858C3.21071 10.9609 3 11.4696 3 12Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSEllipsisIcon',
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
};
</script>

<style scoped>
.svg {
  width: 100% !important;
  height: 100% !important;
}

</style>
