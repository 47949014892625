<template>
  <div
    class="ui-radio-button"
    :class="[
      radioButtonChecked ? 'ui-radio-button--checked' : null,
      isDisabled ? 'ui-radio-button--disabled' : null,
    ]"
  >
    <span class="ui-radio-button__radio-button" />
    <span class="ui-radio-button__text">{{ radioButtonText }}</span>
  </div>
</template>

<script>
export default {
  name: 'UIRadioButton',
  props: {
    radioButtonText: {
      type: String,
      default: 'Some text',
    },
    radioButtonChecked: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.ui-radio-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 100%;

  &__radio-button {
    height: 18px;
    width: 18px;
    min-width: 18px;
    border-radius: 50%;
    border: 1px solid #c7c7cd;
    transition: 0.2s;
    background-color: transparent;
  }

  &--checked &__radio-button {
    border-width: 5px;
    border-color: #4397cb;
    background-color: transparent;
  }

  &:hover &__radio-button {
    border-color: #2d55a5;
  }

  &__text {
    color: black;
    margin-left: 8px;
    font-family: $text-ibm-plex-sans;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--disabled &__text {
    color: #C7C7CD;
  }

  &--disabled &__radio-button {
    border-color: #C7C7CD;
  }
}
</style>
