import THandleFieldBlurParams from '@/module/product/view/ProductFormPage/model/THandleFieldBlurParams';
import BaseFieldBlurScenario from '@/module/product/view/ProductFormPage/scenario/BaseFieldBlurScenario';
import TPricingModelOption from '@/module/product/model/Product/Fields/PricingModel/Option/TPricingModelOption';
import EPricingModelName from '@/module/product/model/Product/Fields/PricingModel/EPricingModelName';
import TPricingTableValue from '@/module/product/model/TPricingTableValue';

class PricingModelFieldBlurScenario extends BaseFieldBlurScenario {
  run = (item: THandleFieldBlurParams) => {
    const productFormPage = this.serviceContainer.moduleProduct.productFormPage;
    const formDataHandler = productFormPage.handlerService.formDataHandler;
    const pricingTableHelper = this.serviceContainer.moduleProduct.pricingTableHelper;
    const portalModeService = this.serviceContainer.portalModeService;
    const utilsService = this.serviceContainer.utilsService;

    const currencyList = portalModeService.getProductFormPageHandler().getPriceCurrencyList()
      .map((currency) => {
        const title = currency.toUpperCase();
        return { id: currency, title };
      });

    const pricingModelName = (item.value as TPricingModelOption).id;
    const formData = formDataHandler.getByPricingModel(pricingModelName) ?? formDataHandler.get();

    formData.pricingModel = item.value as TPricingModelOption;

    if (pricingModelName === EPricingModelName.ONE_PRICE) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!formData.price?.inputValue) {
        formData.price = {
          inputValue: '',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          selectValue: currencyList[0],
        };
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (formData.renewal && !formData.renewalPrice?.inputValue) {
        formData.renewalPrice = {
          inputValue: '',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          selectValue: currencyList[0],
        };
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formData.sku = formData.sku ?? '';
    } else if (pricingModelName === EPricingModelName.VOLUME_PRICING) {
      if (!formData.price || !pricingTableHelper.isVolumePrice(formData.price as TPricingTableValue)) {
        formData.price = pricingTableHelper.initValue(formData);
      }

      if (
        formData.renewal
        && (
          !formData.renewalPrice
          || !pricingTableHelper.isVolumePrice(formData.renewalPrice as TPricingTableValue)
        )
      ) {
        formData.renewalPrice = utilsService.data.cloneImmutable(formData.price);
      }
    } else if (pricingModelName === EPricingModelName.VOLUME_PRICING_AND_OPTIONS) {
      if (!formData.price || !pricingTableHelper.isVolumePriceAndOptions(formData.price as TPricingTableValue)) {
        formData.price = pricingTableHelper.initValue(formData);
      }

      if (
        formData.renewal
        && (
          !formData.renewalPrice
          || !pricingTableHelper.isVolumePriceAndOptions(formData.renewalPrice as TPricingTableValue)
        )
      ) {
        formData.renewalPrice = utilsService.data.cloneImmutable(formData.price);
      }
    } else if (pricingModelName === EPricingModelName.ONE_PRICE_FOR_EACH_CURRENCY) {
      if (!formData.price || !pricingTableHelper.isOnePriceForEachCurrency(formData.price as TPricingTableValue)) {
        formData.price = pricingTableHelper.initValue(formData);
      }

      if (
        formData.renewal
        && (
          !formData.renewalPrice
          || !pricingTableHelper.isOnePriceForEachCurrency(formData.renewalPrice as TPricingTableValue)
        )
      ) {
        formData.renewalPrice = utilsService.data.cloneImmutable(formData.price);
      }
    }

    formDataHandler.update(formData);

    this.updateFromConfig();
  };
}

export default PricingModelFieldBlurScenario;
