<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      clip-rule="evenodd"
      d="M7.79997 10.9999L12.7 6.09992C12.8825 5.91363 12.9842 5.66282 12.9829 5.40199C12.9816 5.14116 12.8774 4.89139 12.6929 4.70696C12.5085 4.52252 12.2587 4.41832 11.9979 4.417C11.7371 4.41569 11.4863 4.51736 11.3 4.69992L4.70697 11.2929C4.5195 11.4804 4.41418 11.7348 4.41418 11.9999C4.41418 12.2651 4.5195 12.5194 4.70697 12.7069L11.3 19.2999C11.4863 19.4825 11.7371 19.5842 11.9979 19.5828C12.2587 19.5815 12.5085 19.4773 12.6929 19.2929C12.8774 19.1084 12.9816 18.8587 12.9829 18.5978C12.9842 18.337 12.8825 18.0862 12.7 17.8999L7.79997 12.9999H20C20.2652 12.9999 20.5195 12.8946 20.7071 12.707C20.8946 12.5195 21 12.2651 21 11.9999C21 11.7347 20.8946 11.4804 20.7071 11.2928C20.5195 11.1053 20.2652 10.9999 20 10.9999H7.79997Z"
      fill-rule="evenodd"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSTailArrowLeftIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>
