<template>
  <div class="c-marked-list">
    <DSHeader
      v-if="title"
      :size="3"
      :text="title"
      class="c-marked-list__header"
    />

    <DSTextElement
      v-if="textBefore"
    >
      <!-- eslint-disable vue/no-v-html -->
      <span
        v-html="textBefore"
      />
      <!-- eslint-enable vue/no-v-html -->
    </DSTextElement>

    <DSMarkedList
      :list="list"
      class="c-marked-list__list"
    >
      <template
        #list-item="slotData"
      >
        <div
          class="c-marked-list__item"
        >
          <DSTextElement
            v-if="slotData.item.title"
            variant="medium"
          >
            {{ slotData.item.title }}
          </DSTextElement>
          <DSTextElement
            v-if="slotData.item.text"
          >
            <!-- eslint-disable vue/no-v-html -->
            <span
              v-html="slotData.item.text"
            />
            <!-- eslint-enable vue/no-v-html -->
          </DSTextElement>

          <DSMarkedList
            v-if="slotData.item.subItems"
            :list="slotData.item.subItems"
            class="c-marked-list__list c-marked-list__list--sublist"
          >
            <template
              #list-item="listItemSlotData"
            >
              <div
                class="c-marked-list__item c-marked-list__item--subitem"
              >
                <DSTextElement
                  v-if="listItemSlotData.item.title"
                  variant="medium"
                >
                  {{ listItemSlotData.item.title }}
                </DSTextElement>
                <DSTextElement
                  v-if="listItemSlotData.item.text"
                >
                  <!-- eslint-disable vue/no-v-html -->
                  <span
                    v-html="listItemSlotData.item.text"
                  />
                  <!-- eslint-enable vue/no-v-html -->
                </DSTextElement>
              </div>
            </template>
          </DSMarkedList>
        </div>
      </template>
    </DSMarkedList>

    <DSTextElement
      v-if="textAfter"
    >
      <!-- eslint-disable vue/no-v-html -->
      <span
        v-html="textAfter"
      />
      <!-- eslint-enable vue/no-v-html -->
    </DSTextElement>
  </div>
</template>

<script>
import DSHeader from '@/module/design-system/components/Text/DSHeader.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import DSMarkedList from '@/module/design-system/components/Data/DSMarkedList.vue';

export default {
  name: 'CMarkedList',
  components: {
    DSHeader,
    DSMarkedList,
    DSTextElement,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    textBefore: {
      type: String,
      default: '',
    },
    textAfter: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isSlots() {
      return !!this.$scopedSlots['list-item'];
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.c-marked-list {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__list {
    &--sublist {
      &::v-deep {
        .ds-list__item {
          list-style-type: circle;
        }
      }
    }
  }
}
</style>
