<template>
  <div
    class="c-table"
  >
    <DSHeader
      v-if="title"
      :size="3"
      :text="title"
      class="c-text-block__list"
    />

    <DSTextElement
      v-if="textBefore"
    >
      <!-- eslint-disable vue/no-v-html -->
      <span
        v-html="textBefore"
      />
      <!-- eslint-enable vue/no-v-html -->
    </DSTextElement>

    <div
      class="c-table__block"
    >
      <perfect-scrollbar
        class="c-table__wrapper"
      >
        <DSTable
          class="c-table__table"
          :head="head"
          :rows="rows"
          :fill-first-col="fillFirstCol"
        >
          <template
            #head="slotData"
          >
            <slot
              name="head"
              :cell="slotData.cell"
              :col="slotData.col"
            />
          </template>
          <template
            #cell="slotData"
          >
            <slot
              name="cell"
              :cell="slotData.cell"
              :row="slotData.row"
              :col="slotData.col"
            />
          </template>
        </DSTable>
      </perfect-scrollbar>
    </div>

    <DSTextElement
      v-if="textAfter"
    >
      <!-- eslint-disable vue/no-v-html -->
      <span
        v-html="textAfter"
      />
      <!-- eslint-enable vue/no-v-html -->
    </DSTextElement>
  </div>
</template>

<script>
import DSHeader from '@/module/design-system/components/Text/DSHeader.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import DSTable from '@/module/design-system/components/Data/DSTable.vue';

export default {
  name: 'CTable',
  components: {
    DSHeader,
    DSTable,
    DSTextElement,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    textBefore: {
      type: String,
      default: '',
    },
    textAfter: {
      type: String,
      default: '',
    },
    head: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    fillFirstCol: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import "@/module/design-system/components/variables.scss";

.c-table {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__block {
    overflow: hidden;
    border: 1px solid $surface-stroke-color;
    border-radius: 12px;
  }

  &__table {
    &::v-deep {
      .ds-table {
        &__row {
          &:first-child .ds-table__cell {
            border-top: 0;
          }

          &:last-child .ds-table__cell {
            border-bottom: 0;
          }
        }

        &__cell {
          &:first-child {
            border-left: 0;
          }

          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }

  &__row {
    &:first-child .c-table__cell {
      border-top: 0;
    }

    &:last-child .c-table__cell {
      border-bottom: 0;
    }
  }

  &__cell {
    padding: 12px 15px;
    border: 1px solid $surface-stroke-color;

    &--header {
      text-align: left;
      background-color: #f8fafc;
    }

    &:first-child {
      border-left: 0;
    }

    &:last-child {
      border-right: 0;
    }
  }
}
</style>
