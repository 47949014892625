<template>
  <div
    :class="classes"
    @click="$emit('click', tab)"
  >
    <slot
      name="icon"
    />
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'DSTabItem',
  props: {
    tab: {
      type: [Object, String, Number],
      required: true,
    },
    variant: {
      type: String,
      default: 'disabled',
    },
    titleField: {
      type: String,
      default: 'title',
    },
  },
  computed: {
    classes() {
      return [
        'tab',
        `tab_${this.variant}`,
      ];
    },
    text() {
      return typeof this.tab !== 'object' ? this.tab : this.tab[this.titleField];
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 10px 15px;
  font-family: $text-ibm-plex-sans;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  white-space: nowrap;
  border-radius: 20px;
  cursor: pointer;
  gap: 4px;

  &_disabled {
    color: $secondary-text;
    background: transparent;

    &:hover {
      background: $tabs-background-hover;
    }
  }

  &_active {
    color: $primary-text;
    background: $tabs-background-active;
  }
}
</style>
