enum EApiName {
  API_KEYS = 'apiKeys',
  COMPANY_INFO = 'companyInfo',
  FILES_STORAGE = 'filesStorage',
  PRODUCT_REGISTRY_VALIDATOR = 'productRegistryValidator',
  WIDGET_CONFIG = 'widgetConfig',
  METRICS = 'metrics',
  PERFORMANCE = 'performance',
  FULFILLMENT = 'fulfillment',
  FULFILLMENT_PARAMETERS = 'fulfillmentParameters',
  FULFILLMENT_EMAIL = 'fulfillmentEmail',
}

export default EApiName;
