import defaultLayout from '@/router/defaultLayout';
import ApiKeysPage from '@/module/developers/views/ApiKeysPage.vue';
import ERouteName from '@/module/common/models/router/ERouteName';

const routes = () => [
  {
    path: '/api-keys',
    name: ERouteName.API_KEYS,
    components: {
      default: ApiKeysPage,
      ...defaultLayout,
    },
    meta: { title: 'pages.developers.api_keys.title' },
  },
];

export default routes;
