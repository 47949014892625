import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IProductService from '@/module/product/service/contract/IProductService';
import IProductMapperService from '@/module/product/service/contract/IProductMapperService';
import ProductMapperService from '@/module/product/service/ProductMapperService';
import IProductServiceState from '@/module/product/service/model/IProductServiceState';
import IProductTypeOptionCheckHelper
  from '@/module/product/helper/ProductTypeOptionCheckHelper/IProductTypeOptionCheckHelper';
import ProductTypeOptionCheckHelper
  from '@/module/product/helper/ProductTypeOptionCheckHelper/ProductTypeOptionCheckHelper';

class ProductService implements IProductService {
  private readonly serviceContainer: IServiceContainer;

  constructor(
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
  }

  private state = {} as IProductServiceState;

  get productMapperService(): IProductMapperService {
    if (!this.state.productMapperService) {
      this.state.productMapperService = new ProductMapperService(
        this.serviceContainer,
        this.productTypeOptionCheckHelper,
      );
    }

    return this.state.productMapperService;
  }

  get productTypeOptionCheckHelper(): IProductTypeOptionCheckHelper {
    if (!this.state.productTypeOptionCheckHelper) {
      this.state.productTypeOptionCheckHelper = new ProductTypeOptionCheckHelper();
    }

    return this.state.productTypeOptionCheckHelper;
  }
}

export default ProductService;
