import metrics from '@/module/dashboard/api/metrics';
import performance from '@/module/dashboard/api/performance';
import state from '@/module/dashboard/api/state';

export default function dashboard(instance) {
  return {
    metrics: metrics(instance),
    performance: performance(instance),
    state: state(instance),
  };
}
