import CreateProductDTO from '@/helpers/createProductDTO';
import ParseProductDTO from '@/helpers/parseProductDTO';
import mapCreateEditProductsCurrency from '@/common/mapCreateEditProductsCurrency';
import cloneImmutable from '@/module/common/utils/data/cloneImmutable';

let actualFulfillment;

const defaultStore = () => ({
  imageUrl: '',
  licenseType: 'new',
  title: '',
  subtitle: '',
  description: '',
  availableFor: 'all',
  licenseTermCheckbox: false,
  licenseTermAmount: '1',
  licenseTermType: 'Month',
  trialCheckbox: false,
  trialSku: '',
  trialTermAmount: '1',
  trialTermType: 'Month',
  trialTitle: '',
  trialSubtitle: '',
  renewalCheckbox: false,
  renewalTermAmount: '1',
  renewalTermType: 'Month',
  renewalTitle: '',
  renewalSubtitle: '',
  softwareRegistry: {
    alertStatus: 'default',
    status: false,
    date: null,
    link: {
      value: '',
      status: {
        code: 'default',
      },
    },
    number: {
      value: '',
      status: {
        code: 'default',
      },
    },
  },
  price: [
    [
      {
        sku: '',
        price: {
          common: { currency: 'USD', price: '' },
        },
      },
    ],
    [
      {
        from: '1',
        to: '2',
        sku: '',
        price: {
          common: { currency: 'USD', price: '' },
        },
      },
      {
        from: '3',
        to: '4',
        sku: '',
        price: {
          common: { currency: 'USD', price: '' },
        },
      },
    ],
    [
      {
        sku: '',
        price: {},
      },
    ],
    [
      {
        from: '1',
        to: '2',
        sku: '',
        price: {},
      },
      {
        from: '3',
        to: '4',
        sku: '',
        price: {},
      },
    ],
  ],
  renewalPrice: [
    [
      {
        sku: '',
        price: {
          common: { currency: 'USD', price: '' },
        },
      },
    ],
    [
      {
        from: '1',
        to: '2',
        sku: '',
        price: {
          common: { currency: 'USD', price: '' },
        },
      },
      {
        from: '3',
        to: '4',
        sku: '',
        price: {
          common: { currency: 'USD', price: '' },
        },
      },
    ],
    [
      {
        sku: '',
        price: {},
      },
    ],
    [
      {
        from: '1',
        to: '2',
        sku: '',
        price: {},
      },
      {
        from: '3',
        to: '4',
        sku: '',
        price: {},
      },
    ],
  ],
});

export default (serviceContainer) => ({
  namespaced: true,
  state: {
    fulfillmentId: null,
    ...defaultStore(),
  },
  mutations: {
    changeFieldValue(state, { fieldName, newValue }) {
      state[fieldName] = newValue;
    },
    changePrice(state, {
      price, modelCode, variant, type, newPrice,
    }) {
      state[price][modelCode][variant].price[type].price = newPrice;
    },
    changeSku(state, {
      price, modelCode, variant, newSku,
    }) {
      state[price][modelCode][variant].sku = newSku;
    },
    changeCurrency(state, { price, modelCode, newCurrency }) {
      state[price][modelCode] = state[price][modelCode].map((variant) => {
        variant.price.common.currency = newCurrency;
        return variant;
      });
    },
    changeFrom(state, {
      price, modelCode, variant, newFrom,
    }) {
      state[price][modelCode][variant].from = newFrom;
    },
    changeTo(state, {
      price, modelCode, variant, newTo,
    }) {
      state[price][modelCode][variant].to = newTo;
    },
    addTier(state, { modelCode, newTier }) {
      function generateForkedTo(renewal = false) {
        const price = renewal ? 'renewalPrice' : 'price';
        const priceModelCodeLength = state[price][modelCode].length - 1;

        const forkedTo = state[price][modelCode][priceModelCodeLength].to;
        if (!forkedTo || forkedTo === '∞') {
          const forkedFrom = state[price][modelCode][priceModelCodeLength].from;
          state[price][modelCode][priceModelCodeLength].to = `${Number(forkedFrom) + 1}`;
          return Number(forkedFrom) + 1;
        }

        return Number(forkedTo);
      }

      const prevMainTo = generateForkedTo();
      const prevRenewalTo = generateForkedTo(true);

      const price = { ...state.price };
      const renewalPrice = { ...state.renewalPrice };

      const common = !!price[modelCode][0].price.common;

      if (!common) {
        price[modelCode].push(newTier(prevMainTo));
        renewalPrice[modelCode].push(newTier(prevRenewalTo));

        return;
      }

      const mainCurrency = price[modelCode][0].price.common.currency;
      const renewalCurrency = renewalPrice[modelCode][0].price.common.currency;

      const priceTier = newTier(prevMainTo, mainCurrency);
      const renewalTier = newTier(prevRenewalTo, renewalCurrency);

      price[modelCode].push(priceTier);
      renewalPrice[modelCode].push(renewalTier);

      state.price = price;
      state.renewalPrice = renewalPrice;
    },
    removeTier(state, { modelCode, tierIndex }) {
      state.price[modelCode].splice(tierIndex, 1);
      state.renewalPrice[modelCode].splice(tierIndex, 1);
    },
    setProductData(state, { product, modelCode }) {
      const defaultStoreData = defaultStore();
      if (!product) {
        Object.entries(defaultStoreData).forEach(([key, value]) => {
          state[key] = value;
        });
        return;
      }

      Object.entries(product).forEach(([key, value]) => {
        if (['price', 'renewalPrice'].includes(key)) {
          state[key] = defaultStoreData[key];
          Object.entries(value).forEach(([fieldName, fieldValue]) => {
            state[key][modelCode][fieldName] = fieldValue;
          });
        } else {
          state[key] = value;
        }
      });
    },
    toggleValidating(state, isValidationFinished) {
      state.isFieldsValid = isValidationFinished;
    },
    changeFieldsValid(state, validationsResult) {
      state.isFieldsValid = state.isFieldsValid && validationsResult;
    },
    setSoftwareRegistry(state, payload) {
      state.softwareRegistry = cloneImmutable(payload);
    },
    setFulfillmentId(state, payload) {
      state.fulfillmentId = payload;
    },
  },
  actions: {
    updateSoftwareRegistry({ commit }, softwareRegistry) {
      commit('setSoftwareRegistry', softwareRegistry);
    },
    async saveProduct({ state }, { modelCode }) {
      const utilsService = serviceContainer.resolve('utilsService');

      const newProduct = new CreateProductDTO(state, modelCode).getNewProduct();

      newProduct.fulfillmentId = !utilsService.typeCheck.isSet(actualFulfillment) ? null : actualFulfillment;

      const api = serviceContainer.resolve('api');

      try {
        await api.products.createProduct(newProduct);

        actualFulfillment = undefined;
        return null;
      } catch (error) {
        return error.message;
      }
    },
    async loadProductToEdit({ commit }, { productId }) {
      const api = serviceContainer.resolve('api');

      try {
        const response = await api.products.getProduct(productId);
        const { data: { product } } = response;
        const { pricingModel, softwareRegistry } = product;

        // TODO: update RUB if exist. crutch
        const { price, renewalPrice, fulfillmentId } = product;

        actualFulfillment = fulfillmentId;

        product.price = mapCreateEditProductsCurrency(price, serviceContainer);

        if (renewalPrice) {
          product.renewalPrice = mapCreateEditProductsCurrency(renewalPrice, serviceContainer);
        }

        if (softwareRegistry) {
          const softwareRegistryDTO = {
            status: softwareRegistry.status ?? null,
            date: softwareRegistry.date ?? null,
            link: {
              value: softwareRegistry.url ?? '',
              status: {
                code: 'default',
              },
            },
            number: {
              value: softwareRegistry.registrationNumber ?? '',
              status: {
                code: 'default',
              },
            },
          };

          commit('setSoftwareRegistry', softwareRegistryDTO);
        }

        return {
          pricingModel,
          productToEdit: new ParseProductDTO(product).getEditProduct(),
        };
      } catch (e) {
        return false;
      }
    },
    async updateProduct({ state }, { productId, modelCode }) {
      const utilsService = serviceContainer.resolve('utilsService');

      const api = serviceContainer.resolve('api');

      const updatedProduct = new CreateProductDTO(state, modelCode).getNewProduct();

      updatedProduct.fulfillmentId = !utilsService.typeCheck.isSet(actualFulfillment) ? null : actualFulfillment;

      try {
        // TODO: update RUB if exist. crutch
        const { price, renewalPrice } = updatedProduct;

        updatedProduct.price = mapCreateEditProductsCurrency(price);

        if (renewalPrice) {
          updatedProduct.renewalPrice = mapCreateEditProductsCurrency(renewalPrice);
        }

        await api.products.updateProduct(productId, updatedProduct);

        actualFulfillment = undefined;
        return null;
      } catch (error) {
        return error.message;
      }
    },
    async loadIcon({ commit }, icon) {
      const api = serviceContainer.resolve('api');

      const extension = icon.name.split('.')[icon.name.split('.').length - 1];
      const formData = new FormData();
      formData.append('icon', icon, `icon.${extension}`);
      try {
        const response = await api.products.loadIcon(formData);
        commit('changeFieldValue', { fieldName: 'imageUrl', newValue: response.data.url });
        return true;
      } catch (error) {
        return error;
      }
    },
    pushTier({ commit }, { modelCode, newTier }) {
      commit('addTier', { modelCode, newTier });
    },
    removeTier({ commit }, { modelCode, tierIndex }) {
      commit('removeTier', { modelCode, tierIndex });
    },
  },
  getters: {
    getProductData(state) {
      const store = serviceContainer.resolve('store');
      store.getters['authStore/getSellingCurrencies'].forEach((currency) => {
        if (!state.price[2][0].price[currency]) {
          state.price[2][0].price[currency] = {
            currency,
            price: '',
          };
        }

        if (!state.renewalPrice[2][0].price[currency]) {
          state.renewalPrice[2][0].price[currency] = {
            currency,
            price: '',
          };
        }

        state.price[3].forEach((variant, index) => {
          if (!state.price[3][index].price[currency]) {
            state.price[3][index].price[currency] = {
              currency,
              price: '',
            };
          }
        });

        state.renewalPrice[3].forEach((variant, index) => {
          if (!state.renewalPrice[3][index].price[currency]) {
            state.renewalPrice[3][index].price[currency] = {
              currency,
              price: '',
            };
          }
        });
      });

      return state;
    },
  },
});
