import IState from '@/module/product/view/FulfillmentFormPage/model/interfaces/state/IState';
import ILocalisationRegistry from '@/module/common/utils/localisation/ILocalisationRegistry';
import TTSApi from '@/module/common/tsApi/TTSApi';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import { Store } from 'vuex';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IScenario from '@/module/product/view/FulfillmentFormPage/model/contract/scenario/IScenario';
import EManagerEmailType from '@/module/product/view/FulfillmentFormPage/model/enums/EManagerEmailType';
import ILocalServiceContainer from '@/module/product/view/FulfillmentFormPage/model/ILocalServiceContainer';
import IHandlerService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IHandlerService';
import ELocalServiceFulfillment from '@/module/product/view/FulfillmentFormPage/model/enums/ELocalServiceFulfillment';
import INotificationService from '@/module/common/service/NotificationService/INotificationService';
import ENotificationType from '@/module/common/service/NotificationService/model/ENotificationType';
import VueRouter from 'vue-router';

class SendEmailToManagerScenario implements IScenario {
  private readonly state: IState;

  private readonly serviceContainer: IServiceContainer;

  private readonly localServiceContainer: ILocalServiceContainer;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    localServiceContainer: ILocalServiceContainer,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.localServiceContainer = localServiceContainer;
  }

  run = (): void => {
    const notificationService = this.serviceContainer
      .resolve<INotificationService>(EServiceContainerInstances.NOTIFICATION_SERVICE);
    const router = this.serviceContainer
      .resolve<VueRouter>(EServiceContainerInstances.ROUTER);
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const formDataHandler = handlerService.getFormDataHandler();

    const tsApi = this.serviceContainer.resolve<TTSApi>(EServiceContainerInstances.TS_API);
    const store = this.serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);

    const licenseSourceHandler = handlerService.getLicenseSourceHandler();

    const localisationRegistry: ILocalisationRegistry = store.getters.getLocalisationRegistry;
    const notificationLocalisation = localisationRegistry.fulfillment.form.notification;
    const popUpLocalisation = localisationRegistry.popUp;
    const formData = formDataHandler.getData(this.state.licenseSourceName);

    const payload: {
      type: EManagerEmailType,
      customerNotification?: string,
    } = {} as { type: EManagerEmailType, customerNotification?: string };

    if (licenseSourceHandler.isSalesLicensePool(formData) || licenseSourceHandler.isTestLicensePool(formData)) {
      payload.type = EManagerEmailType.LICENSE_POOL;
      payload.customerNotification = formData.messageToManager;
    } else if (licenseSourceHandler.isSalesOther(formData)) {
      payload.type = EManagerEmailType.OTHER;
      payload.customerNotification = formData.messageToManager;
    } else {
      return;
    }

    this.state.isLoading = true;

    tsApi.fulfillmentEmail.create(payload)
      .then(() => {
        notificationService.push(
          ENotificationType.INFO_POPUP,
          {
            delay: 8000,
            text: notificationLocalisation.emailSent.text,
            title: notificationLocalisation.emailSent.title,
          },
        );

        this.state.isLoading = false;

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        store.dispatch('authStore/refreshInfo').catch(() => {});

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        router.push('/products').catch(() => {});
      })
      .catch(() => {
        notificationService.push(
          ENotificationType.ERROR_POPUP,
          {
            text: popUpLocalisation.wentWrong,
            title: popUpLocalisation.error,
          },
        );

        this.state.isLoading = false;
      });
  };
}

export default SendEmailToManagerScenario;
