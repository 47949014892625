import IFulfillmentService from '@/module/product/service/contract/IFulfillmentService';
import IFulfillmentBackendModel
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/REST/IFulfillmentBackendModel';
import TTSApi from '@/module/common/tsApi/TTSApi';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import RESTApi from '@/module/common/service/RESTApi/RESTApi';
import IUtilsService from '@/module/common/service/UtilsService/contract/IUtilsService';

class FulfillmentService implements IFulfillmentService {
  private serviceContainer: IServiceContainer;

  private fulfillmentList?: IFulfillmentBackendModel[];

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  getActualFulfillment = async (): Promise<IFulfillmentBackendModel | null> => {
    const utilsService = this.serviceContainer.resolve<IUtilsService>(EServiceContainerInstances.UTILS_SERVICE);

    let maxId: number | null = null;
    if (utilsService.typeCheck.isUndefined(this.fulfillmentList)) {
      await this.refreshFulfillmentList();
    }

    if (utilsService.typeCheck.isUndefined(this.fulfillmentList)) {
      return null;
    }

    const fulfillmentIds = this.fulfillmentList.map((fulfillment) => fulfillment.id) as number[];
    maxId = Math.max(...fulfillmentIds);

    const fulfillment = this.fulfillmentList.find((fulfillment) => fulfillment.id === maxId);

    return Promise.resolve(fulfillment ?? null);
  };

  refreshFulfillmentList = async (): Promise<IFulfillmentBackendModel[]> => {
    const tsApi = this.serviceContainer.resolve<TTSApi>(EServiceContainerInstances.TS_API);
    const fulfillmentApi: RESTApi<IFulfillmentBackendModel> = tsApi.fulfillment as RESTApi<IFulfillmentBackendModel>;

    try {
      const response = await fulfillmentApi.list();

      // TODO: fix typing
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.fulfillmentList = response?.data?.list ?? [];
      // eslint-disable-next-line no-empty
    } catch (e) {}

    return Promise.resolve(this.fulfillmentList as IFulfillmentBackendModel[]);
  };
}

export default FulfillmentService;
