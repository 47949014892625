import IPricingModelService from '@/module/product/service/contract/IPricingModelService';
import EPricingModelName from '@/module/product/model/Product/Fields/PricingModel/EPricingModelName';
import TPricingModelOption from '@/module/product/model/Product/Fields/PricingModel/Option/TPricingModelOption';
import OptionException from '@/module/product/service/OptionException';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';

class PricingModelService implements IPricingModelService {
  private serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  get = (pricingModel: EPricingModelName): TPricingModelOption => {
    const result = this.list()
      .find((option) => option.id === pricingModel);

    if (!result) {
      throw new OptionException('pricingModel', pricingModel);
    }

    return result;
  };

  list = (): TPricingModelOption[] => {
    const localisationRegistry = this.serviceContainer.localisationRegistry;

    return [
      {
        id: EPricingModelName.ONE_PRICE,
        title: localisationRegistry.productsOld.form.onePrice.title,
        tooltip: localisationRegistry.productsOld.form.onePrice.hint,
      },
      {
        id: EPricingModelName.VOLUME_PRICING,
        title: localisationRegistry.productsOld.form.volumePricing.title,
        tooltip: localisationRegistry.productsOld.form.volumePricing.hint,
      },
      {
        id: EPricingModelName.VOLUME_PRICING_AND_OPTIONS,
        title: localisationRegistry.productsOld.form.volumePricingAndOptions.title,
        tooltip: localisationRegistry.productsOld.form.volumePricingAndOptions.hint,
      },
      {
        id: EPricingModelName.ONE_PRICE_FOR_EACH_CURRENCY,
        title: localisationRegistry.productsOld.form.onePriceForEachCurrency.title,
        tooltip: localisationRegistry.productsOld.form.onePriceForEachCurrency.hint,
      },
    ];
  };
}

export default PricingModelService;
