import ILicenseSourceHandler
  from '@/module/product/view/FulfillmentFormPage/model/contract/handler/ILicenseSourceHandler';
import TFormData from '@/module/product/view/FulfillmentFormPage/model/types/TFormData';
import ELicenseSourceName from '@/module/product/view/FulfillmentFormPage/model/enums/ELicenseSourceName';
import IFormDataSalesCreateGenerator
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataSalesCreateGenerator';
import IHandlerService from '@/module/product/view/FulfillmentFormPage/model/contract/service/IHandlerService';
import ELocalServiceFulfillment from '@/module/product/view/FulfillmentFormPage/model/enums/ELocalServiceFulfillment';
import IFormDataTestCreateGenerator
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataTestCreateGenerator';
import ILocalServiceContainer from '@/module/product/view/FulfillmentFormPage/model/ILocalServiceContainer';
import IFormDataSalesLicensePool
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataSalesLicensePool';
import IFormDataTestLicensePool
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataTestLicensePool';
import IFormDataSalesOther
  from '@/module/product/view/FulfillmentFormPage/model/interfaces/formData/IFormDataSalesOther';

class LicenseSourceHandler implements ILicenseSourceHandler {
  constructor(localServiceContainer: ILocalServiceContainer) {
    this.localServiceContainer = localServiceContainer;
  }

  getLicenseSourceName = (formData: TFormData): ELicenseSourceName => {
    const licenseSourceName = formData.licenseSource?.id;

    return licenseSourceName ?? ELicenseSourceName.DEFAULT;
  };

  isSalesCreateGenerator = (formData: TFormData): formData is IFormDataSalesCreateGenerator => {
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const licenseSourceHandler = handlerService.getLicenseSourceHandler();
    const licenseSourceName = licenseSourceHandler.getLicenseSourceName(formData);

    return licenseSourceName === ELicenseSourceName.SALES_CREATE_GENERATOR;
  };

  private localServiceContainer: ILocalServiceContainer;

  isTestCreateGenerator = (formData: TFormData): formData is IFormDataTestCreateGenerator => {
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const licenseSourceHandler = handlerService.getLicenseSourceHandler();
    const licenseSourceName = licenseSourceHandler.getLicenseSourceName(formData);

    return licenseSourceName === ELicenseSourceName.TEST_CREATE_GENERATOR;
  };

  isSalesLicensePool = (formData: TFormData): formData is IFormDataSalesLicensePool => {
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const licenseSourceHandler = handlerService.getLicenseSourceHandler();
    const licenseSourceName = licenseSourceHandler.getLicenseSourceName(formData);

    return licenseSourceName === ELicenseSourceName.SALES_LICENSE_POOL;
  };

  isTestLicensePool = (formData: TFormData): formData is IFormDataTestLicensePool => {
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const licenseSourceHandler = handlerService.getLicenseSourceHandler();
    const licenseSourceName = licenseSourceHandler.getLicenseSourceName(formData);

    return licenseSourceName === ELicenseSourceName.TEST_LICENSE_POOL;
  };

  isSalesOther = (formData: TFormData): formData is IFormDataSalesOther => {
    const handlerService = this.localServiceContainer
      .resolve<IHandlerService>(ELocalServiceFulfillment.HANDLER_SERVICE);
    const licenseSourceHandler = handlerService.getLicenseSourceHandler();
    const licenseSourceName = licenseSourceHandler.getLicenseSourceName(formData);

    return licenseSourceName === ELicenseSourceName.SALES_OTHER;
  };
}

export default LicenseSourceHandler;
