import IFieldStatusHandler
  from '@/module/common/components/FormComponent/ValidatorHandler/contract/IFieldStatusHandler';
import IValidator from '@/module/common/utils/validator/contract/IValidator';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import IFieldStatus from '@/module/design-system/components/models/FormComponent/IFieldStatus';

class SimpleFieldStatusHandler implements IFieldStatusHandler {
  getStatus = (
    validator: IValidator,
  ): IFieldStatus => {
    const status = {
      code: validator.hasErrors() ? EValidationFieldStatusCode.ERROR : EValidationFieldStatusCode.DEFAULT,
    } as IFieldStatus;

    if (validator.hasErrors()) {
      status.text = validator.getMessages()[0];
    }

    return status;
  };
}

export default SimpleFieldStatusHandler;
