<template>
  <div :class="sliderClasses">
    <p
      v-if="textBefore"
      class="slider-switch__text"
    >
      <LabelComponent
        :label-text="textBefore"
        :theme="theme"
        :tooltip="tooltipBefore"
        :no-margin-bottom="true"
      />
    </p>

    <label
      :class="labelClasses"
    >
      <input
        class="slider-switch__input"
        type="checkbox"
        :checked="checked"
        @change="handleChange"
      >
      <div :class="inputClasses" />
    </label>

    <p
      v-if="textAfter"
      class="slider-switch__text"
    >
      <LabelComponent
        :label-text="textAfter"
        :theme="theme"
        :tooltip="tooltipAfter"
        :no-margin-bottom="true"
      />
    </p>
  </div>
</template>

<script>
import LabelComponent from '@/components/LabelComponent.vue';

export default {
  name: 'SliderSwitch',
  components: {
    LabelComponent,
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    textBefore: {
      type: String,
      default: '',
    },
    textAfter: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: 'light',
    },
    tooltipBefore: {
      type: String,
      default: '',
    },
    tooltipAfter: {
      type: String,
      default: '',
    },
  },
  computed: {
    sliderClasses() {
      return [
        'slider-switch',
        `slider-switch_theme_${this.theme}`,
      ];
    },
    labelClasses() {
      return [
        'slider-switch__label',
        this.checked ? 'slider-switch__label_active' : null,
      ];
    },
    inputClasses() {
      return [
        'slider-switch__slider',
        this.checked ? 'slider-switch__slider_active' : null,
      ];
    },
  },
  methods: {
    handleChange(event) {
      this.$emit('update:checked', event.target.checked);
    },
  },
};
</script>

<style lang='scss'>
/* The switch - the box around the slider */
.slider-switch {
  display: flex;
  align-items: center;
  gap: 7px;

  &__text {
    display: flex;
  }

  &__label {
    margin-left: 8px;
    position: relative;
    // display: inline-block;
    min-width: 28px;
    width: 28px;
    height: 16px;
    transition: .1s;
    border-radius: 20px;
    cursor: pointer;
  }

  &__input {
    display: none;
  }

  &__slider {
    position: absolute;
    height: 14px;
    width: 14px;
    left: 1px;
    top: 1px;
    transition: .2s;
    border-radius: 50%;
    box-shadow: 0 1px 3px 0 rgba(8, 13, 38, .12);

    &_active {
      left: 13px;
    }
  }

  &_theme {
    &_light {
      .slider-switch {
        &__text {
          color: rgba(0, 0, 0, .65);
        }

        &__label {
          background-color: #f0f0f0;

          &.slider-switch__label_active {
            background-color: #4397cb;
          }
        }

        &__slider {
          background-color: #ffffff;
        }
      }
    }

    &_dark {
      .slider-switch {
        &__text {
          color: #ffffff;
        }

        &__label {
          background-color: #404040;

          &.slider-switch__label_active {
            background-color: #4397cb;
          }
        }

        &__slider {
          background-color: #abacac;

          &.slider-switch__slider_active {
            background-color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
