<template>
  <header
    class="header-row"
  >
    <div
      class="header-row__title"
    >
      <CPageTitle
        :page-title="pageTitle"
        :tooltip-text="tooltipText"
      >
        <slot />
      </CPageTitle>
      <div class="header-row__mobile-slot">
        <slot
          name="mobile-menu"
        />
      </div>
    </div>
    <div
      class="header-row__right-side"
    >
      <slot
        name="right-side"
      />
    </div>
  </header>
</template>

<script>
import CPageTitle from '@/module/common/components/PageParts/CPageTitle.vue';

export default {
  name: 'COverviewPageHeaderRow',
  components: {
    CPageTitle,
  },
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
.header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;

  &__right-side {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    gap: 10px;
  }

  &__mobile-slot {
    display: none;
  }
}

@media screen and (max-width: 840px) {
  .header-row {
    flex-wrap: wrap;

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      flex-basis: 100%;
    }

    &__right-side {
      justify-content: flex-start;
      flex-basis: 100%;
    }
  }
}

@media screen and (max-width: 670px) {
  .header-row {
    &__mobile-slot {
      display: block;
    }
  }
}
</style>
