
import {
  defineComponent,
  PropType,
  ref,
  computed,
  reactive,
} from 'vue';

import EColorScheme from '@/module/design-system/components/models/EColorScheme';
import EDSFieldStatus from '@/module/design-system/models/UI/EDSFieldStatus';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import EInputType from '@/module/common/models/UI/EInputType';
import IFieldStatus from '@/module/design-system/components/models/FormComponent/IFieldStatus';
import IMaskOptions from '@/module/common/models/UI/IMaskOptions';

import CLabel from '@/module/common/components/UI/CLabel.vue';
import CMaskedInputField from '@/module/common/components/UI/MaskedInput/CMaskedInputField.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';
import formFieldStatusCodeMapper from '@/module/common/service/FormComponent/mappers/formFieldStatusMapper';

export default defineComponent({
  name: 'CMaskedInput',
  components: {
    CLabel,
    CMaskedInputField,
    DSTextElement,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [
        String,
        Number,
      ],
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    status: {
      type: Object as PropType<IFieldStatus>,
      default: () => ({
        code: EValidationFieldStatusCode.DEFAULT,
      }),
    },
    type: {
      type: String as PropType<EInputType>,
      default: EInputType.TEXT,
    },
    inputDelay: {
      type: Number,
      default: null,
    },
    maskOptions: {
      type: Object as PropType<IMaskOptions>,
      required: true,
    },
    transparentDefaultBorder: {
      type: Boolean,
      default: false,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    errorInPopup: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { slots, emit }) {
    const state = reactive<{ isEditing: boolean; }>({
      isEditing: false,
    });
    const input = ref(null);

    const isBeforeSlot = computed(() => !!slots['before-input']);
    const isAfterSlot = computed(() => !!slots['after-input']);
    const mappedStatusCode = computed(() => formFieldStatusCodeMapper(props.status.code));
    const statusCode = computed(() => (state.isEditing ? EDSFieldStatus.DEFAULT : mappedStatusCode.value));
    const statusTextColor = computed(() => (state.isEditing ? EColorScheme.SECONDARY : mappedStatusCode.value));

    function handleBlur(event) {
      state.isEditing = false;

      emit('blur', event);
    }

    function handleInput(event) {
      emit('input', event);
    }

    function handleKeydown(event) {
      state.isEditing = true;

      emit('keydown', event);
    }

    const statusTextClasses = computed(() => [
      'c-masked-input',
      props.errorInPopup ? 'c-masked-input__comment_popup' : null,
    ]);

    return {
      input,
      isBeforeSlot,
      isAfterSlot,
      statusCode,
      statusTextColor,
      statusTextClasses,
      handleBlur,
      handleInput,
      handleKeydown,
    };
  },
});
