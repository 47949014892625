import IFormFieldItem from '@/module/design-system/components/models/FormComponent/IFormFieldItem';
import cloneImmutable from '@/module/common/utils/data/cloneImmutable';
import IPageState from '@/module/merchant/view/AccountActivatePage/models/IPageState';
import EFieldName from '@/module/merchant/view/AccountActivatePage/models/EFieldName';
import EFieldBlock from '@/module/merchant/view/AccountActivatePage/models/EFieldBlock';
import THandleFieldBlurParams from '@/module/merchant/view/AccountActivatePage/models/THandleFieldBlurParams';
import TFilteredSelectRegistry from '@/module/merchant/view/AccountActivatePage/models/TFilteredSelectRegistry';
import IFilteredSelectHandler from '@/module/merchant/view/AccountActivatePage/Handler/contract/IFilteredSelectHandler';

class FilteredSelectHandler implements IFilteredSelectHandler {
  private state: IPageState;

  constructor(state: IPageState) {
    this.state = state;
  }

  applyFilter = (
    params: { formConfig: IFormFieldItem<EFieldName, EFieldBlock>, data: string },
  ) => {
    const { fieldName, fieldBlock } = params.formConfig;
    const filteredSelectRegistry = cloneImmutable(this.state.filteredSelectRegistry);

    if (!filteredSelectRegistry[fieldBlock]) {
      filteredSelectRegistry[fieldBlock] = {} as { [key in EFieldName]: string };
    }

    filteredSelectRegistry[fieldBlock][fieldName] = params.data;

    this.state.filteredSelectRegistry = filteredSelectRegistry;
  };

  dropFilter = (params: THandleFieldBlurParams) => {
    const { fieldName, fieldBlock } = params.field;

    this.state.filteredSelectRegistry = Object.keys(this.state.filteredSelectRegistry)
      .reduce((registry, blockKey) => Object.keys(this.state.filteredSelectRegistry[blockKey])
        .reduce((registryField, fieldKey) => {
          if (blockKey === fieldBlock && fieldKey === fieldName) {
            return registryField;
          }

          if (this.state.filteredSelectRegistry[blockKey]?.[fieldKey]) {
            return registryField;
          }

          if (!registryField[blockKey]) {
            registryField[blockKey] = {};
          }

          registryField[blockKey][fieldKey] = this.state.filteredSelectRegistry[blockKey][fieldKey];

          return registryField;
        }, registry), {} as TFilteredSelectRegistry);
  };
}

export default FilteredSelectHandler;
