import IHelperService from '@/module/common/service/HelperService/IHelperService';
import IDSButtonHelper from '@/module/common/helpers/contract/IDSButtonHelper';
import DSButtonHelper from '@/module/common/helpers/DSButtonHelper';

class HelperService implements IHelperService {
  private _dsButtonHelper?: IDSButtonHelper;

  get dsButtonHelper(): IDSButtonHelper {
    if (!this._dsButtonHelper) {
      this._dsButtonHelper = new DSButtonHelper();
    }

    return this._dsButtonHelper;
  }
}

export default HelperService;
