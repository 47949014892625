<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 29.3334C23.3638 29.3334 29.3333 23.3638 29.3333 16C29.3333 8.63622 23.3638 2.66669 16 2.66669C8.63621 2.66669 2.66667 8.63622 2.66667 16C2.66667 23.3638 8.63621 29.3334 16 29.3334ZM14.4782 11.6154C14.5585 11.8162 14.6794 11.9982 14.8333 12.15C15.1333 12.4667 15.5333 12.65 16 12.65C16.4396 12.6438 16.859 12.464 17.1667 12.15C17.4833 11.85 17.6333 11.45 17.6333 10.9833C17.6333 10.5167 17.4667 10.1333 17.1667 9.81668C16.8667 9.50002 16.4667 9.35002 16 9.35002C15.5333 9.35002 15.15 9.51668 14.8333 9.81668C14.5167 10.1167 14.3667 10.5167 14.3667 10.9833C14.3599 11.1995 14.3979 11.4146 14.4782 11.6154ZM17.15 14.8334C17.4833 15.1833 17.6333 15.5667 17.6667 15.9833V20.9834H18.2956C18.8624 20.9834 19.3413 21.4988 18.9902 21.9438C18.9384 22.0094 18.8806 22.0726 18.8167 22.1334C18.4667 22.4667 18.1167 22.65 17.6667 22.65H16C15.55 22.65 15.1833 22.4667 14.85 22.1334C14.5167 21.8167 14.3667 21.4333 14.3333 20.9834V15.9833H13.7075C13.1395 15.9833 12.6721 15.4718 13.018 15.0214C13.0677 14.9567 13.1228 14.8939 13.1833 14.8334C13.5333 14.5333 13.8833 14.35 14.3333 14.3167H16C16.45 14.3333 16.8167 14.5167 17.15 14.8334Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSInfoIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>

<style scoped>

</style>
