import { AxiosResponse } from 'axios';
import IRESTApi from '@/module/common/service/RESTApi/contract/IRESTApi';
import IRESTApiListFilter from '@/module/common/service/RESTApi/IRESTApiListFilter';
import IRESTApiMapper from '@/module/common/service/RESTApi/mappers/IRESTApiMapper';

export default class RESTApiDecoratorMap<R, M> implements IRESTApi<M> {
  readonly next: IRESTApi<R>;

  readonly mapper: IRESTApiMapper<R, M>;

  constructor(next: IRESTApi<R>, mapper: IRESTApiMapper<R, M>) {
    this.next = next;
    this.mapper = mapper;
  }

  async list<F>(filter?: IRESTApiListFilter | F): Promise<AxiosResponse<M[]>> {
    return this.next.list<F>(filter).then(this.mapper.list);
  }

  async get(id: number | string, options = null): Promise<AxiosResponse<M>> {
    return this.next.get(id, options).then(this.mapper.get);
  }

  async create(data?: M): Promise<AxiosResponse<M>> {
    return this.next.create(this.mapper.createEntity(data)).then(this.mapper.create);
  }

  async update(id: number, data: M): Promise<AxiosResponse<M>> {
    return this.next.update(id, this.mapper.updateEntity(data)).then(this.mapper.update);
  }

  async replace(id: number, data: M): Promise<AxiosResponse<M>> {
    return this.next.replace(id, this.mapper.replaceEntity(data)).then(this.mapper.update);
  }

  async delete(id: number): Promise<AxiosResponse> {
    return this.next.delete(id);
  }
}
