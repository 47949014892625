import {
  reactive,
} from 'vue';

interface IStateCMaskedInputField {
  currentValue: string | number;
  delay?: number;
  isFocused: boolean;
}

function initState(): IStateCMaskedInputField {
  return reactive({
    currentValue: '',
    isFocused: false,
  });
}

export default initState;
