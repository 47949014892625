import getLocalisation from '@/module/common/helpers/getLocalisation';
import mapObjectSnakeKeysToCamelCaseRecursive
  from '@/module/common/utils/object/mapObjectSnakeKeysToCamelCaseRecursive';
import IVueInstance from '@/module/common/models/IVueInstance';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import IPortalModeService from '@/module/common/service/PortalMode/IPortalModeService';
import EPriceType from '@/module/product/model/EPriceType';

interface ILocalisationProductPricingModel {
  one_price: {
    title: string;
    hint: string;
  };
  volume_pricing: {
    title: string;
    hint: string;
  };
  one_price_for_each_currency: {
    title: string;
    hint: string;
  };
  volume_pricing_and_options: {
    title: string;
    hint: string;
  };
}

interface ICamelCaseLocalisationProductPricingModel {
  onePrice: {
    title: string;
    hint: string;
  };
  volumePricing: {
    title: string;
    hint: string;
  };
  onePriceForEachCurrency: {
    title: string;
    hint: string;
  };
  volumePricingAndOptions: {
    title: string;
    hint: string;
  };
}

enum EPricingModelNames {
  ONE_PRICE = 'One price',
  VOLUME_PRICING = 'Volume pricing',
  ONE_PRICE_FOR_EACH_CURRENCY = 'One price for each currency',
  VOLUME_PRICING_AND_OPTIONS = 'Volume pricing and options',
}

interface PricingModel {
  value: EPricingModelNames;
  title: string;
  hint: string;
}

export default function initProductPricingModelsList(vueInstance: IVueInstance): PricingModel[] {
  const rawLocalisation = getLocalisation<ILocalisationProductPricingModel>('product.form');
  const localisation = mapObjectSnakeKeysToCamelCaseRecursive<
    ILocalisationProductPricingModel,
    ICamelCaseLocalisationProductPricingModel
  >(rawLocalisation);

  const productFormPageHandler = vueInstance.$container
    .resolve<IPortalModeService>(EServiceContainerInstances.PORTAL_MODE_SERVICE).getProductFormPageHandler();

  const allowedPriceTypes = productFormPageHandler.getAllowedPricingModes();

  const priceTypeMap = [
    {
      key: EPriceType.ONE_PRICE,
      value: EPricingModelNames.ONE_PRICE,
    },
    {
      key: EPriceType.VOLUME_PRICE,
      value: EPricingModelNames.VOLUME_PRICING,
    },
    {
      key: EPriceType.ONE_PRICE_EACH_CURRENCY,
      value: EPricingModelNames.ONE_PRICE_FOR_EACH_CURRENCY,
    },
    {
      key: EPriceType.VOLUME_PRICE_AND_OPTIONS,
      value: EPricingModelNames.VOLUME_PRICING_AND_OPTIONS,
    },
  ];

  return priceTypeMap
    .filter((priceTypeMapItem) => allowedPriceTypes.includes(priceTypeMapItem.key))
    .map((priceTypeMapItem) => ({
      ...localisation[priceTypeMapItem.key],
      value: priceTypeMapItem.value,
    }));
}

export {
  ICamelCaseLocalisationProductPricingModel,
  EPricingModelNames,
};
