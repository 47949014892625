import ServiceContainer from '@/module/common/service/ServiceContainer/ServiceContainer';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';

export default {
  install(Vue, { serviceContainer }: { serviceContainer: IServiceContainer }) {
    Vue.prototype.$container = serviceContainer;
  },
};

function getContainerInstance(): IServiceContainer {
  return new ServiceContainer();
}

export {
  getContainerInstance,
};
