
import Vue, {
  computed,
  inject,
  onMounted,
  reactive,
} from 'vue';
import {
  useRoute,
  useRouter,
} from 'vue-router/composables';
import {
  Store,
} from 'vuex';

import ButtonMore from '@/components/ButtonMore/ButtonMore.vue';
import CCopyField from '@/module/common/components/UI/CCopyField.vue';
import CInfoBlockList from '@/module/common/components/CInfoBlockList.vue';
import COverviewPageLayout from '@/module/common/components/PageParts/COverviewPageLayout.vue';
import DSButton from '@/module/design-system/components/UI/DSButton.vue';
import DSHeader from '@/module/design-system/components/Text/DSHeader.vue';
import DSIcon from '@/module/design-system/components/Icons/DSIcon.vue';
import DSTextElement from '@/module/design-system/components/Text/DSTextElement.vue';

import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IState from '@/module/product/view/ProductCardPage/model/IState';
import TProduct from '@/module/product/model/Product/TProduct';

import ProductCardPage from '@/module/product/view/ProductCardPage';

import IVueInstance from '@/module/common/models/IVueInstance';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';

export default Vue.extend({
  name: 'ProductCardPage',
  components: {
    ButtonMore,
    CCopyField,
    CInfoBlockList,
    COverviewPageLayout,
    DSButton,
    DSHeader,
    DSIcon,
    DSTextElement,
  },
  setup() {
    const vueInstance = inject<IVueInstance>('vueInstance') as IVueInstance;
    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;

    const localisationRegistry = serviceContainer.localisationRegistry;
    const localisation = localisationRegistry.productsForm;
    const localisationOld = localisationRegistry.productsOld;
    const localisationPopup = localisationRegistry.popUp;
    const localisationModal = localisationRegistry.modalWindow;

    const store = serviceContainer.resolve<Store<unknown>>(EServiceContainerInstances.STORE);

    const route = useRoute();
    const router = useRouter();

    const state: IState = reactive({
      product: {} as TProduct,
      buyLink: '',
      isLoading: true,
      responseStatus: 200,
      isError: false,
      id: +route.params.id,
      infoBlockList: [],
    });

    const isLoading = computed(() => state.isLoading);

    const productCardPage = new ProductCardPage(state, serviceContainer, vueInstance);

    onMounted(async () => {
      await productCardPage.loadProduct();

      state.infoBlockList = productCardPage.mapDataForInfoBlocks();
    });

    /* @TODO избавиться от стора */
    async function handleDeleteProduct(productId) {
      await store.dispatch('deleteModalStore/changeFieldValue', {
        fieldName: 'id',
        newValue: productId,
      });
      await store.dispatch('deleteModalStore/changeFieldValue', {
        fieldName: 'isOpened',
        newValue: true,
      });

      await store.dispatch('deleteModalStore/changeFieldValue', {
        fieldName: 'activeModalData',
        newValue: {
          dataToDelete: state.product,
          title: localisationModal.deleteProduct,
          buttonKeep: localisationModal.keep,
          buttonDelete: localisationModal.delete,
          handleDeleteClick: () => {
            store.dispatch('deleteModalStore/handleDeleteProductClick', [
              productId,
              localisationPopup.deletedProduct,
              `${state.product.title} • ${state.product.subtitle}`,
              localisationPopup.error,
              localisationPopup.wentWrong,
            ]);

            router.push('/products').catch(() => null);
          },
        },
      });
    }

    async function handleCopyToClipboard() {
      await store.dispatch(
        'notificationsStore/pushNotification',
        {
          title: localisationOld.productCard.notification.copyLink,
          component: 'DSAlert',
          type: 'info',
        },
      );
    }

    return {
      router,
      state,
      localisation,
      localisationOld,
      isLoading,
      handleDeleteProduct,
      handleCopyToClipboard,
    };
  },
});
