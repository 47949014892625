<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :fill="color"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.3636 18.2726L19.363 19.363L20.4545 19.3635V21.5453H3V19.3635L4.09 19.363L4.09091 18.2726H19.3636ZM18.2738 9.5508V17.1872H16.092V9.5508H18.2738ZM12.8193 9.54535V17.1817H10.6375V9.54535H12.8193ZM7.36473 9.54098V17.1773H5.18291V9.54098H7.36473ZM11.7275 3L20.4548 6.27273V8.45455H3.00022V6.27273L11.7275 3Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script>
export default {
  name: 'DSPayoutsIcon',
  props: {
    color: {
      type: String,
      default: '#abacac',
    },
  },
};
</script>

<style scoped>

</style>
