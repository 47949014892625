// eslint-disable-next-line max-len
import IFormDataMapper from '@/module/merchant/view/AccountActivatePage/DataMapper/IFormDataMapper';
import IFormFieldItem from '@/module/design-system/components/models/FormComponent/IFormFieldItem';
import EFormFieldType from '@/module/design-system/components/models/FormComponent/EFormFieldType';
import ICInfoBlockItem from '@/module/common/models/ICInfoBlock/ICInfoBlockItem';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import IUtils from '@/plugins/utils/IUtils';
import EServiceContainerInstances from '@/module/common/service/ServiceContainer/EServiceContainerInstances';
import ICountry from '@/module/merchant/models/ICountry';
import notEmptyValidator from '@/module/common/utils/validator/validators/notEmptyValidator';
import EFieldName from '@/module/merchant/view/AccountActivatePage/models/EFieldName';
import EFieldBlock from '@/module/merchant/view/AccountActivatePage/models/EFieldBlock';
import TFormData
  from '@/module/merchant/view/AccountActivatePage/models/FormData/TFormData';
import TFromDataDraft
  from '@/module/merchant/view/AccountActivatePage/models/Draft/TFromDataDraft';

class FormDataToSummaryMapper implements IFormDataMapper {
  private readonly serviceContainer: IServiceContainer;

  constructor(
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
  }

  // eslint-disable-next-line class-methods-use-this
  mapFieldValue(
    formFieldConfigItem: IFormFieldItem<EFieldName, EFieldBlock>,
    data: TFormData | TFromDataDraft,
  ) {
    const { fieldBlock, fieldName } = formFieldConfigItem;
    const value = data[fieldBlock]?.[fieldName] ?? null;

    const dataObject: ICInfoBlockItem = {
      title: formFieldConfigItem.summary?.label ?? formFieldConfigItem.label,
      type: formFieldConfigItem.type,
    } as ICInfoBlockItem;

    if (!notEmptyValidator(value)) {
      dataObject.value = '-';
    } else if (formFieldConfigItem.type === EFormFieldType.CHECKBOX_GROUP) {
      dataObject.value = (Object.values(value) as { value: boolean, title: string }[])
        .filter((val: { value: boolean, title: string }) => val.value)
        .map((val: { value: boolean, title: string }) => val.title);
    } else if (
      fieldBlock === EFieldBlock.BUSINESS_STRUCTURE
      && fieldName === EFieldName.COUNTRY
    ) {
      dataObject.value = (value as ICountry).name;
    } else if (
      fieldBlock === EFieldBlock.ACCOUNT_FOR_PAYOUTS
      && fieldName === EFieldName.COUNTRY
    ) {
      dataObject.value = (value as ICountry).name;
    } else if (formFieldConfigItem.type === EFormFieldType.DATE) {
      const utils = this.serviceContainer.resolve<IUtils>(EServiceContainerInstances.UTILS);
      dataObject.value = utils.date.getDateForSummary(value as Date);
    } else if (formFieldConfigItem.type === EFormFieldType.BUTTON_GROUP) {
      dataObject.value = value.title;
    } else if (formFieldConfigItem.type === EFormFieldType.SELECT) {
      dataObject.value = value.title;
    } else {
      dataObject.value = value;
    }

    return dataObject;
  }
}

export default FormDataToSummaryMapper;
