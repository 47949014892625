<template>
  <div class="product-table">
    <div
      v-if="products.length"
      class="product-table__row product-table__head"
    >
      <p class="secondary">
        {{ $t('product.product_table.titlesubtitle').toUpperCase() }}
      </p>
      <p class="secondary">
        {{ $t('product.product_table.id').toUpperCase() }}
      </p>
      <p class="secondary">
        {{ $t('product.product_table.sku').toUpperCase() }}
      </p>
      <p class="secondary">
        {{ $t('product.product_table.last_edit').toUpperCase() }}
      </p>
    </div>
    <div
      v-for="(item, idx) in products"
      :key="item.product.id"
      class="product-table__row"
      @click="(event) => handleRedirectToProductCard(event, item.product.id, idx)"
    >
      <p class="product-table__name">
        {{ item.product.title }}  {{ item.product.subtitle }}
      </p>

      <p class="product-table__id">
        {{ item.product.id }}
      </p>

      <p class="product-table__sku">
        {{ cutExtraLines(item.product.sku).visible || '-' }}
        <span
          v-if="cutExtraLines(item.product.sku).howMore"
          class="secondary"
        >
          {{ cutExtraLines(item.product.sku).howMore }}
        </span>
      </p>

      <p class="product-table__update-date">
        {{ utilsService.date.formatSkipCurrentYearWithoutWeekDay(item.product.updateDate) }}
      </p>

      <ButtonMore
        ref="buttonMoreRef"
        class="product-table__button-more"
        :buttons="[
          {
            name: 'product.product_card.edit',
            handler: () => handleRedirectToEdit(item.product.id),
          },
          {
            name: 'product.product_card.delete',
            handler: () => handleDeleteProduct(item.product.id),
          },
          {
            name: 'product.product_card.copy_buy_link',
            handler: () => handleCopyBuyLink(item.buylink),
          },
        ]"
      />
    </div>
  </div>
</template>

<script>
import {
  inject,
} from 'vue';
import {
  mapState,
  mapMutations,
  mapActions,
} from 'vuex';

import ButtonMore from '@/components/ButtonMore/ButtonMore.vue';
import DateTimeMixin from '@/mixins/dateTimeMixin';

export default {
  name: 'ProductsTable',

  components: {
    ButtonMore,
  },

  mixins: [DateTimeMixin],

  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const serviceContainer = inject('serviceContainer');

    const utilsService = serviceContainer.utilsService;

    const localisationRegistry = serviceContainer.localisationRegistry;
    const localisationOld = localisationRegistry.productsOld;

    const store = serviceContainer.resolve('store');

    async function handleCopyBuyLink(buyLink) {
      await navigator.clipboard.writeText(buyLink);

      await store.dispatch(
        'notificationsStore/pushNotification',
        {
          title: localisationOld.productCard.notification.copyLink,
          component: 'DSAlert',
          type: 'info',
        },
      );
    }

    return {
      utilsService,
      handleCopyBuyLink,
    };
  },

  computed: {
    ...mapState({
      isPopupWdithControls: (state) => state.popupStore.withControls,
      isPopupOpened: (state) => state.popupStore.isOpened,
      id: (state) => state.deleteModalStore.id,
    }),

    productToDelete() {
      return this.$store.state.productListStore.products.find((item) => item.product.id === this.id)?.product;
    },
  },

  methods: {
    ...mapActions({
      handleDeleteProductClick: 'deleteModalStore/handleDeleteProductClick',
    }),

    ...mapMutations({
      togglePopUp: 'popupStore/togglePopUp',
      toggleControls: 'popupStore/toggleWithControls',
      changeTryAgainhandler: 'popupStore/changeTryAgainHandler',
      changeDeleteModalFieldValue: 'deleteModalStore/changeFieldValue',
    }),

    handleRedirectToProductCard(event, productId, idx) {
      if (this.isPopupOpened) this.handleClosePopup();

      const { target } = event;
      const button = this.$refs.buttonMoreRef[idx].$el;
      if (button.contains(target)) return;
      this.$router.push(`/products/${productId}`).catch(() => null);
    },

    handleClosePopup() {
      this.togglePopUp();

      if (this.isPopupWdithControls) {
        this.changeTryAgainhandler(null);
        this.toggleControls();
      }
    },

    cutExtraLines(string) {
      const array = string.replaceAll(' ', '').split(',');
      if (array.length <= 2) {
        return {
          visible: array.join(' '),
        };
      }
      const visible = array.slice(0, 1).join(' ');
      const howMore = array.splice(1).length;
      return {
        visible,
        howMore: this.$t('product.product_table.and_more', { number: howMore }),
      };
    },

    handleDeleteProduct(productId) {
      this.changeDeleteModalFieldValue({ fieldName: 'id', newValue: productId });
      this.changeDeleteModalFieldValue({ fieldName: 'isOpened', newValue: true });
      this.changeDeleteModalFieldValue({
        fieldName: 'activeModalData',
        newValue: {
          dataToDelete: this.productToDelete,
          title: this.$t('modal_window.delete_product'),
          buttonKeep: this.$t('modal_window.keep'),
          buttonDelete: this.$t('modal_window.delete'),
          handleDeleteClick: () => {
            const info = [
              productId,
              this.$t('popup_messages.deleted_product'),
              `${this.productToDelete.title} • ${this.productToDelete.subtitle}`,
              this.$t('popup_messages.error'),
              this.$t('popup_messages.went_wrong'),
            ];
            return this.handleDeleteProductClick(info);
          },
        },
      });

      this.$refs.buttonMoreRef.forEach((el) => {
        el.isMenuOpened = false;
      });
    },

    handleRedirectToEdit(productId) {
      this.$router.push(`/products/edit/${productId}`).catch(() => null);
    },
  },
};
</script>

<style lang="scss">
  .product-table {
    margin-top: 10px;

    width: 100%;
    &__sku {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &__row {
      cursor: pointer;
      padding: 20px 0;
      display: grid;
      grid-template-columns: auto 163px 161px 104px 28px;
      border-bottom: 1px solid #E5E5E5;
      height: 84px;
    }

    &__head {
      border-bottom: 0;
      padding: 0;
      padding-bottom: 6px;
      height: auto;

      p{
        font-size: 14px;
      }
    }

    &__name, &__id, &__sku {
      margin-right: 53px;
    }

    &__name {
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__update-date {
      margin-right: 37px;
    }

    &__button-more {
      bottom: 0;
      align-self: flex-start;
    }

    &__button-more .button-more {
      border: 0;
      padding: 0;
      width: 24px;
      height: 24px;

      svg path {
        fill: black
      }
    }
  }

  @media screen and (max-width: 1120px) {
    .product-table__row {
      grid-template-columns: auto 125px 123px 105px 28px;
    }
  }

  @media screen and (max-width: 920px) {
    .product-table__row {
      grid-template-columns: auto 111px 109px 96px 28px;
      height: 110px;
    }

    .product-table__row:first-child {
      height: auto;
    }
  }
</style>
